<template>
    <b-modal ref="modal-feedback" hide-footer hide-header centered size="md" class="p-2">
      <div class="d-block text-center my-3">
        <h4>Envíanos comentarios o preguntas para mejorar Utopie</h4>
      </div>
      <b-form-textarea
        v-model="text" class="my-2"
        placeholder="Comentario"
        rows="4"
        max-rows="6"
        maxlength="1000"
      ></b-form-textarea>
      <div class="w-100">
        <b-button class="my-3" variant="primary" block pill @click="send_feedback">Enviar</b-button>
      </div>
    </b-modal>
</template>

<script>
import userService from "../../../services/userService";
export default {
  components: {
    userService
  },
  data: function() {
    return {
      text: "",
    };
  },
  methods: {
    open(){
        this.$refs['modal-feedback'].show()
    },
    send_feedback: function() {
      if (!this.text || this.text.length < 5) {
        this.$toasted.error("Por favor escribe un mensaje más largo.");
        return;
      }
      userService.send_feedback({ text: this.text }).then(
        data => {
          this.$toasted.success(data.message);
          this.text = "";
        },
        error => {
          this.$toasted.error(
            "Error al mandar tu opinión, por favor inténtalo mas tarde."
          );
          console.error(error);
        }
      );
      this.$bvModal.hide("modal-feedback");
    }
  }
};
</script>

<style scoped>

</style>
