var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center w-100"},[(_vm.loading)?_c('div',[_c('div',{staticClass:"m-2"},[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"type":"grow","label":"Loading..."}})],1)],1)],1)],1)]):_c('div',[_c('b-card',{staticClass:"m-0",class:_vm.material.style === 1
          ? 'modern-style'
          : _vm.material.style === 2
          ? 'dream-style'
          : _vm.material.style === 3
          ? 'calm-style'
          : '',attrs:{"no-body":""}},[_c('b-card-body',[(_vm.mode === 'creating' || _vm.mode === 'editing')?_c('b-row',{staticClass:"w-100 mx-auto my-2 p-2"},[_c('b-col',{staticClass:"text-left",attrs:{"cols":"2"}},[_c('b-button',{staticClass:"ml-0",attrs:{"pill":"","variant":"danger"},on:{"click":function($event){return _vm.$emit('back-to-creation', _vm.material)}}},[_c('b',[_c('v-icon',{attrs:{"name":"chevron-left"}}),_vm._v(" Volver")],1)])],1)],1):_vm._e(),(_vm.material.pages[_vm.pageIndex] && _vm.material.pages[_vm.pageIndex].audio)?_c('b-row',{staticClass:"mx-3 my-1 px-2 py-2 rounded border audio-section"},[_c('b-col',{staticClass:"col-sm-4 col-md-3 col-lg-3 col-xl-2"},[_c('audio',{ref:"player-page",attrs:{"autoplay":_vm.autoplay,"src":_vm.material.pages[_vm.pageIndex].audio},on:{"ended":() => {
                  _vm.nowPlaying = '';
                  _vm.playText();
                },"play":function($event){_vm.nowPlaying = 'page'}}}),(_vm.nowPlaying !== 'page')?_c('div',[_c('b-img',{staticStyle:{"cursor":"pointer"},attrs:{"width":"30px","src":_vm.listenIcon},on:{"click":function($event){return _vm.togglePlayer('page')}}})],1):_c('div',[_c('b-img',{staticStyle:{"cursor":"pointer"},attrs:{"width":"30px","src":_vm.$refs[`player-page`] && _vm.$refs[`player-page`]['paused']
                    ? _vm.resumeIcon
                    : _vm.pauseIcon},on:{"click":function($event){return _vm.pausePlayer('page')}}})],1)]),_c('b-col',{staticClass:"pt-2 text-muted text-left col-sm-8 col-md-9 col-lg-9 col-xl-10",staticStyle:{"font-size":"16px"}},[_vm._v(" Presiona para reproducir audio ")])],1):_vm._e(),_c('b-card',{staticClass:"m-5",attrs:{"no-body":""}},[_c('b-card-body',[_c('b-row',{staticClass:"text-left m-0 p-0 mt-3"},[(_vm.material.pages[_vm.pageIndex])?_c('p',{staticClass:"content text-left responsive-page-instruction",staticStyle:{"white-space":"pre-wrap","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.material.pages[_vm.pageIndex].additional_instruction)+" ")]):_vm._e()]),(_vm.material.pages[_vm.pageIndex].text)?_c('b-row',{staticClass:"text-center"},[(_vm.material.pages[_vm.pageIndex].text.content_type === 0)?_c('div',{staticClass:"m-1 p-2 question-form"},[_c('h6',{staticClass:"w-100 responsive-text-title"},[_vm._v(" "+_vm._s(_vm.material.pages[_vm.pageIndex].text.title)+" ")]),_c('h4',{staticClass:"text-muted mx-auto responsive-text-autor"},[_vm._v(" Por "),_c('i',[_vm._v(_vm._s(_vm.material.pages[_vm.pageIndex].text.author))])]),(_vm.material.pages[_vm.pageIndex].text.audio)?_c('div',{staticClass:"w-100 text-left mx-3"},[_c('audio',{ref:"player-text",attrs:{"autoplay":_vm.autoplay && _vm.material.pages[_vm.pageIndex].audio
                        ? false
                        : true,"src":_vm.material.pages[_vm.pageIndex].text.audio},on:{"ended":() => {
                        _vm.nowPlaying = '';
                      },"play":function($event){_vm.nowPlaying = 'text'}}}),(_vm.nowPlaying !== 'text')?_c('div',[_c('b-img',{staticStyle:{"cursor":"pointer"},attrs:{"width":"30px","src":_vm.listenIcon},on:{"click":function($event){return _vm.togglePlayer('text')}}})],1):_c('div',[_c('b-img',{staticStyle:{"cursor":"pointer"},attrs:{"width":"30px","src":_vm.repeatIcon},on:{"click":function($event){return _vm.togglePlayer('text')}}}),_c('b-img',{staticStyle:{"cursor":"pointer"},attrs:{"width":"30px","src":_vm.$refs[`player-text`] && _vm.$refs[`player-text`]['paused']
                          ? _vm.resumeIcon
                          : _vm.pauseIcon},on:{"click":function($event){return _vm.pausePlayer('text')}}})],1)]):_vm._e(),_c('div',{staticClass:"text-left m-2 px-lg-2 text-content responsive-text-content"},[_vm._v(" "+_vm._s(_vm.material.pages[_vm.pageIndex].text.content)+" ")])]):_vm._e(),(_vm.material.pages[_vm.pageIndex].text.content_type === 1)?_c('div',{staticClass:"w-75 mx-auto"},[_c('CustomAudioSlider',{attrs:{"content":_vm.material.pages[_vm.pageIndex].text.story_chapters}})],1):_vm._e(),(_vm.material.pages[_vm.pageIndex].text.content_type === 2)?_c('div',{staticClass:"m-1 p-2 question-form"},[_c('h6',{staticClass:"w-100 responsive-text-title"},[_vm._v(" "+_vm._s(_vm.material.pages[_vm.pageIndex].text.title)+" ")]),(_vm.material.pages[_vm.pageIndex].text.audio)?_c('div',{staticClass:"w-100 text-left mx-3"},[_c('audio',{ref:"player-text",attrs:{"autoplay":_vm.autoplay && _vm.material.pages[_vm.pageIndex].audio
                        ? false
                        : true,"src":_vm.material.pages[_vm.pageIndex].text.audio},on:{"ended":() => {
                        _vm.nowPlaying = '';
                      },"play":function($event){_vm.nowPlaying = 'text'}}}),(_vm.nowPlaying !== 'text')?_c('div',[_c('b-img',{staticStyle:{"cursor":"pointer"},attrs:{"width":"30px","src":_vm.listenIcon},on:{"click":function($event){return _vm.togglePlayer('text')}}})],1):_c('div',[_c('b-img',{staticStyle:{"cursor":"pointer"},attrs:{"width":"30px","src":_vm.repeatIcon},on:{"click":function($event){return _vm.togglePlayer('text')}}}),_c('b-img',{staticStyle:{"cursor":"pointer"},attrs:{"width":"30px","src":_vm.$refs[`player-text`] && _vm.$refs[`player-text`]['paused']
                          ? _vm.resumeIcon
                          : _vm.pauseIcon},on:{"click":function($event){return _vm.pausePlayer('text')}}})],1)]):_vm._e(),_c('div',{staticClass:"text-left m-2 px-lg-2 text-content responsive-text-content"},[_vm._v(" "+_vm._s(_vm.material.pages[_vm.pageIndex].text.content)+" ")])]):_vm._e()]):_vm._e(),(
                _vm.material.pages[_vm.pageIndex] &&
                _vm.material.pages[_vm.pageIndex].images.length > 1
              )?_c('b-row',{staticClass:"justify-content-md-center"},_vm._l((_vm.material.pages[_vm.pageIndex].images),function(img,imgIndex){return _c('b-col',{key:imgIndex + '-img',attrs:{"cols":"12","md":"6"}},[_c('b-img',{key:img.image,attrs:{"src":img.image,"fluid-grow":""}})],1)}),1):(
                _vm.material.pages[_vm.pageIndex] &&
                _vm.material.pages[_vm.pageIndex].images.length === 1
              )?_c('b-row',{staticClass:"justify-content-md-center"},_vm._l((_vm.material.pages[_vm.pageIndex].images),function(img,imgIndex){return _c('b-col',{key:imgIndex + '-img'},[_c('b-img',{key:img.image,attrs:{"src":img.image,"fluid-grow":""}})],1)}),1):_vm._e(),_c('b-container',{staticClass:"responsive-card-question"},_vm._l((_vm.material.pages[_vm.pageIndex]
                  .questions),function(question,qindex){return _c('b-row',{key:qindex + '-question'},[_c('question-preview',{attrs:{"question":question,"design":_vm.material.style,"qindex":qindex},on:{"now-playing":_vm.changePlaying,"mute-all":_vm.muteAllAudios}})],1)}),1)],1),_c('b-card-footer',[_c('b-row',{staticClass:"w-100 mt-2 mx-auto align-items-center"},[_c('b-col',{staticClass:"text-left",attrs:{"cols":"3","sm":"4"}},[(_vm.pageIndex > 0)?_c('b-button',{staticClass:"m-1 m-lg-2",attrs:{"pill":"","variant":"danger"},on:{"click":function($event){_vm.pageIndex -= 1;
                    _vm.scrollToTop();}}},[_c('v-icon',{attrs:{"name":"chevron-left"}}),_c('span',{staticClass:"d-none d-md-inline-block",staticStyle:{"font-size":"16px"}},[_vm._v(" Anterior ")])],1):_vm._e()],1),_c('b-col',{staticClass:"text-center d-none d-md-inline-block",staticStyle:{"font-size":"16px"},attrs:{"cols":"12","sm":"4"}},[_c('strong',[_vm._v("Página "+_vm._s(_vm.pageIndex + 1)+" de "+_vm._s(_vm.material.pages.length))])]),_c('b-col',{staticClass:"text-center d-md-none responsive-footer-text",attrs:{"cols":"6","sm":"4"}},[_c('strong',[_vm._v("Página "+_vm._s(_vm.pageIndex + 1)+" ")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"3","sm":"4"}},[(_vm.pageIndex + 1 < _vm.material.pages.length)?_c('b-button',{staticClass:"m-1 m-lg-2",class:_vm.material.style === 1
                      ? 'modern-next-page-button'
                      : _vm.material.style === 2
                      ? 'dream-next-page-button'
                      : _vm.material.style === 3
                      ? 'calm-next-page-button'
                      : 'default-next-page-button',attrs:{"pill":"","variant":"unknown"},on:{"click":function($event){_vm.pageIndex += 1;
                    _vm.scrollToTop();}}},[_c('span',{staticClass:"d-none d-md-inline-block",staticStyle:{"font-size":"16px"}},[_vm._v(" Siguiente ")]),_c('v-icon',{attrs:{"name":"chevron-right"}})],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }