<template>
  <div class="text-left" style="height: 100%">
    <b-overlay spinner-variant="primary" spinner-type="grow" spinner-small :show="loadingMaterial">
      <EvaluationMetricModal ref="rubric-modal" />
      <AdditionalInstructionModal ref="instruction-modal" />
      <AudioRecordingModal ref="audio-modal" />
      <TextManagerModal ref="text-manager" mode="creationModal" @add-text-material="pushTextMaterial" />
      <QuestionManagerModal ref="question-manager" :pushedFamilies="pushedFamilies"
        @add-selected-question="pushQuestionMaterial" @replace-selected-question="replaceQuestionMaterial"
        @created-question="pushQuestion" />
      <SaveMaterialModal ref="save-material-modal" @save="saveFromModal" :inName="material.name"
        :inDuration="material.duration" :inStyle="material.style" />

      <b-modal ref="pond-modal-pages" hide-footer hide-header centered>
        <FilePond ref="pond-pages" class="p-2 m-2" label-idle="Arrastra tus archivos acá o haz clic para navegar"
          :allow-multiple="false" accepted-file-types="image/jpeg, image/png, image/svg+xml"
          :files="pondObject && pondObject.image ? [pondObject.image] : []" />
        <b-row class="text-right">
          <b-col offset="5">
            <b-button pill variant="danger" class="m-1" @click="closePondPages">
              <v-icon name="times" /> Cancelar</b-button>
            <b-button pill variant="primary" class="my-1 ml-1 mr-2" @click="associateImagePages">
              <v-icon name="save" /> Guardar</b-button>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal ref="loading-modal" centered hide-footer hide-header no-close-on-backdrop no-close-on-esc>
        <div class="m-2">
          <b-container>
            <b-row>
              <b-col class="text-center">
                <b-spinner style="width: 3rem; height: 3rem" type="grow" label="Loading..."></b-spinner>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-modal>
      <b-modal ref="warning-modal" centered hide-footer hide-header rounded-0>
        <div class="m-2">
          <b-row>
            <b-col class="text-center">
              <p>Si vuelve sin guardar se perderán todos los cambios</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center">
              <b-button class="ml-0" pill variant="danger" @click="$emit('back-to-list')">
                <b>Volver</b>
              </b-button>
            </b-col>
            <b-col class="text-center">
              <b-button class="ml-0" pill variant="primary" @click="
                $refs['warning-modal'].hide();
              showModalSave();
              ">
                <v-icon name="save" /> Guardar
              </b-button>
            </b-col>
            <!-- <b-col class="text-center">
            <b-button class="ml-0" pill variant="danger" @click="$refs['warning-modal'].hide()">
              <b> Cancelar</b>
            </b-button>
          </b-col> -->
          </b-row>
        </div>
      </b-modal>
      <b-modal ref="delete-page-confirmation" centered hide-header hide-footer>
        <b-container>
          <b-row>
            <b-col class="text-center"> ¿Eliminar Página? </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center">
              <b-button variant="danger" @click="$refs['delete-page-confirmation'].hide()" pill>
                Cancelar
              </b-button>
            </b-col>
            <b-col class="text-center">
              <b-button variant="warning" @click="deletePage()" pill>
                Eliminar
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-modal>
      <b-modal ref="delete-background-modal" centered hide-header hide-footer>
        <b-container>
          <b-row>
            <b-col class="text-center">
              ¿Estás seguro que deseas eliminar el fondo?
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center">
              <b-button variant="danger" @click="$refs['delete-background-modal'].hide()" pill>
                Cancelar
              </b-button>
            </b-col>
            <b-col class="text-center">
              <b-button variant="warning" @click="
                deleteBackground();
              $refs['delete-background-modal'].hide();
              " pill>
                Eliminar
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-modal>
      <b-modal hide-header hide-footer centered ref="question-creation-modal" size="xl">
        <b-container>
          <b-row v-for="(question, index) in questionsToCreate" :key="index + '-q'">
            <b-col>
              Tipo de pregunta:
              <b-select v-model="question.type">
                <b-select-option :value="null">
                  Selecciona tipo de pregunta
                </b-select-option>
                <b-select-option :value="0">
                  Alternativas
                </b-select-option>
                <b-select-option :value="1">
                  Desarrollo
                </b-select-option>
                <b-select-option :value="3">
                  Ordenamiento
                </b-select-option>
              </b-select>
            </b-col>
            <b-col>
              OA:
              <b-select v-model="question.oa">
                <b-select-option :value="null">
                  Seleccione un OA
                </b-select-option>
                <b-select-option :value="oa" v-for="(oa, oIndex) in customTags" :key="oIndex + '-oa'"
                  :id="oIndex + '-oa'">
                  {{ oa.name }} - {{ oa.description }}
                </b-select-option>
                <b-tooltip v-for="(oa, oIndex) in customTags" :key="oIndex + '-oa-tooltip'" :target="oIndex + '-oa'"
                  triggers="hover" placement="auto">
                  {{ oa.description }}
                </b-tooltip>
              </b-select>
            </b-col>
            <b-col>
              Dificultad:
              <b-select v-model="question.difficulty">
                <b-select-option value="fácil">
                  Fácil
                </b-select-option>
                <b-select-option value="media">
                  Media
                </b-select-option>
                <b-select-option value="difícil">
                  Difícil
                </b-select-option>
              </b-select>
            </b-col>
            <b-col cols="1">
              <b-button size="sm" variant="danger" pill @click="questionsToCreate.splice(index, 1)" class="mt-3">
                <v-icon name="trash-alt" />
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-button variant="unknown"
              @click="questionsToCreate.push({ type: null, difficulty: 'media', oa: null, age: material.grade })">
              <v-icon name="plus-circle" />
              Añadir pregunta
            </b-button>
          </b-row>
          <b-row>
            <b-col class="text-right" @click="generateQuestions">
              <b-button variant="primary" pill>
                Generar Preguntas
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-modal>
      <b-card :class="material.style === 1
        ? 'modern-style'
        : material.style === 2
          ? 'dream-style'
          : material.style === 3
            ? 'calm-style'
            : material.style === 4
              ? 'custom-style'
              : ''
        " :style="material.style == 4 ? 'background-image: url(' + customBG + ');' : ''
    " no-body>
        <b-card-body>
          <b-row class="w-100 mx-auto my-2 p-2">
            <b-col class="text-left" cols="2">
              <b-button class="ml-0" pill variant="danger" @click="backToList">
                <b><v-icon name="chevron-left" /> Volver</b>
              </b-button>
            </b-col>
            <!-- <b-col class="text-center">
              <b-input
                v-model="material.name"
                v-if="editingName"
                :autofocus="editingName"
                @keyup.enter="editingName = false"
                class="text-left w-75 mx-auto d-inline-block"
                size="lg"
              ></b-input>
              <div class="h3 d-inline-block" v-else>{{ material.name }}</div>
              <v-icon
                class="d-inline-block ml-2 mb-1"
                :name="editingName ? 'check' : 'pencil-alt'"
                @click="editingName = !editingName"
                style="cursor: pointer"
                :class="editingName ? 'text-success' : 'text-warning'"
              />
              <b-button
                class="ml-2 mr-0 mb-1"
                pill
                variant="primary"
                @click="$emit('preview-material', material, mode)"
              >
                Previsualizar Material
              </b-button>
            </b-col> -->
            <b-col class="text-center p-1">
              <b-form-select v-model="material.profession" :disabled="!editingFilters">
                <b-form-select-option :value="null">Elige una Profesión</b-form-select-option>
                <b-form-select-option v-for="(role, ir) in roles.filter((r) => [6].includes(r[0]))" :key="ir + '-role'"
                  :value="role[0]">
                  {{ role[1] }}
                </b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col class="text-center p-1" v-if="material.profession">
              <b-form-select v-model="material.subject" :disabled="!editingFilters" @change="checkIfClose">
                <b-form-select-option :value="null">Elige una Asignatura</b-form-select-option>
                <b-form-select-option v-for="(subject, is) in subjects.filter((s) =>
                  [7, 29].includes(s[0])
                )" :key="is + '-subject'" :value="subject[0]">
                  {{ subject[1] }}
                </b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col class="text-center p-1" v-if="material.subject">
              <b-form-select v-model="material.grade" :disabled="!editingFilters" @change="checkIfClose">
                <b-form-select-option :value="null">Elige un Curso</b-form-select-option>
                <b-form-select-option v-for="(grade, ig) in grades.filter((g) =>
                  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].includes(g[0])
                )" :key="ig + '-grade'" :value="grade[0]">
                  {{ grade[1] }}
                </b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col v-if="showSave && (material.is_active || mode === 'creating')" class="text-right" cols="2">
              <b-button class="mr-0" pill variant="primary" block @click="showModalSave" :disabled="disableSave">
                <v-icon name="save" /> Guardar
              </b-button>
            </b-col>
            <b-col v-if="!showSave && (material.is_active || mode === 'creating')" class="text-right" cols="2">
              <div id="applyButton">
                <b-button class="mr-0" pill variant="secondary" block @click="createApplication"
                  :disabled="changed || !material.id">
                  <v-icon name="save" /> Aplicar
                </b-button>
              </div>
              <b-tooltip target="applyButton" triggers="hover" placement="auto" v-if="changed || !material.id">
                {{
                  !material.id
                  ? "El material no ha sido guardado!"
                  : "Hay cambios sin guardar en el material!"
                }}
              </b-tooltip>
            </b-col>
          </b-row>

          <!-- <b-row class="w-100 mx-auto p-2">
          <b-button
            v-if="!showOptions"
            @mouseover="showOptions = true"
            class="m-1"
            >Mostrar opciones</b-button
          >
          <b-collapse v-model="showOptions">
            <b-row>
              <b-col class="text-center p-1">
                <b-form-select
                  v-model="material.profession"
                  :disabled="!editingFilters"
                >
                  <b-form-select-option :value="null"
                    >Elige una Profesión</b-form-select-option
                  >
                  <b-form-select-option
                    v-for="(role, ir) in roles"
                    :key="ir + '-role'"
                    :value="role[0]"
                  >
                    {{ role[1] }}
                  </b-form-select-option>
                </b-form-select>
              </b-col>
              <b-col class="text-center p-1" v-if="material.profession">
                <b-form-select
                  v-model="material.subject"
                  :disabled="!editingFilters"
                  @change="checkIfClose"
                >
                  <b-form-select-option :value="null"
                    >Elige una Asignatura</b-form-select-option
                  >
                  <b-form-select-option
                    v-for="(subject, is) in subjects"
                    :key="is + '-subject'"
                    :value="subject[0]"
                  >
                    {{ subject[1] }}
                  </b-form-select-option>
                </b-form-select>
              </b-col>
              <b-col class="text-center p-1" v-if="material.subject">
                <b-form-select
                  v-model="material.grade"
                  :disabled="!editingFilters"
                  @change="checkIfClose"
                >
                  <b-form-select-option :value="null"
                    >Elige un Curso</b-form-select-option
                  >
                  <b-form-select-option
                    v-for="(grade, ig) in grades"
                    :key="ig + '-grade'"
                    :value="grade[0]"
                  >
                    {{ grade[1] }}
                  </b-form-select-option>
                </b-form-select>
              </b-col> -->
          <b-row>
            <b-col cols="4" class="text-left" v-if="material.grade || material.grade===0">
              <b-dropdown variant="info" size="sm" menu-class="w-100" class="mt-2">
                <template #button-content>
                  <v-icon name="palette" scale="0.9" /> Elegir diseño de la
                  prueba
                </template>
                <b-dropdown-item href="#" @click="material.style = 0" :active="material.style === 0">
                  <b-row class="m-0 p-0">
                    <b-col class="m-0 p-0 text-left"> Por defecto </b-col>
                    <b-col class="m-0 p-0 text-right">
                      <v-icon name="circle" style="color: #758bfd" />
                      <v-icon name="circle" style="color: #535175" />
                      <v-icon name="circle" style="color: #16c79a" />
                      <v-icon name="circle" style="color: #ed217c" />
                    </b-col>
                  </b-row>
                </b-dropdown-item>
                <b-dropdown-item href="#" @click="material.style = 1" :active="material.style === 1">
                  <b-row class="m-0 p-0">
                    <b-col class="m-0 p-0 text-left"> Moderno </b-col>
                    <b-col class="m-0 p-0 text-right">
                      <v-icon name="circle" style="color: #008cff" />
                      <v-icon name="circle" style="color: #00266c" />
                      <v-icon name="circle" style="color: #ffa001" />
                      <v-icon name="circle" style="color: #e23a13" />
                    </b-col>
                  </b-row>
                </b-dropdown-item>
                <b-dropdown-item href="#" @click="material.style = 2" :active="material.style === 2">
                  <b-row class="m-0 p-0">
                    <b-col class="m-0 p-0 text-left"> Sueño </b-col>
                    <b-col class="m-0 p-0 text-right">
                      <v-icon name="circle" style="color: #ea5cd6" />
                      <v-icon name="circle" style="color: #303047" />
                      <v-icon name="circle" style="color: #48d6ed" />
                      <v-icon name="circle" style="color: #db2152" />
                    </b-col>
                  </b-row>
                </b-dropdown-item>
                <b-dropdown-item href="#" @click="material.style = 3" :active="material.style === 3">
                  <b-row class="m-0 p-0">
                    <b-col class="m-0 p-0 text-left"> Tranquilidad </b-col>
                    <b-col class="m-0 p-0 text-right">
                      <v-icon name="circle" style="color: #45b49b" />
                      <v-icon name="circle" style="color: #193a31" />
                      <v-icon name="circle" style="color: #b28346" />
                      <v-icon name="circle" style="color: #cc3315" />
                    </b-col>
                  </b-row>
                </b-dropdown-item>
                <b-dropdown-item href="#" @click="material.style = 4" :active="material.style === 4">
                  <b-row class="m-0 p-0">
                    <b-col class="m-0 p-0 text-left"> Personalizado </b-col>
                  </b-row>
                </b-dropdown-item>
              </b-dropdown>
            </b-col>

            <b-col cols="4" v-if="material.style == 4">
              <b-form-file v-model="material.background_image" :state="Boolean(material.background_image)"
                placeholder="Elige una imagen o arrástrala aquí" drop-placeholder="Arrastra la imagen aquí"></b-form-file>
            </b-col>

            <b-col cols="1" class="text-left px-0" v-if="material.style == 4 && material.background_image">
              <b-button class="mx-1 btn-circle-lg" pill size="md" variant="danger" v-b-tooltip.hover title="Borrar fondo"
                @click="openDeleteBackgroundModal()">
                <v-icon name="trash-alt" />
              </b-button>
            </b-col>
          </b-row>
          <!-- </b-row>
          </b-collapse>
        </b-row> -->
          <b-row v-for="(page, pindex) of material.pages" :key="pindex" class="m-2 w-100 page-form">
            <b-col cols="11" class="p-0">
              <b-container>
                <b-card>
                  <b-row class="m-0 p-0" v-if="material.subject === 29">
                    <b-col cols="11">
                      <b-card no-body class="bg-light">
                        <b-card-body>
                          <b-container class="mx-0 px-0" v-if="!Boolean(page.text)">
                            <b-row class="mx-0 px-0">
                              <b-col cols="3">
                                <b-form-select v-model="page.eje">
                                  <b-form-select-option :value="null">Selecciona eje</b-form-select-option>
                                  <b-form-select-option v-for="(eje, is) in ejes" :key="is + '-subject'" :value="eje.id">
                                    {{ eje.name }}
                                  </b-form-select-option>
                                </b-form-select>
                              </b-col>
                              <b-col cols="3" class="text-center">
                                <b-select v-model="textType" v-if="page.eje">
                                  <b-select-option :value="null">Seleccione un tipo de texto</b-select-option>
                                  <b-select-option :value="1">Fábula</b-select-option>
                                  <b-select-option :value="2">Texto Informativo</b-select-option>
                                  <b-select-option :value="3">Novela Breve</b-select-option>
                                </b-select>
                              </b-col>
                              <b-col cols="3" class="text-center">
                                <b-select v-model="textTheme" v-if="textType">
                                  <b-select-option :value="null">Seleccione un tipo de texto</b-select-option>
                                  <b-select-option :value="1">Ciencia Ficción</b-select-option>
                                  <b-select-option :value="2">Romance</b-select-option>
                                  <b-select-option :value="3">Viaje</b-select-option>
                                </b-select>
                              </b-col>
                              <b-col class="text-center" cols="3">
                                <b-input type="text" v-model="textDetails" v-if="textType" placeholder="Que incluya..." />
                              </b-col>
                              <b-col cols="3" v-if="textTheme !== null && !selectedPlot" class="text-center">
                                <b-button variant="primary" pill @click="generatePlots">
                                  Generar Tramas
                                </b-button>
                              </b-col>
                            </b-row>
                            <b-row v-if="plots[0].title !== ''">
                              <b-container>
                                <div v-for="(plot, iPlot) in plots" :key="iPlot + '-plot'">
                                  <b-row v-if="selectedPlot === plot || selectedPlot === null" class="my-3 mx-0 px-0"
                                    @mouseover="hoveredPlot = plot" @mouseleave="hoveredPlot = null"
                                    @click="selectedPlot === plot ? selectedPlot = null : selectedPlot = plot" :style="(hoveredPlot === plot ? 'border: 2px solid gold; border-radius: 5px;' : '') +
                                      (selectedPlot === plot ? 'background-color: gold;' : '')">
                                    <b-col v-if="plot.title" cols="3">
                                      Título
                                      <b-textarea v-model="plot.title" />
                                    </b-col>
                                    <b-col v-if="plot.content" cols="9">
                                      <b-textarea v-model="plot.content" rows="5" no-resize />
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-container>
                            </b-row>
                            <b-row v-if="selectedPlot && !selectedChar">
                              <!-- <b-col>
                                Cantidad de personajes:
                              </b-col>
                              <b-col>
                                <b-input type="number" v-model="nCharacters" />
                              </b-col> -->
                              <b-col class="text-right">
                                <b-button variant="primary" pill @click="generateCharacters">
                                  Generar personajes
                                </b-button>
                              </b-col>
                            </b-row>
                            <b-row v-if="characters[0].content !== ''">
                              <b-container>
                                <div v-for="(character, iChar) in characters" :key="iChar + '-plot'">
                                  <b-row v-if="selectedChar === character || selectedChar === null" class="m-3"
                                    @mouseover="hoveredChar = character" @mouseleave="hoveredChar = null"
                                    @click="selectedChar === character ? selectedChar = null : selectedChar = character"
                                    :style="(hoveredChar === character ? 'border: 2px solid gold; border-radius: 5px;' : '') +
                                      (selectedChar === character ? 'background-color: gold;' : '')">
                                    <b-col v-if="character.content" cols="12">
                                      <b-textarea v-model="character.content" rows="5" no-resize />
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-container>
                            </b-row>
                            <b-row v-if="characters[0].content !== ''">
                              <b-col>
                                <b-select v-model="nPerson">
                                  <b-select-option value="Primera persona">
                                    Primera persona
                                  </b-select-option>
                                  <b-select-option value="Tercera persona">
                                    Tercera persona
                                  </b-select-option>
                                </b-select>
                              </b-col>
                              <b-col>
                                <b-select v-model="textStyle">
                                  <b-select-option value="Misterio y suspenso">
                                    Misterio y suspenso
                                  </b-select-option>
                                </b-select>
                              </b-col>
                              <b-col class="text-left">
                                <b-button pill variant="primary" @click="generateText">
                                  Generar Texto
                                </b-button>
                              </b-col>
                            </b-row>
                            <b-row v-if="generatedText">
                              <strong class="d-block">
                                Texto:
                              </strong>
                              <b-textarea v-model="generatedText" rows="6" />
                            </b-row>
                            <b-row v-if="generatedText">
                              <b-col class="text-right">
                                <b-button pill variant="primary" @click="saveText(page)">
                                  Guardar Texto
                                </b-button>
                              </b-col>
                              <b-col class="text-right">
                                <b-button pill variant="primary" @click="generateHistory(page)">
                                  Generar historia
                                </b-button>
                              </b-col>
                            </b-row>
                          </b-container>
                          <b-container v-else>
                            <b-badge pill variant="info">
                              <v-icon name="thumbtack" scale="0.8" />
                              {{ page.text.title }}
                            </b-badge>
                          </b-container>
                        </b-card-body>
                      </b-card>
                    </b-col>
                    <b-col class="text-right m-0 p-0" style="font-size: 14px">
                      Página {{ pindex + 1 }}
                      <b-button class="mx-1 btn-circle-lg" pill size="lg" variant="danger" v-b-tooltip.hover
                        title="Borrar página" @click="openConfirmation(pindex)">
                        <v-icon name="trash-alt" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <!-- <div class="mx-2 h6 text-muted">
                  <div v-if="Boolean(page.text)">
                    Página relacionada al texto:
                    <b-badge
                      pill
                      variant="info"
                      style="cursor: pointer"
                      @click="openTextModalPage(pindex, page.text)"
                    >
                      <v-icon name="thumbtack" scale="0.8" />
                      {{ page.text.title }}
                    </b-badge>
                    <b-button
                      class="mx-1 btn-circle"
                      pill
                      size="sm"
                      variant="danger"
                      @click="page.text = null"
                    >
                      <v-icon name="trash-alt" />
                    </b-button>
                  </div>
                </div> -->
                  <b-row class="mx-3 my-1 px-0 py-2 rounded border audio-section" v-if="page.audio">
                    <!-- <b-col class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                      <div class="ml-0 pt-1" size="md">
                      <b-img width="30px" class="ml-1 mr-1" alt="audio-img"
                          :src="listenIcon"/>  
                      </div>
                      
                  </b-col> -->
                    <b-col class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                      <audio :autoplay="autoplay" :ref="`player-page-${pindex}`" :src="page.audio" @ended="() => {
                        nowPlaying = '';
                      }
                        " @play="nowPlaying = `page-${pindex}`"></audio>
                      <div v-if="nowPlaying !== `page-${pindex}`">
                        <b-img @click="
                          muteAll = -1;
                        togglePlayer(`page-${pindex}`);
                        " width="30px" style="cursor: pointer" :src="listenIcon" />
                      </div>
                      <div v-else>
                        <b-img @click="pausePlayer(`page-${pindex}`)" width="30px" style="cursor: pointer" :src="$refs[`player-page-${pindex}`] &&
                          $refs[`player-page-${pindex}`]['paused']
                          ? resumeIcon
                          : pauseIcon
                          " />
                      </div>
                    </b-col>
                    <b-col class="pt-2 h6 text-muted text-left col-sm-6 col-md-7 col-lg-8 col-xl-8">
                      Audio adjunto
                    </b-col>
                    <b-col
                      class="border-left d-flex justify-content-center align-content-center text-right col-sm-4 col-md-3 col-lg-2 col-xl-2">
                      <b-button class="mx-1" size="md" variant="outline-danger" @click="deleteAudio(page)">
                        <v-icon name="trash-alt" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row v-for="(img, imgI) in page.images" :key="imgI + '-img'"
                    class="mx-3 my-1 px-0 py-2 image-section rounded border">
                    <b-col cols="10" class="border-right">
                      <!-- <b-img :src="img.image_url" v-if="img.image.file" width="320"/> -->
                      <b-img :src="img.image" width="320" />
                    </b-col>
                    <b-col cols="2" class="d-flex justify-content-center align-content-center">
                      <b-button class="mx-1" size="md" variant="outline-danger" @click="
                        page.images.splice(imgI, 1);
                      deletedPageImages.push({
                        page: page.id,
                        page_image: img.id,
                      });
                      ">
                        <v-icon name="trash-alt" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <!-- <b-row align-h="between">
                  <b-col cols="6">
                    <div class="text-center add-question-div">
                      Crear una nueva pregunta
                      <b-button
                        class="mx-1 btn-circle-sm"
                        pill
                        size="sm"
                        variant="primary"
                        @click="openNewQuestion(pindex)"
                      >
                        Aquí
                      </b-button>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div class="text-center add-question-div">
                      Agrega una pregunta ya creada
                      <b-button
                        class="mx-1 btn-circle-sm"
                        pill
                        size="sm"
                        variant="primary"
                        @click="openQuestionListModalPage(pindex, page.text)"
                      >
                        Aquí
                      </b-button>
                    </div>
                  </b-col>
                </b-row> -->
                  <b-row v-if="page.text || material.subject === 7">
                    <b-col class="text-center">
                      <b-button variant="primary" @click="openGenerateQuestionsModal(page)">
                        Generar Preguntas
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row v-for="(question, qindex) of page.questions" :key="qindex" class="m-2 mx-auto page-form">
                    <div class="scissors"></div>
                    <b-col class="pl-3" cols="11" v-if="question.question===''">
                      <div class="questionWait">
                        <b-row class="float-right">
                          <b-button class="mx-1 btn-circle-md" pill size="md" variant="danger" v-b-tooltip.hover
                            title="Borrar pregunta" @click="deleteQuestion(pindex, qindex)">
                            <v-icon name="trash-alt" />
                          </b-button>
                          <b-button class="mx-1 btn-circle-md" pill size="md" variant="primary" v-b-tooltip.hover
                            title="Editar pregunta" @click="editQuestion(question, pindex, qindex)">
                            <v-icon name="pencil-alt" />
                          </b-button>
                        </b-row>
                        <b-row class="w-100 p-3">
                          <b-col class="pl-0" cols="1">
                            <div class="circle">
                              <p>{{ qindex + 1 }}</p>
                            </div>
                            <div class="d-block">
                              <b-badge variant="primary">
                                {{
                                  question.tag
                                  ? question.tag.name
                                  : "Sin OA asignado"
                                }}
                              </b-badge>
                            </div>
                          </b-col>
                          <b-col class="pl-0" cols="11">
                            <b-row class="justify-content-md-center">
                              <b-spinner></b-spinner>
                            </b-row>
                            <b-row class="justify-content-md-center">
                              <span>Generando pregunta</span>
                            </b-row>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                    <b-col class="pl-3" cols="11" v-else>
                      <div class="question">
                        <b-row class="float-right">
                          <b-button class="mx-1 btn-circle-md" pill size="md" variant="danger" v-b-tooltip.hover
                            title="Borrar pregunta" @click="deleteQuestion(pindex, qindex)">
                            <v-icon name="trash-alt" />
                          </b-button>

                          <b-button class="mx-1 btn-circle-md" pill size="md" variant="primary" v-b-tooltip.hover
                            title="Editar pregunta" @click="editQuestion(question, pindex, qindex)">
                            <v-icon name="pencil-alt" />
                          </b-button>
                        </b-row>
                        <b-row class="w-100 p-3">
                          <b-col class="pl-0" cols="1">
                            <div class="circle">
                              <p>{{ qindex + 1 }}</p>
                            </div>
                            <div class="d-block">
                              <b-badge variant="primary">
                                {{
                                  question.tag
                                  ? question.tag.name
                                  : "Sin OA asignado"
                                }}
                              </b-badge>
                            </div>
                          </b-col>
                          <b-col class="pl-0" cols="11">
                            <strong>
                              <LatexRenderer v-if="detectLatex(question.question)" :textWithLatex="question.question"/>
                              <p v-else style="white-space: pre-wrap">
                                {{ question.question }}
                              </p>
                            </strong>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="2" class="text-right mr-2 pr-0">
                            <strong>Puntaje:</strong>
                          </b-col>
                          <b-col cols="2" class="text-left ml-0 pl-0">
                            <b-input type="number" v-model="question.score" />
                          </b-col>
                        </b-row>
                        <div v-if="question.formulas">
                          <b-form-row v-for="(formula, findex) in question.formulas" :key="findex" class="px-3">
                            <b-col>
                              <math-field @blur="(event) => {
                                change(findex, event.target.value);
                              }
                                " id="formula" ref="formula" style="height: 50px; font-size: 20pt" readonly="true">
                                {{ formula }}
                              </math-field>
                            </b-col>
                          </b-form-row>
                        </div>

                        <div v-if="question.audio" class="d-inline-block">
                          <audio :ref="`player-page-${pindex}-question-${qindex}`" :src="question.audio" @ended="() => {
                            nowPlaying = '';
                          }
                            "></audio>
                          <div v-if="nowPlaying !== `page-${pindex}-question-${qindex}`
                            ">
                            <b-img @click="
                              togglePlayer(
                                `page-${pindex}-question-${qindex}`
                              )
                              " width="30px" style="cursor: pointer" :src="listenIcon" />
                          </div>
                          <div v-else>
                            <b-img @click="
                              togglePlayer(
                                `page-${pindex}-question-${qindex}`
                              )
                              " width="30px" style="cursor: pointer" :src="repeatIcon" />
                            <b-img @click="
                              pausePlayer(`page-${pindex}-question-${qindex}`)
                              " width="30px" style="cursor: pointer" :src="$refs[
    `player-page-${pindex}-question-${qindex}`
  ] &&
    $refs[
    `player-page-${pindex}-question-${qindex}`
    ]['paused']
    ? resumeIcon
    : pauseIcon
    " />
                          </div>
                        </div>
                        <b-row v-if="question.image">
                          <b-img :src="question.image" width="320px"></b-img>
                        </b-row>
                      </div>

                      <div v-if="question.kind === 0" class="question">
                        <b-form-row v-for="(alternative, aindex) of question.alternatives" :key="aindex"
                          class="alternative" :class="{
                            alt_correct: alternative.is_correct,
                            alt_incorrect: !alternative.is_correct,
                          }">
                          <b-col cols="12" v-if="alternative.text">
                            <p v-if="!alternative.is_formula && alternative.text">
                              {{ alternative.text }}
                            </p>
                            <math-field class="align-middle" v-if="alternative.is_formula" :key="alternative.text" style="
                                pointer-events: none;
                                height: 50px;
                                font-size: 20pt;
                                margin-bottom: 10px;
                              " id="formula" :read-only="true">
                              {{ alternative.text }}
                            </math-field>
                          </b-col>
                          <b-col cols="12" v-if="alternative.audio" class="text-left py-1">
                            <audio :ref="`player-page-${pindex}-question-${qindex}-alt-${aindex}`"
                              :src="alternative.audio" @ended="() => {
                                nowPlaying = '';
                              }
                                "></audio>
                            <div v-if="nowPlaying !==
                              `page-${pindex}-question-${qindex}-alt-${aindex}`
                              ">
                              <b-img @click="
                                togglePlayer(
                                  `page-${pindex}-question-${qindex}-alt-${aindex}`
                                )
                                " width="25px" style="cursor: pointer" :src="listenIcon" />
                            </div>
                            <div v-else>
                              <b-img @click="
                                togglePlayer(
                                  `page-${pindex}-question-${qindex}-alt-${aindex}`
                                )
                                " width="25px" style="cursor: pointer" :src="repeatIcon" />
                              <b-img @click="
                                pausePlayer(
                                  `page-${pindex}-question-${qindex}-alt-${aindex}`
                                )
                                " width="25px" style="cursor: pointer" :src="$refs[
    `player-page-${pindex}-question-${qindex}-alt-${aindex}`
  ] &&
    $refs[
    `player-page-${pindex}-question-${qindex}-alt-${aindex}`
    ]['paused']
    ? resumeIcon
    : pauseIcon
    " />
                            </div>
                          </b-col>
                          <b-col cols="12" v-if="alternative.image" class="text-left py-1">
                            <b-img :src="alternative.image" width="320px" />
                            <!-- <v-icon v-if="alternative.image" name="paperclip" class="ml-1 mr-1"/> -->
                          </b-col>
                        </b-form-row>
                      </div>

                      <div v-if="question.kind === 1" class="question">
                        <b-row class="essay_question">
                          <p v-if="question.response_type === 1">
                            Escribe una respuesta ...
                          </p>
                          <p v-else-if="question.response_type === 2">
                            Adjunta una imagen para responder ...
                          </p>
                          <p v-else-if="question.response_type === 3">
                            Graba un audio con tu respuesta ...
                          </p>
                          <p v-else-if="question.response_type === 4">
                            Responde con una carta ...
                          </p>
                          <p v-else>Responde con una noticia ...</p>
                        </b-row>
                      </div>
                      <div v-if="question.kind === 2" class="question">
                        <b-row class="dictation_question">
                          <p>
                            Ingresa lo dictado en los
                            {{ question.dictation }} audios ...
                          </p>
                        </b-row>
                      </div>
                      <div v-if="question.kind === 3" class="question">
                        <b-row>
                          <b-col cols="5" class="options">
                            <div v-for="(option, oindex) of question.options" :key="oindex">
                              <b-row v-if="option.correct" class="correct-option">
                                <b-col v-if="option.text" cols="12">
                                  <p>{{ option.text }}</p>
                                </b-col>
                                <b-col v-if="option.audio" cols="12" class="text-left">
                                  <audio :ref="`player-page-${pindex}-question-${qindex}-opt-${oindex}`"
                                    :src="option.audio" @ended="() => {
                                      nowPlaying = '';
                                    }
                                      "></audio>
                                  <div v-if="nowPlaying !==
                                    `page-${pindex}-question-${qindex}-opt-${oindex}`
                                    ">
                                    <b-img @click="
                                      togglePlayer(
                                        `page-${pindex}-question-${qindex}-opt-${oindex}`
                                      )
                                      " width="25px" style="cursor: pointer" :src="listenIcon" />
                                  </div>
                                  <div v-else>
                                    <b-img @click="
                                      togglePlayer(
                                        `page-${pindex}-question-${qindex}-opt-${oindex}`
                                      )
                                      " width="25px" style="cursor: pointer" :src="repeatIcon" />
                                    <b-img @click="
                                      pausePlayer(
                                        `page-${pindex}-question-${qindex}-opt-${oindex}`
                                      )
                                      " width="25px" style="cursor: pointer" :src="$refs[
    `player-page-${pindex}-question-${qindex}-opt-${oindex}`
  ] &&
    $refs[
    `player-page-${pindex}-question-${qindex}-opt-${oindex}`
    ]['paused']
    ? resumeIcon
    : pauseIcon
    " />
                                  </div>
                                </b-col>
                                <b-col v-if="option.image" cols="12" class="text-left">
                                  <b-img :src="option.image" class="w-100" />
                                </b-col>
                              </b-row>
                            </div>
                          </b-col>
                          <b-col cols="5" class="options">
                            <div v-for="(option, oindex) of question.options" :key="oindex">
                              <b-row v-if="!option.correct" class="incorrect-option">
                                <b-col v-if="option.text" cols="12">
                                  <p>{{ option.text }}</p>
                                </b-col>
                                <b-col v-if="option.audio" cols="12" class="text-left">
                                  <audio :ref="`player-page-${pindex}-question-${qindex}-opt-${oindex}`"
                                    :src="option.audio" @ended="() => {
                                      nowPlaying = '';
                                    }
                                      "></audio>
                                  <div v-if="nowPlaying !==
                                    `page-${pindex}-question-${qindex}-opt-${oindex}`
                                    ">
                                    <b-img @click="
                                      togglePlayer(
                                        `page-${pindex}-question-${qindex}-opt-${oindex}`
                                      )
                                      " width="25px" style="cursor: pointer" :src="listenIcon" />
                                  </div>
                                  <div v-else>
                                    <b-img @click="
                                      togglePlayer(
                                        `page-${pindex}-question-${qindex}-opt-${oindex}`
                                      )
                                      " width="25px" style="cursor: pointer" :src="repeatIcon" />
                                    <b-img @click="
                                      pausePlayer(
                                        `page-${pindex}-question-${qindex}-opt-${oindex}`
                                      )
                                      " width="25px" style="cursor: pointer" :src="$refs[
    `player-page-${pindex}-question-${qindex}-opt-${oindex}`
  ] &&
    $refs[
    `player-page-${pindex}-question-${qindex}-opt-${oindex}`
    ]['paused']
    ? resumeIcon
    : pauseIcon
    " />
                                  </div>
                                </b-col>
                                <b-col v-if="option.image" cols="12" class="text-left">
                                  <b-img :src="option.image" class="w-100" />
                                </b-col>
                              </b-row>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                    <b-col cols="1" class="text-center my-auto" style="color: #f8b500">
                      <table>
                        <tr>
                          <th>
                            <ArrowUpIcon @click.native="moveUp(pindex, qindex)" viewbox="-3 0 60 30" width="35"
                              height="20" :on="qindex !== 0" />
                          </th>
                        </tr>
                        <tr>
                          <th>
                            <small class="text-dark">Mover</small>
                          </th>
                        </tr>
                        <tr>
                          <th>
                            <ArrowDownIcon @click.native="moveDown(pindex, qindex)" viewbox="-3 0 60 30" width="35"
                              height="20" :on="qindex !== page.questions.length - 1" />
                          </th>
                        </tr>
                      </table>
                    </b-col>
                  </b-row>
                </b-card>
              </b-container>
            </b-col>
            <b-col cols="1" class="text-center my-auto" style="color: #f8b500">
              <table>
                <tr>
                  <th>
                    <ArrowUpIcon @click.native="movePageUp(pindex)" viewbox="12 0 30 30" width="40" height="20"
                      :on="pindex !== 0" />
                  </th>
                </tr>
                <tr>
                  <th>
                    <small class="text-dark">Mover</small>
                  </th>
                </tr>
                <tr>
                  <th>
                    <ArrowDownIcon @click.native="movePageDown(pindex)" viewbox="12 0 30 30" width="40" height="20"
                      :on="pindex !== material.pages.length - 1" />
                  </th>
                </tr>
              </table>
            </b-col>
          </b-row>

          <b-row class="m-2 p-2 text-center mx-auto text-muted add-page" @click="material.pages.push(getEmptyPage())"
            v-if="material.pages.length > 0 && material.pages[material.pages.length - 1].questions.length > 0">
            <div class="p-2 mx-auto">
              <p class="h4 w-100">Clic aquí para medir otro eje/unidad</p>
              <v-icon name="plus-circle" scale="4" />
            </div>
          </b-row>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import customMaterialService from "@/services/customMaterialService";
import customTestService from "@/services/customTestService";
import informationService from "@/services/informationService";
import assistedMaterialService from "@/services/assistedMaterialService";
import TextManagerModal from "@/components/custom-materials/modals/TextManagerModal";
import AudioRecordingModal from "@/components/custom-materials/modals/AudioRecordingModal";
import QuestionManagerModal from "@/components/custom-materials/modals/QuestionManagerModal";
import SaveMaterialModal from "@/components/custom-materials/modals/SaveMaterialModal";
import ArrowDownIcon from "../icons/ArrowDownIcon";
import ArrowUpIcon from "../icons/ArrowUpIcon";
import EvaluationMetricModal from "@/components/custom-materials/modals/EvaluationMetricModal";
import AdditionalInstructionModal from "@/components/custom-tests/modals/AdditionalInstructionModal";
import FormulaIcon from "../icons/FormulaIcon";
import MicrophoneIcon from "../icons/MicrophoneIcon";
import ClipIcon from "../icons/ClipIcon";
import RubricIcon from "../icons/RubricIcon";
import ThreeDotsIcon from "../icons/ThreeDotsIcon";
import TrashIcon from "../icons/TrashIcon";
import LatexRenderer from "./LatexRenderer";

export default {
  name: "AssistedCreateMaterial",
  components: {
    TextManagerModal,
    AudioRecordingModal,
    QuestionManagerModal,
    SaveMaterialModal,
    ArrowDownIcon,
    ArrowUpIcon,
    EvaluationMetricModal,
    AdditionalInstructionModal,
    FormulaIcon,
    MicrophoneIcon,
    ClipIcon,
    RubricIcon,
    ThreeDotsIcon,
    TrashIcon,
    LatexRenderer,
  },
  props: {
    tags: {
      type: Array,
      required: false,
    },
    inMaterial: {
      type: Object,
      required: false,
    },
    mode: {
      type: String,
      required: false,
    },
    materialID: {
      type: String,
      required: false,
    },
  },
  watch: {
    // material: {
    //   handler() {
    //     console.log('cambio');
    //     this.changed = true;
    //   },
    //   deep: true
    // }
    "material.background_image": {
      handler(newVal) {
        console.log("recargando imagen de fondo");
        if (newVal) {
          newVal instanceof File ? this.customBG = URL.createObjectURL(newVal) : this.customBG = newVal;
        }

      },
      deep: true,
    },
  },
  data() {
    return {
      preselectedHT: null,
      customTags: [],
      pageQuestions: null,
      index: 0,
      questionsToCreate: [],
      nCharacters: 2,
      characters: [
        { content: '' },
        { content: '' },
        { content: '' }
      ],
      hoveredChar: null,
      selectedChar: null,
      hoveredPlot: null,
      selectedPlot: null,
      generatedText: '',
      nPerson: "Primera persona",
      textStyle: "Misterio y suspenso",
      plots: [
        { title: '', content: '' },
        { title: '', content: '' },
        { title: '', content: '' }
      ],
      textType: null,
      textTheme: null,
      textDetails: '',
      text: '',
      plotTitle: ['Titulo1: ', 'Titulo2: ', 'Titulo3: '],
      plotText: ['', '', ''],
      showOptions: true,
      changed: false,
      selectedQuestion: undefined,
      kinds: [
        { text: "Alternativas", value: 0 },
        { text: "Desarrollo", value: 1 },
        { text: "Dictado", value: 2 },
        { text: "Ordenamiento", value: 3 },
        { text: "Múltiples Ejercicios", value: 4 },
      ],
      response_types: [
        { text: "Texto escrito", value: 1 },
        { text: "Imagen", value: 2 },
        { text: "Audio", value: 3 },
        { text: "Carta", value: 4 },
        { text: "Noticia", value: 5 },
      ],
      exercise_types: [
        { text: "Alternativas", value: 1 },
        { text: "Desarrollo", value: 2 },
        { text: "Audio", value: 3 },
        { text: "Imágenes seleccionables", value: 4 },
      ],
      listenIcon: require("../../../assets/img/eureka_icons/icono_escuchar.svg"),
      repeatIcon: require("../../../assets/img/eureka_icons/icono_repetiraudio.svg"),
      resumeIcon: require("../../../assets/img/eureka_icons/icono_comenzar.svg"),
      pauseIcon: require("../../../assets/img/eureka_icons/icono_reproduciendo.svg"),
      muteAll: -1,
      editingName: false,
      uploaded: [],
      pondObject: null,
      disableSave: false,
      defaultBG: require("../../../assets/img/backgrounds/patternbg.svg"),
      modernBG: require("../../../assets/img/backgrounds/bg-1.png"),
      dreamBG: require("../../../assets/img/backgrounds/bg-2.png"),
      calmBG: require("../../../assets/img/backgrounds/bg-3.png"),
      customBG: "",
      material: {
        name: "Agregar título del material",
        pages: [],
        duration: 60,
        style: 0,
        description: "Agrega una descripcion",
        profession: null,
        topic: null,
        subject: null,
        grade: null,
        school: this.$store.state.login.school,
        created_by: this.$store.state.login.user_id,
      },
      showPreview: false,
      disablePreview: true,
      incorrectText: "",
      correctText: "",
      correctForm: false,
      incorrectForm: false,
      imageHolder: {},
      audioHolder: {},
      correctAnswer: "",
      nowPlaying: "",
      selectedPage: null,
      tagQuestion: { subtags: [] },
      questions: [],
      editingFilters: true,
      pageToDelete: null,
      applications: 0,
      autoplay: false,
      deletedPageImages: [],
      // deletedQuestions:[],
      topics: [
        { text: "Elige un Tema", value: null },
        { text: "Aplicación evaluativa", value: "Aplicación evaluativa" },
      ],
      subjects: [],
      grades: [],
      roles: [],
      select_duration: [
        { text: "Definir duración", value: 0 },
        { text: "5 minutos", value: 5 },
        { text: "10 minutos", value: 10 },
        { text: "15 minutos", value: 15 },
        { text: "20 minutos", value: 15 },
        { text: "25 minutos", value: 25 },
        { text: "30 minutos", value: 30 },
        { text: "35 minutos", value: 35 },
      ],
      deletedPageAudio: [],
      pushedFamilies: [],
      ejes: [],
      loadingMaterial: false,
      deletedBackground: false,
    };
  },
  computed: {
    isLanguage() {
      return this.material.subject === 29;
    },
    showSave() {
      return !this.material.id || this.changed;
    },
  },
  mounted() {
    if (this.materialID) {
      this.loadingMaterial = true;
      customMaterialService.getMaterialByID(this.materialID).then((data) => {
        this.material = data.material;
        customMaterialService
          .getMaterialApplications(this.material.id)
          .then((data) => {
            this.applications = data.length;
          });
        if (this.material.subject && this.material.grade) {
          informationService
            .getEjesValues(this.material.subject, this.material.grade)
            .then((data) => {
              this.ejes = data.ejes;
              this.loadingMaterial = false;
            });
        }
      });
    } else {
      // this.material.pages.push(this.getEmptyPage());
    }
    this.getOptions();
    this.changed = false;
    // customMaterialService.getSchoolQuestions(this.$store.state.login.school).then(
    //     data => this.questions = data
    // ).catch(()=>this.$toasted.error('Ocurrió un problema al traer tus ejercicios'))
  },
  methods: {
    openInstruction(obj) {
      this.$refs["instruction-modal"].open(obj);
    },
    openRubric(question) {
      this.$refs["rubric-modal"].open(question);
    },
    detectLatex(text) {
      const latexRegex = /\\[a-zA-Z]+(?:\{(?:[^{}]|(?:\{[^{}]*\}))*\})?|\$(?:[^$]|\\\$)*\$/g;
      return latexRegex.test(text);
    },
    openGenerateQuestionsModal(page) {
      this.questionsToCreate = [{ difficulty: "media", oa: null, type: null, age: this.material.grade }];
      this.pageQuestions = page;
      customTestService
        .getHashtagsByContent(this.material.subject, this.material.grade)
        .then((data) => {
          customTestService.getTagsFiltered(data[1]).then((d) => {
            this.customTags = d;
          });
        });
      this.$refs['question-creation-modal'].show();
    },
    generateQuestions() {
      console.log("Empezando generateQuestions");
      this.$refs['question-creation-modal'].show();
      this.questionsToCreate.forEach((question) => {
        console.log("question:", question)
        this.generateQuestion(this.pageQuestions, this.material.subject, question);
      })
    },
    generateQuestion(page, subject, question){
      let vue_context = this;
      if (subject === 7) {
        let init_question = {
          kind: 0,
          question: '',
          tag: {id: '', name: question.oa.name, subtags: []},
          alternatives: [
            {
              text: 'A)',
              is_formula: false,
              is_correct: false
            },
            {
              text: 'B)',
              is_formula: false,
              is_correct: false
            },
            {
              text: 'C)',
              is_formula: false,
              is_correct: false
            },
            {
              text: 'D)',
              is_formula: false,
              is_correct: false
            }
          ]
        }
        page.questions.push(init_question);
        let index = page.questions.length - 1;
        assistedMaterialService.generateMathQuestion(question, this.$store.state.login.school
        ).then(
          response => {
            if (!response.body) {
              console.log("WENT WRONG");
              return;
            }

            const content_type = response.headers.get('content-type');

            if (content_type && content_type.includes('application/json')) {
              response.json().then(
                data => {
                  const q = data.question;
                  const letters = ["A)", "B)", "C)", "D)"];
                  let letter_ind = 0;
                  q.alternatives.forEach(alt => {
                    if (!alt.text.includes(letters[letter_ind])) {
                      alt.text = letters[letter_ind] + " " + alt.text;
                      letter_ind += 1;
                    }
                  });
                  page.questions.splice(index, 1, q);
                  return;
                }
              )
            }
            else if (typeof response.body.getReader === 'function') {
              const reader = response.body.getReader();
              const decoder = new TextDecoder();
              return assistedMaterialService.processStream(reader, decoder, page, vue_context);

            }
          }
        )
      }
      else {
        assistedMaterialService.generateQuestion(page.text.content, question, this.$store.state.login.school
        ).then(
          body => {
            // if(question.kind ) //alternatives
            // page.questions.push(body.question)
            const init_question = {
              kind: 0,
              question: '',
              alternatives: [
                {
                  text: 'A)',
                  is_formula: false,
                  is_correct: false
                },
                {
                  text: 'B)',
                  is_formula: false,
                  is_correct: false
                },
                {
                  text: 'C)',
                  is_formula: false,
                  is_correct: false
                },
                {
                  text: 'D)',
                  is_formula: false,
                  is_correct: false
                }
              ]
            }
            page.questions.push(init_question)
            const index = page.questions.length - 1;
            const reader = body.getReader();
            const decoder = new TextDecoder();
            const status = {
              "title": true,
              "alternative": "A",
            }
            function processChunk({ done, value }) {
              if (value) {
                const decoded = decoder.decode(value);
                if (decoded.includes("[finish:")) {
                  // sacar el question y pushear
                  let stringWithoutBrackets = decoded.slice(8, -1);
                  const q = JSON.parse(stringWithoutBrackets);
                  // page.questions[index] = q;
                  page.questions.pop();
                  page.questions.push(q);
                  console.log("pages:", page.questions);
                } else {
                  // agregar pregunta y alternativas por streaming 
                  const matrix = [];
                  if (decoded.includes(';')) {
                    let array = decoded.split(';').map(item => {
                      let stringWithoutBrackets = item.slice(1, -1);
                      return stringWithoutBrackets;
                    });
                    for (const text of array) {
                      matrix.push(text);
                    }
                  } else {
                    let stringWithoutBrackets = decoded.slice(1, -1);
                    matrix.push(stringWithoutBrackets);
                  }
                  for (const text of matrix) {
                    // separamos si es pregunta o alternativa
                    // estamos en el titulo
                    if (status.title) {
                      if (text.includes(")")) {
                        page.questions[index].question = page.questions[index].question.slice(0, -1);
                        status.title = false;
                      } else {
                        page.questions[index].question += text;
                      }
                    } else {
                      // estamos en una alternativa
                      switch (status.alternative) {
                        case "A": // alternativa A
                          if (text.includes("*")) {
                            page.questions[index].alternatives[0].is_correct = true;
                            break;
                          }
                          if (text.includes(")")) {
                            page.questions[index].alternatives[0].text = page.questions[index].alternatives[0].text.slice(0, -1);
                            status.alternative = "B";
                            break;
                          } else {
                            page.questions[index].alternatives[0].text += text;
                            break;
                          }
                        case "B":
                          if (text.includes("*")) {
                            page.questions[index].alternatives[1].is_correct = true;
                            break;
                          }
                          if (text.includes(")")) {
                            page.questions[index].alternatives[1].text = page.questions[index].alternatives[1].text.slice(0, -1);
                            status.alternative = "C";
                            break;
                          } else {
                            page.questions[index].alternatives[1].text += text;
                            break;
                          }
                        case "C":
                          if (text.includes("*")) {
                            page.questions[index].alternatives[2].is_correct = true;
                            break;
                          }
                          if (text.includes(")")) {
                            page.questions[index].alternatives[2].text = page.questions[index].alternatives[2].text.slice(0, -1);
                            status.alternative = "D";
                            break;
                          } else {
                            page.questions[index].alternatives[2].text += text;
                            break;
                          }
                        case "D":
                          if (text.includes("*")) {
                            page.questions[index].alternatives[3].is_correct = true;
                            break;
                          }
                          page.questions[index].alternatives[3].text += text;
                          break;
                        default:
                          break;
                      }
                    }
                  }
                }
              }
              if (done) {
                vue_context.$emit("tag-completed", vue_context.planification);
                return;
              }
              return reader.read().then(processChunk);
            }
            reader.read().then(processChunk);
          }
        )
      }
    },
    saveText(page) {
      assistedMaterialService.saveText({
        title: this.selectedPlot.title,
        text: this.generatedText,
        school: this.$store.state.login.school
      }).then(
        data => {
          this.clearAssistedTextData();
          page.text = data;
        }
      )
    },
    generateHistory(page) {
      assistedMaterialService.generateHistory({
        title: this.selectedPlot.title,
        text: this.generatedText,
        school: this.$store.state.login.school
      }).then(
        data => {
          this.clearAssistedTextData();
          page.text = data;
          console.log("data in generateHistory is: ", data)
        }
      )
    },
    clearAssistedTextData() {
      this.textType = null;
      this.textTheme = null;
      this.textDetails = '';
      this.plots = [
        { title: '', content: '' },
        { title: '', content: '' },
        { title: '', content: '' }
      ];
      this.characters = [
        { content: '' },
        { content: '' },
        { content: '' }
      ];
      this.selectedPlot = null;
      this.selectedChar = null;
      this.textStyle = 'Misterio y suspenso';
      this.nPerson = 'Primera persona';
      this.generatedText = '';
    },
    generateText() {
      let vue_context = this;
      assistedMaterialService.generateText(
        this.selectedChar.content,
        this.selectedPlot.content,
        this.textTheme,
        this.nPerson,
        this.textType,
        this.textDetails,
        this.material.grade,
        this.textStyle
      ).then(
        (body) => {
          const reader = body.getReader();
          const decoder = new TextDecoder();
          function processChunk({ done, value }) {
            if (value) {
              const decoded = decoder.decode(value);
              const matrix = [];
              if (decoded.includes(';')) {
                let array = decoded.split(';').map(item => {
                  let stringWithoutBrackets = item.slice(1, -1);
                  return stringWithoutBrackets;
                });
                for (const text of array) {
                  matrix.push(text);
                }
              } else {
                let stringWithoutBrackets = decoded.slice(1, -1);
                matrix.push(stringWithoutBrackets);
              }
              for (const text of matrix) {
                vue_context.generatedText += text;
              }
            }
            if (done) {
              vue_context.$emit("tag-completed", vue_context.planification);
              return;
            }
            return reader.read().then(processChunk);
          }
          reader.read().then(processChunk);
        });
    },
    generateCharacters() {
      let vue_context = this;
      assistedMaterialService.generateCharacters(this.nCharacters, this.selectedPlot.content, this.textType).then(
        (body) => {
          const reader = body.getReader();
          const decoder = new TextDecoder();
          function processChunk({ done, value }) {
            if (value) {
              const decoded = decoder.decode(value);
              const matrix = [];
              if (decoded.includes(';')) {
                let array = decoded.split(';').map(item => {
                  let indexPlot = item[1];
                  let stringWithoutBrackets = item.slice(2, -1);
                  let arr = [stringWithoutBrackets, indexPlot]
                  return arr;
                });
                for (const arr of array) {
                  matrix.push(arr);
                }
              } else {
                let indexPlot = decoded[1];
                let stringWithoutBrackets = decoded.slice(2, -1);
                let array = [stringWithoutBrackets, indexPlot];
                matrix.push(array);
              }
              for (const arr of matrix) {
                if (arr[1]) {
                  const text = arr[0];
                  const indexPlot = arr[1];
                  vue_context.characters[indexPlot].content += text;
                }
              }
            }
            if (done) {
              vue_context.$emit("tag-completed", vue_context.planification);
              return;
            }
            return reader.read().then(processChunk);
          }
          reader.read().then(processChunk);
        });
    },
    generatePlots() {
      let vue_context = this;
      assistedMaterialService.generatePlots(this.material.grade, this.textType, this.textTheme, this.textDetails).then(
        (body) => {
          const reader = body.getReader();
          const decoder = new TextDecoder();

          function processChunk({ done, value }) {
            if (value) {
              const decoded = decoder.decode(value);
              const matrix = [];
              if (decoded.includes(';')) {
                let array = decoded.split(';').map(item => {
                  let indexPlot = item[1];
                  let stringWithoutBrackets = item.slice(2, -1);
                  let arr = stringWithoutBrackets.split('|');
                  arr.push(indexPlot);
                  return arr;
                });
                for (const arr of array) {
                  matrix.push(arr);
                }
              } else {
                let indexPlot = decoded[1];
                let stringWithoutBrackets = decoded.slice(2, -1);
                let array = stringWithoutBrackets.split('|');
                array.push(indexPlot);
                matrix.push(array);
              }
              for (const arr of matrix) {
                const type = arr[0];
                const text = arr[1];
                const indexPlot = arr[2];
                if (type == "title") {
                  vue_context.plots[indexPlot].title += text;
                }
                else if (type == "content") {
                  vue_context.plots[indexPlot].content += text;
                }
              }
            }
            if (done) {
              vue_context.$emit("tag-completed", vue_context.planification);
              return;
            }
            return reader.read().then(processChunk);
          }
          reader.read().then(processChunk);
        });
    },
    displayTextStreaming(textChunk) {
      this.generatedText2 += textChunk + ' ';
    },
    checkIfClose() {
      if (this.material.subject && this.material.grade) {
        this.material.pages = [this.getEmptyPage()];
        this.showOptions = false;
        informationService
          .getEjesValues(this.material.subject, this.material.grade)
          .then((data) => {
            this.ejes = data.ejes;
          });
      }
    },
    createApplication() {
      this.$emit("create-application", this.material.id);
    },
    openConfirmation(pIndex) {
      this.pageToDelete = pIndex;
      this.$refs["delete-page-confirmation"].show();
    },
    getOptions() {
      informationService.getCardSubjectsValues().then((data) => {
        this.subjects = data;
      });
      informationService.getPieGrades().then((data) => {
        this.grades = data.grades;
      }),
        informationService.getMaterialsRoles().then((data) => {
          this.roles = data.roles;
          this.material.profession = 6;
          // this.roles.forEach((role) => {
          //   if (data.chosen === role[0] && !this.material.id) {
          //     this.material.profession = role[0];
          //     this.changed = false;
          //   }
          // });
          this.$watch(
            "material",
            (newVal, oldVal) => {
              if (oldVal.id) {
                this.changed = true;
              }
            },
            { deep: true }
          );
        });
    },
    applyFilters() {
      this.editingFilters = false;
    },
    editFilters() {
      this.editingFilters = true;
    },
    attachImagePage(obj) {
      this.pondObject = obj;
      this.$refs["pond-modal-pages"].show();
    },
    closePondPages() {
      this.uploaded = [];
      this.$refs["pond-modal-pages"].hide();
    },
    associateImagePages() {
      let images = this.$refs["pond-pages"].getFiles();
      if (!this.pondObject) {
        return;
      }
      if (images.length < 1 && !this.pondObject.image) {
        this.$toasted.error("Debes subir una imagen para poder guardar");
        return;
      }
      if (this.pondObject.image && images.length === 0) {
        this.pondObject.image = null;
        return;
      }
      this.pondObject["images"].push({
        image: window.URL.createObjectURL(images[0].file),
        image_blob: images[0],
      });
      this.closePondPages();
    },
    openTextModalPage(page, text, eje) {
      this.selectedPage = page;
      this.$refs["text-manager"].openFromPage(page, text, eje);
    },
    manageRecording(obj) {
      // if(obj.audio instanceof Blob){
      //   obj.audio_blob = obj.audio
      //   obj.audio = window.URL.createObjectURL(obj.audio)
      // }
      this.$refs["audio-modal"].open(obj);
    },
    deleteAudio(obj) {
      obj.audio = null;
      obj.audio_blob = null;
      this.deletedPageAudio.push({
        page: obj.id,
      });
    },
    getEmptyPage() {
      return {
        index: this.material.pages.length,
        questions: [],
        text: null,
        images: [],
        audio: null,
        additional_instruction: "",
        eje: null,
      };
    },
    movePageUp(pindex) {
      if (pindex > 0) {
        this.material.pages.splice(
          pindex - 1,
          2,
          this.material.pages[pindex],
          this.material.pages[pindex - 1]
        );
      }
    },
    movePageDown(pindex) {
      if (pindex < this.material.pages.length - 1) {
        this.material.pages.splice(
          pindex,
          2,
          this.material.pages[pindex + 1],
          this.material.pages[pindex]
        );
      }
    },
    openQuestionListModalPage(page, text, eje) {
      console.log(eje);
      this.selectedPage = page;
      if (text) {
        this.$refs["question-manager"].questionList(
          page,
          this.material.subject,
          this.material.grade,
          text.id,
          eje
        );
      } else {
        this.$refs["question-manager"].questionList(
          page,
          this.material.subject,
          this.material.grade,
          "",
          eje
        );
      }
    },
    openNewQuestion(pindex) {
      this.$refs["question-manager"].createFromPage(
        pindex,
        this.material.subject,
        this.material.grade
      );
    },
    editQuestion(question, pindex, qindex) {
      this.$refs["question-manager"].editFromPage(question, pindex, qindex);
    },
    pushQuestion(question) {
      this.questions.unshift(question);
    },
    pushQuestionMaterial(question, page_idx) {
      this.pushedFamilies.push(question.family);
      this.material.pages[page_idx].questions.push(question);
    },
    replaceQuestionMaterial(question, page_idx, question_idx) {
      this.material.pages[page_idx].questions.splice(
        question_idx,
        1,
        JSON.parse(JSON.stringify(question))
      );
    },
    pushTextMaterial(text, page_idx) {
      this.material.pages[page_idx].text = text;
    },
    deletePage(pindex) {
      if (!pindex && this.pageToDelete !== null) {
        this.material.pages.splice(this.pageToDelete, 1);
        this.pageToDelete = null;
        this.$refs["delete-page-confirmation"].hide();
      } else {
        this.material.pages.splice(pindex, 1);
      }
    },
    deleteQuestion(pindex, qindex) {
      let page = this.material.pages[pindex];
      let familyID = page.questions[qindex].family;
      let pushedIndex = this.pushedFamilies.indexOf(familyID);
      this.pushedFamilies.splice(pushedIndex, 1);
      page.questions.splice(qindex, 1);
    },
    selectQuestion(question) {
      console.log(question);
      this.$refs["question-manager"].selectQuestion(
        question,
        true,
        this.selectedPage
      );
    },
    moveUp(pindex, idx) {
      if (idx > 0) {
        this.material.pages[pindex].questions.splice(
          idx - 1,
          2,
          this.material.pages[pindex].questions[idx],
          this.material.pages[pindex].questions[idx - 1]
        );
      }
    },
    moveDown(pindex, idx) {
      if (idx < this.material.pages[pindex].questions.length - 1) {
        this.material.pages[pindex].questions.splice(
          idx,
          2,
          this.material.pages[pindex].questions[idx + 1],
          this.material.pages[pindex].questions[idx]
        );
      }
    },
    togglePlayer(name) {
      console.log(this.$refs[`player-${name}`][0]["play"]());
      if (this.nowPlaying) {
        this.$refs[`player-${this.nowPlaying}`][0]["currentTime"] = 0;
      }
      this.nowPlaying = name;
      if (
        this.$refs[`player-${name}`] &&
        this.$refs[`player-${name}`][0]["paused"]
      ) {
        this.$refs[`player-${name}`][0]["play"]();
      } else {
        this.$refs[`player-${name}`][0]["currentTime"] = 0;
      }
    },
    pausePlayer(name) {
      console.log(this.$refs[`player-${name}`]);
      if (
        this.$refs[`player-${name}`] &&
        this.$refs[`player-${name}`][0]["paused"]
      ) {
        this.$refs[`player-${name}`][0]["play"]();
      } else {
        this.$refs[`player-${name}`][0]["pause"]();
      }
    },
    sendFiles(pageMultimedia) {
      pageMultimedia.forEach((p) => {
        let formData = new FormData();
        if (p.image) {
          console.log("imagen");
          formData.append("image", p.image);
        } else if (p.image_id) {
          formData.append("image_id", p.image_id);
        } else if (p.audio) {
          console.log("audio");
          formData.append(
            "audio",
            p.audio,
            `audio-${this.$moment().unix()}.wav`
          );
        } else {
          return;
        }
        console.log(this.material.pages[p.page]["id"]);
        formData.append("page", this.material.pages[p.page]["id"]);
        customMaterialService
          .uploadPageFiles(formData)
          .then((data) => console.log(data));
      });
    },
    backToList() {
      if (this.changed) {
        this.$refs["warning-modal"].show();
      } else {
        this.$emit("back-to-list");
      }
    },
    save() {
      let pagesMultimedia = [];
      let backgroundImage = "";
      if (!this.material.profession) {
        this.$toasted.error("Debe seleccionar una profesión!");
        return;
      }
      if (!this.material.grade) {
        this.$toasted.error("Debe seleccionar un curso!");
        return;
      }
      if (!this.material.subject) {
        this.$toasted.error("Debe seleccionar una asignatura!");
        return;
      }
      this.$refs["loading-modal"].show();

      if (this.material.background_image) {
        if (this.material.background_image instanceof Blob) {
          backgroundImage = this.material.background_image;
        } else {
          var xhr = new XMLHttpRequest();
          xhr.open("GET", this.material.background_image);
          xhr.responseType = "blob";
          xhr.onload = function () {
            backgroundImage = xhr.response;
          };
          xhr.send();
          backgroundImage = this.material.background_image;
        }
        delete this.material.background_image;
      }
      this.material.pages.forEach((page, pidx) => {
        page.images.forEach((image) => {
          if (image.image_blob) {
            pagesMultimedia.push({ page: pidx, image: image.image_blob.file });
          } else if (image.image instanceof Blob) {
            pagesMultimedia.push({ page: pidx, image: image.image.file });
          } else if (image.id && this.applications > 0) {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", image.image);
            xhr.responseType = "blob";
            xhr.onload = function () {
              pagesMultimedia.push({ page: pidx, image: xhr.response });
            };
            xhr.send();
          }
        });

        if (page.audio) {
          if (page.audio_blob) {
            pagesMultimedia.push({ page: pidx, audio: page.audio_blob });
          } else if (page.audio instanceof Blob) {
            pagesMultimedia.push({ page: pidx, audio: page.audio });
          } else {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", page.audio);
            xhr.responseType = "blob";
            xhr.onload = function () {
              pagesMultimedia.push({ page: pidx, audio: xhr.response });
            };
            xhr.send();
          }
          page.audio = null;
          delete page.audio_blob;
          delete page.changed;
        }
        delete page.audio;
        page.images = [];
      });

      // pages = this.material.pages
      // delete this.material.pages
      if (this.applications > 0 || this.mode === "creating") {
        console.log("creating");
        if (this.mode === "editing") {
          delete this.material.id;
          this.material.pages.forEach((page) => {
            delete page.id;
          });
        }
        customMaterialService.createMaterial(this.material).then((data) => {
          // this.material = data;
          this.sendFiles(pagesMultimedia);
          // this.$emit("back-to-list");
          this.$refs["loading-modal"].hide();
          this.$refs["save-material-modal"].close();
          this.$toasted.success("Material guardado exitosamente!");
          this.changed = false;
          if (backgroundImage) {
            this.uploadBackground(data.id, backgroundImage);
          }
          this.$emit("open-created-material", data.id);
        });
      } else {
        customMaterialService.updateMaterial(this.material).then((data) => {
          // this.material = data;
          this.sendFiles(pagesMultimedia);
          this.deleteImages(this.deletedPageImages);
          this.deleteAudios(this.deletedPageAudio);
          if (this.deletedBackground) {
            customMaterialService.deleteMaterialBackground(data.id);
          }
          // this.$emit("back-to-list");
          this.$refs["loading-modal"].hide();
          this.$refs["save-material-modal"].close();
          this.$toasted.success("Material guardado exitosamente!");
          this.changed = false;
          if (backgroundImage) {
            this.uploadBackground(data.id, backgroundImage);
          }
          this.$emit("open-created-material", data.id);
        });
      }
      console.log("guardando");
    },
    deleteImages(deletedImages) {
      deletedImages.forEach((imgPage) => {
        customMaterialService
          .deletePageImage(imgPage)
          .then((response) => console.log(response))
          .catch(() => this.$toasted.error("No se pudo eliminar imagen"));
      });
    },
    deleteAudios(deletedAudios) {
      deletedAudios.forEach((page) => {
        customMaterialService
          .deletePageAudio(page)
          .then((response) => console.log(response))
          .catch(() => this.$toasted.error("No se pudo eliminar Audio"));
      });
    },
    preview() {
      this.showPreview = !this.showPreview;
    },
    showModalSave() {
      this.$refs["save-material-modal"].open();
    },
    saveFromModal(data) {
      let { name, duration, style } = data;

      this.material.name = name;
      this.material.duration = duration;
      this.material.style = style;
      this.save();
    },
    // method to upload file to server calling method customMaterialService.uploadMaterialBackground
    uploadBackground(id, file) {
      let formData = new FormData();
      formData.append("background_image", file);
      customMaterialService
        .uploadMaterialBackground(id, formData)
        .then((data) => console.log(data));
    },

    openDeleteBackgroundModal() {
      this.$refs["delete-background-modal"].show();
    },
    deleteBackground() {
      if (this.material.background_image instanceof Blob) {
        delete this.material.background_image;
        this.customBG = "";
      } else {
        delete this.material.background_image;
        this.deletedBackground = true;
        this.customBG = "";
      }
    },
  },
};
</script>

<style>
.page-form {
  width: 100%;
  /* border: #F8B500 1px solid;
  border-radius: 25px;
  padding-bottom: 5em !important; */
  padding: 0.5em;
  margin: auto !important;
}

.circle {
  background: rgb(37, 137, 189);
  border-radius: 25px;
  color: white;
  height: 25px;
  font-weight: bold;
  width: 25px;
  display: table;
  margin: 0px 6px 0px 2px;
  text-align: center;
  padding: 1px;
}

.circle p {
  vertical-align: middle;
  display: table-cell;
  font-size: 0.9em;
}

.scissors {
  height: 30px;
  /* image height */
  width: 100%;
  margin: auto auto;
  background-size: 30px;
  background-image: url("http://i.stack.imgur.com/cXciH.png");
  background-repeat: no-repeat;
  background-position: right;
  position: relative;
  overflow: hidden;
}

.scissors:after {
  content: "";
  position: relative;
  top: 50%;
  display: block;
  border-top: 1px dashed rgb(122, 119, 119);
  margin-top: -1px;
  transform: scale(4);
}

.alternative {
  background-color: rgb(243, 243, 243);
  margin: 1em 0 1em 0;
  border-radius: 5px;
  padding: 1em;
  min-height: 3em;
  width: 60%;
}

.add-question-div {
  background-color: rgb(243, 243, 243);
  height: 80px;
  vertical-align: middle;
  padding: 25px 10px 25px 10px;
  border-radius: 5px;
}

.essay_question {
  background-color: rgb(243, 243, 243);
  border-radius: 5px;
  width: 80%;
  height: 80px;
  padding: 10px 10px 25px 10px;
}

.dictation_question {
  background-color: rgb(243, 243, 243);
  border-radius: 5px;
  height: 50px;
  padding: 10px 10px 25px 10px;
}

.question {
  width: 100%;
}

.alt_correct {
  border-color: rgb(105, 226, 105);
  border-style: dashed;
  border-width: thin;
}

.alt_incorrect {
  border-color: rgb(236, 65, 65);
  border-style: dashed;
  border-width: thin;
}

.correct-option {
  background-color: rgb(243, 243, 243);
  margin: 1em 0 1em 0;
  border-radius: 5px;
  padding: 1em;
  min-height: 3em;
  max-height: 1000px;
  border-color: rgb(105, 226, 105);
  border-style: dashed;
  border-width: thin;
}

.incorrect-option {
  background-color: rgb(243, 243, 243);
  margin: 1em 0 1em 0;
  border-radius: 5px;
  padding: 1em;
  min-height: 3em;
  max-height: 1000px;
  border-color: rgb(236, 65, 65);
  border-style: dashed;
  border-width: thin;
}

.options {
  margin: 0 2px 0 2px;
}

.image-section {
  width: 60%;
  max-width: 75%;
  align-items: center;
}

.audio-section {
  width: 60%;
  max-width: 75%;
}

.modern-style {
  background-image: url("../../../assets/img/backgrounds/bg-1.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}

.custom-style {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}
</style>
