<template>
    <b-modal ref="rubric-modal" hide-header hide-footer size="xl">
        <b-container>
            <b-table-simple responsive bordered hover fixed>
                <b-thead head-variant="light">
                    <b-tr>
                        <b-th :rowspan="2" style="width: 300px;">Indicadores</b-th>
                        <b-th :colspan="levels.length+1" :style="'width: '+(levels.length*300+100)+'px;'">Niveles de logro</b-th>
                    </b-tr>
                    <b-tr>
                        <b-th v-for="(level, lIndex) in levels" :key="lIndex+'-level'" style="width: 150px;">
                            <b-input v-model="level.name" />
                        </b-th>
                        <b-th>
                            <b-button variant="success" @click="levels.push({name: ''})">Añadir</b-button>
                        </b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="(indicator, iIndex) in indicators" :key="iIndex+'-indicator'">
                        <b-td>
                            <b-textarea v-model="indicator.name"/>
                        </b-td>
                        <b-td v-for="(level, lIndex) in levels" :key="lIndex+'-level'">
                            <b-textarea v-model="indicator.level_indications[lIndex]"/>
                            <b-input type="number" placeholder="Puntaje" v-model="indicator.level_scores[lIndex]"/>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>
                            <b-button variant="success" @click="indicators.push({name: '', level_indications: {}, level_scores: {}})">
                                Añadir
                            </b-button>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <b-row>
                <b-button pill variant="primary" @click="associateRubric">
                    Guardar
                </b-button>
            </b-row>
        </b-container>
    </b-modal>
</template>
<script>

export default {
    data() {
        return {
            levels: [],
            indicators: [],
            question: null
        }
    },
    methods: {
        open(question) {
            this.question = question;
            if (question.rubric) {
                this.indicators = question.rubric.indicators? question.rubric.indicators:[];
                this.levels = question.rubric.levels? question.rubric.levels:[];
            }
            else {
                this.levels = [];
                this.indicators = [];
            }
            this.$refs["rubric-modal"].show()
        },
        associateRubric() {
            if (this.question.rubric) {
                this.question.rubric.levels = this.levels;
                this.question.rubric.indicators = this.indicators;
            }
            else {
                this.question.rubric = {levels: this.levels, indicators: this.indicators}
            }
            this.$refs["rubric-modal"].hide()
        }
    }
}
</script>
<style lang="scss">

</style>
