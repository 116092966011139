import api from '@/services/api';

export default {
  getRedpieTutorialIndications() {
    return api.get('language_suggestions/get_redpie_tutorial_esp/').then(response => response.data);
  },
  getRedpieTutorialSlides() {
    return api.get('slides/get_redpie_tutorial_slides/').then(response => response.data);
  },
  getUtopieTutorialIndications() {
    return api.get('language_suggestions/get_utopie_tutorial_esp/').then(response => response.data);
  },
  getUtopieTutorialSlides() {
    return api.get('slides/get_utopie_tutorial_slides/').then(response => response.data);
  },
};
