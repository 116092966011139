<template>
  <div>
    <div v-if="period === 'daily'">
      <daily-planification
        :subject="subject"
        :grade="grade"
        :oa="oa"
        :ind="ind"
        :type="type"
        :condition="condition"
        @done="success"
      />
    </div>
    <div v-if="period === 'monthly'">
      <div v-for="(tag, tagidx) in tagsPile" :key="tagidx">
        <PlanificationOaTable
          :subject="subject"
          :grade="grade"
          :oa="tag"
          :type="type"
          :condition="condition"
          @tag-completed="addTag"
        />
      </div>
    </div>
    <b-row
      v-if="showDownload"
      class="w-100 download-button py-2 m-1"
      :class="download ? 'download-off' : 'download-on'"
      @click="downloadDocument()"
    >
      <b-col> Has terminado ¡Descarga tu archivo! </b-col>
      <b-col cols="2" class="col-sm-1">
        <v-icon :name="download ? 'download-white' : 'download-color'" />
      </b-col>
    </b-row>
    <FeedbackComponent v-if="showDownload" />

    <!-- Botón para que el usuario genere un PPT a partir de la planificación daily -->
    <!-- <b-button v-if="showDownload && period == 'daily' && !pptGenerated" @click="generatePptPlanification()" class="py-2 m-1" size="lg" block id="ppt-button" :disabled="generatingPpt">
      <span v-if="generatingPpt" :key="loadingMessagesIndex">
        <div>
          <b-spinner label="Loading..." :variant="info"></b-spinner>
        </div>
        {{ loadingMessages[loadingMessagesIndex] }}
      </span>
      <span v-else>Generar PPT con esta planificación</span>
    </b-button> -->

    <!-- <b-button
      v-if="pptGenerated && period == 'daily'"
      @click="downloadPptPlanification()"
      class="py-2 m-1"
      size="lg"
      block
      id="download-ppt-button"
      variant="primary"
    >
      <span>Descargar PPT</span>
    </b-button> -->
  </div>
</template>

<script>
import PlanificationOaTable from "./PlanificationOaTable.vue";
import DailyPlanification from "./DailyPlanification";
import chatBotService from "@/services/chatBotService";
import userService from "@/services/userService";
import FeedbackComponent from "./FeedbackComponent.vue";

export default {
  name: "PlanificationTable",
  components: {
    PlanificationOaTable,
    FeedbackComponent,
    DailyPlanification,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
    condition: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      tagsPile: [],
      currentIndex: 1,
      planificationReport: [],
      download: false,
      showDownload: false,
      dailyPlanification: {},
      generatingPpt: false,
      loadingMessages: [
        "Generando títulos...",
        "Generando diapositivas...",
        "Generando imágenes...",
        "Generando textos...",
        "Generando PPT...",
        "Insertando imágenes...",
        "Insertando textos...",
      ],
      loadingMessagesIndex: 0,
      pptGenerated: false,
      generatedPptData: null,
    };
  },

  methods: {
    addTag(planification) {
      this.planificationReport.push(planification);
      if (this.currentIndex < this.tags.length) {
        this.tagsPile.push(this.tags[this.currentIndex]);
        this.currentIndex += 1;
      } else {
        userService.get_user_data().then((data) => {
          this.$store.commit("login/setPoints", data);
        });
        this.showDownload = true;
      }
    },
    success(dailyPlanification) {
      this.dailyPlanification = dailyPlanification;
      this.dailyPlanification.oa = this.oa;
      this.dailyPlanification.ind = this.ind;
      this.dailyPlanification.type = this.type;
      this.showDownload = true;
    },
    // Función para generar y descargar el PPT de la planificación
    generatePptPlanification() {
      // Si el tipo de planificación no es daily, no generar el PPT
      if (this.period !== "daily") return;

      // Se activa el indicador de generación de PPT
      this.generatingPpt = true;

      // Se inicia un intervalo para mostrar mensajes de carga cada 3 segundos
      const loadingMessageInterval = setInterval(() => {
        this.loadingMessagesIndex =
          (this.loadingMessagesIndex + 1) % this.loadingMessages.length;
      }, 3000);

      // Se llama a la función para generar el PPT
      chatBotService
        .downloadPptPlanification(this.dailyPlanification)
        .then((response) => {
          this.generatedPptData = response;
          this.pptGenerated = true;

          // Se muestra un mensaje de éxito al usuario
          this.$toasted.success("¡Se ha generado el PPT!", {
            duration: 3000,
            position: "top-center",
          });
        })
        .catch((error) => {
          console.error("Error generando el PPT:", error);
          this.$toasted.error(
            "Se ha producido un error al generar el PPT. Por favor, inténtalo de nuevo."
          );
        })
        .finally(() => {
          // Se desactiva el indicador de generación de PPT
          this.generatingPpt = false;

          // Se limpia el intervalo de mensajes de carga
          clearInterval(loadingMessageInterval);
        });
    },
    downloadPptPlanification() {
      if (this.generatedPptData) {
        let filename = "";
        const disposition =
          this.generatedPptData.headers["Content-Disposition"];
        if (disposition && disposition.indexOf("attachment") !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
          }
        }
        const blob = new Blob([this.generatedPptData.data], {
          type: this.generatedPptData.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(link.href);
      } else {
        this.$toasted.error(
          "Se ha producido un error al generar el PPT. Por favor, inténtalo de nuevo."
        );
      }
    },
    downloadDocument() {
      this.download = true;
      chatBotService
        .downloadPlanification(this.reportContext)
        .then((response) => {
          console.log(response);
          var filename = "";
          var disposition = response.headers["Content-Disposition"];
          if (disposition && disposition.indexOf("attachment") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          var blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        });
    },
  },
  mounted() {
    if (this.tags && this.tags.length) {
      this.tagsPile = [this.tags[0]];
    }
  },
  computed: {
    subject() {
      return this.context.asignatura.name;
    },
    grade() {
      return this.context.curso.original_name;
    },
    tags() {
      return this.context.indicadores;
    },
    type() {
      return this.context.type.option;
    },
    period() {
      return this.context.period.option;
    },
    oa() {
      if (this.context.single_oa) {
        return this.context.single_oa;
      } else {
        return false;
      }
    },
    ind() {
      if (this.context.single_indicadores) {
        return this.context.single_indicadores;
      } else {
        return false;
      }
    },
    reportContext() {
      return {
        ASIGNATURA: this.subject,
        NIVEL: this.grade,
        type: this.type,
        period: this.period,
        PROFESOR:
          this.$store.state.login.first_name +
          " " +
          this.$store.state.login.last_name,
        planification: this.dailyPlanification,
        oas: this.planificationReport.map((tag, tagidx) => {
          return {
            name: tag.name.replace(/<(.*?)>/g, ""),
            indicadores: tag.indicators.map((ind, indidx) => {
              return {
                name: this.tagsPile[tagidx].options[indidx].name,
                actividades: ind.activities.map((activity) => {
                  return {
                    actividad: activity.text,
                    estrategia: activity.dua,
                    titulo: activity.title,
                  };
                }),
              };
            }),
          };
        }),
      };
    },
  },
};
</script>

<style>
/* CONTINUE BUTTON */
.download-button {
  border-radius: 5px;
  border: 2pt solid rgb(0, 0, 0) !important;
  font-family: "Ubuntu Medium", Arial, sans-serif;
  font-weight: bold; /* Aplica negrita al texto */
}
.download-on {
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}
.download-off {
  background-color: rgb(0, 185, 174);

  cursor: not-allowed;
}
</style>
