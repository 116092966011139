<template>
    <svg version="1.1" id="Layer_1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    :viewBox="viewbox"
    :width="width"
    :height="height"
    xml:space="preserve"
    class="custom-icon-button">
  
<g>
	<circle class="st0-clip" cx="43" cy="43" r="43"/>
	<path class="st1-clip" d="M40.6,65.5c-0.4,0-0.9-0.2-1.2-0.6c-0.7-0.7-0.7-1.8,0-2.5L61,40.8c3.8-3.8,3.8-10,0-13.8
		c-1.9-1.9-4.3-2.9-6.9-2.9c-2.6,0-5.1,1-6.9,2.9L23.9,50.2c-1.1,1.1-1.8,2.6-1.8,4.2c0,1.5,0.6,3,1.8,4.2c2.3,2.3,6.1,2.3,8.4,0
		l23.3-23.3c0.8-0.8,0.8-2.1,0-2.9s-2.1-0.8-2.9,0L30.9,54c-0.7,0.7-1.8,0.7-2.5,0c-0.7-0.7-0.7-1.8,0-2.5L50,29.8
		c2.2-2.2,5.8-2.2,8,0c2.2,2.2,2.2,5.8,0,8L34.8,61.1c-3.6,3.6-9.9,3.6-13.5,0c-3.7-3.7-3.7-9.8,0-13.5l23.3-23.3
		c2.5-2.5,5.9-3.9,9.5-3.9c3.6,0,6.9,1.4,9.5,4c5.2,5.2,5.2,13.7,0,18.9L41.8,65C41.5,65.4,41,65.5,40.6,65.5L40.6,65.5z"/>
</g>

  

  </svg>
  </template>
  
  <script>
  export default {
    name: 'ClipIcon',
    props: {
        viewbox: {
              type: String,
              default: "0 0 18 18"
        },
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },
    },
   
  }
  </script>
  
<style>
.st0-clip{
    fill:#F8B500;
}
.st1-clip{
    fill:#FFFFFF;
}
.custom-icon-button{
    cursor:pointer;
}
</style>