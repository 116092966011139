import api from '@/services/api';

export default {
  authenticate_password(payload) {
    return api.post('authenticate/password/', payload).then(response => response.data);
  },
  authenticateGoogle(payload) {
    return api.post('authenticate/google/', payload).then(response => response.data);
  },
  register(payload) {
    return api.post('register/', payload).then(response => response.data);
  },
  verify_token(payload) {
    return api.post('verify_token/', payload).then(response => response.data);
  },
  getInvitationData(uid) {
    return api.get(`invitation_data/${uid}/`).then(response => response.data);
  },
  registerWithoutMail(payload) {
    return api.post('invitation_register/', payload).then(response => response.data);
  },
  acceptInvitation(payload) {
    return api.post('accept_invitation/', payload).then(response => response.data);
  },
  rejectInvitation(payload) {
    return api.post('reject_invitation/', payload).then(response => response.data);
  },
  logout() {
    window.google.accounts.id.disableAutoSelect();
    return api.post('logout/').then(response => response.data);
  },
  adminAddUser(payload) {
    return api.post('admin_add_user/', payload).then(response => response.data);
  },
  adminChangePassword(payload) {
    return api.post('admin_change_password/', payload).then(response => response.data);
  },
  changePassword(payload) {
    return api.post('change_password/', payload).then(response => response.data);
  }
};
