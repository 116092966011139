<template lang="">
  <div>
    <!-- Agregamos un selector de edad para que el usuario elija la edad -->
    <label for="age-select">Seleccione la edad:</label>
    <select v-model="edad" id="age-select" @change="updateForm">
      <option value="" disabled>Seleccione...</option>
      <option value="4">4 años</option>
      <option value="7">7 años</option>
    </select>

    <!-- Mostramos el formulario solo si la edad ha sido seleccionada -->
    <div v-if="test.length > 0">
      <GenericTest
        :test="test"
        :test_id="test_id"
        :semester="semester"
        :required_fields="required"
        :answer="answer"
        :previous_answer="previous_answer"
      ></GenericTest>
    </div>
  </div>
</template>

<script>
import GenericTest from "./GenericTest";
export default {
  components: { GenericTest },
  props: ["test_id", "student_id", "previous_answer", "semester"],
  watch: {
    student_id() {
      this.answer.student_id = this.student_id;
    },
    test_id() {
      this.answer.test = this.test_id;
    },
    previous_answer: {
      handler(val) {
        this.$nextTick(() => {
          for (const [key, value] of Object.entries(val)) {
            this.answer.answers[key] = value;
          }
        });
      },
      deep: true,
    },
  },
  mounted() {
    if (this.previous_answer && Object.keys(this.previous_answer).length) {
      for (const [key, value] of Object.entries(this.previous_answer)) {
        this.$set(this.answer.answers, key, value);
      }
    }
  },
  data() {
    return {
      edad: "", // Variable para la edad seleccionada
      answer: {
        test: this.test_id,
        answers: {},
        student_id: this.student_id,
      },
      test: [], // Inicializamos test como un array vacío
    };
  },
  methods: {
    updateForm() {
      // Verificamos que la variable "edad" esté definida
      if (this.edad === "7") {
        this.test = [
          { type: "h3", label: "I NIVEL SEMANTICO" },
          {
            var: "evocacion_categorial_7",
            label: "1) Evocación categorial:",
            type: "number",
            min: "-50",
            required: true,
          },
          {
            var: "relacion_terminos_7",
            label: "2) Relación de Términos por el Uso:",
            type: "number",
            min: "-50",
            required: true,
          },
          {
            var: "definiciones_7",
            label: "3) Definiciones:",
            type: "number",
            min: "-50",
            required: true,
          },
          {
            var: "semejanzas_verbales_7",
            label: "4) Semejanzas Verbales:",
            type: "number",
            min: "-50",
            required: true,
          },
          {
            var: "asociacion_auditiva_7",
            label: "5) Asociación Auditiva:",
            type: "number",
            min: "-50",
            required: true,
          },
          {
            var: "sinonimia_antonimia_7",
            label: "6) Sinonimia / Antonimia:",
            type: "number",
            min: "-50",
            required: true,
          },
          {
            var: "comprension_lenguaje_7",
            label: "7) Comprensión del Lenguaje Figurado:",
            type: "number",
            min: "-50",
            required: true,
          },
          { type: "h3", label: "II NIVEL MORFOSINTACTICO" },
          {
            var: "construccion_oraciones_7",
            label: "8) Construcción de oraciones",
            type: "number",
            min: "-50",
            required: true,
          },
          {
            var: "completacion_oraciones_7",
            label: "9) Completación de oraciones",
            type: "number",
            min: "-50",
            required: true,
          },
          {
            var: "narraciones_7",
            label: "10) Narraciones:",
            type: "number",
            min: "-50",
            required: true,
          },
          {
            var: "descripciones_7",
            label: "11) Descripciones",
            type: "number",
            min: "-50",
            required: true,
          },
          {
            var: "argumentaciones_7",
            label: "12) Argumentaciones:",
            type: "number",
            min: "-50",
            required: true,
          },
        ];
      } else if (this.edad === "4") {
        this.test = [
          { type: "h3", label: "I NIVEL SEMANTICO" },
          {
            var: "evocacion_categorial_4",
            label: "1) Evocación categorial",
            type: "number",
            min: "0",
            max: "8",
            required: true,
          },
          {
            var: "definiciones_4",
            label: "2) Relación de Términos por el Uso",
            type: "number",
            min: "0",
            max: "8",
            required: true,
          },
          {
            var: "asociacion_auditiva_4",
            label: "3) Asociación Auditiva",
            type: "number",
            min: "0",
            max: "6",
            required: true,
          },
          {
            var: "contenidos_4",
            label: "4) Contenidos",
            type: "number",
            min: "0",
            max: "3",
            required: true,
          },
          { type: "h3", label: "II NIVEL MORFOSINTACTICO" },
          {
            var: "oracion_extensa_4",
            label: "5) Oración más extensa",
            type: "number",
            min: "0",
            max: "9",
            required: true,
          },
          {
            var: "completacion_oraciones_4",
            label: "6) Completación de oraciones",
            type: "number",
            min: "0",
            max: "4",
            required: true,
          },
          {
            var: "narraciones_4",
            label: "7) Narraciones",
            type: "number",
            min: "0",
            max: "9",
            required: true,
          },
          {
            var: "descripciones_4",
            label: "8) Descripciones",
            type: "number",
            min: "0",
            max: "9",
            required: true,
          },
          {
            var: "lenguaje_espontaneo_4",
            label: "9) Lenguaje espontáneo",
            type: "number",
            min: "0",
            max: "3",
            required: true,
          },
          { type: "h3", label: "III NIVEL COMPRENSIVO" },
          {
            var: "comprension_relato_4",
            label: "10) Comprensión de relato",
            type: "number",
            min: "0",
            max: "8",
            required: true,
          },
          {
            var: "resolucion_situaciones_4",
            label: "11) Resolución de situaciones",
            type: "number",
            min: "0",
            max: "4",
            required: true,
          },
          {
            var: "reconocimiento_absurdos_4",
            label: "12) Reconocimiento de absurdos",
            type: "number",
            min: "0",
            max: "3",
            required: true,
          },
          {
            var: "ejecucion_ordenes_4",
            label: "13) Ejecución de órdenes",
            type: "number",
            min: "0",
            max: "6",
            required: true,
          },
        ];
      } else {
        this.test = []; // Vaciar el test si no se selecciona edad válida
      }
    },
  },
  computed: {
    required() {
      return this.test
        .filter((elem) => elem.required == true)
        .map((elem) => elem.var);
    },
  },
};
</script>

<style lang=""></style>
