<template lang="">
  <div>
    <GenericTest
      :test="test"
      :semester="semester"
      :test_id="test_id"
      :answer="answer"
      :previous_answer="previous_answer"
    ></GenericTest>
  </div>
</template>
<script>
import GenericTest from "./GenericTest";
export default {
  components: { GenericTest },
  props: ["test_id", "student_id", "previous_answer", "semester"],
  watch: {
    student_id() {
      this.answer.student_id = this.student_id;
    },
    test_id() {
      this.answer.test = this.test_id;
    },
  },
  data() {
    return {
      answer: {
        test: this.test_id,
        answers: {},
        student_id: this.student_id,
      },
      test: [
        {
          type: "radio",
          label: "Sexo (marque uno):",

          var: "ONT_sexo",
          options: [
            { value: "cuyo sexo es masculino", text: "Masculino" },

            { value: "cuyo sexo es femenino", text: "Femenino" },
          ],
        },
        {
          type: "radio",
          label: "Modo principal de expresión (marque uno):",

          var: "ONT_modo_principal_expresion",
          options: [
            {
              value: "no cuenta con ningún medio de expresión",
              text: "Ninguno",
            },

            {
              value: "su principal medio de expresión son los gestos",
              text: "Gestos",
            },

            {
              value: "su principal medio de expresión es el habla",
              text: "Habla",
            },

            {
              value:
                "su principal medio de expresión es el lenguaje de signos gestuales o manuales",
              text: "Lenguaje de signos gestuales o manuales",
            },

            {
              value:
                "su principal medio de expresión es el tablero de símbolos o aparatos para la comunicación",
              text: "Tablero de símbolos o aparatos para la comunicación",
            },

            { value: "0", text: "Otro" },
          ],
        },
        {
          label: "Si la respuesta anterior es otro, Especifique:",

          type: "text",
          var: "if_ONT_modo_principal_expresion",
          maxlength: "100",
        },

        {
          type: "radio",
          label: "Estado legal (marque uno):",

          var: "ONT_estado_legal",
          options: [
            {
              value:
                "Sobre su tutoría, es un adulto plenamente resposnable ante la ley",
              text: "Adulto plentamente responsable ante la ley",
            },

            {
              value:
                "Sobre su tutoria, ésta es responsabilidad de su padre, su madre o un pariente",
              text: "Su padre, su madre o un pariente son sus tutores legales",
            },

            {
              value: "Su tutor legal es una persona que no es pariente suyo/a)",
              text: "Su tutor legal es una persoan que no es pariente suyo/a",
            },

            {
              value:
                "Su tutor legal es una institución pública o una asociación de afectados",
              text: "Su tutor legal es una isntitución pública o una asociación de afectados",
            },

            { value: "0", text: "Otro" },
          ],
        },
        {
          label: "Si la respuesta anterior es otro, Especifique:",

          type: "text",
          var: "if_ONT_estado_legal",
          maxlength: "100",
        },

        {
          type: "radio",
          label: "Diagnóstico principal (marque uno):",

          var: "ONT_categoria_diag_prin",
          options: [
            {
              value: "Hasta ahora, no presenta ningún diagnóstico principal",
              text: "Ninguno",
            },

            { value: "Su diagnóstico principal es autismo", text: "Autismo" },

            { value: "Su diagnóstico principal es ceguera", text: "Ceguera" },

            {
              value:
                "Su diagnóstico principal es lesión cerebral o neurológica, síndrome cerebral crónico",
              text: "Lesión cerebral o neurológica, síndrome cerebral crónico",
            },

            {
              value: "Su diagnóstico principal es parálisis cerebral",
              text: "Parálisis cerebral",
            },

            {
              value: "Su diagnóstico principal es drogodependencia",
              text: "Drogodependencia",
            },

            { value: "Su diagnóstico principal es sordera", text: "Sordera" },

            {
              value: "Su diagnóstico principal es epilepsia o convulsiones",
              text: "Epilepsia o convulsiones",
            },

            {
              value: "Su diagnóstico principal es discapacidad intelectual",
              text: "Discapacidad intelectual",
            },

            {
              value: "Su diagnóstico principal es discapacidades múltiples",
              text: "Discapacidades múltiples",
            },

            {
              value: "Su diagnóstico principal es problemas de salud física",
              text: "Problemas de salud física",
            },

            {
              value:
                "Su diagnóstico principal es enfermedad mental ( con diagnístico formal, psicosis esquzofrenia, etc)",
              text: "Su diagnóstico principal es Enfermedad mental ( con diagnístico formal, psicosis esquzofrenia, etc",
            },

            {
              value:
                "Su diagnóstico principal es problemas de salud mental situacional o episódico ( condiagnóstico formal); depresión ansiedad, angustia, temor, trastornos emocionales",
              text: "Problemas de salud mental situacional o episódico ( condiagnóstico formal); depresión ansiedad, angustia, temor, trastornos emocionales",
            },

            { value: "0", text: "Otro" },
          ],
        },
        {
          label: "Si tiene algún problema físico, Especifique:",

          type: "text",
          var: "if_ONT_categoria_diag_prin_fis",
          maxlength: "100",
        },

        {
          label: "Si la respuesta anterior es otro, Especifique:",

          type: "text",
          var: "if_ONT_categoria_diag_prin",
          maxlength: "100",
        },

        {
          type: "h3",
          label: "Otros diagnósticos (marque todos los que correspondan):",
        },
        {
          type: "multiple_checkbox",
          id: "ONT_categoria_diag_otros",
          var: "ONT_categoria_diag_otros",
          val: "no presenta ningún diagnóstico secundario",
          options: [
            {
              val: "no presenta ningún diagnóstico secundario",
              label: "Ninguno",
            },

            {
              val: ",además se le asocia un diagnóstico secundario de autismo",
              label: "Autismo",
            },

            {
              val: ",además se le asocia un diagnóstico secundario de ceguera",
              label: "Ceguera",
            },

            {
              val: ",además se le asocia un diagnóstico secundario de lesión cerebral o neurológica, síndrome cerebral crónico",
              label: "Lesión cerebral o neurológica, síndrome cerebral crónico",
            },

            {
              val: ",además se le asocia un diagnóstico secundario de parálisis cerebral",
              label: "Parálisis cerebral",
            },

            {
              val: ",además se le asocia un diagnóstico secundario de drogodependencia",
              label: "Drogodependencia",
            },

            {
              val: ",además se le asocia un diagnóstico secundario de sordera",
              label: "Sordera",
            },

            {
              val: ",además se le asocia un diagnóstico secundario de epilepsia o convulsiones",
              label: "Epilepsia o convulsiones",
            },

            {
              val: ",además se le asocia un diagnóstico secundario de discapacidad intelectual",
              label: "Discapacidad intelectual",
            },

            {
              val: ",además se le asocia un diagnóstico secundario de discapacidades múltiples",
              label: "Discapacidades múltiples",
            },

            {
              val: ",además se le asocia un diagnóstico secundario de problemas de salud física",
              label: "Problemas de salud física",
            },

            {
              val: ",además se le asocia un diagnóstico secundario de enfermedad mental ( con diagnístico formal, psicosis esquzofrenia, etc)",
              label:
                "Enfermedad mental ( con diagnístico formal, psicosis esquzofrenia, etc)",
            },

            {
              val: ",además se le asocia un diagnóstico secundario de problemas de salud mental situacional o episódico ( condiagnóstico formal); depresión ansiedad, angustia, temor, trastornos emocionales",
              label:
                "Problemas de salud mental situacional o episódico ( condiagnóstico formal); depresión ansiedad, angustia, temor, trastornos emocionales",
            },

            {
              val: "0",
              label: "Otro",
            },
          ],
        },
        {
          label: "Si tiene algún problema físico, Especifique:",

          type: "text",
          var: "if_ONT_categoria_diag_otros_fis",
          maxlength: "100",
        },

        {
          label: "Si la respuesta anterior es otro, Especifique:",

          type: "text",
          var: "if_ONT_categoria_diag_otros",
          maxlength: "100",
        },

        {
          label: "Escriba sus comentarios:",

          type: "textarea",
          var: "diag_comments",
          cols: "40",
          rows: "10",
          maxlength: "200",
        },

        {
          type: "radio",
          label: "Nivel de discapacidad intelectual (marque uno):",

          var: "ONT_nivel_discapacidad_int",
          options: [
            {
              value: "",
              text: "No tiene discapacidad intelectual",
            },

            { value: "límite (CI 70)", text: "Límite (CI 70" },

            { value: "leve (CI 50-69)", text: "Leve (CI 50-69" },

            { value: "moderado (CI 35-49)", text: "Moderado (CI 35-49" },

            {
              value: "grave o severo (CI 20-34)",
              text: "Grave o severo (CI 20-34",
            },

            {
              value: "profundo (CI por debajo de 20)",
              text: "Profundo (CI por debajo de 20",
            },

            {
              value: "desconocido /desarrollo Lento /de riesgo",
              text: "Desconocido /desarrollo Lento /de riesgo",
            },
          ],
        },
        {
          type: "radio",
          label: "Visión (marque uno):",

          var: "ONT_vision",
          options: [
            {
              value: "",
              text: "Visión (con o sin lentes",
            },

            {
              value:
                ". Tiene problemas de visión que limitan la lectura o su movilidad ",
              text: "Problemas de visión que limitan la lectura o su movilidad (con o sin lentes",
            },
          ],
        },
        {
          type: "radio",
          label: "Audición (marque uno):",

          var: "ONT_audicion",
          options: [
            {
              value: "",
              text: "Puede oir voces normales (con o sin audífonos)",
            },

            {
              value: ", sobre su audición puede oir sólo voces altas",
              text: "Puede oir sólo voces altas (con o sin audífonos)",
            },

            {
              value: ", su audición es pobre o nula (incluso con audífonos)",
              text: "Audición pobre o nula (incluso con audífonos)",
            },
          ],
        },
        {
          type: "radio",
          label: "Frecuencia de ataques epilépticos (marque uno):",

          var: "ONT_frecuencia_ataques",
          options: [
            {
              value: "",
              text: "Ninguno o controlados",
            },

            {
              value:
                ". Presenta ataques epilépticos a frecuencia de uno cada dos o más meses",
              text: "Uno cada dos o más meses",
            },

            {
              value: ". Presenta ataques epilépticos mensuales",
              text: "Mensualmente",
            },

            {
              value:
                ". Presenta ataques epilépticos a frecuencia de una o más a la semana",
              text: "Una o más a la semana",
            },
          ],
        },
        {
          type: "radio",
          label: "Salud (marque uno):",

          var: "ONT_salud",
          options: [
            {
              value: "",
              text: "No limita sus actividades diarias",
            },

            {
              value: ", su salud limita un poco sus actividades diarias",
              text: "Limita un poco sus actividades diarias",
            },

            {
              value: "su salud limita significativamente su vida diaria",
              text: "Muchas o significatias limitaciones en las actividades diarias",
            },
          ],
        },
        {
          type: "radio",
          label:
            "Precisa de la atención de un/a enfermero/a o un/a médico (marque uno):",

          var: "ONT_precisa_de_atencion",
          options: [
            {
              value:
                ". Precisa de atención de un médico o enfermero menos de una vez al mes",
              text: "Menos de una vez al mes",
            },

            {
              value:
                ". Precisa de atención de un médico o enfermero mensualmente",
              text: "Mensualmente",
            },

            {
              value:
                ". Precisa de atención de un médico o enfermero semanalmente",
              text: "Semanalmente",
            },

            {
              value:
                ". Precisa de atención de un médico o enfermero diariamente",
              text: "Diariamente",
            },

            {
              value:
                ". Precisa de atención de un médico o enfermero disponible  en cualquier momento, durante las 24 horas",
              text: "Necesita tener disponible atención médica en cualquier momento, durante las 24 horas",
            },
          ],
        },
        {
          type: "h3",
          label:
            "Medicamentos que toma actualmente (marque todos los que correspondan):",
        },

        {
          type: "multiple_checkbox",
          id: "ONT_medicamentos_toma",
          var: "ONT_medicamentos_toma",
          options: [
            {
              val: ", no toma medicamentos ",
              label: "Ninguno",
            },
            {
              val: ", toma medicamentos para problemas de salud física",
              label: "Problemas de salud física",
            },

            {
              val: ", toma medicamentos para para problemas anímicos o emocionales (por ejemplo ansiedad o insomnio)",
              label:
                "Para problemas anímicos o emocionales ( pro ejemplo ansiedad o insomnio)",
            },
            {
              val: ", toma medicamentos para epilepsia",
              label: "Para epilepsia",
            },
            {
              val: "0",
              label: "Otros",
            },
            {
              val: ", toma medicamentos, pero el entrevistado no conoce sus nombres o utilidad ",
              label: "Desconocidos",
            },
          ],
        },
        {
          label: "Si la respuesta anterior es por Salud Fisica, Especifique:",

          type: "text",
          var: "if_ONT_medicamentos_toma_fis",
          maxlength: "100",
        },

        {
          label:
            "Si la respuesta anterior es por Salud Emocional o Anímico, Especifique:",

          type: "text",
          var: "if_ONT_medicamentos_toma_anim",
          maxlength: "100",
        },

        {
          label: "Si la respuesta anterior es para Epilepcia, Especifique:",

          type: "text",
          var: "if_ONT_medicamentos_toma_epilep",
          maxlength: "100",
        },

        {
          label: "Si la respuesta anterior es otro, Especifique:",

          type: "text",
          var: "if_ONT_medicamentos_toma",
          maxlength: "100",
        },

        {
          type: "radio",
          label: "Brazo/mano (marque uno):",

          var: "ONT_brazo_mano",
          options: [
            {
              value: "",
              text: "No presencia limitaciones en las actividades diarias",
            },

            {
              value:
                ". Sobre la movilidad de sus brazos o manos, presenta algunas limitaciones en las actividades diarias",
              text: "Con algunas limitaciones en las actividades diarias",
            },

            {
              value:
                ". Sobre la movilidad de sus brazos o manos, presenta limitaciones en la mayoría de las actividades diarias",
              text: "Con limitaciones en la mayoría de las actividades diarias",
            },
          ],
        },
        {
          type: "radio",
          label: "Movilidad (marque uno):",

          var: "ONT_movilidad",
          options: [
            {
              value: "",
              text: "Camina sin problemas y sin ayudas",
            },

            {
              value:
                ", además generalmente se moviliza en silla de ruedas, o no camina",
              text: "Generalmente en silla de ruedas, o no camina",
            },

            {
              value:
                ", además se encuentra limitado a la cama durante la mayor parte del día",
              text: "limitado a la cama durante la mayor parte del día",
            },

            {
              value: ", además se encuentra limitado en cama todo el día",
              text: "En cama todo el día",
            },
          ],
        },
        {
          type: "h3",
          label:
            "Ayudas para su movilidad (marque todos los que correspondan):",
        },
        {
          type: "multiple_checkbox",
          id: "ONT_ayudas_para_mov",
          var: "ONT_ayudas_para_mov",
          val: ", por lo que no requiere ayudas adicionales para moverse",
          options: [
            {
              val: ", por lo que no requiere ayudas adicionales para moverse",
              label: "Ninguna",
            },

            {
              val: ", por lo que necesita de aparatos y ayudas (bastón, silla de ruedas, etc)",
              label: "Necesita aparatos y ayudas (bastón silla de ruedas, etc)",
            },

            {
              val: ", por lo que necesita de ayuda de otra persona de vez en cuando",
              label: "Necesita ayuda de otra persona de vez en cuando",
            },

            {
              val: ", por lo que necesita  de otra persona permanentemente",
              label: "Necesita de ayuda de otra persona permanentemente",
            },
          ],
        },
        {
          type: "h3",
          label:
            "Actividades durante el mes pasado (marque todas las que correspondan):",
        },

        {
          type: "multiple_checkbox",
          id: "actividades_s_o_1",
          var: "actividades_s_o_1",
          options: [
            {
              val: "durante el mes pasado, no tuvo actividades sociales o de ocio",
              label: "Ninguna",
            },
            {
              val: "sobre sus actividades sociales y de ocio, durante el mes pasado, habló por teléfono con parientes o amigos",
              label: "Habló con parientes o amigos",
            },
            {
              val: "sobre sus actividades sociales y de ocio, durante el mes pasado, visitó parientes",
              label: "Visitó parientes",
            },
            {
              val: "sobre sus actividades sociales y de ocio, durante el mes pasado, visitó amigos o vecinos fuera de su residencia",
              label: "Visitó amigos o vecinos fuer ade su residencia",
            },
            {
              val: "sobre sus actividades sociales y de ocio, durante el mes pasado, fue de compras o comió fuera (solo o con otros)",
              label: "Fue de compras o cmió afuera (solo o con otros)",
            },
            {
              val: "sobre sus actividades sociales y de ocio, durante el mes pasado, asistió a actividades fuera de casa",
              label: "Asistió a actividades sociales o de ocio fuera de casa",
            },
            {
              val: "sobre sus actividades sociales y de ocio, durante el mes pasado, dedicó tiempo a un hobby ",
              label:
                "Tiene un hobby ( ocupa su tiempo libre con una actividad personal)",
            },
            {
              val: "0",
              label: "Otra",
            },
          ],
        },
        {
          label: "Si la respuesta anterior es Otras, Especifique:",
          type: "text",
          var: "if_actividades_s_o_1",
          maxlength: "200",
        },

        {
          type: "h3",
          label:
            "Factores que limitan las actividades sociales (marque todas las que correspondan):",
        },

        {
          type: "multiple_checkbox",
          id: "actividades_s_o_2",
          var: "actividades_s_o_2",
          options: [
            {
              val: "actualmente, no hay factores que limiten sus actividades sociales",
              label: "Ninguno",
            },
            {
              val: "actualmente su falta de interés limita sus actividades sociales",
              label: "Carece de interés",
            },
            {
              val: "actualmente, nadie quiere acompañarle a actividades sociales o de ocio y eso limita bastante",
              label: "Nadie quiere acompañarle",
            },
            {
              val: "actualmente no cuenta con un transporte y esto limita bastante sus actividades sociales y de ocio",
              label: "No hay transporte",
            },
            {
              val: "actualmente no tiene dinero y eso limita bastante sus actividades socailes y de ocio",
              label: "No tiene dinero",
            },
            {
              val: "actualmente tiene problemas de salud y eso limita bastante sus actividades sociales y de ocio",
              label: "Tiene problemas de salud",
            },
            {
              val: "actualmente problemas de conducta y eso limita bastante sus actividaDES sociales y de ocio",
              label: "Presenta problemas de conducta",
            },
            {
              val: "0",
              label: "Otro",
            },
          ],
        },
        {
          label: "Si la respuesta anterior es Otras, Especifique:",

          type: "text",
          var: "if_actividades_s_o_2",
          maxlength: "200",
        },

        {
          label: "Escriba sus comentarios:",

          type: "textarea",
          var: "limit_comments",
          cols: "40",
          rows: "10",
          maxlength: "200",
        },

        {
          type: "radio",
          label: "Pasa objetos pequeños de una mano a la otra:",

          var: "destrezas_motoras_1",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label:
            "Se sienta solo/a manteniendo la cabeza y la espalda derechas y firmes (sin apoyo) durante treinta segundos:",

          var: "destrezas_motoras_2",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label:
            "Se mantiene de pie, al menos durante cinco segundos, apoyándose en muebles u otros objetos:",

          var: "destrezas_motoras_3",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label:
            "Mete objetos pequeños en recipientes y los vuelve a sacar después:",

          var: "destrezas_motoras_4",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label:
            "Hace rayas marcas o dibujos, con lápiz o con pinturas en una hoja de papel:",

          var: "destrezas_motoras_5",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label: "Escribe su nombre copiándolo de un modelo:",

          var: "destrezas_motoras_6",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label:
            "Levanta y lleva una bolsa llena de objetos por lo menos a una distancia de seis metros y la deposita en el suelo",

          var: "destrezas_motoras_7",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label:
            "En actividades grupales espera por lo menos dos minutos a que le llegue su turno (por ejemplo, espera su turno para patear una pelota o tomar un trago de agua):",

          var: "destrezas_sociales_1",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label:
            "Ofrece ayuda a otras personas (ejemplos: mantiene la puerta abierta para que pase una persona que tiene las manos ocupadas o recoge un objeto que se le ha caído a alguien):",

          var: "destrezas_sociales_2",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label:
            "Se comporta de una manera adecuada, sin llamar la atención de los demás, cuando está con sus amigos en lugares públicos (por ejemplo, cine, micro, centro comercial, etc). :",

          var: "destrezas_sociales_3",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label:
            "Cuenta de manera resumida una historia de forma que otra persona pueda entenderla (por ejemplo, un programa de tv o una película de cine):",

          var: "destrezas_sociales_4",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label:
            "Eecuerda o sabe cómo localizar números telefónicos y llama a sus amigos:",

          var: "destrezas_sociales_5",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label: "Traga alimentos blandos:",

          var: "destrezas_delavidap_1",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label: "Permanece sin orinarse al menos durante tres horas:",

          var: "destrezas_delavidap_2",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label: "Se quita el pantalón o la falda y la ropa interior:",

          var: "destrezas_delavidap_3",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label:
            "Hace sus necesidades cuando se sienta en el wc de acuerdo a un horario regular o cuando se le lleva al baño:",

          var: "destrezas_delavidap_4",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label: "Se pone camisetas o polerón, aunque sea al revés:",

          var: "destrezas_delavidap_5",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label: "Lava, seca y luego guarda los platos en su lugar:",

          var: "destrezas_delavidad_1",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label:
            "Arregla su dormitorio, lo cual incluye guardar su ropa, cambiar las sábanas, quitar el polvo y barrer el suelo:",

          var: "destrezas_delavidad_2",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label:
            "Prepara listas de compras de por lo menos seis productos para adquirir en una tienda de comestibles:",

          var: "destrezas_delavidad_3",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label:
            "Carga y maneja una lavadora, utilizando la cantidad de detergente y el programa apropiados:",

          var: "destrezas_delavidad_4",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label:
            "Planifica, prepara y sirve una comida completa para más de dos personas:",

          var: "destrezas_delavidad_5",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label:
            "Realiza pequeñas reparaciones de su ropa, como coserse un botón, o encarga a la persona adecuada para que lo haga:",

          var: "destrezas_delavidad_6",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label:
            "Maneja herramientas manuales eléctricas, potencialmente peligrosas, y aparatos de piezas móviles (por ejemplo, un taladro eléctrico, una batidora o una licuadora):",

          var: "destrezas_delavidad_7",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label:
            "Administra su dinero de forma que cubra los gastos de, por lo menos, una semana (ocio, transporte y otras necesidades):",

          var: "destrezas_delavidad_8",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label:
            "Rellena formularios y asiste a entrevistas de selección para buscar trabajo:",

          var: "destrezas_delavidad_9",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label:
            "Efectúa pagos de boletas (agua, luz, internet, etc) antes de que venza el plazo:",

          var: "destrezas_delavidad_10",
          options: [
            { value: "0", text: "Nunca o rara vez" },

            { value: "1", text: "La realiza, aunque no muy bien" },

            { value: "2", text: "La realiza bien" },

            { value: "3", text: "La realiza muy bien" },
          ],
        },
        {
          type: "radio",
          label: "Comportamiento autolesivo-daño a sí mismo (marque uno):",

          var: "ONT_comp_autoles1",
          options: [
            { value: "Presenta comportamiento autolesivo, ", text: "Si" },

            { value: " ", text: "No" },

            {
              label: "Si la respuesta anterior es Si, Especifique:",

              type: "text",
              var: "if_ONT_comp_autoles1",
              maxlength: "200",
            },
          ],
        },
        {
          type: "radio",
          label: "Frecuencia, ¿cuántas veces ocurre esto? (marque uno):",

          var: "ONT_comp_autoles2",
          options: [
            { value: " ", text: "Nunca" },

            {
              value: "menos de una vez al mes",
              text: "Menos de una vez al mes",
            },

            {
              value: "de una a tres veces al mes",
              text: "De una a tres veces al mes",
            },

            {
              value: "de una a seis veces por semana",
              text: "De una a seis veces por semana",
            },

            {
              value: "de una a diez veces al dia",
              text: "De una a diez veces al día",
            },

            {
              value: "una o más veces en una hora",
              text: "Una o más veces en una hora",
            },
          ],
        },
        {
          type: "radio",
          label: "Gravedad (marque uno):",

          var: "ONT_comp_autoles3",
          options: [
            { value: " ", text: "No es grave, no es un problema" },

            {
              value: "ligeramente grave, es un problema leve",
              text: "Ligeramente grave, es un problema leve",
            },

            {
              value: "medianamente grave, es un problema moderado",
              text: "Medianamente grave, es un problema moderado",
            },

            {
              value: "muy grave, es un problema grave",
              text: "Muy grave, es un problema grave",
            },

            {
              value: "extremadamente grave, es un problema crítico",
              text: "Extremadamente grave, es un problema crítico",
            },
          ],
        },
        {
          type: "radio",
          label: "Heteroagresividad o daño a otros (marque uno):",

          var: "ONT_comp_heteroagresiv1",
          options: [
            { value: "si", text: "Si" },

            { value: " ", text: "No" },

            {
              label: "Si la respuesta anterior es Si, Especifique:",

              type: "text",
              var: "if_ONT_comp_heteroagresiv1",
              maxlength: "200",
            },
          ],
        },
        {
          type: "radio",
          label: "Frecuencia, ¿cuántas veces ocurre esto? (marque uno):",

          var: "ONT_comp_heteroagresiv2",
          options: [
            { value: " ", text: "Nunca" },

            {
              value: "menos de una vez al mes",
              text: "Menos de una vez al mes",
            },

            {
              value: "de una a tres veces al mes",
              text: "De una a tres veces al mes",
            },

            {
              value: "de una a seis veces por semana",
              text: "De una a seis veces por semana",
            },

            {
              value: "de una a diez veces al dia",
              text: "De una a diez veces al día",
            },

            {
              value: "una o más veces en una hora",
              text: "Una o más veces en una hora",
            },
          ],
        },
        {
          type: "radio",
          label: "Gravedad (marque uno):",

          var: "ONT_comp_heteroagresiv3",
          options: [
            { value: " ", text: "No es grave, no es un problema" },

            {
              value: "ligeramente grave, es un problema leve",
              text: "Ligeramente grave, es un problema leve",
            },

            {
              value: "medianamente grave, es un problema moderado",
              text: "Medianamente grave, es un problema moderado",
            },

            {
              value: "muy grave, es un problema grave",
              text: "Muy grave, es un problema grave",
            },

            {
              value: "extremadamente grave, es un problema crítico",
              text: "Extremadamente grave, es un problema crítico",
            },
          ],
        },
        {
          type: "radio",
          label: "Destrucción de objetos (marque uno):",

          var: "ONT_comp_destruccion1",
          options: [
            { value: "si", text: "Si" },

            { value: " ", text: "No" },
          ],
        },
        {
          label: "Si la respuesta anterior es Si, Especifique:",

          type: "text",
          var: "if_ONT_comp_destruccion1",
          maxlength: "200",
        },

        {
          type: "radio",
          label: "Frecuencia, ¿cuántas veces ocurre esto? (marque uno):",

          var: "ONT_comp_destruccion2",
          options: [
            { value: " ", text: "Nunca" },

            {
              value: "menos de una vez al mes",
              text: "Menos de una vez al mes",
            },

            {
              value: "de una a tres veces al mes",
              text: "De una a tres veces al mes",
            },

            {
              value: "de una a seis veces por semana",
              text: "De una a seis veces por semana",
            },

            {
              value: "de una a diez veces al dia",
              text: "De una a diez veces al día",
            },

            {
              value: "una o más veces en una hora",
              text: "Una o más veces en una hora",
            },
          ],
        },
        {
          type: "radio",
          label: "Gravedad (marque uno):",

          var: "ONT_comp_destruccion3",
          options: [
            { value: " ", text: "No es grave, no es un problema" },

            {
              value: "ligeramente grave, es un problema leve",
              text: "Ligeramente grave, es un problema leve",
            },

            {
              value: "medianamente grave, es un problema moderado",
              text: "Medianamente grave, es un problema moderado",
            },

            {
              value: "muy grave, es un problema grave",
              text: "Muy grave, es un problema grave",
            },

            {
              value: "extremadamente grave, es un problema crítico",
              text: "Extremadamente grave, es un problema crítico",
            },
          ],
        },
        {
          type: "radio",
          label: "Conducta disruptiva (marque uno):",

          var: "ONT_comp_cond_disrup1",
          options: [
            { value: "si", text: "Si" },

            { value: " ", text: "No" },
          ],
        },
        {
          label: "Si la respuesta anterior es Si, Especifique:",

          type: "text",
          var: "if_ONT_comp_cond_disrup1",
          maxlength: "200",
        },

        {
          type: "radio",
          label: "Frecuencia, ¿cuántas veces ocurre esto? (marque uno):",

          var: "ONT_comp_cond_disrup2",
          options: [
            { value: " ", text: "Nunca" },

            {
              value: "menos de una vez al mes",
              text: "Menos de una vez al mes",
            },

            {
              value: "de una a tres veces al mes",
              text: "De una a tres veces al mes",
            },

            {
              value: "de una a seis veces por semana",
              text: "De una a seis veces por semana",
            },

            {
              value: "de una a diez veces al dia",
              text: "De una a diez veces al día",
            },

            {
              value: "una o más veces en una hora",
              text: "Una o más veces en una hora",
            },
          ],
        },
        {
          type: "radio",
          label: "Gravedad (marque uno):",

          var: "ONT_comp_cond_disrup3",
          options: [
            { value: " ", text: "No es grave, no es un problema" },

            {
              value: "ligeramente grave, es un problema leve",
              text: "Ligeramente grave, es un problema leve",
            },

            {
              value: "medianamente grave, es un problema moderado",
              text: "Medianamente grave, es un problema moderado",
            },

            {
              value: "muy grave, es un problema grave",
              text: "Muy grave, es un problema grave",
            },

            {
              value: "extremadamente grave, es un problema crítico",
              text: "Extremadamente grave, es un problema crítico",
            },
          ],
        },
        {
          type: "radio",
          label: "Hábitos atípicos y repetitivos-estereotipias (marque uno):",

          var: "ONT_comp_habit_atip1",
          options: [
            { value: "si", text: "Si" },

            { value: " ", text: "No" },
          ],
        },
        {
          label: "Si la respuesta anterior es Si, Especifique:",

          type: "text",
          var: "if_ONT_comp_habit_atip1",
          maxlength: "200",
        },

        {
          type: "radio",
          label: "Frecuencia, ¿cuántas veces ocurre esto? (marque uno):",

          var: "ONT_comp_habit_atip2",
          options: [
            { value: " ", text: "Nunca" },

            {
              value: "menos de una vez al mes",
              text: "Menos de una vez al mes",
            },

            {
              value: "de una a tres veces al mes",
              text: "De una a tres veces al mes",
            },

            {
              value: "de una a seis veces por semana",
              text: "De una a seis veces por semana",
            },

            {
              value: "de una a diez veces al dia",
              text: "De una a diez veces al día",
            },

            {
              value: "una o más veces en una hora",
              text: "Una o más veces en una hora",
            },
          ],
        },
        {
          type: "radio",
          label: "Gravedad (marque uno):",

          var: "ONT_comp_habit_atip3",
          options: [
            { value: " ", text: "No es grave, no es un problema" },

            {
              value: "ligeramente grave, es un problema leve",
              text: "Ligeramente grave, es un problema leve",
            },

            {
              value: "medianamente grave, es un problema moderado",
              text: "Medianamente grave, es un problema moderado",
            },

            {
              value: "muy grave, es un problema grave",
              text: "Muy grave, es un problema grave",
            },

            {
              value: "extremadamente grave, es un problema crítico",
              text: "Extremadamente grave, es un problema crítico",
            },
          ],
        },
        {
          type: "radio",
          label: "Conducta social ofensiva (marque uno):",

          var: "ONT_comp_cond_soc_of1",
          options: [
            { value: "si", text: "Si" },

            { value: " ", text: "No" },
          ],
        },
        {
          label: "Si la respuesta anterior es Si, Especifique:",

          type: "text",
          var: "if_ONT_comp_cond_soc_of1",
          maxlength: "200",
        },

        {
          type: "radio",
          label: "Frecuencia, ¿cuántas veces ocurre esto? (marque uno):",

          var: "ONT_comp_cond_soc_of2",
          options: [
            { value: " ", text: "Nunca" },

            {
              value: "menos de una vez al mes",
              text: "Menos de una vez al mes",
            },

            {
              value: "de una a tres veces al mes",
              text: "De una a tres veces al mes",
            },

            {
              value: "de una a seis veces por semana",
              text: "De una a seis veces por semana",
            },

            {
              value: "de una a diez veces al dia",
              text: "De una a diez veces al día",
            },

            {
              value: "una o más veces en una hora",
              text: "Una o más veces en una hora",
            },
          ],
        },
        {
          type: "radio",
          label: "Gravedad (marque uno):",

          var: "ONT_comp_cond_soc_of3",
          options: [
            { value: " ", text: "No es grave, no es un problema" },

            {
              value: "ligeramente grave, es un problema leve",
              text: "Ligeramente grave, es un problema leve",
            },

            {
              value: "medianamente grave, es un problema moderado",
              text: "Medianamente grave, es un problema moderado",
            },

            {
              value: "muy grave, es un problema grave",
              text: "Muy grave, es un problema grave",
            },

            {
              value: "extremadamente grave, es un problema crítico",
              text: "Extremadamente grave, es un problema crítico",
            },
          ],
        },
        {
          type: "radio",
          label: "Retraimiento o falta de atención (marque uno):",

          var: "ONT_comp_retraimento_falte1",
          options: [
            { value: "si", text: "Si" },

            { value: "", text: "No" },
          ],
        },
        {
          label: "Si la respuesta anterior es Si, Especifique:",

          type: "text",
          var: "if_ONT_comp_retraimento_falte1",
          maxlength: "200",
        },

        {
          type: "radio",
          label: "Frecuencia, ¿cuántas veces ocurre esto? (marque uno):",

          var: "ONT_comp_retraimento_falte2",
          options: [
            { value: " ", text: "Nunca" },

            {
              value: "menos de una vez al mes",
              text: "Menos de una vez al mes",
            },

            {
              value: "de una a tres veces al mes",
              text: "De una a tres veces al mes",
            },

            {
              value: "de una a seis veces por semana",
              text: "De una a seis veces por semana",
            },

            {
              value: "de una a diez veces al dia",
              text: "De una a diez veces al día",
            },

            {
              value: "una o más veces en una hora",
              text: "Una o más veces en una hora",
            },
          ],
        },
        {
          type: "radio",
          label: "Gravedad (marque uno):",

          var: "ONT_comp_retraimento_falte3",
          options: [
            { value: " ", text: "No es grave, no es un problema" },

            {
              value: "ligeramente grave, es un problema leve",
              text: "Ligeramente grave, es un problema leve",
            },

            {
              value: "medianamente grave, es un problema moderado",
              text: "Medianamente grave, es un problema moderado",
            },

            {
              value: "muy grave, es un problema grave",
              text: "Muy grave, es un problema grave",
            },

            {
              value: "extremadamente grave, es un problema crítico",
              text: "Extremadamente grave, es un problema crítico",
            },
          ],
        },
        {
          type: "radio",
          label: "Conductas no colaboradoras (marque uno):",

          var: "ONT_comp_cond_nocolab1",
          options: [
            { value: "si", text: "Si" },

            { value: " ", text: "No" },

            {
              label: "Si la respuesta anterior es Si, Especifique:",

              type: "text",
              var: "if_ONT_comp_cond_nocolab1",
              maxlength: "200",
            },
          ],
        },
        {
          type: "radio",
          label: "Frecuencia, ¿cuántas veces ocurre esto? (marque uno):",

          var: "ONT_comp_cond_nocolab2",
          options: [
            { value: " ", text: "Nunca" },

            {
              value: "menos de una vez al mes",
              text: "Menos de una vez al mes",
            },

            {
              value: "de una a tres veces al mes",
              text: "De una a tres veces al mes",
            },

            {
              value: "de una a seis veces por semana",
              text: "De una a seis veces por semana",
            },

            {
              value: "de una a diez veces al dia",
              text: "De una a diez veces al día",
            },

            {
              value: "una o más veces en una hora",
              text: "Una o más veces en una hora",
            },
          ],
        },
        {
          type: "radio",
          label: "Gravedad (marque uno):",

          var: "ONT_comp_cond_nocolab3",
          options: [
            { value: " ", text: "No es grave, no es un problema" },

            {
              value: "ligeramente grave, es un problema leve",
              text: "Ligeramente grave, es un problema leve",
            },

            {
              value: "medianamente grave, es un problema moderado",
              text: "Medianamente grave, es un problema moderado",
            },

            {
              value: "muy grave, es un problema grave",
              text: "Muy grave, es un problema grave",
            },

            {
              value: "extremadamente grave, es un problema crítico",
              text: "Extremadamente grave, es un problema crítico",
            },
          ],
        },
        {
          type: "radio",
          label:
            "Reacción a la conducta problema en cualquier categoría (marque uno):",

          var: "ONT_reaccion_conducta",
          options: [
            {
              value:
                "La persona entrevistada indica que frente a dificultades de conducta, reacciona como si no hubiera problemaa alguno",
              text: "No hay problemas de conducta",
            },

            {
              value:
                "La persona entrevistada indica que frente a dificultades de conducta, no reacciona o no le consuelan",
              text: "No hacen nada o no le consuelan",
            },

            {
              value:
                "La persona entrevistada indica que frente a dificultades de conducta, le pide que abandone ese comportamiento, razonando con él o con ella",
              text: "Le piden que abandone la conducta, razonando con él o con ella",
            },

            {
              value:
                "La persona entrevistada indica que frente a dificultades de conducta deliberadamente ignoran esta(s) conducta(s), reforzando otras más positivas",
              text: "Deliberadamente ignoran esta(s) conducta(s), reforzando otras más positivas",
            },

            {
              value:
                "La persona entrevistada indica que frente a dificultades de conducta, le piden que se enmiende o corrija la situación",
              text: "Le piden a la persona que se enmiende o corrija la situación",
            },

            {
              value:
                "La persona entrevistada indica que frente a dificultades de conducta, modifican o reestructuran el entorno, cambian de materiales",
              text: "Modifican o reestructuran el entorno, cambian de materiales",
            },

            {
              value:
                "La persona entrevistada indica que frente a dificultades de conducta, pide que salga de la habitación o del aula, la sala, etc o que se siente en otro lugar",
              text: "Piden que la persona salga de la habitación o del aula, la sala, etc, o que se siente en otro lugar ",
            },

            {
              value:
                "La persona entrevistada indica que frente a dificultades de conducta, le retira ciertos privilegios obtenidos anteriormente",
              text: "Le retiran ciertos privilegios obtenidos anteriormente",
            },

            {
              value:
                "La persona entrevistada indica que frente a dificultades de conducta, le dirige de manera física, le traslada o le ponen límites, físicamente",
              text: "Le dirigen de manera física, le trasladan o le ponen límites físicamente",
            },

            {
              value:
                "La persona entrevistada indica que frente a dificultades de conducta, se necesita ayuda para controlarle ( son precisas dos o más personas para ello)",
              text: "Se necesita ayuda para controlarle (son precisas dos o más personas para ello",
            },

            { value: " ", text: "Otras" },
          ],
        },
        {
          label: "Si la respuesta anterior es Otras, Especifique:",

          type: "text",
          var: "if_ONT_reaccion_conducta_otras",
          maxlength: "200",
        },

        {
          type: "radio",
          label: "Residencia actual (marque uno):",

          var: "residencia_actual",
          options: [
            {
              value: ", actualmente reside con padres o familiares",
              text: "Con padres o familiares",
            },

            {
              value:
                ", actualmente reside con padres adoptivos o familia sustituta",
              text: "Padres adoptivos o familia sustituta",
            },

            {
              value: ", actualmente reside de forma independiente en su casa",
              text: "Independiente en su casa",
            },

            {
              value:
                ", actualmente reside de forma semi-independiente con supervisión regular",
              text: "Semi independiente con supervisión regular",
            },

            {
              value: ", actualmente reside en un servicio residencial",
              text: "Servicio residencial",
            },

            { value: "otro", text: "Otro" },
          ],
        },
        {
          type: "radio",
          label:
            "Recomendación de cambio, dentro de los 2 años siguientes (marque uno):",

          var: "residencia_recomendacion",
          options: [
            {
              value: ", actualmente reside con padres o familiares",
              text: "Con padres o familiares",
            },

            {
              value:
                ", actualmente reside con padres adoptivos o familia sustituta",
              text: "Padres adoptivos o familia sustituta",
            },

            {
              value: ", actualmente reside de forma independiente en su casa",
              text: "Independiente en su casa",
            },

            {
              value:
                ", actualmente reside de forma semi-independiente con supervisión regular",
              text: "Semi independiente con supervisión regular",
            },

            {
              value: ", actualmente reside en un servicio residencial",
              text: "Servicio residencial",
            },

            { value: "otro", text: "Otro" },
          ],
        },
        {
          label:
            "Si la respuesta anterior (o la anterior a esta) es Otro, Especifique:",

          type: "text",
          var: "if_residencia_otras",
          maxlength: "200",
        },

        {
          label: "Escriba sus comentarios:",

          type: "textarea",
          var: "residencia_comments",
          cols: "40",
          rows: "10",
          maxlength: "200",
        },

        {
          type: "radio",
          label: "Servicios de día actuales (marque uno):",

          var: "servicios_dia_actual",
          options: [
            { value: ", no asiste a ningún tipo de escuela", text: "Ninguno" },

            { value: ", asiste a jardin infantil", text: "Jardin infantil" },

            {
              value: ", asiste a una escuela o centro educativo ordinario",
              text: "Escuela o centro educativo ordinario",
            },

            {
              value:
                ", asiste a una escuela o centro educativo ordinario- con servicio de educación especial",
              text: "Escuela o centro educativo ordinario- con servicios de educación especial",
            },

            {
              value:
                ", asiste a una escuela o centro educativo ordinario- aula de educación especial",
              text: "Escuela o centro educativo ordinario- aula de educación especial",
            },

            {
              value: ", asiste a una centro de educación especial",
              text: "Centro de ediucación especial",
            },

            {
              value:
                ", asiste a una centro de día ( actividades personales y sociales)",
              text: "Centro de día ( actividades personales y sociales",
            },

            {
              value: ", asiste a una centro ocupacional",
              text: "Centro ocupacional",
            },

            {
              value:
                ", asiste a una puesto de trabajo ordinario con supervisión y apoyo",
              text: "Puesto de trabajo ordinario con supervisión y apoyo",
            },

            {
              value: ", asiste a una empleo ordinario",
              text: "Empleo ordinario",
            },

            { value: "0", text: "Otro" },
          ],
        },
        {
          type: "radio",
          label:
            "Recomendación de cambio dentro de los 2 años siguientes (marque uno):",

          var: "servicios_dia_recomendacion",
          options: [
            { value: ", no asiste a ningún tipo de escuela", text: "Ninguno" },

            { value: ", asiste a jardin infantil", text: "Jardin infantil" },

            {
              value: ", asiste a una escuela o centro educativo ordinario",
              text: "Escuela o centro educativo ordinario",
            },

            {
              value:
                ", asiste a una escuela o centro educativo ordinario- con servicio de educación especial",
              text: "Escuela o centro educativo ordinario- con servicios de educación especial",
            },

            {
              value:
                ", asiste a una escuela o centro educativo ordinario- aula de educación especial",
              text: "Escuela o centro educativo ordinario- aula de educación especial",
            },

            {
              value: ", asiste a una centro de educación especial",
              text: "Centro de ediucación especial",
            },

            {
              value:
                ", asiste a una centro de día ( actividades personales y sociales)",
              text: "Centro de día ( actividades personales y sociales",
            },

            {
              value: ", asiste a una centro ocupacional",
              text: "Centro ocupacional",
            },

            {
              value:
                ", asiste a una puesto de trabajo ordinario con supervisión y apoyo",
              text: "Puesto de trabajo ordinario con supervisión y apoyo",
            },

            {
              value: ", asiste a una empleo ordinario",
              text: "Empleo ordinario",
            },

            { value: "0", text: "Otro" },
          ],
        },
        {
          label:
            "Si la respuesta anterior (o la anterior a esta) es Otro, Especifique:",

          type: "text",
          var: "if_servicios_dia_otras",
          maxlength: "200",
        },

        {
          type: "h3",
          label:
            "Servicios de apoyo que utiliza actualmente (marque todos los que correspondan):",
        },

        {
          type: "multiple_checkbox",
          id: "servicio_apoyo_actual",
          var: "servicio_apoyo_actual",
          options: [
            {
              val: ", actualmente no recibe ningún tipo de apoyo",
              label: "Ninguno",
            },
            {
              val: ", actualmente recibe valoración, orientación y seguimiento",
              label: "Valoración, orientaión y seguimiento",
            },
            {
              val: ", actualmente recibe intervención o estimulación temprana",
              label: "Intervención o estimulación temprana",
            },
            {
              val: ", actualmente recibe psicopedagóga/o",
              label: "Psicopedagóga/o",
            },
            {
              val: ", actualmente recibe terapia ocupacional",
              label: "Terapia ocupacional",
            },
            {
              val: ", actualmente recibe fisioterapia",
              label: "Fisioterapia",
            },
            {
              val: ", actualmente recibe cuidados médicos especializados (de forma continua)",
              label: "Cuidados especializados (de forma continua)",
            },
            {
              val: ", actualmente recibe servicios especializados de salud mental",
              label: "Servicios especializados de salud mental",
            },
            {
              val: ", actualmente recibe servicios de apoyo en el hogar",
              label: "Servicios de apoyo en el hogar",
            },
            {
              val: ", actualmente recibe servicios especiales de transporte",
              label: "Servicios especiales de transporte",
            },
            {
              val: "0",
              label: "Otro",
            },
          ],
        },
        {
          type: "h3",
          label:
            "Recomendación de cambio, dentro de los 2 años siguientes (marque todos los que correspondan):",
        },

        {
          type: "multiple_checkbox",
          id: "servicio_apoyo_recomendacion",
          var: "servicio_apoyo_recomendacion",
          options: [
            {
              val: ", actualmente no recibe ningún tipo de apoyo",
              label: "Ninguno",
            },
            {
              val: ", actualmente recibe valoración, orientación y seguimiento",
              label: "Valoración, orientaión y seguimiento",
            },
            {
              val: ", actualmente recibe intervención o estimulación temprana",
              label: "Intervención o estimulación temprana",
            },
            {
              val: ", actualmente recibe psicopedagóga/o",
              label: "Psicopedagóga/o",
            },
            {
              val: ", actualmente recibe terapia ocupacional",
              label: "Terapia ocupacional",
            },
            {
              val: ", actualmente recibe fisioterapia",
              label: "Fisioterapia",
            },
            {
              val: ", actualmente recibe cuidados médicos especializados (de forma continua)",
              label: "Cuidados especializados (de forma continua)",
            },
            {
              val: ", actualmente recibe servicios especializados de salud mental",
              label: "Servicios especializados de salud mental",
            },
            {
              val: ", actualmente recibe servicios de apoyo en el hogar",
              label: "Servicios de apoyo en el hogar",
            },
            {
              val: ", actualmente recibe servicios especiales de transporte",
              label: "Servicios especiales de transporte",
            },
            {
              val: "0",
              label: "Otro",
            },
          ],
        },
        {
          label:
            "Si la respuesta anterior (o la anterior a esta) es Otro, Especifique:",

          type: "text",
          var: "if_servicio_apoyo_otras",
          maxlength: "200",
        },

        {
          label: "Escriba sus comentarios:",

          type: "textarea",
          var: "reaccion_comments",
          cols: "40",
          rows: "10",
          maxlength: "200",
        },

        {
          type: "radio",
          label:
            "¿Proporcionan estos resultados una correcta representación del funcionamiento actual de la persona evaluada?:",

          var: "result",
          options: [
            { value: " ", text: "Si" },

            { value: "no", text: "No" },
          ],
        },
        {
          label:
            "Si la respuesta es no, ¿Cuáles son sus motivos para dudar de los resultados obtenidos en este ICAP?:",

          type: "textarea",
          var: "if_result_no",
          cols: "40",
          rows: "10",
          maxlength: "200",
        },
      ],
    };
  },
};
</script>
<style lang=""></style>
