<template>
  <b-modal ref="warning-empty-modal" centered hide-footer hide-header rounded-0>
    <div class="m-2">
      <b-row>
        <b-col class="text-center">
          <p>¡{{ warning }}!</p>
          <p>{{ message }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <b-button
            class="ml-0"
            pill
            variant="danger"
            @click="$refs['warning-empty-modal'].hide()"
          >
            <b>Cerrar</b>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "EmptyWarningModal",
  props: {
    warning: String,
    message: String,
  },
  methods: {
    show() {
      this.$refs["warning-empty-modal"].show();
    },
  },
};
</script>

<style></style>
