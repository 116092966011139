<template>
  <b-modal hide-footer hide-header size="xl" centered ref="modal">
    <b-container>
        <b-row class="pt-2">
            <b-col cols="4" class="text-right">
                Seleccione un colegio:
            </b-col>
            <b-col cols="8">
                <multiselect
                    v-model="selectedSchool"
                    :options="schools"
                    placeholder="Selecciona un colegio"
                    label="name"
                    track-by="name"
                    @input="getGrades"
                ></multiselect>
                <!-- <b-form-select v-model="selectedSchool" @change="getGrades">
                    <b-form-select-option :value="''" disabled>
                        Seleccione un colegio
                    </b-form-select-option>
                    <b-form-select-option
                        v-for="(school, index) in schools" :key="'school-'+index"
                        :value="school.id"
                    >
                        {{school.name}}
                    </b-form-select-option>
                </b-form-select> -->
            </b-col>
        </b-row>
        <b-row class="pt-2">
            <b-col cols="4" class="text-right">
                Seleccione un año:
            </b-col>
            <b-col cols="8">
                <b-select
                    v-model="year"
                    :options="years"
                    placeholder="Selecciona un año"
                />
            </b-col>
        </b-row>
        <b-row class="pt-2">
            <b-col cols="4" class="text-right">
                Seleccione los cursos:
            </b-col>
            <b-col cols="8">
                <multiselect
                    v-model="grades"
                    :options="gradeListByLevel"
                    :multiple="true"
                    placeholder="Añadir más cursos"
                    select-label="Seleccionar"
                    selectedLabel="Seleccionado"
                    deselectLabel="Presiona INTRO para remover"
                    group-values="grades"
                    group-label="name"
                    :group-select="true"
                    label="name"
                    track-by="name"
                >
                    <span slot="noOptions">No puedes agregar mas cursos</span>
                    <span slot="noResult">No se ha encontrado ningún curso</span>
                </multiselect>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" class="text-right">
                <b-button :disabled="grades.length === 0" pill variant="primary" @click="associateTest">
                    Guardar
                </b-button>
            </b-col>
        </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import redpieService from "@/services/redpieService"
import customMaterialService from "@/services/customMaterialService";

export default {
    created() {
        // this.getAllSchools()
    },
    data() {
        return {
            schools: [],
            testID: '',
            selectedSchool: null,
            grades: [],
            gradeList: [],
            gradeListByLevel: [],
            year: 2022,
            years: [2021, 2022]
        }
    },
    methods: {
        open(testID) {
            this.testID = testID;
            this.selectedSchool = null;
            this.grades = [];
            this.gradeList = [];
            this.$refs.modal.show();
        },
        getAllSchools() {
            redpieService.getAllSchools().then(
                data => {
                    this.schools = data;
                }
            )
        },
        getGrades() {
            customMaterialService.getGrades(this.selectedSchool.id, this.year).then(
                data => {
                    this.gradeList = data;
                    this.gradeListByLevel = [];
                    for (const grade of this.gradeList){
                        let element = this.gradeListByLevel.find(elem => elem.grade == grade.grade)
                        if (element !== undefined) {
                            element.grades.push(grade);  
                        } 
                        else {
                            this.gradeListByLevel.push({name: grade.grade_name, grade: grade.grade, grades: [grade, ]})
                        }
                    }
                    customMaterialService.getGradeMaterials(this.testID, this.selectedSchool.id).then(
                        data => {
                            this.grades = this.gradeList.filter(elem => data.includes(elem.id));
                        }
                    )
                }
            )
        },
        associateTest() {
            let gradeIds = this.grades.map(elem => elem.id);
            customMaterialService.associateMaterialSchool({test: this.testID, school: this.selectedSchool.id, grades: gradeIds, year: this.year}).then(
                data => {
                    this.$toasted.success(data.message)
                }
            )
        },
    }
}
</script>

<style scoped>

</style>