    import { Model } from '@vuex-orm/core'

export class Product extends Model {
    static entity = 'products'

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(''),
            badge_text: this.attr(''),
            images: this.attr([]),
            price: this.attr(''),
            special_price: this.attr(''),
            rating: this.attr(0),
            review_count: this.attr(0),
            video: this.attr(null),
        }
    }

    static apiConfig = {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            "Content-Type": "application/json"
            
        },
        baseURL: `${process.env.VUE_APP_API}/marketplace`,
        actions: {
            fetch: {
                method: 'GET',
                url: '/products/',
            },
            fetchDetails(id) {
                return this.get(`products/${id}`)
            },
            contact(name, email, message, product, startup){
                return this.post('/contact/send/', {
                    name: name, 
                    email: email, 
                    message: message,
                    product: product,
                    startup: startup,
                })
            }
        }
    }
}

export class Review extends Model{
    static entity = 'reviews'
    static fields() {
        return {
            id: this.attr(null),
            created_by: this.attr(''),
            text: this.attr(''),
            score: this.attr(0),
            product: this.attr(null)
        }
    }

    static apiConfig = {
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        baseURL: `${process.env.VUE_APP_API}/marketplace`,
        actions: {
            save(r) {
                return this.post('/reviews/', r)
            }
        }
    }
}