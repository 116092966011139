<template>
    <b-modal hide-footer ref="rubric-modal" size="xl">
        <b-container>
            <b-table-simple responsive bordered hover fixed>
                <b-thead head-variant="light">
                    <b-tr>
                        <b-th :rowspan="2" style="width: 300px;">Indicadores</b-th>
                        <b-th :colspan="levels.length" :style="'width: '+(levels.length*300)+'px;'">Niveles de logro</b-th>
                    </b-tr>
                    <b-tr>
                        <b-th v-for="(level, lIndex) in levels" :key="lIndex+'-level'" style="width: 300px;">
                            <span>{{level.name}}</span>
                        </b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="(indicator, iIndex) in indicators" :key="iIndex+'-indicator'">
                        <b-td>
                            <span>{{indicator.name}}</span>
                        </b-td>
                        <b-td v-for="(level, lIndex) in levels" :key="lIndex+'-level'"
                                :variant="
                                    chosen.filter(a => a.indicator_id === indicator.id).length && 
                                    chosen.filter(a => a.indicator_id === indicator.id)[0].level_id === level.id? 
                                        'success':''"
                                @click="indicatorSelected(level.id, indicator.id, indicator.level_scores[lIndex])">
                            <span>{{indicator.level_indications[lIndex]}}</span>
                            <br>
                            <strong style="background-color: #F0F8FF" class="w-100">Puntaje: {{indicator.level_scores[lIndex]}}</strong>
                        </b-td>
                    </b-tr>
                    <b-tr>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            Puntaje acumulado: {{score}} / {{maxScore}}
            <b-row>
                <b-col class="text-right">
                    <b-button pill variant="primary" @click="saveAndClose">
                        Guardar
                    </b-button>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>
<script>
export default {
    data() {
        return {
            levels: [],
            indicators: [],
            chosen: [],
            answerID: "",
            maxScore: 0
        }
    },
    methods: {
        open(rubric, chosenIndicators, answerID) {
            this.levels = rubric.levels;
            this.indicators = rubric.indicators;
            this.maxScore = 0;
            this.indicators.forEach(indicator => {
                this.maxScore += Math.max(...Object.values(indicator.level_scores))
            });
            this.answerID = answerID;
            this.chosen = chosenIndicators;
            this.$refs['rubric-modal'].show();
        },
        indicatorSelected(levelID, indicatorID, score) {
            if (this.chosen.filter(a => a.indicator_id === indicatorID).length) {
                if (this.chosen.filter(a => a.indicator_id === indicatorID)[0].level_id === levelID) {
                    this.chosen.filter(a => a.indicator_id === indicatorID)[0].level_id = null
                    this.chosen.filter(a => a.indicator_id === indicatorID)[0].score = 0
                }
                else {
                    this.chosen.filter(a => a.indicator_id === indicatorID)[0].level_id = levelID
                    this.chosen.filter(a => a.indicator_id === indicatorID)[0].score = score
                }
            }
            else {
                this.chosen.push({indicator_id: indicatorID, level_id: levelID, score: score})
            }
        },
        saveAndClose() {
            this.$emit('set-indicators', this.chosen, this.answerID, this.score, this.maxScore);
            this.chosen = []
            this.$refs['rubric-modal'].hide();
        }
    },
    computed: {
        score() {
            var sc = 0;
            this.chosen.forEach(
                chose => {
                    sc += chose.score
                }
            )
            return sc
        }
    }
}
</script>
<style lang="scss">

</style>