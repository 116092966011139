<template>
<b-modal ref="link-modal" hide-footer hide-header centered size="lg">
      <div class="m-2 p-2">
        <div class="h4 mx-auto text-center">Generar links para prueba <i>"{{test.name}}"</i></div>
        <b-card no-body class="mt-3">
            <b-form @submit.prevent="submitLink">
                <b-form-group label="RUT Estudiante *" description="Ingresa RUT sin puntos y con guión"
                                label-size="sm" label-for="rut"
                >
                    <b-input placeholder="Ingresa un RUT" v-model="rut" id="rut" required/>
                </b-form-group>
                <b-form-group label="Nombre Estudiante *" label-size="sm" label-for="name"
                >
                    <b-input v-model="name" id="name" required/>
                </b-form-group>
                <b-form-group label="Correo electrónico estudiante" label-size="sm" label-for="email">
                    <b-input id="email" placeholder="correo@ejemplo.cl" type="email" v-model="email"/>
                </b-form-group>
                <!-- <b-form-row class="m-3">
                    <b-form-radio v-model="videocall_kind" :value="1">
                        Aplicación virtual - Usaré el sistema de videollamadas de la plataforma
                    </b-form-radio>
                </b-form-row> -->
                <b-form-row class="m-3">
                    <b-form-radio v-model="videocall_kind" :value="2">
                        Aplicación virtual - Usaré mi propio sistema de videollamadas
                    </b-form-radio>
                </b-form-row>
                <b-form-row class="m-3">
                    <b-form-radio v-model="videocall_kind" :value="3">
                        Aplicación presencial
                    </b-form-radio>
                    <!-- <b-form-checkbox v-model="needs_video_call" :value="true" :unchecked-value="false">
                        Con videollamada?
                    </b-form-checkbox> -->
                </b-form-row>
                <b-row align-h="center">
                    <b-button class="mx-auto" variant="danger" type="reset" pill size="sm" @click="closeLinkModal">
                        <v-icon name="times"/> Cancelar
                    </b-button>
                    <b-button class="mx-auto" variant="info" pill size="sm" type="submit" @click="submitter = 1">
                        <v-icon name="copy"/> Copiar Link
                    </b-button>
                    <b-button class="mx-auto" variant="primary" type="submit" pill size="sm" @click="submitter = 2">
                        <v-icon name="share-alt"/> Enviar al Correo
                    </b-button>
                </b-row>
            </b-form>
        </b-card>
      </div>
    </b-modal>    
</template>
<script>
import customTestService from "../../../services/customTestService";

export default {
    data() {
        return {
            rut: '',
            name: '',
            email: '',
            test: {},
            student_grade: '',
            submitter: 0,
            needs_video_call: true,
            videocall_kind: 0
        }
    },
    methods: {
        open(rut, name, email, test, student_grade) {
            this.rut = rut;
            this.name = name;
            this.email = email;
            this.test = test;
            this.needs_video_call = true;
            this.student_grade = student_grade;
            this.$refs["link-modal"].show();
        },
        closeLinkModal() {
            this.test = '';
            this.email = '';
            this.rut = '';
            this.submitter = 0;
            this.$refs['link-modal'].hide();
        },
        submitLink() {
            if (this.videocall_kind === 0) {
                this.$toasted.error("Seleccione el tipo de videollamada para generar su link");
                return
            }
            customTestService.generateLink({
                test: this.test.id,
                rut: this.rut,
                email: this.email,
                name: this.name,
                videocall_kind: this.videocall_kind,
                student_grade: this.student_grade
            }).then(
                data => {
                    if (this.submitter===1) {
                        let testURL = `${process.env['VUE_APP_BASE_URL']}/tests#/t/${data.id}`
                        if ((navigator).clipboard) {
                            (navigator).clipboard.writeText(testURL);
                        } else if ((window).clipboardData) {
                            (window).clipboardData.setData('url', testURL);
                        } else {
                            this.$toasted.error("No se pudo copiar");
                            return
                        }
                        this.$toasted.success("Link copiado en el portapapeles")
                    }
                    else if (this.submitter===2){
                        this.sendEmail(data)
                    }
                },
                () => this.$toasted.error("Ocurrió un error. Intenta nuevamente.")
            )
        },
        sendEmail(data) {
            customTestService.sendLinkToStudent(data.id).then(
                () => {
                    this.$toasted.success("Correo enviado con éxito")
                },
                () => this.$toasted.error("Ocurrió un error al enviar el correo. Intente nuevamente")
            )
        }
    }
}
</script>
<style lang="scss">

</style>
