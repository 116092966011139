<template>
  <div class="text-center w-100">
    <div v-if="loading">
      <div class="m-2">
        <b-container>
          <b-row>
            <b-col class="text-center">
              <b-spinner
                style="width: 3rem; height: 3rem"
                type="grow"
                label="Loading..."
              ></b-spinner>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>

    <div v-else>
      <b-card
        no-body
        class="m-0"
        :class="
          material.style === 1
            ? 'modern-style'
            : material.style === 2
            ? 'dream-style'
            : material.style === 3
            ? 'calm-style'
            : ''
        "
      >
        <b-card-body>
          <b-row
            v-if="mode === 'creating' || mode === 'editing'"
            class="w-100 mx-auto my-2 p-2"
          >
            <b-col class="text-left" cols="2">
              <b-button
                class="ml-0"
                pill
                variant="danger"
                @click="$emit('back-to-creation', material)"
              >
                <b><v-icon name="chevron-left" /> Volver</b>
              </b-button>
            </b-col>
          </b-row>
          <b-row
            v-if="material.pages[pageIndex] && material.pages[pageIndex].audio"
            class="mx-3 my-1 px-2 py-2 rounded border audio-section"
          >
            <b-col class="col-sm-4 col-md-3 col-lg-3 col-xl-2">
              <audio
                :autoplay="autoplay"
                ref="player-page"
                :src="material.pages[pageIndex].audio"
                @ended="
                  () => {
                    nowPlaying = '';
                    playText();
                  }
                "
                @play="nowPlaying = 'page'"
              ></audio>
              <div v-if="nowPlaying !== 'page'">
                <b-img
                  @click="togglePlayer('page')"
                  width="30px"
                  style="cursor: pointer"
                  :src="listenIcon"
                />
              </div>
              <div v-else>
                <b-img
                  @click="pausePlayer('page')"
                  width="30px"
                  style="cursor: pointer"
                  :src="
                    $refs[`player-page`] && $refs[`player-page`]['paused']
                      ? resumeIcon
                      : pauseIcon
                  "
                />
              </div>
            </b-col>
            <b-col
              class="pt-2 text-muted text-left col-sm-8 col-md-9 col-lg-9 col-xl-10"
              style="font-size: 16px"
            >
              Presiona para reproducir audio
            </b-col>
          </b-row>
          <b-card no-body class="m-5">
            <b-card-body>
              <b-row class="text-left m-0 p-0 mt-3">
                <p
                  class="content text-left responsive-page-instruction"
                  v-if="material.pages[pageIndex]"
                  style="white-space: pre-wrap; font-weight: bold"
                >
                  {{ material.pages[pageIndex].additional_instruction }}
                </p>
              </b-row>

              <b-row v-if="material.pages[pageIndex].text" class="text-center">
                <div
                  v-if="material.pages[pageIndex].text.content_type === 0"
                  class="m-1 p-2 question-form"
                >
                  <h6 class="w-100 responsive-text-title">
                    {{ material.pages[pageIndex].text.title }}
                  </h6>
                  <h4 class="text-muted mx-auto responsive-text-autor">
                    Por <i>{{ material.pages[pageIndex].text.author }}</i>
                  </h4>
                  <div
                    v-if="material.pages[pageIndex].text.audio"
                    class="w-100 text-left mx-3"
                  >
                    <audio
                      :autoplay="
                        autoplay && material.pages[pageIndex].audio
                          ? false
                          : true
                      "
                      ref="player-text"
                      :src="material.pages[pageIndex].text.audio"
                      @ended="
                        () => {
                          nowPlaying = '';
                        }
                      "
                      @play="nowPlaying = 'text'"
                    ></audio>
                    <div v-if="nowPlaying !== 'text'">
                      <b-img
                        @click="togglePlayer('text')"
                        width="30px"
                        style="cursor: pointer"
                        :src="listenIcon"
                      />
                    </div>
                    <div v-else>
                      <b-img
                        @click="togglePlayer('text')"
                        width="30px"
                        style="cursor: pointer"
                        :src="repeatIcon"
                      />
                      <b-img
                        @click="pausePlayer('text')"
                        width="30px"
                        style="cursor: pointer"
                        :src="
                          $refs[`player-text`] && $refs[`player-text`]['paused']
                            ? resumeIcon
                            : pauseIcon
                        "
                      />
                    </div>
                  </div>
                  <div
                    class="text-left m-2 px-lg-2 text-content responsive-text-content"
                  >
                    {{ material.pages[pageIndex].text.content }}
                  </div>
                </div>

                <div
                  v-if="material.pages[pageIndex].text.content_type === 1"
                  class="w-75 mx-auto"
                >
                  <CustomAudioSlider
                    :content="material.pages[pageIndex].text.story_chapters"
                  />
                </div>
                <div
                  v-if="material.pages[pageIndex].text.content_type === 2"
                  class="m-1 p-2 question-form"
                >
                  <h6 class="w-100 responsive-text-title">
                    {{ material.pages[pageIndex].text.title }}
                  </h6>

                  <div
                    v-if="material.pages[pageIndex].text.audio"
                    class="w-100 text-left mx-3"
                  >
                    <audio
                      :autoplay="
                        autoplay && material.pages[pageIndex].audio
                          ? false
                          : true
                      "
                      ref="player-text"
                      :src="material.pages[pageIndex].text.audio"
                      @ended="
                        () => {
                          nowPlaying = '';
                        }
                      "
                      @play="nowPlaying = 'text'"
                    ></audio>
                    <div v-if="nowPlaying !== 'text'">
                      <b-img
                        @click="togglePlayer('text')"
                        width="30px"
                        style="cursor: pointer"
                        :src="listenIcon"
                      />
                    </div>
                    <div v-else>
                      <b-img
                        @click="togglePlayer('text')"
                        width="30px"
                        style="cursor: pointer"
                        :src="repeatIcon"
                      />
                      <b-img
                        @click="pausePlayer('text')"
                        width="30px"
                        style="cursor: pointer"
                        :src="
                          $refs[`player-text`] && $refs[`player-text`]['paused']
                            ? resumeIcon
                            : pauseIcon
                        "
                      />
                    </div>
                  </div>
                  <div
                    class="text-left m-2 px-lg-2 text-content responsive-text-content"
                  >
                    {{ material.pages[pageIndex].text.content }}
                  </div>
                </div>
              </b-row>

              <b-row
                v-if="
                  material.pages[pageIndex] &&
                  material.pages[pageIndex].images.length > 1
                "
                class="justify-content-md-center"
              >
                <b-col
                  v-for="(img, imgIndex) in material.pages[pageIndex].images"
                  :key="imgIndex + '-img'"
                  cols="12"
                  md="6"
                >
                  <b-img :src="img.image" :key="img.image" fluid-grow />
                </b-col>
              </b-row>
              <b-row
                v-else-if="
                  material.pages[pageIndex] &&
                  material.pages[pageIndex].images.length === 1
                "
                class="justify-content-md-center"
              >
                <b-col
                  v-for="(img, imgIndex) in material.pages[pageIndex].images"
                  :key="imgIndex + '-img'"
                >
                  <b-img :src="img.image" :key="img.image" fluid-grow />
                </b-col>
              </b-row>
              <b-container class="responsive-card-question">
                <b-row
                  v-for="(question, qindex) in material.pages[pageIndex]
                    .questions"
                  :key="qindex + '-question'"
                >
                  <question-preview
                    :question="question"
                    :design="material.style"
                    :qindex="qindex"
                    @now-playing="changePlaying"
                    @mute-all="muteAllAudios"
                  />
                </b-row>
              </b-container>
            </b-card-body>
            <b-card-footer>
              <b-row class="w-100 mt-2 mx-auto align-items-center">
                <b-col class="text-left" cols="3" sm="4">
                  <b-button
                    class="m-1 m-lg-2"
                    pill
                    variant="danger"
                    @click="
                      pageIndex -= 1;
                      scrollToTop();
                    "
                    v-if="pageIndex > 0"
                  >
                    <v-icon name="chevron-left" />
                    <span
                      class="d-none d-md-inline-block"
                      style="font-size: 16px"
                    >
                      Anterior
                    </span>
                  </b-button>
                </b-col>
                <b-col
                  class="text-center d-none d-md-inline-block"
                  cols="12"
                  sm="4"
                  style="font-size: 16px"
                >
                  <strong
                    >Página {{ pageIndex + 1 }} de
                    {{ material.pages.length }}</strong
                  >
                </b-col>
                <b-col
                  class="text-center d-md-none responsive-footer-text"
                  cols="6"
                  sm="4"
                >
                  <strong>Página {{ pageIndex + 1 }} </strong>
                </b-col>
                <b-col class="text-right" cols="3" sm="4">
                  <b-button
                    class="m-1 m-lg-2"
                    pill
                    variant="unknown"
                    @click="
                      pageIndex += 1;
                      scrollToTop();
                    "
                    v-if="pageIndex + 1 < material.pages.length"
                    :class="
                      material.style === 1
                        ? 'modern-next-page-button'
                        : material.style === 2
                        ? 'dream-next-page-button'
                        : material.style === 3
                        ? 'calm-next-page-button'
                        : 'default-next-page-button'
                    "
                  >
                    <span
                      class="d-none d-md-inline-block"
                      style="font-size: 16px"
                    >
                      Siguiente
                    </span>
                    <v-icon name="chevron-right" />
                  </b-button>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import customMaterialService from "@/services/customMaterialService";
import QuestionPreview from "@/components/custom-materials/miscellaneous/QuestionPreview";
import CustomAudioSlider from "@/components/custom-materials/miscellaneous/CustomAudioSlider";
export default {
  name: "MaterialPreview",
  components: {
    QuestionPreview,
    CustomAudioSlider,
  },
  props: {
    inMaterial: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: false,
    },
    creating: {
      type: Boolean,
      required: false,
    },
  },
  watch: {
    pageIndex() {
      this.getText();
    },
  },

  data() {
    return {
      pageIndex: 0,
      material: {},
      loading: true,
      nowPlaying: "",
      muteAll: -1,
      autoplay: true,
      listenIcon: require("../../../assets/img/eureka_icons/icono_escuchar.svg"),
      repeatIcon: require("../../../assets/img/eureka_icons/icono_repetiraudio.svg"),
      resumeIcon: require("../../../assets/img/eureka_icons/icono_comenzar.svg"),
      pauseIcon: require("../../../assets/img/eureka_icons/icono_reproduciendo.svg"),
    };
  },
  created() {
    if (this.inMaterial.id && this.mode !== "editing") {
      customMaterialService
        .getMaterial(this.inMaterial.id)
        .then((data) => {
          this.material = data;

          this.loading = false;
        })
        .catch(() => this.$toadted.error("No se pudo obtener el material."));
    } else {
      this.material = this.inMaterial;
      this.loading = false;
    }
  },
  mounted() {},
  methods: {
    togglePlayer(name) {
      if (this.nowPlaying) {
        this.$refs[`player-${this.nowPlaying}`]["currentTime"] = 0;
      }
      this.muteAllAudios();
      this.nowPlaying = name;
      if (
        this.$refs[`player-${name}`] &&
        this.$refs[`player-${name}`]["paused"]
      ) {
        this.$refs[`player-${name}`]["play"]();
      } else {
        this.$refs[`player-${name}`]["currentTime"] = 0;
      }
    },
    pausePlayer(name) {
      if (
        this.$refs[`player-${name}`] &&
        this.$refs[`player-${name}`]["paused"]
      ) {
        this.$refs[`player-${name}`]["play"]();
      } else {
        this.$refs[`player-${name}`]["pause"]();
      }
    },
    playText() {
      this.muteAllAudios();
      if (this.$refs["player-text"]) {
        if (!this.textPlayed) {
          this.textPlayed = true;
          this.$refs["player-text"][0]["play"]();
        }
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    changePlaying(playing) {
      this.nowPlaying = playing;
    },
    muteAllAudios() {
      let audios = document.querySelectorAll("audio");
      audios.forEach((audio) => {
        audio.pause();
      });
    },
  },
};
</script>

<style>
.audio-section {
  width: 40%;
  max-width: 75%;
}

.default-next-page-button {
  color: white !important;
  background-color: #758bfd !important;
}

.default-audio-color {
  color: #ed217c !important;
}

.modern-style {
  background-image: url("../../../assets/img/backgrounds/bg-1.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}

.modern-next-page-button {
  color: white !important;
  background-color: #008cff !important;
}

.modern-audio-color {
  color: #e23a13 !important;
}

.dream-style {
  background-image: url("../../../assets/img/backgrounds/bg-2.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}

.dream-next-page-button {
  color: white !important;
  background-color: #ea5cd6 !important;
}

.dream-audio-color {
  color: #db2152 !important;
}

.calm-style {
  background-image: url("../../../assets/img/backgrounds/bg-3.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}

.calm-next-page-button {
  color: white !important;
  background-color: #45b49b !important;
}

.calm-audio-color {
  color: #cc3315 !important;
}

@media screen and (min-width: 501px) {
  .responsive-card-question {
    padding: 1.25rem !important;
  }
}
@media screen and (max-width: 500px) {
  .responsive-card-question {
    padding: 0.5rem !important;
  }
  .responsive-footer-text {
    font-size: 12px;
  }
  .responsive-text-title {
    font-size: 15px;
  }
  .responsive-text-autor {
    font-size: 12px;
  }
  .responsive-text-content {
    font-size: 10px;
  }
  .responsive-page-instruction {
    font-size: 14px;
  }
}
html {
  scroll-behavior: smooth;
}
</style>
