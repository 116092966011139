import Vue from "vue";

const JOIN_DOCUMENT = "join_document";
const LEAVE_DOCUMENT = "leave_document";
// const USER_ONLINE = 'user_online';
// const USER_CONNECTED = 'user_connected';
// const USER_DISCONNECTED = 'user_disconnected';
const BLOCK_INPUT = "block_input";
const UNBLOCK_INPUT = "unblock_input";
const UPDATE_INPUT = "update_input";
const UPDATE_ERRORS = "update_errors";
const JOIN_APPLICATION = "join_application";
const LEAVE_APPLICATION = "leave_application";

// const connectWS = () => {
// 	vm.$connect()
// }
// const disconnectWS = () => {
// 	vm.$disconnect()
// }

const sendMessageWS = (data) => {
  if (!Vue.prototype.$socket) {
    return;
  }
  Vue.prototype.$socket.sendObj(data);
};

const state = {
  socket: {
    isConnected: false,
    message: "",
    reconnectError: false,
  },
  activeSchool: null,
  activeDocument: null,
  activeField: null,
  actualMessage: null,
  field_values: {},
  activeApplication: null,
};

const mutations = {
  SOCKET_ONOPEN(state, event) {
    Vue.prototype.$socket = event.currentTarget;
    state.socket.isConnected = true;
  },
  // eslint-disable-next-line
  SOCKET_ONCLOSE(state, event) {
    Vue.prototype.$socket = undefined;
    state.socket.isConnected = false;
  },
  SOCKET_ONERROR(state, event) {
    Vue.prototype.$socket = undefined;
    state.socket.isConnected = false;
    console.error(state, event);
  },
  // default handler called for all methods
  SOCKET_ONMESSAGE(state, message) {
    state.actualMessage = message;
  },
  // mutations for reconnect methods
  SOCKET_RECONNECT(state, count) {
    console.info(state, count);
  },
  SOCKET_RECONNECT_ERROR(state) {
    console.error("reconnect error", state);
    state.socket.isConnected = false;
    state.socket.reconnectError = true;
  },
  // SET_ACTIVE_SCHOOL(state, school) {
  // 	state.activeSchool = school;
  // },
  SET_ACTIVE_DOCUMENT(state, document) {
    state.activeDocument = document;
  },
  SET_ACTIVE_FIELD(state, field) {
    state.activeField = field;
  },
  SET_FIELD_VALUES(state, field_values) {
    state.field_values = field_values;
  },
  SET_FIELD_VALUE(state, { key, value }) {
    state.field_values[key] = value;
  },
  UPDATE_ERRORS(state, errors) {
    console.log(state, errors);
  },
  // CONNECT_SCHOOL(state) {
  // 	//connectar el socket y mandar el mensaje al servidor
  // },
  SET_ACTIVE_APPLICATION(state, app) {
    state.activeApplication = app;
  },
};

const actions = {
  // joinSchool({ commit }, school) {
  // 	commit('SET_ACTIVE_SCHOOL', school);
  // },
  blockInput({ commit }, key) {
    commit("SET_ACTIVE_FIELD", key);
    sendMessageWS({ type: BLOCK_INPUT, key: key });
  },
  updateErrors({ commit }, errors) {
    commit("UPDATE_ERRORS", errors);
    sendMessageWS({ type: UPDATE_ERRORS, errors: errors });
  },
  unblockInput({ commit }, payload) {
    const key = payload.key;
    const value = payload.value;
    commit("SET_ACTIVE_FIELD", null);
    commit("SET_FIELD_VALUE", { key, value });
    sendMessageWS({ type: UNBLOCK_INPUT, key: key, value: value });
  },
  updateInput({ commit }, payload) {
    const key = payload.key;
    const value = payload.value;
    commit("SET_ACTIVE_FIELD", null);
    commit("SET_FIELD_VALUE", { key, value });
    sendMessageWS({ type: UPDATE_INPUT, key: key, value: value });
  },
  joinDocument({ commit }, document) {
    commit("SET_ACTIVE_DOCUMENT", document);
    sendMessageWS({ type: JOIN_DOCUMENT, doc_id: document });
  },
  leaveDocument({ commit }) {
    commit("SET_ACTIVE_DOCUMENT", null);
    sendMessageWS({ type: LEAVE_DOCUMENT });
  },
  setFieldValues({ commit }, field_values) {
    commit("SET_FIELD_VALUES", field_values);
  },
  joinApplication({ commit }, app) {
    commit("SET_ACTIVE_APPLICATION", app);
    sendMessageWS({ type: JOIN_APPLICATION, app_id: app });
  },
  leaveApplication({ commit }) {
    commit("SET_ACTIVE_APPLICATION", null);
    sendMessageWS({ type: LEAVE_APPLICATION });
  },
};

export default {
  namespaced: false,
  state,
  mutations,
  actions,
};
