<template>
  <div>
    <TextManagerModal
      ref="text-manager"
      @open-related-material="openMaterial"
      @created-text="pushText"
    />
    <b-modal ref="delete-text-modal" hide-header centered size="lg">
      <b-container>
        <b-row>
          <b-col class="text-center">
            ¿Está seguro que desea enviar este texto a la papelera?
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <b-row>
          <b-col class="text-center">
            <b-button
              variant="danger"
              @click="
                textToDelete = '';
                $refs['delete-text-modal'].hide();
              "
              pill
            >
              Cancelar
            </b-button>
          </b-col>
          <b-col class="text-center">
            <b-button variant="warning" @click="deleteText()" pill>
              Eliminar
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <b-row class="text-right mx-2 p-2">
      <b-col v-if="paperBin">
        <b-form-checkbox
          v-model="showRecycle"
          name="recycle"
          value="active"
          unchecked-value="not_active"
        >
          Mostrar textos en papelera
        </b-form-checkbox>
      </b-col>
      <b-col :cols="paperBin ? 1 : 12" class="text-right">
        <b-button
          variant="unknown"
          size="sm"
          class="mx-auto"
          v-b-tooltip.hover
          title="Papelera"
          @click="paperBin = !paperBin"
        >
          <v-icon name="trash-alt" class="text-danger" />
        </b-button>
      </b-col>
    </b-row>
    <b-row class="text-left mx-2 p-2">
      <b-col cols="3" class="cursor-pointer">
        <p class="h6 w-100" @click="openNewText()">
          Crear nuevo texto
          <v-icon name="plus-circle" scale="1.2" style="cursor: pointer" />
        </p>
      </b-col>
    </b-row>

    <hr />
    <b-row class="text-left mx-2 p-2">
      <!-- <b-col cols="3" class="align-middle mt-4 cursor-pointer">
              <b-card
                header-bg-variant="dark"
                header="Crear nuevo texto"
                id="new"
                @click="openNewText()"
                header-text-variant="white"
                no-body
              >
                <b-card-body>
                  <b-form-row>
                    <b-col class="text-center px-0" cols="6" offset="3">
                      <b-img :src="icon_new" height="100" />
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    Instrucciones:
                    Para crear un nuevo Texto
                    lorem ipsum dolor sit
                    amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod
                    tincidunt ut laoreet dolore magna
                    aliquam erat volutpat.
                  </b-form-row>
                </b-card-body>
              </b-card>
            </b-col> -->

      <b-col cols="12" class="mt-4 mx-0 px-0">
        <b-container class="m-0 p-0" v-if="!loadingTexts">
          <b-row class="m-0 p-0">
            <b-col
              v-for="(text, tindex) in texts"
              :key="tindex"
              cols="4"
              class="mt-4"
            >
              <text-row-component
                :text="text"
                :paperBin="paperBin"
                mode="material-list"
                @open-text="openText(text)"
                @delete-text="deleteTextModal(text.id)"
              />
              <hr />
            </b-col>
          </b-row>
        </b-container>
        <b-container v-else>
          <b-row class="m-0 p-0">
            <b-col class="text-center">
              <b-spinner variant="primary" label="Spinning"></b-spinner>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row v-if="pages > 1">
      <b-col class="text-center">
        <b-button-group>
          <b-button
            variant="outline-info"
            v-if="page > 1"
            @click="page = page - 1"
          >
            &#60;
          </b-button>
          <b-button
            variant="outline-info"
            :pressed="page === 1"
            @click="page = 1"
          >
            1
          </b-button>
          <b-button variant="outline-info" v-if="page - pageRange / 2 > 2">
            ...</b-button
          >
          <b-button
            v-for="(p, index) in pagesArray.filter((p) => {
              return (
                1 < p &&
                p < pages &&
                ((pageRange < page &&
                  page - pageRange / 2 <= p &&
                  p <= page + pageRange / 2) ||
                  (pageRange >= page && p <= pageRange + 1))
              );
            })"
            :key="'page-' + index"
            :pressed="p === page"
            @click="page = p"
            variant="outline-info"
          >
            {{ p }}
          </b-button>
          <b-button
            variant="outline-info"
            v-if="pages - 1 > page + pageRange / 2"
          >
            ...</b-button
          >
          <b-button
            variant="outline-info"
            :pressed="page === pages"
            @click="page = pages"
          >
            {{ pages }}</b-button
          >
          <b-button
            variant="outline-info"
            v-if="page < pages"
            @click="page = page + 1"
          >
            &#62;
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import customMaterialService from "@/services/customMaterialService";
import TextManagerModal from "@/components/custom-materials/modals/TextManagerModal";
import TextRowComponent from "@/components/custom-materials/miscellaneous/TextRowComponent";
export default {
  name: "TextListComponent",
  components: {
    TextManagerModal,
    TextRowComponent,
  },
  data() {
    return {
      paperBin: false,
      showRecycle: "not_active",
      texts: [],
      page: 1,
      pages: 1,
      textToDelete: "",
      loadingTexts: false,
      pageRange: 6,
      perPage: 30,
      order: "-created_at",
    };
  },
  mounted() {
    this.getPaginatedSchoolTexts();
  },
  computed: {
    pagesArray() {
      return Array.from({ length: this.pages }, (_, i) => i + 1);
    },
  },
  watch: {
    page() {
      this.getPaginatedSchoolTexts();
    },
    showRecycle() {
      this.getPaginatedSchoolTexts();
    },
  },
  methods: {
    openNewText() {
      this.$refs["text-manager"].createNew(true);
    },
    openMaterial(materialID) {
      this.$emit("open-related-material", materialID);
    },
    getPaginatedSchoolTexts() {
      this.loadingTexts = true;
      customMaterialService
        .getPaginatedSchoolTexts(
          this.$store.state.login.school,
          this.page,
          this.perPage,
          this.order,
          this.showRecycle
        )
        .then((data) => {
          this.texts = data.texts;
          this.pages = data.pages;
          this.loadingTexts = false;
        })
        .catch(() =>
          this.$toasted.error("Ocurrió un problema al traer tus textos")
        );
    },
    openText(text) {
      this.$refs["text-manager"].selectText(text, true);
    },
    deleteTextModal(textId) {
      this.textToDelete = textId;
      this.$refs["delete-text-modal"].show();
    },
    deleteText() {
      customMaterialService
        .deleteText(this.textToDelete)
        .then(() => {
          this.getPaginatedSchoolTexts();
          this.textToDelete = "";
          this.$toasted.success("Texto movido a la papelera");
          this.$refs["delete-text-modal"].hide();
        })
        .catch(() => this.$toasted.error("No se pudo eliminar el texto"));
    },
    pushText() {
      if (this.page > 1) {
        this.page = 1;
      } else {
        this.getPaginatedSchoolTexts();
      }
    },
  },
};
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
