<template lang="">
  <div>
    <b-row>
      <b-col cols="6">
        <b-select v-model="answer.kind">
          <b-form-select-option :value="null"
            >Selecciona un Evamat</b-form-select-option
          >
          <b-form-select-option
            v-for="(test, index) in tests"
            :key="index"
            :value="index"
            >Evamat {{ index }}</b-form-select-option
          >
        </b-select>
      </b-col>
    </b-row>
    <GenericTest
      v-if="answer.kind != null"
      :test="tests[answer.kind]"
      :test_id="test_id"
      :answer="answer"
      :required_fields="required"
      :semester="semester"
      :previous_answer="previous_answer"
    ></GenericTest>
  </div>
</template>
<script>
import GenericTest from "./GenericTest";
export default {
  components: { GenericTest },
  props: ["test_id", "student_id", "previous_answer", "semester"],
  watch: {
    student_id() {
      this.answer.student_id = this.student_id;
    },
    test_id() {
      this.answer.test = this.test_id;
    },
  },
  data() {
    return {
      answer: {
        test: this.test_id,
        answers: {},
        student_id: this.student_id,
        kind: null,
      },
      tests: [
        [
          { type: "h3", label: "Resolución de problemas" },

          {
            label: "Aciertos Tarea 1",

            var: "7",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "8",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "9",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "10",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "11",
            type: "number",
          },

          { type: "h3", label: "Cantidad y conteo" },

          {
            label: "Aciertos Tarea 1",

            var: "3",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "4",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "5",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "6",
            type: "number",
          },

          { type: "h3", label: "Geometría" },

          {
            label: "Aciertos Tarea 1",

            var: "0",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "1",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "2",
            type: "number",
          },
        ],
        [
          { type: "h3", label: "Resolución de problemas" },

          {
            label: "Aciertos Tarea 1",

            var: "28",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "29",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "30",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "31",
            type: "number",
          },

          { type: "h3", label: "Geometría" },

          {
            label: "Aciertos Tarea 1",

            var: "23",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "24",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "25",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "26",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "27",
            type: "number",
          },

          { type: "h3", label: "Cálculo" },

          {
            label: "Aciertos Tarea 1",

            var: "16",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "17",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "18",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "19",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "20",
            type: "number",
          },

          {
            label: "Aciertos Tarea 6",

            var: "21",
            type: "number",
          },

          {
            label: "Aciertos Tarea 7",

            var: "22",
            type: "number",
          },

          { type: "h3", label: "Numeración" },

          {
            label: "Aciertos Tarea 1",

            var: "12",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "13",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "14",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "15",
            type: "number",
          },
        ],
        [
          { type: "h3", label: "Resolución de problemas" },

          {
            label: "Puntaje",

            var: "49",
            type: "number",
          },

          { type: "h3", label: "Información y azar" },

          {
            label: "Aciertos Tarea 1",

            var: "45",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "46",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "47",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "48",
            type: "number",
          },

          { type: "h3", label: "Geometría" },

          {
            label: "Aciertos Tarea 1",

            var: "42",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "43",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "44",
            type: "number",
          },

          { type: "h3", label: "Cálculo" },

          {
            label: "Aciertos Tarea 1",

            var: "37",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "38",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3a (ítems 21 al 28)",

            var: "39",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3b (ítems 29 al 34)",

            var: "40",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "41",
            type: "number",
          },

          { type: "h3", label: "Numeración" },

          {
            label: "Aciertos Tarea 1",

            var: "32",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "33",
            type: "number",
          },

          {
            label: "Aciertos Tareas 3, 4 y 5",

            var: "34",
            type: "number",
          },

          {
            label: "Aciertos Tarea 6a (ítems 43 al 45)",

            var: "35",
            type: "number",
          },

          {
            label: "Aciertos Tarea 6b (ítems 46 al 51)",

            var: "36",
            type: "number",
          },
        ],
        [
          { type: "h3", label: "Resolución de problemas" },

          {
            label: "Ingrese puntaje (1 al 7)",

            var: "73",
            type: "number",
          },

          {
            label: "Ingrese puntaje (8 y 9)",

            var: "74",
            type: "number",
          },

          { type: "h3", label: "Información y azar" },

          {
            label: "Aciertos Tarea 1",

            var: "68",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "69",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "70",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "71",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "72",
            type: "number",
          },

          { type: "h3", label: "Geometría" },

          {
            label: "Aciertos Tarea 1",

            var: "64",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2a (ítems 8 al 13)",

            var: "65",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2b (ítems 14 al 19)",

            var: "66",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "67",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "300",
            type: "number",
          },

          { type: "h3", label: "Cálculo" },

          {
            label: "Aciertos Tarea 1",

            var: "58",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "59",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "60",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "61",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "62",
            type: "number",
          },

          {
            label: "Aciertos Tarea 6",

            var: "63",
            type: "number",
          },

          { type: "h3", label: "Numeración" },

          {
            label: "Aciertos Tarea 1",

            var: "50",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "51",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "52",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "53",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "54",
            type: "number",
          },

          {
            label: "Aciertos Tarea 6",

            var: "55",
            type: "number",
          },

          {
            label: "Aciertos Tarea 7",

            var: "56",
            type: "number",
          },

          {
            label: "Aciertos Tarea 8",

            var: "57",
            type: "number",
          },
        ],
        [
          { type: "h3", label: "Resolución de problemas" },

          {
            label: "Puntaje (problemas 1 al 4)",

            var: "102",
            type: "number",
          },

          {
            label: "Puntaje (problemas 5 al 14)",

            var: "103",
            type: "number",
          },

          { type: "h3", label: "Información y azar" },

          {
            label: "Aciertos Tarea 1 (ítem 1 al 8)",

            var: "96",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2 (ítem 9 al 15)",

            var: "97",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3 (ítem 16 al 19)",

            var: "98",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4 (ítem 20 al 28)",

            var: "99",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4 (ítem 29 al 32)",

            var: "100",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4 (ítem 33)",

            var: "101",
            type: "number",
          },

          { type: "h3", label: "Geometría" },

          {
            label: "Aciertos Tarea 1",

            var: "90",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "91",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "92",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "93",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "94",
            type: "number",
          },

          {
            label: "Aciertos Tarea 6",

            var: "95",
            type: "number",
          },

          { type: "h3", label: "Cálculo" },

          {
            label: "Aciertos Tarea 1",

            var: "83",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "84",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "85",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "86",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "87",
            type: "number",
          },

          {
            label: "Aciertos Tarea 6",

            var: "88",
            type: "number",
          },

          {
            label: "Aciertos Tarea 7",

            var: "89",
            type: "number",
          },

          { type: "h3", label: "Numeración" },

          {
            label: "Aciertos Tarea 1",

            var: "75",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "76",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "77",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "78",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "79",
            type: "number",
          },

          {
            label: "Aciertos Tarea 6",

            var: "80",
            type: "number",
          },

          {
            label: "Aciertos Tarea 7",

            var: "81",
            type: "number",
          },
        ],
        [
          { type: "h3", label: "Resolución de problemas" },

          {
            label: "Puntaje (problemas 1, 2, 3, 4 y 9)",

            var: "147",
            type: "number",
          },

          {
            label: "Puntaje (problemas 5, 7, 8, 10, 11 y 13)",

            var: "148",
            type: "number",
          },

          {
            label: "Puntaje (problema 6 y 12)",

            var: "149",
            type: "number",
          },

          { type: "h3", label: "Información y azar" },

          {
            label: "Aciertos Tarea 1",

            var: "137",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "138",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "139",
            type: "number",
          },

          {
            label: "Errores Tarea 3",

            var: "140",
            type: "number",
          },

          {
            label: "Omisiones Tarea 3",

            var: "141",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "142",
            type: "number",
          },

          {
            label: "Errores Tarea 4",

            var: "143",
            type: "number",
          },

          {
            label: "Omisiones Tarea 4",

            var: "144",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "145",
            type: "number",
          },

          {
            label: "Aciertos Tarea 6",

            var: "146",
            type: "number",
          },

          { type: "h3", label: "Geometría" },

          {
            label: "Aciertos Tarea 1",

            var: "127",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "128",
            type: "number",
          },

          {
            label: "Errores Tarea 2",

            var: "129",
            type: "number",
          },

          {
            label: "Omisiones Tarea 2",

            var: "130",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "131",
            type: "number",
          },

          {
            label: "Errores Tarea 3",

            var: "132",
            type: "number",
          },

          {
            label: "Omisiones Tarea 3",

            var: "133",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "134",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "135",
            type: "number",
          },

          {
            label: "Aciertos Tarea 6",

            var: "136",
            type: "number",
          },

          { type: "h3", label: "Cálculo" },

          {
            label: "Aciertos Tarea 1",

            var: "116",
            type: "number",
          },

          {
            label: "Errores Tarea 1",

            var: "117",
            type: "number",
          },

          {
            label: "Omisiones Tarea 1",

            var: "118",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "119",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "120",
            type: "number",
          },

          {
            label: "Errores Tarea 3",

            var: "121",
            type: "number",
          },

          {
            label: "Omisiones Tarea 3",

            var: "122",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "123",
            type: "number",
          },

          {
            label: "Errores Tarea 4",

            var: "124",
            type: "number",
          },

          {
            label: "Omisiones Tarea 4",

            var: "125",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "126",
            type: "number",
          },

          { type: "h3", label: "Numeración" },

          {
            label: "Aciertos Tarea 1",

            var: "104",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "105",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "106",
            type: "number",
          },

          {
            label: "Errores Tarea 3",

            var: "107",
            type: "number",
          },

          {
            label: "Omisiones Tarea 3",

            var: "108",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "109",
            type: "number",
          },

          {
            label: "Errores Tarea 4",

            var: "110",
            type: "number",
          },

          {
            label: "Omisiones Tarea 4",

            var: "111",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "112",
            type: "number",
          },

          {
            label: "Errores Tarea 5",

            var: "113",
            type: "number",
          },

          {
            label: "Omisiones Tarea 5",

            var: "114",
            type: "number",
          },

          {
            label: "Aciertos Tarea 6",

            var: "115",
            type: "number",
          },
        ],
        [
          { type: "h3", label: "Resolución de problemas" },

          {
            label: "Puntaje (problemas 1, 2, 3, 4, 6, 7, 9, 11 y 13)",

            var: "197",
            type: "number",
          },

          {
            label: "Puntaje (problemas 5, 8, 10, 12)",

            var: "198",
            type: "number",
          },

          { type: "h3", label: "Información y azar" },

          {
            label: "Aciertos Tarea 1",

            var: "185",
            type: "number",
          },

          {
            label: "Errores Tarea 1",

            var: "186",
            type: "number",
          },

          {
            label: "Omisiones Tarea 1",

            var: "187",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "188",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "189",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "190",
            type: "number",
          },

          {
            label: "Errores Tarea 4",

            var: "191",
            type: "number",
          },

          {
            label: "Omisiones Tarea 4",

            var: "192",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "193",
            type: "number",
          },

          {
            label: "Aciertos Tarea 6",

            var: "194",
            type: "number",
          },

          {
            label: "Errores Tarea 6",

            var: "195",
            type: "number",
          },

          {
            label: "Omisiones Tarea 6",

            var: "196",
            type: "number",
          },

          { type: "h3", label: "Geometría" },

          {
            label: "Aciertos Tarea 1",

            var: "180",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "181",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "182",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "183",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "184",
            type: "number",
          },

          { type: "h3", label: "Cálculo" },

          {
            label: "Aciertos Tarea 1",

            var: "163",
            type: "number",
          },

          {
            label: "Errores Tarea 1",

            var: "164",
            type: "number",
          },

          {
            label: "Omisiones Tarea 1",

            var: "165",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "166",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "167",
            type: "number",
          },

          {
            label: "Errores Tarea 3",

            var: "168",
            type: "number",
          },

          {
            label: "Omisiones Tarea 3",

            var: "169",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "170",
            type: "number",
          },

          {
            label: "Errores Tarea 4",

            var: "171",
            type: "number",
          },

          {
            label: "Omisiones Tarea 4",

            var: "172",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "173",
            type: "number",
          },

          {
            label: "Errores Tarea 5",

            var: "174",
            type: "number",
          },

          {
            label: "Omisiones Tarea 5",

            var: "175",
            type: "number",
          },

          {
            label: "Aciertos Tarea 6",

            var: "176",
            type: "number",
          },

          {
            label: "Errores Tarea 6",

            var: "177",
            type: "number",
          },

          {
            label: "Omisiones Tarea 6",

            var: "178",
            type: "number",
          },

          {
            label: "Aciertos Tarea 7",

            var: "179",
            type: "number",
          },

          { type: "h3", label: "Numeración" },

          {
            label: "Aciertos Tarea 1",

            var: "150",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "151",
            type: "number",
          },

          {
            label: "Errores Tarea 2",

            var: "152",
            type: "number",
          },

          {
            label: "Omisiones Tarea 2",

            var: "153",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "154",
            type: "number",
          },

          {
            label: "Errores Tarea 3",

            var: "155",
            type: "number",
          },

          {
            label: "Omisiones Tarea 3",

            var: "156",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "157",
            type: "number",
          },

          {
            label: "Errores Tarea 4",

            var: "158",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "159",
            type: "number",
          },

          {
            label: "Errores Tarea 5",

            var: "160",
            type: "number",
          },

          {
            label: "Omisiones Tarea 5",

            var: "161",
            type: "number",
          },

          {
            label: "Aciertos Tarea 6",

            var: "162",
            type: "number",
          },

          {
            label: "Omisiones Tarea 4",

            var: "301",
            type: "number",
          },
        ],
        [
          { type: "h3", label: "Resolución de problemas" },

          {
            label: "Puntaje (problemas 1, 4, 5, 6, 7, 8, 9 y 10)",

            var: "247",
            type: "number",
          },

          {
            label: "Puntaje (problema 3)",

            var: "248",
            type: "number",
          },

          {
            label: "Puntaje (problemas 2, 11 y 12)",

            var: "249",
            type: "number",
          },

          { type: "h3", label: "Información y azar" },

          {
            label: "Aciertos Tarea 1",

            var: "236",
            type: "number",
          },

          {
            label: "Errores Tarea 1",

            var: "237",
            type: "number",
          },

          {
            label: "Omisiones Tarea 1",

            var: "238",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "239",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "240",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "241",
            type: "number",
          },

          {
            label: "Errores Tarea 4",

            var: "242",
            type: "number",
          },

          {
            label: "Omisiones Tarea 4",

            var: "243",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "244",
            type: "number",
          },

          {
            label: "Errores Tarea 5",

            var: "245",
            type: "number",
          },

          {
            label: "Omisiones Tarea 5",

            var: "246",
            type: "number",
          },

          { type: "h3", label: "Geometría" },

          {
            label: "Aciertos Tarea 1",

            var: "223",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "224",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "225",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "226",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "227",
            type: "number",
          },

          {
            label: "Errores Tarea 5",

            var: "228",
            type: "number",
          },

          {
            label: "Omisiones Tarea 5",

            var: "229",
            type: "number",
          },

          {
            label: "Aciertos Tarea 6",

            var: "230",
            type: "number",
          },

          {
            label: "Errores Tarea 6",

            var: "231",
            type: "number",
          },

          {
            label: "Omisiones Tarea 6",

            var: "232",
            type: "number",
          },

          {
            label: "Aciertos Tarea 7",

            var: "233",
            type: "number",
          },

          {
            label: "Errores Tarea 7",

            var: "234",
            type: "number",
          },

          {
            label: "Omisiones Tarea 7",

            var: "235",
            type: "number",
          },

          { type: "h3", label: "Cálculo" },

          {
            label: "Aciertos Tarea 1",

            var: "213",
            type: "number",
          },

          {
            label: "Errores Tarea 1",

            var: "214",
            type: "number",
          },

          {
            label: "Omisiones Tarea 1",

            var: "215",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "216",
            type: "number",
          },

          {
            label: "Errores Tarea 2",

            var: "217",
            type: "number",
          },

          {
            label: "Omisiones Tarea 2",

            var: "218",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "219",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "220",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "221",
            type: "number",
          },

          {
            label: "Aciertos Tarea 6",

            var: "222",
            type: "number",
          },

          { type: "h3", label: "Numeración" },

          {
            label: "Aciertos Tarea 1",

            var: "199",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "200",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "201",
            type: "number",
          },

          {
            label: "Errores Tarea 3",

            var: "202",
            type: "number",
          },

          {
            label: "Omisiones Tarea 3",

            var: "203",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "204",
            type: "number",
          },

          {
            label: "Errores Tarea 4",

            var: "205",
            type: "number",
          },

          {
            label: "Omisiones Tarea 4",

            var: "206",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "207",
            type: "number",
          },

          {
            label: "Errores Tarea 5",

            var: "208",
            type: "number",
          },

          {
            label: "Omisiones Tarea 5",

            var: "209",
            type: "number",
          },

          {
            label: "Aciertos Tarea 6",

            var: "210",
            type: "number",
          },

          {
            label: "Errores Tarea 6",

            var: "211",
            type: "number",
          },

          {
            label: "Omisiones Tarea 6",

            var: "212",
            type: "number",
          },
        ],
        [
          { type: "h3", label: "Resolución de problemas" },

          {
            label: "Puntaje (problemas 1 al 6)",

            var: "297",
            type: "number",
          },

          {
            label: "Puntaje (problemas 8, 9 y 11)",

            var: "298",
            type: "number",
          },

          {
            label: "Puntaje (problemas 7, 10, 12, 13 y 14)",

            var: "299",
            type: "number",
          },

          { type: "h3", label: "Información y azar" },

          {
            label: "Aciertos Tarea 1",

            var: "285",
            type: "number",
          },

          {
            label: "Errores Tarea 1",

            var: "286",
            type: "number",
          },

          {
            label: "Omisiones Tarea 1",

            var: "287",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "288",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "289",
            type: "number",
          },

          {
            label: "Errores Tarea 3",

            var: "290",
            type: "number",
          },

          {
            label: "Omisiones Tarea 3",

            var: "291",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "292",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "293",
            type: "number",
          },

          {
            label: "Aciertos Tarea 6",

            var: "294",
            type: "number",
          },

          {
            label: "Errores Tarea 6",

            var: "295",
            type: "number",
          },

          {
            label: "Omisiones Tarea 6",

            var: "296",
            type: "number",
          },

          { type: "h3", label: "Geometría" },

          {
            label: "Aciertos Tarea 1",

            var: "278",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "279",
            type: "number",
          },

          {
            label: "Errores Tarea 2",

            var: "280",
            type: "number",
          },

          {
            label: "Omisiones Tarea 2",

            var: "281",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "282",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "283",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "284",
            type: "number",
          },

          { type: "h3", label: "Cálculo" },

          {
            label: "Aciertos Tarea 1",

            var: "260",
            type: "number",
          },

          {
            label: "Errores Tarea 1",

            var: "261",
            type: "number",
          },

          {
            label: "Omisiones Tarea 1",

            var: "262",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "263",
            type: "number",
          },

          {
            label: "Errores Tarea 2",

            var: "264",
            type: "number",
          },

          {
            label: "Omisiones Tarea 2",

            var: "265",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "266",
            type: "number",
          },

          {
            label: "Errores Tarea 3",

            var: "267",
            type: "number",
          },

          {
            label: "Omisiones Tarea 3",

            var: "268",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "269",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "270",
            type: "number",
          },

          {
            label: "Aciertos Tarea 6",

            var: "271",
            type: "number",
          },

          {
            label: "Aciertos Tarea 7",

            var: "272",
            type: "number",
          },

          {
            label: "Errores Tarea 7",

            var: "273",
            type: "number",
          },

          {
            label: "Omisiones Tarea 7",

            var: "274",
            type: "number",
          },

          {
            label: "Aciertos Tarea 8",

            var: "275",
            type: "number",
          },

          {
            label: "Errores Tarea 8",

            var: "276",
            type: "number",
          },

          {
            label: "Omisiones Tarea 8",

            var: "277",
            type: "number",
          },

          { type: "h3", label: "Numeración" },

          {
            label: "Aciertos Tarea 1",

            var: "250",
            type: "number",
          },

          {
            label: "Aciertos Tarea 2",

            var: "251",
            type: "number",
          },

          {
            label: "Errores Tarea 2",

            var: "252",
            type: "number",
          },

          {
            label: "Omisiones Tarea 2",

            var: "253",
            type: "number",
          },

          {
            label: "Aciertos Tarea 3",

            var: "254",
            type: "number",
          },

          {
            label: "Aciertos Tarea 4",

            var: "255",
            type: "number",
          },

          {
            label: "Errores Tarea 4",

            var: "256",
            type: "number",
          },

          {
            label: "Omisiones Tarea 4",

            var: "257",
            type: "number",
          },

          {
            label: "Aciertos Tarea 5",

            var: "258",
            type: "number",
          },

          {
            label: "Aciertos Tarea 6",

            var: "259",
            type: "number",
          },
        ],
      ],
    };
  },
  computed: {
    required: function () {
      return this.tests[this.answer.kind]
        .filter((elem) => elem.var != null)
        .map((elem) => elem.var);
    },
  },
};
</script>
<style lang=""></style>
