<template>
  <div :class="material.is_active ? '' : 'recycle'">
    <b-card
      :header-bg-variant="
        material.profession === 2
          ? 'danger'
          : material.profession === 5
          ? 'secondary'
          : 'primary'
      "
      :id="material.id"
      header-text-variant="white"
      no-body
    >
      <template v-slot:header>
        <b-form-row no-gutters>
          <b-col @click.self="$emit('open-material')" style="cursor:pointer">{{
            material.name.length > 18
              ? material.name.substring(0, 15) + "..."
              : material.name
          }}</b-col>
          <b-col
            v-if="mode === 'material-list' && material.is_active"
            cols="2"
            class="text-center"
          >
            <b-button
              variant="unknown"
              size="sm"
              class="mx-auto"
              v-b-tooltip.hover
              title="Eliminar material"
              @click="$emit('delete-material')"
            >
              <v-icon
                name="trash-alt"
                :class="
                  material.profession === 2
                    ? 'text-warning'
                    : material.profession === 5
                    ? 'text-warning'
                    : 'text-danger'
                "
              />
            </b-button>
          </b-col>
        </b-form-row>
      </template>
      <b-card-body>
        <b-form-row
          @click="$emit('open-material')"
          v-b-tooltip.hover
          :title="mode !== 'appModal' ? 'Editar Material' : 'Aplicar Material'"
          style="cursor:pointer"
        >
          <b-col class="text-center px-0" cols="4" offset="3">
            <b-img
              :src="
                material.profession === 2
                  ? icon
                  : material.profession === 5
                  ? icon2
                  : icon3
              "
              height="100"
            />
          </b-col>
          <b-col class="text-left px-0" cols="5" align-self="end">
            <b-badge
              :variant="
                material.profession === 2
                  ? 'danger'
                  : material.profession === 5
                  ? 'secondary'
                  : 'primary'
              "
              >{{ material.duration }} minutos</b-badge
            >
          </b-col>
        </b-form-row>
      </b-card-body>
      <b-card-footer
        v-if="mode !== 'appModal' && material.is_active"
        class="bg-transparent"
      >
        <b-button
          class="mr-0"
          pill
          :variant="
            material.profession === 2
              ? 'danger'
              : material.profession === 5
              ? 'secondary'
              : 'primary'
          "
          block
          @click="$emit('apply-material')"
        >
          Aplicar
        </b-button>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "MaterialCard",
  components: {},
  props: {
    material: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      icon: require("../../../assets/img/material_icons/Icon1.svg"),
      icon2: require("../../../assets/img/material_icons/Icon2.svg"),
      icon3: require("../../../assets/img/material_icons/Icon3.svg"),
      icon4: require("../../../assets/img/material_icons/Icon4.svg"),
    };
  },
  methods: {},
};
</script>

<style scoped>
.recycle {
  opacity: 0.5;
}
</style>
