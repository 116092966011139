
const state = {
    conversation: {}
};

const mutations = {
    addMessage(state, msg) {
        state.conversation[msg.name] = msg        
    },
    updateMessage(state, msg) {
        if (!state.conversation[msg.name]) {
            state.conversation[msg.name] = {};
        }
        state.conversation[msg.name][msg.key] = msg.value;
    },
    clear(state) {
        state.conversation = {}
    }
};

const actions = {
    addMessage({ commit }, msg) {
        commit('addMessage',  msg);
    },
    updateMessage({ commit }, msg) {
        commit('updateMessage', msg);
    },
    clear({ commit }) {
        commit('clear');
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

