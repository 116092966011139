<template>
	<div :class="custom? 'image-advertising radius-box-8 pb-3 pr-3 pl-3':'image-advertising radius-box-8 padding-box-16'">
		<img :src="src? src:'https://i5.walmartimages.com/asr/0c904f0e-73e5-4de0-99ea-f762970e522f_1.b2f398eab6b75e3432bffc486ded7523.jpeg'" :style="custom? 'width: 100%;':''" alt="No hay imagen">
	</div>
</template>

<script>
	export default {
		props: {
			src: { type: String, required: false},
			custom: {type: Boolean, default: false}
		},
		data() {
			return {

			}
		}
	}
</script>

<style lang="scss">

	.image-advertising {
		width: 100%;
		background-color: white;

		img {
      width: 100%;
      height: auto;
    }
	}
</style>
