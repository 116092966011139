import api from '@/services/api';

export default {
  get_my_notifications(payload) {
    return api.post('get_my_notifications/', payload).then(response => response.data);
  },
  get_new_notifications(payload) {
    return api.get('get_new_notifications/', payload).then(response => response.data);
  },
  set_not_new_notifications(payload) {
    return api.get('set_not_new_notifications/', payload).then(response => response.data);
  },
};
