<template>
  <b-container class="chat-box">
    <b-row>
      <b-col cols="10" class="mx-auto">
        <div
          v-for="(msg, msgidx) in messages"
          :key="msgidx"
          class="chat-record mt-2"
        >
          <b-row class="box-question rounded text-left">
            <div class="box-list-question px-2">
              <p
                v-html="msg.text"
                class="rounded"
                :ref="`msgRef-${msg.name}`"
              ></p>
              <p
                v-if="msg.hint && msg.showHint"
                class="question-hint"
                :ref="`msg-hint-${msg.name}`"
                v-html="'Indicación: ' + msg.hint"
              ></p>
            </div>
          </b-row>

          <div class="box-answer p-2">
            <b-row
              v-if="msg.answersLoaded && msg.showAnswers"
              class="box-list-answer w-100"
            >
              <b-col
                class="msg p-1 col-4"
                v-for="(answer, ansidx) in msg.answers"
                :key="ansidx"
                :class="answer.cols"
              >
                <div v-if="msg.type === 'cards'">
                  <b-card
                    class="m-2 option-card text-center font-weight-bold w-100"
                    :class="{
                      'selected-card': answer.isSelected,
                    }"
                    @click="handleCardClick(answer, msgidx, ansidx)"
                    :header="answer.title"
                  >
                    <b-card-text
                      :class="answer.bootstrap_classes"
                      class="font-weight-normal"
                    >
                      {{ answer.name }}
                    </b-card-text>
                  </b-card>
                </div>

                <!-- Special conditions input -->

                <div v-if="msg.type == 'input-text'">
                  <SpecialConditions
                    :msg="msg"
                    @update-condition="updateCondition"
                  />
                </div>

                <!-- End special conditions input -->

                <div v-if="msg.type === 'text'">
                  <input type="text" name="answer" />
                </div>
                <div v-if="msg.type == 'options'">
                  <b-card
                    class="text-center option-option"
                    :class="{ 'selected-option': answer.isSelected }"
                    @click="handleCardClick(answer, msgidx, ansidx, msg.max)"
                    v-if="
                      answer.isSelected ||
                      !msg.single_choice ||
                      (msg.single_choice &&
                        msg.answers.filter((ans) => ans.isSelected).length < 1)
                    "
                  >
                    <div
                      :class="{
                        'd-flex': answer.price || answer.priority,
                        'align-items-center': !answer.price && !answer.priority,
                        'justify-content-between':
                          answer.price || answer.priority,
                      }"
                    >
                      <div
                        v-html="answer.name"
                        :class="answer.bootstrap_classes"
                      ></div>

                      <div
                        v-if="answer.priority == 1"
                        class="d-flex align-items-center m-1"
                      >
                        <v-icon name="custom-star" scale="1.5" />
                      </div>
                    </div>
                  </b-card>
                </div>
                <div v-if="msg.type === 'options-counter'">
                  <b-row class="p-2 counter-section mb-1">
                    <p v-html="answer.name_section"></p>
                  </b-row>
                  <b-row
                    class="p-2 mb-1 w-100"
                    v-if="
                      answer.has_warning &&
                      !(answer[answer.key] >= answer.required)
                    "
                  >
                    <b-alert show variant="warning" class="w-100">{{
                      answer.warning_text
                    }}</b-alert>
                  </b-row>
                  <b-row
                    v-for="(opt, optidx) in answer.options"
                    :key="optidx"
                    class="p-2 counter-option w-100 my-1 ml-1"
                  >
                    <b-col cols="4" class="counter-container col-sm-2">
                      <v-icon
                        name="minus"
                        class="counter-button"
                        @click="handleDecrease(opt, answer)"
                        scale="0.8"
                      />
                      <b-input
                        type="number"
                        class="no-arrow input-no-border input-counter"
                        disabled
                        v-model="opt.count"
                      />
                      <v-icon
                        name="plus"
                        class="counter-button"
                        @click="handleIncrease(opt, answer)"
                        scale="0.8"
                      />
                    </b-col>
                    <b-col>
                      {{ opt.name }}
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
            <!-- si no está cargado entonces poner un cargando -->
            <ul v-else class="w-100">
              <b-row>
                <b-col class="text-center">
                  <b-spinner
                    style="width: 3rem; height: 3rem"
                    type="grow"
                    label="Loading..."
                  ></b-spinner>
                </b-col>
              </b-row>
            </ul>
            <div v-if="msg.type === 'custom-component'">
              <component
                :is="msg.component"
                :context="msg.context"
                :condition="condition"
              />
            </div>
          </div>
          <div>
            <b-row
              v-if="
                !msg.single_choice &&
                msg.answersLoaded &&
                !msg.isFinal &&
                msg.showAnswers
              "
              class="w-100 continue-button py-2 m-1"
              :class="msg.answered ? 'continue-off' : 'continue-on'"
              @click="continueChat"
            >
              <b-col> Listo! Siguiente </b-col>
              <b-col cols="2" class="col-sm-1">
                <v-icon
                  :name="
                    msg.answered
                      ? 'continue-arrow-white'
                      : 'continue-arrow-color'
                  "
                />
              </b-col>
            </b-row>
          </div>
          <div>
            <b-row
              v-if="msg.isFinal"
              class="w-100 reset-button py-2 m-1"
              @click="resetChat"
            >
              <b-col>Reiniciar chat</b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <!-- <b-col col lg="2" class="d-none d-sm-block">
        <b-card
          bg-variant="light"
          title="Costo:"
          style="position: sticky; top: 4em"
        >
          <b-card-text>
            <b>{{ cost }}</b>
            <b-img :src="appleIcon" width="22px" class="pl-1 mb-2 mx-0" />
          </b-card-text>
        </b-card>
      </b-col> -->
    </b-row>
  </b-container>
</template>
<script>
import informationService from "@/services/informationService";
import customTestService from "@/services/customTestService";
import chatBotService from "@/services/chatBotService";
import PlanificationTable from "./PlanificationTable";
import SpecialConditions from "./SpecialConditions.vue";

export default {
  components: {
    PlanificationTable,
    SpecialConditions,
  },
  name: "ChatBotComponent",
  data() {
    return {
      currentQuestionIndex: "type",
      answers: {},
      selectedCard: null,
      messages: [],
      prices: {},
      cost: 0,
      appleIcon: require("../../assets/img/icono-manzana.svg"),
      starIcon: require("../../assets/img/icono-estrella.svg"),
      types: ["text", "button", "quickReplies"],
      questions: {
        type: {
          name: "type",
          text: `👋 Hola ${this.$store.state.login.first_name}! Hagamos una planificación de clase. Elige el estilo de planificación`,
          type: "cards",
          answersLoaded: true,
          nextQuestionIndex: "period",
          single_choice: true,
          answers: [
            {
              title: "Regular",
              name: "Actividades regulares",
              cols: "col-6",
              isSelected: false,
              bootstrap_classes: "text-center",
              option: "basic",
              nextQuestionIndex: "period",
            },
            // {
            //   title: "Intermedio",
            //   name: "Lo mejor de ambos mundos",
            //   cols: "col-sm-4",
            //   bootstrap_classes: "text-center",
            //   isSelected: false,
            //   option: "hybrid",
            // },
            {
              title: "Diversificado",
              name: "Actividades con Diseño Universal (DUA)",
              cols: "col-6",
              bootstrap_classes: "text-center",
              isSelected: false,
              option: "accurate",
              nextQuestionIndex: "period",
            },
          ],
          routine: () => {
            // let selectedAnswer = this.currentQuestion.answers.find(
            //   (ans) => ans.isSelected
            // );
            // this.currentQuestionIndex = selectedAnswer.nextQuestionIndex;
            console.log("routine");
          },
        },
        period: {
          name: "period",
          text: `¿Qué tipo de planificación necesitas?`,
          type: "cards",
          answersLoaded: true,
          nextQuestionIndex: "curso",
          single_choice: true,
          answers: [
            {
              title: "Planificación",
              name: "Diaria",
              cols: "col-6",
              isSelected: false,
              bootstrap_classes: "text-center",
              option: "daily",
              nextQuestionIndex: "curso",
            },
            // {
            //   title: "Intermedio",
            //   name: "Lo mejor de ambos mundos",
            //   cols: "col-sm-4",
            //   bootstrap_classes: "text-center",
            //   isSelected: false,
            //   option: "hybrid",
            // },
            {
              title: "Planificación",
              name: "Mensual",
              cols: "col-6",
              bootstrap_classes: "text-center",
              isSelected: false,
              option: "monthly",
              nextQuestionIndex: "curso",
            },
          ],
          routine: () => {
            // let selectedAnswer = this.currentQuestion.answers.find(
            //   (ans) => ans.isSelected
            // );
            // this.currentQuestionIndex = selectedAnswer.nextQuestionIndex;
            console.log("routine");
          },
        },
        curso: {
          name: "curso",
          text: `¡Perfecto ${this.$store.state.login.first_name}! \n Dime ¿Con qué curso vamos a comenzar?`,
          hint: "Escoge solo un curso",
          type: "options",

          answersLoaded: false,
          single_choice: true,
          nextQuestionIndex: "asignatura",
          answers: [],
          process_dic: {
            "Pre-kinder": "PK",
            Kinder: "K",
            "1° básico": "1°",
            "2° básico": "2°",
            "3° básico": "3°",
            "4° básico": "4°",
            "5° básico": "5°",
            "6° básico": "6°",
            "7° básico": "7°",
            "8° básico": "8°",
            "1° medio": "1°M",
            "2° medio": "2°M",
            "3° medio": "3°M",
            "4° medio": "4°M",
          },
          getAnswers() {
            if (!this.answersLoaded) {
              return informationService.getPieGrades().then((data) => {
                data["grades"]
                  .filter((g) => ![14, 15, 16].includes(g[0]))
                  .forEach((ans) => {
                    this.answers.push({
                      original_name: ans[1],
                      name: this.process_dic[ans[1]],
                      code: ans[0],
                      isSelected: false,
                      cols: "col-sm-2 col-3",
                      bootstrap_classes: "font-weight-bold",
                      nextQuestionIndex: "asignatura",
                    });
                  });
                this.answersLoaded = true;
              });
            } else {
              return Promise.resolve(this.answers);
            }
          },
          routine() {
            console.log("routine");
            // let selectedAnswer = this.answers.find((ans) => ans.isSelected);
            // this.currentQuestionIndex = selectedAnswer.nextQuestionIndex;
          },
        },
        asignatura: {
          name: "asignatura",
          text: "¡Muy bien! Ahora dime ¿Con qué asignatura?",
          hint: "Escoge solo una asignatura",
          type: "options",
          answersLoaded: false,
          nextQuestionIndex: "oa",
          answers: [],
          single_choice: true,
          process_dic: {
            "Lenguaje y comunicación / Lengua y Literatura": "Lenguaje",
            Matemáticas: "Matemáticas",
            "Modelo ecológico funcional": "Modelo ecológico funcional",
            Música: "Música",
            "Historia, geografía y ciencias sociales": "Historia",
          },
          getAnswers() {
            if (!this.answersLoaded) {
              return informationService.getCardSubjectsValues().then((data) => {
                data
                  .filter((s) => [7, 29, 61, 8, 4].includes(s[0]))
                  .forEach((ans) => {
                    this.answers.push({
                      name: this.process_dic[ans[1]],
                      code: ans[0],
                      isSelected: false,
                      cols: "col-6",
                      nextQuestionIndex:
                        this.context.period.option === "daily"
                          ? "single_oa"
                          : "oa",
                    });
                  });
                this.answersLoaded = true;
              });
            } else {
              return Promise.resolve(this.answers);
            }
          },
          routine() {
            console.log("routine");
            // let selectedAnswer = this.answers.find((ans) => ans.isSelected);
            // this.currentQuestionIndex = selectedAnswer.nextQuestionIndex;
          },
        },
        oa: {
          name: "oa",
          text: () => {
            const curso = this.answers["curso"].original_name;
            const asignatura = this.answers["asignatura"].name;

            return `Estos son los objetivos de aprendizaje de ${curso} para  ${asignatura}<br> ¿Cuáles te gustaría trabajar?`;
          },
          hint: () => {
            const appleIcon = this.appleIcon;
            const starIcon = this.starIcon;

            return `Escoge tus objetivos de aprendizaje. <br> Las <img src='${starIcon}' width='22px' class='pl-1 mb-2'/></img> indican los OAs prioritarios <br> Cada OA cuesta 100 <img src='${appleIcon}' width='22px' class='pl-1 mb-2'/></img>`;
          },
          type: "options",
          answersLoaded: false,
          answers: [],
          nextQuestionIndex: "indicadores",
          single_choice: false,
          getAnswers() {
            if (!this.answersLoaded) {
              return chatBotService.getPrice({ code: "OA" }).then((prices) => {
                customTestService
                  .getHashtagsByContent(
                    this.context.asignatura.code,
                    this.context.curso.code
                  )
                  .then((data) => {
                    customTestService.getTagsFiltered(data[1]).then((d) => {
                      d.sort((a, b) => a.priority - b.priority).forEach(
                        (ans) => {
                          let short_name = ans.name.split(" ").slice(-1);
                          this.answers.push({
                            name:
                              "<strong style='font-size: 1.2rem;'>" +
                              short_name +
                              "</strong> - " +
                              ans.summary,
                            description: ans.description,
                            isSelected: false,
                            price: prices[0].price,
                            cols: "col-sm-12 col-12",
                            subtags: ans.subtags,
                            bootstrap_classes: "text-left px-2",
                            priority: ans.priority,
                          });
                        }
                      );
                      this.answersLoaded = true;
                    });
                  });
              });
            } else {
              return Promise.resolve(this.answers);
            }
          },

          routine() {
            console.log("routine");
            // this.currentQuestionIndex = this.nextQuestionIndex;
          },
        },
        single_oa: {
          name: "single_oa",
          text: () => {
            const curso = this.answers["curso"].original_name;
            const asignatura = this.answers["asignatura"].name;

            return `Estos son los objetivos de aprendizaje de ${curso} para  ${asignatura}<br> ¿Cuáles te gustaría trabajar?`;
          },
          hint: () => {
            const appleIcon = this.appleIcon;
            const starIcon = this.starIcon;

            return `Escoge tu objetivo de aprendizaje. <br> Las <img src='${starIcon}' width='22px' class='pl-1 mb-2'/></img> indican los OAs prioritarios <br> Cada OA cuesta 100 <img src='${appleIcon}' width='22px' class='pl-1 mb-2'/></img>`;
          },
          type: "options",
          answersLoaded: false,
          answers: [],
          single_choice: true,
          getAnswers() {
            if (!this.answersLoaded) {
              return chatBotService.getPrice({ code: "OA" }).then((prices) => {
                customTestService
                  .getHashtagsByContent(
                    this.context.asignatura.code,
                    this.context.curso.code
                  )
                  .then((data) => {
                    customTestService.getTagsFiltered(data[1]).then((d) => {
                      d.sort((a, b) => a.priority - b.priority).forEach(
                        (ans) => {
                          let short_name = ans.name.split(" ").slice(-1);
                          this.answers.push({
                            name:
                              "<strong style='font-size: 1.2rem;'>" +
                              short_name +
                              "</strong> - " +
                              ans.summary,
                            description: ans.description,
                            isSelected: false,
                            price: prices[0].price,
                            cols: "col-sm-12 col-12",
                            subtags: ans.subtags,
                            bootstrap_classes: "text-left px-2",
                            priority: ans.priority,
                            nextQuestionIndex: "single_indicadores",
                          });
                        }
                      );
                      this.answersLoaded = true;
                    });
                  });
              });
            } else {
              return Promise.resolve(this.answers);
            }
          },

          routine() {
            console.log("routine");
            // this.currentQuestionIndex = this.nextQuestionIndex;
          },
        },
        indicadores: {
          name: "indicadores",
          text: "Ahora, selecciona la cantidad de actividades por indicador de evaluación.",
          hint: () => {
            const appleIcon = this.appleIcon;
            return `Cada indicador cuesta 100 <img src='${appleIcon}' width='22px' class='pl-1 mb-2'/></img>`;
          },
          type: "options-counter",
          answersLoaded: true,
          nextQuestionIndex: "special_conditions",
          answers: [],
          getAnswers() {
            return new Promise((resolve) => {
              return chatBotService.getPrice({ code: "IND" }).then((prices) => {
                this.context.oa.forEach((ans) => {
                  let indicators = ans.subtags
                    .filter((s) => s.summary != null)
                    .map((i, index) => {
                      return {
                        name: `Ind. ${index + 1}: ${i.summary}`,
                        count: 0,
                        description: i.name,
                        price: prices[0].price,
                      };
                    });
                  this.answers.push({
                    name_section: ans.name,
                    has_warning: true,
                    warning_text:
                      "Debes tener al menos dos actividades seleccionadas!",
                    key: "oaInds",
                    required: 2,
                    options: indicators,
                    cols: "col-12",
                    oaInds: 0,
                    description: ans.description,
                  });
                });
                resolve();
              });
            });
          },
          routine() {
            // let selectedAnswer = this.currentQuestion.answers.find(
            //   (ans) => ans.isSelected
            // );

            // iterar en answers y por cada answer iterar en options y eliminar los que tengan count 0 y también eliminar las answer con options vacíos

            this.answers.map((ans) => {
              ans.options = ans.options.filter((opt) => opt.count > 0);
              return ans.options.length > 0;
            });
          },
        },
        single_indicadores: {
          name: "single_indicadores",
          text: "Ahora, selecciona los indicadores para tu planificación.",
          hint: () => {
            const appleIcon = this.appleIcon;
            return `Cada indicador cuesta 100 <img src='${appleIcon}' width='22px' class='pl-1 mb-2'/></img><br>Puedes elegir hasta 3 indicadores.`;
          },
          type: "options",
          single_choice: false,
          answersLoaded: false,
          max: 3,
          nextQuestionIndex: "special_conditions",
          answers: [],
          getAnswers() {
            if (!this.answersLoaded) {
              return chatBotService.getPrice({ code: "IND" }).then((prices) => {
                let index = 0;
                this.context.single_oa.subtags.forEach((i) => {
                  let short_name = `Ind. ${index + 1}: `;
                  index++;
                  this.answers.push({
                    name:
                      "<strong style='font-size: 1.2rem;'>" +
                      short_name +
                      "</strong> " +
                      i.summary,
                    description: i.name,
                    isSelected: false,
                    price: prices[0].price,
                    cols: "col-sm-12 col-12",
                    bootstrap_classes: "text-left px-2",
                    nextQuestionIndex: "special_conditions",
                  });
                });
                this.answersLoaded = true;
              });
            } else {
              return Promise.resolve(this.answers);
            }
          },
          routine() {
            console.log("routine");
          },
        },
        special_conditions: {
          name: "special_conditions",
          text: "¿Hay algo más que te gustaría agregar o personalizar para tu clase? Puedes incluir detalles sobre la temática, requisitos especiales o cualquier otro aspecto que desees.",
          type: "options",
          answersLoaded: false,
          single_choice: true,
          answers: [
            {
              name: "Sí",
              isSelected: false,
              nextQuestionIndex: "special_conditions_text",
              hasSpecialConditions: true,
            },
          ],
          getAnswers() {
            if (!this.answersLoaded) {
              return new Promise((resolve) => {
                let planificationType = this.context.period.option;
                this.answers.push({
                  name: "No",
                  isSelected: false,
                  nextQuestionIndex:
                    planificationType === "daily"
                      ? "daily_planification"
                      : "planificacion",
                  hasSpecialConditions: false,
                });
                this.answersLoaded = true;
                resolve();
              });
            }
          },
          routine() {
            console.log("routine");
          },
        },
        special_conditions_text: {
          name: "special_conditions_text",
          text: "Escribe aquí lo que deseas agregar",
          type: "input-text",
          answersLoaded: false,
          required: true,
          answers: [
            {
              name: "special_conditions_input",
              bootstrap_classes: "text-left px-2",
            },
          ],
          nextQuestionIndex: "daily_planification",
          getAnswers() {
            if (!this.answersLoaded) {
              return new Promise((resolve) => {
                let planificationType = this.context.period.option;
                this.nextQuestionIndex =
                  planificationType === "daily"
                    ? "daily_planification"
                    : "planificacion";
                this.answersLoaded = true;
                console.log({ nextQuestionIndex: this.nextQuestionIndex });
                resolve();
              });
            }
          },
          routine() {
            console.log("routine");
          },
        },
        daily_planification: {
          name: "daily_planification",
          text: "Muy bien. Esta es la planificación que has creado",
          type: "custom-component",
          answersLoaded: true,
          answers: [],
          component: PlanificationTable,
          getAnswers() {
            return new Promise((resolve) => {
              resolve();
            });
          },
          isFinal: true,
        },
        planificacion: {
          name: "planificacion",
          text: "Muy bien. Esta es la planificación que has creado",
          type: "custom-component",
          answersLoaded: true,
          answers: [],
          component: PlanificationTable,
          getAnswers() {
            return new Promise((resolve) => {
              resolve();
            });
          },
          isFinal: true,
        },
      },
      condition: "",
    };
  },
  mounted() {
    // this.messages.push({ ...this.questions[this.currentQuestionIndex] });
    // Escucha el evento para desplazarse automáticamente al agregar nuevo contenido
    // this.$nextTick(() => {
    //   const container = document.querySelector(".global-container");
    //   container.addEventListener("DOMNodeInserted", this.scrollToBottom);
    // });

    this.loadQuestion(this.currentQuestionIndex);
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIndex];
    },
    school() {
      return this.$store.state.login.school;
    },
    year() {
      return this.$store.state.login.year;
    },
    isAdmin() {
      return this.$store.state.login.isAdmin;
    },
  },
  methods: {
    handleCardClick(answer, msgidx, ansidx, max) {
      if (this.messages[msgidx].name === this.currentQuestion.name) {
        this.selectCard(msgidx, ansidx, answer, max);
        this.selectAnswer();
      }
    },
    handleIncrease(opt, answer) {
      if (opt.price) {
        if (opt.count === 0) {
          if (this.cost + opt.price <= this.$store.state.login.points) {
            this.cost += opt.price;
            opt.count++;
            answer.oaInds++;
          } else {
            this.$toasted.error(
              "No cuentas con aportes suficientes para realizar esta acción!"
            );
          }
        } else {
          if (opt.count < 2) {
            opt.count++;
            answer.oaInds++;
          }
        }
      } else {
        opt.count++;
        answer.oaInds++;
      }
    },
    handleDecrease(opt, answer) {
      if (opt.count > 0) {
        if (opt.price && opt.count === 1) {
          this.cost -= opt.price;
          opt.count--;
          answer.oaInds--;
        } else {
          opt.count--;
          answer.oaInds--;
        }
      }
    },
    continueChat() {
      // hacer switch para disstintos tipos de pregunta
      switch (this.currentQuestion.type) {
        case "options":
          if (this.currentQuestion.single_choice) {
            this.answers[this.currentQuestion.name] =
              this.currentQuestion.answers.find((ans) => ans.isSelected);
          } else {
            this.answers[this.currentQuestion.name] =
              this.currentQuestion.answers.filter((ans) => ans.isSelected);
          }
          break;
        case "options-counter":
          this.answers[this.currentQuestion.name] =
            this.currentQuestion.answers;
          break;

        case "cards":
          if (this.currentQuestion.single_choice) {
            this.answers[this.currentQuestion.name] =
              this.currentQuestion.answers.find((ans) => ans.isSelected);
          } else {
            this.answers[this.currentQuestion.name] =
              this.currentQuestion.answers.filter((ans) => ans.isSelected);
          }
          break;
        default:
          break;
      }

      this.messages.slice(-1)[0].answered = true;
      if (!this.currentQuestion.isFinal) {
        this.currentQuestion.routine();
        if (!this.currentQuestion.single_choice) {
          this.currentQuestionIndex = this.currentQuestion.nextQuestionIndex;
        } else {
          this.currentQuestionIndex =
            this.answers[this.currentQuestion.name].nextQuestionIndex;
        }
        this.loadQuestion(this.currentQuestionIndex);
      }
    },
    selectCard(msgidx, answeridx, answer, max) {
      if (this.currentQuestion.single_choice) {
        this.messages[msgidx].answers.forEach((ans) => {
          if (ans.price && ans.isSelected) {
            this.cost -= ans.price;
          }
          ans.isSelected = false;
        });
      }
      if (answer.price && !answer.isSelected) {
        if (this.cost + answer.price <= this.$store.state.login.points) {
          if (
            !max ||
            (max &&
              this.messages[msgidx].answers.filter((ans) => ans.isSelected)
                .length < max)
          ) {
            this.cost += answer.price;
            answer.isSelected = true;
          } else {
            this.$toasted.error(
              "Ya has seleccionado el máximo de " + max + " elementos"
            );
          }
        } else {
          this.$toasted.error(
            "No cuentas con aportes suficientes para realizar esta acción!"
          );
        }
      } else {
        if (answer.price && answer.isSelected) {
          this.cost -= answer.price;
        }
        if (
          answer.isSelected ||
          !max ||
          (max &&
            this.messages[msgidx].answers.filter((ans) => ans.isSelected)
              .length < max)
        ) {
          this.messages[msgidx].answers[answeridx].isSelected =
            !this.messages[msgidx].answers[answeridx].isSelected;
        } else {
          this.$toasted.error(
            "Ya has seleccionado el máximo de " + max + " elementos"
          );
        }
      }
    },
    resetValues() {
      this.selectedCard = null;
    },

    setText(sourceObj, keyObj, targetObj, keyTarget) {
      return new Promise((resolve) => {
        // resolve content
        let content =
          typeof sourceObj[keyObj] === "function"
            ? sourceObj[keyObj]()
            : sourceObj[keyObj];

        // type effect
        let i = 0;
        // targetObj[keyTarget] = "";
        let timer = setInterval(() => {
          if (i < content.length && content.charAt(i)) {
            targetObj[keyTarget] += content.charAt(i);

            i++;

            this.$forceUpdate();
          } else {
            clearInterval(timer);
            resolve();
          }
        }, 10);
      });
    },
    loadQuestion(questionIndex) {
      let question = this.questions[questionIndex];

      let { text, hint, ...msg } = question;
      console.log(text, hint);
      msg.context = this.answers;
      msg.text = "";
      msg.hint = "";
      msg.showHint = false;
      if (msg.isFinal) {
        // Eliminar el event listener antes de agregarlo nuevamente
        const container = document.querySelector(".global-container");
        container.removeEventListener("DOMNodeInserted", this.scrollToBottom);
        container.addEventListener("DOMNodeInserted", this.scrollToBottom);
      }

      if (msg.getAnswers) {
        msg
          .getAnswers(this)
          .then(() => {
            this.messages.push(msg);
            this.setText(question, "text", msg, "text").then(() => {
              if (hint) {
                msg.showHint = true;
                this.setText(question, "hint", msg, "hint").then(() => {
                  msg.showAnswers = true;
                  this.$forceUpdate();

                  this.$nextTick(() => {
                    const newMessageRef = this.$refs[`msgRef-${msg.name}`];
                    this.scrollToMsg(newMessageRef);
                  });
                });
              } else {
                msg.showAnswers = true;
                this.$forceUpdate();

                this.$nextTick(() => {
                  const newMessageRef = this.$refs[`msgRef-${msg.name}`];
                  this.scrollToMsg(newMessageRef);
                });
              }
            });
          })
          .catch((err) => {
            console.log("Error loading answers:", err);
          });
      } else {
        this.messages.push(msg);

        this.setText(question, "text", msg, "text").then(() => {
          if (hint) {
            msg.showHint = true;
            this.setText(question, "hint", msg, "hint").then(() => {
              msg.showAnswers = true;
              this.$forceUpdate();

              this.$nextTick(() => {
                const newMessageRef = this.$refs[`msgRef-${msg.name}`];
                this.scrollToMsg(newMessageRef);
              });
            });
          } else {
            msg.showAnswers = true;
            this.$forceUpdate();

            this.$nextTick(() => {
              const newMessageRef = this.$refs[`msgRef-${msg.name}`];
              this.scrollToMsg(newMessageRef);
            });
          }
        });
      }
    },
    selectAnswer() {
      if (this.currentQuestion.single_choice) {
        this.continueChat();
      }
    },
    scrollToBottom() {
      let container = document.querySelector(".global-container");
      // container.scrollIntoView();
      window.scrollTo(0, container.scrollHeight);
    },

    scrollToMsg(msgRef) {
      const parentMsg = msgRef[0].parentNode;
      parentMsg.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      // const scrollOffset = parentMsg.offsetTop;

      // window.scrollTo(0, scrollOffset);
    },
    resetChat() {
      // this.messages = [];
      // this.answers = {};
      // this.currentQuestionIndex = "type";
      // this.loadQuestion(this.currentQuestionIndex);
      this.$emit("toggle-reload");
    },
    // Actualizar la condicion con lo que nos llega desde el componente SpecialConditions
    updateCondition(condition) {
      this.condition = condition;
    },
  },
  watch: {
    cost(val) {
      this.$emit("set-cost", val);
    },
  },
};
</script>
<style>
.global-container {
  margin: 0;
  padding: 0;
  min-height: 100%;
  background-color: #ffffff !important;
}
.image-container {
  background-image: url("../../assets/img/botpie/mancha.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center bottom;
}
.chat-box {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding-left: 10px;
  padding-right: 10px;
}
.box-list-answer {
  display: flex;
  list-style-type: none;
  padding-left: 10px;
  padding-right: 10px;
}
.chat-box-container {
  margin-bottom: 1px;
}

.box-list-span {
  padding: 8px;
  color: white;
  border-radius: 4px;
}
.box-list-question {
  float: left;
  background-color: rgb(255, 255, 255, 0.5);
  color: rgb(0, 0, 0);
  border: 1pt dashed rgb(0, 185, 174, 0.5);
  border-radius: 10px;
  font-family: "Ubuntu Regular", Arial, sans-serif;
}
.box-list-question p {
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
}
.box-list-answer {
  float: right;
  /* background-color: white; */
}

.box-question,
.box-answer {
  width: 100%;
  height: auto;
}

.question-hint {
  color: rgb(0, 0, 0, 0.5);
}

.chat-box {
  margin: 10px;
  width: 50vw;
  margin-left: auto;
  margin-right: auto;
  align-items: space-between;
  justify-content: space-between;
}

.chat-inputs {
  /* display: flex; */
  float: right;
}
.chat-send {
  float: right;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}
/* CARD OPTIONS */
.selected-card {
  background-color: rgb(0, 185, 174) !important;
  color: rgb(0, 0, 0) !important;
}
.option-card {
  border: 1pt solid rgb(0, 185, 174) !important;
  cursor: pointer;
  max-height: 30rem;
  border-radius: 5px !important;
  font-family: "Ubuntu Medium", Arial, sans-serif;
  color: #000000;
}
.option-option {
  max-height: 30rem;
  color: #000000;
  cursor: pointer;
  border: 1pt solid rgb(0, 185, 174) !important;
  border-radius: 5px !important;
  font-family: "Ubuntu Medium", Arial, sans-serif;
  .card-body {
    padding: 0 !important;
  }
}

/* LITTLE CARD OPTIONS */
.selected-option {
  background-color: rgb(0, 185, 174) !important;
  border-color: #000000 !important;
}

/* CONTINUE BUTTON */
.continue-button {
  border-radius: 5px;
  border: 2pt solid rgb(0, 0, 0) !important;
  font-family: "Ubuntu Medium", Arial, sans-serif;
  font-weight: bold; /* Aplica negrita al texto */
}
.continue-on {
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}
.continue-off {
  background-color: rgb(0, 185, 174);

  cursor: not-allowed;
}

/* COUNTER OPTIONS*/

.counter-section {
  float: left;
  background-color: rgb(255, 255, 255, 0.5);
  color: rgb(0, 0, 0);
  border: 1pt dashed rgb(0, 185, 174, 0.5);
  border-radius: 10px;
  font-family: "Ubuntu Medium", Arial, sans-serif;
}

.counter-option {
  float: left;
  background-color: rgb(255, 255, 255, 0.5);
  color: rgb(0, 0, 0);
  border: 1pt dashed rgb(0, 185, 174, 0.5);
  border-radius: 10px;
  font-family: "Ubuntu Medium", Arial, sans-serif;
  font-weight: bold; /* Aplica negrita al texto */
}

input.no-arrow::-webkit-inner-spin-button,
input.no-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-no-border {
  border: none !important;
  outline: none !important;
}
.input-counter {
  text-align: center;
  font-weight: bold;
}
.counter-container {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1pt solid rgb(0, 185, 174, 0.5);
  border-radius: 10px;
  height: min-content;
  font-size: 0.5rem;
}
.counter-button {
  cursor: pointer;
}

html {
  scroll-behavior: smooth;
}

input[type="number"]:disabled {
  background: #ffffff;
}

/* RESET BUTTON */
/* CONTINUE BUTTON */
.reset-button {
  border-radius: 5px;
  border: 2pt solid rgb(0, 0, 0) !important;
  font-family: "Ubuntu Medium", Arial, sans-serif;
  font-weight: bold; /* Aplica negrita al texto */
  cursor: pointer;
}
</style>
