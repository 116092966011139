<template>
  <b-modal
    centered
    ref="incomponent-modal-choose-template"
    hide-header
    hide-footer
  >
    <b-container v-if="!loading">
      <b-row align-h="center">
        <strong>Elige la plantilla que vas a usar</strong>
      </b-row>
      <b-list-group>
        <b-list-group-item
          button
          v-for="(template, index) in templates"
          :key="index"
          @click="
            $emit('template-chose', template.id, sg_dt_id, studentIndex, documentIndex, changeLoading, isOnline);
            close();"
        >
          {{template.name}}
        </b-list-group-item>
      </b-list-group>
    </b-container>
    <b-container v-else>
      <b-row class="d-flex justify-content-center">
        <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
export default {
  data () {
    return {
      templates: [],
      sg_dt_id: "",
      studentIndex: "",
      documentIndex: "",
      loading: false,
      isOnline: false
    }
  },
  methods: {
    close: function() {
      this.$refs["incomponent-modal-choose-template"].hide();
    },
    open(templates, docType, studentIndex, documentIndex, isOnline) {
      this.isOnline = isOnline;
      this.loading = false;
      this.templates = templates;
      this.sg_dt_id = docType;
      this.studentIndex = studentIndex;
      this.documentIndex = documentIndex;
      this.$refs["incomponent-modal-choose-template"].show();
    },
  }
}
</script>
