<template>
  <div class="feedback-box p-2 my-4">
    <b-form v-if="!sended" class="text-center">
      <!-- create a feedback modal with starts and a textarea  con un titulo de 'tu opinion es muy importante para nosotros'-->

      <b-form-group label="Tu opinión es muy importante para nosotros">
        <b-form-rating v-model="score" variant="warning" />
      </b-form-group>
      <b-form-textarea
        id="textarea"
        v-model="opinion"
        placeholder="¿Qué opinas?😱
  ¿Este tipo de herramientas te alivianan la pega?
  Cuéntame cómo puedo ayudarte un poco más... porfavor 🙏"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
      <b-button class="mt-3 custom-button" @click="sendFeedback()">
        Enviar
      </b-button>
    </b-form>
    <div v-else>
      <b-alert
        show
        variant="success"
        class="mt-3"
        dismissible
        fade
        @dismissed="sended = false"
      >
        <b>¡Gracias por tu opinión!</b>
      </b-alert>
    </div>
  </div>
</template>
<script>
import userService from "@/services/userService";
export default {
  name: "FeedbackComponent",

  data() {
    return {
      score: 0,
      opinion: "",
      sended: false,
    };
  },
  methods: {
    sendFeedback() {
      if (!this.opinion || this.opinion.length < 5) {
        this.$toasted.error("Por favor escribe un mensaje más largo.");
        return;
      }
      userService
        .send_feedback({ text: this.opinion, code: 2, score: this.score })
        .then(
          (data) => {
            this.$toasted.success(data.message);
            this.opinion = "";
            this.score = 0;
            this.sended = true;
          },
          (error) => {
            this.$toasted.error(
              "Error al mandar tu opinión, por favor inténtalo mas tarde."
            );
            console.error(error);
          }
        );
      this.$bvModal.hide("modal-feedback");
    },
  },
};
</script>
<style scoped>
.custom-button {
  background-color: #00b9ae;
}
.feedback-box {
  border: #00b9ae solid 1px;
  border-radius: 10px;
}
</style>
