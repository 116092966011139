<template>
  <b-modal ref="pass-modal" hide-header hide-footer centered>
    <b-container>
      <b-row>
        <h4>Cambiar contraseña</h4>
      </b-row>
      <b-row>
        <b-col>
          Ingresa tu contraseña actual:
        </b-col>
        <b-col>
          <b-form-input type="password" placeholder="Contraseña actual..." v-model="currentPassword" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          Ingresa tu nueva contraseña:
        </b-col>
        <b-col>
          <b-form-input type="password" placeholder="Contraseña nueva..." v-model="newPassword" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          Reingresa tu nueva contraseña:
        </b-col>
        <b-col>
          <b-form-input type="password" placeholder="Repetir contraseña..." v-model="reNewPassword" />
        </b-col>
      </b-row>
      <b-row>
        <b-button variant="primary" @click="changePassword">Cambiar</b-button>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import authService from "../../../services/authService";

export default {
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
    }
  },
  methods: {
    open() {
      this.currentPassword = "",
      this.newPassword = "",
      this.reNewPassword = ""
      this.$refs['pass-modal'].show();
    },
    changePassword() {
      if (this.newPassword !== this.reNewPassword) {
        this.$toasted.error('Contraseñas no coinciden');
        return;
      }
      authService.changePassword({
        current_password: this.currentPassword,
        new_password: this.newPassword,
        re_new_password: this.reNewPassword}).then(
          data => {
            this.$toasted.success(data.message)
          },
          error => {
            var message = "Ha ocurrido un error al cambiar tu contraseña";
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              message = error.response.data.message;
            }
            this.$toasted.error(message);
          }
        )
    }
  }
}
</script>
<style lang="scss">
</style>
