<template>
  <b-container class="contact" fluid>
    <div class="p-4">
      <b-row>
        <b-col class="p-4" lg="6">
          <b-form class="mx-auto responsive-width" @submit.prevent="sendContact">
            <h5 class="text-warning font-weight-bold">Contáctanos</h5>
            <div class="form-group">
              <b-input v-model="username" class="form-control rounded-pill b0" name="name" placeholder="Nombre Completo"
                       required type="text"/>
            </div>
            <div class="form-group">
              <b-input v-model="email" class="form-control rounded-pill b0" name="email" placeholder="ejemplo@correo.cl"
                       required type="email"/>
            </div>
            <div class="form-group">
              <b-textarea v-model="message" class="form-control b0" placeholder="Escribir mensaje" required
                          style="border-radius: 20px" rows="5"/>
            </div>
            <div class="text-center">
              <b-button class="rounded-pill font-weight-bold px-4" type="submit" variant="primary">Enviar</b-button>
            </div>
          </b-form>
        </b-col>
        <b-col class="p-4" lg="6">
          <b-row>
            <b-col class="mt-4" cols="6" offset-lg="1" lg="4">
              <p class="h6 font-weight-bold text-warning ml-0">Teléfono:</p>
              <p class="h6 ml-0 mb-4">+569 9 223 88 89</p>
              <p class="h6 ml-0 mb-4">+569 5 066 41 86</p>
              <p class="h6 font-weight-bold text-warning ml-0">E-mail:</p>
              <p class="h6 ml-0">contacto@redpie.cl</p>
              <p class="h6 font-weight-bold text-warning ml-0 w-100 mt-4">Proyecto apoyado por:</p>
            </b-col>
            <b-col class="p-0" cols="4" offset="2" lg="4" offset-lg="1">
              <b-img :src="nino" class="responsive-height mx-auto d-block" rounded="circle"></b-img>
            </b-col>
          </b-row>
          <b-row class="mt-0 mb-4 pt-0">
            <b-col offset-lg="1">
              <div class="rounded p-2" style="background-color: #fdfdfd; max-height: 100px">
                <b-img :src="startup" class="p-2 bordered" style="width: 65%; height: inherit"/>
                <b-img :src="corfo" class="w-25 ml-3" style="height: inherit"/>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import redpieService from "@/services/redpieService";

export default {
  name: "ContactUs",
  props: {
    route: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      nino: require("../../../assets/img/sales/ninoredpie.svg"),
      startup: require("../../../assets/img/sales/startup.png"),
      corfo: require("../../../assets/img/sales/corfo.png"),
      username: '',
      email: '',
      message: ''
    }
  },
  methods: {
    sendContact() {
      const payload = {
        name: this.username,
        email: this.email,
        message: this.message
      }
      redpieService.sendSalesContact(payload).then(
          response => {
            this.$toasted.success(response)
            this.reset()
          }
      ).catch(
          error => {
            console.log(error);
            this.$toasted.error("Ocurrió un error al enviar el mensaje")
          }
      )
    },
    reset(){
      this.username = ''
      this.email = ''
      this.message = ''
    }
  }
}
</script>

<style scoped>
.contact {
  color: ghostwhite;
  background-image: url("~@/assets/img/sales/contacto.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #363940;
}

.b0 {
  border-width: 0 !important;
}

.responsive-height {
  height: 12em;
  margin-right: 0;
}

.bordered {
  border-right: 2px solid #333;
}

@media (min-width: 900px) {
  .responsive-width {
    width: 75%;
  }
}

@media (max-width: 500px) {
  .responsive-height {
     height: 8em;
  }
}
</style>