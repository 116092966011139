<template>
    <svg version="1.1" id="Layer_1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    :viewBox="viewbox"
    :width="width"
    :height="height"
    xml:space="preserve"
    :class="on ? 'custom-icon-button':''">
  

   <g v-if="on">
    <path class="st0-on" d="M2.1,10.6L8.4,16l14.5,12.4c2.3,2,5.7,2,7.9,0l14.7-12.5l6.3-5.4C56,6.9,53.4,0,47.8,0H26.9H6
	C0.4,0-2.2,6.9,2.1,10.6z"/>
    </g>

   <g v-else>
    <path class="st0-off" d="M2.1,10.6L8.4,16l14.5,12.4c2.3,2,5.7,2,7.9,0l14.7-12.5l6.3-5.4C56,6.9,53.4,0,47.8,0H26.9H6
	C0.4,0-2.2,6.9,2.1,10.6z"/>
  </g>

  

  </svg>
  </template>
  
  <script>
  export default {
      name: 'ArrowDownIcon',
      props: {
        viewbox: {
              type: String,
              default: "0 0 18 18"
        },
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },
          on:{
            type: Boolean,
            default: true
            } 
          },
   
  }
  </script>
  
<style scoped>
.st0-off{ 
    fill:#F3F3F3;
    }
.st0-on{ 
    fill:#F8B500;
    }
.custom-icon-button{
    cursor:pointer;
}
</style>