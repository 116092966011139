<template>
  <b-modal centered ref="incomponent-modal-trigger-bot" hide-footer hide-header>
    <div class="text-center" v-if="loading">
      <b-spinner variant="success" label="Spinning"></b-spinner>
      <h1>¡ESTAMOS BUSCANDO A SUS ESTUDIANTES!</h1>
      <h1>ESPERE UN MOMENTO</h1>
    </div>
    <b-container v-else-if="alreadyBot">
    <div v-for="(group, index) in tabs[0].groups" v-bind:key="index">
      <ModalPieStudentSpecialTable 
        :group="group"
        v-on:reload-grades="reloadGrades" />
    </div>
    </b-container>
    <b-container v-else>
      <b-row>
        <h3>Ingrese Credenciales</h3>
      </b-row>
      <b-row align-h="center">
        <b-col class="custom-col">
          RBD:
        </b-col>
        <b-col>
          <b-form-input v-model="rbd" placeholder="Rbd..." type="text" />
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col class="custom-col">
          CONTRASEÑA:
        </b-col>
        <b-col>
          <b-form-input
            v-model="password"
            placeholder="Contraseña..."
            type="password"
          />
        </b-col>
      </b-row>
      <b-row align-h="end" style="padding-right: 15px;">
        <b-button variant="primary" @click="patchSchool">
          BUSCAR ESTUDIANTES!
        </b-button>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import redpieService from "../../../services/redpieService";
import ModalPieStudentSpecialTable from "../modals/ModalPieStudentSpecialTable"

export default {
  components: {
    ModalPieStudentSpecialTable,
  },
  props: {
    tabs: {
      type: Array,
      required: true
    },
    alreadyBot: {
      type: Boolean,
      required: true,
    },
    school: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      rbd: "",
      password: "",
      status: "",
      loading: false,
      schoolCreds: false,
    };
  },
  created() {
  },
  methods: {
    open() {
      this.$refs["incomponent-modal-trigger-bot"].show();
    },
    formater: function(rbd) {
      if (rbd.includes("-")) {
        return rbd;
      } else if (!rbd.includes("-")) {
        var dv = rbd.slice(-1);
        var rbds = rbd.slice(0, -1).concat("-");
        rbd = rbds.concat(dv);
        return rbd;
      }
    },
    patchSchool: function() {
      this.loading = true;
      redpieService
        .triggerSIGEBot(this.$store.state.login.school, {
          rbd: this.rbd,
          password: this.password,
        })
        .then((data) => {
          if (data.message == "all ok") {
            this.status = "ok";
            this.$toasted.success("Las credenciales están correctas");
          } else if (data.message == "error") {
            this.$toasted.error("Las Credenciales están incorrectas");
          }
        })
        .then(() => {
          if (this.status == "ok") {
            redpieService.editSchoolRbdSigePass(this.$store.state.login.school, {
              rbd: this.rbd,
              sige_pass: this.password,
            });
            this.$toasted.success("Los estudiantes han sido actualizados");
            this.school.rbd = this.rbd;
            this.school.sige_pass = this.password;
            this.loading = false;
          }
          else {
            this.$toasted.error("Los estudiantes no han sido actualizados");
            this.loading = false;
          }

        });
    },
    reloadGrades: function() {
      this.$emit("reload-grades")
    }
  },
};
</script>
<style lang="scss">
.row {
  padding-bottom: 5px;

  .custom-col {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 0px !important;
  }
}
</style>
