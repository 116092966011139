<template>
    <b-modal size="xl" hide-footer hide-header ref="instruction-modal" no-close-on-backdrop> 
        <b-container>
            <b-form-row>
                  <b-col cols="10">
                  <b-form-textarea 
                    @blur="changed=true"
                    v-model="additional_instruction.instruction" class="w-100" name="question"
                            placeholder="Ingresa tu instrucción adicional" size="lg"/>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col cols="2">
                    Fórmulas adicionales
                </b-col>
                <b-col cols="2">
                    <b-button class="mx-1 btn-circle-lg" pill size="lg" 
                        style="font-size: 1rem; font-style: italic;" variant="info"
                                @click="() => {additional_instruction.formulas.push('x=1+1'); changed = true}">
                        f(x)
                  </b-button>
                </b-col>
            </b-form-row>
            <b-form-row v-for="(formula, findex) in additional_instruction.formulas" :key="findex">
                <b-col>
                  <math-field 
                    @blur="((event) => {changeAdditional(findex, event.target.value);})" 
                    id="formula" 
                    virtual-keyboard-mode="manual"
                    style="height:50px;
                           font-size:20pt;"
                  >
                    {{formula}}
                  </math-field>
                </b-col>
            </b-form-row>
            <b-row>
                <b-col class="text-center">
                    <VueRecordAudio class="btn-recording" @result="handleAudio" mode="press"/>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-center">
                    O sube tu archivo de audio
                </b-col>
            </b-row>
            <b-row>
                <b-form-file
                    class="p-2 m-2"
                    placeholder="Haz clic para navegar o arrastra y suelta"
                    drop-placeholder="Arrastra aquí"
                    accept="audio/*, video/mp4"
                    @input="handleAudio"
                />
            </b-row>
            <b-row v-if="audio" class="p-2 m-2 text-left">
                <h6>Puedes escuchar tu grabación aquí: </h6>
                <audio controls :key="key" class="mx-auto w-100">
                    <source :src="audio">
                    Tu navegador no soporta la reproducción de audio
                </audio>
            </b-row>
            <b-row>
                Imagen
                <b-form-file
                    class="p-2 m-2"
                    placeholder="Haz clic para navegar o arrastra y suelta"
                    drop-placeholder="Arrastra aquí"
                    accept="image/*"
                    @input="handleImage"
                />
                Imagen previa:
                <b-img :src="img_url" width="300"/>
            </b-row>
            <b-row v-if="canDelete">
                <b-checkbox v-model="del" :unchecked-value="false" :value="true">Eliminar</b-checkbox>
            </b-row>
            <b-row>
                <b-col class="text-right">
                    <b-button variant="danger" @click="close" pill>
                        Cerrar
                    </b-button>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>    
</template>
<script>
export default {
    data() {
        return {
            question: null,
            additional_instruction: {
                audio: '',
                formulas: [],
                image: '',
                instruction: ''
            },
            key: false,
            audio: "",
            audioBlob: null,
            img: null,
            img_url: '',
            changed: false,
            canDelete: false,
            del: false
        }
    },
    methods: {
        open(question){
            this.question = question;
            if (question.additional_instruction && Object.keys(question.additional_instruction)) {
                this.canDelete = true;
                this.additional_instruction = question.additional_instruction;
                if (question.additional_instruction.audio) {
                    this.audio = question.additional_instruction.audio;
                    fetch(question.additional_instruction.audio).then(res => res.blob().then(
                        blob => {
                            this.audioBlob = blob;
                        }
                        )
                    );
                }
                if (question.additional_instruction.temp_audio) {
                    this.audioBlob = question.additional_instruction.temp_audio;
                    this.audio = window.URL.createObjectURL(question.additional_instruction.temp_audio)
                }
                if (question.additional_instruction.image) {
                    this.img_url = question.additional_instruction.image;
                }
                if (question.additional_instruction.temp_img) {
                    this.img = question.additional_instruction.temp_img;
                    this.img_url = window.URL.createObjectURL(question.additional_instruction.temp_img)
                }
                if (question.additional_instruction.del) {
                    this.del = question.additional_instruction.del;
                }
                else {
                    this.del = false;
                }
            }
            else {
                this.canDelete = false;
                this.additional_instruction = {
                    audio: '',
                    formulas: [],
                    image: '',
                    instruction: ''
                }
                this.audio = "";
                this.audioBlob = null;
                this.img = null;
                this.img_url = '';
                this.del = false;
            }
            this.changed = false;
            this.key = !this.key;
            this.$refs["instruction-modal"].show();
        },
        handleAudio(data) {
            this.audioBlob = data;
            this.audio = window.URL.createObjectURL(data)
            this.changed = true;
            this.key = !this.key;
        },
        handleImage(data) {
            this.changed = true;
            this.img = data;
        },
        changeAdditional(findex, val) {
            this.additional_instruction.formulas[findex] = val;
            this.changed = true;
        },
        close() {
            if (this.del) {
                this.additional_instruction.changed = true;
                this.additional_instruction.del = true;

            }
            else {
                if (this.audioBlob) {
                    this.additional_instruction.temp_audio = this.audioBlob;
                }
                if (this.img) {
                    this.additional_instruction.temp_img = this.img;
                }
                this.additional_instruction.changed = this.changed
            }
            this.question.additional_instruction = this.additional_instruction;
            this.$refs["instruction-modal"].hide()
        }
    }
}
</script>
<style lang="scss">

</style>
