<template>
    <svg version="1.1" id="Layer_1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    :viewBox="viewbox"
    :width="width"
    :height="height"
    xml:space="preserve"
    class="custom-icon-button">
  
    <g>
        <circle class="st0-mic" cx="43" cy="43" r="43"/>
        <path class="st1-mic" d="M43,16.3c-5.3,0-9.6,4.3-9.6,9.6v16.7c0,5.3,4.3,9.6,9.6,9.6s9.6-4.3,9.6-9.6V26C52.6,20.7,48.3,16.3,43,16.3
            L43,16.3z M28,38.9c-1.1,0-2.1,1-2.1,2.1v2.7c0,8.7,6.6,15.9,15,17v4.9h-4.8c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h13.7
            c1.1,0,2-0.9,2-2s-0.9-2-2-2H45v-4.9c8.5-1,15-8.2,15-17V41c0-1.1-1-2.1-2-2.1c-1.1,0-2.1,1-2,2.1v2.7c0,7.2-5.8,13-13,13
            s-13-5.8-13-13V41C30,39.9,29,38.9,28,38.9L28,38.9z"/>
    </g>

  

  </svg>
  </template>
  
  <script>
  export default {
      name: 'MicrophoneIcon',
      props: {
        viewbox: {
              type: String,
              default: "0 0 18 18"
        },
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },   
  }
}
  </script>
  
<style>
.st0-mic{
    fill:#00B9AE;
}
.st1-mic{
    fill:#FFFFFF;
}
.custom-icon-button{
    cursor:pointer;
}
</style>