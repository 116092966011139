<template>
  <b-modal ref='add-material' size="lg" hide-footer hide-header>
    <b-overlay :show="loading" rounded="sm">
      <b-container>
        <b-row><h3>Agregar material</h3></b-row>
        <b-row v-if="askSchools">
          <b-col cols="2" class="text-right pt-2">
            Colegios:
          </b-col>
          <b-col>
            <b-select v-model="selectedSchool" class="mb-2">
              <b-form-select-option :value="null">
                Selecciona un colegio
              </b-form-select-option>
              <b-form-select-option v-for="(school, index) in userSchools" :key="index" :value="school.id">
                {{school.name}}
              </b-form-select-option>
            </b-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2" class="text-right pt-2">
            Cursos:
          </b-col>
          <b-col>
            <multiselect
              v-model="grades"
              :options="gradesList"
              :multiple="true"
              group-values="levels"
              group-label="label"
              :group-select="true"
              :close-on-select="false"
              placeholder="Añadir más cursos"
              select-label="Seleccionar"
              deselectLabel="Presiona INTRO para remover"
              label="name"
              track-by="name"
            >
              <span slot="noOptions">No puedes agregar mas cursos</span>
              <span slot="noResult">No se ha encontrado ningún curso</span>
            </multiselect>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-button variant="primary" pill @click="addMaterial">
              Agregar
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
  </b-modal>
</template>
<script>
import schoolMaterialService from "../../../services/schoolMaterialService";
import informationService from "../../../services/informationService";
import redpieService from "../../../services/redpieService";

export default {
  props: {
  },
  data() {
    return {
      grades: [],
      gradesList: [],
      loading: false,
      selectedFile: null,
      askSchools: null,
      userSchools: [],
      selectedSchool: null,
    }
  },
  created() {
    this.getPieGrades();
    this.loadUserSchools();
  },
  methods: {
    addMaterial: function() {
      this.loading = true;
      schoolMaterialService.addMaterial({
        grades: this.grades, file_id: this.selectedFile.id,
        school_id: (this.askSchools? this.selectedSchool:this.$store.state.login.school)
      }).then(
        data => {
          this.$toasted.success(data.message)
          this.$emit('reload-school')
          this.loading = false;
        }
      )
    },
    getPieGrades: function() {
      informationService.getPieGrades().then(
        data => {
          this.gradesList = data.grades_by_level
        }
      )
    },
    loadUserSchools: function() {
      redpieService.getUserSchools().then((data) => {
        this.userSchools = data;
      });
    },
    open: function(file, askSchools) {
      this.selectedSchool = null;
      this.askSchools = askSchools;
      this.selectedFile = file;
      this.grades = [];
      this.$refs['add-material'].show()
    }
  }
}
</script>
<style lang="scss">
</style>
