<template>
    <b-modal ref="material-preview-modal" hide-header hide-footer size="xl">
        <b-container>
            <b-row>
                <material-preview 
                    :inMaterial="material"
                    >
                </material-preview>  
            </b-row>
            <b-row>
                <b-col class="text-right">
                    <b-button variant="primary" pill @click="apply()">
                        Elegir
                    </b-button>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import MaterialPreview from "../miscellaneous/MaterialPreview"
export default {
    components:{
        MaterialPreview
    },
    data(){
        return {
            material:{}
        }
    },
    methods:{
        apply() {
            this.$emit('material-chosen', this.material);
            this.close();
        },
        open(material){
            this.material = material
            this.$refs["material-preview-modal"].show()
        },
        close(){
            this.material = {}
            this.$refs["material-preview-modal"].hide()
        }
    }
}
</script>

<style>

</style>