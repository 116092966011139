<template>
  <div style="height: auto">
    <div class="col-xs-12 mb-5">
      <div class="instructions-container">
        <h3 class="instructions-title">Instrucciones:</h3>
        <ul class="instructions-list">
          <li>
            Escribe la palabra que el niño(a) menciona en lenguaje verbatim, ej:
            temefono
          </li>
          <li>
            No es necesario que escribas las palabras que el estudiante
            pronunció bien
          </li>
          <li>Los procesos fonológicos se identificarán automáticamente</li>
          <li>
            Para el caso de yod (j) o wau (w) (presentes en el el proceso S10)
            la yoda escríbela como “i” y wau como “w”
          </li>
          <li>
            Para representar el fonema vibrante simple /r/ que se encuentra AL
            PRINCIPIO de una palabra debes utilizar la notación "-r".
          </li>
        </ul>
      </div>
      Edad
      <b-form-select
        v-model="answer.answers.edad"
        :options="test.edad"
      ></b-form-select>
      <span class="ml-2 edad-warning" v-if="!all_input"
        >Debes completar la edad</span
      >
      <br /><br />
      Puedes seleccionar con el mouse los fonemas que deben ser considerados
      dislalias, aunque el programa también detectará dislalias si un fonema es
      consistentemente omitido
      <br /><br />
      <div class="button-grid">
        <b-button
          v-for="fonema in test.dislalias"
          :key="fonema.value"
          :variant="isSelected(fonema.value) ? 'primary' : 'secondary'"
          @click="toggleFonema(fonema.value)"
          class="fonema-button"
        >
          {{ fonema.text }}
        </b-button>
      </div>

      <table
        cellspacing="0"
        cellpadding="0"
        style="width: 810pt; border-collapse: collapse"
      >
        <tbody>
          <tr style="height: 12.45pt">
            <td
              style="
                width: 63.9pt;
                border: 0.75pt solid #ffffff;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: justify;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'; font-weight: bold"
                  >&nbsp;</span
                >
              </p>
            </td>
            <td
              style="
                width: 1pt;
                border-top: 0.75pt solid #ffffff;
                border-right: 0.75pt solid #ffffff;
                border-left: 0.75pt solid #ffffff;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'">&nbsp;</span>
              </p>
            </td>
            <td
              style="
                width: 62.95pt;
                border: 0.75pt solid #ffffff;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'; font-weight: bold"
                  >&nbsp;</span
                >
              </p>
            </td>
            <td
              style="
                width: 1pt;
                border: 0.75pt solid #ffffff;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'">&nbsp;</span>
              </p>
            </td>
            <td
              style="
                width: 63pt;
                border-top: 0.75pt solid #ffffff;
                border-right: 0.75pt solid #ffffff;
                border-left: 0.75pt solid #ffffff;
                border-bottom-style: solid;
                border-bottom-width: 0.75pt;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'; font-weight: bold"
                  >&nbsp;</span
                >
              </p>
            </td>
            <td
              style="
                width: 1pt;
                border-top: 0.75pt solid #ffffff;
                border-right: 0.75pt solid #ffffff;
                border-left: 0.75pt solid #ffffff;
                border-bottom-style: solid;
                border-bottom-width: 0.75pt;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'; font-weight: bold"
                  >&nbsp;</span
                >
              </p>
            </td>
            <td
              style="
                width: 62.05pt;
                border-top: 0.75pt solid #ffffff;
                border-right: 0.75pt solid #ffffff;
                border-left: 0.75pt solid #ffffff;
                border-bottom-style: solid;
                border-bottom-width: 0.75pt;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'; font-weight: bold"
                  >&nbsp;</span
                >
              </p>
            </td>
            <td
              style="
                width: 1pt;
                border-top: 0.75pt solid #ffffff;
                border-right: 0.75pt solid #ffffff;
                border-left: 0.75pt solid #ffffff;
                border-bottom-style: solid;
                border-bottom-width: 0.75pt;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'; font-weight: bold"
                  >&nbsp;</span
                >
              </p>
            </td>
            <td
              style="
                width: 92.95pt;
                border-top: 0.75pt solid #ffffff;
                border-right: 0.75pt solid #ffffff;
                border-left: 0.75pt solid #ffffff;
                border-bottom-style: solid;
                border-bottom-width: 0.75pt;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'; font-weight: bold"
                  >&nbsp;</span
                >
              </p>
            </td>
            <td
              style="
                width: 1pt;
                border-top: 0.75pt solid #ffffff;
                border-right: 0.75pt solid #ffffff;
                border-left: 0.75pt solid #ffffff;
                border-bottom-style: solid;
                border-bottom-width: 0.75pt;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'; font-weight: bold"
                  >&nbsp;</span
                >
              </p>
            </td>
            <td
              style="
                width: 62.95pt;
                border-top: 0.75pt solid #ffffff;
                border-right: 0.75pt solid #ffffff;
                border-left: 0.75pt solid #ffffff;
                border-bottom-style: solid;
                border-bottom-width: 0.75pt;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'; font-weight: bold"
                  >&nbsp;</span
                >
              </p>
            </td>
            <td
              style="
                width: 1pt;
                border-top: 0.75pt solid #ffffff;
                border-right: 0.75pt solid #ffffff;
                border-left: 0.75pt solid #ffffff;
                border-bottom-style: solid;
                border-bottom-width: 0.75pt;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'; font-weight: bold"
                  >&nbsp;</span
                >
              </p>
            </td>
            <td
              style="
                width: 27.3pt;
                border-top: 0.75pt solid #ffffff;
                border-right: 0.75pt solid #ffffff;
                border-left: 0.75pt solid #ffffff;
                border-bottom-style: solid;
                border-bottom-width: 0.75pt;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'; font-weight: bold"
                  >&nbsp;</span
                >
              </p>
            </td>
          </tr>
          <tr style="height: 12.45pt">
            <td
              style="
                width: 63.9pt;
                border: 0.75pt solid #ffffff;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: justify;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'; font-weight: bold"
                  >ITEM</span
                ><span style="font-family: 'Times New Roman'; font-weight: bold"
                  >E</span
                ><span style="font-family: 'Times New Roman'; font-weight: bold"
                  >S&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </p>
            </td>
            <td
              rowspan="40"
              style="
                width: 1pt;
                border: 0.75pt solid #ffffff;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'">&nbsp;</span>
              </p>
            </td>
            <td
              style="
                width: 62.95pt;
                border: 0.75pt solid #ffffff;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'; font-weight: bold"
                  >REGISTRO</span
                >
              </p>
            </td>
            <td
              style="
                width: 1pt;
                border-top: 0.75pt solid #ffffff;
                border-right-style: solid;
                border-right-width: 0.75pt;
                border-left: 0.75pt solid #ffffff;
                border-bottom: 0.75pt solid #ffffff;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'">&nbsp;</span>
              </p>
            </td>
            <td
              style="
                width: 63pt;
                border-style: solid;
                border-width: 0.75pt;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 13pt;
                "
              >
                <span style="font-family: 'Times New Roman'">EST.SILÁBICA</span>
              </p>
            </td>
            <td
              style="
                width: 1pt;
                border-style: solid;
                border-width: 0.75pt;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'">&nbsp;</span>
              </p>
            </td>
            <td
              style="
                width: 62.05pt;
                border-style: solid;
                border-width: 0.75pt;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 13pt;
                "
              >
                <span style="font-family: 'Times New Roman'">ASIMILACIÓN</span>
              </p>
            </td>
            <td
              style="
                width: 1pt;
                border-style: solid;
                border-width: 0.75pt;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'">&nbsp;</span>
              </p>
            </td>
            <td
              style="
                width: 62.95pt;
                border-style: solid;
                border-width: 0.75pt;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 13pt;
                "
              >
                <span style="font-family: 'Times New Roman'">SUSTITUCIÓN</span>
              </p>
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              ></p>
            </td>
            <td
              style="
                width: 1pt;
                border-style: solid;
                border-width: 0.75pt;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'">&nbsp;</span>
              </p>
            </td>
            <td
              style="
                width: 62.95pt;
                border-style: solid;
                border-width: 0.75pt;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'">TOTAL </span>
              </p>
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'">PROC.</span>
              </p>
            </td>
            <td
              style="
                width: 1pt;
                border-style: solid;
                border-width: 0.75pt;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'">&nbsp;</span>
              </p>
            </td>
            <td
              style="
                width: 27.3pt;
                border-style: solid;
                border-width: 0.75pt;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'">OTRAS</span>
              </p>
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'">RESP. (*)</span>
              </p>
            </td>
          </tr>
          <tr
            style="height: 9pt"
            v-for="(item, index) in test.items"
            :key="index"
          >
            <td
              style="
                width: 63.9pt;
                border: 0.75pt solid #ffffff;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: justify;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                  padding-top: 2px;
                "
              >
                <span style="font-family: 'Times New Roman'"
                  >{{ index + 1 }}.&nbsp;&nbsp;&nbsp; </span
                ><span style="font-family: 'Times New Roman'">{{ item }} </span>
              </p>
            </td>
            <td
              style="
                width: 62.95pt;
                border-top: 0.75pt solid #ffffff;
                border-right: 0.75pt solid #ffffff;
                border-left: 0.75pt solid #ffffff;
                border-bottom-style: solid;
                border-bottom-width: 0.75pt;
                padding-right: 3.12pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: justify;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'">
                  <b-form-input
                    v-model="answer.answers[item]"
                    :disabled="answer.step != 0"
                  ></b-form-input>
                </span>
              </p>
            </td>
            <td
              v-if="index === 0"
              rowspan="38"
              style="
                width: 1pt;
                border-top: 0.75pt solid #ffffff;
                border-right-style: solid;
                border-right-width: 1.5pt;
                border-left: 0.75pt solid #ffffff;
                padding-right: 2.75pt;
                padding-left: 3.12pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: justify;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'">&nbsp;</span>
              </p>
            </td>
            <td
              style="
                width: 63pt;
                border-style: solid;
                border-width: 0.75pt 1.5pt;
                padding-right: 2.75pt;
                padding-left: 2.75pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: justify;
                  widows: 0;
                  orphans: 0;
                  font-size: 12pt;
                "
              >
                <span style="font-family: 'Times New Roman'"
                  ><select
                    v-model="answer.answers[item + 'EA']"
                    style="cursor: pointer"
                    :title="test.selec_info[item + 'EA']"
                  >
                    <option value="-">-</option>
                    <option value="E1">E1</option>
                    <option value="E2">E2</option>
                    <option value="E3">E3</option>
                    <option value="E4">E4</option>
                    <option value="E5">E5</option>
                    <option value="E6">E6</option>
                    <option value="E7">E7</option>
                    <option value="E8">E8</option>
                  </select>
                  <select
                    v-model="answer.answers[item + 'EB']"
                    style="cursor: pointer"
                    :title="test.selec_info[item + 'EB']"
                  >
                    <option value="-">-</option>
                    <option value="E1">E1</option>
                    <option value="E2">E2</option>
                    <option value="E3">E3</option>
                    <option value="E4">E4</option>
                    <option value="E5">E5</option>
                    <option value="E6">E6</option>
                    <option value="E7">E7</option>
                    <option value="E8">E8</option>
                  </select>
                  <select
                    v-model="answer.answers[item + 'EC']"
                    style="cursor: pointer"
                    :title="test.selec_info[item + 'EC']"
                  >
                    <option value="-">-</option>
                    <option value="E1">E1</option>
                    <option value="E2">E2</option>
                    <option value="E3">E3</option>
                    <option value="E4">E4</option>
                    <option value="E5">E5</option>
                    <option value="E6">E6</option>
                    <option value="E7">E7</option>
                    <option value="E8">E8</option>
                  </select>
                </span>
              </p>
            </td>
            <td
              v-if="index === 0"
              rowspan="38"
              style="
                width: 1pt;
                border-top-style: solid;
                border-top-width: 0.75pt;
                border-right-style: solid;
                border-right-width: 1.5pt;
                border-left-style: solid;
                border-left-width: 1.5pt;
                padding-right: 2.75pt;
                padding-left: 2.75pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: justify;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'">&nbsp;</span>
              </p>
            </td>
            <td
              style="
                width: 62.05pt;
                border-style: solid;
                border-width: 0.75pt 1.5pt;
                padding-right: 2.75pt;
                padding-left: 2.75pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: justify;
                  widows: 0;
                  orphans: 0;
                  font-size: 12pt;
                "
              >
                <select
                  v-model="answer.answers[item + 'AA']"
                  style="cursor: pointer"
                  :title="test.selec_info[item + 'AA']"
                >
                  <option value="-">-</option>
                  <option value="A1">A1</option>
                  <option value="A2">A2</option>
                  <option value="A3">A3</option>
                  <option value="A4">A4</option>
                  <option value="A5">A5</option>
                  <option value="A6">A6</option>
                  <option value="A7">A7</option>
                  <option value="A8">A8</option>
                  <option value="A9">A9</option>
                </select>
                <select
                  v-model="answer.answers[item + 'AB']"
                  style="cursor: pointer"
                  :title="test.selec_info[item + 'AB']"
                >
                  <option value="-">-</option>
                  <option value="A1">A1</option>
                  <option value="A2">A2</option>
                  <option value="A3">A3</option>
                  <option value="A4">A4</option>
                  <option value="A5">A5</option>
                  <option value="A6">A6</option>
                  <option value="A7">A7</option>
                  <option value="A8">A8</option>
                  <option value="A9">A9</option>
                </select>
                <select
                  v-model="answer.answers[item + 'AC']"
                  style="cursor: pointer"
                  :title="test.selec_info[item + 'AC']"
                >
                  <option value="-">-</option>
                  <option value="A1">A1</option>
                  <option value="A2">A2</option>
                  <option value="A3">A3</option>
                  <option value="A4">A4</option>
                  <option value="A5">A5</option>
                  <option value="A6">A6</option>
                  <option value="A7">A7</option>
                  <option value="A8">A8</option>
                  <option value="A9">A9</option>
                </select>
              </p>
            </td>
            <td
              v-if="index === 0"
              rowspan="38"
              style="
                width: 1pt;
                border-top-style: solid;
                border-top-width: 0.75pt;
                border-right-style: solid;
                border-right-width: 1.5pt;
                border-left-style: solid;
                border-left-width: 1.5pt;
                padding-right: 2.75pt;
                padding-left: 2.75pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: justify;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'">&nbsp;</span>
              </p>
            </td>
            <td
              style="
                width: 62.95pt;
                border-style: solid;
                border-width: 0.75pt 1.5pt;
                padding-right: 2.75pt;
                padding-left: 2.75pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 9pt;
                "
              >
                <select
                  v-model="answer.answers[item + 'SA']"
                  style="cursor: pointer"
                  :title="test.selec_info[item + 'SA']"
                >
                  <option value="-">-</option>
                  <option value="S1">S1</option>
                  <option value="S2">S2</option>
                  <option value="S3">S3</option>
                  <option value="S4">S4</option>
                  <option value="S5">S5</option>
                  <option value="S6">S6</option>
                  <option value="S7">S7</option>
                  <option value="S8">S8</option>
                  <option value="S9">S9</option>
                  <option value="S10">S10</option>
                  <option value="S11">S11</option>
                  <option value="S12">S12</option>
                  <option value="S13">S13</option>
                  <option value="S14">S14</option>
                  <option value="S15">S15</option>
                  <option value="S16">S16</option>
                </select>
                <select
                  v-model="answer.answers[item + 'SB']"
                  style="cursor: pointer"
                  :title="test.selec_info[item + 'SB']"
                >
                  <option value="-">-</option>
                  <option value="S1">S1</option>
                  <option value="S2">S2</option>
                  <option value="S3">S3</option>
                  <option value="S4">S4</option>
                  <option value="S5">S5</option>
                  <option value="S6">S6</option>
                  <option value="S7">S7</option>
                  <option value="S8">S8</option>
                  <option value="S9">S9</option>
                  <option value="S10">S10</option>
                  <option value="S11">S11</option>
                  <option value="S12">S12</option>
                  <option value="S13">S13</option>
                  <option value="S14">S14</option>
                  <option value="S15">S15</option>
                  <option value="S16">S16</option>
                </select>
                <select
                  v-model="answer.answers[item + 'SC']"
                  style="cursor: pointer"
                  :title="test.selec_info[item + 'SC']"
                >
                  <option value="-">-</option>
                  <option value="S1">S1</option>
                  <option value="S2">S2</option>
                  <option value="S3">S3</option>
                  <option value="S4">S4</option>
                  <option value="S5">S5</option>
                  <option value="S6">S6</option>
                  <option value="S7">S7</option>
                  <option value="S8">S8</option>
                  <option value="S9">S9</option>
                  <option value="S10">S10</option>
                  <option value="S11">S11</option>
                  <option value="S12">S12</option>
                  <option value="S13">S13</option>
                  <option value="S14">S14</option>
                  <option value="S15">S15</option>
                  <option value="S16">S16</option>
                </select>
              </p>
            </td>
            <td
              v-if="index === 0"
              rowspan="38"
              style="
                width: 1pt;
                border-top-style: solid;
                border-top-width: 0.75pt;
                border-right-style: solid;
                border-right-width: 1.5pt;
                border-left-style: solid;
                border-left-width: 1.5pt;
                padding-right: 2.75pt;
                padding-left: 2.75pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'; font-weight: bold"
                  >&nbsp;</span
                >
              </p>
            </td>
            <td
              style="
                width: 62.95pt;
                border-style: solid;
                border-width: 0.75pt 1.5pt;
                padding-right: 2.75pt;
                padding-left: 2.75pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: center;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                {{ test.total[item] }}
              </p>
            </td>
            <td
              v-if="index === 0"
              rowspan="38"
              style="
                width: 1pt;
                border-top-style: solid;
                border-top-width: 0.75pt;
                border-right-style: solid;
                border-right-width: 1.5pt;
                border-left-style: solid;
                border-left-width: 1.5pt;
                padding-right: 2.75pt;
                padding-left: 2.75pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: justify;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'">&nbsp;</span>
              </p>
            </td>
            <td
              style="
                width: 27.3pt;
                border-style: solid;
                border-width: 0.75pt 1.5pt;
                padding-right: 2.75pt;
                padding-left: 2.75pt;
                vertical-align: middle;
              "
            >
              <p
                style="
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  text-align: justify;
                  widows: 0;
                  orphans: 0;
                  font-size: 14pt;
                "
              >
                <span style="font-family: 'Times New Roman'">&nbsp;</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-button
      @click="sendInitialTest"
      v-if="answer.step == 0"
      :disabled="!all_input"
      >Enviar resultados preliminares</b-button
    >
    <span class="ml-2" v-if="!all_input">Debes completar la edad</span>
    <b-button @click="sendTest" v-if="answer.step == 1 && all_input"
      >Corregir Test</b-button
    >
  </div>
</template>
<script>
import testService from "../../../../services/testService";
import redpieService from "../../../../services/redpieService";

export default {
  props: ["test_id", "student_id", "previous_answer", "semester"],
  mounted() {
    if (this.previous_answer && Object.keys(this.previous_answer).length) {
      for (const [key, value] of Object.entries(
        this.previous_answer["step-0"]
      )) {
        this.$set(this.answer.answers, key, value);
      }
    }
  },
  watch: {
    student_id() {
      this.answer.student_id = this.student_id;
    },
    test_id() {
      this.answer.test = this.test_id;
    },
  },
  data() {
    return {
      answer: {
        test: this.test_id,
        answers: { dislalias: [] },
        student_id: this.student_id,
        step: 0,
      },
      test: {
        selec_info: {},
        total: {},
        edad: [
          { value: "3", text: "3.0 - 3.11 años" },
          { value: "4", text: "4.0 - 4.11 años" },
          { value: "5", text: "5.0 - 5.11 años" },
          { value: "6", text: "6.0 - 6.11 años" },
        ],
        dislalias: [
          { value: "a", text: "a" },
          { value: "b", text: "b" },
          { value: "C", text: "ch" },
          { value: "d", text: "d" },
          { value: "e", text: "e" },
          { value: "f", text: "f" },
          { value: "g", text: "g" },
          { value: "i", text: "i" },
          { value: "j", text: "j" },
          { value: "k", text: "k" },
          { value: "l", text: "l" },
          { value: "L", text: "ll" },
          { value: "m", text: "m" },
          { value: "n", text: "n" },
          { value: "ñ", text: "ṉ (ñ)" },
          { value: "o", text: "o" },
          { value: "p", text: "p" },
          { value: "r", text: "r" },
          { value: "R", text: "rr" },
          { value: "s", text: "s" },
          { value: "t", text: "t" },
          { value: "u", text: "u" },
          { value: "w", text: "w" },
        ],
        items: [
          "Plancha",
          "Rueda",
          "Mariposa",
          "Bicicleta",
          "Helicoptero",
          "Bufanda",
          "Caperucita",
          "Alfombra",
          "Refrigerador",
          "Edificio",
          "Calcetin",
          "Dinosaurio",
          "Telefono",
          "Remedio",
          "Peineta",
          "Auto",
          "Indio",
          "Pantalon",
          "Camion",
          "Cuaderno",
          "Micro",
          "Tren",
          "Platano",
          "Jugo",
          "Enchufe",
          "Jabon",
          "Tambor",
          "Volantin",
          "Jirafa",
          "Gorro",
          "Arbol",
          "Dulce",
          "Guitarra",
          "Guante",
          "Reloj",
          "Jaula",
          "Puente",
        ],
      },
    };
  },
  computed: {
    all_input() {
      // Solo verifica si la 'edad' ha sido seleccionada.
      return !!this.answer.answers.edad;
    },
  },
  methods: {
    getDefaultAnswers() {
      const defaultAnswers = {};
      // Cada ítem tendrá como respuesta predeterminada su propio nombre.
      this.test.items.forEach((item) => {
        defaultAnswers[item] = item; // Asigna el nombre del ítem como respuesta predeterminada.
      });
      return defaultAnswers;
    },

    sendInitialTest() {
      const defaultAnswers = this.getDefaultAnswers(); // Obtén las respuestas predeterminadas

      // Llena los campos vacíos con respuestas predeterminadas
      Object.keys(defaultAnswers).forEach((key) => {
        if (!this.answer.answers[key]) {
          // Asumiendo que un campo vacío podría ser undefined, null, o una cadena vacía
          this.$set(this.answer.answers, key, defaultAnswers[key]);
        }
      });

      // Envía el test con las respuestas aseguradas
      // semester
      this.answer["semester"] = this.semester;
      testService.send_test(this.answer).then(
        (data) => {
          this.answer.redpsi_id = data.redpsi_id;
          this.answer.answers = { ...this.answer.answers, ...data.selec };
          this.test.selec_info = data.selec_info;
          this.test.total = data.total;
          this.answer.step = 1;
          this.answer.previous_answer = data.data;
          const msg = `Ya casi estamos! Pincha en algún proceso si quieres modificarlo, para terminar presiona 'Corregir Test'`;
          this.$bvModal.msgBoxOk(msg, {
            okTitle: "ENTENDIDO",
            centered: true,
            size: "lg",
          });
        },
        () => {
          this.$toasted.error("Ha ocurrido al hacer el test");
        }
      );
    },

    sendTest() {
      const defaultAnswers = this.getDefaultAnswers();
      Object.keys(defaultAnswers).forEach((key) => {
        if (!this.answer.answers[key]) {
          this.$set(this.answer.answers, key, defaultAnswers[key]);
        }
      });
      // semester
      this.answer["semester"] = this.semester;
      testService
        .send_test(this.answer)
        .then((data) => {
          this.$emit("add-test", data);
          redpieService
            .assignDocuments({
              test: this.answer.test,
              school: this.$store.state.login.school,
              year: this.$store.state.login.year,
              student_grade: this.answer.student_id,
            })
            .then(() => {
              this.$emit("update-document");
            })
            .catch(() => {
              this.$toasted.error("Ha ocurrido al crear el documento");
            });
        })
        .catch(() => {
          this.$toasted.error("Ha ocurrido al hacer el test");
        });
    },

    isSelected(value) {
      return this.answer.answers.dislalias.includes(value);
    },

    toggleFonema(value) {
      const index = this.answer.answers.dislalias.indexOf(value);
      if (index === -1) {
        this.answer.answers.dislalias.push(value);
      } else {
        this.answer.answers.dislalias.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped>
.instructions-container {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.instructions-title {
  color: #333;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.instructions-list {
  list-style-type: decimal;
  margin: 0;
  padding-left: 20px;
  color: #555;
}

.instructions-list li {
  margin-bottom: 10px;
  line-height: 1.5;
}

.edad-warning {
  color: red;
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 10px;
  display: block;
}

.button-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.fonema-button {
  flex: 0 1 auto; /* Los botones se ajustarán según el contenido */
  min-width: 50px; /* Asegura que los botones tengan un tamaño mínimo */
  margin-bottom: 10px;
}
</style>
