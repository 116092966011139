import api from "@/services/api";
import multiform from "@/services/multiform";

export default {
  create_student(payload) {
    return api
      .post("create_student/", payload)
      .then((response) => response.data);
  },
  createGrade(payload) {
    return api.post("grades/", payload).then((response) => response.data);
  },
  create_school(payload) {
    return api
      .post("create_school/", payload)
      .then((response) => response.data);
  },
  get_school(payload) {
    return api.post("get_school/", payload).then((response) => response.data);
  },
  get_graph_data(payload) {
    return api
      .post("get_graph_data/", payload)
      .then((response) => response.data);
  },
  get_grades(payload) {
    return api.post("get_grades/", payload).then((response) => response.data);
  },
  get_student_grades(payload) {
    return api
      .post("get_student_grades/", payload)
      .then((response) => response.data);
  },
  get_document_templates(payload) {
    return api
      .post("get_document_templates/", payload)
      .then((response) => response.data);
  },
  get_document(payload) {
    return api.post("get_document/", payload).then((response) => response.data);
  },
  download_online_document(payload) {
    return api
      .post("download_document/", payload, { responseType: "blob" })
      .then((response) => response);
  },
  get_document_info(payload) {
    return api
      .post("get_document_info/", payload)
      .then((response) => response.data);
  },
  change_state_document(payload) {
    return api
      .post("change_state_document/", payload)
      .then((response) => response.data);
  },
  archive_document(payload) {
    return api
      .post("archive_document/", payload)
      .then((response) => response.data);
  },
  delete_document(payload) {
    return api
      .post("delete_document/", payload)
      .then((response) => response.data);
  },
  upload_document(payload) {
    return multiform
      .post("upload_document/", payload)
      .then((response) => response.data);
  },
  restoreDocument(payload) {
    return api
      .post("restore_document/", payload)
      .then((response) => response.data);
  },
  updateFureev(payload) {
    return api
      .post("update_fureev_values/", payload)
      .then((response) => response.data);
  },
  getFureevContext(payload) {
    return api
      .post("get_fureev_context/", payload)
      .then((response) => response.data);
  },
  getPaidSchools() {
    return api
      .get(`schools/get_paid_schools/`)
      .then((response) => response.data);
  },
  getSchoolFudeis(schoolID) {
    return api
      .get(`schools/${schoolID}/get_school_fudeis/`)
      .then((response) => response.data);
  },
  checkAutoUpdateFureev(payload) {
    return api
      .post("check_auto_update_fureev/", payload)
      .then((response) => response.data);
  },
  upload_document_with_template(payload) {
    return api
      .post("upload_document_with_template/", payload)
      .then((response) => response.data);
  },
  downloadTemplate(payload) {
    return api
      .post("download_document_template/", payload, { responseType: "blob" })
      .then((response) => response);
  },
  asociateNewTemplate(payload) {
    return api
      .post("asociate_new_template/", payload)
      .then((response) => response.data);
  },
  uploadDocumentTemplates(payload) {
    return multiform
      .post("upload_document_templates/", payload)
      .then((response) => response.data);
  },
  getGradeDiagnosticTypeTemplates(payload) {
    return api
      .post("get_grade_diagnostic_type_templates/", payload)
      .then((response) => response.data);
  },
  create_invitation(payload) {
    return api
      .post("create_invitation/", payload)
      .then((response) => response.data);
  },
  getUserSchools() {
    return api.get(`schools/my-schools/`).then((response) => response.data);
  },
  getPieSchoolData(id, year) {
    return api
      .get(`schools/${id}/school-pie-data/?year=${year}`)
      .then((response) => response.data);
  },
  getSchoolData(id) {
    return api.get(`schools/${id}`).then((response) => response.data);
  },
  schoolSelected(payload) {
    return api
      .post("schools/school_selected/", payload)
      .then((response) => response.data);
  },
  checkRut(schoolID, rut) {
    return api
      .get(`students/check/${schoolID}/${rut}`)
      .then((response) => response.data);
  },
  getSimpleSchool(schoolID, year) {
    return api
      .get(`schools/${schoolID}/simple-students/?year=${year}`)
      .then((response) => response.data);
  },
  checkSigeCredentials(schoolID) {
    return api
      .get(`schools/${schoolID}/sige-credentials/`)
      .then((response) => response.data);
  },
  getGrades(schoolID, grade) {
    return api
      .get(`schools/${schoolID}/level/?school_level=${grade}`)
      .then((response) => response.data);
  },
  getPieGrades(schoolID, grade, year) {
    return api
      .get(`schools/${schoolID}/pielevel/?school_level=${grade}&year=${year}`)
      .then((response) => response.data);
  },
  getStudent(studentID) {
    return api.get(`students/${studentID}/`).then((response) => response.data);
  },
  updateStudent(studentID, data) {
    return api
      .patch(`students/${studentID}/`, data)
      .then((response) => response.data);
  },
  updateDiagnostic(studentID, payload) {
    return api
      .post(`students/${studentID}/assign-diagnostic/`, payload)
      .then((response) => response.data);
  },
  deleteStudent(studentID) {
    return api
      .delete(`students/${studentID}/`)
      .then((response) => response.data);
  },
  deleteStudentGrade(sgID) {
    return api
      .post(`student-grades/${sgID}/delete/`)
      .then((response) => response.data);
  },
  assignDocumentType(payload) {
    return api
      .post("assign_document_type/", payload)
      .then((response) => response.data);
  },
  assignDocuments(payload) {
    return api
      .post("assign_documents/", payload)
      .then((response) => response.data);
  },
  unassignDocumentType(payload) {
    return api
      .post("unassign_document_type/", payload)
      .then((response) => response.data);
  },
  createDocumentTemplateWithJson(payload) {
    return api
      .post("create_document_template_with_json/", payload)
      .then((response) => response.data);
  },
  copyDocumentTypes(payload) {
    return api
      .post("copy_document_types/", payload)
      .then((response) => response.data);
  },
  getDiagnosesGradesDocumentType(schoolID, year) {
    return api
      .get(`get_diagnostic_grades_document_types/${schoolID}/${year}/`)
      .then((response) => response.data);
  },
  deleteDocumentType(payload) {
    return api
      .post("delete_document_type/", payload)
      .then((response) => response.data);
  },
  editSchoolRbdSigePass(schoolID, payload) {
    return api
      .patch(`schools/${schoolID}/update-school-info/`, payload)
      .then((response) => response.data);
  },
  triggerSIGEBot(schoolID, payload) {
    return api
      .post(`schools/${schoolID}/download-nomina/`, payload)
      .then((response) => response.data);
  },
  renameLevels(schoolID, payload) {
    return api
      .post(`schools/${schoolID}/rename_levels/`, payload)
      .then((response) => response.data);
  },
  editDocumentType(payload) {
    return multiform
      .post("edit_document_type/", payload)
      .then((response) => response.data);
  },
  updateTypesOrder(payload) {
    return api
      .post("update_document_types_order/", payload)
      .then((response) => response.data);
  },
  updateDiagnosticWithStudentGrade(sgID, payload) {
    return api
      .post(`student-grades/${sgID}/update_diagnostic/`, payload)
      .then((response) => response.data);
  },
  deleteGrade(gradeID) {
    return api.delete(`grades/${gradeID}/`).then((response) => response.data);
  },
  changeGradeName(payload) {
    return api
      .post("grades/change_grade_name/", payload)
      .then((response) => response.data);
  },
  getAllSchools() {
    return api
      .get("schools/get_all_schools/")
      .then((response) => response.data);
  },
  getMirrorTypes() {
    return api
      .get("schools/get_mirror_types/")
      .then((response) => response.data);
  },
  copySingleDocumentType(payload) {
    return api
      .post("schools/copy_single_document_type/", payload)
      .then((response) => response.data);
  },
  uploadSchoolData(payload) {
    return multiform
      .post("schools/upload_school_data/", payload)
      .then((response) => response.data);
  },
  getSchoolUsers(schoolID) {
    return api
      .get(`schools/${schoolID}/get_users/`)
      .then((response) => response.data);
  },
  updateSchoolUserLevel(payload) {
    return api
      .post("schools/update_school_user_level/", payload)
      .then((response) => response.data);
  },
  getSchoolStudents(schoolID) {
    return api
      .get(`schools/${schoolID}/get_students/`)
      .then((response) => response.data);
  },
  getSchoolUsersMin(schoolID) {
    return api
      .get(`schools/${schoolID}/get_users_min/`)
      .then((response) => response.data);
  },
  createBulkTemplates(payload) {
    return multiform
      .post("document-types/bulk-create/", payload)
      .then((response) => response.data);
  },
  migrateDocuments(payload) {
    return api
      .post("document-types/migrate-documents/", payload)
      .then((response) => response.data);
  },
  createSchool(payload) {
    return api
      .post("schools/create_school_task/", payload)
      .then((response) => response.data);
  },
  getStudentDocumentsByYear(student, year) {
    return api
      .get(`schools/get_documents/?year=${year}&student=${student}`)
      .then((response) => response.data);
  },
  requestSalesEmail(payload) {
    return api.post("sales/email/", payload).then((response) => response.data);
  },
  sendSalesContact(payload) {
    return api
      .post("sales/contact/", payload)
      .then((response) => response.data);
  },
  getSalesVideo() {
    return api.get("sales/video/").then((response) => response.data);
  },
  getStudentDocuments(studentID) {
    return api
      .get(`schools/get_documents/?student=${studentID}`)
      .then((response) => response.data);
  },
  getGradesStudents(school, year, semester) {
    return api
      .get(
        `schools/get_grades_students/?school=${school}&year=${year}&semester=${semester}`
      )
      .then((response) => response.data);
  },
  getStudentGradeDocuments(studentID) {
    return api
      .get(`schools/get_sg_documents/?student=${studentID}`)
      .then((response) => response.data);
  },
  getDashboardData() {
    return api
      .get(`schools/get_dashboard_data/`)
      .then((response) => response.data);
  },
  createSchoolAdminAccount(payload) {
    return api
      .post("easy-register/admin/", payload)
      .then((response) => response.data);
  },
  createSchoolAccount(payload) {
    return api
      .post("easy-register/invite/", payload)
      .then((response) => response.data);
  },
  createBulkCoordinators(payload) {
    return api
      .post("easy-register/coordinators/", payload)
      .then((response) => response.data);
  },
  getPendingLinkInvites(schoolID) {
    return api
      .get(`link-invite/pending/?school=${schoolID}`)
      .then((response) => response.data);
  },
  acceptLinkInvite(id) {
    return api
      .get(`link-invite/${id}/accept`)
      .then((response) => response.data);
  },
  revokeLinkInvite(id) {
    return api.delete(`link-invite/${id}/`).then((response) => response.data);
  },
  createBulkUsers(payload) {
    return api
      .post("easy-register/users/", payload)
      .then((response) => response.data);
  },
  changeStudentGrade(payload) {
    return api
      .post("schools/change-grade/", payload)
      .then((response) => response.data);
  },
  uploadUpdateSchool(payload) {
    return api
      .post("schools/upload-update-school/", payload)
      .then((response) => response.data);
  },
  removeSchoolUser(payload) {
    return api
      .post("schools/remove-school-user/", payload)
      .then((response) => response.data);
  },
  uploadStudents(payload) {
    return api
      .post("schools/upload-students/", payload)
      .then((response) => response.data);
  },
  manualSaveFureev(payload) {
    return api
      .post("manual_save_fureev/", payload)
      .then((response) => response.data);
  },
  createUser(payload) {
    return api
      .post("easy-register/user/", payload)
      .then((response) => response.data);
  },
  getGradesByYear(year, school) {
    return api
      .get(`grades/get_grades/?year=${year}&school=${school}`)
      .then((response) => response.data);
  },
  createEurekaBasicSchool(payload) {
    return api
      .post("schools/create_eureka_basic_school/", payload)
      .then((response) => response.data);
  },
  getTestsInfo(sgdt_id) {
    return api
      .get(`schools/get_tests_info/?sgdt=${sgdt_id}`)
      .then((response) => response.data);
  },
  getReadyErrorsFudei(school, year) {
    return api
      .get(`schools/get_ready_errors_fudeis/?school_id=${school}&year=${year}`)
      .then((response) => response.data);
  },
  getDocumentOnlineBackup(id) {
    return api
      .get(`schools/get_document_online_backup/?backup_id=${id}`)
      .then((response) => response.data);
  },
  restoreDocumentOnlineBackup(payload) {
    return api
      .post("schools/restore_document_backup/", payload)
      .then((response) => response.data);
  },
};
