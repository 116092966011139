<template>
  <div class="text-center w-100">
    <AssignSchoolModal ref="school-modal" />

    <ApplicationModal
      ref="application-modal"
      @create-new-material="$emit('create-new-material')"
      @open-preview="openPreview"
      @create-application="createApplication"
    />
    <MaterialPreviewModal
      ref="material-preview-modal"
      @material-chosen="materialChosen"
    />
    <b-modal ref="link-modal" hide-footer hide-header centered size="lg">
      <div class="m-2 p-2">
        <div class="h4 mx-auto text-center">
          Generar links para prueba <i>"{{ linkTest.name }}"</i>
        </div>
        <b-card no-body class="mt-3">
          <b-tabs card>
            <b-tab title="Individuales">
              <b-form @submit.prevent="submitLink">
                <b-form-group
                  label="RUT Estudiante *"
                  description="Ingresa RUT sin puntos y con guión"
                  label-size="sm"
                  label-for="rut"
                >
                  <b-input
                    placeholder="Ingresa un RUT"
                    v-model="linkRut"
                    id="rut"
                    required
                  />
                </b-form-group>
                <b-form-group
                  label="Nombre Estudiante *"
                  label-size="sm"
                  label-for="name"
                >
                  <b-input v-model="linkName" id="name" required />
                </b-form-group>
                <b-form-group
                  label="Correo electrónico estudiante *"
                  label-size="sm"
                  label-for="email"
                >
                  <b-input
                    id="email"
                    placeholder="correo@ejemplo.cl"
                    type="email"
                    v-model="linkEmail"
                    required
                  />
                </b-form-group>
                <b-row align-h="center">
                  <b-button
                    class="mx-auto"
                    variant="danger"
                    type="reset"
                    pill
                    size="sm"
                    @click="closeLinkModal"
                  >
                    <v-icon name="times" /> Cancelar
                  </b-button>
                  <b-button
                    class="mx-auto"
                    variant="info"
                    pill
                    size="sm"
                    type="submit"
                    @click="submitter = 1"
                  >
                    <v-icon name="copy" /> Copiar Link
                  </b-button>
                  <b-button
                    class="mx-auto"
                    variant="primary"
                    type="submit"
                    pill
                    size="sm"
                    @click="submitter = 2"
                  >
                    <v-icon name="share-alt" /> Enviar al Correo
                  </b-button>
                </b-row>
              </b-form>
            </b-tab>
            <b-tab title="Grupales">
              <b-form-row>
                <b-col cols="12">
                  <b-form-group label="Nombre del Grupo *" label-size="sm">
                    <b-input size="sm" required v-model="groupName" />
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form @submit.prevent="addStudent">
                <b-form-row class="w-100 my-2">
                  <b-col cols="3">
                    <b-form-group label="RUT *" label-size="sm">
                      <b-input size="sm" v-model="linkRut" name="rut" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group label="Nombre *" label-size="sm">
                      <b-input size="sm" v-model="linkName" name="name" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="3">
                    <b-form-group label="E-mail *" label-size="sm">
                      <b-input
                        size="sm"
                        type="email"
                        v-model="linkEmail"
                        name="email"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="2">
                    <b-button
                      variant="primary"
                      size="sm"
                      pill
                      class="mt-4"
                      block
                      type="submit"
                    >
                      + Agregar
                    </b-button>
                  </b-col>
                </b-form-row>
              </b-form>
              <b-form-row v-if="students.length > 0">
                <b-col cols="3" class="text-left"><small>RUT</small></b-col>
                <b-col cols="4" class="text-left"><small>Nombre</small></b-col>
                <b-col cols="4" class="text-left"><small>E-mail</small></b-col>
                <b-col cols="1" class="text-left"
                  ><small>Eliminar</small></b-col
                >
              </b-form-row>
              <b-form-row
                v-for="(student, index) of students"
                :key="`invite-${index}`"
                class="my-1"
              >
                <b-col cols="3">
                  <b-input size="sm" v-model="student.rut" />
                </b-col>
                <b-col cols="4">
                  <b-input size="sm" v-model="student.name" />
                </b-col>
                <b-col cols="4">
                  <b-input size="sm" type="email" v-model="student.email" />
                </b-col>
                <b-col cols="1">
                  <b-button
                    class="btn-circle mx-auto"
                    variant="danger"
                    size="sm"
                    @click="students.splice(index, 1)"
                  >
                    <v-icon name="trash-alt" />
                  </b-button>
                </b-col>
              </b-form-row>
              <b-row align-h="center" class="mt-3">
                <b-col cols="2">
                  <b-button
                    pill
                    size="sm"
                    variant="danger"
                    @click="cancelBulkInvites"
                  >
                    <v-icon name="times" /> Cancelar
                  </b-button>
                </b-col>
                <b-col cols="2">
                  <!-- <b-button pill size="sm" variant="primary" @click="generateBulkInvites">
                    <v-icon name="link"/> Generar
                  </b-button> -->
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </b-modal>
    <assisted-create-material v-if="state==='assisted'" @back-to-list="state=''" mode='creating'/>
    <b-card no-body style="height: 100%" class="mx-4" v-else>
      <b-tabs
        content-class="p-2"
        fill
        align="center"
        card
        justified
        v-model="tabIndex"
      >
        <b-tab title="TUS MATERIALES - EVALUACIONES" no-body lazy>
          <material-list-component
            @create-new-material="$emit('create-new-material')"
            @open-material="openMaterial"
            @apply-material="openApplicationMaterial"
          />
        </b-tab>

        <b-tab title="TUS TEXTOS" no-body lazy>
          <text-list-component @open-related-material="openMaterial" />
        </b-tab>

        <b-tab title="TUS EJERCICIOS" no-body lazy>
          <question-list-component @open-related-material="openMaterial" />
        </b-tab>
        <b-tab
          title="TUS APLICACIONES"
          no-body
          :active="mode === 'openApp'"
          lazy
        >
          <application-list-component
            @create-new-material="$emit('create-new-material')"
            @open-application-status="openApplicationStatus"
          />
        </b-tab>
        <b-tab title="MATERIAL ASISTIDO" no-body v-if="$store.state.login.isAdmin || $store.state.login.useAssisted">
          <b-button variant="primary" pill @click="state='assisted'">
            Empezar
          </b-button>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import customMaterialService from "@/services/customMaterialService";
import AssignSchoolModal from "@/components/custom-materials/modals/AssignSchoolModal";
import MaterialPreviewModal from "@/components/custom-materials/modals/MaterialPreviewModal";
import ApplicationModal from "@/components/custom-materials/modals/ApplicationModal";
import ApplicationListComponent from "@/components/custom-materials/miscellaneous/ApplicationListComponent.vue";
import QuestionListComponent from "@/components/custom-materials/miscellaneous/QuestionListComponent.vue";
import TextListComponent from "@/components/custom-materials/miscellaneous/TextListComponent.vue";
import MaterialListComponent from "@/components/custom-materials/miscellaneous/MaterialListComponent";
import AssistedCreateMaterial from "@/components/custom-materials/miscellaneous/AssistedCreateMaterial";

export default {
  name: "InteractiveMaterialsTabs",
  components: {
    AssignSchoolModal,
    MaterialPreviewModal,
    ApplicationModal,
    ApplicationListComponent,
    QuestionListComponent,
    TextListComponent,
    MaterialListComponent,
    AssistedCreateMaterial
  },
  props: {
    tags: {
      type: Array,
      required: true,
    },
    mode: {
      type: String,
      required: false,
    },
    inMaterial: {
      type: Object,
      required: false,
    },
    application: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      tabIndex: 0,
      state: '',
      texts: [],
      linkTest: {},
      linkRut: "",
      linkEmail: "",
      linkName: "",
      submitter: 0,
      students: [],
      groupName: "",

      chosenMaterialID: "",
    };
  },
  watch: {},
  mounted() {
    if (this.mode === "openApp" && this.inMaterial) {
      this.openApplicationMaterial(this.inMaterial);
    }
  },
  computed: {
    schoolID() {
      return this.$store.state.login.school;
    },
  },
  methods: {
    generateLink(test) {
      this.linkTest = test;
      this.$refs["link-modal"].show();
    },
    closeLinkModal() {
      this.linkTest = {};
      this.linkEmail = "";
      this.linkRut = "";
      this.submitter = 0;
      this.$refs["link-modal"].hide();
    },
    submitLink() {
      customMaterialService
        .generateLink({
          test: this.linkTest.id,
          rut: this.linkRut,
          email: this.linkEmail,
          name: this.linkName,
        })
        .then(
          (data) => {
            if (this.submitter === 1) {
              let testURL = `${process.env["VUE_APP_BASE_URL"]}/materials#/t/${data.id}`;
              if (navigator.clipboard) {
                navigator.clipboard.writeText(testURL);
              } else if (window.clipboardData) {
                window.clipboardData.setData("url", testURL);
              } else {
                this.$toasted.error("No se pudo copiar");
                return;
              }
              this.$toasted.success("Link copiado en el portapapeles");
            } else if (this.submitter === 2) {
              this.sendEmail(data);
            }
          },
          () => this.$toasted.error("Ocurrió un error. Intenta nuevamente.")
        );
    },
    sendEmail(data) {
      console.log(data.id);
      customMaterialService.sendLinkToStudent(data.id).then(
        () => {
          this.$toasted.success("Correo enviado con éxito");
          this.closeLinkModal();
        },
        () =>
          this.$toasted.error(
            "Ocurrió un error al enviar el correo. Intente nuevamente"
          )
      );
    },
    addStudent() {
      this.students.push({
        email: this.linkEmail,
        rut: this.linkRut,
        name: this.linkName,
      });
      this.linkRut = "";
      this.linkName = "";
      this.linkEmail = "";
    },
    cancelBulkInvites() {
      this.students = [];
      this.linkRut = "";
      this.linkName = "";
      this.linkEmail = "";
      this.groupName = "";
      this.$refs["link-modal"].hide();
    },
    goToReview(link, app) {
      this.$emit("response-admin", link, app);
    },
    openNewApplication() {
      this.$refs["application-modal"].open(0);
    },
    openPreview(material) {
      this.$refs["material-preview-modal"].open(material);
    },
    materialChosen(material) {
      this.$refs["application-modal"].changeStep(1);
      this.chosenMaterialID = material.id;
    },
    createApplication(data) {
      var payload = {
        data: data,
        year: this.$store.state.login.year,
        school: this.$store.state.login.school,
      };
      customMaterialService
        .createApplication(this.chosenMaterialID, payload)
        .then((data) => {
          this.$toasted.success("Aplicación creada!");
          this.$emit("open-application-status", data);
        });
    },

    openApplicationMaterial(materialID) {
      this.$refs["application-modal"].open(1);
      this.chosenMaterialID = materialID;
    },
    openMaterial(materialID) {
      this.$emit("open-material", materialID);
    },
    openApplicationStatus(data) {
      this.$emit("open-application-status", data);
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  font-size: 8px;
  text-align: center;
}
</style>
