<template lang="">
  <div>
    <b-modal ref="modal" class="w-75" centered hide-header>
      <b-container>
        <b-row class="my-2">
          <b-col class="text-center"> {{ warningMsg }} </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <b-row class="">
          <b-col class="text-center">
            <b-button variant="danger" @click="$refs['modal'].hide()" pill>
              Cancelar
            </b-button>
          </b-col>
          <b-col class="text-center">
            <b-button
              variant="warning"
              @click="
                deleteAudio();
                $refs['modal'].hide();
              "
              pill
            >
              Eliminar
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <b-row v-if="state === 'base' && audioURL === ''" class="py-2">
      <b-col>
        <span>{{ baseMsg }}</span>
      </b-col>
    </b-row>
    <b-row v-if="state === 'base' && audioURL === ''">
      <b-col class="text-center">
        <b-button
          class="mx-1 btn-circle-lg"
          variant="success"
          pill
          size="lg"
          @click="startAudioRecording"
          v-b-tooltip.hover
          title="Grabar audio"
        >
          <v-icon name="microphone" fill="white"></v-icon>
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="state === 'recording'" class="py-2">
      <b-col>
        <span>{{ recordingMsg }}</span>
      </b-col>
    </b-row>
    <b-row v-if="state === 'recording'">
      <!-- <b-col class="text-center">
        <b-button @click="cancelAudioRecording" pill size="md" variant="danger"
          ><v-icon name="times" scale="1" fill="white"></v-icon
        ></b-button>
      </b-col> -->
      <b-col class="text-center numbers">
        <div class="">
          <span> {{ cronometer.minute }} </span>
          <span> : </span>
          <span> {{ cronometer.second }} </span>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="state === 'recording'">
      <b-col class="text-center">
        <b-button variant="success" pill size="md" @click="stopAudioRecording"
          ><v-icon name="stop" scale="1" fill="white"></v-icon
        ></b-button>
      </b-col>
    </b-row>

    <b-row v-if="audioURL" class="mt-2">
      <b-col cols="12">
        <audio
          :src="audioURL"
          class="mw-100 custom-audio-tag"
          preload="auto"
          controls
        >
          <source :src="audioURL" />
          Tu navegador no soporta la reproducción de audio
        </audio>
      </b-col>
    </b-row>
    <b-row v-if="audioURL" class="mt-2">
      <b-col>
        <b-button @click="warningModal" pill size="xl" variant="danger"
          ><v-icon name="trash" scale="1.5" fill="white"></v-icon
        ></b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "CustomAudioRecord",
  props: {
    baseMsg: {
      type: String,
      required: false,
    },
    recordingMsg: {
      type: String,
      required: false,
    },
    warningMsg: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      audioBlobs: [] /*of type Blob[]*/,
      mediaRecorder: null /*of type MediaRecorder*/,
      streamBeingCaptured: null /*of type MediaStream*/,
      audioRecordStartTime: "",
      elapsedTimeTimer: "",
      audioURL: "",
      state: "base",
      audioBlob: null,
      cronometer: {
        minute: "00",
        second: "00",
      },
      maxMin: 59,
    };
  },
  methods: {
    start() {
      if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
        //Feature is not supported in browser
        //return a custom error
        return Promise.reject(
          new Error(
            "mediaDevices API or getUserMedia method is not supported in this browser."
          )
        );
      } else {
        return (
          navigator.mediaDevices
            .getUserMedia({ audio: true } /*of type MediaStreamConstraints*/)
            //returns a promise that resolves to the audio stream
            .then((stream) /*of type MediaStream*/ => {
              this.streamBeingCaptured = stream;
              this.mediaRecorder = new MediaRecorder(stream);
              console.log(this.mediaRecorder);
              this.audioBlobs = [];
              this.mediaRecorder.ondataavailable = (event) => {
                //store audio Blob object
                this.audioBlobs.push(event.data);
              };

              this.mediaRecorder.start();
            })
        );
        //Feature is supported in browser
        //...
      }
    },
    startAudioRecording() {
      this.$emit("mute-all");
      this.start()
        .then(() => {
          this.state = "recording";
          this.audioRecordStartTime = new Date();
          this.handleDisplayingRecordingControlButtons();
          this.audioURL = "";
        })

        .catch((error) => console.log(error));
    },
    stop() {
      return new Promise((resolve) => {
        //save audio type to pass to set the Blob type
        let mimeType = this.mediaRecorder.mimeType;

        //listen to the stop event in order to create & return a single Blob object
        this.mediaRecorder.onstop = () => {
          //create a single blob object, as we might have gathered a few Blob objects that needs to be joined as one
          let audioBlob = new Blob(this.audioBlobs, { type: mimeType });

          //resolve promise with the single audio blob representing the recorded audio
          resolve(audioBlob);
        };

        //stop the recording feature
        this.mediaRecorder.stop();

        //stop all the tracks on the active stream in order to stop the stream
        this.stopStream();

        //reset API properties for next recording
        this.resetRecordingProperties();

        clearInterval(this.elapsedTimeTimer);
      });
    },

    stopStream() {
      //stopping the capturing request by stopping all the tracks on the active stream
      this.streamBeingCaptured
        .getTracks() //get all tracks from the stream
        .forEach((track) /*of type MediaStreamTrack*/ => track.stop()); //stop each one
    },

    resetRecordingProperties() {
      this.mediaRecorder = null;
      this.streamBeingCaptured = null;
      this.audioURL = "";
      this.audioBlob = null;
    },

    stopAudioRecording() {
      console.log("Stopping Audio Recording...");
      this.stop()
        .then((audioAsblob) => {
          //stopping makes promise resolves to the blob file of the recorded audio
          console.log("stopped with audio Blob:", audioAsblob);

          //Do something with the recorded audio
          //...
          this.state = "base";
          this.handleAudio(audioAsblob);

          this.setCronometer(0);
        })
        .catch((error) => {
          //Error handling structure
          switch (error.name) {
            case "InvalidStateError": //error from the MediaRecorder.stop
              console.log("An InvalidStateError has occured.");
              break;
            default:
              console.log("An error occured with the error name " + error.name);
          }
        });
    },
    computeElapsedTime(startTime) {
      let endTime = new Date();
      let timeDiff = endTime - startTime;
      timeDiff = Math.trunc(timeDiff / 1000);

      this.setCronometer(timeDiff);
      //   console.log(timeDiff);
    },
    setCronometer(t) {
      let sec = t % 60;
      let min = Math.trunc(t / 60);
      this.chechMax(min);
      this.cronometer.minute = min < 10 ? "0" + min.toString() : min.toString();
      this.cronometer.second = sec < 10 ? "0" + sec.toString() : sec.toString();
    },
    chechMax(min) {
      if (min === 59) {
        this.stopAudioRecording();
      }
    },
    handleElapsedRecordingTime() {
      this.elapsedTimeTimer = setInterval(() => {
        //compute the elapsed time every second
        this.computeElapsedTime(this.audioRecordStartTime); //pass the actual record start time
        //display the elapsed time
      }, 1000); //every second
    },
    handleDisplayingRecordingControlButtons() {
      //Handle the displaying of the elapsed recording time
      this.handleElapsedRecordingTime();
    },
    handleAudio(recorderAudioAsBlob) {
      this.audioBlob = recorderAudioAsBlob;

      let reader = new FileReader();
      reader.onload = (event) => {
        this.audioURL = event.target.result;
        this.$emit("result", { blob: this.audioBlob, url: this.audioURL });
      };

      reader.readAsDataURL(recorderAudioAsBlob);
    },
    setPreviousAudio(url, blob) {
      this.audioURL = url;
      this.audioBlob = blob
    },
    deleteAudio() {
      this.$emit("result", { blob: null, url: "" });
      this.resetRecordingProperties();
    },
    warningModal() {
      this.$refs["modal"].show();
    },
  },
};
</script>
<style lang="scss">
@media screen and (min-width: 501px) {
  .numbers {
    span {
      font-size: 30pt;
      font-weight: 700;
    }
  }
}
@media screen and (max-width: 500px) {
  .numbers {
    span {
      font-size: 20pt;
      font-weight: 700;
    }
  }
  .custom-audio-tag {
    height: 40px;
  }
}
</style>
