import { Model } from '@vuex-orm/core'

export class Video extends Model {
    static entity = 'video'

    static fields() {
        return {
            id: this.attr(null),
            category: this.attr(null),
            subcategory: this.attr(null),
            video: this.attr(null),
            photos: this.attr([])
        }
    }

    static apiConfig = {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            "Content-Type": "application/json",
        },
        baseURL: `${process.env["VUE_APP_BASE_URL"]}/api`,
        actions: {
            fetch: {
                method: 'GET',
                url: '/videotutorial/',
            },
        }
    }
}