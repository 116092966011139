<template>
  <b-modal
    centered
    :size="selecting ? 'lg' : 'xl'"
    ref="modal"
    hide-header
    :hide-footer="listing"
    :no-close-on-backdrop="!listing"
    :no-close-on-esc="!listing"
    @hide="close"
    style="z-index: 100 !important"
  >
    <AudioRecordingModal ref="audio-modal" />
    <AdditionalInstructionModal ref="instruction-modal" />
    <EvaluationMetricModal ref="rubric-modal" />
    <MultipleAudioRecorder ref="multi-audio-modal" />
    <TextManagerModal ref="text-manager" />

    <b-modal ref="pond-modal" hide-footer hide-header centered>
      <FilePond
        ref="pond"
        class="p-2 m-2"
        label-idle="Arrastra tus archivos acá o haz clic para navegar"
        :allow-multiple="false"
        accepted-file-types="image/jpeg, image/png, image/svg+xml"
        :files="pondObject && pondObject.image ? [pondObject.image] : []"
      />
      <b-row class="text-right">
        <b-col offset="5">
          <b-button pill variant="danger" class="m-1" @click="closePond">
            <v-icon name="times" /> Cancelar</b-button
          >
          <b-button
            pill
            variant="primary"
            class="my-1 ml-1 mr-2"
            @click="associateImage"
          >
            <v-icon name="save" /> Guardar</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
    <b-modal ref="image-modal" hide-footer hide-header centered>
      <div class="modal-image">
        <b-img :src="modalImage" alt="modal-image" width="320px"></b-img>
      </div>
    </b-modal>
    <b-modal
      ref="warning-discard-modal"
      centered
      hide-footer
      hide-header
      rounded-0
    >
      <div class="m-2">
        <b-row>
          <b-col class="text-center">
            <p>Al salir sin guardar se perderán todos los cambios</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <b-button class="ml-0" pill variant="danger" @click="close">
              <b>Salir</b>
            </b-button>
          </b-col>
          <b-col class="text-center">
            <b-button
              class="ml-0"
              pill
              variant="primary"
              @click="
                $refs['warning-discard-modal'].hide();
                save();
              "
            >
              <v-icon name="save" /> Guardar
            </b-button>
          </b-col>
          <!-- <b-col class="text-center">
                <b-button class="ml-0" pill variant="danger" @click="$refs['warning-discard-modal'].hide()">
                <b> Cancelar</b>
                </b-button>
            </b-col> -->
        </b-row>
      </div>
    </b-modal>
    <warning-empty-modal
      warning="Tu pregunta está vacía"
      message="Debes escribir un enunciado para poder guardar"
      ref="warning-empty-modal"
    />
    <RelatedMaterialsSidebar
      @open-material="openRelatedMaterial"
      :objectID="question.id"
      ref="related-materials-sidebar"
    />
    <div v-if="creating || editing" class="creating">
      <!-- PRINCIPAL MODAL OPTIONS -->
      <tags-component
        :preSelectedSubtags="question.subtags"
        :preSelectedTag="question.tag"
        :preselected="preselectedHT"
        @set-tag="setTag"
        @set-subtags="setSubtag"
      />
      <b-form-row class="w-100 p-3">
        <b-col cols="7">
          <b-form-textarea
            v-model="question.question"
            class="w-100"
            name="question"
            placeholder="Ingresa tu pregunta"
            size="lg"
          />
        </b-col>

        <b-col cols="1" @click="manageRecording(question)">
          <MicrophoneIcon viewbox="0 0 100 100" width="55" height="55" />
        </b-col>
        <b-col cols="1" @click="attachImage(question)">
          <ClipIcon viewbox="0 0 100 100" width="55" height="55" />
        </b-col>
        <b-col cols="1" @click="openRubric(question)">
          <RubricIcon viewbox="0 0 100 100" width="55" height="55" />
        </b-col>

        <b-col
          cols="1"
          class="formula text-center"
          @click="question.formulas.push('x=1+1')"
        >
          <FormulaIcon
            class="formula-on"
            viewbox="0 0 100 100"
            width="55"
            height="55"
            :on="true"
          />
          <FormulaIcon
            class="formula-off"
            viewbox="0 0 100 100"
            width="55"
            height="55"
            :on="false"
          />
        </b-col>
        <b-col cols="1">
          <b-button
            class="btn-circle-lg"
            pill
            size="lg"
            variant="primary"
            @click="openInstruction(question)"
          >
            <v-icon name="folder-plus" />
          </b-button>
        </b-col>
      </b-form-row>

      <!-- <b-form-row class="px-3 pb-2">
                    <b-col>
                    <b-badge class="mr-2" v-for="(subtag, tIndex) in question.subtags" :key="tIndex" variant="primary">{{subtag.name}}</b-badge>
                    </b-col>
            </b-form-row> -->

      <b-form-row
        v-if="question.formulas && question.formulas.length > 0"
        class="px-3"
      >
        Fórmulas
      </b-form-row>
      <b-form-row
        v-for="(formula, findex) in question.formulas"
        :key="findex"
        class="px-3"
      >
        <b-col>
          <math-field
            @blur="
              (event) => {
                change(findex, event.target.value);
              }
            "
            :id="'formula-' + findex"
            :key="'formula-' + formula"
            :ref="'formula-' + findex"
            virtual-keyboard-mode="onfocus"
            virtual-keyboard-container="modal"
            style="height: 50px; font-size: 20pt; z-index: 1041"
          >
            {{ formula }}
          </math-field>
        </b-col>
        <b-col cols="1">
          <div @click="deleteFormula(findex)">
            <TrashIcon viewbox="0 0 100 100" width="35" height="35" />
          </div>
        </b-col>
      </b-form-row>

      <b-row
        class="mx-3 my-1 px-0 py-2 rounded border audio-section"
        v-if="question.audio"
      >
        <b-col cols="2">
          <div class="ml-0 pt-1" size="md">
            <audio
              ref="player-question"
              :src="question.audio"
              autoplay
              @ended="nowPlaying = ''"
              @play="nowPlaying = 'question'"
            ></audio>
            <b-img
              @click="togglePlayer('question')"
              width="30px"
              class="ml-1 mr-1"
              alt="audio-img"
              :src="nowPlaying === 'question' ? repeatIcon : listenIcon"
              style="cursor: pointer"
            />
          </div>
        </b-col>
        <b-col class="pt-2 h6 text-muted text-left" sm="6" md="7">
          Audio adjunto
        </b-col>
        <b-col
          class="d-flex justify-content-center align-content-center border-left"
          sm="4"
          md="3"
        >
          <b-button
            class="mx-1"
            size="md"
            variant="outline-danger"
            @click="deleteAudio(question)"
          >
            <v-icon name="trash-alt" />
          </b-button>
        </b-col>
      </b-row>

      <b-row
        class="mx-3 my-1 px-0 py-2 image-section rounded border"
        v-if="question.image"
      >
        <b-col cols="10" class="border-right">
          <b-img
            :src="question.image_url"
            v-if="question.image.file"
            width="200"
          />
          <b-img
            :src="question.image"
            v-if="!question.image.file"
            width="200"
          />
        </b-col>
        <b-col
          cols="2"
          class="d-flex justify-content-center align-content-center"
        >
          <b-button
            class="mx-1"
            size="md"
            variant="outline-danger"
            @click="question.image = null"
          >
            <v-icon name="trash-alt" />
          </b-button>
        </b-col>
      </b-row>

      <!-- <b-form-row class="px-3 mx-1">
                <b-form-group label="Puntaje" label-cols="3">
                    <b-input type="number" min="1" v-model="question.score" size="sm"/>
                </b-form-group>
            </b-form-row> -->
      <b-form-row class="px-3"> Elige un tipo de respuesta: </b-form-row>
      <b-form-row class="w-100 p-3">
        <b-col cols="12">
          <b-form-radio-group
            v-model="question.kind"
            :options="kinds"
            button-variant="outline-warning"
            buttons
          ></b-form-radio-group>
        </b-col>
      </b-form-row>
      <div v-if="question.kind === 0" class="px-3 py-2">
        <b-form-row>
          <b-form-checkbox v-model="question.is_count_question">
            Pregunta de conteo
          </b-form-checkbox>
        </b-form-row>
        
        <!-- Pregunta de conteo -->
        <b-row v-if="question.is_count_question" class="mt-2 px-3">
          <b-form-row>
            <b-form-group label="Ingresa la cantidad de elementos a contar" label-cols="8" label-class="mx-2">
              <b-form-input type="number" min="0" v-model="question.number_of_images[0]" size="sm"></b-form-input>
            </b-form-group>
            <b-form-group label="Selecciona una imagen" v-if="question.number_of_images[0] > 0" label-cols="4" label-class="ml-4">
              <b-form-select v-model="question.selected_images[0]" :options="image_options"></b-form-select>
            </b-form-group>
          </b-form-row>

          <b-form-row>
            <b-form-group label="Ingresa la cantidad de elementos a contar" label-cols="8" label-class="mx-2">
              <b-form-input type="number" min="0" v-model="question.number_of_images[1]" size="sm"></b-form-input>
            </b-form-group>
            <b-form-group label="Selecciona una imagen" v-if="question.number_of_images[1] > 0" label-cols="4" label-class="ml-4">
              <b-form-select v-model="question.selected_images[1]" :options="image_options"></b-form-select>
            </b-form-group>
          </b-form-row>
        </b-row>

      </div>
      <!-- ALTERNATIVAS -->
      <b-form-row class="w-100 px-3">
        <b-col
          cols="3"
          offset="9"
          v-if="question.kind === 0 && question.alternatives.length > 0"
          class="text-center"
          style="font-size: 10pt"
        >
          Alternativa <br />
          correcta
        </b-col>
      </b-form-row>
      <b-form-row
        v-for="(alternative, altIndex) of question.alternatives"
        v-if="question.kind === 0"
        :key="`${altIndex}`"
        class="w-100 px-3 my-1 border-bottom py-1 align-items-center"
      >
        <b-col cols="1" class="text-center">
          <table>
            <tr>
              <th class="py-0 vertical-align-middle">
                <v-icon
                  :name="altIndex === 0 ? 'arrow-up-off' : 'arrow-up-on'"
                  @click="moveElementUp(question.alternatives, altIndex)"
                  style="cursor: pointer"
                  scale="0.5"
                  class="text-info mt-2"
                />
              </th>
            </tr>
            <tr>
              <th>
                <v-icon
                  :name="
                    altIndex === question.alternatives.length - 1
                      ? 'arrow-down-off'
                      : 'arrow-down-on'
                  "
                  @click="moveElementDown(question.alternatives, altIndex)"
                  style="cursor: pointer"
                  scale="0.5"
                  class="text-info mb-2"
                />
              </th>
            </tr>
          </table>
        </b-col>
        <!-- <b-col cols="2" class="text-center" style="font-size:10pt;">
                    <label :for="`alt-${altIndex}`">Alternativa {{altIndex+1}}:</label>
                </b-col> -->
        <b-col cols="6" class="text-center" style="font-size: 10pt">
          <b-input
            v-model="alternative.text"
            class="w-100"
            v-if="!alternative.is_formula"
            :id="`alt-${altIndex}`"
            name="question"
            type="text"
            placeholder="Ingresa la alternativa"
            size="sm"
          />
          <math-field
            @blur="
              (event) => {
                question.alternatives[altIndex].text = event.target.value;
              }
            "
            v-else
            id="formula"
            virtual-keyboard-mode="manual"
            style="height: 50px; font-size: 20pt"
          >
            {{ alternative.text }}
          </math-field>
        </b-col>
        <b-col
          cols="1"
          class="text-center"
          @click="manageRecording(alternative)"
        >
          <MicrophoneIcon viewbox="0 0 100 100" width="35" height="35" />
        </b-col>
        <b-col cols="1" class="text-center" @click="attachImage(alternative)">
          <ClipIcon viewbox="0 0 100 100" width="35" height="35" />
        </b-col>
        <b-col
          cols="1"
          class="formula text-center"
          @click="alternative.is_formula = !alternative.is_formula"
        >
          <FormulaIcon
            class="formula-on"
            viewbox="0 0 100 100"
            width="35"
            height="35"
            :on="true"
          />
          <FormulaIcon
            class="formula-off"
            viewbox="0 0 100 100"
            width="35"
            height="35"
            :on="false"
          />
        </b-col>
        <b-col cols="1" class="text-center">
          <b-form-checkbox v-model="alternative.is_correct" />
        </b-col>
        <b-col
          cols="1"
          class="border-left text-center"
          @click="question.alternatives.splice(altIndex, 1)"
        >
          <TrashIcon viewbox="0 0 100 100" width="35" height="35" />
        </b-col>
        <b-form-row class="px-3" v-if="alternative.audio">
          <div class="mx-2 h6 text-muted">
            <v-icon name="paperclip" />
            {{ alternative.audio ? "audio.mp3" : "" }}
            <b-button
              class="ml-1 btn-circle"
              pill
              size="sm"
              variant="danger"
              @click="deleteAudio(alternative)"
            >
              <v-icon name="trash-alt" />
            </b-button>
          </div>
        </b-form-row>

        <b-form-row class="px-3" v-if="alternative.image">
          <div class="mx-2 h6 text-muted">
            <v-icon name="paperclip" />
            {{
              alternative.image
                ? alternative.image.filename
                  ? `${alternative.image.filename}`
                  : alternative.image.substring(
                      alternative.image.lastIndexOf("/") + 1
                    )
                : ""
            }}
            <b-button
              class="ml-1 btn-circle"
              pill
              size="sm"
              variant="danger"
              @click="alternative.image = null"
            >
              <v-icon name="trash-alt" />
            </b-button>
          </div>
        </b-form-row>
      </b-form-row>
      <b-form-row
        v-if="question.kind === 0"
        class="w-100 px-3 py-2 text-left"
        style="cursor: pointer"
      >
        <div class="text-muted">
          <p
            class="h6 mx-2"
            @click="question.alternatives.push(getEmptyAlternative())"
          >
            Clic acá para agregar una alternativa
            <v-icon name="plus-circle" scale="1.2" />
          </p>
        </div>
      </b-form-row>
      <!-- DESARROLLO -->
      <b-form-row v-else-if="question.kind === 1" class="px-3">
        <div class="mx-2">
          <h6>El estudiante debe completar con:</h6>
          <b-radio-group
            v-model="question.response_type"
            :options="response_types"
            class="mx-4"
            stacked
          ></b-radio-group>
        </div>
      </b-form-row>
      <!-- DICTADO -->
      <div v-else-if="question.kind === 2" class="px-3">
        <b-form-row>
          <b-form-group
            label="Ingresa la cantidad de elementos a dictar (máx. 20)"
            label-cols="8"
            label-class="mx-2"
          >
            <b-form-input
              type="number"
              min="0"
              max="20"
              v-model="question.dictation"
              size="sm"
            ></b-form-input>
          </b-form-group>
        </b-form-row>
        <b-row align-h="start">
          <b-button
            pill
            variant="primary"
            size="sm"
            class="mx-3"
            @click="openMultirecordModal(question, question.dictation)"
          >
            <v-icon name="microphone" /><b>
              Grabar {{ question.dictation }} audio{{
                question.dictation > 1 ? "s" : ""
              }}</b
            >
          </b-button>
          <div
            v-if="
              question.dictation_audios && question.dictation_audios.length > 0
            "
            class="my-1"
          >
            Ya has grabado {{ question.dictation_audios.length }} audios
          </div>
        </b-row>
      </div>
      <!-- ORDENAMIENTO -->
      <b-form-row v-else-if="question.kind === 3" class="px-3">
        <b-col cols="6">
          <div class="px-2 text-left">
            <h6>Ingresa las selecciones correctas</h6>
          </div>

          <b-row
            class="mx-1 align-items-center"
            v-for="(option, oind) of question.options.filter((o) => o.correct)"
            :key="`${oind}`"
            no-gutters
          >
            <b-col cols="1" class="text-center pt-2">
              <table>
                <tr>
                  <th class="py-0 vertical-align-middle">
                    <v-icon
                      name="arrow-up-on"
                      @click="moveOptionUp(option.index, true)"
                      style="cursor: pointer"
                      scale="0.5"
                      class="text-info mt-2"
                    />
                  </th>
                </tr>
                <tr>
                  <th>
                    <v-icon
                      name="arrow-down-on"
                      @click="moveOptionDown(option.index, true)"
                      style="cursor: pointer"
                      scale="0.5"
                      class="text-info mb-2"
                    />
                  </th>
                </tr>
              </table>
            </b-col>
            <b-col cols="7">
              <b-input
                v-if="!option.is_formula"
                class="d-inline"
                size="sm"
                v-model="option.text"
              ></b-input>
              <math-field
                @blur="
                  (event) => {
                    option.text = event.target.value;
                  }
                "
                v-else
                id="formula"
                virtual-keyboard-mode="manual"
                style="height: 50px; font-size: 20pt"
              >
                {{ option.text }}
              </math-field>
            </b-col>
            <b-col class="pl-1">
              <b-dropdown
                size="sm"
                toggle-class="rounded-circle custom-dots-button"
                no-caret
              >
                <template #button-content>
                  <ThreeDotsIcon
                    viewbox="-35 10 100 120"
                    width="12"
                    height="18"
                  />
                </template>
                <b-dropdown-item href="#" @click="manageRecording(option)">
                  Agregar audio <v-icon name="microphone" scale="1" />
                </b-dropdown-item>
                <b-dropdown-item href="#" @click="attachImage(option)">
                  Agregar imagen <v-icon name="paperclip" scale="1" />
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
            <b-col
              class="formula text-center"
              @click="option.is_formula = !option.is_formula"
            >
              <FormulaIcon
                class="formula-on"
                viewbox="-6 -6 100 100"
                width="36"
                height="36"
                :on="true"
              />
              <FormulaIcon
                class="formula-off"
                viewbox="-6 -6 100 100"
                width="36"
                height="36"
                :on="false"
              />
            </b-col>
            <b-col class="text-center" @click="removeOption(option.index)">
              <TrashIcon viewbox="-7 -7 100 100" width="35" height="35" />
            </b-col>

            <b-form-row class="px-3" v-if="option.audio">
              <div class="mx-2 h6 text-muted">
                <v-icon name="paperclip" />
                {{ option.audio ? "audio.mp3" : "" }}
                <b-button
                  class="ml-1 btn-circle"
                  pill
                  size="sm"
                  variant="danger"
                  @click="deleteAudio(option)"
                >
                  <v-icon name="trash-alt" />
                </b-button>
              </div>
            </b-form-row>

            <b-form-row class="px-3" v-if="option.image">
              <div class="mx-2 h6 text-muted">
                <v-icon name="paperclip" />
                {{
                  option.image
                    ? option.image.filename
                      ? `${option.image.filename}`
                      : option.image.substring(
                          option.image.lastIndexOf("/") + 1
                        )
                    : ""
                }}
                <b-button
                  class="ml-1 btn-circle"
                  pill
                  size="sm"
                  variant="danger"
                  @click="option.image = null"
                >
                  <v-icon name="trash-alt" />
                </b-button>
              </div>
            </b-form-row>
          </b-row>
          <b-form-row class="w-100 px-3 py-2 text-left" style="cursor: pointer">
            <div class="text-muted">
              <p
                class="h6 mx-2 text-muted"
                @click="question.options.push(getEmptyOption(true))"
              >
                Clic acá para agregar una alternativa
                <v-icon name="plus-circle" scale="1.2" />
              </p>
            </div>
          </b-form-row>
        </b-col>
        <b-col cols="6">
          <div class="px-2 text-left">
            <h6>Ingresa las selecciones incorrectas</h6>
          </div>

          <b-row
            class="mx-1 align-items-center"
            v-for="option of question.options.filter((o) => !o.correct)"
            :key="`iopt-${option.index}`"
            no-gutters
          >
            <b-col cols="1" class="text-center pt-2">
              <table>
                <tr>
                  <th class="py-0 vertical-align-middle">
                    <v-icon
                      name="arrow-up-on"
                      @click="moveOptionUp(option.index, true)"
                      style="cursor: pointer"
                      scale="0.5"
                      class="text-info mt-2"
                    />
                  </th>
                </tr>
                <tr>
                  <th>
                    <v-icon
                      name="arrow-down-on"
                      @click="moveOptionDown(option.index, true)"
                      style="cursor: pointer"
                      scale="0.5"
                      class="text-info mb-2"
                    />
                  </th>
                </tr>
              </table>
            </b-col>
            <b-col cols="7">
              <b-input
                v-if="!option.is_formula"
                class="d-inline"
                size="sm"
                v-model="option.text"
              ></b-input>
              <math-field
                @blur="
                  (event) => {
                    option.text = event.target.value;
                  }
                "
                v-else
                id="formula"
                virtual-keyboard-mode="manual"
                style="height: 50px; font-size: 20pt"
              >
                {{ option.text }}
              </math-field>
            </b-col>
            <b-col class="pl-1">
              <b-dropdown
                size="sm"
                toggle-class="rounded-circle custom-dots-button"
                no-caret
              >
                <template #button-content>
                  <ThreeDotsIcon
                    viewbox="-35 10 100 120"
                    width="12"
                    height="18"
                  />
                </template>
                <b-dropdown-item href="#" @click="manageRecording(option)">
                  Agregar audio <v-icon name="microphone" scale="1" />
                </b-dropdown-item>
                <b-dropdown-item href="#" @click="attachImage(option)">
                  Agregar imagen <v-icon name="paperclip" scale="1" />
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
            <b-col
              class="formula text-center"
              @click="option.is_formula = !option.is_formula"
            >
              <FormulaIcon
                class="formula-on"
                viewbox="-6 -6 100 100"
                width="36"
                height="36"
                :on="true"
              />
              <FormulaIcon
                class="formula-off"
                viewbox="-6 -6 100 100"
                width="36"
                height="36"
                :on="false"
              />
            </b-col>
            <b-col class="text-center" @click="removeOption(option.index)">
              <TrashIcon viewbox="-7 -7 100 100" width="35" height="35" />
            </b-col>

            <b-form-row class="px-3" v-if="option.audio">
              <div class="mx-2 h6 text-muted">
                <v-icon name="paperclip" />
                {{ option.audio ? "audio.mp3" : "" }}
                <b-button
                  class="ml-1 btn-circle"
                  pill
                  size="sm"
                  variant="danger"
                  @click="deleteAudio(option)"
                >
                  <v-icon name="trash-alt" />
                </b-button>
              </div>
            </b-form-row>

            <b-form-row class="px-3" v-if="option.image">
              <div class="mx-2 h6 text-muted">
                <v-icon name="paperclip" />
                {{
                  option.image
                    ? option.image.filename
                      ? `${option.image.filename}`
                      : option.image.substring(
                          option.image.lastIndexOf("/") + 1
                        )
                    : ""
                }}
                <b-button
                  class="ml-1 btn-circle"
                  pill
                  size="sm"
                  variant="danger"
                  @click="option.image = null"
                >
                  <v-icon name="trash-alt" />
                </b-button>
              </div>
            </b-form-row>
          </b-row>
          <b-form-row class="w-100 px-3 py-2 text-left" style="cursor: pointer">
            <div class="text-muted">
              <p
                class="h6 mx-2"
                @click="question.options.push(getEmptyOption())"
              >
                Clic acá para agregar una alternativa
                <v-icon name="plus-circle" scale="1.2" />
              </p>
            </div>
          </b-form-row>
        </b-col>
      </b-form-row>
    </div>

    <div v-else-if="selecting" class="selecting">
      <b-row class="m-1">
        <b-col cols="10">
          <p>{{ question.question }}</p>
        </b-col>
        <b-col cols="2" class="text-right">
          <b-button
            class="ml-1"
            v-b-tooltip.hover.left
            :title="`Ver otras ${
              question.count_relatives - 1
            } versiones de esta pregunta`"
            @click="getRelatives"
            v-b-toggle.sidebar-right-relatives
          >
            <v-icon name="users" size="40px" />
          </b-button>
        </b-col>
      </b-row>
      <div v-if="question.subtags.length">
        <b-badge
          v-for="(subtag, sIndex) in question.subtags"
          :key="'sbadge-' + sIndex"
          class="mx-1"
        >
          {{ subtag.parent_name }} - {{ subtag.name }}
        </b-badge>
      </div>
      <div v-else-if="!question.subtags.length && question.tag">
        <b-badge class="mx-1">
          {{ question.tag.name }}
        </b-badge>
      </div>
      <div v-if="question.has_texts">
        Textos Asociados:
        <ul>
          <li v-for="(text, tIndex) in texts" :key="'text-' + tIndex">
            <a
              style="cursor: pointer"
              @click="openText(text)"
              v-if="canOpenText"
              >{{ text.title }}</a
            >
            <p v-else>{{ text.title }}</p>
          </li>
        </ul>
      </div>
      <div v-if="question.formulas">
        <b-form-row
          v-for="(formula, findex) in question.formulas"
          :key="findex"
          class="px-3"
        >
          <b-col>
            <math-field
              @blur="
                (event) => {
                  change(findex, event.target.value);
                }
              "
              id="formula"
              ref="formula"
              style="height: 50px; font-size: 20pt"
              readonly="true"
            >
              {{ formula }}
            </math-field>
          </b-col>
        </b-form-row>
      </div>
      <b-row class="m-1">
        <div v-if="question.audio">
          <audio
            ref="player-question"
            :src="question.audio"
            autoplay
            @ended="nowPlaying = ''"
            @play="nowPlaying = 'question'"
          ></audio>
          <b-img
            @click="togglePlayer('question')"
            width="30px"
            class="ml-1 mr-1"
            alt="audio-img"
            :src="nowPlaying === 'question' ? repeatIcon : listenIcon"
            style="cursor: pointer"
          />
        </div>
        <div v-if="question.image">
          <v-icon
            @click="openModalImage(question.image)"
            name="paperclip"
            size="30px"
            class="ml-1 mr-1"
            style="cursor: pointer"
          />
        </div>
      </b-row>

      <div v-if="question.kind === 0">
        <div class="mb-3" v-if="question.is_count_question">
          <b-row class="m-1">
            <b-col :cols="question.number_of_images[1] > 0 ? '6' : '12'" class="d-flex flex-wrap" :class="question.number_of_images[1] > 0 ? 'border-right justify-content-center' : 'mx-auto justify-content-center align-items-center' ">
              <div v-for="(image, index) in question.number_of_images[0]" :key="index">
                <b-img
                  v-if="image_options.length > 0"
                  :src="image_options.find((img) => img.value === question.selected_images[0]).src"
                  :width="question.number_of_images[1] > 0 ? '60' : '70'"
                  class="m-1"
                  fluid
                />
              </div>
            </b-col>
            <b-col cols="6" class="d-flex flex-wrap justify-content-center" v-if="question.number_of_images[1] > 0">
              <div v-for="(image, index) in question.number_of_images[1]" :key="index">
                <b-img
                  v-if="image_options.length > 0"
                  :src="image_options.find((img) => img.value === question.selected_images[1]).src"
                  width="60"
                  class="m-1"
                  fluid
                />
              </div>
            </b-col>
          </b-row>
        </div>
        <b-row
          v-for="(alternative, aindex) of question.alternatives"
          :key="aindex"
          class="alternative m-2"
          :class="{
            alt_correct: alternative.is_correct,
            alt_incorrect: !alternative.is_correct,
          }"
        >
          <b-col cols="8">
            <p v-if="!alternative.is_formula">{{ alternative.text }}</p>
            <math-field
              class="align-middle"
              v-if="alternative.is_formula"
              :key="alternative.text"
              style="
                pointer-events: none;
                height: 50px;
                font-size: 20pt;
                margin-bottom: 10px;
              "
              id="formula"
              :read-only="true"
            >
              {{ alternative.text }}
            </math-field>
          </b-col>
          <b-col cols="4" class="text-right">
            <audio
              :ref="`player-alternative-${aindex}`"
              :src="alternative.audio"
              @ended="nowPlaying = ''"
              @play="nowPlaying = `alternative-${aindex}`"
            ></audio>
            <b-img
              v-if="alternative.audio"
              @click="altTogglePlayer(`alternative-${aindex}`)"
              width="25px"
              class="ml-1 mr-1"
              alt="audio-img"
              :src="
                nowPlaying === `alternative-${aindex}` ? repeatIcon : listenIcon
              "
              style="cursor: pointer"
            />

            <v-icon
              v-if="alternative.image"
              @click="openModalImage(alternative.image)"
              name="paperclip"
              size="30px"
              class="ml-1 mr-1"
              style="cursor: pointer"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="question.kind === 1">
        <b-row class="essay_question m-2">
          <p v-if="question.response_type === 1">Escribe una respuesta ...</p>
          <p v-else-if="question.response_type === 2">
            Adjunta una imagen para responder ...
          </p>
          <p v-else-if="question.response_type === 3">
            Graba un audio con tu respuesta ...
          </p>
          <p v-else-if="question.response_type === 4">
            Responde con una carta ...
          </p>
          <p v-else>Responde con una noticia ...</p>
        </b-row>
      </div>

      <div v-if="question.kind === 2">
        <b-row class="dictation_question">
          <p>Ingresa lo dictado en los {{ question.dictation }} audios ...</p>
        </b-row>
      </div>

      <div v-if="question.kind === 3">
        <b-row>
          <b-col cols="5" class="options">
            <div v-for="(option, oindex) of question.options" :key="oindex">
              <b-row v-if="option.correct" class="correct-option">
                <b-col cols="8">
                  <p>{{ option.text }}</p>
                </b-col>
                <b-col cols="4" class="text-right">
                  <audio
                    :ref="`player-option-${oindex}`"
                    :src="option.audio"
                    @ended="nowPlaying = ''"
                    @play="nowPlaying = `option-${oindex}`"
                  ></audio>
                  <b-img
                    v-if="option.audio"
                    @click="altTogglePlayer(`option-${oindex}`)"
                    width="25px"
                    class="ml-1 mr-1"
                    alt="audio-img"
                    :src="
                      nowPlaying === `option-${oindex}`
                        ? repeatIcon
                        : listenIcon
                    "
                    style="cursor: pointer"
                  />

                  <v-icon
                    v-if="option.image"
                    @click="openModalImage(option.image)"
                    name="paperclip"
                    size="30px"
                    class="ml-1 mr-1"
                    style="cursor: pointer"
                  />
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col cols="5" class="options">
            <div v-for="(option, oindex) of question.options" :key="oindex">
              <b-row v-if="!option.correct" class="incorrect-option">
                <b-col cols="8">
                  <p>{{ option.text }}</p>
                </b-col>
                <b-col cols="4" class="text-right">
                  <audio
                    :ref="`player-option-inc-${oindex}`"
                    :src="option.audio"
                    @ended="nowPlaying = ''"
                    @play="nowPlaying = `option-inc-${oindex}`"
                  ></audio>
                  <b-img
                    v-if="option.audio"
                    @click="altTogglePlayer(`option-inc-${oindex}`)"
                    width="25px"
                    class="ml-1 mr-1"
                    alt="audio-img"
                    :src="
                      nowPlaying === `option-inc-${oindex}`
                        ? repeatIcon
                        : listenIcon
                    "
                    style="cursor: pointer"
                  />
                  <v-icon
                    v-if="option.image"
                    @click="openModalImage(option.image)"
                    name="paperclip"
                    size="30px"
                    class="ml-1 mr-1"
                    style="cursor: pointer"
                  />
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>

    </div>

    <div v-else-if="listing">
      <!-- <b-row
        v-for="(relation, rIndex) in selectedHashtags"
        :key="rIndex + '-relation'"
      >
        <b-col
          v-for="(selectedHashtag, hIndex) in relation"
          :key="hIndex + '-sht'"
        >
          <b-select
            v-model="selectedHashtags[rIndex][hIndex]"
            @change="hashtagChanged(rIndex, hIndex)"
          >
            <b-select-option :value="null"
              >Seleccione
              {{
                selectedHashtagsOptions[rIndex][hIndex].relation_name
              }}</b-select-option
            >
            <b-select-option
              v-for="(child, cIndex) in selectedHashtagsOptions[rIndex][hIndex]
                ? selectedHashtagsOptions[rIndex][hIndex].children
                : []"
              :key="cIndex + '-child'"
              :value="child.id"
            >
              {{ child.name }}
            </b-select-option>
          </b-select>
        </b-col>
      </b-row> -->
      <div class="text-left mx-2 p-2" v-if="!loadingFamilies">
        <b-row>
          <b-col cols="3" class="cursor-pointer">
            <p
              class="h6 w-100"
              @click="createFromPage(materialPage, subject, grade)"
            >
              Crear nuevo ejercicio
              <v-icon name="plus-circle" scale="1.2" style="cursor: pointer" />
            </p>
          </b-col>
        </b-row>
        <hr />
        <div
          v-for="family in families.filter(
            (f) => !pushedFamilies.includes(f.id)
          )"
          :key="family.id"
        >
          <question-row-component
            :question="family.most_popular"
            :textRelated="family.text_related"
            :ejeRelated="family.eje_related"
            @open-question="selectQuestion(family.most_popular)"
            @select-question="
              $emit('add-selected-question', family.most_popular, materialPage)
            "
          />

          <hr />
        </div>
      </div>
      <b-row class="text-left mx-2 p-2" v-else>
        <b-col class="text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </b-col>
      </b-row>
      <b-row v-if="ePages > 1">
        <b-col class="text-center">
          <b-button-group>
            <b-button
              variant="outline-info"
              v-if="ePage > 1"
              @click="ePage = ePage - 1"
            >
              &#60;
            </b-button>
            <b-button
              variant="outline-info"
              :pressed="ePage === 1"
              @click="ePage = 1"
            >
              1
            </b-button>
            <b-button variant="outline-info" v-if="ePage - ePageRange / 2 > 2">
              ...</b-button
            >
            <b-button
              v-for="(p, index) in ePagesArray.filter((p) => {
                return (
                  1 < p &&
                  p < ePages &&
                  ((ePageRange < ePage &&
                    ePage - ePageRange / 2 <= p &&
                    p <= ePage + ePageRange / 2) ||
                    (ePageRange >= ePage && p <= ePageRange + 1))
                );
              })"
              :key="'page-' + index"
              :pressed="p === ePage"
              @click="ePage = p"
              variant="outline-info"
            >
              {{ p }}
            </b-button>
            <b-button
              variant="outline-info"
              v-if="ePages - 1 > ePage + ePageRange / 2"
            >
              ...</b-button
            >
            <b-button
              variant="outline-info"
              :pressed="ePage === ePages"
              @click="ePage = ePages"
            >
              {{ ePages }}</b-button
            >
            <b-button
              variant="outline-info"
              v-if="ePage < ePages"
              @click="ePage = ePage + 1"
            >
              &#62;
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </div>
    <b-sidebar
      id="sidebar-right-relatives"
      ref="sidebar-right-relatives"
      right
      bg-variant="white"
    >
      <div v-if="!waitingFamily" class="px-3 py-2">
        <div v-if="relatives.length > 1">
          <b-row
            v-for="relative in relatives.filter((r) => {
              return r.id !== question.id;
            })"
            :key="relative.id"
          >
            <question-card
              class="mx-2 w-100"
              :question="relative"
              @click.native="selectQuestion(relative, fromList)"
            />
          </b-row>
        </div>
        <div v-else>
          <p>No hay mas preguntas en esta familia</p>
        </div>
      </div>
      <div v-else class="px-3 py-2" align-self="center">
        <b-row>
          <b-col class="text-center">
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </b-col>
        </b-row>
      </div>
    </b-sidebar>
    <template #modal-footer>
      <div v-if="editing || creating" class="justify-content-center p-0 m-0">
        <b-button
          pill
          variant="danger"
          class="mx-1 custom-button"
          @click="discard"
          size="md"
        >
          <v-icon name="times" /> Descartar
        </b-button>
        <b-button
          pill
          variant="primary"
          class="mr-0 custom-button"
          @click="
            question.question ? save() : $refs['warning-empty-modal'].show()
          "
          :disabled="disableSave"
          size="px-3 md"
        >
          <b> Crear</b>
        </b-button>
        <b-button
          v-if="question.id && !editing"
          pill
          variant="primary"
          class="mx-1"
          @click="openRelatedMaterials"
          >Usar</b-button
        >
      </div>

      <div v-if="selecting" class="justify-content-center p-0 m-0">
        <b-button
          pill
          variant="danger"
          class="mx-1"
          v-on="fromList ? { click: close } : { click: backToList }"
        >
          <v-icon name="chevron-left" />
          <b> Volver</b>
        </b-button>
        <b-button
          v-if="!fromList"
          class="mr-0"
          pill
          variant="primary"
          @click="
            addToPage();
            backToList();
          "
        >
          <b>Seleccionar</b>
        </b-button>
        <b-button
          v-if="isGlobal"
          class="mr-0"
          pill
          variant="primary"
          @click="addToSchool(question.id)"
        >
          <b>Añadir a mi escuela</b>
        </b-button>
        <b-button
          class="mr-0"
          pill
          variant="primary"
          @click="editQuestion"
          :disabled="disableSave"
        >
          <b><v-icon name="edit" /> Editar</b>
        </b-button>
        <b-button
          v-if="question.id && !editing"
          pill
          variant="primary"
          class="mx-1"
          @click="openRelatedMaterials"
          >Usar</b-button
        >
      </div>
    </template>
  </b-modal>
</template>
<script>
import AudioRecordingModal from "@/components/custom-materials/modals/AudioRecordingModal";
import AdditionalInstructionModal from "@/components/custom-tests/modals/AdditionalInstructionModal";
import MultipleAudioRecorder from "@/components/custom-materials/modals/MultipleAudioRecorder";
import EvaluationMetricModal from "@/components/custom-materials/modals/EvaluationMetricModal";
import TextManagerModal from "@/components/custom-materials/modals/TextManagerModal";
import TagsComponent from "@/components/custom-tests/miscellaneous/TagsComponent";
import customMaterialService from "@/services/customMaterialService";
import customTestService from "@/services/customTestService";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import vueFilePond from "vue-filepond";
import QuestionRowComponent from "@/components/custom-materials/miscellaneous/QuestionRowComponent";
import FormulaIcon from "../icons/FormulaIcon";
import MicrophoneIcon from "../icons/MicrophoneIcon";
import ClipIcon from "../icons/ClipIcon";
import RubricIcon from "../icons/RubricIcon";
import ThreeDotsIcon from "../icons/ThreeDotsIcon";
import TrashIcon from "../icons/TrashIcon";
import WarningEmptyModal from "@/components/custom-materials/modals/WarningEmptyModal.vue";
import RelatedMaterialsSidebar from "@/components/custom-materials/miscellaneous/RelatedMaterialsSidebar";

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

export default {
  name: "QuestionManagerModal",
  props: {
    isGlobal: {
      type: Boolean,
      default: false,
    },
    pushedFamilies: {
      type: Array,
      required: false,
    },
  },
  components: {
    AudioRecordingModal,
    AdditionalInstructionModal,
    MultipleAudioRecorder,
    EvaluationMetricModal,
    FilePond,
    TagsComponent,
    QuestionRowComponent,
    TextManagerModal,
    FormulaIcon,
    MicrophoneIcon,
    ClipIcon,
    RubricIcon,
    ThreeDotsIcon,
    TrashIcon,
    WarningEmptyModal,
    RelatedMaterialsSidebar,
  },

  data() {
    return {
      loadingFamilies: false,
      appleIcon: require("../../../assets/img/icono-manzana.svg"),
      starIcon: require("../../../assets/img/icono-estrella.svg"),
      changed: false,
      questions: [],
      image_options: [],
      question: {
        question: "",
        formulas: [],
        additional_instruction: "",
        kind: 0,
        response_type: 0,
        col_type: 0,
        row_size_type: 0,
        dictation: 0,
        score: 1,
        text: null,
        rubric: null,
        alternatives: [],
        image: null,
        image_class: null,
        audio: null,
        audio_blob: null,
        options: [],
        dictation_audios: [],
        exercises: [],
        subtags: [],
        tag: null,
        family: "",
        is_count_question: false,
        number_of_images: [0, 0],
        selected_images: [null, null],
      },
      per_page: 8,
      ePages: 1,
      ePage: 1,
      ePageRange: 4,
      order: "-created_at",
      fromList: false,
      creating: false,
      editing: false,
      listing: false,
      selecting: false,
      pondObject: null,
      userID: this.$store.state.login.user_id,
      disableSave: false,
      materialPage: 0,
      modalImage: "",
      replacing: false,
      currentIndex: 0,
      kinds: [
        { text: "Alternativas", value: 0 },
        { text: "Desarrollo", value: 1 },
        // {text: 'Dictado', value: 2},
        { text: "Ordenamiento", value: 3 }
      ],
      response_types: [
        { text: "Texto escrito", value: 1 },
        { text: "Imagen", value: 2 },
        { text: "Audio", value: 3 },
        { text: "Carta", value: 4 },
        { text: "Noticia", value: 5 },
      ],
      nowPlaying: "",
      listenIcon: require("../../../assets/img/eureka_icons/icono_escuchar.svg"),
      repeatIcon: require("../../../assets/img/eureka_icons/icono_repetiraudio.svg"),
      resumeIcon: require("../../../assets/img/eureka_icons/icono_comenzar.svg"),
      pauseIcon: require("../../../assets/img/eureka_icons/icono_reproduciendo.svg"),
      icon_2: require("../../../assets/img/material_icons/Icon2.svg"),
      loadingGif: require("../../../assets/img/preloader.gif"),
      eKinds: {
        0: "Alternativas",
        1: "Desarrollo",
        2: "Dictado",
        3: "Ordenamiento"
      },
      subject: null,
      grade: null,
      texts: [],
      textID: "",
      preselectedHT: [],
      selectedHashtags: [],
      selectedHashtagsOptions: [],
      hashtags: [],
      families: [],
      waitingFamily: true,
      relatives: [],
      canOpenText: true,
      eje: "",
    };
  },
  mounted() {
    // this.retrieveData();
    console.log("mounted");

    // Obtener las imagenes para el select
    this.getCountImages();
  },
  destroyed() {
    console.log("destroy");
  },
  watch: {
    schoolID() {
      // this.retrieveData();
    },
    ePage() {
      if (this.hashtags.length) {
        // this.getFilteredQuestions();
        this.getFilteredFamilies();
      } else {
        // this.getPaginatedSchoolQuestions();
        this.getPaginatedSchoolFamilies();
      }
    },
    question: {
      handler() {
        if (!this.selecting) {
          this.changed = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    ePagesArray() {
      return Array.from({ length: this.ePages }, (_, i) => i + 1);
    },
    schoolID() {
      return this.$store.state.login.school;
    },
  },
  methods: {
    open() {
      this.changed = false;
      if (this.listing) {
        this.retrieveData();
      }
      if ((this.grade || this.grade === 0) && this.subject) {
        customTestService
          .getHashtagsByContent(this.subject, this.grade)
          .then((data) => {
            this.preselectedHT = data;
            if (this.listing) {
              // this.getFilters();
            }
          });
      } else if (this.listing) {
        // this.getFilters();
      }
      this.texts = [];
      if (this.question.has_texts) {
        customMaterialService.getRelatedTexts(this.question.id).then((data) => {
          this.texts = data;
        });
      }
      this.$refs["modal"].show();
    },
    addToSchool(questionID) {
      customMaterialService
        .associateQuestionSchool({
          school: this.$store.state.login.school,
          question: questionID,
        })
        .then((data) => {
          this.$toasted.success(data.message);
        });
    },
    openText(text) {
      this.$refs["text-manager"].selectText(text, true, false);
    },
    getFilteredQuestions() {
      customMaterialService
        .getPaginatedSchoolQuestions(
          this.$store.state.login.school,
          this.ePage,
          this.per_page,
          this.order,
          this.hashtags,
          this.textID
        )
        .then((data) => {
          this.questions = data.questions;
          this.ePages = data.pages;
        })
        .catch(() =>
          this.$toasted.error("Ocurrió un problema al traer tus ejercicios")
        );
    },
    // Obtener id, nombre y src de las imagenes
    getCountImages() {
      customMaterialService.getCountImages().then((data) => {
        this.image_options = data;
      });
    },
    getFilteredFamilies() {
      this.loadingFamilies = true;
      customMaterialService
        .getPaginatedSchoolFamilies(
          this.$store.state.login.school,
          this.ePage,
          this.per_page,
          this.order,
          this.hashtags,
          false,
          this.eje,
          this.textID
        )
        .then((data) => {
          this.families = data.families;
          this.ePages = data.pages;
          this.loadingFamilies = false;
        })
        .catch(() =>
          this.$toasted.error("Ocurrió un problema al traer tus ejercicios")
        );
    },
    getFilters() {
      // this.visible = !this.visible;
      // this.selectedTag = null;
      // this.customTags = [];
      customTestService
        .getFilters("")
        .then((data) => {
          this.selectedHashtags = [];
          this.selectedHashtagsOptions = [];
          for (var i = 0; i < data.length; i++) {
            this.selectedHashtagsOptions.push([data[i]]);
            if (
              data[i].children.filter((child) => {
                return this.preselectedHT.includes(child.id);
              }).length
            ) {
              this.selectedHashtags.push([
                data[i].children.filter((child) => {
                  return this.preselectedHT.includes(child.id);
                })[0].id,
              ]);
              this.hashtagChanged(this.selectedHashtags.length - 1, i);
            } else {
              this.selectedHashtags.push([null]);
            }
          }
        })
        .catch(() =>
          this.$toasted.error("Ocurrió un problema al traer tus tags")
        );
    },
    // hashtagChanged(rIndex, hIndex) {
    //   customTestService
    //     .getFilters(this.selectedHashtags[rIndex][hIndex])
    //     .then((data) => {
    //       if (data.length) {
    //         if (this.selectedHashtags[rIndex].length === hIndex + 1) {
    //           if (
    //             data[0].children.filter((child) => {
    //               return this.preselectedHT.includes(child.id);
    //             }).length
    //           ) {
    //             this.selectedHashtags[rIndex].push(
    //               data[0].children.filter((child) => {
    //                 return this.preselectedHT.includes(child.id);
    //               })[0].id
    //             );
    //           } else {
    //             this.selectedHashtags[rIndex].push(null);
    //           }
    //           this.selectedHashtagsOptions[rIndex].push(data[0]);
    //         } else {
    //           this.$set(this.selectedHashtags[rIndex], hIndex + 1, null);
    //           this.$set(
    //             this.selectedHashtagsOptions[rIndex],
    //             hIndex + 1,
    //             data[0]
    //           );
    //           this.$set(
    //             this.selectedHashtags,
    //             rIndex,
    //             this.selectedHashtags[rIndex].slice(0, hIndex + 2)
    //           );
    //           this.$set(
    //             this.selectedHashtagsOptions,
    //             rIndex,
    //             this.selectedHashtagsOptions[rIndex].slice(0, hIndex + 2)
    //           );
    //         }
    //       }
    //       var hashtags = [];
    //       for (var i = 0; i < this.selectedHashtags.length; i++) {
    //         if (
    //           this.selectedHashtags[i][this.selectedHashtags[i].length - 1] ===
    //           null
    //         ) {
    //           hashtags.push(
    //             this.selectedHashtags[i][this.selectedHashtags[i].length - 2]
    //           );
    //         } else {
    //           hashtags.push(
    //             this.selectedHashtags[i][this.selectedHashtags[i].length - 1]
    //           );
    //         }
    //       }
    //       this.hashtags = hashtags;
    //       this.getFilteredFamilies();
    //       // console.log(hashtags);
    //     })
    //     .catch(() =>
    //       this.$toasted.error("Ocurrió un problema al traer tus tags")
    //     );
    // },
    retrieveData() {
      this.ePages = 1;
      this.ePage = 1;
      this.order = "-created_at";

      this.getPaginatedSchoolFamilies();
    },

    getPaginatedSchoolQuestions() {
      customMaterialService
        .getPaginatedSchoolQuestions(
          this.$store.state.login.school,
          this.ePage,
          this.per_page,
          this.order,
          "",
          this.textID
        )
        .then((data) => {
          this.questions = data.questions;
          this.ePages = data.pages;
        })
        .catch(() =>
          this.$toasted.error("Ocurrió un problema al traer tus preguntas")
        );
    },
    getPaginatedSchoolFamilies() {
      this.loadingFamilies = true;
      customMaterialService
        .getPaginatedFamiliesFilteredSchool(
          this.$store.state.login.school,
          this.ePage,
          this.per_page,
          this.eje,
          this.textID
        )
        .then((data) => {
          this.families = data.families;
          this.ePages = data.pages;
          this.loadingFamilies = false;
        })
        .catch(() =>
          this.$toasted.error("Ocurrió un problema al traer las preguntas")
        );
    },
    createNew(fromList = false) {
      this.changed = false;
      this.creating = true;
      this.editing = false;
      this.listing = false;
      this.selecting = false;
      this.fromList = fromList;
      if (fromList) {
        this.open();
      }
    },
    selectQuestion(question, fromList = false) {
      if (question.family !== this.question.family) {
        this.waitingFamily = true;
      }
      this.question = JSON.parse(JSON.stringify(question));
      this.creating = false;
      this.editing = false;
      this.listing = false;
      this.selecting = true;
      this.changed = false;
      this.fromList = fromList;
      if (fromList) {
        this.open();
      } else {
        this.canOpenText = false;
        this.texts = [];
        if (this.question.has_texts) {
          customMaterialService
            .getRelatedTexts(this.question.id)
            .then((data) => {
              this.texts = data;
            });
        }
      }
    },
    backToList() {
      this.$refs["sidebar-right-relatives"].hide();
      this.creating = false;
      this.editing = false;
      this.listing = true;
      this.selecting = false;
    },
    editQuestion() {
      this.$refs["sidebar-right-relatives"].hide();
      this.creating = false;
      this.editing = true;
      this.listing = false;
      this.selecting = false;
      this.open();
    },
    questionList(page, subject, grade, textID, eje) {
      console.log(eje);
      if (textID) {
        this.textID = textID;
      }
      this.materialPage = page;
      this.creating = false;
      this.editing = false;
      this.listing = true;
      this.selecting = false;
      this.fromList = false;
      this.subject = subject;
      this.grade = grade;
      this.eje = eje;
      this.open();
    },
    createFromPage(page, subject, grade) {
      this.creating = true;
      this.editing = false;
      this.listing = false;
      this.selecting = false;
      this.fromList = false;
      this.changed = false;
      this.materialPage = page;
      this.subject = subject;
      this.grade = grade;
      this.open();
    },
    editFromPage(question, page, qindex, grade, subject) {
      this.question = JSON.parse(JSON.stringify(question));
      this.creating = false;
      this.editing = true;
      this.listing = false;
      this.selecting = false;
      this.fromList = false;
      this.replacing = true;
      this.changed = false;
      this.waitingFamily = true;
      if (subject) {
        this.subject = subject;
      }
      if (grade) {
        this.grade = grade;
      }
      this.materialPage = page;
      this.currentIndex = qindex;
      this.open();
    },
    altTogglePlayer(name) {
      if (this.nowPlaying) {
        this.$refs[`player-${this.nowPlaying}`][0]["currentTime"] = 0;
      }
      this.nowPlaying = name;
      if (
        this.$refs[`player-${name}`] &&
        this.$refs[`player-${name}`][0]["paused"]
      ) {
        this.$refs[`player-${name}`][0]["play"]();
      } else {
        this.$refs[`player-${name}`][0]["currentTime"] = 0;
      }
    },
    togglePlayer(name) {
      if (this.nowPlaying) {
        this.$refs[`player-${this.nowPlaying}`]["currentTime"] = 0;
      }
      this.nowPlaying = name;
      console.log();
      if (
        this.$refs[`player-${name}`] &&
        this.$refs[`player-${name}`]["paused"]
      ) {
        console.log("toy acá");
        this.$refs[`player-${name}`]["play"]();
      } else {
        this.$refs[`player-${name}`]["currentTime"] = 0;
      }
    },
    manageRecording(obj) {
      // if(obj.audio instanceof Blob){
      //     obj.audio_blob = obj.audio
      //     obj.audio = window.URL.createObjectURL(obj.audio)
      // }
      this.$refs["audio-modal"].open(obj);
    },
    deleteAudio(obj) {
      obj.audio = null;
      obj.audio_blob = null;
    },
    openModalImage(img) {
      this.modalImage = img;
      this.$refs["image-modal"].show();
    },
    attachImage(obj) {
      this.pondObject = obj;
      this.$refs["pond-modal"].show();
    },
    openInstruction(obj) {
      this.$refs["instruction-modal"].open(obj);
    },
    getEmptyAlternative() {
      return {
        text: "",
        is_correct: false,
        is_formula: false,
      };
    },
    openMultirecordModal(question, total) {
      this.$refs["multi-audio-modal"].open(question, total);
    },

    removeOption(optIndex) {
      this.question.options = this.question.options.filter(
        (o) => o.index !== optIndex
      );
    },
    moveOptionUp(oIndex, correct) {
      let currIdx = this.question.options
        .filter((o) => o.correct === correct)
        .findIndex((o) => o.index === oIndex);
      if (currIdx === 0) {
        return;
      }
      let nextIdx = this.question.options.filter((o) => o.correct === correct)[
        currIdx - 1
      ].index;
      let temp = this.question.options[nextIdx];
      this.question.options.splice(nextIdx, 1, this.question.options[oIndex]);
      this.question.options.splice(oIndex, 1, temp);
      this.question.options[oIndex].index = oIndex;
      this.question.options[nextIdx].index = nextIdx;
    },
    moveOptionDown(oIndex, correct) {
      let options = this.question.options.filter((o) => o.correct === correct);
      let currIndex = options.findIndex((o) => o.index === oIndex);
      if (currIndex === options.length - 1) {
        return;
      }
      let prevIdx = this.question.options.filter((o) => o.correct === correct)[
        currIndex + 1
      ].index;
      let temp = this.question.options[oIndex];
      this.question.options.splice(oIndex, 1, this.question.options[prevIdx]);
      this.question.options.splice(prevIdx, 1, temp);
      this.question.options[oIndex].index = oIndex;
      this.question.options[prevIdx].index = prevIdx;
    },
    moveElementUp(alist, aidx) {
      if (aidx > 0) {
        alist.splice(aidx - 1, 2, alist[aidx], alist[aidx - 1]);
      }
    },
    moveElementDown(alist, aidx) {
      if (aidx < alist.length - 1) {
        alist.splice(aidx, 2, alist[aidx + 1], alist[aidx]);
      }
    },

    getEmptyOption(correct = false) {
      let index = this.question.options.length;
      return {
        index: index,
        text: "",
        is_formula: false,
        correct: correct,
      };
    },
    openRubric(question) {
      this.$refs["rubric-modal"].open(question);
    },
    saveRubric(rubric) {
      customMaterialService.saveRubric({
        question: this.question.id,
        rubric: rubric,
      });
    },

    closePond() {
      this.$refs["pond-modal"].hide();
    },
    associateImage() {
      let images = this.$refs.pond.getFiles();

      if (!this.pondObject) {
        return;
      }
      if (images.length < 1 && !this.pondObject.image) {
        this.$toasted.error("Debes subir una imagen para poder guardar");
        return;
      }
      if (this.pondObject.image && images.length === 0) {
        this.pondObject.image = null;
        return;
      }
      this.$set(this.pondObject, "image", images[0]);
      this.$set(
        this.pondObject,
        "image_url",
        window.URL.createObjectURL(images[0].file)
      );

      this.closePond();
    },
    discard() {
      if (this.changed) {
        this.$refs["warning-discard-modal"].show();
      } else {
        this.close();
      }
    },
    close() {
      this.question = {
        question: "",
        formulas: [],
        additional_instruction: "",
        kind: 0,
        response_type: 0,
        col_type: 0,
        row_size_type: 0,
        dictation: 0,
        score: 1,
        text: null,
        image: null,
        image_class: null,
        audio: null,
        audio_blob: null,
        rubric: null,
        alternatives: [],
        options: [],
        dictation_audios: [],
        exercises: [],
        subtags: [],
        family: "",
        is_count_question: false,
        number_of_images: [0, 0],
        selected_images: [null, null],
      };
      this.$refs["modal"].hide();
      this.modalImage = "";
      this.nowPlaying = "";
      this.pondObject = null;
      this.textID = "";
      this.canOpenText = true;
      this.creating = true;
      this.editing = false;
      this.listing = false;
      this.selecting = false;
      // this.fromList = false
      this.disableSave = false;
      this.changed = false;
      this.waitingFamily = true;
      this.relatives = [];
    },
    change(findex, val) {
      this.question.formulas[findex] = val;
    },

    addToPage() {
      this.$emit("add-selected-question", this.question, this.materialPage);

      // this.close();
    },
    saveSubtags(subtags, tag) {
      customMaterialService
        .saveSubtags({ question: this.question.id, subtags: subtags, tag: tag })
        .then((data) => {
          console.log(data);
        });
    },
    saveNumberOfImages(is_count_question, number_of_images, question_id) {
      customMaterialService
        .saveNumberOfImages({
          is_count_question: is_count_question,
          number_of_images: number_of_images,
          question: question_id
        })
        .then((data) => {
          console.log(data);
        });
    },
    saveSelectedImages(question_id, selected_images) {
      customMaterialService
        .saveSelectedImages({
          question: question_id,
          selected_images: selected_images
        })
        .then((data) => {
          console.log(data);
        });
    },
    deleteFormula(index) {
      this.question.formulas.splice(index, 1);
      console.log(this.question.formulas);
    },
    // Funcion para elegir una imagen aleatoria del array de imagenes
    pickRandom(image_options) {
      let randomIndex = Math.floor(Math.random() * image_options.length);
      let randomElement = image_options[randomIndex];

      return randomElement.value;
    },
    save() {
      this.disableSave = true;
      let questionMultimedia = [];

      let alternativeMultimedia = [];
      let dictationMultimedia = [];
      let optionsMultimedia = [];
      let rubric = this.question.rubric;
      let subtags = this.question.subtags;
      let tag = this.question.tag;
      let isCountQuestion = this.question.is_count_question;
      let numberOfImages = this.question.number_of_images;
      let selectedImages = this.question.selected_images;

      if (this.question.image && this.question.image.file) {
        questionMultimedia.push({ image: this.question.image.file });
        this.question.image = null;
      }

      if (this.question.audio && this.question.changed) {
        if (this.question.audio_blob) {
          questionMultimedia.push({ audio: this.question.audio_blob });
        } else if (this.question.audio instanceof Blob) {
          questionMultimedia.push({ audio: this.question.audio });
        }
        this.question.audio = null;
        delete this.question.audio_blob;
        delete this.question.changed;
      }
      if (
        this.question.additional_instruction &&
        this.question.additional_instruction.changed
      ) {
        if (this.question.additional_instruction.del === true) {
          delete this.question.additional_instruction;
        }
      }
      if (this.question.dictation_changed) {
        this.question.dictation_audios.forEach((a, aidx) => {
          a.index = aidx;
          dictationMultimedia.push({
            index: aidx,
            audio: a.audioBlob,
            correct_answers: a.correct_answers,
          });
        });
        delete this.question.dictation_changed;
      }
      this.question.alternatives.forEach((a, idx2) => {
        a.index = idx2;
        if (a.image && a.image.file) {
          alternativeMultimedia.push({
            alternative: a.index,
            image: a.image.file,
          });
          a.image = null;
        }
        if (a.audio && a.changed) {
          if (a.audio_blob) {
            alternativeMultimedia.push({
              alternative: a.index,
              audio: a.audio_blob,
            });
          } else if (a.audio instanceof Blob) {
            alternativeMultimedia.push({
              alternative: a.index,
              audio: a.audio,
            });
          }
          delete a.audio_blob;
          delete a.changed;
          a.audio = null;
        }
        // if(a.audio && !a.changed){
        //     delete a.audio
        // }
        // if(a.image && !a.image.file){
        //     delete a.image
        // }
      });

      this.question.options.forEach((o) => {
        if (o.image && o.image.file) {
          optionsMultimedia.push({ index: o.index, image: o.image.file });
          o.image = null;
        }
        if (o.audio && o.changed) {
          if (o.audio_blob) {
            optionsMultimedia.push({ index: o.index, audio: o.audio_blob });
          } else if (o.audio instanceof Blob) {
            optionsMultimedia.push({ index: o.index, audio: o.audio });
          }

          o.audio = null;
          delete o.audio_blob;
          delete o.changed;
        }
      });

      if (this.question.id && !this.editing) {
        console.log("Just before call update");
        console.log(this.question);
        console.log("updating");
        customMaterialService
          .updateQuestion(this.question)
          .then((data) => {
            this.question = data;
            // SAVING RUBRIC
            if (rubric) {
              this.saveRubric(rubric);
            }
            if (tag) {
              this.saveSubtags(subtags, tag);
            }
            if (numberOfImages[0] > 0 || numberOfImages[1] > 0){
              this.saveNumberOfImages(isCountQuestion, numberOfImages, this.question.id);

              if (numberOfImages[0] > 0){
                if (selectedImages[0] == null){
                  selectedImages[0] = this.pickRandom(this.image_options);
                }
                if (selectedImages[1] == null){
                  selectedImages[1] = this.pickRandom(this.image_options);
                }
                this.saveSelectedImages(this.question.id, selectedImages);
              }
            }

            this.sendFiles(
              questionMultimedia,
              alternativeMultimedia,
              dictationMultimedia,
              optionsMultimedia
            );
            if (this.question.additional_instruction) {
              this.saveInstructions(this.question.additional_instruction);
            }
            this.$toasted.success("Guardado");
            this.disableSave = false;

            this.close();
          })
          .catch(() =>
            this.$toasted.error("Ocurrió un error al actualizar la pregunta")
          );
      } else if (this.question.id && this.editing) {
        console.log("editing");
        delete this.question.id;
        this.question.options.forEach((o) => delete o.id);
        this.question.alternatives.forEach((a) => delete a.id);

        if (this.question.image && !this.question.image.file) {
          let img_url = new URL(this.question.image);
          let img_name = img_url.pathname.split("/").pop();
          questionMultimedia.push({ image: img_name });
          this.question.image = null;
        }

        if (this.question.audio) {
          questionMultimedia.push({ audio: this.question.audio });
          this.question.audio = null;
        }
        this.question.alternatives.forEach((a, idx2) => {
          a.index = idx2;
          if (a.image) {
            let img_url = new URL(a.image);
            let img_name = img_url.pathname.split("/").pop();
            alternativeMultimedia.push({
              alternative: a.index,
              image: img_name,
            });
            a.image = null;
          }
          if (a.audio) {
            alternativeMultimedia.push({
              alternative: a.index,
              audio: a.audio,
            });
            a.audio = null;
          }
          if (a.audio && !a.changed) {
            delete a.audio;
          }
          if (a.image && !a.image.file) {
            delete a.image;
          }
        });

        this.question.options.forEach((o) => {
          if (o.image) {
            let img_url = new URL(o.image);
            let img_name = img_url.pathname.split("/").pop();
            optionsMultimedia.push({ index: o.index, image: img_name });
            o.image = null;
          }
          if (o.audio) {
            optionsMultimedia.push({ index: o.index, audio: o.audio });
            o.audio = null;
          }
          if (o.audio && !o.changed) {
            delete o.audio;
          }
          if (o.image && !o.image.file) {
            delete o.image;
          }
        });
        customMaterialService
          .createQuestion(this.question)
          .then((data) => {
            this.question.id = data.id;
            // SAVING RUBRIC
            console.log("Just before save rubric");
            console.log(rubric);
            if (rubric) {
              this.saveRubric(rubric);
            }
            if (tag) {
              this.saveSubtags(subtags, tag);
            }
            if (numberOfImages[0] > 0 || numberOfImages[1] > 0){
              this.saveNumberOfImages(isCountQuestion, numberOfImages, this.question.id);

              if (numberOfImages[0] > 0){
                if (selectedImages[0] == null){
                  selectedImages[0] = this.pickRandom(this.image_options);
                }
                if (selectedImages[1] == null){
                  selectedImages[1] = this.pickRandom(this.image_options);
                }
                this.saveSelectedImages(this.question.id, selectedImages);
              }
            }

            this.sendFiles(
              questionMultimedia,
              alternativeMultimedia,
              dictationMultimedia,
              optionsMultimedia
            );
            if (this.question.additional_instruction) {
              this.saveInstructions(this.question.additional_instruction);
            }
            customMaterialService.associateQuestionSchool({
              question: data.id,
              school: this.schoolID,
            });
            setTimeout(() => {
              customMaterialService.getQuestion(data.id).then((response) => {
                if (!this.fromList) {
                  if (this.replacing) {
                    this.$emit(
                      "replace-selected-question",
                      response,
                      this.materialPage,
                      this.currentIndex
                    );
                  } else {
                    this.$emit(
                      "add-selected-question",
                      response,
                      this.materialPage
                    );
                  }
                } else {
                  this.$emit("created-question");
                }
              });

              this.$toasted.success("Guardado");

              this.disableSave = false;

              this.close();
            }, 1000);
          })
          .catch(() => {
            this.$toasted.error("Ocurrió un error al editar la pregunta");
          });
      } else {
        console.log("Just before call create");
        console.log(this.question);
        customMaterialService
          .createQuestion(this.question)
          .then((data) => {
            console.log(!this.fromList);
            this.question.id = data.id;
            // SAVING RUBRIC
            // console.log("Just before save rubric");
            // console.log(rubric);
            if (rubric) {
              this.saveRubric(rubric);
            }
            if (tag) {
              this.saveSubtags(subtags, tag);
            }
            if (numberOfImages[0] > 0 || numberOfImages[1] > 0){
              this.saveNumberOfImages(isCountQuestion, numberOfImages, this.question.id);

              if (numberOfImages[0] > 0){
                if (selectedImages[0] == null){
                  selectedImages[0] = this.pickRandom(this.image_options);
                }
                if (selectedImages[1] == null){
                  selectedImages[1] = this.pickRandom(this.image_options);
                }
                this.saveSelectedImages(this.question.id, selectedImages);
              }
            }

            console.log(!this.fromList);
            this.sendFiles(
              questionMultimedia,
              alternativeMultimedia,
              dictationMultimedia,
              optionsMultimedia
            );
            if (this.question.additional_instruction) {
              this.saveInstructions(this.question.additional_instruction);
            }
            console.log(!this.fromList);
            customMaterialService.associateQuestionSchool({
              question: data.id,
              school: this.schoolID,
            });
            customMaterialService.associateFamilySchool(
              data.family,
              this.schoolID
            );
            console.log(!this.fromList);
            setTimeout(() => {
              customMaterialService.getQuestion(data.id).then((response) => {
                if (!this.fromList) {
                  this.$emit(
                    "add-selected-question",
                    response,
                    this.materialPage
                  );
                } else {
                  this.$emit("created-question");
                }
              });

              this.$toasted.success("Guardado");

              this.disableSave = false;

              this.close();
            }, 1000);
          })
          .catch(() => {
            this.$toasted.error("Ocurrió un error al crear la pregunta");
          });
      }
    },
    sendFiles(
      questionMultimedia,
      alternativeMultimedia,
      dictationMultimedia,
      optionsMultimedia
    ) {
      questionMultimedia.forEach((q) => {
        let formData = new FormData();
        if (q.image) {
          formData.append("image", q.image);
        } else if (q.audio) {
          if (q.audio instanceof Blob) {
            formData.append(
              "audio",
              q.audio,
              `audio-${this.$moment().unix()}.wav`
            );
          } else {
            let audio_url = new URL(q.audio);
            let audio_name = audio_url.pathname.split("/").pop();
            formData.append("audio", audio_name);
          }
        } else {
          return;
        }
        formData.append("question", this.question.id);
        customMaterialService.uploadFiles(formData);
      });
      alternativeMultimedia.forEach((a) => {
        let formDataA = new FormData();
        if (a.image) {
          formDataA.append("image", a.image);
        }
        if (a.audio) {
          if (a.audio instanceof Blob) {
            formDataA.append(
              "audio",
              a.audio,
              `audio-${this.$moment().unix()}.wav`
            );
          } else {
            let audio_url = new URL(a.audio);
            let audio_name = audio_url.pathname.split("/").pop();
            formDataA.append("audio", audio_name);
          }
        }
        formDataA.append("alternative", a.alternative);
        formDataA.append("question", this.question.id);

        customMaterialService.uploadFiles(formDataA);
      });
      dictationMultimedia.forEach((a) => {
        let formDataD = new FormData();
        formDataD.append("question", this.question.id);
        formDataD.append("index", a.index);
        if (a.audio) {
          formDataD.append(
            "audio",
            a.audio,
            `audio-${this.$moment().unix()}.wav`
          );
        }

        a.correct_answers.forEach((x) =>
          formDataD.append("correct_answers", x)
        );
        customMaterialService.createDictation(formDataD).then((data) => {
          if (!this.question.dictation_audios) {
            this.question.dictation_audios = [];
          }
          this.question.dictation_audios.push(data);
        });
      });
      optionsMultimedia.forEach((o) => {
        let formData = new FormData();
        formData.append("question", this.question.id);
        formData.append("index", o.index);
        if (o.image) {
          formData.append("image", o.image);
        }
        if (o.audio) {
          if (o.audio instanceof Blob) {
            formData.append(
              "audio",
              o.audio,
              `audio-${this.$moment().unix()}.wav`
            );
          } else {
            let audio_url = new URL(o.audio);
            let audio_name = audio_url.pathname.split("/").pop();
            formData.append("audio", audio_name);
          }
        }
        customMaterialService.optionFiles(formData);
      });
    },
    saveInstructions(a) {
      let formData = new FormData();
      formData.append("question", this.question.id);

      if (a.del) {
        formData.append("delete", true);
      }
      if (a.temp_img) {
        formData.append("image", a.temp_img);
      }
      if (a.temp_audio) {
        formData.append(
          "audio",
          a.temp_audio,
          `audio-${this.$moment().unix()}.wav`
        );
      }
      formData.append("instruction", a.instruction);
      a.formulas.forEach((f) => {
        formData.append("formulas", f);
      });
      customMaterialService.saveAdditionalInstruction(formData);
    },
    getRelatives() {
      if (this.waitingFamily) {
        if (this.question.count_relatives > 1) {
          customMaterialService.getFamily(this.question.family).then((data) => {
            this.waitingFamily = false;
            this.relatives = data.questions;
          });
        } else {
          this.waitingFamily = false;
        }
      }
    },
    setTag(tag) {
      console.log("set tag");
      this.question.tag = tag;
    },
    setSubtag(subtag) {
      console.log("set subtag");
      this.question.subtags = subtag;
    },
    openRelatedMaterials() {
      this.$refs["related-materials-sidebar"].openFromQuestion();
    },
    openRelatedMaterial(materialID) {
      this.$emit("open-related-material", materialID);
    },
  },
};
</script>

<style>
.image-section {
  width: 40%;
  max-width: 75%;
  align-items: center;
}
.audio-section {
  width: 70%;
  max-width: 75%;
}
.question-form {
  width: 100%;
  border: #f8b500 1px solid;
  padding-bottom: 5em !important;
  margin: 0.5em auto !important;
}
.modal-image {
  text-align: center;
}
.alternative {
  background-color: rgb(243, 243, 243);
  border-radius: 5px;
  padding: 1em;
  min-height: 3em;
  width: 60%;
}
.alt_correct {
  border-color: rgb(105, 226, 105);
  border-style: dashed;
  border-width: thin;
}
.alt_incorrect {
  border-color: rgb(236, 65, 65);
  border-style: dashed;
  border-width: thin;
}
.essay_question {
  background-color: rgb(243, 243, 243);
  border-radius: 5px;
  width: 80%;
  height: 80px;
  padding: 10px 10px 25px 10px;
}

.dictation_question {
  background-color: rgb(243, 243, 243);
  border-radius: 5px;
  height: 50px;
  padding: 10px 10px 25px 10px;
}

.correct-option {
  background-color: rgb(243, 243, 243);
  margin: 1em 0 1em 0;
  border-radius: 5px;
  padding: 1em;
  min-height: 3em;
  max-height: 1000px;
  border-color: rgb(105, 226, 105);
  border-style: dashed;
  border-width: thin;
}
.incorrect-option {
  background-color: rgb(243, 243, 243);
  margin: 1em 0 1em 0;
  border-radius: 5px;
  padding: 1em;
  min-height: 3em;
  max-height: 1000px;
  border-color: rgb(236, 65, 65);
  border-style: dashed;
  border-width: thin;
}

.creating,
.selecting {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
.modal-footer {
  justify-content: center !important;
}

.formula:hover > .formula-on,
.formula > .formula-off {
  display: none;
}
.formula:hover > .formula-off {
  display: inline;
}
.custom-button {
  min-width: 120px;
}
.custom-dots-button {
  background-color: #00b9ae !important;
}
</style>
<style scoped>
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
}
.cursor-pointer {
  cursor: pointer;
}
</style>

<style>
.ML__keyboard {
  z-index: 1060 !important;
}
</style>
