<template lang="">
  <div class="container">
    <h1>Prueba Perfil Sensorial</h1>
    
    <!-- Selector de edad -->
    <div class="form-group">
      <label for="edad">Seleccione la edad del niño/a:</label>
      <select v-model="answer.answers.edad" id="edad" class="select-field">
        <option v-for="option in edadOptions" :value="option.value" :key="option.value">{{ option.label }}</option>
      </select>
    </div>

    <!-- Sección de tablas por dimensión -->
    <div v-for="(dimension, index) in groupedByDimension" :key="index" class="dimension-section">
      <h3>{{ dimension.label }}</h3>
      <table class="table">
        <thead>
          <tr>
            <th>Cuadrante</th>
            <th>Item</th>
            <th>Descripción</th>
            <th>Frecuencia (0-5)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="question in dimension.items" :key="question.item">
            <td>{{ question.cuadrante }}</td>
            <td>{{ question.item }}</td>
            <td>{{ question.descripcion }}</td>
            <td>
              <input
                type="number"
                :id="'item_' + question.item"
                v-model="answer.answers[question.var]"
                :min="0"
                :max="5"
                class="input-field"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <button @click="send_test" class="submit-btn">Corregir</button>
  </div>
</template>

<script>
import redpieService from "../../../../services/redpieService";
import testService from "../../../../services/testService";

export default {
  props: ["test_id", "student_id", "previous_answer", "semester"],
  watch: {
    student_id() {
      this.answer.student_id = this.student_id;
    },
    test_id() {
      this.answer.test = this.test_id;
    },
    previous_answer: {
      handler(val) {
        this.$nextTick(() => {
          for (const [key, value] of Object.entries(val)) {
            this.answer.answers[key] = value || "0";
          }
        });
      },
      deep: true,
    },
  },
  mounted() {
    if (this.previous_answer && Object.keys(this.previous_answer).length) {
      for (const [key, value] of Object.entries(this.previous_answer)) {
        this.$set(this.answer.answers, key, value || "0");
      }
    }
  },
  data() {
    return {
      edadOptions: [
        { value: "3-4", label: "3-4 años" },
        { value: "5-6", label: "5-6 años" },
        { value: "7-8", label: "7-8 años" },
        { value: "9-10", label: "9-10 años" },
        { value: "11-14", label: "11-14 años" }
      ],
      answer: {
        test: this.test_id,
        answers: this.defaultAnswers(),
        student_id: this.student_id,
        semester: this.semester,
      },
      // Lista completa de los 86 ítems agrupada por dimensiones con variables 'var'
      test: [
        { dimension: "Procesamiento AUDITIVO", cuadrante: "EV", item: 1, var: 'item_1', descripcion: "reacciona intensamente a sonidos fuertes e inesperados." },
        { dimension: "Procesamiento AUDITIVO", cuadrante: "EV", item: 2, var: 'item_2', descripcion: "se tapa los oídos con las manos para protegerlos de los sonidos." },
        { dimension: "Procesamiento AUDITIVO", cuadrante: "SE", item: 3, var: 'item_3', descripcion: "le cuesta terminar las tareas cuando está puesta la música o la televisión." },
        { dimension: "Procesamiento AUDITIVO", cuadrante: "SE", item: 4, var: 'item_4', descripcion: "se distrae cuando hay mucho ruido a su alrededor." },
        { dimension: "Procesamiento AUDITIVO", cuadrante: "EV", item: 5, var: 'item_5', descripcion: "rinde poco cuando hay ruido ambiental (p. ej., ventilador, frigorífico)." },
        { dimension: "Procesamiento AUDITIVO", cuadrante: "SE", item: 6, var: 'item_6', descripcion: "no me hace caso o parece ignorarme." },
        { dimension: "Procesamiento AUDITIVO", cuadrante: "SE", item: 7, var: 'item_7', descripcion: "parece que no oye cuando lo llamo por su nombre (aunque oye bien)." },
        { dimension: "Procesamiento AUDITIVO", cuadrante: "RE", item: 8, var: 'item_8', descripcion: "disfruta con los ruidos extraños o hace ruidos por diversión." },

        { dimension: "Procesamiento VISUAL", cuadrante: "SE", item: 9, var: 'item_9', descripcion: "prefiere jugar o trabajar con poca luz." },
        { dimension: "Procesamiento VISUAL", cuadrante: "SE", item: 10, var: 'item_10', descripcion: "prefiere ropa de colores vivos o estampada." },
        { dimension: "Procesamiento VISUAL", cuadrante: "SE", item: 11, var: 'item_11', descripcion: "disfruta observando los detalles de los objetos." },
        { dimension: "Procesamiento VISUAL", cuadrante: "RE", item: 12, var: 'item_12', descripcion: "necesita ayuda para encontrar objetos que son evidentes para otras personas." },
        { dimension: "Procesamiento VISUAL", cuadrante: "SE", item: 13, var: 'item_13', descripcion: "le molestan las luces brillantes más que a otros niños de su edad." },
        { dimension: "Procesamiento VISUAL", cuadrante: "BU", item: 14, var: 'item_14', descripcion: "mira a las personas que se mueven por la habitación." },

        { dimension: "", cuadrante: "EV", item: 15, var: 'item_15', descripcion: "le molestan las luces brillantes (p. ej., se esconde de la luz que entra por la ventana del coche)." },

        { dimension: "Procesamiento TÁCTIL", cuadrante: "SE", item: 16, var: 'item_16', descripcion: "se muestra angustiado cuando lo arreglan." },
        { dimension: "Procesamiento TÁCTIL", cuadrante: "SE", item: 17, var: 'item_17', descripcion: "se irrita por tener que llevar zapatos o calcetines." },
        { dimension: "Procesamiento TÁCTIL", cuadrante: "EV", item: 18, var: 'item_18', descripcion: "reacciona impulsiva o agresivamente cuando alguien lo toca." },
        { dimension: "Procesamiento TÁCTIL", cuadrante: "SE", item: 19, var: 'item_19', descripcion: "se pone nervioso cuando está de pie cerca de otras personas." },
        { dimension: "Procesamiento TÁCTIL", cuadrante: "SE", item: 20, var: 'item_20', descripcion: "se frota o rasca la parte del cuerpo que alguien le ha tocado." },
        { dimension: "Procesamiento TÁCTIL", cuadrante: "BU", item: 21, var: 'item_21', descripcion: "toca tanto a las personas o a las cosas que llega a molestar a los demás." },
        { dimension: "Procesamiento TÁCTIL", cuadrante: "BU", item: 22, var: 'item_22', descripcion: "muestra la necesidad de tocar juguetes, superficies o texturas." },
        { dimension: "Procesamiento TÁCTIL", cuadrante: "RE", item: 23, var: 'item_23', descripcion: "parece no darse cuenta del dolor." },
        { dimension: "Procesamiento TÁCTIL", cuadrante: "RE", item: 24, var: 'item_24', descripcion: "parece no darse cuenta de los cambios de temperatura." },
        { dimension: "Procesamiento TÁCTIL", cuadrante: "BU", item: 25, var: 'item_25', descripcion: "toca a las personas o las cosas más que otros niños de su edad." },
        { dimension: "Procesamiento TÁCTIL", cuadrante: "RE", item: 26, var: 'item_26', descripcion: "parece no darse cuenta de que tiene las manos o la cara sucias." },

        { dimension: "Procesamiento MOVIMIENTO", cuadrante: "BU", item: 27, var: 'item_27', descripcion: "se mueve tanto que afecta a sus actividades diarias." },
        { dimension: "Procesamiento MOVIMIENTO", cuadrante: "BU", item: 28, var: 'item_28', descripcion: "se balancea mientras está sentado en la silla, en el suelo o de pie." },
        { dimension: "Procesamiento MOVIMIENTO", cuadrante: "SE", item: 29, var: 'item_29', descripcion: "vacila al subir o bajar aceras o escaleras." },
        { dimension: "Procesamiento MOVIMIENTO", cuadrante: "BU", item: 30, var: 'item_30', descripcion: "se muestra entusiasmado mientras realiza tareas que implican movimiento." },
        { dimension: "Procesamiento MOVIMIENTO", cuadrante: "BU", item: 31, var: 'item_31', descripcion: "realiza movimientos o trepa de manera arriesgada y peligrosa." },
        { dimension: "Procesamiento MOVIMIENTO", cuadrante: "BU", item: 32, var: 'item_32', descripcion: "busca oportunidades para caerse sin tener en cuenta su seguridad." },
        { dimension: "Procesamiento MOVIMIENTO", cuadrante: "RE", item: 33, var: 'item_33', descripcion: "pierde el equilibrio inesperadamente cuando camina por una superficie irregular." },
        { dimension: "Procesamiento MOVIMIENTO", cuadrante: "RE", item: 34, var: 'item_34', descripcion: "choca con las cosas, sin darse cuenta de los objetos o personas que hay en su camino." },

        { dimension: "Procesamiento CORPORAL", cuadrante: "RE", item: 35, var: 'item_35', descripcion: "se mueve con rigidez." },
        { dimension: "Procesamiento CORPORAL", cuadrante: "RE", item: 36, var: 'item_36', descripcion: "se cansa fácilmente, en especial cuando está de pie o mantiene el cuerpo en una misma posición." },
        { dimension: "Procesamiento CORPORAL", cuadrante: "RE", item: 37, var: 'item_37', descripcion: "parece tener músculos débiles." },
        { dimension: "Procesamiento CORPORAL", cuadrante: "RE", item: 38, var: 'item_38', descripcion: "se apoya para sostenerse." },
        { dimension: "Procesamiento CORPORAL", cuadrante: "RE", item: 39, var: 'item_39', descripcion: "se agarra a cosas, paredes o barandillas más que otros niños de su edad." },
        { dimension: "Procesamiento CORPORAL", cuadrante: "RE", item: 40, var: 'item_40', descripcion: "camina haciendo ruido, como si le pesaran los pies." },
        { dimension: "Procesamiento CORPORAL", cuadrante: "BU", item: 41, var: 'item_41', descripcion: "se estira echándose sobre los muebles o las personas." },
        { dimension: "Procesamiento CORPORAL", cuadrante: "SE", item: 42, var: 'item_42', descripcion: "necesita mantas gruesas para dormir." },

        { dimension: "Procesamiento ORAL", cuadrante: "SE", item: 43, var: 'item_43', descripcion: "tiene arcadas fácilmente con determinadas texturas de los alimentos o al ponerse los cubiertos en la boca." },
        { dimension: "Procesamiento ORAL", cuadrante: "SE", item: 44, var: 'item_44', descripcion: "rechaza ciertos sabores u olores de alimentos que son habituales en la dieta infantil." },
        { dimension: "Procesamiento ORAL", cuadrante: "SE", item: 45, var: 'item_45', descripcion: "solo toma comidas con ciertos sabores." },
        { dimension: "Procesamiento ORAL", cuadrante: "SE", item: 46, var: 'item_46', descripcion: "se limita a determinadas texturas de los alimentos." },
        { dimension: "Procesamiento ORAL", cuadrante: "SE", item: 47, var: 'item_47', descripcion: "es escrupuloso con la comida, en especial con las texturas de los alimentos." },
        { dimension: "Procesamiento ORAL", cuadrante: "BU", item: 48, var: 'item_48', descripcion: "huele cosas que no son comida." },
        { dimension: "Procesamiento ORAL", cuadrante: "BU", item: 49, var: 'item_49', descripcion: "muestra una clara preferencia por ciertos sabores." },
        { dimension: "Procesamiento ORAL", cuadrante: "BU", item: 50, var: 'item_50', descripcion: "tiene antojos de ciertos alimentos, sabores u olores." },
        { dimension: "Procesamiento ORAL", cuadrante: "BU", item: 51, var: 'item_51', descripcion: "se mete cosas en la boca." },
        { dimension: "Procesamiento ORAL", cuadrante: "SE", item: 52, var: 'item_52', descripcion: "se muerde más la lengua o los labios que otros niños de su edad." },

        { dimension: "Respuesta CONDUCTUAL", cuadrante: "RE", item: 53, var: 'item_53', descripcion: "parece propenso a tener accidentes." },
        { dimension: "Respuesta CONDUCTUAL", cuadrante: "RE", item: 54, var: 'item_54', descripcion: "pinta, escribe o dibuja apresuradamente." },
        { dimension: "Respuesta CONDUCTUAL", cuadrante: "BU", item: 55, var: 'item_55', descripcion: "corre riesgos excesivos." },
        { dimension: "Respuesta CONDUCTUAL", cuadrante: "BU", item: 56, var: 'item_56', descripcion: "parece más activo que otros niños de su edad." },
        { dimension: "Respuesta CONDUCTUAL", cuadrante: "RE", item: 57, var: 'item_57', descripcion: "hace las cosas de una forma más complicada de lo necesario." },
        { dimension: "Respuesta CONDUCTUAL", cuadrante: "EV", item: 58, var: 'item_58', descripcion: "puede ser terco y poco dispuesto a colaborar." },
        { dimension: "Respuesta CONDUCTUAL", cuadrante: "EV", item: 59, var: 'item_59', descripcion: "coge berrinches." },
        { dimension: "Respuesta CONDUCTUAL", cuadrante: "BU", item: 60, var: 'item_60', descripcion: "parece que disfruta cuando se cae." },
        { dimension: "Respuesta CONDUCTUAL", cuadrante: "EV", item: 61, var: 'item_61', descripcion: "se muestra reacio a tener contacto visual." },

        { dimension: "Respuesta SOCIOEMOCIONAL", cuadrante: "RE", item: 62, var: 'item_62', descripcion: "parece que tiene la autoestima baja." },
        { dimension: "Respuesta SOCIOEMOCIONAL", cuadrante: "EV", item: 63, var: 'item_63', descripcion: "requiere refuerzo positivo para volver a enfrentarse a los retos." },
        { dimension: "Respuesta SOCIOEMOCIONAL", cuadrante: "EV", item: 64, var: 'item_64', descripcion: "es sensible a las críticas." },
        { dimension: "Respuesta SOCIOEMOCIONAL", cuadrante: "EV", item: 65, var: 'item_65', descripcion: "tiene miedos explícitos y previsibles." },
        { dimension: "Respuesta SOCIOEMOCIONAL", cuadrante: "EV", item: 66, var: 'item_66', descripcion: "manifiesta que se siente un fracasado." },
        { dimension: "Respuesta SOCIOEMOCIONAL", cuadrante: "EV", item: 67, var: 'item_67', descripcion: "es muy serio." },
        { dimension: "Respuesta SOCIOEMOCIONAL", cuadrante: "EV", item: 68, var: 'item_68', descripcion: "tiene fuertes arrebatos emocionales cuando no puede terminar una tarea." },
        { dimension: "Respuesta SOCIOEMOCIONAL", cuadrante: "SE", item: 69, var: 'item_69', descripcion: "le cuesta interpretar el lenguaje corporal o las expresiones faciales." },
        { dimension: "Respuesta SOCIOEMOCIONAL", cuadrante: "EV", item: 70, var: 'item_70', descripcion: "se frustra fácilmente." },
        { dimension: "Respuesta SOCIOEMOCIONAL", cuadrante: "EV", item: 71, var: 'item_71', descripcion: "tiene miedos que afectan a sus actividades diarias." },
        { dimension: "Respuesta SOCIOEMOCIONAL", cuadrante: "EV", item: 72, var: 'item_72', descripcion: "se angustia cuando cambian los planes." },
        { dimension: "Respuesta SOCIOEMOCIONAL", cuadrante: "SE", item: 73, var: 'item_73', descripcion: "necesita más protección en la vida que otros niños de su edad." },
        { dimension: "Respuesta SOCIOEMOCIONAL", cuadrante: "EV", item: 74, var: 'item_74', descripcion: "interactúa o participa menos en los grupos." },
        { dimension: "Respuesta SOCIOEMOCIONAL", cuadrante: "EV", item: 75, var: 'item_75', descripcion: "tiene dificultades con las amistades." },

        { dimension: "Respuesta ATENCIONAL", cuadrante: "RE", item: 76, var: 'item_76', descripcion: "pierde el contacto visual conmigo cuando interactúo con él en el día a día." },
        { dimension: "Respuesta ATENCIONAL", cuadrante: "SE", item: 77, var: 'item_77', descripcion: "le cuesta prestar atención." },
        { dimension: "Respuesta ATENCIONAL", cuadrante: "SE", item: 78, var: 'item_78', descripcion: "aparta la mirada de sus tareas para observar lo que sucede a su alrededor." },
        { dimension: "Respuesta ATENCIONAL", cuadrante: "RE", item: 79, var: 'item_79', descripcion: "se muestra indiferente en ambientes con mucha actividad." },
        { dimension: "Respuesta ATENCIONAL", cuadrante: "RE", item: 80, var: 'item_80', descripcion: "mira muy fijamente las cosas." },
        { dimension: "Respuesta ATENCIONAL", cuadrante: "EV", item: 81, var: 'item_81', descripcion: "mira muy fijamente a las personas." },
        { dimension: "Respuesta ATENCIONAL", cuadrante: "BU", item: 82, var: 'item_82', descripcion: "observa a todas las personas que se mueven por la habitación." },
        { dimension: "Respuesta ATENCIONAL", cuadrante: "BU", item: 83, var: 'item_83', descripcion: "pasa de hacer una cosa a hacer otra, tanto que afecta a sus actividades." },
        { dimension: "Respuesta ATENCIONAL", cuadrante: "SE", item: 84, var: 'item_84', descripcion: "se pierde fácilmente." },
        { dimension: "Respuesta ATENCIONAL", cuadrante: "RE", item: 85, var: 'item_85', descripcion: "lo pasa mal cuando ha de buscar algo en un entorno complejo." },

        { dimension: "", cuadrante: "RE", item: 86, var: 'item_86', descripcion: "parece no darse cuenta de las cosas a su alrededor." },
      ]
    };
  },
  computed: {
    groupedByDimension() {
      const dimensions = [...new Set(this.test.map(q => q.dimension))]; // Obtener dimensiones únicas
      return dimensions.map(dim => {
        return {
          label: dim || 'Sin dimensión', // Mostrar 'Sin dimensión' para ítems sin una dimensión
          items: this.test.filter(q => q.dimension === dim)
        };
      });
    },
  },
  methods: {
    defaultAnswers() {
      let answers = { edad: "" };
      for (let i = 1; i <= 86; i++) {
        answers[`item_${i}`] = "0";
      }
      return answers;
    },
    send_test() {
      this.answer.semester = this.semester;
      // Asegurarnos de que los valores sean cadenas de texto
      for (const key in this.answer.answers) {
        this.answer.answers[key] = String(this.answer.answers[key] || "0");
      }
      let test;
      testService
        .send_test(this.answer)
        .then((data) => {
          test = data;

          redpieService.assignDocuments({
            test: this.answer.test,
            school: this.$store.state.login.school,
            year: this.$store.state.login.year,
            student_grade: this.answer.student_id,
          });
        })
        .then(
          () => {
            this.$emit("update-document");
            this.$emit("add-test", test);
          },
          () => {
            this.$toasted.error("Ha ocurrido un error al crear test");
          }
        );
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #FFF3E0; /* Fondo amarillo claro */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #E65100; /* Naranjo oscuro */
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
}

.select-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #E65100;
  border-radius: 5px;
  background-color: #FFF3E0;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.table th, .table td {
  padding: 10px;
  border: 1px solid #E65100;
}

.input-field {
  width: 50px;
  text-align: center;
  border: 1px solid #E65100;
  border-radius: 5px;
  padding: 5px;
  background-color: #FFF3E0;
}

.submit-btn {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #FF9800; /* Naranjo fuerte */
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #FB8C00; /* Naranjo más oscuro */
}
</style>