<template>
  <b-container class="p-2">
    <b-form @submit.prevent="updateUser" ref="personal-data-form">
      <b-row>
        <b-col>
          <b-row class="text-center">
            <h4 class="w-100">Bienvenida/o a Redpie</h4>
            <p class="mx-auto">Por favor ingresa tus datos personales.</p>
          </b-row>
          <b-row v-if="existingUserID !== undefined">
            <b-col class="text-center">
              <b-overlay
                :show="loadImage"
                v-b-hover="handleHover"
                rounded="circle"
                style="width: 120px; height: 120px"
                class="mx-auto"
              >
                <b-img
                  :src="uploadedPic ? getPicUrl() : profilePic"
                  rounded="circle"
                  style="width: 120px; height: 120px"
                  thumbnail
                  center
                />
                <template v-slot:overlay>
                  <div
                    class="text-center p-5"
                    style="cursor: pointer !important"
                    @click="selectProfilePic()"
                  >
                    <v-icon name="camera" scale="2" />
                    <p><strong>Cargar Foto</strong></p>
                    <b-form-file
                      ref="picSelector"
                      v-model="uploadedPic"
                      style="display: none"
                      type="file"
                      @change="setPic"
                    />
                  </div>
                </template>
              </b-overlay>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="6">
          <b-form-row>
            <b-col cols="6">
              <b-form-group label="Primer Nombre *">
                <b-input
                  size="sm"
                  type="text"
                  required
                  v-model="user.first_name"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Segundo Nombre">
                <b-input size="sm" type="text" v-model="user.middle_name" />
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="6">
              <b-form-group label="Primer Apellido *">
                <b-input
                  size="sm"
                  type="text"
                  required
                  v-model="user.last_name"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Segundo Apellido">
                <b-input
                  size="sm"
                  type="text"
                  v-model="user.mothers_family_name"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="12">
              <b-form-group
                label="RUT *"
                :state="rutState"
                :invalid-feedback="rutFeedback"
                valid-feedback="RUT válido"
                description="Ingresa tu RUT sin puntos y con guión: 12345678-9"
              >
                <b-input
                  size="sm"
                  type="text"
                  required
                  :formatter="formatRUT"
                  v-model="user.rut"
                  :state="rutState"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-col>
      </b-row>

      <b-form-row>
        <b-col cols="6">
          <b-form-group label="Correo Electrónico *">
            <b-input
              disabled
              size="sm"
              type="email"
              required
              v-model="user.email"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Teléfono"
            description="Ingresa un teléfono de 9 dígitos"
          >
            <b-input-group prepend="+56" size="sm">
              <b-input
                size="sm"
                type="tel"
                v-model="user.phone"
                pattern="[0-9]{9}"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col cols="6">
          <b-form-group label="Profesión">
            <b-input size="sm" v-model="user.specialty" />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group label="Secreduc *">
            <b-input size="sm" type="number" v-model="user.secreduc" />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col cols="12">
          <b-form-group label="Rol *">
            <b-select size="sm" v-model="user.role" required>
              <b-select-option :value="null">Selecciona un Rol</b-select-option>
              <b-select-option
                v-for="(role, index) in roles"
                :key="index + '-role'"
                :value="role[0]"
                >{{ role[1] }}</b-select-option
              >
            </b-select>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col cols="6">
          <b-form-group label="Cursos con los que trabajo *">
            <div
              v-for="(gradeGroup, gIndex) in grades"
              :key="gIndex + '-group'"
            >
              <b-form-checkbox
                v-for="(grade, gIndex) in gradeGroup.levels"
                v-model="user.grades"
                :key="gIndex + '-grade'"
                :value="grade"
                name="flavour-3a"
              >
                {{ renames[grade.code] ? renames[grade.code] : grade.name }}
              </b-form-checkbox>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Diagnósticos con los que trabajo *">
            <b-button
              pill
              variant="primary"
              size="sm"
              @click="
                user.diagnoses.length === diagnoses.length
                  ? (user.diagnoses = [])
                  : (user.diagnoses = diagnoses)
              "
            >
              {{
                user.diagnoses.length === diagnoses.length
                  ? "Quitar todos"
                  : "Seleccionar todos"
              }}
            </b-button>
            <b-form-checkbox
              v-for="(diagnosis, dIndex) in diagnoses"
              v-model="user.diagnoses"
              :key="dIndex + '-diag'"
              :value="diagnosis"
              name="flavour-3a"
            >
              {{ diagnosis.name }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row v-if="user.level === 2">
        <b-form-checkbox v-model="user.receives_report" name="report">
          Quiero recibir informe semanal de actividades en mi correo
        </b-form-checkbox>
      </b-form-row>
      <b-button
        pill
        variant="primary"
        v-if="existingUserID !== undefined && showPass === false"
        @click="showPass = true"
      >
        Cambiar contraseña
      </b-button>
      <div v-if="existingUserID === undefined || showPass">
        <b-form-row>
          <b-col cols="6">
            <b-form-group
              :label="
                existingUserID === undefined
                  ? 'Nueva Contraseña *'
                  : 'Nueva Contraseña'
              "
              :state="passwordState"
              :invalid-feedback="passwordFeedback"
            >
              <b-input
                size="sm"
                type="password"
                v-model="user.password"
                autocomplete="chrome-off"
                :required="existingUserID === undefined"
                :state="passwordState"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :label="
                existingUserID === undefined
                  ? 'Repetir Nueva Contraseña *'
                  : 'Repetir Nueva Contraseña'
              "
            >
              <b-input
                size="sm"
                type="password"
                v-model="password_confirm"
                autocomplete="chrome-off"
                :required="existingUserID === undefined"
                @blur="checkPassword"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row
          v-if="
            existingUserID !== undefined &&
            $store.state.login.email === user.email
          "
        >
          <b-col>
            <b-form-group label="Contraseña actual">
              <b-input
                size="sm"
                type="password"
                v-model="actual_password"
                autocomplete="chrome-off"
                :required="existingUserID !== undefined"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
      </div>
      <!-- <b-form-row>
        <b-form-checkbox v-model="user.receives_report" name="report">
          Quiero recibir informe semanal de actividades en mi correo
        </b-form-checkbox>
      </b-form-row> -->
      <!-- <b-row align-h="end" class="px-3">
        <b-button size="lg" type="submit" pill variant="primary">
          Guardar <v-icon name="save" scale="1.2" />
        </b-button>
      </b-row> -->
    </b-form>
  </b-container>
</template>

<script>
import userService from "@/services/userService";
import informationService from "@/services/informationService";
import { authMixin } from "../../../mixins/authMixin";

export default {
  name: "PersonalData",
  mixins: [authMixin],
  props: {
    existingUserID: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      user: {
        first_name: "",
        middle_name: "",
        last_name: "",
        mothers_family_name: "",
        rut: "",
        email: "",
        phone: "",
        password: "",
        secreduc: "",
        specialty: "",
        diagnoses: [],
        grades: [],
        role: null,
        receives_report: false,
      },
      showPass: false,
      actual_password: "",
      rutState: null,
      rutFeedback: "",
      password_confirm: "",
      passwordState: null,
      passwordFeedback: "",
      flag: true,
      roles: [],
      loadImage: false,
      uploadedPic: null,
      diagnoses: [],
      grades: [],
      renames: {},
    };
  },
  methods: {
    getPicUrl() {
      return URL.createObjectURL(this.uploadedPic);
    },
    selectProfilePic() {
      if (this.loadImage) {
        this.$refs.picSelector.$el.childNodes[0].click();
      }
    },
    getExtension(filename) {
      var parts = filename.split(".");
      return parts[parts.length - 1];
    },
    isImage(filename) {
      var ext = this.getExtension(filename);
      switch (ext.toLowerCase()) {
        case "jpg":
        case "gif":
        case "bmp":
        case "png":
          //etc
          return true;
      }
      return false;
    },
    setPic(e) {
      if (this.isImage(e.target.files[0].name)) {
        this.uploadedPic = e.target.files[0];
        this.user.thumbnail = e.target.files[0];
      } else {
        this.$toasted.error("El archivo seleccionado no es una imagen!");
      }
    },
    formatRUT(val) {
      let rut = val.replaceAll(".", "");
      if (!rut.includes("-")) {
        this.rutState = false;
        this.rutFeedback = "Debes ingresar tu RUT con guión";
        return val;
      }
      rut = rut.replaceAll("-", "");
      let rnumber = rut.slice(0, -1);
      let dv = rut.slice(-1).toUpperCase();
      if (rnumber.length < 7) {
        this.rutState = false;
        this.rutFeedback = "El RUT ingresado es muy corto";
        return val;
      }
      let sum = 0;
      let mult = 2;
      for (let i = 1; i <= rnumber.length; i++) {
        let c = Number(rnumber.charAt(rnumber.length - i));
        sum = sum + mult * c;
        mult = mult < 7 ? mult + 1 : 2;
      }
      let expected = 11 - (sum % 11);
      let realdv = Number(dv);
      realdv = dv === "K" ? 10 : realdv;
      realdv = dv === "0" ? 11 : realdv;
      this.rutState = realdv === expected;
      if (this.rutState) {
        this.rutFeedback = "";
        val = rnumber + "-" + dv;
      } else {
        this.rutFeedback = "Dígito Verificador incorrecto";
      }
      return val;
    },
    checkPassword() {
      if (this.user.password !== this.password_confirm) {
        this.passwordState = false;
        this.passwordFeedback = "Las contraseñas no coinciden";
      } else {
        this.passwordState = true;
        this.passwordFeedback = "";
      }
    },
    handleHover(val) {
      this.loadImage = val;
    },
    updateUser() {
      if (
        this.existingUserID !== undefined &&
        !this.actual_password &&
        this.$store.state.login.email === this.user.email &&
        this.showPass
      ) {
        this.$toasted.error("Ingresa tu contraseña para actualizar tus datos!");
        return;
      }
      if ([1, 2, 3, 5, 6, 12, 17].includes(this.user.role)) {
        if (!this.user.rut) {
          this.$toasted.error("Ingresa tu RUT!");
          return;
        }
        if (!this.user.secreduc) {
          this.$toasted.error("Ingresa tu SECREDUC!");
          return;
        }
        if (!this.user.diagnoses || !this.user.diagnoses.length) {
          this.$toasted.error("Ingresa tus diagnósticos!");
          return;
        }
        if (!this.user.grades || !this.user.grades.length) {
          this.$toasted.error("Ingresa tus cursos!");
          return;
        }
      }
      if (this.user.password !== this.password_confirm) {
        this.passwordState = false;
        this.passwordFeedback = "Las contraseñas no coinciden";
        return;
      }
      var formData = new FormData();
      this.user.pic_changed = this.uploadedPic !== null;
      formData.append("actual_password", this.actual_password);
      formData.append("school", this.$store.state.login.school);
      formData.append("receives_report", this.user.receives_report);
      delete this.user.receives_report;
      for (var key in this.user) {
        if (key === "diagnoses" || key === "grades") {
          formData.append(
            key,
            this.user[key].map((element) => {
              return element.code;
            })
          );
        } else {
          formData.append(key, this.user[key]);
        }
      }
      userService.updateUser(formData, this.user.id).then(
        (data) => {
          if (this.existingUserID === undefined) {
            this.authenticate_password(
              {
                email: this.user.email,
                password: this.user.password,
              },
              this
            );
          } else {
            this.$toasted.success("Datos guardados!");
            if (this.$store.state.login.email === data.email) {
              userService.get_user_data().then((data) => {
                this.$store.commit("login/setUser", data);
              });
            }
            this.$emit("data-saved");
          }
        },
        (error) => {
          var message = "Ha ocurrido un error al actualizar tus datos.";
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message = error.response.data.message;
          }
          this.$toasted.error(message);
        }
      );
    },
    handleAuthenticated() {
      if (this.$store.state.login.schoolLevel === 2)
        window.location.href = "/redpie#/?invite=t";
      else window.location.href = "/redpie#/?welcome=t";
    },
  },
  computed: {
    profilePic() {
      return process.env.VUE_APP_BASE_URL + this.user.thumbnail;
    },
  },
  created() {
    this.$nextTick(() => {
      informationService.getRoles().then((data) => {
        this.roles = Object.entries(data.roles);
      });
      informationService.getDiagnosesGroupedList().then((data) => {
        this.diagnoses = data;
      });
      informationService.getPieGrades().then((data) => {
        this.grades = data.grades_by_level;
      });
      informationService
        .getGradeLevelsRenames(this.$store.state.login.school)
        .then((data) => {
          data.forEach((rename) => {
            this.renames[rename.grade] = rename.name;
          });
        });
      if (this.existingUserID !== undefined) {
        userService
          .getUserDataByID({
            school: this.$store.state.login.school,
            user: this.existingUserID,
          })
          .then((data) => {
            this.user = data;
            this.user.password = "";
            this.user.id = this.existingUserID;
          });
      } else {
        this.user.id = this.$store.state.login.user_id;
        this.user.email = this.$store.state.login.email;
      }
    });
  },
};
</script>

<style scoped></style>
