<template>
  <div>
      <ChooseTemplateModal ref="choose-template-modal" v-on:template-chose="templateChose"/>
      <b-row v-if="!student.id">
        <b-card bg-variant="Light" class="w-100 text-center">
          <b-card-text>Necesitas seleccionar un curso y estudiante para trabajar un documento.</b-card-text>
        </b-card>
      </b-row>
      <div v-else>
        <h1>Documentos</h1>
        <b-table-simple v-if="!loading">
          <b-thead>
            <b-tr>
              <b-th class="t-col-1">
                Documentos
              </b-th>
              <b-th class="text-center t-col-2">
                Aplicar
              </b-th>
              <b-th class="t-col-3">
                Tests
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(document, index) in documents" :key="index">
              <b-td>
                {{document.document_type.name}}
              </b-td>
              <b-td class="text-center">
                <SpecialTableTransformValue
                    :ref="'doc_' + document.document_type.id"
                    :diagnostic="student.diagnostic"
                    :docType="document.document_type"
                    class="mx-auto"
                    @setDoc="nothing"
                    @view-document="view_document"
                    @show-fudei="show_fudei"
                    @create-fudei="create_fudei"
                    @delete-document="delete_document"
                    @restore-document="restore_document"
                    @show-modal-upload-document="show_modal_upload_document"
                    @archived-document="archived_document"
                    @open-templates="openTemplates"
                    @document-updated="checkIfRequired"
                />
              </b-td>
              <b-td>
                <b-button
                  pill variant="outline-success"
                  class="mr-1 text-uppercase"
                  v-for="(test, index) in document.document_type.document_info.required_tests"
                  :key="index"
                  @click="doTest(test.test)"
                >
                  {{test.name}}
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-container v-else>
          <b-row>
            Cargando
          </b-row>
        </b-container>
      </div>
  </div>
</template>
<script>
import redpieService from "../../../../services/redpieService"
import SpecialTableTransformValue from "../SpecialTableTransformValue";
import ChooseTemplateModal from "../../modals/ChooseTemplateModal";

export default {
  props: {
    student: {
      type: Object,
      required: false,
    },
    tests: {
      type: Array,
      required: false
    },
    documents: {
      type: Array,
      required: false
    }
  },
  components: {
    SpecialTableTransformValue,
    ChooseTemplateModal,
  },
  data () {
    return {
      loading: false,
      focusedSGDT: null,
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    nothing: function() {
      return
    },
    show_modal_upload_document: function (data) {
      this.$emit("show-modal-upload-document", data);
    },
    show_fudei: function(fudei_id, sgdt_id, state) {
      this.$emit("show-fudei", fudei_id, sgdt_id, state);
    },
    create_fudei: function(sgdt_id) {
      this.$emit("create-fudei", sgdt_id);
    },
    view_document: function(data) {
      this.$emit('open-loading')
      this.$emit("view-document", data);
    },
    download_document: function (data) {
      this.$emit("download-document", data);
    },
    archived_document: function (data) {
      this.$emit("archived-document", data);
    },
    delete_document: function (data) {
      this.$emit("delete-document", data);
    },
    restore_document: function (data) {
      this.$emit("restore-document", data);
    },
    checkIfRequired(studentGradeDocumentType, docID, previousDoc) {
      if (studentGradeDocumentType === this.focusedSGDT && docID !== null && previousDoc === null) {
        this.requiredData['document_id'] = docID;
        this.view_document(this.requiredData);
        this.focusedSGDT = null;
      }
    },
    openTemplates(templates, docType, studentIndex, documentIndex, diagnostic, isOnline) {
      if (templates.filter(template => template.diagnostic === diagnostic).length > 0) {
        this.templateChose(
            templates.filter(template => template.diagnostic === diagnostic)[0].id,
            docType,
            studentIndex,
            documentIndex,
            isOnline
        )
      } else {
        this.$refs["choose-template-modal"].open(templates, docType, studentIndex, documentIndex, isOnline)
      }
    },
    templateChose(templateID, sg_dt_id, studentIndex, documentIndex, isOnline) {
      this.$emit('open-loading')
      this.focusedSGDT = sg_dt_id;
      this.requiredData = {
        student_index: studentIndex,
        document_index: documentIndex,
        student_grade_document_type_id: sg_dt_id,
        first_time: true,
        is_online: isOnline
      }
      redpieService.upload_document_with_template({
            document_template_id: templateID,
            student_grade_document_type_id: sg_dt_id
          }
      ).then(
          () => {
            this.$emit('reload-points');
          },
          error => {
            this.$emit('close-loading')
            var message = "Ha ocurrido un error al cargar el template";
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
              message = error.response.data.message;
            }
            this.$toasted.error(message);
          }
      )
    },
    openTemplate: function(dt_id) {
      // this.$emit("documents-mode");
      this.$refs[dt_id][0].handleWorkOnline();
    },
    doTest: function(test) {
      this.$emit("test-mode", test);
    }
  },
}
</script>
<style lang="scss">
.t-col-2 {
  min-width: 200px;
}
.t-col-3 {
  width: 500px;
}
</style>