<template>
    <div>
        <!-- Textarea para escribir la necesidad especial del/la estudiante -->
        <div v-if="msg.type == 'input-text'">
            <textarea
                v-model="specialConditionsInput"
                name="white-square"
                id="white-square"
                cols="30" rows="5"
                placeholder="Ejemplo: haz que la temática de la clase sea de los Avengers ya que a mis estudiantes les encanta."
                required>
            </textarea>
            <b-button block variant="primary" @click="handleSpecialConditionsInput" class="add-button my-2" :disabled="!specialConditionsInput.trim()">
                <strong>Agregar</strong>
            </b-button>
        </div>
        <!-- Mostrar necesidades especiales si es que el usuario ya las tenia guardadas o si acaba de guardar -->
        <div v-if="hasSpecialConditions">
            <div class="b-col mb-3">
                <p class="mb-1">Selecciona la opción que deseas agregar</p>
                <b-form-select v-model="selectedSpecialCondition" :options="specialConditions" @change="updateFinalPrompt">
                    <template #first>
                        <b-form-select-option :value="null" disabled>Selecciona una opción</b-form-select-option>
                    </template>
                </b-form-select>
            </div>
        </div>
    </div>
</template>

<script>
import chatBotService from "@/services/chatBotService";
export default {
    name: 'SpecialConditions',
    props: {
        msg: Object
    },
    data() {
        return {
            specialConditionsInput: "",
            specialConditions: [],
            selectedSpecialCondition: null,
            hasSpecialConditions: false,
            finalPrompt: ""
        }
    },
    methods: {
        // Funcion para agregar una necesidad especial a la lista al darle click al boton de Agregar
        handleSpecialConditionsInput() {
            // Obtener el input del usuario
            const input = this.specialConditionsInput.trim();
            if (!input) {
                // Mostrar un error si el input esta vacio
                this.$toasted.error(`Debes especificar las necesidades especiales`);
                return;
            }
            // Crear el objeto para agregarlo al select
            let condition = {
                text: input,
                value: input
            }
            // Agregar la necesidad especial a la lista
            this.specialConditions.push(condition);
            // Guardar la necesidad especial en la base de datos
            chatBotService.saveCondition(condition.value);
            // Seleccionar la necesidad especial agregada
            this.selectedSpecialCondition = condition.value;

            this.$toasted.success(`Hemos añadido tu contexto`);
            this.hasSpecialConditions = true;
            // Limpiar el input despues de agregar la necesidad especial
            this.specialConditionsInput = "";
            
            // Actualizar el prompt con la nueva necesidad especial agregada
            this.updateFinalPrompt();
        },
        // Actualizar el mensaje final
        updateFinalPrompt() {
            // Si no hay necesidades especiales seleccionadas, no se agrega nada al prompt
            if (!this.selectedSpecialCondition) {
                this.finalPrompt = "";
                return;
            }
            // Agregar las condiciones especiales seleccionadas al prompt
            this.finalPrompt = `${this.selectedSpecialCondition}`

            // Emitir el mensaje final al componente Chatbot
            this.$emit('update-condition', this.finalPrompt);
        }
    },
    mounted() {
        // Obtener las necesidades especiales guardadas del usuario
        chatBotService.getConditions().then((response) => {
            // Si el usuario ya tiene necesidades especiales guardadas, agregarlas a la lista de necesidades especiales
            if (response.special_condition.length > 0) {
                this.specialConditions = response.special_condition;
                this.hasSpecialConditions = true;
            }
        })
    }
}
</script>

<style>
    textarea {
        resize: none !important; /* Que no se pueda redimensionar */
        border-radius: 10px;
        border: 1.5pt dashed rgb(0, 185, 174, 0.5);
        padding: 10px;
        font-family: "Ubuntu Medium", Arial, sans-serif;
    }

    textarea:focus {
        outline: rgb(0, 121, 113);
        border: 2pt dashed rgb(0, 130, 120);
    }

    .add-button {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    label {
        font-family: "Ubuntu Medium", Arial, sans-serif;
        font-size: 1rem;
    }
</style>