<template lang="">
  <b-sidebar
    id="sidebar-right-related-materials"
    ref="sidebar-right-related-materials"
    right
    bg-variant="white"
    v-model="show"
  >
    <div v-if="!loadingMaterials" class="px-3 py-2">
      <div v-if="materials.length > 0">
        <b-row v-for="material in materials" :key="material.id">
          <material-row-component
            :material="material"
            @open-material="$emit('open-material', material.id)"
            mode="related-to-text"
          />
          <hr />
        </b-row>
      </div>
      <div v-else>
        <p>No hay materiales asociados</p>
      </div>
    </div>
    <div v-else class="px-3 py-2" align-self="center">
      <b-row>
        <b-col class="text-center">
          <b-spinner
            style="width: 3rem; height: 3rem"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </b-col>
      </b-row>
    </div>
  </b-sidebar>
</template>
<script>
import customMaterialService from "@/services/customMaterialService";
import MaterialRowComponent from "@/components/custom-materials/miscellaneous/MaterialRowComponent.vue";

export default {
  name: "RelatedMaterialsSidebar",
  components: {
    MaterialRowComponent,
  },
  props: {
    objectID: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      materials: [],
      loadingMaterials: false,
      show: false,
    };
  },
  mounted() {
    console.log("related materials sidebar mounted");
    // this.getRelatedMaterials();
  },
  methods: {
    getRelatedMaterialsToText() {
      this.loadingMaterials = true;
      customMaterialService
        .getRelatedMaterialsToText(
          this.objectID,
          this.$store.state.login.school
        )
        .then((data) => {
          this.materials = data;
          this.loadingMaterials = false;
        });
    },
    getRelatedMaterialsToQuestion() {
      this.loadingMaterials = true;
      customMaterialService
        .getRelatedMaterialsToQuestion(
          this.objectID,
          this.$store.state.login.school
        )
        .then((data) => {
          this.materials = data;
          this.loadingMaterials = false;
        });
    },
    openFromText() {
      this.getRelatedMaterialsToText();
      console.log("open sidebar");
      this.show = true;
    },
    openFromQuestion() {
      this.getRelatedMaterialsToQuestion();
      console.log("open sidebar");
      this.show = true;
    },
  },
};
</script>
<style lang=""></style>
