<template>
    <svg version="1.1" id="Layer_1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    :viewBox="viewbox"
    :width="width"
    :height="height"
    xml:space="preserve"
    class="custom-icon-button">
  
        <g>
            <circle class="st0" cx="43" cy="43" r="43"/>
            <path class="st1" d="M56.9,68l3.5-36l1.6,0.3c1,0.2,1.9-0.5,2.1-1.5l0.6-3.6c0.2-1-0.5-1.9-1.5-2.1l-10.7-1.7l0.4-2.7
                c0.2-1-0.5-1.9-1.5-2.1l-14.2-2.3c-1-0.2-1.9,0.5-2.1,1.5l-0.4,2.7L24,18.8c-1-0.2-1.9,0.5-2.1,1.5l-0.6,3.6
                c-0.2,1,0.5,1.9,1.5,2.1l20,3.3H26.3c-0.5,0-1,0.2-1.3,0.6c-0.3,0.4-0.5,0.9-0.5,1.4L28.1,68c0.1,0.9,0.9,1.7,1.8,1.7h25.2
                C56,69.7,56.8,68.9,56.9,68L56.9,68z M38.9,58.9c0,1-0.8,1.8-1.8,1.8c-1,0-1.8-0.8-1.8-1.8l0-18.9c0-1,0.8-1.8,1.8-1.8
                c1,0,1.8,0.8,1.8,1.8L38.9,58.9z M49.7,58.9c0,1-0.8,1.8-1.8,1.8c-1,0-1.8-0.8-1.8-1.8l0-18.9c0-1,0.8-1.8,1.8-1.8
                c1,0,1.8,0.8,1.8,1.8L49.7,58.9z"/>
        </g>

    </svg>
  </template>
  
  <script>
  export default {
    name: 'ClipIcon',
    props: {
        viewbox: {
              type: String,
              default: "0 0 18 18"
        },
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },
    },
   
  }
  </script>
  
<style scoped>
.st0{
    fill:#E84855;
}
.st1{
    fill:#FFFFFF;
}
</style>