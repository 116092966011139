<template>
  <b-modal ref="audio-modal" hide-header hide-footer centered class="text-center" @hide="close">
    <b-row class="text-center">
      <h5 class="mx-auto">Presiona el botón para grabar un nuevo audio</h5>
    </b-row>
    <b-row>
      <VueRecordAudio class="btn-recording" @result="handleAudio" mode="press"/>
    </b-row>
    <b-row>
      <b-col class="text-center">
        O sube tu archivo de audio
      </b-col>
    </b-row>
    <b-row>
      <b-form-file
          class="p-2 m-2"
          placeholder="Haz clic para navegar o arrastra y suelta"
          drop-placeholder="Arrastra aquí"
          accept="audio/*, video/mp4"
          @input="handleAudio"
      />
    </b-row>
    <b-row v-if="audio" class="p-2 m-2 text-left">
      <h6>Puedes escuchar tu grabación aquí: </h6>
      <audio controls :key="key" class="mx-auto w-100">
        <source :src="audio">
        Tu navegador no soporta la reproducción de audio
      </audio>
    </b-row>
    <b-row align-h="end" v-if="audio" class="p-2 m-1">
      <b-button pill variant="danger" size="sm" class="mx-1" @click="close">
        <v-icon name="times"/> Cancelar
      </b-button>
      <b-button pill variant="primary" size="sm" class="mx-1" @click="attachAudio">
        <v-icon name="save"/> Guardar
      </b-button>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: "AudioRecordingModal",
  data() {
    return {
      obj: null,
      audio: null,
      audioBlob: null,
      key: false
    }
  },
  methods: {
    open(obj){
      this.obj = obj
      if(obj.audio)
        this.audio = obj.audio
      this.$refs['audio-modal'].show()
    },
    handleAudio(data) {
      this.audioBlob = data
      this.audio = window.URL.createObjectURL(data)
      this.key = !this.key;
    },
    close() {
      this.audio = null
      this.audioBlob = null
      this.$refs['audio-modal'].hide()
    },
    attachAudio() {
      this.$set(this.obj, 'audio_blob', this.audioBlob)
    
      this.$set(this.obj, 'audio',  this.audio)
     
      
      this.$set(this.obj, 'changed', true)
      this.close()
    }
  },
}
</script>

<style scoped>
.btn-recording {
  margin: 1em auto !important;
  min-width: 90px;
  min-height: 90px;
}
</style>