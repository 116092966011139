<template>
  <b-modal ref="audio-modal" hide-header hide-footer centered class="text-center" size="xl" @hide="close">
    <b-row class="text-center">
      <h4 class="mx-auto">Audios Dictado</h4>
    </b-row>
    <div class="mx-3">
      <b-row v-for="(dictaudio, dictIndex) of audios" :key="`dict-audio-${dictIndex}`" class="dictation-row my-2">
        <b-col cols="1">
          <div class="mt-4">#{{dictIndex+1}}</div>
        </b-col>
        <b-col cols="2">
          <VueRecordAudio @result="handleAudio($event, dictIndex)" mode="press" class="mt-2"/>
        </b-col>
        <b-col cols="4">
          <audio controls class="mx-auto mt-3 w-100" v-if="dictaudio.audio"
                 :src="dictaudio.audio" :ref="`player-${dictIndex}`">
          </audio>
          <h6 v-else class="mt-4">Aún no has grabado tu audio</h6>
        </b-col>
        <b-col cols="5">
          <b-form-row>
            <b-col cols="10">
              <b-form-group label="Respuestas correctas:" label-size="sm" label-cols="5" class="mt-2">
                <b-input size="sm" v-model="correctTexts[dictIndex]" @keyup.enter="addCorrectAnswer(dictIndex)"/>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-button variant="info" class="btn-circle mt-2" @click="addCorrectAnswer(dictIndex)">
                <v-icon name="plus"/>
              </b-button>
            </b-col>
          </b-form-row>
          <ul>
            <li v-for="(correct, cIndex) in dictaudio.correct_answers" :key="`dic-corr-${dictIndex}-${cIndex}`"
                class="ml-5">
              {{correct}}</li>
          </ul>
        </b-col>
      </b-row>
      <b-row align-h="end" class="p-2 m-1">
        <b-button pill variant="danger" size="sm" class="mx-1" @click="close">
          <v-icon name="times"/> Cancelar
        </b-button>
        <b-button pill variant="primary" size="sm" class="mx-1" @click="submitAudio">
          <v-icon name="save"/> Guardar
        </b-button>
      </b-row>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: "MultipleAudioRecorder",
  data() {
    return {
      audios: [],
      total: 1,
      question: null,
      correctTexts: []
    }
  },
  methods: {
    handleAudio(data, index) {
      let audio = this.audios[index]
      this.audios.splice(index, 1,{
        audioBlob: data,
        audio: window.URL.createObjectURL(data),
        correct_answers: audio.correct_answers})
    },
    open(question, total) {
      this.question = question
      this.question.dictation_audios.forEach(da=> this.audios.push({
        audio: da.audio,
        audioBlob: da.audioBlob,
        correct_answers: da.correct_answers? da.correct_answers : []
      }))
      if (this.audios.length < total)
        for (let i = this.audios.length; i < total; i++) {
          this.audios.push({audio: null, audioBlob: null, correct_answers: []})
        }
      this.audios.forEach(()=>this.correctTexts.push(''))
      this.total = total
      this.$refs['audio-modal'].show()
    },
    addCorrectAnswer(dictIndex) {
      this.audios[dictIndex].correct_answers.push(this.correctTexts[dictIndex])
      this.correctTexts[dictIndex] = ''
    },
    close() {
      this.question = null
      this.audios = []
      this.total = 1
      this.$refs['audio-modal'].hide()
    },
    submitAudio() {
      this.$set(this.question, "dictation_audios", this.audios)
      this.$set(this.question, "dictation_changed", true)
      this.close()
    }
  }
}
</script>

<style scoped>
.dictation-row {
  border: #F8B500 1px solid;
  border-radius: 10px;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  font-size: 8px;
  text-align: center;
}
</style>