import api from "@/services/api";

export default {
  tr(subject, level, oa, type, condition) {
    const params = new URLSearchParams({
      subject: subject,
      level: level,
      type: type,
      oa: JSON.stringify(oa),
      condition: condition
    }).toString();
    return fetch(`/api/get_data/?${params}`).then((response) => {
      return response.body;
    });
  },
  getDailyPlanification(subject, level, oa, type, ind, condition) {
    const params = new URLSearchParams({
      subject: subject,
      level: level,
      type: type,
      oa: JSON.stringify(oa),
      ind: JSON.stringify(ind),
      condition: condition
    }).toString();
    return fetch(`/api/get_daily_planification/?${params}`).then((response) => {
      return response.body;
    });
  },
  getDemoPlanification() {
    return fetch(`/api/get_demo_planification/`).then((response) => {
      return response.body;
    });
  },
  downloadPlanification(payload) {
    return api
      .post("download_planification/", payload, { responseType: "blob" })
      .then((response) => response);
  },
  getPrice(params) {
    return api.get('marketplace/product-price/', {params: params}).then(response => response.data);
  },
  // Obtener las condiciones guardadas del usuario
  getConditions() {
    return fetch('/api/get_special_conditions/').then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return [];
      }
    });
  },
  // Guardar la condicion dada por el usuario
  saveCondition(condition) {
    return api.post('create_special_condition/', { condition }).then(response => response.data);
  },
  // Generar PPT de planificacion diaria para descargar
  downloadPptPlanification(planification) {
    return api.post('download_ppt_planification/', { planification }, { responseType: "blob" }).then(response => response);
  }
};
