<template>
  <b-container fluid style="background-color: #fdfdfd">
    <div class="p-4">
    <b-row class="text-center mt-4"><span class="mx-auto h4 text-muted font-weight-bold">Actualmente trabajamos con</span></b-row>
    <b-row class="p-4" style="@media (min-width:900px) {max-height: 250px}">
      <b-col lg="3"><b-img class="img-fluid mx-auto d-block" :src="belenEduca"></b-img></b-col>
      <b-col lg="3"><b-img class="img-fluid mx-auto d-block" :src="ptealto"></b-img></b-col>
      <b-col lg="3"><b-img height="150" class="mx-auto d-block" :src="molokai"></b-img></b-col>
      <b-col lg="3"><b-img class="img-fluid mx-auto d-block" :src="sip"></b-img></b-col>
    </b-row>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "OurCustomers",
  props: {
    route: {
      type: String,
      required: false
    }
  },
  data(){
    return {
      belenEduca: require('../../../assets/img/sales/belenEduca.png'),
      ptealto: require('../../../assets/img/sales/ptealto.png'),
      molokai: require('../../../assets/img/sales/molokai.jpg'),
      sip: require('../../../assets/img/sales/sip.jpg')
    }
  }
}
</script>

<style scoped>
b-col {
  display: flex;
  align-items: center
}
</style>