import api from '@/services/api'

export default {
  getUncompletedMissions() {
    return api.get(`user-mission/get_uncompleted_missions/`).then(response => response.data);
  },
  getCompletedMissions() {
    return api.get(`user-mission/get_completed_missions/`).then(response => response.data);
  },
  buttonClicked(code) {
    return api.get(`user-mission/check_buttons_missions/?code=${code}`).then(response => response.data);
  }
}