import { render, staticRenderFns } from "./OurCustomers.vue?vue&type=template&id=cd0fc4ee&scoped=true"
import script from "./OurCustomers.vue?vue&type=script&lang=js"
export * from "./OurCustomers.vue?vue&type=script&lang=js"
import style0 from "./OurCustomers.vue?vue&type=style&index=0&id=cd0fc4ee&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd0fc4ee",
  null
  
)

export default component.exports