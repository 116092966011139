<template lang="">
  <div style="height: auto">
    <p>
      No es necesario que completes las respuestas correctas, se autocompletarán
      si las dejas en blanco
    </p>
    <b-form-group
      class="mb50m"
      :label="test[0].label"
      :label-for="test[0].var"
      label-cols-sm="2"
      label-align-sm="right"
      label-size="sm"
    >
      <b-form-select
        size="sm"
        v-model="answer.answers[test[0].var]"
        :options="test[0].options"
        :state="checkState(test[0].var)"
      ></b-form-select>
    </b-form-group>
    <div class="row">
      <div
        class="col-sm-12 col-md-6 col-lg-3 col-xl-2"
        v-for="(item, index) in test[1]"
        :key="index"
        style="margin-right: 20px"
      >
        <table>
          <tbody>
            <tr>
              <th class="rotate">
                <div><span>Item</span></div>
              </th>
              <th class="rotate">
                <div><span>Resp. niño</span></div>
              </th>
              <th class="rotate">
                <div><span>Correcta</span></div>
              </th>
            </tr>
            <tr v-for="(question, q_index) in item" :key="q_index">
              <td>{{ index * 20 + q_index + 1 }}</td>
              <td>
                <b-form-radio-group
                  :class="
                    checkState(question[0]) == false ? 'radio-invalid' : ''
                  "
                  v-model="answer.answers[question[0]]"
                  :options="['1', '2', '3']"
                  name="radios-btn-default"
                  buttons
                  :state="false"
                ></b-form-radio-group>
              </td>
              <td>
                <label>{{ question[1] }}</label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <span class="" v-if="is_valid == false"
      >Debes completar la edad y cada uno de los items.</span
    ><br />
    <b-button @click="send_test">Corregir Test</b-button>
  </div>
</template>
<script>
import testService from "../../../../services/testService";
import redpieService from "../../../../services/redpieService";
export default {
  props: ["test_id", "student_id", "previous_answer", "semester"],
  watch: {
    student_id() {
      this.answer.student_id = this.student_id;
    },
    test_id() {
      this.answer.test = this.test_id;
    },
  },
  data() {
    return {
      answer: { test: this.test_id, answers: {}, student_id: this.student_id },
      test: [
        {
          label: "Edad:",
          type: "select",
          var: "edad",
          options: [
            { value: "3", text: "3.0 - 3.11 años" },

            { value: "4", text: "4.0 - 4.11 años" },

            { value: "5", text: "5.0 - 5.11 años" },

            { value: "6", text: "6.0 - 6.11 años" },
          ],
        },
        [
          [
            ["p1", "2:"],
            ["p2", "2:"],
            ["p3", "1:"],
            ["p4", "3:"],
            ["p5", "1:"],
            ["p6", "3:"],
            ["p7", "1:"],
            ["p8", "1:"],
            ["p9", "3:"],
            ["p10", "2:"],
            ["p11", "1:"],
            ["p12", "1:"],
            ["p13", "3:"],
            ["p14", "2:"],
            ["p15", "1:"],
            ["p16", "3:"],
            ["p17", "1:"],
            ["p18", "2:"],
            ["p19", "3:"],
            ["p20", "1:"],
          ],
          [
            ["p21", "1:"],
            ["p22", "3:"],
            ["p23", "3:"],
            ["p24", "2:"],
            ["p25", "3:"],
            ["p26", "3:"],
            ["p27", "1:"],
            ["p28", "2:"],
            ["p29", "1:"],
            ["p30", "1:"],
            ["p31", "3:"],
            ["p32", "3:"],
            ["p33", "1:"],
            ["p34", "2:"],
            ["p35", "3:"],
            ["p36", "2:"],
            ["p37", "1:"],
            ["p38", "1:"],
            ["p39", "2:"],
            ["p40", "3:"],
          ],
          [
            ["p41", "1:"],
            ["p42", "2:"],
            ["p43", "2:"],
            ["p44", "1:"],
            ["p45", "3:"],
            ["p46", "2:"],
            ["p47", "1:"],
            ["p48", "2:"],
            ["p49", "1:"],
            ["p50", "1:"],
            ["p51", "3:"],
            ["p52", "2:"],
            ["p53", "3:"],
            ["p54", "2:"],
            ["p55", "1:"],
            ["p56", "2:"],
            ["p57", "1:"],
            ["p58", "1:"],
            ["p59", "2:"],
            ["p60", "1:"],
          ],
          [
            ["p61", "3:"],
            ["p62", "3:"],
            ["p63", "1:"],
            ["p64", "1:"],
            ["p65", "2:"],
            ["p66", "1:"],
            ["p67", "3:"],
            ["p68", "1:"],
            ["p69", "2:"],
            ["p70", "1:"],
            ["p71", "3:"],
            ["p72", "2:"],
            ["p73", "2:"],
            ["p74", "3:"],
            ["p75", "3:"],
            ["p76", "1:"],
            ["p77", "3:"],
            ["p78", "1:"],
            ["p79", "2:"],
            ["p80", "1:"],
          ],
          [
            ["p81", "1:"],
            ["p82", "3:"],
            ["p83", "2:"],
            ["p84", "3:"],
            ["p85", "2:"],
            ["p86", "2:"],
            ["p87", "1:"],
            ["p88", "3:"],
            ["p89", "1:"],
            ["p90", "1:"],
            ["p91", "2:"],
            ["p92", "1:"],
            ["p93", "1:"],
            ["p94", "3:"],
            ["p95", "2:"],
            ["p96", "1:"],
            ["p97", "3:"],
            ["p98", "3:"],
            ["p99", "2:"],
            ["p100", "2:"],
            ["p101", "1:"],
          ],
        ],
      ],
      required_fields: ["edad"],
      is_valid: null,
    };
  },
  mounted() {
    if (this.previous_answer && Object.keys(this.previous_answer).length) {
      for (const [key, value] of Object.entries(this.previous_answer)) {
        this.$set(this.answer.answers, key, value);
      }
    }
  },
  methods: {
    getDefaultAnswers() {
      const defaultAnswers = {};
      this.test[1].forEach((subgroup) => {
        subgroup.forEach((question) => {
          const id = question[0];
          const defaultValue = question[1].slice(0, -1);
          defaultAnswers[id] = defaultValue;
        });
      });
      return defaultAnswers;
    },

    initializeAnswers() {
      const defaultAnswers = {};
      this.test[1].forEach((subgroup) => {
        subgroup.forEach((question) => {
          const id = question[0];
          const defaultValue = question[1].slice(0, -1);
          defaultAnswers[id] = defaultValue;
        });
      });
      this.answer.answers = defaultAnswers; // Asigna las respuestas inicializadas directamente
    },

    checkRequired: function () {
      const answerKeys = Object.keys(this.answer.answers);
      this.is_valid = this.required_fields.every((element) => {
        return answerKeys.includes(element);
      });
    },
    checkState: function (item_name) {
      if (this.is_valid == null) {
        return null;
      }
      return this.answer.answers[item_name] ? null : false;
    },

    send_test() {
      const defaultAnswers = this.getDefaultAnswers(); // Obtén las respuestas predeterminadas

      // Revisa cada respuesta y si está vacía, asigna el valor predeterminado
      Object.keys(defaultAnswers).forEach((key) => {
        if (!this.answer.answers[key]) {
          // Asumiendo que un campo vacío podría ser undefined, null, o una cadena vacía
          this.$set(this.answer.answers, key, defaultAnswers[key]);
        }
      });

      this.checkRequired(); // Asumiendo que este método valida si todos los campos requeridos están llenos
      if (this.is_valid) {
        // semester
        this.answer["semester"] = this.semester;
        testService.send_test(this.answer).then((data) => {
          // Manejo del éxito del envío
          redpieService
            .assignDocuments({
              test: this.answer.test,
              school: this.$store.state.login.school,
              year: this.$store.state.login.year,
              student_grade: this.answer.student_id,
            })
            .then(() => {
              this.$emit("update-document");
              this.$emit("add-test", data);
            })
            .catch(() => {
              this.$toasted.error("Ha ocurrido al crear el documento");
            });
        });
      }
    },
  },
};
</script>
<style>
th.rotate {
  height: 140px;
  white-space: nowrap;
}

th.rotate > div {
  transform: translate(0px, 51px) rotate(335deg);
  width: 35px;
}
th.rotate > div > span {
  border-bottom: 1px solid #ccc;
}
.btn.active {
  background-color: #006469 !important;
}
.radio-invalid {
  border: #dc3545 2px solid;
  border-radius: 5px;
}
.mb50m {
  margin-bottom: -50px !important;
}
</style>
