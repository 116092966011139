import { Model } from '@vuex-orm/core'

export class Activity extends Model {
    static entity = 'activity'

    static fields() {
        return {
            id: this.attr(null),
            action_time: this.attr(null),
            action_flag: this.attr(0),
        }
    }

    static apiConfig = {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            "Content-Type": "application/json",
        },
        baseURL: `${process.env["VUE_APP_BASE_URL"]}/api`,
        actions: {
            fetch: {
                method: 'GET',
                url: '/activity/',
            },
            fetchByPeriodAndSchool(school, page){
                return this.get(`/activity/period/?school=${school}&?page=${page}`)
            },
            filter(school, page, date, professional, action){
                let params = {
                    school: school,
                    page: page,
                    ...(date && {date: date}),
                    ...(professional && {professional: professional}),
                    ...(action && {action: action})
                }
                return this.get('/activity/filter/', {params: params}).then(r => r.response.data)
            },
            fetchOptions(school){
                return this.get(`/activity/options/?school=${school}`).then(response => response.response)
            }
        }
    }
}