<template>
  <div :class="question.is_active ? '' : 'recycle'">
    <b-card
      :id="question.id"
      header-bg-variant="info"
      header-text-variant="white"
      no-body
    >
      <template v-slot:header>
        <b-form-row no-gutters>
          <b-col
            @click.self="$emit('open-question')"
            style="cursor: pointer"
            class="m-0 p-0"
          >
            {{
              question.question.length > 18
                ? question.question.substring(0, 15) + "..."
                : question.question
            }}
          </b-col>
          <b-col class="m-0 p-0" cols="1" v-if="question.is_from_text">
            <v-icon name="star" />
          </b-col>
          <b-col
            v-if="mode === 'material-list' && question.is_active"
            cols="2"
            class="text-center"
          >
            <b-button
              variant="unknown"
              size="sm"
              class="mx-auto"
              v-b-tooltip.hover
              title="Eliminar ejercicio"
              @click="$emit('delete-question')"
            >
              <v-icon name="trash-alt" class="text-danger" />
            </b-button>
          </b-col>
        </b-form-row>
      </template>

      <b-card-body class="p-0">
        <b-form-row @click="$emit('open-question')" style="cursor: pointer">
          <b-col cols="2" offset="1">
            <b-badge v-if="question.has_texts" variant="info" class="ml-1 mt-1">
              <v-icon name="bookmark" scale="1" />
            </b-badge>
          </b-col>
          <b-col class="text-center px-0 py-2" cols="4">
            <b-img :src="icon" height="100" />
          </b-col>
          <b-col class="text-left px-0 pt-1" cols="5">
            <b-row align-v="start">
              <b-badge variant="info">{{ eKinds[question.kind] }}</b-badge>
            </b-row>
            <b-row v-if="oa" align-v="end" class="h-75">
              <b-col class="m-0 p-0" align-h="end">
                <b-badge variant="primary">
                  {{ oa }}
                </b-badge>
              </b-col>
            </b-row>
          </b-col>
        </b-form-row>
      </b-card-body>
    </b-card>
    <!-- <b-row>
      <b-col cols="2">
        <b-badge variant="info">{{ eKinds[question.kind] }}</b-badge>
      </b-col>
      <b-col cols="8">
        {{ question.question }}
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
export default {
  name: "QuestionCard",
  components: {},
  props: {
    question: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      eKinds: {
        0: "Alternativas",
        1: "Desarrollo",
        2: "Dictado",
        3: "Ordenamiento"
      },
      icon: require("../../../assets/img/material_icons/Icon2.svg"),
    };
  },
  methods: {},
  computed: {
    oa() {
      let subtags = this.question.subtags;
      let tag = this.question.tag;
      return subtags.length ? subtags[0].parent_name : tag ? tag.name : "";
    },
  },
};
</script>

<style>
.recycle {
  opacity: 0.5;
}
</style>
