<template>
  <b-modal
    centered
    size="xl"
    ref="incomponent-modal-document-types"
    hide-footer
    @hide="saveOrder"
  >
    <create-update-template-online ref="template-online-modal" 
      @reload="reload"
      />
    <ModalAddDocumentType ref="modal-add-document-type" @reload="reload()"/>
    <template v-slot:modal-header="{ close }">
      <span class="h3 mx-auto">Gestión de tipos de documentos</span>
      <a @click="close()" class="mr-0 mt-0 text-dark" style="cursor:pointer;">x</a>
    </template>
    <b-container>
      <b-row v-if="loading" class="text-center">
        <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow" />
      </b-row>
      <b-row no-gutters v-else>
        <b-col cols="10">
          <b-row>
            <b-list-group style="width: 100% !important;">
              <DocumentTypeInformation
                @reload="$emit('reload')"
                v-for="(docType, index) in documentTypes"
                v-bind:key="index+'-doctype'"
                v-on:deleteDocumentType="deleteDocumentType(index, docType.id)"
                :docType="docType"
                :grades="grades"
                :dtIndex="index"
                :last="index===documentTypes.length-1"
                @move-up="moveUp(index)"
                @move-down="moveDown(index)"
                :diagnoses="diagnoses"
                :gradesList="gradesList"
                :diagnosesList="diagnosesList"
                @edit-online-template="openEditOnlineTemplate"
              />
            </b-list-group>
          </b-row>
        </b-col>
        <b-col cols="2">
          <b-button
            :disabled="$store.state.login.schoolLevel < 1"
            variant="primary"
            class="rounded-pill p-1"
            @click="openAddDocumentType"
          >
            <v-icon name="plus"/>
            Agregar Formato
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import DocumentTypeInformation from "../miscellaneous/DocumentTypeInformation";
import ModalAddDocumentType from "./ModalAddDocumentType";
import CreateUpdateTemplateOnline from "./CreateUpdateTemplateOnline.vue";
import informationService from "../../../services/informationService";
import redpieService from "../../../services/redpieService";

export default {
  components: {
    DocumentTypeInformation,
    ModalAddDocumentType,
    CreateUpdateTemplateOnline
  },
  data () {
    return {
      documentTypes: [],
      diagnosesList: [],
      gradeList: [],
      diagnoses: [],
      grades: [],
      loading: false
    }
  },
  created() {
    this.getPieGrades();
    this.getDiagnosesList();
  } ,
  methods: {
    reload() {
      this.loading = true;
      redpieService.getDiagnosesGradesDocumentType(this.$store.state.login.school, this.$store.state.login.year).then(
        data => {
          this.documentTypes = data.document_types;
          this.loading = false;
        }
      )
      this.$emit('reload');
    },
    moveUp(docTypeIndex) {
      this.documentTypes.splice(
          docTypeIndex-1, 2, this.documentTypes[docTypeIndex], this.documentTypes[docTypeIndex-1])
    },
    moveDown(docTypeIndex) {
      this.documentTypes.splice(
          docTypeIndex, 2, this.documentTypes[docTypeIndex+1], this.documentTypes[docTypeIndex])
    },
    saveOrder() {
      var data = {document_types: []}
      for (var i=0;i<this.documentTypes.length; i++) {
        data.document_types.push({id: this.documentTypes[i].id, order: i})
      }
      redpieService.updateTypesOrder(data).then(
        data => {
          this.$toasted.success(data.message)
        }
      )
    },
    open(schoolID) {
      this.loading = true;
      this.documentTypes = [];
      redpieService.getDiagnosesGradesDocumentType(schoolID, this.$store.state.login.year).then(
        data => {
          this.documentTypes = data.document_types;
          this.loading = false;
          this.$refs["incomponent-modal-document-types"].show();
        }
      )
    },
    openEditOnlineTemplate(dt_id, ot_id) {
      this.$refs["template-online-modal"].open(dt_id, ot_id);
    },
    getDiagnosesList: function() {
      informationService.getDiagnosesList().then(
        data => {
          this.diagnosesList = data.grouped_diagnoses;
          this.diagnoses = data.diagnoses;
        }
      )
    },
    getPieGrades: function() {
      informationService.getPieGrades().then(
        data => {
          this.gradesList = data.grades_by_level;
          this.grades = data.grades;
        }
      )
    },
    openAddDocumentType(){
      this.$refs["modal-add-document-type"].open();
    },
    deleteDocumentType: function(index, id) {
      redpieService.deleteDocumentType({document_type: id}).then(
        data => {
          this.$toasted.success(data.message);
          this.documentTypes.splice(index, 1)
        }
      )
    }
  }
}
</script>
<style lang="scss">
.row {
  padding-bottom: 5px;

  .custom-col {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 0px !important;
  }
}
</style>
