<template>
  <p v-html="renderedText"></p>
</template>

<script>
import katex from 'katex';
import 'katex/dist/katex.min.css';

export default {
  name: "LatexRenderer",
  props: {
    textWithLatex: {
      type: String,
      required: true,
    },
  },
  computed: {
    renderedText() {
      const parenthesisRegex = /\\\(|\\\)/g;
      let processedText = this.textWithLatex.replace(parenthesisRegex, '');
      const latexRegex = /\\[^ ]+/g;
      processedText = processedText.replace(latexRegex, (match) => {
        try {
          return katex.renderToString(match, {
            throwOnError: false,
            strict: 'ignore',
          });
        } catch (e) {
          console.error('Error rendering LaTeX:', e);
          return match; // Return the original match (unprocessed)
        }
      });
      // let processedText = katex.renderToString(this.textWithLatex, {
      //   throwOnError: false,
      //   strict: 'ignore',
      // });
      return processedText;
    },
  },
};
</script>

<style>
.katex {
  display: inline-block;
  font-size: 1.2em;
}
</style>
