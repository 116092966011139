<template>
  <b-modal size="xl" ref="personal-modal">
    <b-container class="personal-data-form">
      <personal-data
        ref="personal-data"
        :existingUserID="userID"
        @data-saved="$refs['personal-modal'].hide()"
      />
    </b-container>
    <template #modal-footer>
      <b-row align-h="end" class="px-3 w-100">
        <b-button
          size="lg"
          type="submit"
          @click="submitForm"
          pill
          variant="primary"
        >
          Guardar <v-icon name="save" scale="1" />
        </b-button>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import PersonalData from "../pages/PersonalData";

export default {
  components: {
    PersonalData,
  },
  data() {
    return {
      userID: null,
    };
  },
  methods: {
    open(id) {
      this.userID = id;
      this.$refs["personal-modal"].show();
    },
    submitForm() {
      this.$refs["personal-data"].updateUser();
    },
  },
};
</script>
<style lang="scss">
.personal-data-form {
  max-height: 60vh;
  overflow-y: auto;
}
</style>
