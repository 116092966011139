<template>
    <svg version="1.1" id="Layer_1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    :viewBox="viewbox"
    :width="width"
    :height="height"
    xml:space="preserve"
    class="custom-icon-button">
  

   <g v-if="on">
        <g>
            <circle class="fx-off" cx="43" cy="43" r="43"/>
        </g>
        <path class="fx-on" d="M43.5,22.4c-3.8,1.1-6.7,4.4-7.1,8.3c-0.2,1.9-0.3,3.8-0.5,5.7c-0.1,1-0.1,1.8-0.1,1.8s-1,0-1.8,0
            c-1.1,0-2.3,0-3.4,0c-1.3,0-2.4,1.1-2.4,2.4v0c0,1.3,1.1,2.4,2.4,2.4h4.7c-0.1,1.3-0.2,2.5-0.3,3.6c-0.3,2.9-0.5,5.9-0.9,8.8
            c-0.3,1.9-1.6,3-3.4,3.5c-1.8,0.5-2.9,0.4-4.2-1.1c0,0-0.5-0.3-0.5-0.3c-0.8-0.8-2.1-0.9-2.8,0l0,0c-1,1.2-1,3,0.2,4
            c4.3,3.8,10.8,2.8,13.9-1.7c1.5-2.1,1.7-4.5,1.9-6.9c0.3-3.3,0.6-6.6,0.9-10h4.7c1.3,0,2.4-1.1,2.4-2.4v0c0-1.3-1.1-2.4-2.4-2.4
            h-4.4c0.3-2.7,0.4-5.4,0.9-7.9c0.5-2.3,2.9-3.7,5.3-3.3c1,0.2,1.8,0.6,2.5,1.2c0.9,0.9,2.3,0.8,3.2,0l0,0c1-0.9,1-2.5,0-3.4
            C50,22.3,46.6,21.6,43.5,22.4z"/>
        <path class="fx-on" d="M59.6,52l3.1-3.1c1-1,1-2.5,0-3.4l0,0c-1-1-2.5-1-3.4,0l-3.1,3.1L53,45.4c-1-1-2.5-1-3.4,0l0,0
            c-1,1-1,2.5,0,3.4l3.1,3.1l-3.1,3.1c-1,1-1,2.5,0,3.4h0c1,1,2.5,1,3.4,0l3.1-3.1l3.1,3.1c1,1,2.5,1,3.4,0h0c1-1,1-2.5,0-3.4
            L59.6,52z"/>
    </g>

   <g v-else>
      <path class="fx-off" d="M43,2.7c22.2,0,40.2,18.1,40.2,40.2S65.2,83.2,43,83.2S2.7,65.2,2.7,43S20.8,2.7,43,2.7 M43,0
          C19.2,0,0,19.2,0,43s19.2,43,43,43s43-19.2,43-43S66.7,0,43,0L43,0z"/>
      <path class="fx-off" d="M43.5,22.4c-3.8,1.1-6.7,4.4-7.1,8.3c-0.2,1.9-0.3,3.8-0.5,5.7c-0.1,1-0.1,1.8-0.1,1.8s-1,0-1.8,0
          c-1.1,0-2.3,0-3.4,0c-1.3,0-2.4,1.1-2.4,2.4v0c0,1.3,1.1,2.4,2.4,2.4h4.7c-0.1,1.3-0.2,2.5-0.3,3.6c-0.3,2.9-0.5,5.9-0.9,8.8
          c-0.3,1.9-1.6,3-3.4,3.5c-1.8,0.5-2.9,0.4-4.2-1.1c0,0-0.5-0.3-0.5-0.3c-0.8-0.8-2.1-0.9-2.8,0l0,0c-1,1.2-1,3,0.2,4
          c4.3,3.8,10.8,2.8,13.9-1.7c1.5-2.1,1.7-4.5,1.9-6.9c0.3-3.3,0.6-6.6,0.9-10h4.7c1.3,0,2.4-1.1,2.4-2.4v0c0-1.3-1.1-2.4-2.4-2.4
          h-4.4c0.3-2.7,0.4-5.4,0.9-7.9c0.5-2.3,2.9-3.7,5.3-3.3c1,0.2,1.8,0.6,2.5,1.2c0.9,0.9,2.3,0.8,3.2,0l0,0c1-0.9,1-2.5,0-3.4
          C50,22.3,46.6,21.6,43.5,22.4z"/>
      <path class="fx-off" d="M59.6,52l3.1-3.1c1-1,1-2.5,0-3.4l0,0c-1-1-2.5-1-3.4,0l-3.1,3.1L53,45.4c-1-1-2.5-1-3.4,0l0,0
          c-1,1-1,2.5,0,3.4l3.1,3.1l-3.1,3.1c-1,1-1,2.5,0,3.4h0c1,1,2.5,1,3.4,0l3.1-3.1l3.1,3.1c1,1,2.5,1,3.4,0h0c1-1,1-2.5,0-3.4
          L59.6,52z"/>
  </g>

  

  </svg>
  </template>
  
  <script>
  export default {
      name: 'FormulaIcon',
      props: {
        viewbox: {
              type: String,
              default: "0 0 18 18"
        },
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },
          on:{
            type: Boolean,
            default: true
            } 
          },
   
  }
  </script>
  
<style>
.fx-off{
    fill:#2589BD;
}
.fx-on{
    fill:#FFFFFF;
}
.custom-icon-button{
    cursor:pointer;
}
</style>