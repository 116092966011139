var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"chat-box"},[_c('b-row',[_c('b-col',{staticClass:"mx-auto",attrs:{"cols":"10"}},_vm._l((_vm.messages),function(msg,msgidx){return _c('div',{key:msgidx,staticClass:"chat-record mt-2"},[_c('b-row',{staticClass:"box-question rounded text-left"},[_c('div',{staticClass:"box-list-question px-2"},[_c('p',{ref:`msgRef-${msg.name}`,refInFor:true,staticClass:"rounded",domProps:{"innerHTML":_vm._s(msg.text)}}),(msg.hint && msg.showHint)?_c('p',{ref:`msg-hint-${msg.name}`,refInFor:true,staticClass:"question-hint",domProps:{"innerHTML":_vm._s('Indicación: ' + msg.hint)}}):_vm._e()])]),_c('div',{staticClass:"box-answer p-2"},[(msg.answersLoaded && msg.showAnswers)?_c('b-row',{staticClass:"box-list-answer w-100"},_vm._l((msg.answers),function(answer,ansidx){return _c('b-col',{key:ansidx,staticClass:"msg p-1 col-4",class:answer.cols},[(msg.type === 'cards')?_c('div',[_c('b-card',{staticClass:"m-2 option-card text-center font-weight-bold w-100",class:{
                    'selected-card': answer.isSelected,
                  },attrs:{"header":answer.title},on:{"click":function($event){return _vm.handleCardClick(answer, msgidx, ansidx)}}},[_c('b-card-text',{staticClass:"font-weight-normal",class:answer.bootstrap_classes},[_vm._v(" "+_vm._s(answer.name)+" ")])],1)],1):_vm._e(),(msg.type == 'input-text')?_c('div',[_c('SpecialConditions',{attrs:{"msg":msg},on:{"update-condition":_vm.updateCondition}})],1):_vm._e(),(msg.type === 'text')?_c('div',[_c('input',{attrs:{"type":"text","name":"answer"}})]):_vm._e(),(msg.type == 'options')?_c('div',[(
                    answer.isSelected ||
                    !msg.single_choice ||
                    (msg.single_choice &&
                      msg.answers.filter((ans) => ans.isSelected).length < 1)
                  )?_c('b-card',{staticClass:"text-center option-option",class:{ 'selected-option': answer.isSelected },on:{"click":function($event){return _vm.handleCardClick(answer, msgidx, ansidx, msg.max)}}},[_c('div',{class:{
                      'd-flex': answer.price || answer.priority,
                      'align-items-center': !answer.price && !answer.priority,
                      'justify-content-between':
                        answer.price || answer.priority,
                    }},[_c('div',{class:answer.bootstrap_classes,domProps:{"innerHTML":_vm._s(answer.name)}}),(answer.priority == 1)?_c('div',{staticClass:"d-flex align-items-center m-1"},[_c('v-icon',{attrs:{"name":"custom-star","scale":"1.5"}})],1):_vm._e()])]):_vm._e()],1):_vm._e(),(msg.type === 'options-counter')?_c('div',[_c('b-row',{staticClass:"p-2 counter-section mb-1"},[_c('p',{domProps:{"innerHTML":_vm._s(answer.name_section)}})]),(
                    answer.has_warning &&
                    !(answer[answer.key] >= answer.required)
                  )?_c('b-row',{staticClass:"p-2 mb-1 w-100"},[_c('b-alert',{staticClass:"w-100",attrs:{"show":"","variant":"warning"}},[_vm._v(_vm._s(answer.warning_text))])],1):_vm._e(),_vm._l((answer.options),function(opt,optidx){return _c('b-row',{key:optidx,staticClass:"p-2 counter-option w-100 my-1 ml-1"},[_c('b-col',{staticClass:"counter-container col-sm-2",attrs:{"cols":"4"}},[_c('v-icon',{staticClass:"counter-button",attrs:{"name":"minus","scale":"0.8"},on:{"click":function($event){return _vm.handleDecrease(opt, answer)}}}),_c('b-input',{staticClass:"no-arrow input-no-border input-counter",attrs:{"type":"number","disabled":""},model:{value:(opt.count),callback:function ($$v) {_vm.$set(opt, "count", $$v)},expression:"opt.count"}}),_c('v-icon',{staticClass:"counter-button",attrs:{"name":"plus","scale":"0.8"},on:{"click":function($event){return _vm.handleIncrease(opt, answer)}}})],1),_c('b-col',[_vm._v(" "+_vm._s(opt.name)+" ")])],1)})],2):_vm._e()])}),1):_c('ul',{staticClass:"w-100"},[_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"type":"grow","label":"Loading..."}})],1)],1)],1),(msg.type === 'custom-component')?_c('div',[_c(msg.component,{tag:"component",attrs:{"context":msg.context,"condition":_vm.condition}})],1):_vm._e()],1),_c('div',[(
              !msg.single_choice &&
              msg.answersLoaded &&
              !msg.isFinal &&
              msg.showAnswers
            )?_c('b-row',{staticClass:"w-100 continue-button py-2 m-1",class:msg.answered ? 'continue-off' : 'continue-on',on:{"click":_vm.continueChat}},[_c('b-col',[_vm._v(" Listo! Siguiente ")]),_c('b-col',{staticClass:"col-sm-1",attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"name":msg.answered
                    ? 'continue-arrow-white'
                    : 'continue-arrow-color'}})],1)],1):_vm._e()],1),_c('div',[(msg.isFinal)?_c('b-row',{staticClass:"w-100 reset-button py-2 m-1",on:{"click":_vm.resetChat}},[_c('b-col',[_vm._v("Reiniciar chat")])],1):_vm._e()],1)],1)}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }