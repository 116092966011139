var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-container',[_c('div',[_c('b-tabs',{attrs:{"content-class":"mt-3","justified":""},model:{value:(_vm.tab_index),callback:function ($$v) {_vm.tab_index=$$v},expression:"tab_index"}},_vm._l((_vm.filtered_tests),function(test,index){return _c('b-tab',{key:index,attrs:{"title":test.text,"lazy":""},on:{"click":_vm.emptyPrevious}},[(!_vm.student.id)?_c('b-row',[_c('b-card',{staticClass:"w-100 text-center",attrs:{"bg-variant":"Light"}},[_c('b-card-text',[_vm._v("Necesitas seleccionar un curso y estudiante para trabajar un test.")])],1)],1):_vm._e(),(_vm.last_test && !Object.keys(_vm.previousAnswers).length)?_c('b-row',[_c('div',{staticClass:"mt-2"},[_vm._v("Último test "+_vm._s(_vm.last_test.created_at))]),_c('b-button',{staticClass:"m-1",attrs:{"pill":"","variant":"primary"},on:{"click":_vm.getPreviousAnswers}},[_vm._v(" Editar ")]),_c('b-button',{staticClass:"m-1",attrs:{"pill":"","variant":"danger"},on:{"click":_vm.deleteTest}},[_vm._v(" Eliminar test ")])],1):_vm._e(),(
              (!_vm.last_test || Object.keys(_vm.previousAnswers).length) &&
              _vm.student.id
            )?_c('b-row',[_c('div',{staticClass:"test-div"},[_c('b-card',{attrs:{"bg-variant":"Light"}},[_c(test.component,{tag:"component",attrs:{"semester":_vm.semester,"student_id":_vm.student.id,"test_id":_vm.actual_test,"previous_answer":_vm.previousAnswers},on:{"add-test":_vm.addTest,"update-document":_vm.updateDocument}})],1)],1)]):_vm._e(),(_vm.student.id)?_c('b-row',[_c('b-card',{staticClass:"w-100 text-center",attrs:{"bg-variant":"Light"}},_vm._l((_vm.documents_with_actual_test),function(doc,index){return _c('b-card-text',{key:index,attrs:{"set":(_vm.completed_tests[index] = _vm.completedTests(doc))}},[_vm._v(" "+_vm._s(doc.document_type.name)+": "+_vm._s(_vm.completed_tests[index])+" / "+_vm._s(doc.document_type.document_info.required_tests.length)+" "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
                    title: 'Debe tener al menos un test completado',
                    placement: 'top',
                    variant: 'danger',
                    disabled: _vm.completed_tests[index] > 0,
                  }),expression:"{\n                    title: 'Debe tener al menos un test completado',\n                    placement: 'top',\n                    variant: 'danger',\n                    disabled: completed_tests[index] > 0,\n                  }"}],staticClass:"d-inline-block",attrs:{"tabindex":"0"}},[_c('b-button',{staticClass:"m-1",attrs:{"pill":"","variant":"primary","disabled":_vm.completed_tests[index] < 1},on:{"click":function($event){return _vm.workOnlineDocument(
                        doc,
                        _vm.completed_tests[index],
                        doc.document_type.document_info.required_tests.length
                      )}}},[_vm._v(" "+_vm._s(doc.document_type.document_info.document ? "Ver" : "Crear")+" "+_vm._s(doc.document_type.name)+" ")])],1)])}),1)],1):_vm._e()],1)}),1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }