import api from '@/services/api'

export default {
  getInfoSGDT(student_grade_document_type_id) {
    return api.get(`multi-documents/get_info_by_sg_dt/?sg_dt_id=${student_grade_document_type_id}`)
      .then(response => response.data)
  },
  getOnlineTemplate(document_type, grade, diagnostic) {
    return api.get(`multi-documents/get_online_template/?document_type=${document_type}&grade=${grade}&diagnostic=${diagnostic}`)
      .then(response => response.data)
  },
  saveAnswers(payload) {
    return api.post('multi-documents/save_answers/', payload).then(response => response.data);
  },
  getOnlineTemplateByID(dt_on_id) {
    return api.get(`multi-documents/get_template_online/?document_template_online=${dt_on_id}`)
      .then(response => response.data)
  },
  updateOrCreateOnlineTemplateByID(payload) {
    return api.post(`multi-documents/update_template_online/`, payload)
      .then(response => response.data)
  },
  downloadMultiDocuments(payload) {
      return api.post('multi-documents/download-multi-documents/', payload, {responseType: 'blob'}).then(response => response);
  },
}