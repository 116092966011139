<template>
  <div id="header" class="header-general-container">
    <ChangePasswordModal ref="change-password-modal" />
    <ViewVideoModal ref="view-video-modal" />
    <quick-eureka-modal ref="quick-eureka-modal" />
    <div class="header-container bg-dark">
      <b-container class="h-container" fluid>
        <b-row>
          <b-col
            cols="5"
            sm="5"
            md="2"
            lg="2"
            xl="2"
            class="logo m-0 p-0 text-right"
          >
            <div class="image ml-2" v-if="showLogo" @click="redirectHome"></div>
          </b-col>
          <b-col
            cols="0"
            sm="0"
            md="3"
            lg="3"
            xl="3"
            class="search d-none d-xl-block d-lg-block d-md-block"
          >
            <b-input-group class="search-input-group">
              <b-input
                v-model="rut"
                v-on:keyup.enter="searchStudent"
                placeholder="Ingresar rut estudiante"
                type="text"
                class="search-input-go"
                :formatter="formatter"
                lazy-formatter
              />
              <b-input-group-append class="search-input-group">
                <b-button class="button search-input-go" @click="searchStudent">
                  <v-icon
                    scale="1"
                    class="text-light-hover-darken text-light"
                    name="search"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <!-- <b-col cols="6" sm="6" md="4" lg="4" xl="4" class="search d-none d-xl-block d-lg-block d-md-block">
            <b-input-group class="search-input-group" v-if="showSearch">
              <b-form-input
                v-model="search"
                v-on:keyup.enter="search_by_text"
                :disabled="$router.history.base !== '/utopie' || $route.name==='media'"
                placeholder="Buscar..."
                class="search-input-go"
              />
              <b-input-group-append class="search-input-group">
                <b-button
                  class="button search-input-go"
                  :disabled="$router.history.base !== '/utopie' || $route.name==='media'"
                  @click="search_by_text">
                  <v-icon scale="1" class="text-light-hover-darken text-light" name="search" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col> -->
          <b-col
            cols="7"
            sm="7"
            md="7"
            lg="7"
            xl="7"
            class="right-options m-0 p-0"
          >
            <b-container class="m-0 p-0">
              <b-row class="m-0 p-0" align-h="end">
                <b-col
                  v-if="$store.state.login.token && showUser"
                  class="notifications text-right px-0"
                >
                  <b-badge
                    class="text-dark mr-1"
                    variant="primary"
                    :class="
                      $router.history.base !== '/utopie' ? 'bg-redpie' : ''
                    "
                    >{{ $store.state.login.new_notifications }}</b-badge
                  >
                  <v-icon name="bell" class="icon" />
                  <b-dropdown
                    size="lg"
                    variant="link"
                    right
                    toggle-class="text-decoration-none"
                    v-on:show="set_not_new_notifications"
                  >
                    <div class="custom-dropdown-notifications">
                      <div class="custom-header">
                        <h2>NOTIFICACIONES</h2>
                        <a href="/utopie#/profile/notifications/">VER TODAS</a>
                      </div>
                      <div class="custom-list">
                        <ul>
                          <li
                            v-for="(notification, index) in $store.state.login
                              .notifications"
                            :key="'b-dropdown-item' + index"
                          >
                            <span>
                              <strong
                                v-on:click.stop
                                v-html="notification.message"
                              ></strong>
                              <small class="capfirst">{{
                                $moment(notification.created_at).fromNow()
                              }}</small>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div class="custom-header">
                        <b-button
                          class="button btn btn-primary"
                          @click="load_notifications"
                          >Ver más</b-button
                        >
                      </div>
                    </div>
                  </b-dropdown>
                  <!-- </b-col> -->
                  <!-- <b-column v-if="$store.state.login.token && showUser">
                  <b-container class="m-0 p-0 w-100">
                    <b-row class="m-0 p-0">
                      <strong class="text-success"
                        >Nivel {{ $store.state.login.user_level }}</strong
                      >
                    </b-row>
                    <b-row class="m-0 p-0">
                      <b-progress
                        :max="$store.state.login.nextLevelExp"
                        style="color: black"
                        class="w-100 m-0 p-0"
                        id="progress-bar"
                        show-progress
                        animated
                        variant="success"
                      >
                        <b-progress-bar :value="$store.state.login.currentExp">
                        </b-progress-bar>
                      </b-progress>
                      <b-popover
                        target="progress-bar"
                        placement="bottom"
                        triggers="hover focus"
                        :content="`${$store.state.login.currentExp}/${$store.state.login.nextLevelExp}`"
                      ></b-popover>
                    </b-row>
                  </b-container>
                </b-column> -->
                  <!-- <b-column
                  v-if="$store.state.login.token && showUser"
                  class="notifications"
                >
                  <b-badge
                    class="text-dark mr-1"
                    variant="success"
                    :class="
                      $router.history.base !== '/utopie' ? 'bg-redpie' : ''
                    "
                  >
                    {{
                      $store.state.login.missions.filter((m) => !m.completed)
                        .length
                    }}
                  </b-badge>
                  <v-icon name="list" class="icon text-success" />
                  <b-dropdown
                    size="lg"
                    variant="link"
                    right
                    toggle-class="text-decoration-none"
                  >
                    <div class="custom-dropdown-notifications">
                      <div class="custom-header">
                        <h2>MISIONES</h2>
                        <a href="/utopie#/profile/missions/">VER TODAS</a>
                      </div>
                      <div style="width: 100%">
                        <span style="padding-left: 15px; font-size: 14px">
                          Haz click en la misión para ver un video explicativo.
                        </span>
                      </div>
                      <div class="custom-list-missions">
                        <ul>
                          <li
                            v-for="(mission, index) in $store.state.login
                              .missions"
                            :key="'b-dropdown-item' + index"
                            @click="openVideoModal(mission.mission.video)"
                          >
                            <b-container class="m-0 p-0">
                              <b-row class="m-0 p-0">
                                <b-col>
                                  <h5 class="text-primary">
                                    {{ mission.mission.title }}
                                  </h5>
                                </b-col>
                                <b-col cols="2" v-if="mission.completed">
                                  <v-icon name="check-circle" />
                                </b-col>
                              </b-row>
                              <b-row class="m-0 p-0">
                                {{ mission.mission.description }}
                              </b-row>
                            </b-container>
                          </li>
                        </ul>
                      </div>
                      <div class="custom-header">
                        <b-button class="button btn btn-primary" @click="load_notifications">Ver más</b-button>
                      </div>
                    </div>
                  </b-dropdown>
                </b-column> -->

                  <!-- <b-column
                  v-if="$store.state.login.points != 0"
                  class="d-inline balance my-1 p-2"
                  style="display: inline-block; padding-right: 5px !important"
                >
                  Saldo: {{ $store.state.login.points.toLocaleString() }}
                  <b-img :src="appleIcon" width="20px" class="pl-1 mx-0" />
                </b-column>
                <b-column
                  v-if="$router.history.base === '/botpie'"
                  class="cost my-1 ml-1 p-2"
                  :class="{ 'change-color': showColor }"
                >
                  Gasto : {{ cost }}
                  <b-img :src="appleIcon" width="20px" class="pl-1 mx-0" />
                </b-column> -->
                  <!-- <b-col v-if="$store.state.login.token" class="username text-left px-0"> -->
                  <div class="username">
                    <b-dropdown
                      size="lg"
                      variant="link"
                      class="d-inline"
                      right
                      toggle-class="text-decoration-none"
                    >
                      <template v-slot:button-content>
                        <div class="user-avatar">
                          <img
                            :class="
                              $router.history.base !== '/utopie' ? 'redpie' : ''
                            "
                            :src="$store.state.login.thumbnail"
                            referrerpolicy="no-referrer"
                          />
                        </div>
                        <span
                          class="text-light-hover-darken text-light d-none d-xl-inline"
                          style="font-size: 17px !important"
                          >{{ $store.state.login.first_name }}
                          {{ $store.state.login.last_name }}</span
                        >
                      </template>
                      <b-dropdown-item href="#" @click="redirectProfile"
                        >Perfil</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="$store.state.isAdmin"
                        href="#"
                        @click="redirectMgmt"
                        >Gestión de Colegio</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="$store.state.login.isAdmin"
                        href="#"
                        @click="goFudeiTable"
                        >Fudeis Colegios</b-dropdown-item
                      >
                      <b-dropdown-item href="#" @click="openChangePasswordModal"
                        >Cambiar contraseña</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="$store.state.login.isAdmin"
                        href="#"
                        @click="redirectAdminUsers"
                        >Admin. usuarios</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="$store.state.login.isAdmin"
                        href="#"
                        @click="redirectTemplates"
                        >Crear Templates</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="$store.state.login.isAdmin"
                        href="#"
                        @click="redirectTests"
                        >Crear Tests</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="$store.state.login.isAdmin"
                        href="#"
                        @click="redirectUpSchool"
                        >Subir/actualizar colegio</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="$store.state.login.isAdmin"
                        href="#"
                        @click="redirectCoordinators"
                        >Crear varios colegios</b-dropdown-item
                      >
                      <b-dropdown-item href="#" @click="redirectChatbot"
                        >Ir al Chatbot</b-dropdown-item
                      >
                      <b-dropdown-item href="#" @click="logout()"
                        >Cerrar sesión</b-dropdown-item
                      >
                    </b-dropdown>
                  </div>
                </b-col>
                <!-- <b-column v-if="$store.state.login.token" class="username">
                  <b-dropdown
                    size="lg"
                    variant="link"
                    class="d-inline"
                    right
                    toggle-class="text-decoration-none"
                  >
                    <template v-slot:button-content>
                      <div class="user-avatar">
                        <img
                          :class="
                            $router.history.base !== '/utopie' ? 'redpie' : ''
                          "
                          :src="$store.state.login.thumbnail"
                          referrerpolicy="no-referrer"
                        />
                      </div>
                      <span
                        class="text-light-hover-darken text-light d-none d-xl-inline"
                        style="font-size: 17px !important"
                        >{{ $store.state.login.first_name }}
                        {{ $store.state.login.last_name }}</span
                      > -->
                <!-- </template> -->

                <!-- <b-dropdown-item href="#" @click="redirectProfile"
                      >Perfil</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-if="$store.state.login.isAdmin"
                      href="#"
                      @click="redirectMgmt"
                      >Gestión de Colegio</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-if="$store.state.login.isAdmin"
                      href="#"
                      @click="goFudeiTable"
                      >Fudeis Colegios</b-dropdown-item
                    >
                    <b-dropdown-item href="#" @click="openChangePasswordModal"
                      >Cambiar contraseña</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-if="$store.state.login.isAdmin"
                      href="#"
                      @click="redirectAdminUsers"
                      >Admin. usuarios</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-if="$store.state.login.isAdmin"
                      href="#"
                      @click="redirectTemplates"
                      >Crear Templates</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-if="$store.state.login.isAdmin"
                      href="#"
                      @click="redirectTests"
                      >Crear Tests</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-if="$store.state.login.isAdmin"
                      href="#"
                      @click="redirectUpSchool"
                      >Subir/actualizar colegio</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-if="$store.state.login.isAdmin"
                      href="#"
                      @click="redirectCoordinators"
                      >Crear varios colegios</b-dropdown-item
                    >
                    <b-dropdown-item href="#" @click="redirectChatbot"
                      >Ir al Chatbot</b-dropdown-item
                    >
                    <b-dropdown-item href="#" @click="logout()"
                      >Cerrar sesión</b-dropdown-item
                    >
                  </b-dropdown>
                </b-column> -->

                <b-col v-else class="logout text-right">
                  <b-button
                    class="btn-outline-primary bg-dark mr-1"
                    size="sm"
                    @click="openModal()"
                    >Iniciar sesión</b-button
                  >
                  <!-- <span class="d-none d-md-inline-block"><strong> O </strong></span>
                  <b-button class="btn-outline-primary bg-dark d-inline ml-1" size="sm" @click="openModal(true)">Regístrate</b-button> -->
                </b-col>
              </b-row>
            </b-container>
            <!-- <div class="notifications d-inline" v-if="$store.state.login.token && showUser">
              <b-badge
                class="text-dark mr-1"
                variant="primary"
                :class="$router.history.base !== '/utopie'? 'bg-redpie':''"
              >{{$store.state.login.new_notifications}}</b-badge>
              <v-icon name="bell" class="icon" />
              <b-dropdown size="lg"
                variant="link" right toggle-class="text-decoration-none"
                v-on:show="set_not_new_notifications"
              >
                <div class="custom-dropdown-notifications">
                  <div class="custom-header">
                    <h2>NOTIFICACIONES</h2>
                    <a href="/utopie#/profile/notifications/">VER TODAS</a>
                  </div>
                  <div class="custom-list">
                    <ul>
                      <li
                        v-for="(notification, index) in $store.state.login.notifications"
                        :key="'b-dropdown-item'+index"
                      >
                        <span>
                          <strong v-on:click.stop v-html="notification.message"></strong>
                          <small class="capfirst">{{$moment(notification.created_at).fromNow()}}</small>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div class="custom-header">
                    <b-button class="button btn btn-primary" @click="load_notifications">Ver más</b-button>
                  </div>
                </div>
              </b-dropdown>
            </div>
            <div class="notifications d-inline" style="min-width: 120px" v-if="$store.state.login.token && showUser">
              <div class="d-inline">
                Nivel {{$store.state.login.user_level}}
                <b-progress :value="$store.state.login.currentExp" 
                    :max="$store.state.login.nextLevelExp"
                    class="d-inline-block"
                    show-progress animated variant="success"/>
              </div> -->
            <!-- {{$store.state.login.currentExp}} {{$store.state.login.nextLevelExp}} -->
            <!-- </div>
            <div class="notifications d-inline" v-if="$store.state.login.token && showUser"> -->
            <!-- <div>
                <div class="d-block">
                  Nivel {{$store.state.login.user_level}} {{$store.state.login.currentExp}} {{$store.state.login.nextLevelExp}}
                </div>
                <div class="d-block">
                </div>
              </div> -->
            <!-- <b-badge
                class="text-dark mr-1"
                variant="primary"
                :class="$router.history.base !== '/utopie'? 'bg-redpie':''"
              >
                {{$store.state.login.missions.filter(m => !m.completed).length}}
              </b-badge>
              <v-icon name="list" class="icon" />
              <b-dropdown size="lg"
                variant="link" right toggle-class="text-decoration-none"
              >
                <div class="custom-dropdown-notifications">
                  <div class="custom-header">
                    <h2>MISIONES</h2>
                    <a href="/utopie#/profile/missions/">VER TODAS</a>
                  </div>
                  <div style="width: 100%;">
                    <span style="padding-left: 15px; font-size: 14px;">
                      Haz click en la misión para ver un video explicativo.
                    </span>
                  </div>
                  <div class="custom-list">
                    <ul>
                      <li
                        v-for="(mission, index) in $store.state.login.missions"
                        :key="'b-dropdown-item'+index"
                        @click="openVideoModal(mission.mission.video)"
                      >
                        <b-container class="m-0 p-0">
                          <b-row class="m-0 p-0">
                            <b-col>
                              <h5 class="text-primary">{{mission.mission.title}}</h5>
                            </b-col>
                            <b-col cols="2" v-if="mission.completed">
                              <v-icon name="check-circle"/>
                            </b-col>
                          </b-row>
                          <b-row class="m-0 p-0">
                            {{mission.mission.description}}
                          </b-row>
                        </b-container>
                      </li>
                    </ul>
                  </div> -->
            <!-- <div class="custom-header">
                    <b-button class="button btn btn-primary" @click="load_notifications">Ver más</b-button>
                  </div> -->
            <!-- </div>
              </b-dropdown>
            </div>
            <div class="username d-inline" v-if="$store.state.login.token">
              <div
                class="d-inline"
                style="
                  display: inline-block;
                  padding-right: 5px !important;
                  font-size: 20px !important;">
                <b-badge variant="primary"
                  :class="$router.history.base !== '/utopie'? 'bg-redpie':''"
                >{{$store.state.login.points}} <span class="d-none d-lg-inline d-xl-inline">Aportes</span></b-badge>
              </div>
              <b-dropdown size="lg" variant="link" class="d-inline" right toggle-class="text-decoration-none">
                <template v-slot:button-content>
                  <div class="user-avatar" >
                    <img :class="$router.history.base !== '/utopie'? 'redpie':''" :src="$store.state.login.thumbnail" />
                  </div>
                  <span
                    class="text-light-hover-darken text-light d-none d-xl-inline"
                    style="font-size: 17px !important;"
                  >{{$store.state.login.first_name}} {{$store.state.login.last_name}}</span>
                </template>
                <b-dropdown-item href="#" @click="redirectProfile">Perfil</b-dropdown-item>
                <b-dropdown-item v-if="$store.state.login.isAdmin" href="#" @click="redirectMgmt">Gestión de Colegio</b-dropdown-item>
                <b-dropdown-item href="#" @click="openChangePasswordModal">Cambiar contraseña</b-dropdown-item>
                <b-dropdown-item v-if="$store.state.login.isAdmin" href="#" @click="redirectAdminUsers">Admin. usuarios</b-dropdown-item>
                <b-dropdown-item v-if="$store.state.login.isAdmin" href="#" @click="redirectTemplates">Crear Templates</b-dropdown-item>
                <b-dropdown-item v-if="$store.state.login.isAdmin" href="#" @click="redirectTests">Crear Tests</b-dropdown-item>
                <b-dropdown-item v-if="$store.state.login.isAdmin" href="#" @click="redirectUpSchool">Subir/actualizar colegio</b-dropdown-item>
                <b-dropdown-item v-if="$store.state.login.isAdmin" href="#" @click="redirectCoordinators">Crear varios colegios</b-dropdown-item>
                <b-dropdown-item href="#" @click="logout()">Cerrar sesión</b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="logout" v-else>
              <b-button class="btn-outline-primary bg-dark mr-1" size="sm" @click="openModal()">Iniciar sesión</b-button>
              <span class="d-none d-md-inline-block"><strong> O </strong></span>
              <b-button class="btn-outline-primary bg-dark d-inline ml-1" size="sm" @click="openModal(true)">Regístrate</b-button>
            </div> -->
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!-- <div :class="$router.history.base !== '/utopie'?
          'subheader-container bg-redpie':'subheader-container bg-secondary'" >
      <p>
        Bienvenida(o) a la comunidad de profesionales de la educación más grande del mundo, publica, pregunta, comparte, responde y disfruta!
      </p>
    </div> -->
    <LoginModalComponent
      ref="login-modal"
      @exit="closeModal"
      @hidden="$emit('hidden')"
    />
    <b-modal ref="registered-modal" centered hide-header hide-footer>
      <b-container class="container">
        <b-row>
          <b-col cols="12">
            {{ registerMessage }}
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import authService from "../../../services/authService";
import customTestService from "../../../services/customTestService";
import LoginModalComponent from "../modals/LoginModalComponent";
import ChangePasswordModal from "../modals/ChangePasswordModal";
import ViewVideoModal from "../modals/ViewVideoModal";
import QuickEurekaModal from "./QuickEurekaModal";
import userService from "@/services/userService";

export default {
  components: {
    LoginModalComponent,
    ChangePasswordModal,
    ViewVideoModal,
    QuickEurekaModal,
  },
  props: {
    showLogo: { type: Boolean, default: true },
    showSearch: { type: Boolean, default: true },
    showUser: { type: Boolean, default: true },
    cost: { type: Number, default: 0 },
  },
  created() {
    this.$emit("set-open-login-fun", this.openModal);
    if (this.$store.state.login.token) {
      userService.get_user_data().then((data) => {
        this.$store.commit("login/setPoints", data, { root: true });
      });
    }
  },
  data() {
    return {
      search: "",
      registerMessage: "",
      rut: "",
      appleIcon: require("../../../assets/img/icono-manzana.svg"),
    };
  },
  watch: {
    cost() {
      this.showColor = true;
      setTimeout(() => {
        this.showColor = false;
      }, 1000);
    },
  },
  methods: {
    searchStudent: function () {
      this.rut = this.formatter();
      if (!this.rut) {
        this.$toasted.error("Ingresar rut!");
      } else {
        this.checkTestRut();
      }
    },

    checkTestRut: function () {
      var year = new Date().getFullYear();
      var showPast = new Date().getMonth() < 8;

      customTestService.checkTestRut(this.rut, year, showPast).then(
        (data) => {
          this.$refs["quick-eureka-modal"].open(data);
        },
        (error) => {
          var message = "Ha ocurrido un error.";
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message = error.response.data.message;
          }
          this.$toasted.error(message);
        }
      );
    },
    validate() {
      if (typeof this.rut !== "string") {
        this.state = false;
        return;
      }
      if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(this.rut)) {
        this.state = false;
        this.invalidMessage = "El formato no coincide con el esperado";
        return;
      }
      let clean = this.rut.replace(/[^\w\s]|_/g, "");
      clean = clean.slice(0, clean.length - 1);
      let t = parseInt(clean, 10);
      let m = 0;
      let s = 1;

      while (t > 0) {
        s = (s + (t % 10) * (9 - (m++ % 6))) % 11;
        t = Math.floor(t / 10);
      }

      let v = s > 0 ? "" + (s - 1) : "K";
      this.state = v === this.rut.slice(-1);
      if (!this.state)
        this.invalidMessage = "El dígito verificador no corresponde al RUT";
    },
    formatter() {
      this.rut = this.rut.toUpperCase();
      this.rut = this.rut.replace(" ", "").replace(/\./g, "").replace(/-/g, "");
      let result = "";
      if (this.rut.length === 9) {
        if (this.rut.charAt(this.rut.length - 2) !== "-")
          result = [
            this.rut.slice(0, this.rut.length - 1),
            "-",
            this.rut.slice(this.rut.length - 1),
          ].join("");
        else result = this.rut;
        if (result.charAt(2) !== ".")
          result = [result.slice(0, 2), ".", result.slice(2)].join("");
        if (result.charAt(6) !== ".")
          result = [result.slice(0, 6), ".", result.slice(6)].join("");
      } else if (this.rut.length === 8) {
        if (this.rut.charAt(this.rut.length - 2) !== "-")
          result = [
            this.rut.slice(0, this.rut.length - 1),
            "-",
            this.rut.slice(this.rut.length - 1),
          ].join("");
        else result = this.rut;
        if (result.charAt(1) !== ".")
          result = [result.slice(0, 1), ".", result.slice(1)].join("");
        if (result.charAt(5) !== ".")
          result = [result.slice(0, 5), ".", result.slice(5)].join("");
      } else if (this.rut.length === 10) {
        if (this.rut.charAt(this.rut.length - 2) !== "-")
          result = [
            this.rut.slice(0, this.rut.length - 1),
            "-",
            this.rut.slice(this.rut.length - 1),
          ].join("");
        else result = this.rut;
        if (result.charAt(3) !== ".")
          result = [result.slice(0, 3), ".", result.slice(3)].join("");
        if (result.charAt(7) !== ".")
          result = [result.slice(0, 7), ".", result.slice(7)].join("");
      }
      this.validate();
      if (!this.state) {
        this.rut = "";
        return "";
      }
      return result;
    },
    redirectHome: function () {
      if (this.$router.history.base === "/utopie")
        this.$router.push({ name: "home" });
      else window.location.href = "/utopie#/";
    },
    openVideoModal: function (link) {
      this.$refs["view-video-modal"].open(link);
    },
    redirectAdminUsers: function () {
      if (this.$router.history.base === "/utopie")
        this.$router.push({ name: "agregar-cambiar-usuarios" });
      else window.location.href = "/utopie#/agregar-cambiar-usuarios";
    },
    redirectTemplates() {
      if (this.$router.history.base === "/redpie")
        this.$router.push({ name: "templates" });
      else window.location.href = "/redpie#/templates";
    },
    redirectTests() {
      window.location.href = "/tests#/";
    },
    redirectMgmt() {
      if (this.$router.history.base === "/redpie")
        this.$router.push({ name: "gestion" });
      else window.location.href = "/redpie#/gestion";
    },
    goFudeiTable() {
      if (this.$router.history.base === "/redpie")
        this.$router.push({ name: "school-fudei" });
      else window.location.href = "/redpie#/admin-school-fudei";
    },
    redirectUpSchool() {
      if (this.$router.history.base === "/redpie")
        this.$router.push({ name: "upload-update-school" });
      else window.location.href = "/redpie#/upload-update-school";
    },
    redirectProfile: function () {
      if (this.$router.history.base === "/utopie")
        this.$router.push({ name: "profile" });
      else window.location.href = "/utopie#/profile";
    },
    redirectCoordinators: function () {
      if (this.$router.history.base === "/redpie")
        this.$router.push({ name: "coordinadores" });
      else window.location.href = "/redpie#/coordinadores";
    },
    redirectChatbot: function () {
      window.location.href = "/botpie#/";
    },
    openModal: function (register) {
      this.$refs["login-modal"].open(register);
    },
    openChangePasswordModal: function () {
      this.$refs["change-password-modal"].open();
    },
    closeModal: function (make) {
      if (!make) this.$refs["login-modal"].close();
      else {
        this.registerMessage = make;
        this.$refs["registered-modal"].show();
      }
    },
    close_login_dropdown: function () {
      this.$refs.loginDropdown.hide();
    },
    search_by_text: function () {
      this.$emit("search-by-text", this.search);
    },
    set_not_new_notifications: function () {
      this.$emit("set-not-new-notifications");
    },
    load_notifications: function () {
      this.$emit("load-notifications");
    },
    logout: function () {
      authService.logout().then(() => {
        this.$store.commit("login/setUserEmpty");
        if (this.$router.history.base === "/utopie")
          this.$router.push({ name: "home" });
        else window.location.href = "/utopie#/";
      });
    },
  },
};
</script>

<style lang="scss">
.spacer {
  flex: 1 1 auto;
}

.logo-right {
  width: 100%;
  height: $headerHeight;

  .image-right {
    width: 100%;
    height: 100%;
    background: url(../../../assets/img/isotipo_utopie.svg) no-repeat center;
  }
}

.header-general-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;

  .bg-dark {
    background-color: $dark !important;
  }
  .no-bottom {
    height: 5%;
  }
  .med-text {
    font-size: 20px;
  }
  .bg-redpie {
    background-color: #e74a57 !important;
  }
  .header-container {
    .h-container {
      .logo {
        // width: 100%;
        height: $headerHeight;

        .image {
          // width: 100%;
          height: 100%;
          cursor: pointer;
          background: url(../../../assets/img/logo.svg) no-repeat left center;
        }
      }
      .search {
        height: $headerHeight;

        .search-input-group {
          height: 100%;
          padding: 0px 0;

          .search-input-go {
            height: 100% !important;
          }
        }

        .button {
          background-color: #fff !important;
          border: solid 1px #ced4da;
          border-left: none;
        }
      }
      .right-options {
        width: 100%;
        text-align: right;

        .notifications,
        .username,
        .logout {
          color: #fff;
          padding: 0 12px;
          display: inline-block;
          height: $headerHeight;
          line-height: $headerHeight;

          button {
            color: #fff;
          }
        }
        .notifications {
          .custom-dropdown-notifications {
            min-width: 412px;
            color: $dark;

            .custom-header {
              width: 100%;
              display: flex;
              padding-top: 6px;
              padding-bottom: 12px;

              h2,
              span {
                font-size: 14px;
                padding: 0 12px;
                margin: 0;
                line-height: 32px;
                flex: 1;
                font-weight: bold;
              }

              span {
                text-align: right;

                a {
                  color: $secondary;
                }
              }
            }

            .custom-list {
              width: 100%;
              max-height: 412px;
              overflow: auto;

              ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                  padding: 16px;
                  font-size: 14px;
                  border-top: solid 1px $light;
                  background-color: $ultralight;
                  cursor: pointer;

                  span {
                    display: block;
                    line-height: 1.3em;
                    font-size: 0.9em;
                  }
                }
                li:last-child {
                  border-bottom: solid 1px $light;
                }
                li:hover {
                  background-color: $omglight;
                }
              }
            }
            .custom-list-missions {
              width: 100%;
              max-height: 412px;

              ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                  padding: 16px;
                  font-size: 14px;
                  border-top: solid 1px $light;
                  background-color: $ultralight;
                  cursor: pointer;

                  span {
                    display: block;
                    line-height: 1.3em;
                    font-size: 0.9em;
                  }
                }
                li:last-child {
                  border-bottom: solid 1px $light;
                }
                li:hover {
                  background-color: $omglight;
                }
              }
            }
          }
        }
        .username {
          font-size: 14px;

          .user-avatar {
            display: inline-block;
            width: 45px;
            height: 100%;
            padding-right: 5px !important;

            img {
              width: 100%;
              border-radius: 50%;
              border: solid 3px $primary;
            }
            .redpie {
              border: solid 3px #e74a57;
            }
          }

          a {
            text-decoration: none;
            color: inherit;
          }
        }
        .logout {
          font-size: 12px;
        }
      }
    }
  }
  .subheader-container {
    font-size: 12px;
    text-align: center;
    color: #fff;

    p {
      line-height: $headerSubHeight;
      margin: 0;
    }
  }
  .balance {
    display: flex;
    justify-content: center;
    align-items: center; /* Centrado vertical */
    color: white;
    font-size: 1.1rem !important;
    font-weight: 200;

    padding: 0.1rem;
    border: 2px solid white;
    border-radius: 0.7rem;
  }
  .cost {
    display: flex;
    justify-content: center;
    align-items: center; /* Centrado vertical */
    color: white;
    font-size: 1.1em;
    font-weight: 200;

    padding: 0.1rem;
    border: 2px solid white;
    border-radius: 0.7rem;
    transition: all 0.3s ease-in-out;
  }
  .change-color {
    background-color: rgb(0, 185, 174);
  }
}
</style>
