<template>
  <b-modal
    centered
    size="lg"
    ref="video-tutorial-modal"
    hide-footer
  >
    <b-container>
      <b-row>
        <b-embed
            type="iframe"
            aspect="16by9"
            :src="link"
            allowfullscreen
        />
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>

export default {
  components: {
  },
  data () {
    return {
      link: "",
    }
  },
  created() {
  },
  methods: {
    embed(url){
        const params = new URLSearchParams(url);
        const id = params.get('https://www.youtube.com/watch?v');
        return `https://www.youtube-nocookie.com/embed/${id}`;
    },
    open(link) {
        this.link = this.embed(link);
        this.$refs["video-tutorial-modal"].show();
    },
  }
}
</script>
<style lang="scss">
</style>