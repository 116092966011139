<template>
  <b-container class="experiences text-center my-4" fluid>
    <div class="p-4">
      <span class="h4 text-warning font-weight-bold"
        >Nuestros usuarios nos respaldan</span
      >
    </div>
    <b-row class="p-0 my-4 d-none d-lg-inline-flex">
      <b-col lg="4" v-for="client in page" :key="client.id" class="p-0 mt-2">
        <b-img
          :src="client.photo"
          class="img-fluid d-block mx-auto rounded-circle client-img"
        ></b-img>
        <b-card body-bg-variant="light" class="mx-4">
          <b-card-title class="h5 font-weight-bold text-warning mt-4">
            <br />{{ client.name }}
          </b-card-title>
          <b-card-sub-title class="text-dark">{{
            client.position
          }}</b-card-sub-title>
          <b-form-rating
            value="5"
            variant="warning"
            no-border
            class="bg-light"
            readonly
            inline
          ></b-form-rating>
          <p class="text-small text-muted font-italic">
            {{ client.review }}
          </p>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="d-block mx-5 d-lg-none">
      <carousel
        class="mx-0"
        :per-page="1"
        :mouse-drag="true"
        :navigationEnabled="true"
        :paginationEnabled="false"
        :autoplay="true"
        :autoplayTimeout="5000"
        :centerMode="true"
      >
        <slide v-for="client in page" :key="client.id">
          <b-img
            :src="client.photo"
            class="img-fluid d-block rounded-circle client-img mx-auto"
          ></b-img>
          <b-card body-bg-variant="light" class="">
            <b-card-title class="h5 font-weight-bold text-warning mt-4">
              <br />{{ client.name }}
            </b-card-title>
            <b-card-sub-title class="text-dark">{{
              client.position
            }}</b-card-sub-title>
            <b-form-rating
              value="5"
              variant="warning"
              no-border
              class="bg-light"
              readonly
              inline
            ></b-form-rating>
            <p class="text-small text-muted font-italic">
              {{ client.review }}
            </p>
          </b-card>
        </slide>
      </carousel>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "CustomerExperiences",
  props: {
    route: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      slide: 0,
      interval: 4000,
      page: [
        {
          id: 1,
          name: "Lorena Huerta",
          position: "Coordinadora Fundacional- Fundación Belén Educa",
          photo: require("../../../assets/img/sales/client1.jpeg"),
          review:
            '"La plataforma RED PIE es una herramienta tecnológica que ha sido fundamental para agilizar procesos administrativos que nos compete gestionar en los programas de integración escolar. En términos prácticos permite que todos los profesionales en línea puedan elaborar los diversos informes que exige el MINEDUC. Este insumo es valioso y significativo, ya que tenemos la información de todos nuestros estudiantes que pertenecen al programa de manera sistematizada, ordenada y detallada. Además, cuento con todo el historial del estudiante desde que ingresa al programa."',
        },
        {
          id: 2,
          name: "Sonia Gutiérrez Cordero",
          position: "Coordinadora Liceo Juan Mackenna O'Reilly",
          photo: require("../../../assets/img/sales/client2.jpeg"),
          review:
            '"Desde que contamos con RED PIE en nuestro Liceo, ha sido un tremendo apoyo a la labor que desempeñamos ya que :\n' +
            "1. Optimiza el tiempo en la elaboración de formularios e informes.\n" +
            "2. Resguardo seguro de la documentación de las y los estudiantes.\n" +
            "3. Facilita la obtención de información de los estudiantes y del equipo PIE\n" +
            "4. Manejo amigable de los recursos que cuenta la RED PIE.\n" +
            "5. Respuesta inmediata y cercana de los administradores  de la RED PIE.\n" +
            "6. Permite acceder al historial del estudiante beneficiario del PIE.\n" +
            "7. En tiempo de pandemia facilitó cumplir con la requerimientos ministeriales en forma oportuna sin tener que tener la documentación impresa\n" +
            "8. Constante innovación, para favorecer las distintas tareas administrativas del PIE.\n" +
            'Todos agradecemos esta herramienta que nos ha permitido y nos da la tranquilidad, de cumplir con los debidos procesos."',
        },
        {
          id: 3,
          name: "Claudia Villagrán Pereira",
          position: "Coordinadora Colegio Claudio Matte",
          photo: require("../../../assets/img/sales/client3.jpeg"),
          review:
            '"REDPIE ha sido un soporte importante desde que contamos con él, especialmente en esta contingencia, nos ha permitido gestionar con facilidad las evidencias que requiere el sistema. Este recurso es de fácil uso, es amable y eficiente; si hay necesidad de apoyo siempre la atención es rápido, muy profesional y especialmente gentil. Gracias🌼"',
        },
        {
          id: 4,
          name: "Adrián",
          position: "Coordinador PIE",
          photo: require("../../../assets/img/sales/client4.jpg"),
          review:
            "Esta herramienta nos ha permitido acceder a toda la documentación disponible de los y las estudiantes, así como también a su elaboración. Como coordinador, esto hace que el seguimiento tanto de los estudiantes como de los profesionales que trabajan con ellos sea más accesible. Además, las evaluaciones, como EUREKA, proporciona información valiosa sobre las capacidades, dificultades y objetivos de cada estudiante evaluado, lo que nos permite trabajar en potenciar sus habilidades y superar sus desafíos. Como equipo, estamos muy satisfechos con esta herramienta de gestión, especialmente con el eficaz soporte técnico que hemos recibido. ¡Muchas gracias!",
        },
        {
          id: 5,
          name: "Camila",
          position: "Coordinadora PIE",
          photo: require("../../../assets/img/sales/client5.jpg"),
          review:
            "Las planificaciones diversificadas de Utopie son un excelente aporte al trabajo de las educadoras diferenciales. Esto debido a que reduce el tiempo de trabajo administrativos que se debe invertir en esto y así poder dedicar más tiempo al trabajo personalizado con los estudiantes en aula de recursos. \n Lo mejor de esta herramienta es que se puede descargar el material y modificarlo según el contexto y necesidad de los estudiantes.",
        },
        {
          id: 6,
          name: "Kimberly",
          position: "Coordinadora PIE",
          photo: require("../../../assets/img/sales/client6.jpg"),
          review:
            "Desde sus inicios, Utopie ha desempeñado un papel fundamental como un sólido respaldo para mi labor docente. Hoy, con la integración de la inteligencia artificial, la plataforma ha sumado un valioso elemento a mi práctica profesional, permitiéndome desarrollar planificaciones más diversificadas y agilizar la formulación de informes. Este avance tecnológico representa un notable plus que potencia y simplifica significativamente nuestras tareas administrativas.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.experiences {
  background-color: #f0f5f7;
}

.text-small {
  font-size: small;
}

.client-img {
  border: #e0a800 4px solid;
  margin-bottom: -4em;
  z-index: +1;
  position: relative;
  max-height: 150px;
  max-width: 150px;
}
</style>
