<template>
  <div class="simple-other-options radius-box-8 padding-box-16">
    <ModalDocumentTypes ref="modal-document-types" @reload="reloadFunc()"/>
    <ModalTriggerBot
        ref="modal-trigger-bot"
        :alreadyBot="alreadyBot"
        :school="school"
        :tabs="tabs"
        v-on:reload-grades="reloadData"
    />
    <ModalEditStudents
      ref="modal-edit-students"/>
    <ModalDocumentTest
      ref="modal-document-test"
      @open-loading="$emit('open-loading')"
      @show-fudei="show_fudei"
      @delete-document="delete_document"
      @create-fudei="create_fudei"
      @restore-document="restore_document"
      @show-modal-upload-document="show_modal_upload_document"
      @archived-document="archived_document"
      @view-document="view_document"
    />
    <ModalAddEmail ref="modal-add-email"/>
    <b-modal id="link-modal" hide-header hide-footer centered>
      <div class="text-right p-2">
        <b-input :value="link"></b-input>
        <b-button variant="primary" pill size="sm" class="mt-2" @click="copyToClipboard"><b>Copiar Link</b></b-button>
      </div>
    </b-modal>
    <div v-if="$store.state.login.points < 0">
      <b-row class="item" @click="goToBuy()" no-gutters>
        <b-col cols="3">
          <v-icon class="icon-group">
            <v-icon class="secondary" name="circle" scale="2"></v-icon>
            <v-icon class="text-ultralight" name="graduation-cap"></v-icon>
          </v-icon>
        </b-col>
        <b-col cols="9" class="d-flex">
            <div class="justify-content-center align-self-center">
              <b>Adquiere Utopie para tu colegio</b>
            </div>
        </b-col>
      </b-row>
      <b-overlay :show="!canWork">
        <b-row class="item" @click="workOnline()" no-gutters>
          <b-col cols="3">
            <v-icon class="icon-group">
              <v-icon class="text-primary" name="circle" scale="2"></v-icon>
              <v-icon class="text-ultralight" name="file-signature"></v-icon>
            </v-icon>
          </b-col>
          <b-col cols="9" class="d-flex">
            <div class="justify-content-center align-self-center">
              <b>Prueba trabajar un documento</b>
            </div>
          </b-col>
        </b-row>

        <b-row class="item" @click="goToOA()" no-gutters>
          <b-col cols="3">
            <v-icon class="icon-group">
              <v-icon class="text-warning" name="circle" scale="2"></v-icon>
              <v-icon class="text-ultralight" name="puzzle-piece"></v-icon>
            </v-icon>
          </b-col>
          <b-col cols="9" class="d-flex">
            <div class="justify-content-center align-self-center">
              <b>Encuentra rápido los OA</b>
            </div>
          </b-col>
        </b-row>

        <b-row class="item" @click="goToAuto()" no-gutters>
          <b-col cols="3">
            <v-icon class="icon-group">
              <v-icon class="primary" name="circle" scale="2"></v-icon>
              <v-icon class="text-ultralight" name="magic"></v-icon>
            </v-icon>
          </b-col>
          <b-col cols="9" class="d-flex">
            <div class="justify-content-center align-self-center">
              <b>Realiza informes automáticos</b>
            </div>
          </b-col>
        </b-row>
      </b-overlay>
      <b-row class="item" @click="generateLink" no-gutters>
        <b-col cols="3">
          <v-icon class="icon-group">
            <v-icon class="warning" name="circle" scale="2"></v-icon>
            <v-icon class="text-ultralight" name="link"></v-icon>
          </v-icon>
        </b-col>
        <b-col cols="9" class="d-flex">
          <div class="justify-content-center align-self-center">
            <b>Genera un link de invitación</b>
          </div>
        </b-col>
      </b-row>
      <b-row class="item" @click="goToMaterials()" no-gutters>
        <b-col cols="3">
          <v-icon class="icon-group">
            <v-icon class="secondary" name="circle" scale="2"></v-icon>
            <v-icon class="text-ultralight" name="folder-open"></v-icon>
          </v-icon>
        </b-col>
        <b-col cols="9" class="d-flex">
          <div class="justify-content-center align-self-center">
            <b>Registro PIE y materiales</b>
          </div>
        </b-col>
      </b-row>
      <b-overlay :show="!canTestWork">
        <b-row class="item" @click="goToTest()">
          <b-col cols="3">
            <v-icon class="icon-group">
              <v-icon class="tertiary" name="circle" scale="2"></v-icon>
              <v-icon class="text-ultralight" name="spell-check"></v-icon>
            </v-icon>
          </b-col>
          <b-col cols="9" class="d-flex">
            <div class="justify-content-center align-self-center">
              <b>Corrige un test</b>
            </div>
          </b-col>
        </b-row>
      </b-overlay>

    </div>
    <b-button
        id="btn-materiales"
        class="no-outline border-none no-box-shadow"
        variant="unknown"
        @click=changeShow()
    >
      {{ showMore ? 'MENOS' : 'MÁS' }} ACCIONES
      <v-icon v-if="!showMore" name="chevron-down"/>
      <v-icon v-else name="chevron-up"/>
    </b-button>
    <b-collapse v-model="showMore">
      <b-overlay :opacity="0.6" :show="$store.state.login.points < 0 && $store.state.login.token" rounded="sm" style="cursor: pointer;"
                 @click="$store.commit('login/changeBuy')">
        <b-row @click="openTests" no-gutters class="item">
          <b-col cols="3">
            <v-icon class="icon-group">
              <v-icon class="secondary" name="circle" scale="2"></v-icon>
              <v-icon class="text-ultralight" name="user-plus"></v-icon>
            </v-icon>
          </b-col>
          <b-col cols="9">
            <b>Trabaja Tests</b>
          </b-col>
        </b-row>
        <b-row @click="openAddEmail" no-gutters class="item">
          <b-col cols="3">
            <v-icon class="icon-group">
              <v-icon class="secondary" name="circle" scale="2"></v-icon>
              <v-icon class="text-ultralight" name="user-plus"></v-icon>
            </v-icon>
          </b-col>
          <b-col cols="9">
            <b>Añade personas a tu equipo PIE</b>
          </b-col>
        </b-row>
        <b-row class="item" @click="openDocumentTypes" no-gutters>
          <b-col cols="3">
            <v-icon class="icon-group">
              <v-icon class="text-primary" name="circle" scale="2"></v-icon>
              <v-icon class="text-ultralight" name="file-alt"></v-icon>
            </v-icon>
          </b-col>
          <b-col cols="9" class="d-flex">
            <div class="justify-content-center align-self-center">
              <b>Usa formatos de tu colegio</b>
            </div>
          </b-col>
        </b-row>
        <b-row class="item" @click="changeWatch" no-gutters>
          <b-col cols="3">
            <v-icon class="icon-group">
              <v-icon class="primary" name="circle" scale="2"></v-icon>
              <v-icon class="text-ultralight" :name="mode==='fudei'? 'chalkboard-teacher':'file-signature'"></v-icon>
            </v-icon>
          </b-col>
          <b-col cols="9" class="d-flex">
            <div class="justify-content-center align-self-center">
              <b>{{mode==='fudei'? 'Volver a los cursos':'Administra los FUDEIs'}}</b>
            </div>
          </b-col>
        </b-row>
        <b-row class="item" @click="generateLink" no-gutters>
          <b-col cols="3">
            <v-icon class="icon-group">
              <v-icon class="warning" name="circle" scale="2"></v-icon>
              <v-icon class="text-ultralight" name="link"></v-icon>
            </v-icon>
          </b-col>
          <b-col cols="9" class="d-flex">
            <div class="justify-content-center align-self-center">
              <b>Genera un link de invitación</b>
            </div>
          </b-col>
        </b-row>
        <b-row class="item" @click="openTriggerBot" no-gutters>
          <b-col cols="3">
            <v-icon class="icon-group">
              <v-icon class="tertiary" name="circle" scale="2"></v-icon>
              <v-icon class="text-ultralight" name="child"></v-icon>
            </v-icon>
          </b-col>
          <b-col cols="9" class="d-flex">
            <div class="justify-content-center align-self-center">
              <b>Trae tus estudiantes!</b>
            </div>
          </b-col>
        </b-row>
        <b-row class="item" @click="$emit('show-graph')" no-gutters>
          <b-col cols="3">
            <v-icon class="icon-group">
              <v-icon class="primary" name="circle" scale="2"></v-icon>
              <v-icon class="text-ultralight" name="chart-bar"></v-icon>
            </v-icon>
          </b-col>
          <b-col cols="9" class="d-flex">
            <div class="justify-content-center align-self-center">
              <b>¿Cómo vamos con los documentos?</b>
            </div>
          </b-col>
        </b-row>

        <b-row class="item" @click="$emit('video-call')" no-gutters>
          <b-col cols="3">
            <v-icon class="icon-group">
              <v-icon class="text-warning" name="circle" scale="2"></v-icon>
              <v-icon class="text-ultralight" name="video"></v-icon>
            </v-icon>
          </b-col>
          <b-col cols="9" class="d-flex">
            <div class="justify-content-center align-self-center">
              <b>Videollamadas</b>
            </div>
          </b-col>
        </b-row>

        <template v-slot:overlay>
          <div class="text-center">
            <strong>Adquiere la versión completa para utilizar estas funcionalidades</strong>
          </div>
        </template>
      </b-overlay>
    </b-collapse>
  </div>
</template>

<script>
import redpieService from "../../../services/redpieService";
import ModalDocumentTypes from "../modals/ModalDocumentTypes";
import ModalTriggerBot from "../modals/ModalTriggerBot";
import ModalAddEmail from "../modals/ModalAddEmail";
import ModalEditStudents from "@/components/redpie/modals/ModalEditStudents";
import ModalDocumentTest from "@/components/redpie/modals/ModalDocumentTest";

export default {
  components: {
    ModalEditStudents,
    ModalDocumentTypes,
    ModalTriggerBot,
    ModalAddEmail,
    ModalDocumentTest,
  },
  data() {
    const defaultGroup = {
      title: {
        label: "AÑADIR ESTUDIANTES",
        key: "name",
        type: "button",
        icon: "plus-circle",
        color: "secondary",
        buttons: [
          {
            color: "secondary",
            text: "Edita, mover o eliminar estudiante",
          },
          {
            color: "secondary-dark",
            text: "Corregir test automático",
          },
        ],
      },
      buttons: [
        {
          color: "secondary",
          icon: "plus-circle",
        },
      ],
    };
    const defaultTab = {
      visibleCompleteGraphInfo: false,
      groups: [
        Object.assign({label: "1° básico"}, defaultGroup),
        Object.assign({label: "2 básico"}, defaultGroup),
        Object.assign({label: "3 básico"}, defaultGroup),
      ],
    };
    return {
      showMore: false,
      school: {},
      tabs: [Object.assign({title: "TODO EL AÑO 2020"}, defaultTab)],
      alreadyBot: false,
      link: ''
    };
  },
  props: {
    reloadFunc: {
      type: Function,
      required: true,
    },
    reloadSchool: {
      type: Boolean,
      required: true,
    },
    canWork: {
      type: Boolean,
      required: true
    },
    canTestWork: {
      type: Boolean,
      required: true
    },
    mode: {
      type: String,
      required: true
    },
    changeWatch: {
      type: Function,
      required: true,
    }
  },
  watch: {
    reloadschool() {
      this.openTriggerBot();
    },
  },
  methods: {
    openDocumentTypes: function () {
      this.$refs["modal-document-types"].open(this.$store.state.login.school, this.periods);
    },
    goToBuy: function () {
      window.location.href = '/comprar#/'
    },
    workOnline: function () {
      this.$emit('work-with-first')
    },
    goToOA: function () {
      this.$emit('work-with-first-OA')
    },
    goToAuto: function () {
      this.$emit('work-with-first-IA')
    },
    goToMaterials: function () {
      this.$emit('change-to-5')
    },
    goToTest: function () {
      this.$emit('open-test-modal')
    },
    changeShow: function () {
      this.showMore = !this.showMore;
    },
    openDocuments: function () {
      this.$refs["modal-document-test"].open(this.$store.state.login.school, this.$store.state.login.year, 'documents');
    },
    openTests: function (student, grade) {
      if (!!student && !!grade) {
        this.$refs["modal-document-test"].open(this.$store.state.login.school, this.$store.state.login.year, 'tests', student, grade);
      }
      else {
        this.$refs["modal-document-test"].open(this.$store.state.login.school, this.$store.state.login.year, 'tests');
      }
    },
    openAddEmail: function () {
      this.$refs["modal-add-email"].open(this.$store.state.login.school);
    },
    openTriggerBot() {
      //this.checkCreds();
      //this.loadSchoolData();
      this.$refs["modal-edit-students"].open(this.$store.state.login.school, this.$store.state.login.year);
    },
    reloadData: function () {
      this.reloadFunc();
      this.checkCreds();
      this.loadSchoolData();
    },
    loadSchoolData: function () {
      if (this.$store.state.login.school) {
        this.tabs[0].groups = [];
        redpieService.getSchoolData(this.$store.state.login.school).then((data) => {
          this.school = data;
          this.tabs[0].title = this.school.name;
          for (var i = 0; i < this.school.levels.length; i++) {
            this.tabs[0].groups.push(
                Object.assign(
                    {
                      label: this.school.levels[i].label,
                      code: this.school.levels[i].code,
                    },
                    this.defaultGroup
                )
            );
          }
        });
      } else {
        this.tabs[0].groups = [];
      }
    },
    checkCreds: function () {
      redpieService
          .checkSigeCredentials(this.$store.state.login.school)
          .then((data) => {
            if (data.data) {
              this.alreadyBot = true;
            } else {
              this.alreadyBot = false;
            }
          });
    },
    generateLink() {
      this.link = `${process.env["VUE_APP_BASE_URL"]}/redpie#/easy-join/?s=${this.$store.state.login.school}`
      this.$bvModal.show('link-modal')
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.link)
      this.$toasted.success("Texto copiado al portapapeles")
    },
    show_modal_upload_document: function(data) {
      this.$emit("show-modal-upload-document", data);
    },
    show_fudei: function(fudei_id, sgdt_id, state) {
      this.$emit("show-fudei", fudei_id, sgdt_id, state);
    },
    create_fudei: function(sgdt_id) {
      this.$emit("create-fudei", sgdt_id);
    },
    view_document: function(data) {
      console.log('simple');
      this.$emit("view-document", data);
    },
    download_document: function (data) {
      this.$emit("download-document", data);
    },
    archived_document: function (data) {
      this.$emit("archived-document", data);
    },
    delete_document: function (data) {
      this.$emit("delete-document", data);
    },
    restore_document: function (data) {
      this.$emit("restore-document", data);
    },
  },
  created() {
    if (this.$store.state.login.points >= -1)
      this.changeShow()
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/redpie4/colors";

.simple-other-options {
  width: 100%;
  background-color: white;

  .icon-group {
    width: 52px;
    height: 52px;

    .primary {
      color: $primary !important;
    }

    .secondary {
      color: $secondary !important;
    }

    .tertiary {
      color: $tertiary !important;
    }
  }

  .item:hover {
    background-color: $ultralight !important;
    cursor: pointer;
  }
}
</style>