<template>
  <div v-b-visible="demoHandler">
    <b-table-simple
      class="plan-table"
      hover
      small
      caption-top
      responsive
      bordered
      fixed
    >
      <b-thead class="head-table">
        <b-tr>
          <b-th v-html="planification.title"></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <div v-for="(section, sIndex) in planification.sections" :key="sIndex">
          <b-tr>
            <b-th 
              :rowspan="section.rowspan+1" 
              style="writing-mode: vertical-rl; text-orientation: upright;"
              :style="'background-color: '+section.bgColor+'; border: 5px solid '+section.borderColor"
            >
              {{section.name}}
            </b-th>
            <b-td class="text-center" :style="'width: 200px; background-color: '+section.bgColor+'; border: 5px solid '+section.borderColor">Sección</b-td>
            <b-td class="text-center" :style="'background-color: '+section.bgColor+'; border: 5px solid '+section.borderColor">Contenido</b-td>
          </b-tr>
          <b-tr v-for="(subsection, ssIndex) in section.subsections" :key="ssIndex+'-s'">
            <b-td class="text-center" :style="'border: 5px solid '+section.borderColor">{{subsection.name}}</b-td>
            <b-td class="text-center" style="white-space: pre-wrap" :style="'border: 5px solid '+section.borderColor">{{subsection.content}}</b-td>
          </b-tr>
        </div>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import chatBotService from "@/services/chatBotService";

export default {
  name: "PlanificationOaTable",
  props: {
    subject: {
      type: String,
      required: false,
    },
    grade: {
      type: String,
      required: false,
    },
    oa: {
      type: Object,
      required: false,
    },
    ind: {
      type: Array,
      required: false
    },
    type: {
      type: String,
      required: false,
    },
    demo: {
      type: Boolean,
      required: false
    },
    condition: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      text: "",
      previous: false,
      taxonomy: [
        "Pasos",
        "Errores",
        "Conceptos",
        "Ejemplo",
        "Recordar",
        "Entender",
        "Aplicar",
        "Analizar",
        "Evaluar",
      ],
      taxonomyNames: {
        "Pasos": "Entrégale un paso a paso como forma de automonitoreo a los estudiantes. ",
        "Errores": "Avísale a los estudiantes cuáles son los errores comunes para que se anticipen.",
        "Conceptos": "Define y entrega los conceptos importantes antes de empezar la clase.",
        "Ejemplo": "Aterriza el contenido a un ejemplo cercano",
        "Recordar": "Nivel 1 Recordar datos o conceptos.",
        "Entender": "Nivel 2 Explicar con sus propias palabras",
        "Aplicar": "Nivel 3 Aplicar en situaciones nuevas",
        "Analizar": "Nivel 4 Analizar la información ",
        "Evaluar": "Nivel 5 Evaluar contextos",
      }
    };
  },
  mounted() {
    if (!this.demo) {
      this.getDailyPlanification();
    }
    // else {
    //   this.getDemoPlanification();
    // }
  },
  computed: {
    planification() {
      var table = {title: this.demo?'Demo':"", sections: []}
      var title = this.demo?'Ejemplo de lenguaje':'OA'+this.oa.name+'<br>'
      if (!this.demo)
        this.ind.forEach((indicator) => title+=indicator.name+'<br>')
      table.title=title;
      var ending_split = this.text.split("[cierre_clase]")
      var middle_split = ending_split[0].split("[desarrollo_clase]")
      var beggining = middle_split[0].split("[inicio_clase]")[1]
      if (beggining) {
        var introduction_split = beggining.split("[introduction]");
        var intro = introduction_split[1];
        var relation_split = introduction_split[0].split("[question_relation]");
        var relation = relation_split[1];
        var question = relation_split[0].split("[question]")[1]
        var intro_section = {name: "INICIO", subsections: [], rowspan: 3, bgColor: "#92e0d6", borderColor: "#1bc1b7"}
        if (question) {
          intro_section.subsections.push({name: "Pregunta", content: question.replace(/\\n/g, "\n").replace(/\\t/g, "\t").replace(/}/g, "")})
        }
        if (relation) {
          intro_section.subsections.push({name: "Bajada de pregunta", content: relation.replace(/\\n/g, "\n").replace(/\\t/g, "\t").replace(/}/g, "")})
        }
        if (intro) {
          intro_section.subsections.push({name: "Introducción del objetivo", content: intro.replace(/\\n/g, "\n").replace(/\\t/g, "\t").replace(/}/g, "")})
        }
        table.sections.push(intro_section)
      }
      var middle = middle_split[1]
      if (middle) {
        var middle_section = {name: "DESARROLLO", subsections: [], rowspan: 3, bgColor: "#d8a7c6", borderColor: "#d17caf"}
        if (this.type==="accurate"){
          middle_section.rowspan = this.taxonomy.length+1
        }
        var group_split = middle.split("[group]");
        var group = group_split[1];
        var individual_split = group_split[0].split("[individual]");
        var individual = individual_split[1];
        var bloom_activities = {}
        for (var i=this.taxonomy.length; i--;i>-1) {
          var pieces = individual_split[0].split("["+this.taxonomy[i]+"]")
          if (pieces[1]) {
            bloom_activities[this.taxonomy[i]] = pieces[1].replace(/\\n/g, "\n").replace(/\\t/g, "\t").replace(/}/g, "").trim();
          }
          individual_split[0] = pieces[0]
        }
        var explanation = individual_split[0].split("[explanation]")[1]
        if (explanation) {
          middle_section.subsections.push({name: "Explicación", content: explanation.replace(/\\n/g, "\n").replace(/\\t/g, "\t").replace(/}/g, "")})
        }
        if (this.type==="basic") {
          if (individual) {
            middle_section.subsections.push({name: "Actividad Individual", content: individual.replace(/\\n/g, "\n").replace(/\\t/g, "\t").replace(/}/g, "")})
          }
          if (group) {
            middle_section.subsections.push({name: "Actividad grupal", content: group.replace(/\\n/g, "\n").replace(/\\t/g, "\t").replace(/}/g, "")})
          }
        }
        else {
          this.taxonomy.forEach(tax => {
            if (bloom_activities[tax]) {
              middle_section.subsections.push({name: this.taxonomyNames[tax], content: bloom_activities[tax]})
            }
          })
        }
        table.sections.push(middle_section)
      }
      var ending = ending_split[1]
      if (ending) {
        var motivation_split = ending.split("[motivation]");
        var motivation = motivation_split[1];
        var final_thoughts = motivation_split[0].split("[final_thoughts]")[1]
        var ending_section = {name: "FINAL", subsections: [], rowspan: 2, bgColor: "#e8c691", borderColor: "#e29f17"}
        if (final_thoughts) {
          ending_section.subsections.push({name: "Reflexión final", content: final_thoughts.replace(/\\n/g, "\n").replace(/\\t/g, "\t").replace(/}/g, "")})
        }
        if (motivation) {
          ending_section.subsections.push({name: "Despedida y motivación", content: motivation.replace(/\\n/g, "\n").replace(/\\t/g, "\t").replace(/}/g, "")})
        }
        table.sections.push(ending_section)
      }
      return table;
    },
  },
  watch: {
  },
  methods: {
    demoHandler(visible) {
      if (visible && this.demo && !this.previous) {
        this.previous = true;
        this.getDemoPlanification();
      }
    },
    formatText(text) {
      return text.replace(/\\n/g, "\n").replace(/\\t/g, "\t");
    },
    getDailyPlanification() {
      var clearOA = this.oa;
      clearOA.subtags = [];
      chatBotService
        .getDailyPlanification(this.subject, this.grade, clearOA, this.type, this.ind.map((i) => {return {name: i.name, description: i.description}}), this.condition)
        .then((body) => {
          const reader = body.getReader();
          const decoder = new TextDecoder();
          var vue_context = this;

          function processChunk({ done, value }) {
            if (value) {
              const decodedChunk = decoder.decode(value);
              vue_context.text += decodedChunk
            }

            if (done) {
              vue_context.$emit("done", vue_context.planification)
              return;
            }

            return reader.read().then(processChunk);
          }

          reader.read().then(processChunk);
        });
    },
    getDemoPlanification() {
      chatBotService
        .getDemoPlanification()
        .then((body) => {
          const reader = body.getReader();
          const decoder = new TextDecoder();
          var vue_context = this;

          function processChunk({ done, value }) {
            if (value) {
              const decodedChunk = decoder.decode(value);
              vue_context.text += decodedChunk
            }

            if (done) {
              vue_context.$emit("done", vue_context.planification)
              return;
            }

            return reader.read().then(processChunk);
          }

          reader.read().then(processChunk);
        });
    },
  },
};
</script>
<style scoped>
.plan-table {
  border: 1pt solid rgb(0, 185, 174, 0.5);
  /* font-family: "Ubuntu Regular", sans-serif !important; */
}
.head-table {
  background-color: rgb(0, 185, 174) !important;
  color: white;
}
.head-table-ind {
  background-color: rgb(0, 185, 174) !important;
}
</style>
