<template>
  <b-row :class="question.is_active ? '' : 'recycle'">
    <b-col
      class="text-center"
      cols="1"
      v-if="ejeRelated && textRelated && mode !== 'material-list'"
    >
      <v-icon name="star" class="text-warning" />
    </b-col>
    <b-col
      class="text-center"
      cols="1"
      v-if="!ejeRelated && textRelated && mode !== 'material-list'"
    >
      <v-icon name="star" class="text-danger" />
    </b-col>
    <b-col cols="2">
      <b-badge variant="info">{{ eKinds[question.kind] }}</b-badge>
    </b-col>

    <b-col cols="3" class="text-center">
      <!-- <p>
        {{ oa }}
      </p> -->
      <b-badge v-if="ind" variant="primary text-wrap">
        {{ ind.parent_name + " - "+ind.parent_summary}} <br/>
        {{ "IND: "+ind.summary }}
      </b-badge>
      <b-badge v-else-if= "oa" variant="primary text-wrap">
        {{ oa.name + " - "+oa.summary}}
      </b-badge>

    </b-col>
    <b-col v-if="!showCompleteText && exceedsLong">
      <p @click="$emit('open-question')" class="text-hover">
        {{ question.question.substring(0, longLimit) + " ..." }}
      </p>
      <a @click="showCompleteText = true" class="text-hover">ver mas</a>
    </b-col>
    <b-col v-else-if="showCompleteText && exceedsLong">
      <p @click="$emit('open-question')" class="text-hover">
        {{ question.question }}
      </p>
      <a @click="showCompleteText = false" class="text-hover">ver menos</a>
    </b-col>
    <b-col
      v-else-if="!exceedsLong"
      @click="$emit('open-question')"
      class="text-hover"
    >
      <p>{{ question.question }}</p>
    </b-col>
    <!-- <b-col cols="1" @click="$emit('open-question')" style="cursor: pointer">
      <b-badge variant="primary">Ver</b-badge>
    </b-col> -->
    <b-col
      v-if="mode !== 'material-list' && mode !== 'text-related'"
      cols="2"
      @click="$emit('select-question')"
      style="cursor: pointer"
    >
      <b-badge variant="info">Seleccionar</b-badge>
    </b-col>
    <b-col
      v-if="mode === 'material-list' && question.is_active && paperBin"
      cols="1"
      class="text-center"
    >
      <b-button
        variant="unknown"
        size="sm"
        class="mx-auto"
        v-b-tooltip.hover
        title="Eliminar ejercicio"
        @click="$emit('delete-question')"
      >
        <v-icon name="trash-alt" class="text-danger" />
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: "QuestionRowComponent",
  props: {
    question: {
      type: Object,
      required: true,
    },
    textRelated: {
      type: Boolean,
      default: false,
      required: false,
    },
    ejeRelated: {
      type: Boolean,
      default: false,
      required: false,
    },
    mode: {
      type: String,
      required: false,
    },
    paperBin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      eKinds: {
        0: "Alternativas",
        1: "Desarrollo",
        2: "Dictado",
        3: "Ordenamiento"
      },
      showCompleteText: false,
      longLimit: 60,
    };
  },
  computed: {
    oa() {
      let subtags = this.question.subtags;
      let tag = this.question.tag;
      return subtags.length ? null : tag;
    },
    ind(){
      let subtags = this.question.subtags;
      return subtags.length ? subtags[0] : null;
    },
    exceedsLong() {
      return this.question.question.length > this.longLimit + 3;
    },
  },
};
</script>
<style>
.recycle {
  opacity: 0.5;
}
.text-hover:hover {
  opacity: 0.6;
  cursor: pointer;
  transition: 0.1s;
}
</style>
