<template>
  <div class="text-center w-100">
    <MaterialPreviewModal
      ref="material-preview-modal"
      @material-chosen="materialChosen"
    />
    <ApplicationModal
      ref="application-modal"
      @create-new-material="$emit('create-new-material')"
      @open-preview="openPreview"
      @create-application="createApplication"
    />
    <b-modal ref="delete-app-modal" hide-header centered size="lg">
      <b-container>
        <b-row>
          <b-col class="text-center">
            ¿Está seguro que desea enviar esta aplicación a la papelera?
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <b-row>
          <b-col class="text-center">
            <b-button
              variant="danger"
              @click="
                appToDelete = '';
                $refs['delete-app-modal'].hide();
              "
              pill
            >
              Cancelar
            </b-button>
          </b-col>
          <b-col class="text-center">
            <b-button variant="warning" @click="deleteApplication()" pill>
              Eliminar
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <b-row class="text-right mx-2 p-2">
      <b-col v-if="appsPaperBin">
        <b-form-checkbox
          v-model="aShowRecycle"
          name="recycle"
          value="active"
          unchecked-value="not_active"
        >
          Mostrar applicaciones en papelera
        </b-form-checkbox>
      </b-col>
      <b-col :cols="appsPaperBin ? 1 : 12" class="text-right">
        <b-button
          variant="unknown"
          size="sm"
          class="mx-auto"
          v-b-tooltip.hover
          title="Papelera"
          @click="appsPaperBin = !appsPaperBin"
        >
          <v-icon name="trash-alt" class="text-danger" />
        </b-button>
      </b-col>
    </b-row>
    <!-- <b-row class="text-left mx-2 p-2">
      <b-col cols="6" class="cursor-pointer">
        <p class="h6 w-100" @click="openNewApplication()">
          Aplicar un material nuevo
          <v-icon name="plus-circle" scale="1.2" style="cursor: pointer" />
        </p>
      </b-col>
    </b-row> -->
    <b-row class="text-left mx-2 p-2" v-if="!loadingApplications">
      <!-- <b-col cols="3" class="align-middle mt-4">
          <b-card
            header-bg-variant="dark"
            header="Aplicar un Material Nuevo"
            id="new"
            @click="openNewApplication()"
            header-text-variant="white"
            style="cursor: pointer"
            no-body
          >
            <b-card-body class="custom-bg-light">
              <b-form-row>
                <b-col class="text-center px-0" cols="6" offset="3">
                  <b-img :src="icon_new" height="100" />
                </b-col>
              </b-form-row>
            </b-card-body>
          </b-card>
        </b-col> -->

      <b-col cols="12" class="mt-4 mx-0 px-0">
        <b-container class="m-0 p-0" v-if="!loadingApplications">
          <b-row class="m-0 p-0">
            <b-col
              v-for="(app, aindex) in applications"
              :key="aindex"
              cols="6"
              class="mt-4"
            >
              <application-row-component
                :application="app"
                :paperBin="appsPaperBin"
                mode="material-list"
                @open-application="openApplication(app)"
                @delete-application="deleteAppModal(app.id)"
              />
              <hr />
            </b-col>
          </b-row>
        </b-container>
        <b-container v-else>
          <b-row class="m-0 p-0">
            <b-col class="text-center">
              <b-spinner variant="primary" label="Spinning"></b-spinner>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      <!-- <b-col cols="9" class="mt-4 mx-0 px-0">
          <b-container class="m-0 p-0">
            <b-row class="m-0 p-0">
              <b-col v-for="app in applications" :key="app.id" cols="3">
                <b-card
                  :header-bg-variant="
                    app.material.profession === 2
                      ? 'danger'
                      : app.material.profession === 5
                      ? 'secondary'
                      : 'primary'
                  "
                  :header="
                    app.material.name.length > 18
                      ? app.material.name.substring(0, 15) + '...'
                      : app.material.name
                  "
                  :id="app.id"
                  header-text-variant="white"
                  @click="
                    $emit('open-application-status', app);
                    selectedApplication = app.id;
                  "
                  style="cursor: pointer"
                  no-body
                >
                  <b-card-body>
                    <b-form-row>
                      <b-col class="text-center px-0" cols="4" offset="3">
                        <b-img
                          :src="
                            app.material.profession === 2
                              ? icon_1
                              : app.material.profession === 5
                              ? icon2
                              : icon3
                          "
                          height="100"
                        />
                      </b-col>
                      <b-col class="text-left px-0" cols="5" align-self="end">
                        <b-badge
                          :variant="
                            app.material.profession === 2
                              ? 'danger'
                              : app.material.profession === 5
                              ? 'secondary'
                              : 'primary'
                          "
                          >{{ app.material.duration }} minutos</b-badge
                        >
                      </b-col>
                    </b-form-row>
                  </b-card-body>
                </b-card>
                <p class="text-light">
                  # Aplicado por {{ app.creator.first_name }}
                  {{ app.creator.middle_name }} {{ app.creator.last_name }}
                </p>
              </b-col>
            </b-row>
          </b-container>
        </b-col> -->
    </b-row>
    <b-row v-else>
      <b-col class="text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </b-col>
    </b-row>
    <b-row v-if="pages > 1">
      <b-col class="text-center">
        <b-button-group>
          <b-button
            variant="outline-info"
            v-if="page > 1"
            @click="page = page - 1"
          >
            &#60;
          </b-button>
          <b-button
            variant="outline-info"
            :pressed="page === 1"
            @click="page = 1"
          >
            1
          </b-button>
          <b-button variant="outline-info" v-if="page - pageRange / 2 > 2">
            ...</b-button
          >
          <b-button
            v-for="(p, index) in pagesArray.filter((p) => {
              return (
                1 < p &&
                p < pages &&
                ((pageRange < page &&
                  page - pageRange / 2 <= p &&
                  p <= page + pageRange / 2) ||
                  (pageRange >= page && p <= pageRange + 1))
              );
            })"
            :key="'page-' + index"
            :pressed="p === page"
            @click="page = p"
            variant="outline-info"
          >
            {{ p }}
          </b-button>
          <b-button
            variant="outline-info"
            v-if="pages - 1 > page + pageRange / 2"
          >
            ...</b-button
          >
          <b-button
            variant="outline-info"
            :pressed="page === pages"
            @click="page = pages"
          >
            {{ pages }}</b-button
          >
          <b-button
            variant="outline-info"
            v-if="page < pages"
            @click="page = page + 1"
          >
            &#62;
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import customMaterialService from "@/services/customMaterialService";
import MaterialPreviewModal from "../modals/MaterialPreviewModal";
import MaterialApplicationsAdmin from "./MaterialApplicationsAdmin";
import MaterialResponseReview from "./MaterialResponseReview";
import ApplicationModal from "../modals/ApplicationModal";
import ApplicationRowComponent from "@/components/custom-materials/miscellaneous/ApplicationRowComponent";

export default {
  name: "ApplicationListComponent",
  components: {
    MaterialPreviewModal,
    MaterialApplicationsAdmin,
    MaterialResponseReview,
    ApplicationModal,
    ApplicationRowComponent,
  },
  props: ["material"],
  data() {
    return {
      orderFilter: null,
      subjectFilter: null,
      applications: [],
      icon_new: require("../../../assets/img/material_icons/Icon4.svg"),
      icon_1: require("../../../assets/img/material_icons/Icon1.svg"),
      icon2: require("../../../assets/img/material_icons/Icon2.svg"),
      icon3: require("../../../assets/img/material_icons/Icon3.svg"),
      icon4: require("../../../assets/img/material_icons/Icon4.svg"),
      step: 0,
      chosenMaterial: null,
      selectedApplication: null,
      selectedLink: null,
      loadingApplications: false,
      page: 1,
      perPage: 12,
      pages: 1,
      pageRange: 6,
      order: "-created_at",
      appsPaperBin: false,
      aShowRecycle: "not_active",
    };
  },

  watch: {
    schoolID() {
      this.retrieveData();
    },
    userID() {
      this.retrieveData();
    },
    page() {
      this.getPaginatedSchoolApplications();
    },
    aShowRecycle() {
      this.getPaginatedSchoolApplications();
    },
  },
  computed: {
    schoolID() {
      return this.$store.state.login.school;
    },
    userID() {
      return this.$store.state.login.user_id;
    },
    pagesArray() {
      return Array.from({ length: this.pages }, (_, i) => i + 1);
    },
  },
  mounted() {
    this.retrieveData();
    console.log("mounted list");
    if (this.material) {
      this.openFromMaterial(this.material);
      this.$emit("clear-material");
    }
  },
  methods: {
    reviewLink(link) {
      this.selectedLink = link;
    },
    createApplication(data) {
      var payload = {
        data: data,
        year: this.$store.state.login.year,
        school: this.$store.state.login.school,
      };
      customMaterialService
        .createApplication(this.chosenMaterial.id, payload)
        .then((data) => {
          this.$toasted.success("Aplicación creada!");
          this.$emit("open-application-status", data);
        });
    },

    openNewApplication() {
      this.step = 0;
      this.$refs["application-modal"].open(this.step);
    },
    openFromMaterial(material) {
      this.step = 1;
      this.$refs["application-modal"].open(this.step);
      this.materialChosen(material);
    },
    materialChosen(material) {
      this.step = 1;
      this.$refs["application-modal"].changeStep(this.step);
      this.chosenMaterial = material;
    },
    getSchoolApplications() {
      this.loadingApplications = true;
      customMaterialService
        .getSchoolApplications(this.schoolID)
        .then((data) => {
          this.applications = data;
          this.loadingApplications = false;
        })
        .catch(() =>
          this.$toasted.error(
            "Ocurrió un problema al traer aplicaciones en tu colegio."
          )
        );
    },
    getPaginatedSchoolApplications() {
      this.loadingApplications = true;
      customMaterialService
        .getPaginatedSchoolApplications(
          this.schoolID,
          this.page,
          this.perPage,
          this.order,
          this.aShowRecycle
        )
        .then((data) => {
          this.applications = data.applications;
          this.pages = data.pages;
          this.loadingApplications = false;
        })
        .catch(() =>
          this.$toasted.error("Ocurrió un problema al traer tus textos")
        );
    },
    retrieveData() {
      this.getPaginatedSchoolApplications();
    },
    openPreview(material) {
      this.$refs["material-preview-modal"].open(material);
    },
    openApplication(app) {
      this.$emit("open-application-status", app);
      this.selectedApplication = app.id;
    },
    deleteAppModal(appID) {
      this.appToDelete = appID;
      this.$refs["delete-app-modal"].show();
    },
    deleteApplication() {
      customMaterialService
        .deleteApplication(this.appToDelete)
        .then(() => {
          this.getPaginatedSchoolApplications();
          this.appToDelete = "";
          this.$toasted.success("Aplicación movida a la papelera");
          this.$refs["delete-app-modal"].hide();
        })
        .catch(() => this.$toasted.error("No se pudo eliminar la aplicación"));
    },
  },
};
</script>

<style>
.custom-bg-light {
  background-color: #ffffff;
}
.h-scroll {
  overflow-x: scroll;
}
</style>
