<template>
  <b-container class="w-100">
    <b-card no-body>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              :variant="step > 0 ? 'outline-primary' : 'outline-info'"
              id="button-oa"
              style="color: black"
              block
              @click="getFilters"
            >
              <!-- <v-icon v-if="selectedTag" name="crosshairs" class="text-warning"/> -->
              {{
                customTags.length || selectedTag
                  ? selectedTag
                    ? "1 OA agregado y " +
                      selectedSubtags.length +
                      " indicadores agregados"
                    : "Añadir OA"
                  : "Seleccionar OA"
              }}
            </b-button>
            <b-tooltip
              v-if="selectedSubtags.length"
              target="button-oa"
              triggers="hover"
            >
              <ul>
                <li v-for="(st, sti) in selectedSubtags" :key="sti + '-st'">
                  {{ st.parent_name }} - {{ st.name }}
                  <b-button
                    @click="subtagClicked(st)"
                    variant="unknown"
                    class="mx-auto"
                    size="sm"
                  >
                    <v-icon name="trash-alt" class="text-danger" />
                  </b-button>
                </li>
              </ul>
            </b-tooltip>
          </b-col>
        </b-row>
      </b-container>
      <b-collapse id="oas-collapse" v-model="visible">
        <b-container>
          <b-row
            v-for="(relation, rIndex) in selectedHashtags"
            :key="rIndex + '-relation'"
          >
            <b-col
              v-for="(selectedHashtag, hIndex) in relation"
              :key="hIndex + '-sht'"
            >
              <b-select
                v-model="selectedHashtags[rIndex][hIndex]"
                @change="hashtagChanged(rIndex, hIndex)"
              >
                <b-select-option :value="null"
                  >Seleccione
                  {{
                    selectedHashtagsOptions[rIndex][hIndex].relation_name
                  }}</b-select-option
                >
                <b-select-option
                  v-for="(child, cIndex) in selectedHashtagsOptions[rIndex][
                    hIndex
                  ]
                    ? selectedHashtagsOptions[rIndex][hIndex].children
                    : []"
                  :key="cIndex + '-child'"
                  :value="child.id"
                >
                  {{ child.name }}
                </b-select-option>
              </b-select>
            </b-col>
          </b-row>
          <b-row class="pt-2">
            <b-col class="text-center">
              <h5>Objetivos de Aprendizaje (OA)</h5>
            </b-col>
            <b-col class="text-center">
              <h5>Indicadores</h5>
            </b-col>
          </b-row>
          <b-overlay :show="loading">
            <b-row>
              <b-col class="m-0 p-0" style="height: 400px; overflow-y: scroll">
                <b-container
                  class="m-0 p-0"
                  v-for="(tag, index) in customTags"
                  :key="'tag-' + index"
                >
                  <b-overlay
                    :show="selectedTag && selectedTag !== tag"
                    opacity="0.5"
                    @click="tagClicked(tag)"
                  >
                    <b-row class="mx-3">
                      <b-card
                        class="my-2 py-2 w-100"
                        no-body
                        bg-variant="warning text-white"
                        style="cursor: pointer"
                        @click="tagClicked(tag)"
                      >
                        <b-card-body class="text-left">
                          <b-card-title>{{ tag.name }}</b-card-title>
                          <b-card-text>
                            {{ tag.description }}
                          </b-card-text>
                        </b-card-body>
                      </b-card>
                    </b-row>
                    <b-row class="mx-3">
                      <b-button
                        variant="outline-primary"
                        v-for="(hashtag, hIndex) in tag.hashtags"
                        size="sm"
                        :key="'h-' + hIndex"
                        class="m-1 text-warning hashtag"
                      >
                        #{{ hashtag.name }}
                      </b-button>
                    </b-row>
                    <template #overlay>
                      <div></div>
                    </template>
                  </b-overlay>
                </b-container>
              </b-col>
              <b-col style="height: 400px; overflow-y: scroll">
                <div v-if="!selectedTag">Seleccione un OA</div>
                <b-container v-else>
                  <b-button
                    v-for="(subtag, sIndex) in selectedTag.subtags"
                    :key="'subtag-' + sIndex"
                    :variant="
                      includedSubtag(subtag) ? 'primary' : 'outline-primary'
                    "
                    block
                    style="color: black"
                    class="m-1"
                    @click="subtagClicked(subtag)"
                  >
                    {{ subtag.name }}
                  </b-button>
                  <!-- <b-card
                                        border-variant="warning"
                                        v-for="(subtag, sIndex) in selectedTag.subtags"
                                        :key="'subtag-'+sIndex"
                                        class="m-2 text-justify"
                                        no-body
                                    >
                                        <b-card-text class="m-1">{{subtag.name}}</b-card-text>
                                    </b-card> -->
                </b-container>
              </b-col>
            </b-row>
            <template #overlay>
              <b-container>
                <b-row class="d-flex justify-content-center">
                  <b-img :src="loadingGif" rounded alt="Loading image" />
                </b-row>
              </b-container>
            </template>
          </b-overlay>
        </b-container>
      </b-collapse>
      <!-- <b-tabs pills vertical v-model="cosa">
                <b-tab v-for="(tag, index) in customTags" :key="index" @click="selectedTag=tag" class="h-100" lazy>
                    <template #title>
                        {{ tag.name }}
                        <v-icon class="icon-right" name="caret-right" />
                    </template>
                    <IndividualTagComponent :selectedTag="selectedTag" :selectedSubtags="selectedSubtags" 
                        @subtag-clicked="subtagClicked" class="h-100"/>
                </b-tab>
            </b-tabs> -->
    </b-card>
  </b-container>
</template>
<script>
import IndividualTagComponent from "./IndividualTagComponent";
import customTestService from "@/services/customTestService";

export default {
  components: {
    IndividualTagComponent,
  },
  props: ["preSelectedSubtags", "preSelectedTag", "preselected"],
  data() {
    return {
      step: 0,
      cosa: null,
      selectedSubtags: this.preSelectedSubtags,
      selectedTag: this.preSelectedTag,
      selectedHashtags: [],
      selectedHashtagsOptions: [],
      customTags: [],
      loadingGif: require("../../../assets/img/preloader.gif"),
      loading: false,
      visible: false,
    };
  },

  created() {},
  methods: {
    getFilters() {
      this.visible = !this.visible;
      //   this.selectedTag = null;
      this.customTags = [];
      customTestService
        .getFilters("")
        .then((data) => {
          this.selectedHashtags = [];
          this.selectedHashtagsOptions = [];
          for (var i = 0; i < data.length; i++) {
            this.selectedHashtagsOptions.push([data[i]]);
            if (
              data[i].children.filter((child) => {
                return this.preselected.includes(child.id);
              }).length
            ) {
              this.selectedHashtags.push([
                data[i].children.filter((child) => {
                  return this.preselected.includes(child.id);
                })[0].id,
              ]);
              this.hashtagChanged(this.selectedHashtags.length - 1, i);
            } else {
              this.selectedHashtags.push([null]);
            }
          }
        })
        .catch(() =>
          this.$toasted.error("Ocurrió un problema al traer tus tags")
        );
    },
    includedSubtag(subtag) {
      if (
        this.selectedSubtags.filter((st) => {
          return st.id === subtag.id;
        }).length
      ) {
        return true;
      } else {
        return false;
      }
    },
    subtagClicked(subtag) {
      if (this.includedSubtag(subtag)) {
        this.selectedSubtags.splice(this.selectedSubtags.indexOf(subtag), 1);
      } else {
        this.selectedSubtags.push(subtag);
        this.$emit("set-subtags", this.selectedSubtags);
      }
    },
    getTags(selected) {
      this.loading = true;
      customTestService.getTagsFiltered(selected).then((data) => {
        this.loading = false;
        this.customTags = data;
      });
    },
    hashtagChanged(rIndex, hIndex) {
      //   this.selectedTag = null;
      customTestService
        .getFilters(this.selectedHashtags[rIndex][hIndex])
        .then((data) => {
          if (data.length) {
            if (this.selectedHashtags[rIndex].length === hIndex + 1) {
              if (
                data[0].children.filter((child) => {
                  return this.preselected.includes(child.id);
                }).length
              ) {
                this.selectedHashtags[rIndex].push(
                  data[0].children.filter((child) => {
                    return this.preselected.includes(child.id);
                  })[0].id
                );
              } else {
                this.selectedHashtags[rIndex].push(null);
              }
              this.selectedHashtagsOptions[rIndex].push(data[0]);
            } else {
              this.$set(this.selectedHashtags[rIndex], hIndex + 1, null);
              this.$set(
                this.selectedHashtagsOptions[rIndex],
                hIndex + 1,
                data[0]
              );
              this.$set(
                this.selectedHashtags,
                rIndex,
                this.selectedHashtags[rIndex].slice(0, hIndex + 2)
              );
              this.$set(
                this.selectedHashtagsOptions,
                rIndex,
                this.selectedHashtagsOptions[rIndex].slice(0, hIndex + 2)
              );
            }
          }
          var hashtags = [];
          for (var i = 0; i < this.selectedHashtags.length; i++) {
            if (
              this.selectedHashtags[i][this.selectedHashtags[i].length - 1] ===
              null
            ) {
              hashtags.push(
                this.selectedHashtags[i][this.selectedHashtags[i].length - 2]
              );
            } else {
              hashtags.push(
                this.selectedHashtags[i][this.selectedHashtags[i].length - 1]
              );
            }
          }
          this.getTags(hashtags);
          // console.log(hashtags);
        })
        .catch(() =>
          this.$toasted.error("Ocurrió un problema al traer tus tags")
        );
    },
    tagClicked(tag) {
      console.log("click");
      if (this.selectedTag === tag) {
        this.selectedTag = null;
        this.$emit("set-tag", this.selectedTag);
      } else {
        this.selectedTag = tag;
        this.$emit("set-tag", this.selectedTag);
      }
      this.selectedSubtags = [];
      this.$emit("set-subtags", this.selectedSubtags);
    },
  },
  watch: {},
};
</script>
<style lang="scss">
.icon-right {
  position: absolute;
  right: 15px;
}
.hashtag:hover {
  color: #fff !important;
}
</style>
