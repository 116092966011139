<template lang="">
  <div>
    <GenericTest
      :test="test"
      :test_id="test_id"
      :answer="answer"
      :col_size="6"
      :label_size="6"
      :input_size="6"
      :semester="semester"
      :previous_answer="previous_answer"
    ></GenericTest>
  </div>
</template>
<script>
import GenericTest from "./GenericTest";
export default {
  components: { GenericTest },
  props: ["test_id", "student_id", "previous_answer", "semester"],
  watch: {
    student_id() {
      this.answer.student_id = this.student_id;
    },
    test_id() {
      this.answer.test = this.test_id;
    },
  },
  data() {
    return {
      answer: {
        test: this.test_id,
        answers: {},
        student_id: this.student_id,
      },
      test: [
        {
          label: "Completación:",
          type: "number",
          var: "completacion",
          maxlength: "3",
        },

        {
          label: "Información:",
          type: "number",
          var: "informacion",
          maxlength: "3",
        },

        { label: "Claves:", type: "number", var: "claves", maxlength: "3" },

        {
          label: "Analogías:",
          type: "number",
          var: "analogias",
          maxlength: "3",
        },

        {
          label: "Ordenamiento:",
          type: "number",
          var: "ordenamiento",
          maxlength: "3",
        },

        {
          label: "Arimética:",
          type: "number",
          var: "aritmetica",
          maxlength: "3",
        },

        {
          label: "Diseño con cubos:",
          type: "number",
          var: "cubos",
          maxlength: "3",
        },

        {
          label: "Vocabulario:",
          type: "number",
          var: "vocabulario",
          maxlength: "3",
        },

        {
          label: "Ensamblajes:",
          type: "number",
          var: "ensamblajes",
          maxlength: "3",
        },

        {
          label: "Comprensión:",
          type: "number",
          var: "comprension",
          maxlength: "3",
        },

        { label: "Símbolos:", type: "number", var: "simbolos", maxlength: "3" },

        { label: "Dígitos:", type: "number", var: "digitos", maxlength: "3" },

        {
          label: "Laberintos:",
          type: "number",
          var: "laberintos",
          maxlength: "3",
        },

        {
          label: "Tipo de puntaje:",
          type: "select",
          var: "tipo",
          options: [
            { value: "Bruto", text: "Bruto" },

            { value: "Estandar", text: "Estándar" },
          ],
        },

        {
          label: "País:",
          type: "select",
          var: "pais",
          options: [{ value: "Chile", text: "Chile" }],
        },

        {
          label: "Edad del evaluado:",
          type: "select",
          var: "edad",
          options: [
            {
              value: "a",
              text: "6 años 0 meses 0 días a 6 años 6 meses 30 días",
            },

            {
              value: "b",
              text: "6 años 7 meses 0 días a 6 años 11 meses 30 días",
            },

            {
              value: "c",
              text: "7 años 0 meses 0 días a 7 años 6 meses 30 días",
            },

            {
              value: "d",
              text: "7 años 7 meses 0 días a 7 años 11 meses 30 días",
            },

            {
              value: "e",
              text: "8 años 0 meses 0 días a 8 años 11 meses 30 días",
            },

            {
              value: "f",
              text: "9 años 0 meses 0 días a 9 años 11 meses 30 días",
            },

            {
              value: "g",
              text: "10 años 0 meses 0 días a 10 años 11 meses 30 días",
            },

            {
              value: "h",
              text: "11 años 0 meses 0 días a 11 años 11 meses 30 días",
            },

            {
              value: "i",
              text: "12 años 0 meses 0 días a 12 años 11 meses 30 días",
            },

            {
              value: "j",
              text: "13 años 0 meses 0 días a 13 años 11 meses 30 días",
            },

            {
              value: "k",
              text: "14 años 0 meses 0 días a 14 años 11 meses 30 días",
            },

            {
              value: "l",
              text: "15 años 0 meses 0 días a 15 años 11 meses 30 días",
            },

            {
              value: "m",
              text: "16 años 0 meses 0 días a 16 años 11 meses 30 días",
            },
          ],
        },

        {
          label: "Nivel de confianza:",
          type: "select",
          var: "confianza",
          options: [
            { value: "95%", text: "95%" },

            { value: "90%", text: "90%" },
          ],
        },
      ],
    };
  },
};
</script>
<style lang=""></style>
