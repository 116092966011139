<template>
  <b-row :class="text.is_active ? '' : 'recycle'">
    <b-col
      class="text-center"
      cols="1"
      v-if="text.is_related && mode !== 'material-list'"
    >
      <v-icon name="star" class="text-warning" />
    </b-col>
    <b-col cols="3">
      <b-badge variant="info">{{ types[text.content_type] }}</b-badge>
    </b-col>
    <b-col @click.self="$emit('open-text')" class="text-hover">
      {{ text.title }}
    </b-col>
    <b-col
      v-if="mode === 'material-list' && text.is_active && paperBin"
      cols="2"
      class="text-center"
    >
      <b-button
        variant="unknown"
        size="sm"
        class="mx-auto"
        v-b-tooltip.hover
        title="Eliminar texto"
        @click="$emit('delete-text')"
      >
        <v-icon name="trash-alt" class="text-danger" />
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: "TextRowComponent",
  props: {
    text: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: false,
    },
    paperBin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      types: {
        0: "Texto",
        1: "Multimedia",
        2: "Audio",
      },
    };
  },
};
</script>
<style scoped>
.recycle {
  opacity: 0.5;
}
.text-hover:hover {
  opacity: 0.6;
  cursor: pointer;
  transition: 0.1s;
}
</style>
