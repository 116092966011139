import facebook from "../services/facebookService";
import auth from "../services/authService";
import store from "../store/modules/login.js";
import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

export const authMixin = {
  data() {
    return {
      flag: false,
    };
  },
  methods: {
    authenticate: function (provider, modal) {
      var vue_context = this;
      this.$auth
        .authenticate(provider)
        .then(function (response) {
          // eslint-disable-next-line
          FB.api(
            "/me",
            "post",
            {
              access_token: response.access_token,
              fields:
                "email,first_name,last_name,picture.width(100).height(100)",
            },
            function (response_fb) {
              facebook
                .authenticate({
                  user: response_fb,
                  token: response.access_token,
                  school_id: store.state.school,
                })
                .then(
                  (data) => {
                    if (data.token) {
                      this.$store.commit("login/setUser", data);
                      if (this.flag) {
                        modal.handleAuthenticated();
                      } else {
                        // ADD MESSAGE MODAL
                        modal.$emit("exit", false);
                      }
                    } else {
                      vue_context.$toasted.error(
                        "Revisa tu correo o contraseña."
                      );
                      this.$store.commit("login/setUserEmpty");
                      // TODO: add form error
                    }
                    // eslint-disable-next-line
                  },
                  (error) => {
                    if (error.response.data.message)
                      vue_context.$toasted.error(error.response.data.message);
                    else
                      vue_context.$toasted.error(
                        "Ha ocurrido un error en el servidor, intenta más tarde."
                      );
                    this.$store.commit("login/setUserEmpty");
                  }
                );
            }
          );
          // eslint-disable-next-line
        })
        .catch(function (error) {
          console.log(error);
          vue_context.$toasted.error(
            "Ha ocurrido un error en el servidor, intenta más tarde."
          );
          this.$store.commit("login/setUserEmpty");
        });
    },
    getGoogleUrl() {
      const rootUrl = `http://accounts.google.com/o/oauth2/v2/auth`;
      const options = {
        redirect_uri: process.env.VUE_APP_BASE_URL,
        client_id: process.env.VUE_APP_GOOGLE_ID,
        access_type: "online",
        response_type: "token",
        prompt: "consent",
        scope: ["profile", "email"].join(" "),
      };
      const qs = new URLSearchParams(options);

      return `${rootUrl}?${qs.toString()}`;
    },
    authenticate_google: function (modal) {
      var vue_context = this;
      var url = this.getGoogleUrl();
      var popup = window.open(url, "_blank");
      var token = "";
      const checkPopup = setInterval(() => {
        console.log(process.env.VUE_APP_BASE_URL, popup.window, popup);
        if (
          popup.window &&
          popup.window.location.href &&
          popup.window.location.href.includes(process.env.VUE_APP_BASE_URL)
        ) {
          var startIndex =
            popup.window.location.href.indexOf("access_token=") +
            "access_token=".length;
          var endIndex = popup.window.location.href.indexOf("&token_type");
          token = popup.window.location.href.substring(startIndex, endIndex);
          popup.close();
          axios
            .get(
              `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${token}`
            )
            .then((g_response) => {
              var payload = {
                user: g_response.data,
                token: g_response.data.id,
                school_id: store.state.school,
              };
              auth.authenticateGoogle(payload).then(
                (data) => {
                  if (data.token) {
                    vue_context.$store.commit("login/setUser", data);
                    if (vue_context.flag) {
                      modal.handleAuthenticated();
                    } else {
                      modal.$emit("exit", false);
                    }
                  } else {
                    vue_context.$toasted.error(
                      "Revisa tu correo o contraseña."
                    );
                    vue_context.$store.commit("login/setUserEmpty");
                    // TODO: add form error
                  }
                  // eslint-disable-next-line
                },
                (error) => {
                  if (error.response.data.message)
                    vue_context.$toasted.error(error.response.data.message);
                  else
                    vue_context.$toasted.error(
                      "Ha ocurrido un error en el servidor, intenta más tarde."
                    );
                  vue_context.$store.commit("login/setUserEmpty");
                }
              );
            });
        }
        if (!popup || !popup.closed || token !== "") return;
        clearInterval(checkPopup);
      }, 1000);
      // return this.getGoogleUrl()

      // this.$auth.authenticate(provider).then(function(response) {
      //   console.log("falla adentro");
      //   axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${response.access_token}`).then(
      //     g_response => {
      //       var payload = {
      //         user: g_response.data,
      //         token: g_response.data.id,
      //         school_id: store.state.school
      //       }
      //       auth.authenticateGoogle(payload).then(
      //         data => {
      //           if(data.token) {
      //             vue_context.$store.commit('login/setUser', data);
      //             if(vue_context.flag){
      //               modal.handleAuthenticated()
      //             } else {
      //               modal.$emit("exit", false);
      //             }
      //           }
      //           else {
      //             vue_context.$toasted.error("Revisa tu correo o contraseña.");
      //             vue_context.$store.commit('login/setUserEmpty');
      //             // TODO: add form error
      //           }
      //         // eslint-disable-next-line
      //         }, error => {
      //           if (error.response.data.message)
      //             vue_context.$toasted.error(error.response.data.message);
      //           else
      //             vue_context.$toasted.error("Ha ocurrido un error en el servidor, intenta más tarde.");
      //             vue_context.$store.commit('login/setUserEmpty');
      //         }
      //       );
      //     }
      //   )
      // // eslint-disable-next-line
      // }).catch(function(error) {
      //   console.log(error, error.stack, error.name, error.message);
      //   vue_context.$toasted.error("Ha ocurrido un error en el servidor, intenta más tarde.");
      //   vue_context.$store.commit('login/setUserEmpty');
      // });
    },
    authenticate_password: function (payload, modal) {
      var vue_context = this;
      auth
        .authenticate_password(payload)
        .then((response) => {
          if (response.token) {
            this.$store.commit("login/setUser", response);
            if (this.flag) {
              modal.handleAuthenticated();
            } else {
              modal.$emit("exit", false);
            }
          } else {
            vue_context.$toasted.error(
              "Revisa tu correo o contraseña, deben estar incorrectas."
            );
            this.$store.commit("login/setUserEmpty");
            // TODO: add form error
          }
          // eslint-disable-next-line
        })
        .catch((error) => {
          if (error.response && error.response.status === 404)
            vue_context.$toasted.error("Usuario no existente");
          else if (error.response && error.response.data.message)
            vue_context.$toasted.error(error.response.data.message);
          else
            vue_context.$toasted.error(
              "Ha ocurrido un error en el servidor, intenta más tarde."
            );
          this.$store.commit("login/setUserEmpty");
        });
    },
    register_user: function (payload, modal) {
      var vue_context = this;
      auth
        .register(payload)
        .then((response) => {
          if (response && response.message) {
            // vue_context.$toasted.success(response.message);
            modal.$emit("exit", response.message);
          }
          // TODO: add form error
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            vue_context.$toasted.error(error.response.data.message);
            // TODO: add form error
          } else {
            vue_context.$toasted.error(
              "Ha ocurrido un error en el servidor, intenta más tarde."
            );
          }
        });
    },
  },
};
