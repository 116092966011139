<template>
  <b-modal centered ref="modal" hide-header>
    <b-form-group
      label-cols="4"
      label-cols-lg="2"
      label-size="sm"
      label="Título"
      label-for="input-title"
    >
      <b-form-input id="input-title" size="sm" v-model="name"></b-form-input>
    </b-form-group>
    <b-form-group
      label-cols="4"
      label-cols-lg="2"
      content-cols="8"
      content-cols-md="2"
      label-size="sm"
      label="Duración (minutos)"
      label-for="input-duration"
    >
      <b-form-input
        type="number"
        id="input-duration"
        size="sm"
        v-model="duration"
      ></b-form-input>
    </b-form-group>

    <!-- <b-row class="mx-auto">
      <b-col class="text-center mx-auto">
        <b-dropdown variant="info" size="sm" menu-class="w-100" class="mt-2">
          <template #button-content>
            <v-icon name="palette" scale="0.9" /> Elegir diseño de la prueba
          </template>
          <b-dropdown-item href="#" @click="style = 0" :active="style === 0">
            <b-row class="m-0 p-0">
              <b-col class="m-0 p-0 text-left"> Por defecto </b-col>
              <b-col class="m-0 p-0 text-right">
                <v-icon name="circle" style="color: #758bfd" />
                <v-icon name="circle" style="color: #535175" />
                <v-icon name="circle" style="color: #16c79a" />
                <v-icon name="circle" style="color: #ed217c" />
              </b-col>
            </b-row>
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="style = 1" :active="style === 1">
            <b-row class="m-0 p-0">
              <b-col class="m-0 p-0 text-left"> Moderno </b-col>
              <b-col class="m-0 p-0 text-right">
                <v-icon name="circle" style="color: #008cff" />
                <v-icon name="circle" style="color: #00266c" />
                <v-icon name="circle" style="color: #ffa001" />
                <v-icon name="circle" style="color: #e23a13" />
              </b-col>
            </b-row>
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="style = 2" :active="style === 2">
            <b-row class="m-0 p-0">
              <b-col class="m-0 p-0 text-left"> Sueño </b-col>
              <b-col class="m-0 p-0 text-right">
                <v-icon name="circle" style="color: #ea5cd6" />
                <v-icon name="circle" style="color: #303047" />
                <v-icon name="circle" style="color: #48d6ed" />
                <v-icon name="circle" style="color: #db2152" />
              </b-col>
            </b-row>
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="style = 3" :active="style === 3">
            <b-row class="m-0 p-0">
              <b-col class="m-0 p-0 text-left"> Tranquilidad </b-col>
              <b-col class="m-0 p-0 text-right">
                <v-icon name="circle" style="color: #45b49b" />
                <v-icon name="circle" style="color: #193a31" />
                <v-icon name="circle" style="color: #b28346" />
                <v-icon name="circle" style="color: #cc3315" />
              </b-col>
            </b-row>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row> -->
    <template #modal-footer>
      <div class="justify-content-center p-0 m-0">
        <b-button
          pill
          variant="danger"
          class="mx-1 custom-button"
          @click="close"
          size="md"
        >
          <v-icon name="times" /> Cancelar
        </b-button>
        <b-button
          pill
          variant="primary"
          class="mr-0 custom-button"
          @click="saveMaterial"
          size="px-3 md"
        >
          <b> Guardar</b>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
export default {
  name: "SaveMaterialModal",
  props: {
    inName: {
      type: String,
      required: false,
    },
    inDuration: {
      type: Number,
      required: false,
    },
    inStyle: {
      type: Number,
      required: false,
    },
  },
  // computed: {
  //   name() {},
  // },

  data() {
    return {
      name: "Agrega un título",
      duration: 60,
      style: 0,
    };
  },
  methods: {
    open() {
      this.name = this.inName ? this.inName : "Agrega un título";
      this.duration = this.inDuration ? this.inDuration : 60;
      this.style = this.inStyle ? this.inStyle : 0;
      this.$refs["modal"].show();
    },
    close() {
      this.$refs["modal"].hide();
    },
    saveMaterial() {
      this.$emit("save", {
        name: this.name,
        duration: this.duration,
        style: this.style,
      });
    },
  },
};
</script>
<style lang=""></style>
