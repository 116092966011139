import api from "@/services/api";
import multiform from "@/services/multiform";

export default {
  createFolder(payload) {
    return api.post("folder-items/", payload).then((response) => response.data);
  },
  getFolders() {
    return api
      .get("folder-items/root-folders/")
      .then((response) => response.data);
  },
  getGradeFolders(payload) {
    return api
      .get("folder-items/grade-folders/", payload)
      .then((response) => response.data);
  },
  getFolder(folder_id) {
    return api
      .get(`folder-items/${folder_id}/`)
      .then((response) => response.data);
  },
  updateFolder(folder_id, payload) {
    return api
      .put(`folder-items/${folder_id}/`, payload)
      .then((response) => response.data);
  },
  updateFile(file_id, payload) {
    return api
      .put(`file-items/${file_id}/`, payload)
      .then((response) => response.data);
  },
  uploadFile(payload) {
    return multiform
      .post("folder-items/grade-folders/upload-file/", payload)
      .then((response) => response.data);
  },
  partialUpdateFolder(folder_id, payload) {
    return api
      .patch(`folder-items/${folder_id}/`, payload)
      .then((response) => response.data);
  },
  deleteFolder(folder_id) {
    return api
      .delete(`folder-items/${folder_id}/`)
      .then((response) => response.data);
  },
  getFileInfo(file_id) {
    return api.get(`file-items/${file_id}/`).then((response) => response.data);
  },
  copyFile(file_id, payload) {
    return api
      .post(`file-items/${file_id}/copy/`, payload)
      .then((response) => response.data);
  },
  deleteFile(file_id) {
    return api
      .delete(`file-items/${file_id}/`)
      .then((response) => response.data);
  },
  getChildrens(folder_id, includeDeleted) {
    return api
      .get(`folder-items/${folder_id}/childrens/?deleted=${includeDeleted}`)
      .then((response) => response.data);
  },
  uploadYoutubeLink(payload) {
    return api
      .post("folder-items/grade-folders/upload-link/", payload)
      .then((response) => response.data);
  },
  addMaterial(payload) {
    return api
      .post("folder-items/add-material/", payload)
      .then((response) => response.data);
  },
  getGeneralDocumentsFolder(schoolID, year, includeDeleted) {
    return api
      .get(
        `folder-items/get_general_docs_folder/?school=${schoolID}&year=${year}&deleted=${includeDeleted}`
      )
      .then((response) => response.data);
  },
  createNomina(payload) {
    return api
      .post("file-items/create_nomina/", payload)
      .then((response) => response.data);
  },
  createSchedule(payload) {
    return api
      .post("file-items/create_schedule/", payload)
      .then((response) => response.data);
  },
  reactivateFile(payload) {
    return api
      .post("file-items/reactivate/", payload)
      .then((response) => response.data);
  },
  reactivateFolder(payload) {
    return api
      .post("folder-items/reactivate/", payload)
      .then((response) => response.data);
  },
};
