<template>
  <b-modal
    class="justify-content-center"
    ref="modal"
    hide-header
    :hide-footer="step === 0"
    centered
    size="xl"
  >
    <b-container v-if="step === 0">
      <b-row class="d-block text-center mx-2">
        <span class="font-weight-bold"> Crea un Material Nuevo </span>
      </b-row>
      <b-row class="d-block text-center mx-2 custom-bg-light">
        <div class="text-center">
          <b-img
            :src="icon_new"
            height="100"
            style="cursor: pointer"
            @click="$emit('create-new-material')"
          />
        </div>
      </b-row>
      <b-row class="d-block text-center mx-2">
        <span> o aplica un material ya creado </span>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card
            header="Creados por mi"
            no-body
            class="text-center custom-bg-light font-weight-bold"
          >
            <b-card-body>
              <b-container class="h-scroll">
                <b-row class="m-0 p-0 flex-nowrap">
                  <b-col
                    v-for="material in userMaterials"
                    :key="material.id"
                    cols="3"
                  >
                    <material-card
                      :material="material"
                      mode="appModal"
                      @click.native="$emit('open-preview', material)"
                    >
                    </material-card>
                  </b-col>
                </b-row>
              </b-container>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card
            header="Creados en mi Colegio"
            no-body
            class="text-center custom-bg-light font-weight-bold"
          >
            <b-card-body>
              <b-container class="h-scroll">
                <b-row class="m-0 p-0 flex-nowrap">
                  <b-col
                    v-for="material in schoolMaterials"
                    :key="material.id"
                    cols="3"
                  >
                    <material-card
                      :material="material"
                      mode="appModal"
                      @click.native="$emit('open-preview', material)"
                    >
                    </material-card>
                  </b-col>
                </b-row>
              </b-container>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-if="step === 1" class="select-students">
      <select-students
        @data-selected="createApplication"
        @add-students="addToApplication"
        :selectedApplication="selectedApplication"
        ref="select-students"
      />
    </b-container>

    <template #modal-footer>
      <b-row class="w-100 align-items-start">
        <b-col class="text-right">
          <b-button variant="primary" pill @click="save()">
            {{ selectedApplication ? "Añadir" : "Crear Aplicación" }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import customMaterialService from "@/services/customMaterialService";
import MaterialCard from "../miscellaneous/MaterialCard";
import SelectStudents from "../miscellaneous/SelectStudents";

export default {
  name: "ApplicationModal",
  components: {
    MaterialCard,
    SelectStudents,
  },
  computed: {
    schoolID() {
      return this.$store.state.login.school;
    },
    userID() {
      return this.$store.state.login.user_id;
    },
  },
  data() {
    return {
      userMaterials: [],
      schoolMaterials: [],
      selectedApplication: "",
      icon_new: require("../../../assets/img/material_icons/Icon4.svg"),
      icon_1: require("../../../assets/img/material_icons/Icon1.svg"),
      icon2: require("../../../assets/img/material_icons/Icon2.svg"),
      icon3: require("../../../assets/img/material_icons/Icon3.svg"),
      icon4: require("../../../assets/img/material_icons/Icon4.svg"),
      step: 0,
    };
  },
  mounted() {
    this.retrieveData();
  },
  methods: {
    open(step) {
      this.step = step;
      this.$refs["modal"].show();
    },
    openFromAdmin(selectedApp) {
      this.step = 1;
      this.selectedApplication = selectedApp;
      this.$refs["modal"].show();
    },
    retrieveData() {
      this.getUserMaterials();
      this.getSchoolMaterials();
    },
    getUserMaterials() {
      customMaterialService
        .getUserMaterials(this.userID)
        .then((data) => {
          this.userMaterials = data.materials;
        })
        .catch(() =>
          this.$toasted.error("Ocurrió un problema al traer tus materiales.")
        );
    },
    getSchoolMaterials() {
      customMaterialService
        .getSchoolMaterials(this.schoolID)
        .then((data) => {
          this.schoolMaterials = data;
        })
        .catch(() =>
          this.$toasted.error(
            "Ocurrió un problema al traer materiales de tu colegio."
          )
        );
    },
    createApplication(data) {
      this.$emit("create-application", data);
    },
    addToApplication(data) {
      this.$emit("add-to-application", data);
    },
    changeStep(step) {
      this.step = step;
    },
    close() {
      this.$refs["modal"].hide();
    },
    save() {
      this.$refs["select-students"].save();
    },
  },
};
</script>

<style scoped>
.select-students {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
