<template>
    <b-modal ref="modal" size="xl" hide-footer>
        <b-overlay :show="loading" rounded="sm">
            <b-container>
                <b-row>
                    <b-col class="custom-cols" cols="3">
                        Nombre de template online:
                    </b-col>
                    <b-col>
                        <b-form-input v-model="name">
                        </b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="custom-cols" cols="3">
                        Cursos:
                    </b-col>
                    <b-col>
                        <multiselect
                            v-model="grades"
                            :options="gradesList"
                            :multiple="true"
                            group-values="levels"
                            group-label="label"
                            :group-select="true"
                            placeholder="Cursos"
                            select-label="Seleccionar"
                            deselectLabel="Presiona INTRO para remover"
                            label="name"
                            track-by="name"
                        >
                            <span slot="noOptions">No puedes agregar mas cursos</span>
                            <span slot="noResult">No se ha encontrado ningún curso</span>
                        </multiselect>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="custom-cols" cols="3">
                        Diagnósticos:
                    </b-col>
                    <b-col>
                        <multiselect
                            v-model="diagnostics"
                            :options="diagnosesList"
                            :multiple="true"
                            group-values="diagnoses"
                            group-label="label"
                            :group-select="true"
                            placeholder="Diagnósticos"
                            select-label="Seleccionar"
                            deselectLabel="Presiona INTRO para remover"
                            label="name"
                            track-by="name"
                        >
                            <span slot="noOptions">No puedes agregar mas diagnósticos</span>
                            <span slot="noResult">No se ha encontrado ningún diagnóstico</span>
                        </multiselect>
                    </b-col>
                </b-row>
                <h3>Preguntas</h3>
                <b-alert
                    show
                    dismissible
                    fade
                    variant="danger"
                >   
                    La edición y eliminación de preguntas y respuestas se mostrará en documentos existentes.
                </b-alert>
                <b-card v-for="(q, index) in questions" :key="index" no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle="'accordion-'+index" variant="unknown">Pregunta {{index + 1}}: 
                        {{q.question!==''?q.question:'No definida'}}
                    </b-button>
                    </b-card-header>
                    <b-collapse :id="'accordion-'+index" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-row>
                            <b-col class="custom-cols" cols="3">
                                Pregunta:
                            </b-col>
                            <b-col>
                                <b-form-input v-model="q.question">
                                </b-form-input>
                            </b-col>
                        </b-row>
                        <b-row v-for="(a, aindex) in q.answers" :key="aindex">
                            <b-col class="custom-cols" cols="3">
                                Respuesta {{aindex+1}}:
                            </b-col>
                            <b-col cols="8">
                                <b-form-input v-model="a.answer">
                                </b-form-input>
                            </b-col>
                            <b-col>
                                <b-button variant="danger" pill @click="q.answers.splice(aindex, 1)">
                                    X
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-center">
                                <b-button variant="primary" pill @click="q.answers.push({answer: ''})">
                                    Añadir respuesta
                                </b-button>
                                <b-button variant="danger" pill @click="questions.splice(index, 1)">
                                    Eliminar pregunta
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    </b-collapse>
                </b-card>
                <b-row align-h="center">
                    <b-col class="text-center">
                        <b-button variant="primary" pill @click="questions.push({answers: [], question: ''})">
                            Añadir pregunta
                        </b-button>
                    </b-col>
                    <b-col class="text-center">
                        <b-button variant="info" pill @click="saveChanges()">
                            Guardar Cambios
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>
            <template #overlay>
                <b-container>
                    <b-row class="d-flex justify-content-center">
                        <b-img :src="loadingGif" rounded alt="Loading image"/>
                    </b-row>
                </b-container>
            </template>
        </b-overlay>
    </b-modal>
</template>
<script>
import multiDocumentService from "../../../services/multiDocumentService";
import informationService from "../../../services/informationService";

export default {
    props: {
    },
    data() {
        return {
            loadingGif: require('../../../assets/img/preloader.gif'),
            loading: false,
            name: '',
            grades: [],
            diagnostics: [],
            questions: [],
            id: false,
            document_type_id: '',
            gradesList: [],
            diagnosesList: []
        }
    },
    methods: {
        open(dt_id, ot_id=null) {
            this.getDiagnosesList();
            this.getPieGrades();
            this.id = false;
            this.document_type_id = '';
            this.name = '';
            this.document_type_id = dt_id;
            this.id = ot_id;
            this.grades = [];
            this.diagnostics = [];
            this.questions = [];
            this.loading = false;
            if (ot_id) {
                this.getTemplateOnline();
            }
            this.$refs["modal"].show()
        },
        getDiagnosesList: function() {
            informationService.getDiagnosesList().then(
                data => {
                    this.diagnosesList = data.grouped_diagnoses;
                }
            )
        },
        getPieGrades: function() {
            informationService.getPieGrades().then(
                data => {
                    this.gradesList = data.grades_by_level;
                }
            )
        },
        getTemplateOnline() {
            this.loading = true;
            multiDocumentService.getOnlineTemplateByID(this.id).then(
                data => {
                    this.grades = data.grades;
                    this.diagnostics = data.diagnostics;
                    this.name = data.name;
                    this.questions = data.questions;
                    this.loading = false;
                }
            )
        },
        saveChanges() {
            let payload = {
                grades: this.grades,
                diags: this.diagnostics,
                name: this.name,
                questions: this.questions,
                document_type: this.document_type_id
            }
            if (this.id) {
                payload.document_template_online = this.id;
            }
            this.loading = true;
            multiDocumentService.updateOrCreateOnlineTemplateByID(payload).then(
                data => {
                    this.loading = false;
                    this.$toasted.success(data.message);
                    if (!this.id || !this.document_type_id) {
                        this.$emit('reload');
                    }
                }
            )
        }
    }
}
</script>
<style lang="scss">

</style>