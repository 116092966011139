<template>
  <b-card no-body style="height: 100%" class="mx-auto pt-2">
    <ApplicationModal
      ref="application-modal"
      :applicationId="selectedApplication"
      @create-new-material="$emit('create-new-material')"
      @add-to-application="addToApplication"
    />
    <b-container class="p-0">
      <b-row class="m-0 p-0 mt-2">
        <b-col class="text-left m-0 p-0">
          <b-button
            variant="danger"
            @click="
              $emit('back-to-list', 'openApp');
              leaveApplication();
            "
            pill
          >
            <v-icon name="arrow-left" /> Volver
          </b-button>
        </b-col>
        <b-col class="text-center" v-if="material">
          <strong>
            {{ material.name }}
          </strong>
          <b-button
            size="sm"
            variant="primary"
            id="tooltip-question"
            pill
            class="px-3"
            @click="showAlert = !showAlert"
          >
            <strong>?</strong>
          </b-button>
          <b-button
            v-if="!application.is_active"
            variant="success"
            pill
            size="sm"
            class="mx-2 px-3"
            @click="restoreApplication"
          >
            <strong>Restaurar</strong>
          </b-button>
          <b-tooltip target="tooltip-question" triggers="hover">
            ¿Cómo se aplican los materiales?
          </b-tooltip>
        </b-col>
        <b-col class="text-right m-0 p-0">
          <b-button
            v-if="application.is_active"
            variant="secondary"
            pill
            class="px-3"
            @click="openApplicationModal"
          >
            <v-icon name="plus" /> Añadir Estudiantes
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-alert
          :show="showAlert"
          dismissible
          @dismissed="showAlert = false"
          variant="warning"
        >
          Para aplicar el material cada estudiante debe ingresar a www.utopie.cl
          e incorporar su rut en el banner ubicado en el encabezado de la
          página. Otra alternativa es que des un click en el botón de
          "aplicación", copies el link de la aplicación del material y se lo
          envíes al estudiante.
        </b-alert>
      </b-row>
      <b-row class="w-100 m-0">
        <b-col class="text-center py-5" v-if="loadingLevels">
          <b-spinner variant="primary" label="Spinning" />
        </b-col>

        <b-col
          class="text-center py-5"
          v-else-if="levels.length == 0 && !loadingLevels"
        >
          No se han agregado estudiantes a esta aplicación
        </b-col>
        <div
          class="accordion w-100"
          role="tablist"
          v-if="levels.length && !loadingLevels"
        >
          <b-card
            no-body
            class="mb-1 w-100"
            v-for="(level, lIndex) in levels"
            :key="lIndex + '-level'"
          >
            <b-card-header header-tag="header" class="p-1 m-2" role="tab">
              <b-button
                block
                v-b-toggle="'accordion-' + lIndex"
                variant="unknown"
                class="text-left"
                >{{ level.label }}</b-button
              >
            </b-card-header>
            <b-collapse
              :id="'accordion-' + lIndex"
              accordion="my-accordion"
              role="tabpanel"
              @show="!loadedLevels[level.code] ? load(level.code) : ''"
            >
              <b-container v-if="!loadedLevels[level.code]">
                <b-row>
                  <b-col class="text-center">
                    <b-spinner />
                  </b-col>
                </b-row>
              </b-container>
              <b-container v-else class="m-0 p-0">
                <div
                  v-for="(grade, gIndex) in loadedLevels[level.code]"
                  :key="gIndex + '-grade'"
                >
                  <span
                    style="
                      width: 100%;
                      background-color: #d3d3d3;
                      display: block;
                    "
                    class="text-left px-3"
                  >
                    <strong>{{ grade.name }}</strong>
                  </span>
                  <div>
                    <b-container>
                      <b-row>
                        <b-col
                          cols="3"
                          style="font-size: 11px"
                          class="text-center"
                        >
                          <strong>Estudiante</strong>
                        </b-col>
                        <b-col
                          cols="1"
                          style="font-size: 11px"
                          class="text-center"
                        >
                          <strong>Aplicación</strong>
                        </b-col>

                        <b-col
                          cols="2"
                          style="font-size: 11px"
                          class="text-center"
                        >
                          <strong>Porcentaje de Logro</strong>
                        </b-col>
                        <b-col
                          cols="2"
                          style="font-size: 11px"
                          class="text-center"
                        >
                          <strong>Logrado</strong>
                        </b-col>
                        <b-col
                          cols="2"
                          style="font-size: 11px"
                          class="text-center"
                        >
                          <strong>Parcialmente Logrado</strong>
                        </b-col>
                        <b-col
                          cols="2"
                          style="font-size: 11px"
                          class="text-center"
                        >
                          <strong>No Logrado</strong>
                        </b-col>
                      </b-row>

                      <StudentResponseRow
                        v-for="(student, sIndex) in grade.students"
                        :student="student"
                        :level="level"
                        :grade="grade"
                        :key="sIndex + '-student'"
                        @response-admin="review"
                      />
                    </b-container>
                  </div>
                </div>
              </b-container>
            </b-collapse>
          </b-card>
        </div>
      </b-row>
    </b-container>
  </b-card>
</template>
<script>
import customMaterialService from "@/services/customMaterialService";
import ApplicationModal from "../modals/ApplicationModal";
// import materialDocumentSocket from "@/mixins/materialDocumentSocket";
import StudentResponseRow from "./StudentResponseRow";
export default {
  name: "MaterialApplicationsAdmin",
  components: {
    ApplicationModal,
    StudentResponseRow,
  },
  props: {
    selectedApplication: {
      type: String,
    },
    application: {
      type: Object,
    },
  },
  // mixins: [materialDocumentSocket],
  mounted() {
    customMaterialService
      .getApplicationDetails(this.selectedApplication)
      .then((data) => {
        this.levels = data.levels;
        this.material = data.material;
        this.loadingLevels = false;
      });
  },
  data() {
    return {
      levels: [],
      material: null,
      loadedLevels: {},
      showAlert: false,
      loadingLevels: true,
    };
  },
  methods: {
    restoreApplication() {
      customMaterialService
        .restoreApplication({ id: this.application.id })
        .then((data) => {
          this.$toasted.success(data.message);
        });
    },
    closeLink(materialLink) {
      customMaterialService
        .closeMaterial({ invite: materialLink.id })
        .then((data) => {
          materialLink.finished = true;
          this.$toasted.success(data.message);
        });
    },
    closeAndReview(materialLink) {
      customMaterialService
        .closeMaterial({ invite: materialLink.id })
        .then((data) => {
          materialLink.finished = true;
          this.$toasted.success(data.message);
          this.review(materialLink);
        });
    },
    review(materialLink, levelCode, grade) {
      this.$emit("response-admin", materialLink, this.levels, levelCode, grade);
      // this.$emit("review", materialLink);
    },
    copyLink(materialLink) {
      let testURL = `${process.env["VUE_APP_BASE_URL"]}/materials#/t/${materialLink.id}`;
      if (navigator.clipboard) {
        navigator.clipboard.writeText(testURL);
      } else if (window.clipboardData) {
        window.clipboardData.setData("url", testURL);
      } else {
        this.$toasted.error("No se pudo copiar");
        return;
      }
      this.$toasted.success("Link copiado en el portapapeles");
    },
    getTitle(materialLink) {
      if (materialLink.started) {
        if (!materialLink.finished) {
          return "NO TERMINADO";
        } else if (materialLink.finished && !materialLink.reviewed) {
          return "NO REVISADO";
        } else {
          return "REVISADO";
        }
      } else {
        return "SIN COMENZAR";
      }
    },
    load(level) {
      this.$set(this.loadedLevels, level, null);
      customMaterialService
        .getLevelLinks(this.selectedApplication, level)
        .then((data) => {
          this.$set(this.loadedLevels, level, data.grades);
        });
      // if (!this.loadedLevels[level]) {
      //     customMaterialService.getLevelLinks(this.selectedApplication, level).then(
      //         data => {
      //             this.$set(this.loadedLevels, level, data.grades);
      //         }
      //     )
      // }
    },
    getValueClasses(materialLink) {
      if (materialLink.started) {
        if (!materialLink.finished) {
          return [
            "text-primary",
            "text-ultralight-hover",
            "bg-primary-hover",
            "border-primary",
          ];
        } else if (materialLink.finished && !materialLink.reviewed) {
          return [
            "text-secondary",
            "text-ultralight-hover",
            "bg-secondary-hover",
            "border-secondary",
          ];
        } else {
          return [
            "text-tertiary",
            "text-ultralight-hover",
            "bg-tertiary-hover",
            "border-tertiary",
          ];
        }
      } else {
        return [
          "text-redpie",
          "text-ultralight-hover",
          "bg-redpie-hover",
          "border-redpie",
        ];
      }
    },
    getVariant(materialLink) {
      if (materialLink.started) {
        if (!materialLink.finished) {
          return "outline-primary";
        } else if (materialLink.finished && !materialLink.reviewed) {
          return "outline-info";
        } else {
          return "outline-success";
        }
      } else {
        return "outline-danger";
      }
    },
    getValueIcon(materialLink) {
      if (materialLink.started) {
        if (!materialLink.finished) {
          return "minus";
        } else if (materialLink.finished && !materialLink.reviewed) {
          return "check";
        } else {
          return "folder-open";
        }
      } else {
        return "times";
      }
    },
    openApplicationModal() {
      this.$refs["application-modal"].openFromAdmin(this.selectedApplication);
    },
    addToApplication(data) {
      var payload = {
        data: data,
        year: this.$store.state.login.year,
        school: this.$store.state.login.school,
        material: this.material.id,
      };
      customMaterialService
        .addStudentsApplication(this.selectedApplication, payload)
        .then(() => {
          this.$toasted.success("Estudiantes agregados!");
          customMaterialService
            .getApplicationDetails(this.selectedApplication)
            .then((data) => {
              this.levels = data.levels;
              this.material = data.material;
            });
          this.$refs["application-modal"].close();
        });
    },
  },
};
</script>
<style>
.value-container {
  cursor: pointer;
  margin: 0 auto;
  width: 56px;
  height: 24px;
  background-color: white;
  border: solid 1px;
  border-radius: 16px;
  font-size: 0.5em !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.icon {
  margin-left: 2px;
  margin-right: 2px;
}
</style>
