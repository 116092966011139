<template>
  <b-modal
    centered
    ref="incomponent-modal-add-email"
    hide-footer
    hide-header
    size="xl"
  >
    <profile-change-modal ref="prof-modal"/>
    <b-overlay :show="loading">
      <b-container>
        <b-row>
          <b-col class="text-center">
            <h4>Añade personas a tu equipo PIE</h4>
          </b-col>
        </b-row>
        <b-form @submit.prevent="addPerson">
          <b-row>
            <b-col cols="5">
              <b-form-group label="Correo Electrónico *" label-size="sm">
                <b-input v-model="email" placeholder="ejemplo@correo.cl" type="email" size="sm" required/>
              </b-form-group>
            </b-col>
            <b-col cols="5">
              <b-form-group label="Rol *" label-size="sm">
                <b-select size="sm" v-model="role" required>
                  <b-select-option value="">Selecciona un Cargo</b-select-option>
                  <b-select-option
                    v-for="(role, index) in roles"
                    :key="index+'-role'"
                    :value="role[0]">{{role[1]}}</b-select-option>
                </b-select>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-button variant="primary" type="submit" pill class="mt-3">
                Agregar <v-icon name="plus"/>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <b-row v-if="addedUsers.length > 0">
          <b-table :items="addedUsers" :fields="addedFields" small>
            <template v-slot:cell(eliminar)="data">
              <b-button pill variant="danger" size="sm" @click="addedUsers.splice(data.index, 1)">
                Eliminar
              </b-button>
            </template>
          </b-table>
          <b-row align-h="end" class="w-100">
            <b-col cols="12">
              <b-button variant="primary" pill block @click="submitUsers">
                <b>Guardar y Generar Excel <v-icon name="file-excel"/></b>
              </b-button>
            </b-col>
          </b-row>
        </b-row>
  <!--      <b-row v-if="invites.length > 0">
          <b-card border-variant="warning" class="w-75 mx-auto text-center">
            <h6><b>Solicitudes Pendientes</b></h6>
            <b-table :items="invites" :fields="['email', 'aceptar', 'eliminar']" borderless small>
              <template v-slot:cell(email)="data">
                {{data.item.user.email}}
              </template>
              <template v-slot:cell(aceptar)="data">
                <b-button variant="primary" size="sm" pill @click="accept(data.item.id, data.index)">Aceptar</b-button>
              </template>
              <template v-slot:cell(eliminar)="data">
                <b-button variant="danger" size="sm" pill @click="revoke(data.item.id, data.index)">Eliminar</b-button>
              </template>
            </b-table>
          </b-card>
        </b-row>-->
        <b-row class="p-2">
          <h5 class="text-left">Usuarios activos del colegio: </h5>
          <b-table small :fields="fields" :items="users">
            <template v-slot:cell(nombre)="data">
              {{data.item.user.full_name}}
            </template>
            <template v-slot:cell(cargo)="data">
              {{data.item.user.role}}
            </template>
            <template v-slot:cell(correo)="data">
              {{data.item.user.email}}
            </template>
            <template v-slot:cell(permisos)="data">
              <b-select :disabled="$store.state.login.schoolLevel < 2 || data.item.user.email === $store.state.login.email"
                        @change="levelChanged(data.item)"
                        v-model="data.item.level" class="mb-0" size="sm">
                  <b-select-option :value="0">Solo ver</b-select-option>
                  <b-select-option :value="1">Editar</b-select-option>
                  <b-select-option :value="2">Administrador</b-select-option>
                </b-select>
            </template>
            <template v-slot:cell(editar)="data">
              <b-button variant="primary"
                  :disabled="!(data.item.user.email === $store.state.login.email || $store.state.login.schoolLevel > 1)"
                  @click="openProfileModal(data.item.user.id)"
                  pill>Editar</b-button>
            </template>
            <template v-slot:cell(eliminar)="data">
              <b-button variant="danger"
                  :disabled="!(data.item.user.email === $store.state.login.email || $store.state.login.schoolLevel > 1)"
                  @click="removePerson({user: data.item.user.id, school: $store.state.login.school})"
                  pill>Eliminar</b-button>
            </template>
          </b-table>
        </b-row>
      </b-container>
      <template #overlay>
        <b-container>
          <b-row class="d-flex justify-content-center">
            <b-img :src="loadingGif" rounded alt="Loading image"/>
          </b-row>
        </b-container>
      </template>
    </b-overlay>
  </b-modal>
</template>
<script>
import redpieService from "../../../services/redpieService";
import informationService from "@/services/informationService";
import ProfileChangeModal from "./ProfileChangeModal";

export default {
  components: {
    ProfileChangeModal
  },
  data () {
    return {
      loadingGif: require('../../../assets/img/preloader.gif'),
      loading: false,
      email: "",
      profession: "",
      schoolID: null,
      addedUsers: [],
      users: [],
      fields: [
        'nombre', 'cargo', 'correo', 'permisos', 'editar', 'eliminar'
      ],
      addedFields: [
        {key: 'email', label: 'Correo Electronico'},
        {key: 'role', label: 'Cargo', formatter: this.getRole},
          'Eliminar'
      ],
      role: '',
      roles: []
    }
  },
  mounted() {
    if (this.$route.query.invite==='t') {
      this.open(this.$store.state.login.school)
    }
  } ,
  methods: {
    getRole(value) {
      var r = this.roles.filter(role => role[0] === value); 
      return r[0][1]
    },
    open(schoolID) {
      this.schoolID = schoolID;
      this.getSchoolUsers();
      this.$refs["incomponent-modal-add-email"].show();
    },
    submitUsers() {
      let payload = {
        users: this.addedUsers,
        school: this.schoolID
      }
      redpieService.createBulkUsers(payload).then(
          data => {
            // this.users.push(this.addedUsers)
            this.getSchoolUsers();
            this.addedUsers = []
            const link = document.createElement('a')
            link.href = data.excel
            link.setAttribute('download', 'usuarios.xlsx')
            document.body.appendChild(link)
            link.click()
          }
      )
    },
    levelChanged(user) {
      redpieService.updateSchoolUserLevel({
        level: user.level, school: user.school, email: user.user.email
      }).then(
        data => {
          this.$toasted.success(data.message);
        }
      )
    },
    getSchoolUsers() {
      this.loading = true;
      redpieService.getSchoolUsers(this.$store.state.login.school).then(
        data => {
          this.users = data.users;
          this.loading = false;
        }
      )
      informationService.getRoles().then(
          data => this.roles = Object.entries(data.roles)
      )
      // redpieService.getPendingLinkInvites(this.$store.state.login.school).then(
      //    data => this.invites = data
      // );
    },
    addPerson() {
      if (this.$store.state.login.token) {
        this.addedUsers.push({
          email: this.email,
          role: this.role
        })
        this.email = ''
        this.role = ''
      }
      else {
        this.$toasted.info('Regístrate para usar esto.')
      }
    },
    openProfileModal(id) {
      this.$refs["prof-modal"].open(id);
    },
    removePerson(payload) {
      redpieService.removeSchoolUser(payload).then(
        data => {
          this.$toasted.success(data.message);
          this.getSchoolUsers().then(
            () => {
              if (this.users.filter(s_user => {return s_user.user.email === this.$store.state.login.email}).length === 0) {
                this.$toasted.error('Te han removido de este colegio.')
                window.location.href = '/utopie#/'
              }
            }
          )
        }
      )
    },
    // accept(id, idx) {
    //   redpieService.acceptLinkInvite(id).then(()=>this.invites.splice(idx, 1))
    // },
    // revoke(id, idx) {
    //   redpieService.revokeLinkInvite(id).then(()=>this.invites.splice(idx, 1))
    // }
  }
}
</script>
<style>
</style>
