<template>
    <b-modal hide-footer ref="rubric-modal" size="xl">
        <b-container>
            <b-row v-if="answer">
                <b-button pill variant="primary" size="sm" @click="visible=!visible">
                    {{visible? 'Ocultar Pregunta':'Ver Pregunta'}}
                </b-button>
                <b-collapse v-model="visible" class="w-100">
                    <b-col cols="12" class="my-1 text-left">
                        <h5>Pregunta #{{answer.question.index+1}}: {{answer.question.question}}</h5>
                    </b-col>
                    <b-row class="w-100 p-4">
                        <b-col class="my-1 text-left">
                            <div class="d-inline">{{ answer.question.kind===0? 'Alternativa Seleccionada' : 'Respuesta' }}: </div>
                            <div class="d-inline" v-if="answer.question.kind===0">
                                <div v-if="answer.alternative">
                                    <div class="d-inline-block">
                                        <span v-if="!answer.alternative.is_formula">
                                            {{answer.alternative.text}}
                                        </span>
                                        <math-field 
                                            id="formula" 
                                            v-else
                                            :read-only="true"
                                            virtual-keyboard-mode="manual"
                                        >
                                            {{answer.alternative.text}}
                                        </math-field>
                                    </div>
                                    <v-icon v-if="answer.alternative && answer.alternative.is_correct" name="check" class="text-success d-inline"/>
                                    <v-icon v-else name="times" class="text-danger d-inline"/>
                                </div>
                                <div v-else class="ml-3">
                                    Pregunta omitida
                                </div>
                            </div>
                            <div v-else-if="answer.question.kind===1">
                                <div v-if="answer.question.response_type===1 && answer.text_response">{{answer.text_response}}</div>
                                <div v-else-if="answer.question.response_type===2 && answer.image_response">
                                    <b-img :src="answer.image_response"/>
                                </div>
                                <div v-else-if="answer.question.response_type===3 && answer.audio_response">
                                    <audio controls :src="answer.audio_response" preload="auto"></audio>
                                </div>
                                <div v-else-if="answer.question.response_type===4 && answer.letter_response" class="w-75 mx-auto">
                                    <div v-if="answer.letter_response">
                                        <b-row align-h="end"><span>{{answer.letter_response.city_date}}</span></b-row>
                                        <b-row align-h="start"><span>{{answer.letter_response.greeting}}</span></b-row>
                                        <b-row align-h="start"><span class="text-justify">{{answer.letter_response.body}}</span></b-row>
                                        <b-row align-h="start"><span>{{answer.letter_response.goodbye}}</span></b-row>
                                        <b-row align-h="center"><span>{{answer.letter_response.signature}}</span></b-row>
                                        <b-row align-h="start"><span>{{answer.letter_response.postdata}}</span></b-row>
                                    </div>
                                </div>
                                <div v-else-if="answer.question.response_type===5 && answer.news_response" class="w-75 mx-auto">
                                    <div v-if="answer.news_response">
                                        <b-row align-h="start"><span>{{answer.news_response.epigraph}}</span></b-row>
                                        <b-row align-h="start"><span class="h5">{{answer.news_response.title}}</span></b-row>
                                        <b-row align-h="start"><span class="h6">{{answer.news_response.subtitle}}</span></b-row>
                                        <b-row align-h="start"><span class="text-justify">{{answer.news_response.lead}}</span></b-row>
                                        <b-row align-h="start"><span class="text-justify">{{answer.news_response.news_body}}</span></b-row>
                                    </div>
                                </div>
                                <div v-else>
                                    Pregunta omitida
                                </div>
                            </div>
                            <div v-else-if="answer.question.kind===2">
                                <ol>
                                    <li v-for="(txt, txtidx) in answer.dictation_responses"
                                        :class="isCorrectDictation(answer, txtidx)? 'text-success' :
                                        isCorrectDictation(answer, txtidx)===false? 'text-danger' : 'text-info'"
                                        :key="`li-${answer.question.index}-${txtidx}`">
                                    {{txt}} <v-icon :name="isCorrectDictation(answer, txtidx)? 'check' :
                                    isCorrectDictation(answer, txtidx)===null? 'question' : 'times'"/>
                                    </li>
                                </ol>
                            </div>
                            <div v-else-if="answer.question.kind===3">
                                <b-row class="mx-1">
                                    <b-button v-for="(option_resp, optindex) in answer.option_responses"
                                            :key="`btn-${answer.question.index}-${option_resp.option.id}`"
                                            :variant="option_resp.option.id===answer.question.options[optindex].id? 'success' : 'danger'" class="m-2"
                                    >
                                    <b-img v-if="option_resp.option.image" :src="option_resp.option.image" class="btn-image" fluid thumbnail/>
                                    <span class="w-100 d-block" v-if="!option_resp.option.is_formula">{{ option_resp.option.text }}</span>
                                    <math-field 
                                        class="d-block"
                                        id="formula"
                                        v-else 
                                        :read-only="true"
                                        virtual-keyboard-mode="manual"
                                    >
                                        {{ option_resp.option.text }}
                                    </math-field>
                                    </b-button>
                                </b-row>
                                <b-row><span class="mx-3">Respuesta correcta:</span></b-row>
                                <b-row class="mx-1">
                                    <b-button v-for="option in answer.question.options.filter(o=>o.correct)"
                                            :key="`btn2-${answer.question.index}-${option.id}`"
                                            variant="success" class="m-2"
                                    ><b-img v-if="option.image" :src="option.image" class="btn-image" fluid thumbnail/>
                                    <span class="w-100 d-block" v-if="!option.is_formula">{{ option.text }}</span>
                                    <math-field 
                                        class="d-block"
                                        id="formula"
                                        v-else 
                                        :read-only="true"
                                        virtual-keyboard-mode="manual"
                                    >
                                        {{option.text}}
                                    </math-field>
                                    </b-button>
                                </b-row>
                            </div>
                            <div v-else-if="answer.question.kind===4">
                                <b-row v-for="(exrep, exindex) of answer.exercise_responses" :key="`exrep-${exrep.id}`">
                                    <b-col cols="6">{{answer.question.exercises[exindex].text}}</b-col>
                                    <b-col cols="6" v-if="answer.question.exercises[exindex].type<3">
                                    <div v-if="exrep.alternative" class="d-inline-block">
                                        <span v-if="!exrep.alternative.is_formula">
                                        {{exrep.alternative.text}}
                                        </span>
                                        <math-field 
                                        v-else
                                        id="formula" 
                                        :read-only="true"
                                        virtual-keyboard-mode="manual"
                                        >
                                        {{exrep.alternative.text}}
                                        </math-field>
                                    </div>
                                    <div v-else class="d-inline-block">
                                        {{exrep.text}}
                                    </div>
                                    <v-icon v-if="exrep.alternative"
                                            :class="exrep.alternative.correct? 'text-success' : 'text-danger'"
                                            :name="exrep.alternative.correct? 'check' : 'times'"/>
                                    <v-icon v-else :class="isCorrectExercise(answer, exindex)? 'text-success'
                                                            : isCorrectExercise(answer, exindex)===null? 'text-info' : 'text-danger'"
                                                    :name="isCorrectExercise(answer, exindex)? 'check'
                                                            : isCorrectExercise(answer, exindex)===null? 'question' : 'times'"/>
                                    </b-col>
                                    <b-col v-else-if="answer.question.exercises[exindex].type===3 && exrep.exercise_responses_files">
                                    <div v-for="(file, findex) in exrep.exercise_responses_files" :key="'f-'+findex">
                                        <audio controls class="mx-auto w-100" preload="auto">
                                        <source :src="file.file">
                                        Tu navegador no soporta la reproducción de audio
                                        </audio>
                                    </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                    </b-row>
                </b-collapse>
            </b-row>
            <b-table-simple responsive bordered hover fixed>
                <b-thead head-variant="light">
                    <b-tr>
                        <b-th :rowspan="2" style="width: 300px;">Indicadores</b-th>
                        <b-th :colspan="levels.length" :style="'width: '+(levels.length*300)+'px;'">Niveles de logro</b-th>
                    </b-tr>
                    <b-tr>
                        <b-th v-for="(level, lIndex) in levels" :key="lIndex+'-level'" style="width: 300px;">
                            <span>{{level.name}}</span>
                        </b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="(indicator, iIndex) in indicators" :key="iIndex+'-indicator'">
                        <b-td>
                            <span>{{indicator.name}}</span>
                        </b-td>
                        <b-td v-for="(level, lIndex) in levels" :key="lIndex+'-level'"
                                :variant="
                                    chosen.filter(a => a.indicator_id === indicator.id).length && 
                                    chosen.filter(a => a.indicator_id === indicator.id)[0].level_id === level.id? 
                                        'success':''"
                                @click="indicatorSelected(level.id, indicator.id, indicator.level_scores[lIndex])">
                            <span>{{indicator.level_indications[lIndex]}}</span>
                            <br>
                            <strong style="background-color: #F0F8FF" class="w-100">Puntaje: {{indicator.level_scores[lIndex]}}</strong>
                        </b-td>
                    </b-tr>
                    <b-tr>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            Puntaje acumulado: {{score}}
            <b-row>
                <b-col class="text-right">
                    <b-button pill variant="primary" @click="saveAndClose">
                        Guardar
                    </b-button>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>
<script>
export default {
    data() {
        return {
            levels: [],
            indicators: [],
            chosen: [],
            answerID: "",
            answer: null,
            visible: true
        }
    },
    methods: {
        open(rubric, chosenIndicators, answerID, answer) {
            this.levels = rubric.levels;
            this.indicators = rubric.indicators;
            this.answerID = answerID;
            this.chosen = chosenIndicators;
            if (answer) {
                this.answer = answer
            }
            this.$refs['rubric-modal'].show();
        },
        indicatorSelected(levelID, indicatorID, score) {
            if (this.chosen.filter(a => a.indicator_id === indicatorID).length) {
                if (this.chosen.filter(a => a.indicator_id === indicatorID)[0].level_id === levelID) {
                    this.chosen.filter(a => a.indicator_id === indicatorID)[0].level_id = null
                    this.chosen.filter(a => a.indicator_id === indicatorID)[0].score = 0
                }
                else {
                    this.chosen.filter(a => a.indicator_id === indicatorID)[0].level_id = levelID
                    this.chosen.filter(a => a.indicator_id === indicatorID)[0].score = score
                }
            }
            else {
                this.chosen.push({indicator_id: indicatorID, level_id: levelID, score: score})
            }
        },
        saveAndClose() {
            this.$emit('set-indicators', this.chosen, this.answerID, this.score);
            this.chosen = []
            this.$refs['rubric-modal'].hide();
        }
    },
    computed: {
        score() {
            var sc = 0;
            this.chosen.forEach(
                chose => {
                    sc += chose.score
                }
            )
            return sc
        }
    }
}
</script>
<style lang="scss">

</style>