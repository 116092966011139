<template>
  <b-container fluid style="background-color: white">
    <div class="text-center mx-auto p-4 mt-4">
      <span class="h4 text-warning font-weight-bold mt-4">¡Escoge el mejor plan para ti!</span>
      <b-row class="mt-4">
        <b-col :lg="12/plans.length" class="p-4" v-for="(plan, i) of plans" :key="plan.id" :class="{active: selected===i}">
          <b-card body-bg-variant="light" :header-bg-variant="plan.color" style="cursor:pointer;" @click="selected=i">
            <template v-slot:header>
                <h5 class="my-1 text-light text-uppercase font-weight-bold" >{{plan.name}}</h5>
            </template>
            <b-card-body class="p-0">
              <b-card-title class="h1 font-weight-bold" :class="`text-${plan.color}`">{{plan.price}}</b-card-title>
              <b-card-sub-title class="h6 text-dark">{{plan.period}}</b-card-sub-title>
              <hr :class="`border-${plan.color}`">
              <b-table-simple class="text-left text-muted p-1 rounded" borderless responsive>
                <b-tr v-for="(Sperk, index) in plan.specialPerks" :key="index+'-sperk'">
                  <b-td class="align-middle" :class="`text-${plan.color}`"><v-icon name="check"></v-icon></b-td>
                  <b-td class="text-muted"><div v-html="Sperk"></div></b-td>
                </b-tr>
                <b-tr v-for="(perk, index) in plan.perks" :key="index+'-perk'">
                  <b-td class="align-middle" :class="`text-${plan.color}`"><v-icon name="check"></v-icon></b-td>
                  <b-td class="text-muted">{{perk}}</b-td>
                </b-tr>
              </b-table-simple>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <b-button variant="primary" pill>Comprar!</b-button>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "Pricing",
  props: {
    route: {
      type: String,
      required: false
    }
  },
  data(){
    return {
      selected: -1,
      plans: [
        {
          id: 1,
          name: 'Facturación Anual',
          price: 'CLP $30.000 anual por estudiante',
          period: '',
          color: 'danger',
          perks: [
              'Capacitación al equipo sobre el uso de la plataforma.',
              'Teléfonos de contacto y servicio post-venta para solucionar dudas.',
              'Carga de todos los datos del colegio, cursos, estudiantes, usuarios y plantillas de documentos.',
              'Nuevas funcionalides y actualizaciones periódicas'
          ],
          specialPerks: [
            '<span style="font-size: 20px;">CLP $30.000 anual por estudiante ingresado a PIE/Escuela especial <strong>(con un monto tope a pagar de $3.000.000 anuales, sobre 100 estudiantes seguirá pagando lo mismo)</strong>.</span>',
          ]
        },
        // {
        //   id: 1,
        //   name: 'Facturación Mensual',
        //   price: 'CLP $2400 mensual por estudiante',
        //   period: ' ',
        //   color: 'info',
        //   perks: [
        //       'Capacitación al equipo sobre el uso de la plataforma.',
        //       'Teléfonos de contacto y servicio post-venta para solucionar dudas.',
        //       'Carga de todos los datos del colegio, cursos, estudiantes, usuarios y plantillas de documentos.',
        //       'Nuevas funcionalides y actualizaciones periódicas'
        //   ],
        //   specialPerks: [
        //       '<span style="font-size: 20px;">CLP $2400 mensual por estudiante ingresado a PIE/Escuela Especial. <strong>Sin Tope</strong>.</span>',
        //   ]
        // },
        // {
        //   id: 2,
        //   name: 'Plan Plata',
        //   price: '$70.000',
        //   period: 'mensuales',
        //   color: 'warning',
        //   perks: [
        //       'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed sit',
        //       'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed sit',
        //       'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed sit',
        //       'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed sit',
        //   ],
        // },{
        //   id: 3,
        //   name: 'Plan Oro',
        //   price: '$700.000',
        //   period: 'anual',
        //   color: 'info',
        //   perks: [
        //       'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed sit',
        //       'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed sit',
        //       'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed sit',
        //       'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed sit',
        //   ],
        // }
      ]
    }
  },
}
</script>

<style scoped>
.active {
  border: #e0a800 3px solid;
  border-radius: 10px;
}
</style>