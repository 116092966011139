<template>
    <svg version="1.1" id="Layer_1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    :viewBox="viewbox"
    :width="width"
    :height="height"
    xml:space="preserve"
    class="custom-icon-button">

        <g>
            <circle class="st0-rubric" cx="43" cy="43" r="43"/>
            <path class="st1-rubric" d="M23.1,40.1c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2h27.5c1.2,0,2.2,1,2.2,2.2c0,1.2-1,2.2-2.2,2.2H23.1z"/>
            <path class="st1-rubric" d="M23.1,50.3c-1.2,0-2.2-1-2.2-2.2s1-2.2,2.2-2.2h27.5c1.2,0,2.2,1,2.2,2.2s-1,2.2-2.2,2.2H23.1z"/>
            <path class="st1-rubric" d="M23.1,29.8c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2h27.5c1.2,0,2.2,1,2.2,2.2c0,1.2-1,2.2-2.2,2.2H23.1z"/>
            <path class="st1-rubric" d="M56.9,27.5c0-2.3,1.8-4.1,4.1-4.1s4.1,1.8,4.1,4.1s-1.8,4.1-4.1,4.1S56.9,29.8,56.9,27.5"/>
            <path class="st1-rubric" d="M56.9,37.9c0-2.3,1.8-4.1,4.1-4.1s4.1,1.8,4.1,4.1S63.2,42,61,42S56.9,40.1,56.9,37.9"/>
            <path class="st1-rubric" d="M56.9,48.2c0-2.3,1.8-4.1,4.1-4.1s4.1,1.8,4.1,4.1s-1.8,4.1-4.1,4.1S56.9,50.4,56.9,48.2"/>
            <path class="st1-rubric" d="M56.9,58.4c0-2.3,1.8-4.1,4.1-4.1s4.1,1.8,4.1,4.1s-1.8,4.1-4.1,4.1S56.9,60.7,56.9,58.4"/>
            <path class="st1-rubric" d="M23.1,60.6c-1.2,0-2.2-1-2.2-2.2s1-2.2,2.2-2.2h27.5c1.2,0,2.2,1,2.2,2.2s-1,2.2-2.2,2.2H23.1z"/>
        </g>

  

  </svg>
  </template>
  
  <script>
  export default {
      name: 'RubricIcon',
      props: {
        viewbox: {
              type: String,
              default: "0 0 18 18"
        },
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },

    },
   
  }
  </script>
  
<style>
.st0-rubric{
    fill:#E84855;
}
.st1-rubric{
    fill:#FFFFFF;
}
.custom-icon-button{
    cursor:pointer;
}
</style>