<template>
  <b-row :class="material.is_active ? '' : 'recycle'" @mouseover="showPen=true" @mouseleave="showPen=false">
    <b-col cols="1" class="mx-2">
      <b-badge
        @click="$emit('open-material')"
        style="cursor: pointer"
        variant="primary"
        >Ver</b-badge
      >
    </b-col>
    <b-col>
      <div v-if="nameMode==='view'" @click="$emit('open-material')" class="text-hover">
        {{ material.name }}
      </div>
      <b-input type="text" v-model="material.name" v-if="nameMode==='edit'"/>
    </b-col>
    <b-col cols="1">
      <b-badge
        @click="nameMode='edit'"
        style="cursor: pointer"
        variant="primary"
        v-if="nameMode==='view' && showPen"
      >
        <v-icon name="pencil-alt"/>
      </b-badge>
      <b-badge
        @click="saveName"
        style="cursor: pointer"
        variant="success"
        v-if="nameMode==='edit'"
      >
        <v-icon name="save"/>
      </b-badge>
    </b-col>
    <b-col v-if="material.is_active && mode === 'material-list'" cols="2">
      <b-badge
        @click="$emit('apply-material')"
        style="cursor: pointer"
        variant="info"
        >Aplicar</b-badge
      >
    </b-col>
    <b-col
      v-if="mode === 'material-list' && material.is_active && paperBin"
      cols="1"
      class="text-center"
    >
      <b-button
        variant="unknown"
        size="sm"
        class="mx-auto"
        v-b-tooltip.hover
        title="Eliminar material"
        @click="$emit('delete-material')"
      >
        <v-icon name="trash-alt" class="text-danger" />
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
import customMaterialService from '../../../services/customMaterialService';

export default {
  name: "MaterialRowComponent",
  props: {
    material: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: false,
    },
    paperBin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showCompleteText: true,
      nameMode: 'view',
      showPen: false,
    };
  },
  methods: {
    saveName() {
      customMaterialService.changeName({id: this.material.id, name: this.material.name}).then(
        (data) => {
          this.$toasted.success(data.message)
          this.nameMode = 'view';
        }
      )
    }
  }
};
</script>
<style scoped>
.recycle {
  opacity: 0.5;
}

.text-hover:hover {
  opacity: 0.6;
  cursor: pointer;
  transition: 0.1s;
}
</style>
