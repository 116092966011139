<template>
    <b-container class="w-100 h-100">
        <b-card no-body v-if="selectedTag && selectedTag.tags && selectedTag.tags.length" class="h-100">
            <b-tabs pills vertical v-model="cosa" class="h-100">
                <b-tab v-for="(tag, index) in selectedTag.tags" :key="index" class="h-100"  @click="clickedTag=tag" lazy>
                    <template #title>
                        {{ tag.name }}
                        <v-icon class="icon-right" name="caret-right" />
                    </template>
                    <IndividualTagComponent :selectedTag="clickedTag" :selectedSubtags="selectedSubtags"
                        @subtag-clicked="subtagClicked" class="h-100"/>
                </b-tab>
            </b-tabs>
        </b-card>
        <div v-else-if="selectedTag && selectedTag.subtags && selectedTag.subtags.length"  class="w-100 h-100 text-left">
            <b-form-checkbox v-for="(subtag, sindex) in selectedTag.subtags" :key="'subtag-'+sindex" 
                :checked="selectedSubtags.includes(subtag.id)"
                @change="$emit('subtag-clicked', subtag.id)"
            >
                {{ subtag.name }}
            </b-form-checkbox>
            <!-- <b-form-checkbox-group
                id="subtag-checkbox"
                name="subtag-checkbox"
                class="w-100 h-100 text-left"
                stacked
            >
                <b-form-checkbox v-for="(subtag, sindex) in selectedTag.subtags" :key="'subtag-'+sindex" 
                    :checked="selectedSubtags.includes(subtag.id)"
                    @click="$emit('subtag-clicked', subtag.id)"
                >
                    {{ subtag.name }}
                </b-form-checkbox>
            </b-form-checkbox-group> -->
        </div>
    </b-container>
</template>
<script>
import IndividualTagComponent from "@/components/custom-tests/miscellaneous/IndividualTagComponent"

export default {
    name: 'IndividualTagComponent',
    components: {
        IndividualTagComponent
    },
    props: ["selectedTag", "selectedSubtags"],
    data() {
        return {
            cosa: null,
            clickedTag: null
        }
    },
    methods: {
        subtagClicked(id) {
            this.$emit('subtag-clicked', id)
        }
    },
    mounted() {
        if (this.selectedTag && this.selectedTag.tags) {
            this.clickedTag = this.selectedTag.tags[0]
        }
    },
    watch: {
        selectedTag: {
            handler() {
                if (this.selectedTag.tags) {
                    this.clickedTag = this.selectedTag.tags[0]
                }
            },
            deep: true
        }
    }
}
</script>
<style lang="scss">
.icon-right {
    position: absolute;
    right: 15px;
}
</style>
