<template>
  <div>
    <b-table-simple
      class="plan-table"
      hover
      small
      caption-top
      responsive
      bordered
    >
      <b-thead class="head-table">
        <b-tr>
          <b-th v-html="oa.name_section" colspan="2"></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <div
          v-for="(indicator, indIndex) in planification.indicators"
          :key="indIndex"
        >
          <b-tr class="head-table-ind">
            <b-th colspan="2"
              ><b>
                {{ oa.options[indIndex] ? oa.options[indIndex].name : "" }}</b
              ></b-th
            >
          </b-tr>
          <b-tr class="text-center">
            <b-td v-if="indicator.activities[0].dua"
              >Enseñemos para que todos nos entiendan</b-td
            >
            <b-td>Estas son mis sugerencias</b-td>
          </b-tr>
          <b-tr
            v-for="(activity, aIndex) in indicator.activities"
            :key="aIndex + '-act'"
          >
            <b-td v-if="activity.dua">
              {{ activity.dua }}
            </b-td>
            <b-td
              ><strong> {{ activity.title }}: </strong><div style="white-space: pre-wrap" v-html="formatText(activity.text)" /></b-td
            >
          </b-tr>
        </div>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import chatBotService from "@/services/chatBotService";

export default {
  name: "PlanificationOaTable",
  props: {
    subject: {
      type: String,
      required: true,
    },
    grade: {
      type: String,
      required: true,
    },
    oa: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    condition: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      pile: [],
      text: "",
      duas: {
        "[Explicación]": {
          title: "Explicación",
          description: "Explica el contenido de una forma amigable.",
        },

        "[Ejemplo]": {
          title: "Ejemplo",
          description: "Aterriza el contenido a un ejemplo cercano.",
        },

        "[Actividad individual]": {
          title: "Actividad individual",
          description: "Presenta actividades que todos puedan hacer.",
        },

        "[Conceptos]": {
          title: "Conceptos",
          description:
            "Define y entrega los conceptos importantes antes de empezar la clase.",
        },

        "[Preguntas]": {
          title: "Pregunta",
          description:
            "Empieza la clase con una  pregunta que llame la atención.",
        },

        "[Errores]": {
          title: "Errores comunes y estrategias",
          description:
            "Avísale a los estudiantes cuáles son los errores comunes para que se anticipen.",
        },

        "[Pasos]": {
          title: "Paso a paso",
          description:
            "Entrégale un paso a paso como forma de automonitoreo a los estudiantes.",
        },

        "[Actividad grupal]": {
          title: "Actividad grupal",
          description: "Sugiéreles tareas en equipo.",
        },
      },
    };
  },
  mounted() {
    this.getOaPlanification();
  },
  computed: {
    planification() {
      var oa = { name: this.oa.name_section, indicators: [] };
      var indicators = this.text.split("[Indicador]");
      indicators.forEach((indicator) => {
        var ind = {};
        let pattern = /\[(.*?)\]/;
        if (indicator.split(["[Actividad]"]).length > 1) {
          ind.name = indicator.split(["[Actividad]"])[0];
          let activities = indicator.split(["[Actividad]"]).slice(1);
          ind.activities = activities.map((activity_text) => {
            let match = activity_text.match(pattern);
            let dua = match ? this.duas[match[0]]["description"] : "";
            let title = match
              ? this.duas[match[0]]["title"]
              : "Una actividad interesante";
            return {
              text: activity_text.replace(pattern, "").replace(/\\n/g, "\n").replace(/\\t/g, "\t").replace(/}/g, ""),
              dua: dua,
              title: title,
            };
          });
          // ind.activities = indicator.split(["[Actividad]"]).slice(1);
        }
        if (ind.name) {
          oa.indicators.push(ind);
        }
      });
      return oa;
    },
  },
  watch: {
    pile() {
      if (this.pile.length) {
        var text = this.pile.shift();
        var content = JSON.parse(text).choices[0].delta.content;

        if (content) {
          this.text = (this.text + content)
            // .replace("[Explicación]:", "")
            // .replace("[Preguntas]:", "")
            // .replace("[Errores]:", "")
            // .replace("[Actividad grupal]:", "")
            // .replace("[Conceptos]:", "")
            // .replace("[Actividad individual]:", "")
            // .replace("[Pasos]:", "")
            // .replace("[Ejemplo]:", "")
            .replace(":", "");
        }
      }
    },
  },
  methods: {
    formatText(text) {
      return text.replace(/\\n/g, "\n").replace(/\\t/g, "\t").replace(/}/g, "");
    },
    getOaPlanification() {
      chatBotService
        .tr(this.subject, this.grade, this.oa, this.type, this.condition)
        .then((body) => {
          const reader = body.getReader();
          const decoder = new TextDecoder();
          var vue_context = this;

          function processChunk({ done, value }) {
            if (value) {
              const decodedChunk = decoder.decode(value);
              // decodedChunk.split("}{").forEach((chunk) => {
              //   var customChunk = chunk;
              //   if (chunk[0] !== "{") {
              //     customChunk = "{" + customChunk;
              //   }
              //   if (chunk[chunk.length - 1] !== "}") {
              //     customChunk = customChunk + "}";
              //   }
              //   vue_context.pile.push(customChunk);
              // });
              vue_context.text += decodedChunk
            }

            if (done) {
              vue_context.$emit("tag-completed", vue_context.planification);
              return;
            }

            return reader.read().then(processChunk);
          }

          reader.read().then(processChunk);
        });
    },
  },
};
</script>
<style scoped>
.plan-table {
  border: 1pt solid rgb(0, 185, 174, 0.5);
  /* font-family: "Ubuntu Regular", sans-serif !important; */
}
.head-table {
  background-color: rgb(0, 185, 174) !important;
  color: white;
}
.head-table-ind {
  background-color: rgb(0, 185, 174) !important;
}
</style>
