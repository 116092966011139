<template>
  <div>
    <div style="background-color: white" v-if="route === 'redpie'">
      <loading-image-modal ref="loading-modal" />
      <b-row class="p-1 my-1 mx-2 d-block d-md-flex">
        <b-col class="d-block d-lg-inline-block mt-5">
          <span class="h5 text-warning font-weight-bold d-block">
            ¿Por qué Utopie?
          </span>
          <iframe
            :src="video"
            allowfullscreen
            class="embed-responsive-item d-block w-100 h-75"
          ></iframe>
        </b-col>
        <b-col
          class="text-center d-block d-lg-inline-block mt-5"
          align-self="center"
        >
          <b-button
            pill
            variant="warning"
            class="font-weight-bold text-white"
            href="#contact"
          >
            Agendar Videollamada
          </b-button>
          <span class="d-block text-muted">
            Puedes comunicarte directamente con nosotros! Escríbenos a
            contacto@redpie.cl o llamando al +569 5 066 41 86 o al +569 9 223 88
            89
          </span>
        </b-col>
        <b-col class="d-block d-lg-inline-block text-justify mt-5">
          <span class="h5 text-warning font-weight-bold d-block">
            Utopie es un sistema diseñado específicamente para solucionar los
            problemas de la educación especial
          </span>
          <span class="mt-2 text-muted">
            Todo el equipo PIE/escuela especial trabaja colaborativamente!
            Ahorramos más del 60% del tiempo en la elaboración de informes. Toda
            la documentación y actividad del equipo queda respaldada año a año.
            Corregimos automáticamente todas las pruebas estandarizadas que
            solicita el decreto 170 ¡El proceso de evaluación y reevaluación
            tomará semanas en vez de meses! Utopie, la solución a las
            burocracias del decreto 170, a un precio que cualquier colegio puede
            pagar.
          </span>
        </b-col>

        <!-- <b-col class="p-2 m-auto" lg="5" >
          <div class="embed-responsive embed-responsive-16by9">
            <iframe :src="video" allowfullscreen
                    class="embed-responsive-item"></iframe>
          </div>
        </b-col>
        <b-col class="p-2 m-2" lg="5" >
          <div class="w-100 p-2 m-1">
            <span class="h5 text-warning font-weight-bold">
              Redpie es un sistema diseñado específicamente para solucionar los problemas de la educación especial
            </span>
          </div>
          <div class="w-100 p-2 m-1">
          <span class="mt-2 text-muted"> -->
        <!-- Creemos que es absolutamente necesario automatizar las tareas
          burocráticas del PIE y escuelas especiales para dedicar más tiempo a
          trabajar directamente con los estudiantes. Creemos que los
          profesionales deberíamos poder comunicarnos y apoyarnos
          colectivamente, y en definitiva aprovechar de mejor manera las
          tecnologías actuales para mejorar significativamente nuestra disciplina.<br><br>
          En tiempos de teletrabajo se ha vuelto muy dificil organizar el trabajo,
          ordenar la documentación y dedicarle el tiempo requerido a los
          estudiantes. Redpie es un sistema que agiliza los tiempos, automatiza
          la corrección de informes, mantiene toda la documentación ordenada y
          asegurada 24/7 en formato online. -->
        <!-- Todo el equipo PIE/escuela especial trabaja colaborativamente!
            Ahorramos más del 60% del tiempo en la elaboración de informes.
            Toda la documentación y actividad del equipo queda respaldada año a año.  
            Corregimos automáticamente todas las pruebas estandarizadas que solicita el decreto 170
            ¡El proceso de evaluación y reevaluación tomará semanas en vez de meses!
            Utopie, la solución a las burocracias del decreto 170,
            a un precio que cualquier colegio puede pagar.
          </span>
          </div>
        </b-col> -->
      </b-row>
      <!-- <b-row class="m-0 p-0 d-none d-lg-block" style="cursor:pointer;">
        <b-img :src="freeTrial" class="img-fluid" @click="goToRedpie"></b-img>
      </b-row> -->
    </div>
    <div style="background-color: white" v-if="route === 'eureka'">
      <loading-image-modal ref="loading-modal" />
      <!-- <b-row
        class="m-0 px-0 py-4 d-block"
        style="cursor: pointer"
        @click="freeTry"
      >
        <b-img :src="images[route].freeTrial" fluid></b-img>
      </b-row> -->
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          :backgroundImg="images.web[route].standard"
          :backgroundImgCell="images.cel[route].standard"
          orientation="left"
          title="Estandarizada para el diagnóstico DEA según los lineamientos del decreto 170"
          titleSize="3vw"
          text="Eureka está validada, mide el progreso en lectoesritura y cálculo, entregando las desviaciones estándar, lo cual permite el diagnóstico de DEA e ingreso al Programa de Integración Escolar"
          textSizeCell="18px"
          txtColor="black"
          colsMD="7"
        />
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          :backgroundImg="images.web[route].validated"
          :backgroundImgCell="images.cel[route].validated"
          orientation="right"
          title="Eureka está validada y cuenta con un estudio científico"
          titleSize="3vw"
          text="Cuenta con una publicación de validación en la revista 
                Enfoques Educacionales de la Universidad de Chile, que 
                certifica su validez y confiabilidad para el diagnóstico de DEA. 
                Revísalo dando click 
                <a href='https://www.scielo.cl/pdf/ree/v21n1/2735-7279-ree-21-1-00165.pdf' target='_blank' rel='noopener noreferrer'>aquí<a/>"
          textSizeCell="18px"
          txtColor="black"
          colsMD="7"
        />
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          :backgroundImg="images.web[route].levels"
          :backgroundImgCell="images.cel[route].levels"
          orientation="left"
          title="Estandarizada desde 1° Básico hasta 8° Básico"
          titleSize="3vw"
          text="Evalúa hasta dos veces al año a un mismo estudiante (las pruebas de media por ahora son informales)"
          textSizeCell="18px"
          txtColor="black"
          colsMD="7"
        />
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          :backgroundImg="images.web[route].subjects"
          :backgroundImgCell="images.cel[route].subjects"
          orientation="right"
          title="Enfocada en el progreso de Lenguaje y Matemáticas"
          titleSize="3vw"
          titleSizeCell="28px"
          text="Mide objetivos de aprendizaje"
          textSize=""
          txtColor="black"
          colsMD="7"
        />
        <!-- <b-img :src="images[route].subjects" fluid></b-img> -->
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          :backgroundImg="images.web[route].abilities"
          :backgroundImgCell="images.cel[route].abilities"
          orientation="left"
          title="Mide habilidades cognitivas"
          titleSize="3vw"
          text="Atención, memoria, pensamiento y sub habilidades"
          textSize=""
          txtColor="black"
          colsMD="7"
        />
        <!-- <b-img :src="images[route].abilities" fluid></b-img> -->
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          :backgroundImg="images.web[route].digital"
          :backgroundImgCell="images.cel[route].digital"
          orientation="right"
          title="Se rinde de forma digital"
          titleSize="3vw"
          text="Los estudiantes la rinden desde un computador, de manera amigable y sencilla"
          textSize=""
          txtColor="black"
          colsMD="7"
        />
        <!-- <b-img :src="images[route].digital" fluid></b-img> -->
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          :backgroundImg="images.web[route].questions"
          :backgroundImgCell="images.cel[route].questions"
          orientation="left"
          title="Incluye distintos tipos de pregunta"
          titleSize="3vw"
          text="Alternativas, ordenamiento, respuestas orales y de desarrollo"
          textSize=""
          txtColor="black"
          colsMD="7"
        />
        <!-- <b-img :src="images[route].questions" fluid></b-img> -->
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          :backgroundImg="images.web[route].review"
          :backgroundImgCell="images.cel[route].review"
          orientation="right"
          title="Se corrige en línea"
          titleSize="3vw"
          text="Las preguntas de alternativa se corrigen automaticamente, las preguntas de desarrollo cuentan con una rúbrica precisa"
          textSize=""
          txtColor="black"
          colsMD="6"
        />
        <!-- <b-img :src="images[route].review" fluid></b-img> -->
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          :backgroundImg="images.web[route].report"
          :backgroundImgCell="images.cel[route].report"
          orientation="left"
          title="Genera un informe Psicopedagógico"
          titleSize="3vw"
          text="Revisas la prueba y nosotros te entregamos un reporte detallado, lo cual permite el diagnóstico de DEA en Chile, incluyendo las desviaciones estándar."
          textSize=""
          txtColor="black"
          colsMD="8"
        />
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          :backgroundImg="images.web[route].uses"
          :backgroundImgCell="images.cel[route].uses"
          orientation="center"
          title="Adquiérela para las evaluaciones PIE de tu establecimiento"
          titleSize="3vw"
          text=" "
          textSize=""
          txtColor="black"
          colsMD="12"
        />
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          :backgroundImg="images.web[route].evaluations"
          :backgroundImgCell="images.cel[route].evaluations"
          orientation="left"
          title="Hemos evaluado más de 10.000 estudiantes con nuestra prueba en Chile"
          titleSize="3vw"
          titleSizeCell="28px"
          text="Comunas, fundaciones y colegios particulares a lo largo de Chile ya utilizan la batería psicopedagógica Eureka con sus estudiantes en el contexto del Programa de Integración Escolar."
          textSize=""
          textSizeCell="20px"
          marginLeftCell="50px"
          txtColor="black"
          colsMD="8"
        />
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          :backgroundImg="images.web[route].manual"
          :backgroundImgCell="images.cel[route].manual"
          orientation="right"
          title="Cuenta con un manual y una capacitación"
          titleSize="3vw"
          titleSizeCell="28px"
          text="Eureka cuenta con un protocolo estandarizado de aplicación para el diagnóstico de DEA, los profesionales educadores diferenciales y psicopedagogos pueden acceder al manual de aplicación y la capacitación virtual de la plataforma."
          textSize=""
          textSizeCell="20px"
          txtColor="black"
          colsMD="7"
        />
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          :backgroundImg="images.web[route].faq"
          :backgroundImgCell="images.cel[route].faq"
          orientation="left"
          title="Preguntas Frecuentes"
          titleSize="6vw"
          text=""
          textSize=""
          txtColor="warning"
          colsMD="12"
        />
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          orientation="left"
          title="¿Qué prueba se debe ocupar para el diagnóstico de DEA según el decreto 170?"
          titleSize="3vw"
          titleSizeCell="28px"
          text="El decreto 170 no obliga ninguna prueba específica para el diagnóstico de DEA. 
                La prueba a escoger para el diagnóstico es de criterio del evaluador, siempre 
                y cuando cumpla con ciertas características estipuladas en el decreto 
                (las características se especifican en los artículos 2, 28 y 29 que abordamos más abajo) 
                Artículo 28.- Los profesionales competentes deberán elegir los instrumentos evaluativos 
                de carácter psicopedagógicos que sean pertinentes con la edad, el curso y la cultura a 
                la cual pertenece el o la estudiante"
          textSize=""
          textSizeCell="20px"
          txtColor="black"
          colsMD="10"
        />
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          orientation="right"
          title="¿Qué dice el Mineduc?"
          titleSize="3vw"
          titleSizeCell="28px"
          text="El mineduc no valida, certifica o recomienda ninguna prueba 
                en particular para el caso de DEA. Tanto en correos oficiales 
                como en las orientaciones ratifican el contenido del decreto 170, 
                cada evaluador puede escoger la prueba diagnóstica en el caso del 
                diagnóstico de DEA. Puedes ver la respuesta oficial del mineduc en el video."
          textSize=""
          videoURL="https://www.youtube.com/embed/GegH-98Algo?start=4679"
          textSizeCell="20px"
          txtColor="black"
          colsMD="8"
        />
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          orientation="left"
          title="¿Cómo escojo la prueba diagnóstica de DEA a utilizar para cumplir con el decreto 170?"
          titleSize="3vw"
          titleSizeCell="28px"
          text="La prueba a escoger debe cumplir con 3 criterios solicitados en el decreto 170, 
                debe ser validada en chile en las áreas de comprensión lectora, nivel lector, 
                habilidades psicolingüísticas, escritura y matemáticas, entregando las desviaciones 
                estándar en lectoescritura y cálculo.<br/>
                Art. 2c.- Una prueba válida y confiable<br/>
                Art. 28.- La prueba debe ser validada en las áreas de: comprensión lectora, nivel lector, 
                habilidades psicolingüística, escritura y matemáticas.<br/>
                Art. 29b.- El estudiante se debe ubicar 2 DV estándar bajo la media en lectoescritura o cálculo."
          textSize=""
          textSizeCell="20px"
          txtColor="black"
          colsMD="10"
        />
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          orientation="right"
          title="¿Eureka cumple los criterios de prueba diagnóstica según el decreto 170?, ¿La valida el Mineduc?"
          titleSize="3vw"
          titleSizeCell="28px"
          text="Sí, el decreto en su articulo 2c estipula que las pruebas diagnósticas deben garantizar validez, 
                confiabilidad y consistencia, Eureka es una prueba validada en Chile y cuenta con un paper de 
                validación publicado por la revista de la universidad de Chile enfoques educacionales.<br/>
                Según el articulo 2c. el decreto 170 entiende que una prueba diagnóstica es:<br/>
                - Artículo 2 c. Aquellas herramientas y procedimientos de observación y medición que 
                permiten evaluar de manera cuantitativa y/o cualitativa al estudiante en el ámbito de 
                exploración requerido y que garanticen validez, confiabilidad y consistencia, así como 
                obtener información certera acerca del o la estudiante, el contexto escolar y familiar en el 
                que participa. "
          textSize=""
          textSizeCell="20px"
          txtColor="black"
          colsMD="10"
        />
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          orientation="left"
          title="¿La prueba estandarizada Eureka entrega las desviaciones estándar?"
          titleSize="3vw"
          titleSizeCell="28px"
          text="Si, eureka entrega las desviaciones estándar en cálculo y lectoescritura 
                según lo estipulado en el decreto 170 articulo 29b , lo cual permite el 
                diagnóstico de DEA e ingreso a PIE. <br/>
                -Artículo 29 b: En las evaluaciones con normas estandarizadas, rinde por 
                lo menos, dos desviaciones por debajo del estándar esperado para su nivel 
                de edad, de inteligencia y de escolaridad."
          textSize=""
          textSizeCell="20px"
          txtColor="black"
          colsMD="10"
        />
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          orientation="right"
          title="¿La prueba estandarizada Eureka mide lo que pide el decreto para el diagnóstico?"
          titleSize="3vw"
          titleSizeCell="28px"
          text="Sí, Eureka esta creada de acuerdo a los lineamientos del decreto 170, según lo 
                estipulado en el artículo 29, midiendo el nivel de desempeño y posible dificultad 
                en las áreas de Comprensión oral, Expresión Oral, Comprensión lectora, Expresión 
                Escrita, Cálculo y Resolución de problemas matemáticos.<br/><br/>
                - Artículo 29.a: El o la estudiante no progresa adecuadamente y sus dificultades 
                son significativamente mayores que otros niños o niñas de su misma edad o curso, 
                en las áreas de: expresión oral, comprensión oral, destrezas básicas lectoras, 
                habilidades de fluidez lectora, comprensión lectora, expresión escrita, cálculo 
                matemático, solución de problemas matemáticos, aun cuando se le han proporcionado 
                las experiencias de aprendizaje y la instrucción apropiada a sus necesidades 
                individuales de aprendizaje (ritmo y estilo de aprendizaje y conocimientos previos).
                "
          textSize=""
          textSizeCell="20px"
          txtColor="black"
          colsMD="10"
        />
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          orientation="left"
          title="¿Puede ocuparse la prueba estandarizada EUREKA para el diagnóstico de DEA en el contexto 
                  del programa de integración escolar?"
          titleSize="3vw"
          titleSizeCell="28px"
          text="Si, dado que está validada en chile (cuenta con un paper de validación), indica 
                las principales dificultades en los ejes indicados en el decreto para lenguaje y 
                matemáticas, entregando las desviaciones estándar en lectoescritura y cálculo, 
                cumpliendo de esta forma los lineamientos solicitados por el decreto 170.
                "
          textSize=""
          textSizeCell="20px"
          txtColor="black"
          colsMD="10"
        />
      </b-row>
      <!-- <b-row
        class="m-0 px-0 py-4 d-block"
        style="cursor: pointer"
        @click="freeTry"
      >
        <b-img :src="images[route].secondTrial" fluid></b-img>
      </b-row> -->
      <!-- 
      <b-row class="m-0 p-0 d-none d-lg-block" style="cursor:pointer;">
        <b-img :src="freeTrial" class="img-fluid" @click="goToRedpie"></b-img>
      </b-row>
      -->
    </div>
    <div v-if="route === 'planification'">
      <b-container class="my-4 d-none d-md-block">
        <b-row>
          <b-col class="text-center" offset="1" cols="10">
            <daily-planification
              :demo="true"
              :type="'accurate'"
              :condition="''"
            />
          </b-col>
        </b-row>
      </b-container>
      <b-row
        class="m-0 px-0 py-4 d-none d-md-block"
        style="cursor: pointer"
        @click="freeTry"
      >
        <b-img :src="images.web[route].freeTrial" fluid></b-img>
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          :backgroundImg="images.web[route].levels"
          :backgroundImgCell="images.cel[route].levels"
          orientation="left"
          title="Obtienes una planificación ÚNICA ¡En segundos!"
          titleSize="3vw"
          text="Se crean automáticamente con Inteligencia Artificial. Cada planificación que generes es única, hecha solamente para ti."
          textSize=""
          txtColor="black"
          colsMD="7"
          paddingTop="100px"
        />
        <!-- <b-img :src="images.web[route].levels" fluid></b-img> -->
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          :backgroundImg="images.web[route].subjects"
          :backgroundImgCell="images.cel[route].subjects"
          orientation="right"
          title="Puedes elegir el formato DIARIO o MENSUAL"
          titleSize="3vw"
          text="<strong>Formato diario:</strong> incluye el guión de la clase, con inicio, desarrollo y cierre.<br/> <strong>Formato mensual:</strong> permite distintos Objetivos de Aprendizaje, al mismo tiempo."
          textSize=""
          textSizeCell="18px"
          txtColor="black"
          colsMD="7"
          paddingTop="80px"
        />
        <!-- <b-img :src="images.web[route].subjects" fluid></b-img> -->
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          :backgroundImg="images.web[route].abilities"
          :backgroundImgCell="images.cel[route].abilities"
          orientation="left"
          title="Incluyen estrategias DIVERSIFICADAS"
          titleSize="3vw"
          text="Entrega distintas estrategias del diseño universal para facilitar el aprendizaje."
          textSize=""
          txtColor="black"
          colsMD="7"
          paddingTop="80px"
        />
        <!-- <b-img :src="images.web[route].abilities" fluid></b-img> -->
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          :backgroundImg="images.web[route].digital"
          :backgroundImgCell="images.cel[route].digital"
          orientation="right"
          title="Incorporan EJERCICIOS CON 5 DIFICULTADES"
          titleSize="3vw"
          text="De acuerdo a la taxonomía de Bloom actualizada."
          textSize=""
          txtColor="black"
          colsMD="6"
          paddingTop="80px"
        />
        <!-- <b-img :src="images.web[route].digital" fluid></b-img> -->
      </b-row>
      <b-row class="m-0 px-0 py-4 d-block">
        <info-banner
          :backgroundImg="images.web[route].questions"
          :backgroundImgCell="images.cel[route].questions"
          orientation="left"
          title="EDÍTALA DESDE TU PC"
          titleSize="3vw"
          text="Se descargan en formato word editable para que agregues o modifiques lo que necesites"
          textSize=""
          txtColor="black"
          colsMD="6"
          paddingTop="80px"
        />
        <!-- <b-img :src="images.web[route].questions" fluid></b-img> -->
      </b-row>
      <b-row
        class="m-0 px-0 py-4 d-none d-md-block"
        style="cursor: pointer"
        @click="freeTry"
      >
        <b-img :src="images.web[route].review" fluid></b-img>
      </b-row>
    </div>
  </div>
</template>

<script>
import redpieService from "@/services/redpieService";
import LoadingImageModal from "../../utopie/modals/LoadingImageModal";
import DailyPlanification from "@/components/botpie-assistant/DailyPlanification";
import InfoBanner from "../../utopie/miscellaneous/InfoBanner";

export default {
  components: {
    LoadingImageModal,
    DailyPlanification,
    InfoBanner,
  },
  name: "AboutUs",
  props: {
    route: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      video: "",
      freeTrial: require("../../../assets/img/sales/free-trial.jpg"),
      images: {
        web: {
          eureka: {
            standard: require("@/assets/img/eureka_sale/PNG/web/estandarizada.png"),
            validated: require("@/assets/img/eureka_sale/PNG/web/validada.png"),
            evaluations: require("@/assets/img/eureka_sale/PNG/web/evaluaciones.png"),
            manual: require("@/assets/img/eureka_sale/PNG/web/manual.png"),
            faq: require("@/assets/img/eureka_sale/PNG/web/faq.png"),
            levels: require("@/assets/img/eureka_sale/PNG/web/nivel apl.png"),
            freeTrial: require("@/assets/img/eureka_sale/PNG/Sección_Eureka_4_pruébala gratis.png"),
            subjects: require("@/assets/img/eureka_sale/PNG/web/leng y mat.png"),
            abilities: require("@/assets/img/eureka_sale/PNG/web/habilidades psico.png"),
            digital: require("@/assets/img/eureka_sale/PNG/web/apl digital.png"),
            questions: require("@/assets/img/eureka_sale/PNG/web/tipos de eval.jpg"),
            review: require("@/assets/img/eureka_sale/PNG/web/rev_auto.png"),
            report: require("@/assets/img/eureka_sale/PNG/web/informe psico.png"),
            uses: require("@/assets/img/eureka_sale/PNG/web/colegio o persona.png"),
            secondTrial: require("@/assets/img/eureka_sale/PNG/Sección_Eureka_13_pruébala gratis 2.png"),
          },
          planification: {
            freeTrial: require("@/assets/img/planification_sale/PNG/Planificaciones_3_pruébalo gratis@4x.png"),
            levels: require("@/assets/img/planification_sale/PNG/web/Planificaciones_4_en segundos@4x.png"),
            subjects: require("@/assets/img/planification_sale/PNG/web/Planificaciones_5_formatos@4x.png"),
            abilities: require("@/assets/img/planification_sale/PNG/web/Planificaciones_6_diseño universal@4x.png"),
            digital: require("@/assets/img/planification_sale/PNG/web/Planificaciones_7_taxonomía de bloom@4x.png"),
            questions: require("@/assets/img/planification_sale/PNG/web/Planificaciones_8_descárgala a tu pc@4x.png"),
            review: require("@/assets/img/planification_sale/PNG/Planificaciones_9_pruébala gratis 2@4x.png"),
          },
        },
        cel: {
          eureka: {
            faq: require("@/assets/img/eureka_sale/PNG/cel/faq.png"),
            standard: require("@/assets/img/eureka_sale/PNG/cel/estandarizada.png"),
            validated: require("@/assets/img/eureka_sale/PNG/cel/validada.png"),
            evaluations: require("@/assets/img/eureka_sale/PNG/cel/evaluaciones.png"),
            manual: require("@/assets/img/eureka_sale/PNG/cel/manual.png"),
            freeTrial: require("@/assets/img/eureka_sale/PNG/Sección_Eureka_4_pruébala gratis.png"),
            levels: require("@/assets/img/eureka_sale/PNG/cel/nivel apl.png"),
            subjects: require("@/assets/img/eureka_sale/PNG/cel/leng y mat.png"),
            abilities: require("@/assets/img/eureka_sale/PNG/cel/habilidades psico.png"),
            digital: require("@/assets/img/eureka_sale/PNG/cel/apl digital.png"),
            questions: require("@/assets/img/eureka_sale/PNG/cel/tipos de eval.png"),
            review: require("@/assets/img/eureka_sale/PNG/cel/rev_auto.png"),
            report: require("@/assets/img/eureka_sale/PNG/cel/informe psico.png"),
            uses: require("@/assets/img/eureka_sale/PNG/cel/colegio o persona.png"),
            secondTrial: require("@/assets/img/eureka_sale/PNG/Sección_Eureka_13_pruébala gratis 2.png"),
          },
          planification: {
            freeTrial: require("@/assets/img/planification_sale/PNG/Planificaciones_3_pruébalo gratis@4x.png"),
            levels: require("@/assets/img/planification_sale/PNG/cel/Planificaciones_4_en segundos@4x.png"),
            subjects: require("@/assets/img/planification_sale/PNG/cel/Planificaciones_5_formatos@4x.png"),
            abilities: require("@/assets/img/planification_sale/PNG/cel/Planificaciones_6_diseño universal@4x.png"),
            digital: require("@/assets/img/planification_sale/PNG/cel/Planificaciones_7_taxonomía de bloom@4x.png"),
            questions: require("@/assets/img/planification_sale/PNG/cel/Planificaciones_8_descárgala a tu pc@4x.png"),
            review: require("@/assets/img/planification_sale/PNG/Planificaciones_9_pruébala gratis 2@4x.png"),
          },
        },
      },
    };
  },
  methods: {
    freeTry() {
      this.$emit("free-trial-login");
    },
    getVideoURL() {
      redpieService.getSalesVideo().then(
        (data) =>
          (this.video = `https://www.youtube-nocookie.com/embed/${data.url.substring(
            data.url.lastIndexOf("=") + 1
          )}`),
        () => this.$toasted.error("Ocurrió un error al mostrar el vídeo")
      );
    },
    goToRedpie() {
      if (this.$store.state.login.school) {
        this.$emit("redirect-to-school");
      }
      // else {
      //   var payload = {
      //     socket: (this.$store.state.login.token === ""? this.$store.state.login.anonID : this.$store.state.login.user_id)
      //   }
      //   redpieService.createSchool(payload).then(
      //     () => {
      //       this.$refs['loading-modal'].open(true, false)
      //     }
      //   )
      // }
    },
  },
  mounted() {
    this.getVideoURL();
  },
};
</script>

<style scoped></style>
