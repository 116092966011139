<template>
  <b-modal hide-header hide-footer centered size="lg" ref="modal" @hide="close()">
    <div class="p-2">
      <b-row class="text-center">
        <h4 class="mx-auto">Ingresa/Actualiza tus credenciales SIGE</h4>
        <p class="text-muted mx-auto">para trabajar FUDEIS, obtener notas, asistencia, etc.</p>
      </b-row>
      <b-form>
        <b-form-row class="align-items-center">
          <b-col cols="4">
            <b-form-group label="Usuario SIGE">
              <b-input size="sm" :disabled="locked" v-model="user"/>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Contraseña SIGE">
              <b-input size="sm" type="password" :disabled="locked" v-model="password"/>
            </b-form-group>
          </b-col>
          <b-col cols="1">
            <div class="mt-3">
              <b-checkbox switch size="sm" class="d-inline" v-model="locked"/>
              <v-icon :name="locked? 'lock' : 'unlock'" class="d-inline-block" scale="0.8"/>
            </div>
          </b-col>
          <b-col cols="3">
            <b-button pill variant="primary" class="mt-2 mx-auto" size="sm">
              Descargar <v-icon name="download"/>
            </b-button>
          </b-col>
        </b-form-row>
      </b-form>
      <div v-for="(grade, index) of grades" :key="`grade-${index}`" class="my-1">
        <b-button block variant="unknown" class="border border-dark text-left" @click="selectedGrade=index">
          <v-icon :name="selectedGrade===index? 'chevron-up' : 'chevron-down'"/> {{grade.label}}</b-button>
        <b-collapse class="w-100" :visible="selectedGrade===index">
          howdy
        </b-collapse>
      </div>
    </div>
  </b-modal>
</template>

<script>
import redpieService from "@/services/redpieService";

export default {
  name: "ModalEditStudents",
  data() {
    return {
      school: '',
      year: 0,
      grades: [],
      locked: true,
      user: '',
      password: '',
      selectedGrade: -1
    }
  },
  methods: {
    open(school, year) {
      this.school = school
      this.year = year
      redpieService.getPieSchoolData(school, year).then(
          data => {
            this.grades = data.levels
            this.user = data.rbd
            this.password = data.sige_pass
            this.locked = this.user !== '' && this.password !== ''
          },
          () => this.$toasted.error("No pudimos obtener tus cursos. Intenta de nuevo.")
      )
      this.$refs.modal.show()
    },
    close() {
      this.school = ''
      this.year = 0
    }
  }
}
</script>

<style scoped>

</style>