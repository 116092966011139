<template>
  <div>
    <QuestionManagerModal
      ref="question-manager"
      @open-related-material="openMaterial"
      @created-question="pushQuestion"
    />
    <b-modal ref="delete-family-modal" hide-header centered size="lg">
      <b-container>
        <b-row>
          <b-col class="text-center">
            ¿Está seguro que desea enviar este El ejercicio y todas sus
            versiones a la papelera?
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <b-row>
          <b-col class="text-center">
            <b-button
              variant="danger"
              @click="
                familyToDelete = '';
                $refs['delete-family-modal'].hide();
              "
              pill
            >
              Cancelar
            </b-button>
          </b-col>
          <b-col class="text-center">
            <b-button variant="warning" @click="deleteFamily()" pill>
              Eliminar
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <b-row class="text-left mx-2 p-2">
      <b-col>
        <b-row
          v-for="(relation, rIndex) in selectedHashtags"
          :key="rIndex + '-relation'"
        >
          <b-col
            cols="4"
            v-for="(selectedHashtag, hIndex) in relation"
            :key="hIndex + '-sht'"
          >
            <b-select
              v-model="selectedHashtags[rIndex][hIndex]"
              @change="hashtagChanged(rIndex, hIndex)"
            >
              <b-select-option :value="null"
                >Seleccione
                {{
                  selectedHashtagsOptions[rIndex][hIndex].relation_name
                }}</b-select-option
              >
              <b-select-option
                v-for="(child, cIndex) in selectedHashtagsOptions[rIndex][
                  hIndex
                ]
                  ? selectedHashtagsOptions[rIndex][hIndex].relation_name ==
                    'Asignaturas'
                    ? selectedHashtagsOptions[rIndex][hIndex].children.filter(
                        (child) => {
                          return [
                            'Lenguaje y comunicación',
                            'Matemáticas',
                          ].includes(child.name);
                        }
                      )
                    : selectedHashtagsOptions[rIndex][hIndex].children
                  : []"
                :key="cIndex + '-child'"
                :value="child.id"
              >
                {{ child.name }}
              </b-select-option>
            </b-select>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="text-right mx-2 p-2">
      <b-col cols="4">
        <b-select v-model="selectedQuestionKind" @change="getFilteredFamilies">
          <b-select-option :value="''"
            >Seleccione tipo de ejercicio
          </b-select-option>
          <b-select-option
            v-for="(kind, kIndex) in eKinds"
            :key="kIndex + '-kind'"
            :value="kind.value"
          >
            {{ kind.text }}
          </b-select-option>
        </b-select>
      </b-col>
      <b-col v-if="paperBin">
        <b-form-checkbox
          v-model="showRecycle"
          name="recycle"
          value="active"
          unchecked-value="not_active"
        >
          Mostrar ejercicios en papelera
        </b-form-checkbox>
      </b-col>
      <b-col :cols="paperBin ? 1 : 8" class="text-right">
        <b-button
          variant="unknown"
          size="sm"
          class="mx-auto"
          v-b-tooltip.hover
          title="Papelera"
          @click="paperBin = !paperBin"
        >
          <v-icon name="trash-alt" class="text-danger" />
        </b-button>
      </b-col>
    </b-row>
    <b-row class="text-left mx-2 p-2">
      <b-col cols="3" class="cursor-pointer">
        <p class="h6 w-100" @click="openNewQuestion()">
          Crear nuevo ejercicio
          <v-icon name="plus-circle" scale="1.2" style="cursor: pointer" />
        </p>
      </b-col>
    </b-row>
    <hr />
    <b-row class="text-left mx-2 p-2">
      <!-- <b-col cols="3" class="align-middle mt-4 cursor-pointer">
              <b-card
                header-bg-variant="dark"
                header="Crear nuevo ejercicio"
                id="new"
                @click="openNewQuestion()"
                header-text-variant="white"
                no-body
              >
                <b-card-body>
                  <b-form-row>
                    <b-col class="text-center px-0" cols="6" offset="3">
                      <b-img :src="icon_new" height="100" />
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    Instrucciones:
                    Para crear un nuevo Ejercicio
                    Interactivo lorem ipsum dolor sit
                    amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod
                    tincidunt ut laoreet dolore magna
                    aliquam erat volutpat.
                  </b-form-row>
                </b-card-body>
              </b-card>
            </b-col> -->
      <b-col cols="12" class="mx-0 px-0">
        <b-container class="m-0 p-0" v-if="!loadingFamilies">
          <b-row class="m-0 p-0">
            <b-col v-for="family in families" :key="family.id" cols="12">
              <question-row-component
                :question="family.most_popular"
                :paperBin="paperBin"
                mode="material-list"
                @open-question="openQuestion(family.most_popular)"
                @delete-question="deleteFamilyModal(family.id)"
              />
              <hr />
              <!-- <b-tooltip
                      :target="family.most_popular.id"
                      triggers="hover"
                      placement="auto"
                    >
                      {{ family.most_popular.question }}
                    </b-tooltip> -->
            </b-col>
          </b-row>
        </b-container>
        <b-container v-else>
          <b-row class="m-0 p-0">
            <b-col class="text-center">
              <b-spinner variant="primary" label="Spinning"></b-spinner>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row v-if="pages > 1">
      <b-col class="text-center">
        <b-button-group>
          <b-button
            variant="outline-info"
            v-if="page > 1"
            @click="page = page - 1"
          >
            &#60;
          </b-button>
          <b-button
            variant="outline-info"
            :pressed="page === 1"
            @click="page = 1"
          >
            1
          </b-button>
          <b-button variant="outline-info" v-if="page - pageRange / 2 > 2">
            ...</b-button
          >
          <b-button
            v-for="(p, index) in pagesArray.filter((p) => {
              return (
                1 < p &&
                p < pages &&
                ((pageRange < page &&
                  page - pageRange / 2 <= p &&
                  p <= page + pageRange / 2) ||
                  (pageRange >= page && p <= pageRange + 1))
              );
            })"
            :key="'page-' + index"
            :pressed="p === page"
            @click="page = p"
            variant="outline-info"
          >
            {{ p }}
          </b-button>
          <b-button
            variant="outline-info"
            v-if="pages - 1 > page + pageRange / 2"
          >
            ...</b-button
          >
          <b-button
            variant="outline-info"
            :pressed="page === pages"
            @click="page = pages"
          >
            {{ pages }}</b-button
          >
          <b-button
            variant="outline-info"
            v-if="page < pages"
            @click="page = page + 1"
          >
            &#62;
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import customMaterialService from "@/services/customMaterialService";
import QuestionManagerModal from "@/components/custom-materials/modals/QuestionManagerModal";
import QuestionRowComponent from "@/components/custom-materials/miscellaneous/QuestionRowComponent";
import customTestService from "@/services/customTestService";
export default {
  name: "QuestionListComponent",
  components: {
    QuestionManagerModal,
    QuestionRowComponent,
  },
  data() {
    return {
      selectedHashtags: [],
      selectedHashtagsOptions: [],
      eKinds: [
        { text: "Alternativas", value: 0 },
        { text: "Desarrollo", value: 1 },
        // {text: 'Dictado', value: 2},
        { text: "Ordenamiento", value: 3 }
      ],
      paperBin: false,
      loadingFamilies: false,
      families: [],
      pages: 1,
      page: 1,
      pageRange: 6,
      showRecycle: "not_active",
      familyToDelete: "",
      selectedQuestionKind: "",
      perPage: 30,
      order: "-created_at",
      hashtags: [],
    };
  },
  computed: {
    pagesArray() {
      return Array.from({ length: this.pages }, (_, i) => i + 1);
    },
  },
  watch: {
    page() {
      if (this.hashtags.length) {
        // this.getFilteredQuestions();
        this.getFilteredFamilies();
      } else {
        // this.getPaginatedSchoolQuestions();
        this.getPaginatedSchoolFamilies();
      }
    },
    selectedHashtags() {
      this.applyFilters();
    },
    showRecycle() {
      if (this.hashtags.length) {
        // this.getFilteredQuestions();
        this.getFilteredFamilies();
      } else {
        // this.getPaginatedSchoolQuestions();
        this.getPaginatedSchoolFamilies();
      }
    },
  },
  mounted() {
    this.getPaginatedSchoolFamilies();
    this.getFilters();
  },
  methods: {
    getPaginatedSchoolFamilies() {
      this.loadingFamilies = true;
      customMaterialService
        .getPaginatedSchoolFamilies(
          this.$store.state.login.school,
          this.page,
          this.perPage,
          this.order,
          "",
          this.showRecycle,
          "",
          "",
          this.selectedQuestionKind
        )
        .then((data) => {
          this.families = data.families;
          this.pages = data.pages;
          this.loadingFamilies = false;
        })
        .catch(() =>
          this.$toasted.error("Ocurrió un problema al traer tus familias")
        );
    },
    openNewQuestion() {
      this.$refs["question-manager"].createNew(true);
    },
    deleteFamilyModal(familyId) {
      this.familyToDelete = familyId;
      this.$refs["delete-family-modal"].show();
    },
    deleteFamily() {
      customMaterialService
        .deleteFamily(this.familyToDelete)
        .then(() => {
          this.getPaginatedSchoolFamilies();
          this.familyToDelete = "";
          this.$toasted.success(
            "El ejercicio y todas sus versiones fueron movidos a la papelera"
          );
          this.$refs["delete-family-modal"].hide();
        })
        .catch(() => this.$toasted.error("No se pudo eliminar el ejercicio"));
    },
    openMaterial(materialID) {
      this.$emit("open-related-material", materialID);
    },
    pushQuestion() {
      console.log("repaginar");
      if (this.page > 1) {
        this.page = 1;
      } else {
        this.getPaginatedSchoolFamilies();
      }
    },
    openQuestion(question) {
      this.$refs["question-manager"].selectQuestion(question, true);
    },
    getFilters() {
      this.visible = !this.visible;
      this.selectedTag = null;
      this.customTags = [];
      customTestService
        .getFilters("")
        .then((data) => {
          this.selectedHashtags = [];
          this.selectedHashtagsOptions = [];
          for (var i = 0; i < data.length; i++) {
            this.selectedHashtags.push([null]);
            this.selectedHashtagsOptions.push([data[i]]);
          }
        })
        .catch(() =>
          this.$toasted.error("Ocurrió un problema al traer tus tags")
        );
    },
    hashtagChanged(rIndex, hIndex) {
      customTestService
        .getFilters(this.selectedHashtags[rIndex][hIndex])
        .then((data) => {
          if (data.length) {
            if (this.selectedHashtags[rIndex].length === hIndex + 1) {
              this.selectedHashtags[rIndex].push(null);
              this.selectedHashtagsOptions[rIndex].push(data[0]);
            } else {
              this.$set(this.selectedHashtags[rIndex], hIndex + 1, null);
              this.$set(
                this.selectedHashtagsOptions[rIndex],
                hIndex + 1,
                data[0]
              );
              this.$set(
                this.selectedHashtags,
                rIndex,
                this.selectedHashtags[rIndex].slice(0, hIndex + 2)
              );
              this.$set(
                this.selectedHashtagsOptions,
                rIndex,
                this.selectedHashtagsOptions[rIndex].slice(0, hIndex + 2)
              );
            }
          }
          var hashtags = [];
          for (var i = 0; i < this.selectedHashtags.length; i++) {
            if (
              this.selectedHashtags[i][this.selectedHashtags[i].length - 1] ===
              null
            ) {
              hashtags.push(
                this.selectedHashtags[i][this.selectedHashtags[i].length - 2]
              );
            } else {
              hashtags.push(
                this.selectedHashtags[i][this.selectedHashtags[i].length - 1]
              );
            }
          }
          this.hashtags = hashtags;
          // this.getTags(hashtags)
          // console.log(hashtags);
        })
        .catch(() =>
          this.$toasted.error("Ocurrió un problema al traer tus tags")
        );
    },
    applyFilters() {
      this.page = 1;
      // this.getFilteredQuestions();
      this.getFilteredFamilies();
    },
    getFilteredFamilies() {
      this.loadingFamilies = true;
      customMaterialService
        .getPaginatedSchoolFamilies(
          this.$store.state.login.school,
          this.page,
          this.perPage,
          this.order,
          this.hashtags,
          this.showRecycle,
          "",
          "",
          this.selectedQuestionKind
        )
        .then((data) => {
          this.families = data.families;
          this.pages = data.pages;
          this.loadingFamilies = false;
        })
        .catch(() =>
          this.$toasted.error("Ocurrió un problema al traer tus ejercicios")
        );
    },
  },
};
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
