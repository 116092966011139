<template>
  <b-row :class="application.is_active ? '' : 'recycle'">
    <b-col cols="1" class="mx-2">
      <b-badge
        @click="$emit('open-application')"
        style="cursor: pointer"
        variant="primary"
        >Ver</b-badge
      >
    </b-col>
    <b-col @click="$emit('open-application')" class="text-hover">
      {{ application.material.name }}

      <p class="text-light">
        # Aplicado por {{ application.creator.first_name }}
        {{ application.creator.middle_name }}
        {{ application.creator.last_name }}
      </p>
    </b-col>

    <b-col
      v-if="mode === 'material-list' && application.is_active && paperBin"
      cols="1"
      class="text-center"
    >
      <b-button
        variant="unknown"
        size="sm"
        class="mx-auto"
        v-b-tooltip.hover
        title="Eliminar aplicación"
        @click="$emit('delete-application')"
      >
        <v-icon name="trash-alt" class="text-danger" />
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: "ApplicationRowComponent",
  props: {
    application: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: false,
    },
    paperBin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.recycle {
  opacity: 0.5;
}

.text-hover:hover {
  opacity: 0.6;
  cursor: pointer;
  transition: 0.1s;
}
</style>
