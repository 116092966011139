<template lang="">
  <div style="height: auto">
    <b-card bg-variant="light">
      <b-row>
        <b-col
          :sm="col_size ? col_size : 12"
          v-for="(item, index) in test"
          :key="index"
          :class="
            item.type === 'h3c' || item.type === 'h4c' ? 'text-center' : ''
          "
        >
          <h3 v-if="item.type === 'h3' || item.type === 'h3c'">
            {{ item.label }}
          </h3>
          <h4 v-else-if="item.type === 'h4' || item.type === 'h4c'">
            {{ item.label }}
          </h4>
          <div v-else-if="item.type === 'hidden'" class="d-none"></div>
          <b-form-group
            v-else
            :class="no_margin ? 'mb-0' : 'mb-1'"
            :label="item.label"
            :label-for="item.var"
            :label-cols-sm="
              label_size
                ? label_size
                : item.type === 'multiple_checkbox'
                ? 1
                : 4
            "
            label-align-sm="right"
            label-size="sm"
          >
            <b-form-input
              v-if="item.type === 'text'"
              size="sm"
              v-model="answer.answers[item.var]"
              :id="item.var"
              :state="checkState(item)"
              :formatter="
                (text) => {
                  if (item.maxlength)
                    return String(text).substring(0, item.maxlength);
                  else return text;
                }
              "
            ></b-form-input>
            <b-form-input
              v-if="item.type === 'number'"
              type="number"
              size="sm"
              v-model="answer.answers[item.var]"
              :id="item.var"
              :formatter="
                (text) => {
                  if (item.maxlength)
                    return String(text).substring(0, item.maxlength);
                  else return text;
                }
              "
              :state="checkState(item)"
            ></b-form-input>
            <b-form-checkbox
              v-if="item.type === 'checkbox'"
              size="sm"
              v-model="answer.answers[item.var]"
              :id="item.id"
            ></b-form-checkbox>
            <b-form-checkbox-group
              v-if="item.type === 'multiple_checkbox'"
              :id="item.id"
              v-model="answer.answers[item.var]"
              :aria-describedby="ariaDescribedby"
              :name="item.var"
            >
              <b-form-checkbox
                v-for="(item, index2) in item.options"
                :key="index2"
                :value="item.val"
              >
                {{ item.label }}
              </b-form-checkbox>
            </b-form-checkbox-group>
            <b-form-select
              v-if="item.type === 'select'"
              size="sm"
              v-model="answer.answers[item.var]"
              :options="item.options"
              :required="!!item.required"
              :state="checkState(item)"
            ></b-form-select>

            <b-form-checkbox-group
              v-if="item.type === 'multiselect'"
              size="sm"
              v-model="answer.answers[item.var]"
              :options="item.options"
            >
            </b-form-checkbox-group>
            <b-form-radio-group
              v-if="item.type === 'radio'"
              :id="item.var"
              v-model="answer.answers[item.var]"
              :options="item.options"
            >
              <b-form-invalid-feedback :state="checkState(item)"
                >Debes marcar una opción</b-form-invalid-feedback
              >
            </b-form-radio-group>
            <b-form-textarea
              v-if="item.type === 'textarea'"
              :id="item.var"
              v-model="answer.answers[item.var]"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
            <div v-if="item.type === 'group'">
              <b-row class="my-1">
                <b-col class="text-right" cols="2">
                  <strong>{{ item.index ? item.index : "" }}</strong>
                </b-col>
                <b-col class="text-left" cols="6">
                  <div v-for="element in item.elements" :key="element.var">
                    <b-form-input
                      v-if="element.type === 'text'"
                      size="sm"
                      v-model="answer.answers[element.var]"
                      :id="element.var"
                      :state="checkState(element)"
                      :formatter="
                        (text) => {
                          if (element.maxlength)
                            return String(text).substring(0, element.maxlength);
                          else return text;
                        }
                      "
                    ></b-form-input>
                    <b-form-input
                      v-if="element.type === 'number'"
                      type="number"
                      size="sm"
                      v-model="answer.answers[element.var]"
                      :id="element.var"
                      :formatter="
                        (text) => {
                          if (element.maxlength)
                            return String(text).substring(0, element.maxlength);
                          else return text;
                        }
                      "
                      :state="checkState(element)"
                    ></b-form-input>
                    <b-form-checkbox
                      v-if="element.type === 'checkbox'"
                      size="sm"
                      v-model="answer.answers[element.var]"
                      :id="element.id"
                      >{{ element.label }}</b-form-checkbox
                    >
                    <b-form-checkbox-group
                      v-if="element.type === 'multiple_checkbox'"
                      :id="element.id"
                      v-model="answer.answers[element.var]"
                      :aria-describedby="ariaDescribedby"
                      :name="element.var"
                    >
                      <b-form-checkbox
                        v-for="(element, index2) in element.options"
                        :key="index2"
                        :value="element.val"
                      >
                        {{ element.label }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                    <b-form-select
                      v-if="element.type === 'select'"
                      size="sm"
                      v-model="answer.answers[element.var]"
                      :options="element.options"
                      :required="!!element.required"
                      :state="checkState(element)"
                    ></b-form-select>

                    <b-form-checkbox-group
                      v-if="element.type === 'multiselect'"
                      size="sm"
                      v-model="answer.answers[element.var]"
                      :options="element.options"
                    >
                    </b-form-checkbox-group>
                    <b-form-radio-group
                      v-if="element.type === 'radio'"
                      :id="element.var"
                      v-model="answer.answers[element.var]"
                      :options="element.options"
                    >
                      <b-form-invalid-feedback :state="checkState(element)"
                        >Debes marcar una opción</b-form-invalid-feedback
                      >
                    </b-form-radio-group>
                    <b-form-textarea
                      v-if="element.type === 'textarea'"
                      :id="element.var"
                      v-model="answer.answers[element.var]"
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <span class="" v-if="is_valid == false">{{ invalid_text }}</span
      ><br />
      <b-button @click="send_test">Corregir Test</b-button>
    </b-card>
  </div>
</template>
<script>
import redpieService from "../../../../services/redpieService";
import testService from "../../../../services/testService";
export default {
  props: [
    "test",
    "answer",
    "previous_answer",
    "col_size",
    "label_size",
    "input_size",
    "no_margin",
    "required_fields",
    "custom_invalid_text",
    "semester",
  ],
  data() {
    return {
      is_valid: null,
      invalid_text:
        this.custom_invalid_text || "Debes completar los campos requeridos.",
    };
  },
  watch: {
    previous_answer: {
      handler(val) {
        this.$nextTick(() => {
          for (const [key, value] of Object.entries(val)) {
            this.answer.answers[key] = value;
          }
        });
      },
      deep: true,
    },
  },
  mounted() {
    for (var i = 0; i < this.test.length; i++) {
      if (this.test[i].type === "multiselect") {
        this.answer.answers[this.test[i].var] = [];
      }
    }
    if (this.previous_answer && Object.keys(this.previous_answer).length) {
      for (const [key, value] of Object.entries(this.previous_answer)) {
        this.$set(this.answer.answers, key, value);
      }
    }
  },
  methods: {
    checkRequired: function () {
      const answerKeys = Object.keys(this.answer.answers);
      this.is_valid = this.required_fields.every((element) => {
        return answerKeys.includes(element);
      });
    },
    checkState: function (item) {
      if (this.is_valid == null) {
        return null;
      }
      if (this.required_fields.includes(item.var)) {
        if (!this.is_valid) {
          return this.answer.answers[item.var] ? null : false;
        }
      }
      return null;
    },
    send_test: function () {
      let test;
      if (typeof this.required_fields != "undefined") {
        this.checkRequired();
        if (this.is_valid == false) {
          return false;
        }
      }
      this.test
        .filter((question) => {
          return question.type && question.type === "hidden";
        })
        .forEach((element) => {
          if (element.type && element.type === "hidden") {
            this.answer.answers[element.var] = element.value;
          }
        });

      this.answer["semester"] = this.semester;
      testService
        .send_test(this.answer)
        .then((data) => {
          test = data;

          redpieService.assignDocuments({
            test: this.answer.test,
            school: this.$store.state.login.school,
            year: this.$store.state.login.year,
            student_grade: this.answer.student_id,
          });
        })
        .then(
          () => {
            this.$parent.$emit("update-document");
            this.$parent.$emit("add-test", test);
          },
          () => {
            this.$toasted.error("Ha ocurrido un error al crear test");
          }
        );
    },
  },
};
</script>
<style lang=""></style>
