import { Model } from '@vuex-orm/core'

export class Videocall extends Model {
    static entity = 'videocall'

    static fields() {
        return {
            id: this.attr(null),
            topic: this.attr(null),
            time: this.attr(null),
            date: this.attr(null),
            jitsi_id: this.attr(null),
            participants: this.attr([]),
            professionals: this.attr([]),
        }
    }

    static apiConfig = {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            "Content-Type": "application/json",
        },
        baseURL: `${process.env["VUE_APP_BASE_URL"]}/api`,
        actions: {
            fetch: {
                method: 'GET',
                url: '/videocalls/school/',
            },
            fetchByPeriodAndSchool(school, year){
                const payload = {
                    school: school,
                    year: year
                }
                return this.get('/videocalls/school/list/', {params: payload})
            },
            update(call){
                return this.patch(`/videocalls/school/${call.id}/`, call)
            },
            save(call){
                return this.post(`/videocalls/school/`, call)
            },
            requestCertificate(vid_id){
                return this.get(`/videocalls/school/${vid_id}/certificate/`, {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        "Content-Type": "application/json",
                    },
                    baseURL: `${process.env["VUE_APP_BASE_URL"]}/api`,
                    'responseType': 'blob'
                })
            }
        }
    }
}

export class Room extends Model {
    static entity = 'room'

    static fields() {
        return {
            id: this.attr(null),
            title: this.attr(null),
            time: this.attr(null),
            day: this.attr(0),
            jitsi_id: this.attr(null),
            max_people: this.attr(null),
        }
    }

    static apiConfig = {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            "Content-Type": "application/json",
        },
        baseURL: `${process.env["VUE_APP_BASE_URL"]}/api`,
        actions: {
            fetch: {
                method: 'GET',
                url: '/videocalls/permanent/',
            },
            insertOrUpdate(payload) {
                return this.post('/videocalls/form/', payload)
            }
        }
    }
}