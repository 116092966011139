var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.imgLoaded)?_c('b-card',{staticClass:"d-none d-md-block",staticStyle:{"border":"none"},attrs:{"overlay":_vm.backgroundImg ? true : false,"img-src":_vm.backgroundImg ? _vm.backgroundImg : '',"img-alt":"Card Image","text-variant":_vm.txtColor}},[_c('b-container',{staticClass:"px-0 w-100 h-100",class:_vm.orientation === 'center'
          ? 'mt-5'
          : _vm.orientation === 'left'
          ? 'ml-5'
          : _vm.orientation === 'right'
          ? 'ml-auto mr-5'
          : ''},[(_vm.title)?_c('b-row',{class:!_vm.text && !_vm.learnMore && !_vm.tryFunc ? 'h-100' : ''},[_c('b-col',{class:_vm.orientation === 'center'
              ? 'text-center'
              : _vm.orientation === 'left'
              ? 'text-left'
              : 'text-right',style:(_vm.paddingTop ? 'padding-top: ' + _vm.paddingTop + ';' : ''),attrs:{"align-self":"center","cols":12,"md":_vm.orientation === 'center'
              ? _vm.offset
                ? 12 - _vm.offset * 2
                : 12
              : _vm.colsMD
              ? _vm.colsMD
              : 7,"offset-md":_vm.offset
              ? _vm.offset
              : _vm.orientation === 'right'
              ? _vm.colsMD
                ? 12 - _vm.colsMD
                : 5
              : 0}},[_c('h1',{style:(_vm.titleSize ? 'font-size: ' + _vm.titleSize + ';' : 'font-size: 3vw;')},[_c('strong',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.title)}})])])])],1):_vm._e(),(_vm.text)?_c('b-row',[(_vm.videoURL && _vm.orientation === 'right')?_c('b-col',{staticClass:"text-center",attrs:{"cols":_vm.colsMD ? 12 - _vm.colsMD : 5}},[_c('b-embed',{attrs:{"type":"iframe","aspect":"16by9","src":_vm.videoURL,"allowfullscreen":""}})],1):_vm._e(),_c('b-col',{class:_vm.orientation === 'center'
              ? 'text-center'
              : _vm.orientation === 'left'
              ? 'text-left'
              : 'text-right',attrs:{"md":_vm.orientation === 'center'
              ? _vm.offset
                ? 12 - _vm.offset * 2
                : 12
              : _vm.colsMD
              ? _vm.colsMD
              : 7,"offset-md":_vm.offset
              ? _vm.offset
              : _vm.orientation === 'right' && !_vm.videoURL
              ? _vm.colsMD
                ? 12 - _vm.colsMD
                : 5
              : 0}},[_c('p',{style:(_vm.textSize ? 'font-size: ' + _vm.textSize : 'font-size: 23px'),domProps:{"innerHTML":_vm._s(_vm.text)}})]),(_vm.videoURL && _vm.orientation === 'left')?_c('b-col',{staticClass:"text-center",attrs:{"cols":_vm.colsMD ? 12 - _vm.colsMD : 5}},[_c('b-embed',{attrs:{"type":"iframe","aspect":"16by9","src":_vm.videoURL,"allowfullscreen":""}})],1):_vm._e()],1):_vm._e(),(_vm.videoURL && _vm.orientation === 'center')?_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('b-embed',{attrs:{"type":"iframe","aspect":"16by9","src":_vm.videoURL,"allowfullscreen":""}})],1)],1):_vm._e(),(_vm.learnMore)?_c('b-row',[_c('b-col',{class:_vm.orientation === 'center'
              ? 'text-center'
              : _vm.orientation === 'left'
              ? 'text-left'
              : 'text-right',attrs:{"cols":12,"md":_vm.orientation === 'center'
              ? _vm.offset
                ? 12 - _vm.offset * 2
                : 12
              : _vm.colsMD
              ? _vm.colsMD
              : 7,"offset-md":_vm.offset
              ? _vm.offset
              : _vm.orientation === 'right'
              ? _vm.colsMD
                ? 12 - _vm.colsMD
                : 5
              : 0}},[_c('b-button',{staticClass:"d-block font-weight-bold",staticStyle:{"background-color":"white"},attrs:{"variant":_vm.btnVariant,"pill":"","size":"lg","block":""},on:{"click":_vm.learnMore}},[_vm._v(" Conocer más ")])],1)],1):_vm._e(),(_vm.tryFunc)?_c('b-row',[_c('b-col',{class:_vm.orientation === 'center'
              ? 'text-center'
              : _vm.orientation === 'left'
              ? 'text-left'
              : 'text-right',attrs:{"cols":12,"md":_vm.orientation === 'center'
              ? _vm.offset
                ? 12 - _vm.offset * 2
                : 12
              : _vm.colsMD
              ? _vm.colsMD
              : 7,"offset-md":_vm.offset
              ? _vm.offset
              : _vm.orientation === 'right'
              ? _vm.colsMD
                ? 12 - _vm.colsMD
                : 5
              : 0}},[_c('b-button',{staticClass:"d-block font-weight-bold",staticStyle:{"background-color":"white"},attrs:{"variant":_vm.btnVariant,"pill":"","size":"lg","block":""},on:{"click":_vm.tryFunc}},[_vm._v(" Pruébalo gratis! ")])],1)],1):_vm._e()],1)],1):_vm._e(),(_vm.cellImgLoaded)?_c('b-card',{staticClass:"d-block d-md-none",staticStyle:{"border":"none"},attrs:{"overlay":_vm.backgroundImgCell ? true : false,"img-src":_vm.backgroundImgCell ? _vm.backgroundImgCell : '',"img-alt":"Card Image","text-variant":_vm.txtColor}},[_c('b-container',{staticClass:"px-0 w-100",class:_vm.orientation === 'right' ? 'ml-auto mr-5' : ''},[(_vm.title)?_c('b-row',{style:(_vm.marginLeftCell ? 'margin-left: ' + _vm.marginLeftCell : '')},[_c('b-col',{class:_vm.orientation === 'center'
              ? 'text-left'
              : _vm.orientation === 'left'
              ? 'text-left'
              : 'text-right',style:(_vm.paddingTopCell ? 'padding-top: ' + _vm.paddingTopCell + ';' : ''),attrs:{"cols":12}},[_c('h1',{style:(_vm.titleSizeCell ? 'font-size: ' + _vm.titleSizeCell : 'font-size: 9vw')},[_c('strong',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.title)}})])])])],1):_vm._e(),(_vm.text)?_c('b-row',{style:(_vm.marginLeftCell ? 'margin-left: ' + _vm.marginLeftCell : '')},[_c('b-col',{class:_vm.orientation === 'center'
              ? 'text-left'
              : _vm.orientation === 'left'
              ? 'text-left'
              : 'text-right',attrs:{"cols":12}},[_c('p',{style:(_vm.textSizeCell ? 'font-size: ' + _vm.textSizeCell : 'font-size: 6vw'),domProps:{"innerHTML":_vm._s(_vm.text)}})])],1):_vm._e(),(_vm.videoURL)?_c('b-row',[_c('b-embed',{attrs:{"type":"iframe","aspect":"16by9","src":_vm.videoURL,"allowfullscreen":""}})],1):_vm._e(),(_vm.learnMore)?_c('b-row',[_c('b-col',{class:_vm.orientation === 'center'
              ? 'text-center'
              : _vm.orientation === 'left'
              ? 'text-left'
              : 'text-right',attrs:{"cols":12}},[_c('b-button',{staticClass:"d-block font-weight-bold",staticStyle:{"background-color":"white"},attrs:{"variant":_vm.btnVariant,"pill":"","size":"lg","block":""},on:{"click":_vm.learnMore}},[_vm._v(" Conocer más ")])],1)],1):_vm._e()],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }