<template>
  <div>
    <GenericTest
      :test="test"
      :test_id="test_id"
      :answer="answer"
      :semester="semester"
      :previous_answer="previous_answer"
      :col_size="12"
      :label_size="8"
      :input_size="4"
      :no_margin="true"
    ></GenericTest>
  </div>
</template>

<script>
import GenericTest from "./GenericTest";

export default {
  components: { GenericTest },
  props: ["test_id", "student_id", "previous_answer", "semester"],
  watch: {
    student_id() {
      this.answer.student_id = this.student_id;
    },
    test_id() {
      this.answer.test = this.test_id;
    },
  },
  data() {
    return {
      answer: {
        test: this.test_id,
        answers: {},
        student_id: this.student_id,
        semester: this.semester,
      },
      test: [
        // Selector de edad
        {
          label: "Seleccione la edad del niño/a:",
          type: "select",
          var: "edad",
          options: [
            { value: "3-4", text: "3-4 años" },
            { value: "5-6", text: "5-6 años" },
            { value: "7-8", text: "7-8 años" },
            { value: "9-10", text: "9-10 años" },
            { value: "11-14", text: "11-14 años" },
          ],
        },
        // Procesamiento AUDITIVO
        {
          type: "h3",
          label: "Procesamiento AUDITIVO",
        },
        {
          label: "Item 1 (RE): se pierde intentando seguir las instrucciones orales más que otros alumnos de su edad.",
          type: "number",
          var: "item_1",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 2 (RE): no me presta atención o parece ignorarme.",
          type: "number",
          var: "item_2",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 3 (RE): le cuesta terminar las tareas en ambientes ruidosos.",
          type: "number",
          var: "item_3",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 4 (SE): les dice a los demás que se callen.",
          type: "number",
          var: "item_4",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 5 (EV): se angustia en las actividades grupales, a la hora de comer o en otros actos colectivos.",
          type: "number",
          var: "item_5",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 6 (SE): reacciona intensamente a sonidos fuertes o inesperados (p. ej., alarma de incendio, libros que caen al suelo, portazos, avisos por megafonía, timbres).",
          type: "number",
          var: "item_6",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 7 (SE): tiene dificultad para participar en actividades de grupo cuando hay muchas personas hablando.",
          type: "number",
          var: "item_7",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        // Procesamiento VISUAL
        {
          type: "h3",
          label: "Procesamiento VISUAL",
        },
        {
          label: "Item 8 (RE): se pierde intentando seguir las instrucciones escritas o las demostraciones más que otros alumnos de su edad.",
          type: "number",
          var: "item_8",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 9 (RE): le cuesta tener los materiales necesarios preparados para usarlos durante el día.",
          type: "number",
          var: "item_9",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 10 (RE): deja en blanco respuestas de una hoja llena de ejercicios a pesar de saberlas.",
          type: "number",
          var: "item_10",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 11 (BU): mira a las personas que se mueven por la habitación.",
          type: "number",
          var: "item_11",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 12 (SE): aparta la mirada de sus tareas para observar lo que sucede a su alrededor.",
          type: "number",
          var: "item_12",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 13 (RE): pierde el contacto visual conmigo cuando interactúo con él en el día a día.",
          type: "number",
          var: "item_13",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 14 (BU): le atraen las pantallas (TV, ordenador, móvil, etc.) con imágenes de colores vivos y en movimiento.",
          type: "number",
          var: "item_14",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        // Procesamiento TÁCTIL
        {
          type: "h3",
          label: "Procesamiento TÁCTIL",
        },
        {
          label: "Item 15 (BU): se acerca demasiado a las personas cuando hablan cara a cara.",
          type: "number",
          var: "item_15",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 16 (RE): parece no darse cuenta de que tiene las manos o la cara sucias.",
          type: "number",
          var: "item_16",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 17 (BU): toca tanto a las personas o las cosas que llega a molestar a los demás.",
          type: "number",
          var: "item_17",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 18 (BU): muestra la necesidad de tocar cosas, superficies o texturas (p. ej., quiere tocarlo todo).",
          type: "number",
          var: "item_18",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 19 (SE): quiere limpiarse las manos rápidamente cuando hace alguna tarea que ensucia.",
          type: "number",
          var: "item_19",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 20 (SE): se enfada con facilidad si se hace un poco de daño (p. ej., al golpearse con algo, hacerse un rasguño o cortarse).",
          type: "number",
          var: "item_20",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 21 (SE): usa sólo las puntas de los dedos al realizar tareas de manipulación.",
          type: "number",
          var: "item_21",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 22 (EV): se estremece o se aparta cuando alguien lo toca o se le acerca mucho.",
          type: "number",
          var: "item_22",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        // Procesamiento MOVIMIENTO
        {
          type: "h3",
          label: "Procesamiento MOVIMIENTO",
        },
        {
          label: "Item 23 (RE): no sujeta adecuadamente los materiales cuando trabaja (p. ej., no sujeta la hoja de papel en la que escribe).",
          type: "number",
          var: "item_23",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 24 (BU): juega con las cosas o las toquetea (p. ej., lápices, libretas, carpetas).",
          type: "number",
          var: "item_24",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 25 (SE): está inquieto o molesta a los demás cuando está de pie en una fila o cerca de otras personas (p. ej., al ir en autobús, entrar en la escuela, estar sentado en reuniones escolares, actividades grupales, etc.).",
          type: "number",
          var: "item_25",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 26 (RE): se sienta incorrectamente en la silla (p. ej., repanchingado, curvado, medio tumbado).",
          type: "number",
          var: "item_26",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 27 (RE): choca con las cosas, sin darse cuenta de los objetos o personas que hay en su camino.",
          type: "number",
          var: "item_27",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 28 (BU): no para quieto.",
          type: "number",
          var: "item_28",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 29 (BU): parece tener un sinfín de razones para dirigirse al profesor.",
          type: "number",
          var: "item_29",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 30 (EV): participa en las tareas o actividades físicamente activas con un ritmo más lento que otros alumnos de su edad.",
          type: "number",
          var: "item_30",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 31 (EV): está de pie o sentado a un lado del patio durante el recreo.",
          type: "number",
          var: "item_31",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 32 (EV): rechaza participar en los juegos de equipo (p. ej., fútbol, baloncesto).",
          type: "number",
          var: "item_32",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        // Respuesta CONDUCTUAL
        {
          type: "h3",
          label: "Respuesta CONDUCTUAL",
        },
        {
          label: "Item 33 (RE): hace las cosas de una forma más complicada de lo necesario (p. ej., pierde el tiempo, se mueve lentamente).",
          type: "number",
          var: "item_33",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 34 (RE): parece cansado (p. ej., no tiene energía, está decaído).",
          type: "number",
          var: "item_34",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 35 (SE): podría decirse que reacciona de forma exagerada o dramática en comparación con otros alumnos de su edad.",
          type: "number",
          var: "item_35",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 36 (EV): carece de sentido del humor.",
          type: "number",
          var: "item_36",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 37 (EV): podría decirse que es inflexible en comparación con otros alumnos de su edad.",
          type: "number",
          var: "item_37",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 38 (EV): se angustia cuando cambian los planes, las rutinas o las expectativas.",
          type: "number",
          var: "item_38",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 39 (SE): puede ser terco y poco dispuesto a colaborar.",
          type: "number",
          var: "item_39",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 40 (EV): persevera en su conducta hasta el punto de afectar a la participación en actividades (p. ej., no es capaz de variar su velocidad o ritmo).",
          type: "number",
          var: "item_40",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 41 (EV): se retrae cuando cambia el entorno o una rutina.",
          type: "number",
          var: "item_41",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 42 (EV): se frustra fácilmente.",
          type: "number",
          var: "item_42",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 43 (EV): interactúa o participa menos en los grupos que otros alumnos de su edad.",
          type: "number",
          var: "item_43",
          min: 0,
          max: 5,
          maxlength: 1,
        },
        {
          label: "Item 44 (SE): le molesta que no se cumplan las reglas.",
          type: "number",
          var: "item_44",
          min: 0,
          max: 5,
          maxlength: 1,
        },
      ],
    };
  },
};
</script>

<style scoped>
.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #FFF3E0; /* Fondo amarillo claro */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #E65100; /* Naranjo oscuro */
}

::v-deep h3 {
  margin-top: 50px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
}

.select-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #E65100;
  border-radius: 5px;
  background-color: #FFF3E0;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.table th,
.table td {
  padding: 10px;
  border: 1px solid #E65100;
}

.input-field {
  width: 50px;
  text-align: center;
  border: 1px solid #E65100;
  border-radius: 5px;
  padding: 5px;
  background-color: #FFF3E0;
}

.submit-btn {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #FF9800; /* Naranjo fuerte */
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #FB8C00; /* Naranjo más oscuro */
}

/* Mantener la estética del formulario original */
.b-form-group .col-form-label {
  text-align: left !important;
}
</style>