import api from "@/services/api";
import blob from "@/services/blob";

export default {
  do_test(payload) {
    return api.post("do_test/", payload).then((response) => response.data);
  },
  download_test(payload) {
    return api
      .post("download_test/", payload)
      .then((response) => response.data);
  },
  getPreviousAnswers(payload) {
    return api
      .post("get_previous_answers/", payload)
      .then((response) => response.data);
  },
  get_my_tests(payload) {
    return api.post("get_my_tests/", payload).then((response) => response.data);
  },
  get_my_lastest_tests(payload) {
    return api
      .post("get_my_lastest_tests/", payload)
      .then((response) => response.data);
  },
  save_name_test(payload) {
    return api
      .post("save_name_test/", payload)
      .then((response) => response.data);
  },
  send_planification(payload) {
    return blob
      .post("send_planification/", payload)
      .then((response) => response.data);
  },
  getTests() {
    return api.get("get_tests/").then((response) => response.data);
  },
  send_test(payload) {
    return api.post("send_test/", payload).then((response) => response.data);
  },
  delete_test(payload) {
    return api.post("delete_test/", payload).then((response) => response.data);
  },
  getDocumentType(payload) {
    return api
      .post("get_document_type/", payload)
      .then((response) => response.data);
  },
};
