<template>
    <b-modal ref="quick-eureka" hide-footer size="xl">
        <b-container>
            <b-row>
                <h3>Datos estudiante</h3>
            </b-row>
            <b-row>
                Estudiante: {{ student }}
            </b-row>
            <b-row>
                RUT: {{ rut }}
            </b-row>
            <b-row>
                Curso: {{ grade }}
            </b-row>
            <b-row>
                <h3>Eureka</h3>
            </b-row>
            <b-row>
                <eureka-table :studentGradeID="studentID"/>
            </b-row>
            <!-- <b-row>
                <h3>Tests no aplicados</h3>
            </b-row>
            <b-row v-for="(test, index) in unappliedTests" :key="index+'-test'">
                <b-button @click="getLink(test.id)" pill variant="primary">
                    {{ test.name }}
                </b-button>
            </b-row>
            <b-row v-if="!unappliedTests.length">
                No hay tests no aplicados.
            </b-row>
            <b-row>
                <h3>Tests no completados</h3>
            </b-row>
            <b-row v-for="(test, index2) in unfinishedTests" :key="index2+'-test-uncomplete'">
                <b-button @click="copyToClipboard(test.id)" pill variant="primary">
                    {{ test.test_name }}
                </b-button>
            </b-row>
            <b-row v-if="!unfinishedTests.length">
                No hay tests no completados.
            </b-row> -->
            <b-row>
                <h3>Materiales no aplicados</h3>
            </b-row>
            <b-row v-for="(material, mIndex) in unappliedMaterials" :key="mIndex+'-material'">
                <b-button @click="getLink(material.id)" pill variant="primary">
                    {{ material.name }}
                </b-button>
            </b-row>
            <b-row v-if="!unappliedMaterials.length">
                No hay materiales no aplicados.
            </b-row>
            <b-row>
                <h3>Materiales no completados</h3>
            </b-row>
            <b-row v-for="(material, mIndex2) in unfinishedMaterials" :key="mIndex2+'-material-uncomplete'">
                <b-button @click="copyToClipboardMaterial(material.id)" pill variant="primary">
                    {{ material.test_name }}
                </b-button>
            </b-row>
            <b-row v-if="!unfinishedMaterials.length">
                No hay materiales no completados.
            </b-row>
        </b-container>
    </b-modal>
</template>
<script>
import customTestService from "../../../services/customTestService";
import customMaterialService from "../../../services/customMaterialService";
import EurekaTable from "@/components/redpie/miscellaneous/EurekaTable";

export default {
    components: {
        EurekaTable
    },
    data() {
        return {
            studentID: '',
            student: '',
            studentEmail: '',
            gradeID: '',
            grade: '',
            unfinishedTests: [],
            unappliedTests: [],
            unfinishedMaterials: [],
            unappliedMaterials: [],
            rut: '',
        }
    },
    methods: {
        open(data) {
            this.clear();
            this.studentEmail = data.student_email;
            this.studentID = data.id;
            this.student = data.student_name;
            this.gradeID = data.grade;
            this.grade = data.grade_name;
            this.rut = data.student_rut;
            this.unappliedTests = data.unapplied_tests;
            this.unfinishedTests = data.unfinished_tests;
            this.unappliedMaterials = data.unapplied_materials;
            this.unfinishedMaterials = data.unfinished_materials;
            this.$refs["quick-eureka"].show()
        },
        clear() {
            this.studentEmail = '';
            this.studentID = '';
            this.rut = '';
            this.student = '';
            this.gradeID = '';
            this.grade = '';
            this.tests = [];
        },
        copyToClipboard(id) {
            let testURL = `${process.env['VUE_APP_BASE_URL']}/tests#/t/${id}`
            window.open(testURL);
        },
        copyToClipboardMaterial(id) {
            let testURL = `${process.env['VUE_APP_BASE_URL']}/materials#/t/${id}`
            window.open(testURL);
        },
        getLink(testID) {
            customTestService.generateLink({
                test: testID,
                rut: this.rut,
                email: this.studentEmail,
                name: this.student,
                videocall_kind: 3, //aplicacion presencial
                student_grade: this.studentID
            }).then(
                data => {
                    let testURL = `${process.env['VUE_APP_BASE_URL']}/tests#/t/${data.id}`;
                    window.open(testURL);
                },
                () => this.$toasted.error("Ocurrió un error. Intenta nuevamente.")
            )
        },
        getLinkMaterial(materialID) {
            customMaterialService.generateLink({
                test: materialID,
                rut: this.rut,
                email: this.studentEmail,
                name: this.student,
                videocall_kind: 3, //aplicacion presencial
                student_grade: this.studentID
            }).then(
                data => {
                    let testURL = `${process.env['VUE_APP_BASE_URL']}/materials#/t/${data.id}`;
                    window.open(testURL);
                },
                () => this.$toasted.error("Ocurrió un error. Intenta nuevamente.")
            )
        },
    }
}
</script>
<style lang="scss">

</style>
