import api from '@/services/api';

export default {
  download_file(payload) {
    return api.post('download_file/', payload).then(response => response.data);
  },
  deleteAllFiles() {
    return api.post('delete_all_files/').then(response => response.data);
  },
  getRandomFiles() {
    return api.get('get_random_files/').then(response => response.data);
  },
  materialDownload() {
    return api.post('file-items/material_download_notification/').then(response => response.data);
  },
  generateNomina(payload) {
    return api.post('generate_sige_fulfilled/', payload).then(response => response.data);
  },
  downloadNomina(payload) {
    return api.post('download_sige_fulfilled/', payload).then(response => response.data);
  }
};
