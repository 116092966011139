<template>
  <div class="group">
    <div class="group-title">
      <b-button
        @click="manageCollapse"
        variant="unknown"
        class="no-outline border-none no-box-shadow text-dark"
      >
        <v-icon name="angle-down" v-if="!modelGroup" />
        <v-icon name="angle-up" v-if="modelGroup" />
        <span>{{ group.label }}</span>
      </b-button>
    </div>

    <hr />

    <b-collapse id="collapse-complete-graph-info-0" v-model="modelGroup">
      <div style="overflow-x:auto;">
        <div v-if="loading">
          <b-container>
            <b-row align-h="center" style="padding-bottom: 10px !important;">
              <b-spinner variant="success" label="Spinning"></b-spinner>
            </b-row>
          </b-container>
        </div>
        <template v-for="(subgroup, index3) in gradesList" v-else>
          <table border="0" class="text-dark" v-bind:key="index3">
            <thead>
              <tr class="subgroup-label bg-ultralight">
                <td colspan="100%">{{ subgroup.letter }}</td>
              </tr>
            </thead>
            <tbody>
              <tr
                class="subgroup-item bg-omglight-hover"
                v-for="(item, index4) in subgroup.students"
                v-bind:key="index4 + '-' + index3"
              >
                <ModalPieStudentSecondSpecialTable
                  :item="item"
                  :subgroup="subgroup"
                  :diagnosesList="diagnosesList"
                  v-on:reload-student="getGrades"
                  v-on:reload-grades="reloadGrades"/>
              </tr>
            </tbody>
          </table>
        </template>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import redpieService from "../../../services/redpieService";
import informationService from "../../../services/informationService"
import ModalPieStudentSecondSpecialTable from "../modals/ModalPieStudentSecondSpecialTable";

export default {
  components: {
    ModalPieStudentSecondSpecialTable,
  },
  props: {
    group: {
      type: Object,
      require: true,
    },
  },
  data() {
    // eslint-disable-next-line
    const modelGroup = false;
    const subgroups = [];

    return {
      diagnosesList: [],
      modelGroup,
      subgroups,
      gradesList: [],
      loading: false,
      diagnoses: [],
    };
  },
  created() {
    this.getDiagnosesList();
  },
  methods: {
    getDiagnosesList: function () {
      informationService.getDiagnosesList().then(
        data => {
          this.diagnosesList = data.grouped_diagnoses;
          this.diagnoses = data.diagnoses
        }
      )
    },
    selectTableValue(key, item) {
      console.log("key", key);
      console.log("item", item);
    },
    getGrades: function() {
      this.loading = true;
      redpieService
        .getGrades(this.$store.state.login.school, this.group.code)
        .then((data) => {
          this.gradesList = data[0].grades_students_documents;
          this.loading = false;
        });
    },
    manageCollapse: function() {
      this.modelGroup = !this.modelGroup;
      if (this.modelGroup && this.gradesList.length === 0) {
        this.getGrades();
      }
    },
    updateDiagnostic: function() {
      redpieService.updateBulkStudents(this.$store.state.login.school, {payload: this.gradesList})
    },
    reloadGrades: function() {
      this.$emit("reload-grades")
    }
  },
};
</script>

<style lang="scss">
.special-table {
  width: 100%;
  background-color: white;

  table {
    width: 100%;
    font-size: 0.7em;
    text-align: center;

    button {
      font-size: 1em;
      text-transform: uppercase;
      font-weight: bold;
    }

    .subgroup-label {
      text-align: left;
      font-size: 1em;
      font-weight: bold;

      td {
        padding: 2px 6px;
      }
    }

    .subgroup-item {
      td {
        padding: 4px 2px;
      }
    }

    .group-title-value {
      width: 256px;
      font-size: 1.4em;
      font-weight: bold;
      cursor: pointer;

      .title-value {
        padding: 0;
        height: 48px !important;
        line-height: 48px !important;
        border: solid 2px;
        border-radius: 24px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        span {
          font-size: 14px;
          line-height: normal;
          padding: 0 12px;
        }

        button {
          height: 48px;
          font-size: 0.65em;
          text-transform: unset;
          border-radius: 24px !important;
          color: white;
          line-height: 1;
        }
        button:first-child {
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }
        button:last-child {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      }
      .title-value > span.title-value-span {
        display: initial;
      }
      .title-value > div.title-value-buttons {
        display: none;
        width: 100%;

        .unique-button {
          border-radius: 24px !important;
          width: 100% !important;
        }
      }
      .title-value:hover > span.title-value-span {
        display: none;
      }
      .title-value:hover > div.title-value-buttons {
        display: initial;
      }
    }
  }

  .group {
    hr {
      height: 4px;
      margin: 0;
    }

    .group-title button {
      font-weight: bold !important;
      font-size: 0.9em;

      span {
        margin-left: 12px;
      }
    }
  }

  .item-additional-buttons {
    cursor: pointer;
  }
}
</style>
