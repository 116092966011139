<template>
  <b-container class="subscribe" fluid>
    <b-row class="p-4">
      <b-col class="mx-auto p-4 divider-left my-4" lg="6">
        <div class="text-center dec-width m-auto">
          <span class="h4 font-weight-bold">¡Déjanos tu Correo!</span><br />
          <p class="mx-auto text-center dec-width" v-if="route !== 'eureka'">
            Te enviaremos una cotización, el contrato y más detalles del
            servicio.
          </p>
          <p class="mx-auto text-center dec-width" v-if="route === 'eureka'">
            Te enviaremos una cotización y los detalles del servicio
          </p>
          <b-form @submit.prevent="requestEmail">
            <b-row class="mx-auto no-gutters">
              <b-col class="mt-2" lg="7">
                <b-input
                  v-model="email"
                  class="form-control rounded-pill"
                  placeholder="correo@ejemplo.cl"
                  type="email"
                  name="email"
                  required
                ></b-input>
              </b-col>
              <b-col class="mt-2" lg="5">
                <b-button
                  class="rounded-pill font-weight-bold"
                  type="submit"
                  variant="danger"
                >
                  Solicitar Envío
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </b-col>
      <b-col class="mx-auto p-4 my-4" lg="6">
        <div class="dec-width text-center m-auto">
          <span class="h4 font-weight-bold"
            >También puedes comunicarte directamente</span
          >
          <b-row class="mx-auto p-2" v-show="false">
            <b-button
              class="mx-auto rounded-pill mt-2 text-light font-weight-bold"
              variant="warning"
              >Descargar Cotización
            </b-button>
            <b-button
              class="mx-auto rounded-pill mt-2 text-light font-weight-bold"
              variant="warning"
              >Descargar Contrato
            </b-button>
          </b-row>
          <p class="dec-width mx-auto h6 mt-3">
            Háblanos a <b>contacto@redpie.cl</b> y llamando al
            <b>+569 5 066 41 86</b> o al <b>+569 9 223 88 89</b>
          </p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import redpieService from "@/services/redpieService";

export default {
  name: "Subscribe",
  props: {
    route: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      email: "",
    };
  },
  methods: {
    requestEmail() {
      const payload = { email: this.email, service: this.route };
      redpieService
        .requestSalesEmail(payload)
        .then((response) => {
          this.$toasted.success(response);
          this.email = "";
        })
        .catch((error) => {
          console.log(error);
          this.$toasted.error("Ocurrió un error. Intenta nuevamente.");
        });
    },
  },
};
</script>

<style scoped>
.subscribe {
  background-image: url("~@/assets/img/sales/subscribe.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #e6f1f3;
}

@media (min-width: 992px) {
  .divider-left {
    border-right: 2px solid #333;
  }

  .dec-width {
    width: 75%;
  }
}
</style>
