<template>
  <b-row class="m-1">
    <b-col
      :cols="question.number_of_images[1] > 0 ? '6' : '12'"
      class="d-flex flex-wrap"
      :class="
        question.number_of_images[1] > 0
          ? 'border-right justify-content-center'
          : 'mx-auto justify-content-center align-items-center'
      "
    >
      <div v-for="(image, index) in question.number_of_images[0]" :key="index">
        <b-img
          v-if="image_options.length > 0"
          :src="
            image_options.find(
              (img) => img.value === question.selected_images[0]
            ).src
          "
          :width="question.number_of_images[1] > 0 ? '60' : '70'"
          class="m-1"
          fluid
        />
      </div>
    </b-col>
    <b-col
      cols="6"
      class="d-flex flex-wrap justify-content-center"
      v-if="question.number_of_images[1] > 0"
    >
      <div v-for="(image, index) in question.number_of_images[1]" :key="index">
        <b-img
          v-if="image_options.length > 0"
          :src="
            image_options.find(
              (img) => img.value === question.selected_images[1]
            ).src
          "
          width="60"
          class="m-1"
          fluid
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import customMaterialService from "../../../services/customMaterialService";

export default {
  name: "CountQuestionComponent",
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      image_options: [],
    };
  },
  mounted() {
    this.getCountImages();
  },
  methods: {
    getCountImages() {
      customMaterialService.getCountImages().then((data) => {
        this.image_options = data;
      });
    },
  },
};
</script>
