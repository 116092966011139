var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{staticClass:"m-1"},[_c('b-col',{staticClass:"d-flex flex-wrap",class:_vm.question.number_of_images[1] > 0
        ? 'border-right justify-content-center'
        : 'mx-auto justify-content-center align-items-center',attrs:{"cols":_vm.question.number_of_images[1] > 0 ? '6' : '12'}},_vm._l((_vm.question.number_of_images[0]),function(image,index){return _c('div',{key:index},[(_vm.image_options.length > 0)?_c('b-img',{staticClass:"m-1",attrs:{"src":_vm.image_options.find(
            (img) => img.value === _vm.question.selected_images[0]
          ).src,"width":_vm.question.number_of_images[1] > 0 ? '60' : '70',"fluid":""}}):_vm._e()],1)}),0),(_vm.question.number_of_images[1] > 0)?_c('b-col',{staticClass:"d-flex flex-wrap justify-content-center",attrs:{"cols":"6"}},_vm._l((_vm.question.number_of_images[1]),function(image,index){return _c('div',{key:index},[(_vm.image_options.length > 0)?_c('b-img',{staticClass:"m-1",attrs:{"src":_vm.image_options.find(
            (img) => img.value === _vm.question.selected_images[1]
          ).src,"width":"60","fluid":""}}):_vm._e()],1)}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }