<template lang="">
  <div>
    <GenericTest
      :test="test"
      :test_id="test_id"
      :answer="answer"
      :col_size="6"
      :label_size="8"
      :input_size="4"
      :semester="semester"
      :previous_answer="previous_answer"
    ></GenericTest>
  </div>
</template>
<script>
import GenericTest from "./GenericTest";
export default {
  components: { GenericTest },
  props: ["test_id", "student_id", "previous_answer", "semester"],
  watch: {
    student_id() {
      this.answer.student_id = this.student_id;
    },
    test_id() {
      this.answer.test = this.test_id;
    },
  },
  data() {
    return {
      answer: {
        test: this.test_id,
        answers: {
          metodo_discrepancia: "valor_critico",
          tasa_base_significante: "15",
          tipo: "Bruto",
          pais: "Chile",
          confianza: "95%",
        },
        student_id: this.student_id,
      },
      test: [
        {
          label: "Construcción con cubos (Diseño con cubos):",
          type: "number",
          var: "cubos",
          maxlength: "3",
        },
        {
          label: "Analogías (Semejanzas):",
          type: "number",
          var: "analogias",
          maxlength: "3",
        },
        {
          label: "Retención de dígitos:",
          type: "number",
          var: "retencion_de_digitos",
          maxlength: "3",
        },
        {
          label: "Matrices de razonamiento:",
          type: "number",
          var: "matrices_de_razonamiento",
          maxlength: "3",
        },
        {
          label: "Vocabulario:",
          type: "number",
          var: "vocabulario",
          maxlength: "3",
        },
        {
          label: "Arimética:",
          type: "number",
          var: "aritmetica",
          maxlength: "3",
        },
        {
          label: "Búsqueda de símbolos:",
          type: "number",
          var: "busqueda_de_simbolos",
          maxlength: "3",
        },
        {
          label: "Rompecabezas visuales:",
          type: "number",
          var: "rompecabezas_visuales",
          maxlength: "3",
        },
        {
          label: "Información:",
          type: "number",
          var: "informacion",
          maxlength: "3",
        },
        { label: "Claves:", type: "number", var: "claves", maxlength: "3" },
        {
          label: "Secuenciación Letras-Números:",
          type: "number",
          var: "secuenciacion_letras_numeros",
          maxlength: "3",
        },
        {
          label: "Balanzas (Peso figurado):",
          type: "number",
          var: "balanzas",
          maxlength: "3",
        },
        {
          label: "Comprensión:",
          type: "number",
          var: "comprension",
          maxlength: "3",
        },
        {
          label: "Cancelación:",
          type: "number",
          var: "cancelacion",
          maxlength: "3",
        },
        {
          label: "Figuras incompletas:",
          type: "number",
          var: "figuras_incompletas",
          maxlength: "3",
        },
        {
          label: "Método para calcular la discrepancia:",
          var: "metodo_discrepancia",
          type: "select",
          options: [
            { value: "valor_critico", text: "Valor crítico" },
            {
              value: "muestra_total",
              text: "Tasa base: Comparar con Muestra total",
            },
            {
              value: "igual_capacidad",
              text: "Tasa base: Comparar con Nivel de habilidad",
            },
          ],
        },
        {
          label: "Tasa base significante:",
          var: "tasa_base_significante",
          type: "select",
          options: [
            { value: "5", text: "5%" },
            { value: "6", text: "6%" },
            { value: "7", text: "7%" },
            { value: "8", text: "8%" },
            { value: "9", text: "9%" },
            { value: "10", text: "10%" },
            { value: "11", text: "11%" },
            { value: "12", text: "12%" },
            { value: "13", text: "13%" },
            { value: "14", text: "14%" },
            { value: "15", text: "15% (recomendado)" },
            { value: "16", text: "16%" },
            { value: "17", text: "17%" },
            { value: "18", text: "18%" },
            { value: "19", text: "19%" },
            { value: "20", text: "20%" },
            { value: "21", text: "21%" },
            { value: "22", text: "22%" },
            { value: "23", text: "23%" },
            { value: "24", text: "24%" },
            { value: "25", text: "25%" },
            { value: "26", text: "26%" },
            { value: "27", text: "27%" },
            { value: "28", text: "28%" },
            { value: "29", text: "29%" },
            { value: "30", text: "30%" },
            { value: "31", text: "31%" },
            { value: "32", text: "32%" },
            { value: "33", text: "33%" },
            { value: "34", text: "34%" },
            { value: "35", text: "35%" },
          ],
        },
        {
          label: "Tipo de puntaje:",
          type: "select",
          var: "tipo",
          options: [
            { value: "Bruto", text: "Bruto" },
            { value: "Equivalente", text: "Equivalente" },
          ],
        },
        {
          label: "País:",
          type: "select",
          var: "pais",
          options: [
            { value: "Chile", text: "Chile" },
            { value: "Mexico", text: "México" },
            { value: "Venezuela", text: "Venezuela" },
          ],
        },
        {
          label: "Edad del evaluado:",
          var: "edad",
          type: "select",
          options: [
            { value: "16 a 17", text: "16 a 17 años" },
            { value: "18 a 19", text: "18 a 19 años" },
            { value: "20 a 24", text: "20 a 24 años" },
            { value: "25 a 29", text: "25 a 29 años" },
            { value: "30 a 34", text: "30 a 34 años" },
            { value: "35 a 44", text: "35 a 44 años" },
            { value: "45 a 54", text: "45 a 54 años" },
            { value: "55 a 64", text: "55 a 64 años" },
            { value: "65 a 69", text: "65 a 69 años" },
            { value: "70 a 74", text: "70 a 74 años" },
            { value: "75 a 79", text: "75 a 79 años" },
            { value: "80 a 84", text: "80 a 84 años" },
            { value: "85 a 89", text: "85 a 89 años" },
          ],
        },
        {
          label: "Nivel de confianza:",
          var: "confianza",
          type: "select",
          options: [
            { value: "95%", text: "95%" },
            { value: "90%", text: "90%" },
          ],
        },
      ],
    };
  },
};
</script>
<style lang=""></style>
