<template>
  <div class="documents-enlarge-graph radius-box-8 padding-box-16">
    <h2>¿Cómo vamos con los documentos?</h2>
    <div class="image"><img src="../../../assets/img/graph-small.png" /></div>
    <div class="link"><a v-on:click="onclick()">CLIC PARA AMPLIAR</a></div>
  </div>
</template>

<script>
export default {
  methods: {
    onclick() {
      this.$emit("onclick");
    }
  }
};
</script>

<style lang="scss">
.documents-enlarge-graph {
  width: 100%;
  background-color: white;

  h2 {
    font-size: 1em;
    font-weight: bold;
  }
  .image {
    text-align: center;
    width: 100%;
  }
  .link {
    width: 100%;
    text-align: center;
    cursor: pointer;

    a {
      font-size: 0.8em;
      text-decoration: underline;
      color: $secondary-dark !important;
    }
  }
}
</style>
