<template lang="">
  <div>
    <b-row>
      <b-col cols="6">
        <b-select v-model="answer.kind">
          <b-form-select-option :value="null"
            >Selecciona un CLPT</b-form-select-option
          >
          <b-form-select-option
            v-for="(test, index) in tests"
            :key="index"
            :value="index + 1"
            >CLPT {{ index + 1 }}</b-form-select-option
          >
        </b-select>
      </b-col>
    </b-row>
    <GenericTest
      v-if="answer.kind != null"
      :test="tests[answer.kind - 1]"
      :test_id="test_id"
      :answer="answer"
      :semester="semester"
      :previous_answer="previous_answer"
    ></GenericTest>
  </div>
</template>
<script>
import GenericTest from "./GenericTest";
export default {
  components: { GenericTest },
  props: ["test_id", "student_id", "previous_answer", "semester"],
  watch: {
    student_id() {
      this.answer.student_id = this.student_id;
    },
    test_id() {
      this.answer.test = this.test_id;
    },
  },
  data() {
    return {
      answer: {
        test: this.test_id,
        answers: {},
        student_id: this.student_id,
        kind: null,
      },
      tests: [
        [
          { type: "", label: "" },
          { type: "h3", label: "1" },
          {
            type: "radio",
            label: "",
            var: "uno_1_CL",
            options: [
              { value: "1", text: "Autor: Inés Rigal" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "radio",
            label: "",
            var: "uno_2_CL",
            options: [
              { value: "1", text: "Título: La noche de la luz" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "radio",
            label: "",
            var: "uno_3_CL",
            options: [
              { value: "1", text: "Editorial: Editorial Caminante" },
              { value: "0", text: "Otro" },
            ],
          },
          { type: "h3", label: "2" },
          {
            type: "radio",
            label: "",
            var: "dos_CL",
            options: [
              { value: "0", text: "A" },
              { value: "1", text: "B" },
              { value: "00", text: "C" },
            ],
          },
          { type: "h3", label: "3" },
          {
            type: "radio",
            label: "",
            var: "tres_CL",
            options: [
              { value: "0", text: "A" },
              { value: "00", text: "B" },
              { value: "2", text: "C" },
            ],
          },
          { type: "h3", label: "4" },
          {
            type: "select",
            label: "",
            var: "cuatro_CL",
            options: [
              { value: "1", text: "1" },
              { value: "2", text: "2" },
              { value: "3", text: "3" },
              { value: "4", text: "4" },
              { value: "5", text: "5" },
            ],
          },
          { type: "h3", label: "5" },
          {
            type: "radio",
            label: "",
            var: "cinco_CL",
            options: [
              { value: "0", text: "A" },
              { value: "2", text: "B" },
              { value: "00", text: "C" },
            ],
          },
          { type: "h3", label: "6" },
          {
            type: "radio",
            label: "",
            var: "seis_1_CL",
            options: [
              { value: "2", text: "V" },
              { value: "0", text: "F" },
            ],
          },
          {
            type: "radio",
            label: "",
            var: "seis_2_CL",
            options: [
              { value: "0", text: "V" },
              { value: "2", text: "F" },
            ],
          },
          {
            type: "radio",
            label: "",
            var: "seis_3_CL",
            options: [
              { value: "2", text: "V" },
              { value: "0", text: "F" },
            ],
          },
          { type: "h3", label: "7" },
          {
            type: "radio",
            label: "",
            var: "siete_CL",
            options: [
              { value: "3", text: "A" },
              { value: "0", text: "B" },
              { value: "00", text: "C" },
            ],
          },
          { type: "h3", label: "8" },
          {
            type: "select",
            label: "Intención comunicativa:",
            var: "ocho_1_PT",
            options: [
              { value: "1", text: "Escribe de qué se trata el cuento" },
              {
                value: "0",
                text: "no dice de qué se trata el cuento o dice algo erróneo",
              },
            ],
          },
          {
            type: "select",
            label: "Presentación:",
            var: "ocho_2_PT",
            options: [
              { value: "1", text: "Apariencia limpia y agradable" },
              { value: "0.5", text: "Apariencia poco limpia y agradable" },
              { value: "0", text: "Estructura ilegible" },
            ],
          },
          {
            type: "select",
            label: "Presentación:",
            var: "ocho_3_PT",
            options: [
              { value: "2", text: "Escribe de manera clara y precisa 3 ideas" },
              {
                value: "1",
                text: "Escribe de manera clara y precisa 1 ó 2 ideas",
              },
              {
                value: "0",
                text: "Su escrito no incluye los elementos esenciales o detalles interesantes",
              },
            ],
          },
          {
            type: "select",
            label: "Comprensión del tema:",
            var: "ocho_4_PT",
            options: [
              { value: "2", text: "Muestra comprensión del cuento" },
              { value: "1", text: "Muestra comprensión incompleta" },
              {
                value: "0",
                text: "Muestra incomprensión del cuento o incluye elementos no pertinentes",
              },
            ],
          },
          {
            type: "select",
            label: "Capacidad expresiva personal:",
            var: "ocho_5_PT",
            options: [
              {
                value: "2",
                text: "Escribe de manera original y vital, captando el interés del lector",
              },
              {
                value: "1",
                text: "Narra de manera poco original y/o vital, escaso interés para el lector",
              },
              {
                value: "0",
                text: "No muestra originalidad y no capta el interés del lector",
              },
            ],
          },
          {
            type: "select",
            label: "Vocabulario preciso:",
            var: "ocho_7_PT",
            options: [
              {
                value: "1",
                text: "Usa palabras precisas pertinentes al cuento",
              },
              {
                value: "0.5",
                text: "Usa palabras poco precisas y poco pertinentes al cuento",
              },
              { value: "0", text: "Usa palabras incorrectas y/o inadecuadas" },
            ],
          },
          {
            type: "select",
            label: "Vocabulario variado:",
            var: "ocho_8_PT",
            options: [
              { value: "1", text: "Usa diversidad de palabras" },
              { value: "0.5", text: "Usa vocabulario elemental" },
              { value: "0", text: "Usa vocabulario muy restringido" },
            ],
          },
          {
            type: "select",
            label: "Ideas fluyen con naturalidad:",
            var: "ocho_9_PT",
            options: [
              { value: "1", text: "Permite lectura ágil y amena" },
              {
                value: "0.5",
                text: "Presenta 1 ó 2 inconsistencias en el hilo conductor",
              },
              { value: "0", text: "No permite seguir el hilo de ideas" },
            ],
          },
          {
            type: "select",
            label: "Presencia de conectores:",
            var: "ocho_10_PT",
            options: [
              {
                value: "1",
                text: "Utiliza conectores pertinentes y diversos cada vez que se requiere",
              },
              {
                value: "0.5",
                text: "Falta 1 ó 2 conectores en alguna parte del texto",
              },
              {
                value: "0",
                text: "Faltan 3 o más conectores o éstos son repetitivos",
              },
            ],
          },
          {
            type: "select",
            label: "Estructura textual pertinente:",
            var: "ocho_11_PT",
            options: [
              {
                value: "4",
                text: "El cuento está completo y bien organizado, con un inicio, desarrollo y final",
              },
              { value: "3", text: "Tiene inicio y desarrollo únicamente" },
              {
                value: "2",
                text: "tiene inicio o desarrollo y final únicamente",
              },
              { value: "1", text: "Tiene sólo inicio" },
              {
                value: "1.0",
                text: "El escrito tiene un inicio, desarrollo y final, pero están confusamente definidos o en desorden",
              },
            ],
          },
          {
            type: "select",
            label: "Sintaxis:",
            var: "ocho_12_PT",
            options: [
              { value: "0.5", text: "oraciones con orden lógico" },
              { value: "0.25", text: "1 error de sintaxis" },
              { value: "0", text: "Oraciones incomprensibles" },
            ],
          },
          {
            type: "select",
            label: "Morfosintaxis:",
            var: "ocho_13_PT",
            options: [
              { value: "0.5", text: "genero y número son concordantes" },
              { value: "0.25", text: "hay 1 o 2 errores" },
              { value: "0", text: "hay más de 2 errores" },
            ],
          },
          {
            type: "select",
            label: "Ortografía:",
            var: "ocho_14_PT",
            options: [
              {
                value: "0.5",
                text: "Usa mayúscula al comienzo de la oración, sin escritura en carro y sin inversiones o 1 error",
              },
              { value: "0.25", text: "hay 2 o 3 errores" },
              { value: "0", text: "hay más de 3 errores" },
            ],
          },
          {
            type: "select",
            label: "Puntual:",
            var: "ocho_15_PT",
            options: [
              { value: "0.5", text: "uso adecuado del punto final" },
              { value: "0", text: "sin punto final" },
            ],
          },
          {
            type: "select",
            label: "Reflexión sobre el significado:",
            var: "ocho_6_PT",
            options: [
              {
                value: "2",
                text: "Muestra reflexión sobre el significado del cuento",
              },
              { value: "1", text: "Su reflexión es escasa o superficial" },
              { value: "0", text: "no muestra reflexión" },
            ],
          },
          { type: "h3", label: "9" },
          {
            type: "radio",
            label: "1):",
            var: "nueve_1_CL",
            options: [
              { value: "0.5", text: "5" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "radio",
            label: "2):",
            var: "nueve_2_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "radio",
            label: "3):",
            var: "nueve_3_CL",
            options: [
              { value: "0.5", text: "3" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "radio",
            label: "4):",
            var: "nueve_4_CL",
            options: [
              { value: "0.5", text: "2" },
              { value: "0", text: "Otro" },
            ],
          },
          { type: "h3", label: "10" },
          {
            type: "radio",
            label: "1):",
            var: "diez_1_CL",
            options: [
              { value: "2", text: "b" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "radio",
            label: "2):",
            var: "diez_2_CL",
            options: [
              { value: "2", text: "c" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "radio",
            label: "3):",
            var: "diez_3_CL",
            options: [
              { value: "2", text: "c" },
              { value: "0", text: "Otro" },
            ],
          },
          { type: "h3", label: "11" },
          {
            type: "select",
            label: "Escribir acción:",
            var: "once_1_PT",
            options: [
              {
                value: "2",
                text: "camilo reparte los libros el martes/reparte los libros el martes",
              },
              { value: "1", text: "Escribe sólo la acción o el día" },
              { value: "0", text: "No escribe ni la acción ni el día" },
              { value: "00", text: "Su escrito es incomprensible" },
            ],
          },
          {
            type: "select",
            label: "Escribir su nombre:",
            var: "once_2_PT",
            options: [
              {
                value: "0.5",
                text: "Escribe su nombre en el casillero correspondiente de manera comprensible",
              },
              {
                value: "0",
                text: "Escribe su nombre de manera comprensible en otro casillero, o es incomprensible",
              },
            ],
          },
          {
            type: "select",
            label: "Escribir su nombre con mayúscula:",
            var: "once_3_PT",
            options: [
              {
                value: "0.5",
                text: "Escribe su nombre de manera comprensible con mayúscula",
              },
              { value: "0", text: "No escribe su nombre con mayúscula" },
            ],
          },
          {
            type: "select",
            label: "Escribir nombres:",
            var: "once_4_PT",
            options: [
              {
                value: "1.5",
                text: "Escribe los siguientes nombres de manera comprensible: Claudia, María y Óscar",
              },
              { value: "1", text: "Escribe 2 nombres" },
              { value: "0.5", text: "Escribe 1 nombre" },
              {
                value: "0",
                text: "No escribe los nombres o su escritura es incomprensible",
              },
            ],
          },
          {
            type: "select",
            label: "Escribe con mayúscula:",
            var: "once_5_PT",
            options: [
              {
                value: "0.5",
                text: "Escribe los 3 nombres de manera comprensible con mayúscula",
              },
              { value: "0.25", text: "1 ó 2 errores" },
            ],
          },
          {
            type: "select",
            label: "Escribe de manera comprensible:",
            var: "once_6_PT",
            options: [
              {
                value: "3",
                text: "Para que cada niño sepa su responsabilidad / para organizar la clase / para que sepamos lo que tenemos que hacer / para cooperar u otra cosa relacionada con la función del cuadro",
              },
              { value: "1", text: "Su respuesta es incompleta" },
              {
                value: "0",
                text: "Su respuesta carece de sentido o es incomprensible",
              },
            ],
          },
          {
            type: "multiselect",
            label: "Errores",
            var: "once_7_PT",
            options: [
              {
                value: "-0.25",
                text: "Omite punto al final",
              },
              { value: "-0.250", text: "Hay más de 1 error de concordancia" },
              {
                value: "-0.2500",
                text: "Hay más de 1 error de escritura en carro u omisiones",
              },
            ],
          },
          { type: "h3", label: "12" },
          {
            type: "radio",
            label: "",
            var: "doce_CL",
            options: [
              { value: "0", text: "A" },
              { value: "1", text: "B" },
              { value: "00", text: "C" },
            ],
          },
          { type: "h3", label: "13" },
          {
            type: "radio",
            label: "A):",
            var: "trece_1_CL",
            options: [
              { value: "1", text: "V" },
              { value: "0", text: "F" },
            ],
          },
          {
            type: "radio",
            label: "B):",
            var: "trece_2_CL",
            options: [
              { value: "0", text: "V" },
              { value: "1", text: "F" },
            ],
          },
          {
            type: "radio",
            label: "C):",
            var: "trece_3_CL",
            options: [
              { value: "0", text: "V" },
              { value: "2", text: "F" },
            ],
          },
          {
            type: "radio",
            label: "D):",
            var: "trece_4_CL",
            options: [
              { value: "2", text: "V" },
              { value: "0", text: "F" },
            ],
          },
          { type: "h3", label: "14" },
          {
            type: "select",
            label: "Grupos:",
            var: "catorce_1_ML",
            options: [
              { value: "0", text: "1" },
              { value: "1", text: "2" },
              { value: "00", text: "3" },
              { value: "000", text: "4" },
            ],
          },
          {
            type: "select",
            label: "Del:",
            var: "catorce_2_ML",
            options: [
              { value: "1", text: "1" },
              { value: "0", text: "2" },
              { value: "00", text: "3" },
              { value: "000", text: "4" },
            ],
          },
          {
            type: "select",
            label: "Familiares:",
            var: "catorce_3_ML",
            options: [
              { value: "0", text: "1" },
              { value: "00", text: "2" },
              { value: "000", text: "3" },
              { value: "1", text: "4" },
            ],
          },
          {
            type: "select",
            label: "Europa:",
            var: "catorce_4_ML",
            options: [
              { value: "0", text: "1" },
              { value: "00", text: "2" },
              { value: "1", text: "3" },
              { value: "000", text: "4" },
            ],
          },
          { type: "h3", label: "15" },
          {
            type: "radio",
            var: "quince_1_ML",
            label: "1):",
            options: [
              { value: "0.5", text: "Asia" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "radio",
            var: "quince_2_ML",
            label: "2):",
            options: [
              { value: "0.5", text: "Mamífero" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "radio",
            var: "quince_3_ML",
            label: "3):",
            options: [
              { value: "0.5", text: "Kilo" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "radio",
            var: "quince_4_ML",
            label: "4):",
            options: [
              { value: "0.5", text: "Europa" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "radio",
            var: "quince_5_ML",
            label: "5):",
            options: [
              { value: "0.5", text: "Bosques" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "radio",
            var: "quince_6_ML",
            label: "6):",
            options: [
              { value: "0.5", text: "Carnívoro" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "radio",
            var: "quince_7_ML",
            label: "7):",
            options: [
              { value: "0.5", text: "Domésticos" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "radio",
            var: "quince_8_ML",
            label: "8):",
            options: [
              { value: "0.5", text: "Reptiles" },
              { value: "0", text: "Otro" },
            ],
          },
          { type: "h3", label: "16" },
          {
            type: "radio",
            var: "dieciseis_1_ML",
            label: "1):",
            options: [
              { value: "0.5", text: "C" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "radio",
            var: "dieciseis_2_ML",
            label: "2):",
            options: [
              { value: "0.5", text: "F" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "radio",
            var: "dieciseis_3_ML",
            label: "3):",
            options: [
              { value: "0.5", text: "I" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "radio",
            var: "dieciseis_4_ML",
            label: "4):",
            options: [
              { value: "0.5", text: "N" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "radio",
            var: "dieciseis_5_ML",
            label: "5):",
            options: [
              { value: "0.5", text: "P" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "radio",
            var: "dieciseis_6_ML",
            label: "6):",
            options: [
              { value: "0.5", text: "S" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "radio",
            var: "dieciseis_7_ML",
            label: "7):",
            options: [
              { value: "0.5", text: "U" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "radio",
            var: "dieciseis_8_ML",
            label: "8):",
            options: [
              { value: "0.5", text: "Y" },
              { value: "0", text: "Otro" },
            ],
          },
        ],
        [
          { type: "h3", label: "1" },
          {
            type: "select",
            var: "uno_1_CL",
            options: [
              { value: "1", text: "La hebra de la amistad" },
              { value: "0", text: "otro" },
            ],
          },
          {
            type: "select",
            var: "uno_2_CL",
            options: [
              { value: "1", text: "Alejandra Medina" },
              { value: "0", text: "otro" },
            ],
          },
          {
            type: "select",
            var: "uno_3_CL",
            options: [
              { value: "1", text: "Editorial Caminante" },
              { value: "0", text: "otro" },
            ],
          },
          { type: "h3", label: "2" },
          {
            label: "Ingrese puntaje: :",
            type: "select",
            var: "dos_CL",
            options: [
              { value: "7", text: "7" },
              { value: "6", text: "6" },
              { value: "5", text: "5" },
              { value: "4", text: "4" },
              { value: "3", text: "3" },
              { value: "2", text: "2" },
              { value: "1", text: "1" },
              { value: "0", text: "0" },
            ],
          },
          { type: "h3", label: "3" },
          {
            type: "select",
            var: "tres_1_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0.50", text: "2" },
              { value: "0.500", text: "3" },
              { value: "0.5000", text: "4" },
            ],
          },
          {
            type: "select",
            var: "tres_2_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0.50", text: "2" },
              { value: "0.500", text: "3" },
              { value: "0.5000", text: "4" },
            ],
          },
          {
            type: "select",
            var: "tres_3_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0.50", text: "2" },
              { value: "0.500", text: "3" },
              { value: "0.5000", text: "4" },
            ],
          },
          {
            type: "select",
            var: "tres_4_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0.50", text: "2" },
              { value: "0.500", text: "3" },
              { value: "0.5000", text: "4" },
            ],
          },
          { type: "h3", label: "4" },
          {
            type: "radio",
            var: "cuatro_1_CL",
            options: [
              { value: "0", text: " V" },
              { value: "1", text: " F" },
            ],
          },
          {
            type: "radio",
            var: "cuatro_2_CL",
            options: [
              { value: "0", text: " V" },
              { value: "1", text: " F" },
            ],
          },
          {
            type: "radio",
            var: "cuatro_3_CL",
            options: [
              { value: "2", text: " V" },
              { value: "0", text: " F" },
            ],
          },
          {
            type: "radio",
            var: "cuatro_4_CL",
            options: [
              { value: "2", text: " V" },
              { value: "0", text: " F" },
            ],
          },
          { type: "h3", label: "5" },
          {
            type: "radio",
            var: "cinco_CL",
            options: [
              { value: "0", text: "1" },
              { value: "00", text: "2" },
              { value: "000", text: "3" },
              { value: "3", text: "4" },
            ],
          },
          { type: "h3", label: "6" },
          {
            label: "Relación con el destinatario:",
            type: "select",
            var: "seis_1_PT",
            options: [
              {
                value: "0.5",
                text: "Tutea y se expresa como en una relación entre pares",
              },
              {
                value: "0",
                text: "no se expresa como una relación entre pares",
              },
            ],
          },
          {
            label: "Intención comunicativa:",
            type: "select",
            var: "seis_2_PT",
            options: [
              {
                value: "1",
                text: "hace uno o más comentarios sobre las acciones de Florita, siguiendo las instrucciones de la pregunta",
              },
              { value: "0", text: "No comenta las acciones de florita" },
            ],
          },
          {
            label: "Presentación:",
            type: "select",
            var: "seis_3_PT",
            options: [
              { value: "0.5", text: "Apariencia limpia y agradable" },
              { value: "0.25", text: "Apariencia poco limpia y agradable" },
              { value: "0", text: "Apariencia ilegible" },
            ],
          },
          {
            label: "Precisión y variedad:",
            type: "select",
            var: "seis_4_PT",
            options: [
              {
                value: "3",
                text: "Comenta de manera completa y precisa 2 ó 3 acciones relevantes de Florita ( entabla conversación, se hace amiga de Alberto, ata las lanas), o comenta estas acciones de manera implícita",
              },
              {
                value: "2",
                text: "Comenta solo 1 acción de manera explícita o implícita",
              },
              {
                value: "1",
                text: "Sus comentarios son poco relevantes o ambiguos",
              },
              {
                value: "0",
                text: "Comenta otros hechos que no responden a la pregunta",
              },
            ],
          },
          {
            label: "Comprensión del tema:",
            type: "select",
            var: "seis_5_PT",
            options: [
              { value: "2", text: "Muestra comprensión del cuento" },
              { value: "1", text: "Muestra comprensión incompleta" },
              { value: "0", text: "No muestra comprensión del cuento" },
            ],
          },
          {
            label: "Capacidad expresiva personal:",
            type: "select",
            var: "seis_6_PT",
            options: [
              {
                value: "2",
                text: "Comenta de manera original, captando el interés del lector",
              },
              {
                value: "1",
                text: "Comenta de manera pobre con escaso interés para el lector",
              },
              {
                value: "0",
                text: "No muestra originalidad y no capta el interés del lector",
              },
            ],
          },
          {
            label: "Reflexión sobre el significado:",
            type: "select",
            var: "seis_7_PT",
            options: [
              {
                value: "1",
                text: "Muestra reflexión sobre el significado del cuento",
              },
              { value: "0", text: "no muestra reflexión" },
            ],
          },
          {
            label: "Vocabulario preciso:",
            type: "select",
            var: "seis_8_PT",
            options: [
              { value: "1", text: "Usa palabras precisas" },
              { value: "0.5", text: "Usa vocabulario poco preciso" },
              { value: "0", text: "Usa vocabulario incorrecto o inadecuado" },
            ],
          },
          {
            label: "Vcabulario variado:",
            type: "select",
            var: "seis_9_PT",
            options: [
              { value: "1", text: "Usa palabras diversas y coloridas" },
              {
                value: "0.5",
                text: "Usa vocabulario adecuado pero no impactante",
              },
              { value: "0", text: "Usa vocabulario pobre" },
            ],
          },
          {
            label: "Ideas fluyen con naturalidad:",
            type: "select",
            var: "seis_10_PT",
            options: [
              { value: "2", text: "Permite lectura ágil y amena" },
              {
                value: "1",
                text: "Presenta 1 ó 2 inconsistencias en el hilo conductor",
              },
              { value: "0", text: "No permite seguir el hilo de ideas" },
            ],
          },
          {
            label: "Presencia de coenctores:",
            type: "select",
            var: "seis_11_PT",
            options: [
              {
                value: "1",
                text: "Utiliza conectores pertinentes y diversos cada vez que se requiere",
              },
              {
                value: "0.5",
                text: "Falta 1 ó 2 conectores en alguna parte del texto",
              },
              { value: "0", text: "Faltan 2 o más conectores" },
            ],
          },
          {
            label: "Estructura textual pertinente:",
            type: "select",
            var: "seis_12_PT",
            options: [
              {
                value: "2",
                text: "Respeta estructura de una carta, con 4 descriptores, presenta fecha, destinatario, despedida y firma",
              },
              { value: "1.5", text: "presenta solo 3 descriptores" },
              { value: "1", text: "presenta solo 2 descriptores" },
              { value: "0.5", text: "presenta solo 1 descriptor" },
            ],
          },
          {
            label: "Comentarios de la carta:",
            type: "select",
            var: "seis_13_PT",
            options: [
              {
                value: "1",
                text: "los comentarios están en el cuerpo de la carta",
              },
              {
                value: "0",
                text: "los comentarios no están en el cuerpo de la carta",
              },
            ],
          },
          {
            label: "Sintaxis:",
            type: "select",
            var: "seis_14_PT",
            options: [
              { value: "1", text: "oraciones con orden lógico" },
              { value: "0.5", text: "1 error de sintaxis" },
              { value: "0", text: "Más de 2 errores" },
            ],
          },
          {
            label: "Morfosintaxis:",
            type: "select",
            var: "seis_15_PT",
            options: [
              {
                value: "1",
                text: "existe concordancia de género, número y tiempos verbales",
              },
              { value: "0", text: "hay 2 o más inconsistencias" },
            ],
          },
          {
            label: "Ortografía:",
            type: "select",
            var: "seis_16_PT",
            options: [
              {
                value: "1",
                text: "Usa mayúscula al comienzo de la oración, sin omisión de letras, sin escritura en carro y sin inversiones, terminaciones verbales en aba, combinaciones mp, nv, mb, r-rr",
              },
              { value: "0.5", text: "hay 1 o 2 errores" },
              { value: "0", text: "hay más de 2 errores" },
            ],
          },
          {
            label: "Puntual:",
            type: "select",
            var: "seis_17_PT",
            options: [
              {
                value: "1",
                text: "uso adecuado del punto aparte, punto final, signos de interrogación y exclamación",
              },
              { value: "0.5", text: "1 error" },
              { value: "0", text: "2 o más errores" },
            ],
          },
          { type: "h3", label: "7" },
          {
            type: "select",
            var: "siete_1_ML",
            options: [
              { value: "3", text: "respuesta correcta" },
              { value: "2", text: "orden correcto, pero falta 1 palabra" },
              { value: "1", text: "orden correcto, pero faltan 2 palabras" },
              {
                value: "0",
                text: "orden incorrecto o faltan más de 2 palabras",
              },
            ],
          },
          {
            type: "select",
            var: "siete_2_ML",
            options: [
              { value: "3", text: "respuesta correcta" },
              { value: "2", text: "orden correcto, pero falta 1 palabra" },
              { value: "1", text: "orden correcto, pero faltan 2 palabras" },
              {
                value: "0",
                text: "orden incorrecto o faltan más de 2 palabras",
              },
            ],
          },
          {
            type: "select",
            var: "siete_3_ML",
            options: [
              { value: "3", text: "respuesta correcta" },
              { value: "2", text: "orden correcto, pero falta 1 palabra" },
              { value: "1", text: "orden correcto, pero faltan 2 palabras" },
              {
                value: "0",
                text: "orden incorrecto o faltan más de 2 palabras",
              },
            ],
          },
          { type: "h3", label: "8" },
          {
            type: "select",
            var: "ocho_1_CL",
            options: [
              { value: "0", text: "1" },
              { value: "00", text: "2" },
              { value: "0.5", text: "3" },
              { value: "000", text: "4" },
              { value: "0000", text: "5" },
              { value: "00000", text: "6" },
            ],
          },
          {
            type: "select",
            var: "ocho_2_CL",
            options: [
              { value: "0", text: "1" },
              { value: "00", text: "2" },
              { value: "000", text: "3" },
              { value: "0000", text: "4" },
              { value: "0.5", text: "5" },
              { value: "00000", text: "6" },
            ],
          },
          {
            type: "select",
            var: "ocho_3_CL",
            options: [
              { value: "0", text: "1" },
              { value: "00", text: "2" },
              { value: "000", text: "3" },
              { value: "0.5", text: "4" },
              { value: "0000", text: "5" },
              { value: "00000", text: "6" },
            ],
          },
          {
            type: "select",
            var: "ocho_4_CL",
            options: [
              { value: "0", text: "1" },
              { value: "00", text: "2" },
              { value: "000", text: "3" },
              { value: "0000", text: "4" },
              { value: "00000", text: "5" },
              { value: "0.5", text: "6" },
            ],
          },
          { type: "h3", label: "9" },
          {
            type: "radio",
            var: "nueve_1_ML",
            options: [
              { value: "0.5", text: " Anibal" },
              { value: "0", text: " Otro" },
            ],
          },
          {
            type: "radio",
            var: "nueve_2_ML",
            options: [
              { value: "0.5", text: " Beatriz" },
              { value: "0", text: " Otro" },
            ],
          },
          {
            type: "radio",
            var: "nueve_3_ML",
            options: [
              { value: "0.5", text: " Claudia" },
              { value: "0", text: " Otro" },
            ],
          },
          {
            type: "radio",
            var: "nueve_4_ML",
            options: [
              { value: "0.5", text: " Daniel" },
              { value: "0", text: " Otro" },
            ],
          },
          {
            type: "radio",
            var: "nueve_5_ML",
            options: [
              { value: "0.5", text: " Federico" },
              { value: "0", text: " Otro" },
            ],
          },
          {
            type: "radio",
            var: "nueve_6_ML",
            options: [
              { value: "0.5", text: " Isabel" },
              { value: "0", text: " Otro" },
            ],
          },
          {
            type: "radio",
            var: "nueve_7_ML",
            options: [
              { value: "0.5", text: " Leonardo" },
              { value: "0", text: " Otro" },
            ],
          },
          {
            type: "radio",
            var: "nueve_8_ML",
            options: [
              { value: "0.5", text: " María" },
              { value: "0", text: " Otro" },
            ],
          },
          { type: "h3", label: "10" },
          {
            label: "Diez cl:",
            type: "radio",
            var: "diez_CL",
            options: [
              { value: "0", text: " una noticia" },
              { value: "1", text: " un texto informativo" },
              { value: "00", text: " una biografía" },
            ],
          },
          { type: "h3", label: "11" },
          {
            type: "radio",
            var: "once_CL",
            options: [
              { value: "0", text: " El hábitat del delfín" },
              {
                value: "3",
                text: " Las principales características del delfín",
              },
              { value: "00", text: " La utilización del delfín" },
            ],
          },
          { type: "h3", label: "12" },
          {
            type: "radio",
            var: "doce_1_CL",
            options: [
              { value: "0", text: " V" },
              { value: "1", text: " F" },
            ],
          },
          {
            type: "radio",
            var: "doce_2_CL",
            options: [
              { value: "1", text: " V" },
              { value: "0", text: " F" },
            ],
          },
          {
            type: "radio",
            var: "doce_3_CL",
            options: [
              { value: "2", text: " V" },
              { value: "0", text: " F" },
            ],
          },
          {
            type: "radio",
            var: "doce_4_CL",
            options: [
              { value: "0", text: " V" },
              { value: "2", text: " F" },
            ],
          },
          { type: "h3", label: "13" },
          {
            label: "Presición y variedad:",
            type: "select",
            var: "trece_1_PT",
            options: [
              {
                value: "3",
                text: "Escribe 3 ó más argumentos relevantes y precisos, basados en el texto o en sus conocimientos previos",
              },
              {
                value: "2",
                text: "Escribe 1 ó 2 argumentos relevantes y medianamente precisos",
              },
              { value: "1", text: "Argumentos limitados y poco relevantes" },
              {
                value: "0",
                text: "Argumentación fragmentaria, contiene imprecisiones e incongruencias",
              },
            ],
          },
          {
            label: "Comprensión del tema:",
            type: "select",
            var: "trece_2_PT",
            options: [
              { value: "1", text: "Muestra comprensión del tema" },
              { value: "0.5", text: "Muestra comprensión moderada" },
              { value: "0", text: "No muestra comprensión del tema" },
            ],
          },
          {
            label: "Capacidad expresiva personal:",
            type: "select",
            var: "trece_3_PT",
            options: [
              {
                value: "1",
                text: "Argumenta de manera original, escogiendo palabras específicas para referirse al tema",
              },
              {
                value: "0.5",
                text: "Argumenta escogiendo palabras no suficientemente apropiadas para referirse al tema",
              },
              {
                value: "0",
                text: "Argumentación incompleta, repetitiva o utiliza palabras inapropiadas",
              },
            ],
          },
          {
            label: "Reflexión sobre el significado:",
            type: "select",
            var: "trece_4_PT",
            options: [
              {
                value: "1",
                text: "Muestra reflexión y/o emoción al argumentar sobre el tema",
              },
              { value: "0.5", text: "reflexión y emoción escasas" },
              {
                value: "0",
                text: "no muestra reflexión ni emoción.-Usa vocabulario incorrecto o inadecuado",
              },
            ],
          },
          {
            label: "Ideas fluyen con naturalidad:",
            type: "select",
            var: "trece_5_PT",
            options: [
              {
                value: "1",
                text: "sus argumentos se expresan con naturalidad dada la forma en que se integran las ideas que lo sustentan",
              },
              {
                value: "0.5",
                text: "No hay clara integración entre argumentación y las ideas que lo sustentan",
              },
              {
                value: "0",
                text: "Los errores impiden seguir el hilo de las ideas o comprenderlas",
              },
            ],
          },
          {
            label: "Presencia de conectores:",
            type: "select",
            var: "trece_6_PT",
            options: [
              { value: "0.5", text: "Utiliza conectores pertinentes" },
              {
                value: "0.25",
                text: "Utiliza conectores pertinentes pero repetitivos",
              },
              { value: "0", text: "No utiliza conectores pertinentes" },
            ],
          },
          {
            label: "Estructura textual pertinente:",
            type: "select",
            var: "trece_7_PT",
            options: [
              {
                value: "2",
                text: "Desarrolla argumentación de manera lógica y organizada",
              },
              {
                value: "1",
                text: "Su argumentación es poco lógica y organizada",
              },
              {
                value: "0",
                text: "Su argumentación no es clara ni organizada",
              },
            ],
          },
          {
            label: "Sintaxis:",
            type: "select",
            var: "trece_8_PT",
            options: [
              { value: "0.5", text: "oraciones con orden lógico" },
              { value: "0.25", text: "1 error de sintaxis" },
              { value: "0", text: "Más de 2 errores" },
            ],
          },
          {
            label: "Morfosintaxis:",
            type: "select",
            var: "trece_9_PT",
            options: [
              {
                value: "0.5",
                text: "existe concordancia de género, número y tiempos verbales",
              },
              { value: "0.25", text: "hay 1 inconsistencia" },
              { value: "0", text: "hay más de 1 inconsistencia" },
            ],
          },
          {
            label: "Ortografía:",
            type: "select",
            var: "trece_10_PT",
            options: [
              {
                value: "0.5",
                text: "Usa mayúscula al comienzo de la oración, sin omisión de letras, sin escritura en carro y sin inversiones, terminaciones verbales en aba, combinaciones mp, nv, mb, r-rr",
              },
              { value: "0.25", text: "hay 1 o 2 errores" },
              { value: "0", text: "hay más de 2 errores" },
            ],
          },
          {
            label: "Puntual:",
            type: "select",
            var: "trece_11_PT",
            options: [
              {
                value: "0.5",
                text: "uso adecuado del punto aparte, punto final, signos de interrogación y exclamación",
              },
              { value: "0.25", text: "1 error" },
              { value: "0", text: "2 o más errores" },
            ],
          },
          { type: "h3", label: "14" },
          {
            type: "select",
            var: "catorce_1_CL",
            options: [
              { value: "2", text: "Dibujo correcto" },
              { value: "0", text: "Dibujo incorrecto" },
            ],
          },
          {
            type: "select",
            var: "catorce_2_CL",
            options: [
              { value: "2", text: "Escribe el nombre en el lugar correcto" },
              { value: "0", text: "Escribe el nombre en el lugar incorrecto" },
            ],
          },
          {
            type: "select",
            var: "catorce_3_CL",
            options: [
              { value: "2", text: "Hace el dibujo en el lugar correcto" },
              { value: "0", text: "Hace el dibujo en el lugar incorrecto" },
            ],
          },
        ],
        [
          { type: "h3", label: "1" },
          {
            label: "Ingrese puntaje: :",
            type: "select",
            var: "uno_CL",
            options: [
              { value: "0", text: "0" },
              { value: "1", text: "1" },
              { value: "2", text: "2" },
              { value: "3", text: "3" },
              { value: "4", text: "4" },
              { value: "5", text: "5" },
              { value: "6", text: "6" },
              { value: "7", text: "7" },
              { value: "8", text: "8" },
              { value: "9", text: "9" },
            ],
          },
          { type: "h3", label: "2" },
          {
            type: "radio",
            var: "dos_CL",
            options: [
              { value: "0", text: " Informa sobre la vida de los elefantes" },
              {
                value: "1",
                text: " Es una historia breve sobre lo que les ocurre a personajes imaginarios",
              },
              {
                value: "00",
                text: " Es una historia sobre el problema de Tobías",
              },
            ],
          },
          { type: "h3", label: "3" },
          {
            type: "radio",
            var: "tres_CL",
            options: [
              {
                value: "0",
                text: " Para que cuidara a los elefantes enfermos",
              },
              {
                value: "00",
                text: " Para que ayudara a los pequeños a buscar charcos de agua",
              },
              { value: "1", text: " Para perderle el miedo" },
            ],
          },
          { type: "h3", label: "4" },
          {
            type: "radio",
            var: "cuatro_CL",
            options: [
              {
                value: "0",
                text: " La Amistad de tobías con la manada de elefantes",
              },
              {
                value: "2",
                text: " Cómo la Amistad puede ayudar a resolver problemas",
              },
              {
                value: "00",
                text: " Los efectos del sol en la piel de Tobías",
              },
            ],
          },
          { type: "h3", label: "5" },
          {
            type: "radio",
            var: "cinco_CL",
            options: [
              { value: "2", text: " Vive cuidando el medioambiente" },
              { value: "0", text: " Vive feliz" },
              { value: "00", text: " Vive en paz con su mundo" },
            ],
          },
          { type: "h3", label: "6" },
          {
            type: "select",
            var: "seis_1_ML",
            options: [
              { value: "2", text: "Tobias" },
              { value: "0", text: "Elefantes" },
            ],
          },
          {
            type: "select",
            var: "seis_2_ML",
            options: [
              { value: "0", text: "Tobias" },
              { value: "2", text: "Elefantes" },
            ],
          },
          {
            type: "select",
            var: "seis_3_ML",
            options: [
              { value: "2", text: "Tobias" },
              { value: "0", text: "Elefantes" },
            ],
          },
          {
            type: "select",
            var: "seis_4_ML",
            options: [
              { value: "2", text: "Tobias" },
              { value: "0", text: "Elefantes" },
            ],
          },
          { type: "h3", label: "7" },
          {
            label: "Relación con el destinatario:",
            type: "select",
            var: "siete_1_PT",
            options: [
              { value: "1", text: "Utiliza lenguaje formal" },
              { value: "0", text: "No utiliza lenguaje formal" },
            ],
          },
          {
            label: "Intención comunicativa:",
            type: "select",
            var: "siete_2_PT",
            options: [
              { value: "1", text: "Informa sobre la partida de Tobías" },
              { value: "0", text: "No informa sobre la partida de Tobías" },
            ],
          },
          {
            label: "Presentación:",
            type: "select",
            var: "siete_3_PT",
            options: [
              { value: "1", text: "Apariencia limpia y agradable" },
              { value: "0.5", text: "su escritura es regular" },
              { value: "0.5", text: "su escritura se presenta limpia" },
            ],
          },
          {
            label: "Precisión y variedad:",
            type: "select",
            var: "siete_4_PT",
            options: [
              {
                value: "4",
                text: "Informa las circunstancias con precisión y claridad, incluyendo detalles interesantes. La noticia incluye las siguientes ideas esenciales: la manada estaba agradecida de Tobías, escucharon la historia de su vida, lo llevaron al osque como regalo, vive feliz en armonía con su ambiente, u otras referencias emotivas o relacionadas con las condiciones del hecho",
              },
              { value: "3", text: "presenta solo 3 ideas" },
              { value: "2", text: "presenta solo 2 ideas" },
              { value: "1", text: "presenta solo 1 idea" },
            ],
          },
          {
            label: "Comprensión del tema:",
            type: "select",
            var: "siete_5_PT",
            options: [
              { value: "1", text: "Muestra comprensión de lo ocurrido" },
              { value: "0.5", text: "Muestra comprensión incompleta" },
              { value: "0", text: "No muestra comprensión del cuento" },
            ],
          },
          {
            label: "Capacidad expresiva y personal:",
            type: "select",
            var: "siete_6_PT",
            options: [
              {
                value: "2",
                text: "Revela originalidad y vitalidad captando el interés del lector",
              },
              {
                value: "1",
                text: "Revela limitada originalidad y vitalidad, captando medianamente el interés del lector",
              },
              {
                value: "0",
                text: "No revela originalidad ni capta el interés del lector",
              },
            ],
          },
          {
            label: "Reflexión sobre el significado:",
            type: "select",
            var: "siete_7_PT",
            options: [
              {
                value: "1",
                text: "Muestra reflexión sobre la partida de Tobías",
              },
              { value: "0.5", text: "muestra reflexión incompleta" },
              { value: "0", text: "no muestra reflexión" },
            ],
          },
          {
            label: "Vocabulario preciso:",
            type: "select",
            var: "siete_8_PT",
            options: [
              { value: "1", text: "Usa palabras precisas" },
              { value: "0.5", text: "Usa vocabulario poco preciso" },
              { value: "0", text: "Usa vocabulario incorrecto o inadecuado" },
            ],
          },
          {
            label: "Vocabulario variado:",
            type: "select",
            var: "siete_9_PT",
            options: [
              { value: "1", text: "Usa palabras diversas y coloridas" },
              {
                value: "0.5",
                text: "Usa vocabulario adecuado pero no impactante",
              },
              { value: "0", text: "Usa vocabulario pobre" },
            ],
          },
          {
            label: "Ideas fluyen con naturalidad:",
            type: "select",
            var: "siete_10_PT",
            options: [
              { value: "1", text: "Permite lectura ágil y amena" },
              {
                value: "0.5",
                text: "Presenta 1 ó 2 inconsistencias en el hilo conductor",
              },
              { value: "0", text: "No permite seguir el hilo de ideas" },
            ],
          },
          {
            label: "Presencia de conectores:",
            type: "select",
            var: "siete_11_PT",
            options: [
              {
                value: "2",
                text: "Utiliza conectores pertinentes y diversos cada vez que se requiere",
              },
              {
                value: "0.5",
                text: "falta 1 ó 2 conectores en alguna parte del texto",
              },
              { value: "0", text: "faltan 2 o más conectores" },
            ],
          },
          {
            label: "Estructura textual pertinente 1:",
            type: "select",
            var: "siete_12_PT",
            options: [
              {
                value: "1",
                text: "Respeta el formato de una noticia, su título es pertinente",
              },
              { value: "0", text: "No utiliza el organizador gráfico" },
            ],
          },
          {
            label: "Estructura textual pertinente 2:",
            type: "select",
            var: "siete_13_PT",
            options: [
              {
                value: "0.5",
                text: "Su descripción es desorganizada o es una enumeración",
              },
              {
                value: "0",
                text: "No respeta el formato de una noticia, su título no es pertinente",
              },
            ],
          },
          {
            label: "Estructura textual pertinente 3:",
            type: "select",
            var: "siete_14_PT",
            options: [
              {
                value: "0.5",
                text: "Utiliza lenguaje sintético, corto y preciso",
              },
              {
                value: "0",
                text: "no utiliza lenguaje sintético, corto y preciso",
              },
            ],
          },
          {
            label: "Sintaxis:",
            type: "select",
            var: "siete_15_PT",
            options: [
              { value: "1", text: "oraciones con orden lógico" },
              { value: "0.5", text: "1 o 2 error de sintaxis" },
              { value: "0", text: "Más de 2 errores" },
            ],
          },
          {
            label: "Morfosintaxis:",
            type: "select",
            var: "siete_16_PT",
            options: [
              {
                value: "1",
                text: "existe concordancia de género, número y tiempos verbales",
              },
              { value: "0.5", text: "hay 1 inconsistencia" },
              { value: "0", text: "hay más de 1 inconsistencia" },
            ],
          },
          {
            label: "Ortogrfía Literal:",
            type: "select",
            var: "siete_17_PT",
            options: [
              {
                value: "1",
                text: "Uso de mayúsculas, de v/b; c/s/z; sin omisión de letras, sin escritura en carro ni inversiones",
              },
              { value: "0.5", text: "hay 1 o 2 errores" },
              { value: "0", text: "hay más de 2 errores" },
            ],
          },
          {
            label: "Ortografía acentual:",
            type: "select",
            var: "siete_18_PT",
            options: [
              {
                value: "1",
                text: "uso de tilde en palabras agudas terminadas en vocal, n y s",
              },
              { value: "0.5", text: "hay 1 ó 2 errores" },
              { value: "0", text: "hay más de 2 errores" },
            ],
          },
          {
            label: "Ortografía puntual:",
            type: "select",
            var: "siete_19_PT",
            options: [
              {
                value: "1",
                text: "uso adecuado del punto aparte, punto final y comas en enumeraciones",
              },
              { value: "0.5", text: "hay 1 ó 2 errores" },
              { value: "0", text: "hay más de 2 errores" },
            ],
          },
          { type: "h3", label: "8" },
          {
            type: "radio",
            var: "ocho_CL",
            options: [
              {
                value: "0",
                text: " Entrega información sobre las aventuras del ocelote",
              },
              { value: "00", text: " Narra la vida del ocelote" },
              {
                value: "1",
                text: " Entrega información sobre las características del ocelote",
              },
            ],
          },
          { type: "h3", label: "9" },
          {
            type: "radio",
            var: "nueve_CL",
            options: [
              {
                value: "0",
                text: " Durante la estación seca se alimenta de mamíferos y serpientes",
              },
              {
                value: "2",
                text: " Se alimenta de la presa que puede cazar más fácilmente",
              },
              {
                value: "00",
                text: " Se alimenta de aves y animales acuáticos",
              },
            ],
          },
          { type: "h3", label: "10" },
          {
            type: "radio",
            var: "diez_1_CL",
            options: [
              { value: "2", text: " V" },
              { value: "0", text: " F" },
            ],
          },
          {
            type: "radio",
            var: "diez_2_CL",
            options: [
              { value: "0", text: " V" },
              { value: "1", text: " F" },
            ],
          },
          {
            type: "radio",
            var: "diez_3_CL",
            options: [
              { value: "2", text: " V" },
              { value: "0", text: " F" },
            ],
          },
          { type: "h3", label: "11" },
          {
            type: "radio",
            var: "once_CL",
            options: [
              { value: "0", text: " Porque bebe solo agua limpia" },
              { value: "3", text: " Porque está protegido y bien alimentado" },
              { value: "00", text: " Porque no puede ser cazado" },
            ],
          },
          { type: "h3", label: "12" },
          {
            type: "select",
            var: "doce_1_ML",
            options: [
              {
                value: "2",
                text: "las palabras están correctamente ordenadas",
              },
              {
                value: "1",
                text: "el orden está correcto, pero falta una palabra",
              },
              {
                value: "0",
                text: "el orden está incorrecto o le faltan 2 o más palabras",
              },
            ],
          },
          {
            type: "select",
            var: "doce_2_ML",
            options: [
              {
                value: "2",
                text: "las palabras están correctamente ordenadas",
              },
              {
                value: "1",
                text: "el orden está correcto, pero falta una palabra",
              },
              {
                value: "0",
                text: "el orden está incorrecto o le faltan 2 o más palabras",
              },
            ],
          },
          {
            type: "select",
            var: "doce_3_ML",
            options: [
              {
                value: "2",
                text: "las palabras están correctamente ordenadas",
              },
              {
                value: "1",
                text: "el orden está correcto, pero falta una palabra",
              },
              {
                value: "0",
                text: "el orden está incorrecto o le faltan 2 o más palabras",
              },
            ],
          },
          { type: "h3", label: "13" },
          {
            type: "radio",
            var: "trece_1_CL",
            options: [
              { value: "0", text: " V" },
              { value: "1", text: " F" },
            ],
          },
          {
            type: "radio",
            var: "trece_2_CL",
            options: [
              { value: "0", text: " V" },
              { value: "1", text: " F" },
            ],
          },
          {
            type: "radio",
            var: "trece_3_CL",
            options: [
              { value: "1", text: " V" },
              { value: "0", text: " F" },
            ],
          },
          {
            type: "radio",
            var: "trece_4_CL",
            options: [
              { value: "1", text: " V" },
              { value: "0", text: " F" },
            ],
          },
          { type: "h3", label: "14" },
          {
            type: "select",
            var: "catorce_1_CL",
            options: [
              { value: "2", text: "Dibujo correcto" },
              { value: "0", text: "Dibujo incorrecto" },
            ],
          },
          {
            type: "select",
            var: "catorce_2_CL",
            options: [
              { value: "2", text: "Escribe la cruz en el lugar correcto" },
              { value: "0", text: "Escribe la cruz en el lugar incorrecto" },
            ],
          },
          {
            type: "select",
            var: "catorce_3_CL",
            options: [
              {
                value: "2",
                text: "pinta toda la manzana en el lugar correcto",
              },
              {
                value: "0",
                text: "pinta toda la manzana en el lugar incorrecto",
              },
            ],
          },
          { type: "h3", label: "15" },
          {
            type: "radio",
            var: "quince_1_CL",
            options: [
              { value: "0", text: " V" },
              { value: "1", text: " F" },
            ],
          },
          {
            type: "radio",
            var: "quince_2_CL",
            options: [
              { value: "0", text: " V" },
              { value: "1", text: " F" },
            ],
          },
          {
            type: "radio",
            var: "quince_3_CL",
            options: [
              { value: "1", text: " V" },
              { value: "0", text: " F" },
            ],
          },
          { type: "h3", label: "16" },
          {
            label: "Presición y variedad:",
            type: "select",
            var: "dieciseis_1_PT",
            options: [
              {
                value: "3",
                text: "Escribe 2 ó 3 razones relevantes y precisas, basadas en el plano o en sus conocimientos previos",
              },
              { value: "2", text: "Escribe 1 razón bien argumentada" },
              {
                value: "1",
                text: "Argumenta de manera limitada, tanto en cantidad de ideas, como en su pertinencias y claridad",
              },
              {
                value: "0",
                text: "argumentación fragmentaria, contiene imprecisiones e incongruencias",
              },
            ],
          },
          {
            label: "Comprensión del tema:",
            type: "select",
            var: "dieciseis_2_PT",
            options: [
              { value: "1", text: "Muestra comprensión del plano" },
              {
                value: "0.5",
                text: "Muestra comprensión incompleta del plano",
              },
              { value: "0", text: "No muestra comprensión del plano" },
            ],
          },
          {
            label: "Capacidad expresiva presonal:",
            type: "select",
            var: "dieciseis_3_PT",
            options: [
              {
                value: "2",
                text: "Argumenta de manera original, escogiendo palabras específicas para referirse al tema",
              },
              {
                value: "1",
                text: "Argumenta de manera poco original, con palabras poco específicas",
              },
              {
                value: "0",
                text: "Argumenta de manera incompleta, repetitiva o utiliza palabras inapropiadas",
              },
            ],
          },
          {
            label: "Reflexión sobre el significado:",
            type: "select",
            var: "dieciseis_4_PT",
            options: [
              {
                value: "2",
                text: "Muestra y/o emoción al argumentar sobre el tema",
              },
              { value: "1", text: "Muestra reflexión y emoción escasas" },
              { value: "0", text: "No muestra reflexión ni emoción" },
            ],
          },
          {
            label: "Fluidez y cohesión:",
            type: "select",
            var: "dieciseis_5_PT",
            options: [
              {
                value: "1",
                text: "Sus argumentos se expresan con naturalidad dada la forma en que se integran las ideas que lo sustentan",
              },
              {
                value: "0.5",
                text: "No hay clara integración entre argumentación y las ideas que la sustentan",
              },
              {
                value: "0",
                text: "Los errores impiden seguir el hilo de las ideas o comprenderlas",
              },
            ],
          },
          {
            label: "Presencia de conectores:",
            type: "select",
            var: "dieciseis_6_PT",
            options: [
              {
                value: "1",
                text: "Utiliza conectores pertinentes además de “porque”",
              },
              {
                value: "0.5",
                text: "Utiliza conectores pertinentes pero repetitivos",
              },
              { value: "0", text: "No utiliza conectores pertinentes" },
            ],
          },
          {
            label: "Estructura textual pertinente:",
            type: "select",
            var: "dieciseis_7_PT",
            options: [
              {
                value: "1",
                text: "Desarrolla argumentación de manera lógica y organizada",
              },
              {
                value: "0.5",
                text: "Su argumentación es poco lógica y organizada",
              },
              {
                value: "0",
                text: "No aporta argumentos o éstos son incomprensibles",
              },
            ],
          },
          {
            label: "Sintaxis:",
            type: "select",
            var: "dieciseis_8_PT",
            options: [
              { value: "1", text: "oraciones con orden lógico" },
              { value: "0.5", text: "1 o 2 error de sintaxis" },
              { value: "0", text: "Más de 2 errores" },
            ],
          },
          {
            label: "Morfosintaxis:",
            type: "select",
            var: "dieciseis_9_PT",
            options: [
              {
                value: "1",
                text: "existe concordancia de género, número y tiempos verbales",
              },
              { value: "0.5", text: "hay 1 inconsistencia" },
              { value: "0", text: "hay más de 1 inconsistencia" },
            ],
          },
          {
            label: "Ortografía literal:",
            type: "select",
            var: "dieciseis_10_PT",
            options: [
              {
                value: "0.5",
                text: "Uso de mayúsculas, de v/b; c/s/z; sin omisión de letras, sin escritura en carro ni inversiones",
              },
              { value: "0.25", text: "hay 1 o 2 errores" },
              { value: "0", text: "hay más de 2 errores" },
            ],
          },
          {
            label: "Ortografía acentual:",
            type: "select",
            var: "dieciseis_11_PT",
            options: [
              {
                value: "0.5",
                text: "uso de tilde en palabras agudas terminadas en vocal, n y s",
              },
              { value: "0.25", text: "hay 1 ó 2 errores" },
              { value: "0", text: "hay más de 2 errores" },
            ],
          },
          {
            label: "Ortografía puntual:",
            type: "select",
            var: "dieciseis_12_PT",
            options: [
              {
                value: "0.5",
                text: "uso adecuado del punto aparte, punto final y comas en enumeraciones",
              },
              { value: "0.25", text: "hay 1 ó 2 errores" },
              { value: "0", text: "hay más de 2 errores" },
            ],
          },
        ],
        [
          { type: "h3", label: "1" },
          {
            label: "Ingrese Puntaje: :",
            type: "select",
            var: "uno_CL",
            options: [
              { value: "0", text: "0" },
              { value: "1", text: "1" },
              { value: "2", text: "2" },
              { value: "3", text: "3" },
              { value: "4", text: "4" },
              { value: "5", text: "5" },
              { value: "6", text: "6" },
              { value: "7", text: "7" },
              { value: "8", text: "8" },
              { value: "9", text: "9" },
            ],
          },
          { type: "h3", label: "2" },
          {
            type: "radio",
            var: "dos_CL",
            options: [
              {
                value: "0",
                text: " Es una historia divertida con muchas expresiones",
              },
              {
                value: "1",
                text: " Es una historia breve de animales con una enseñanza",
              },
              { value: "00", text: " Es una historia absurda sobre animales" },
            ],
          },
          { type: "h3", label: "3" },
          {
            type: "radio",
            var: "tres_CL",
            options: [
              {
                value: "0",
                text: " Engaña al Buey y a Puma haciéndoles preguntas",
              },
              { value: "2", text: " Salva a Buey de ser devorado por Puma" },
              { value: "00", text: " Vuelve a atrapar a Puma con los trucos" },
            ],
          },
          { type: "h3", label: "4" },
          {
            type: "radio",
            var: "cuatro_CL",
            options: [
              {
                value: "0",
                text: " Entretener al lector con historias de animales",
              },
              {
                value: "00",
                text: " Destacar la importancia de la astucia para ayudar a los amigos",
              },
              {
                value: "3",
                text: " Enseñar valores humanos a través de una historia de animales",
              },
            ],
          },
          { type: "h3", label: "5" },
          {
            type: "radio",
            var: "cinco_CL",
            options: [
              { value: "0", text: " Que si él tiene razón lo dejará libre" },
              { value: "00", text: " Que ya terminó de poner los troncos" },
              {
                value: "3",
                text: " Que si él tiene la razón se lo va a comer",
              },
            ],
          },
          { type: "h3", label: "6" },
          {
            label: "Relación con el destinatario:",
            type: "select",
            var: "seis_1_PT",
            options: [
              {
                value: "1",
                text: "Tutea y se expresa como en una relación entre pares",
              },
              {
                value: "0",
                text: "No tutea ni se expresa como en una relación entre pares",
              },
            ],
          },
          {
            label: "Intención comunicativa:",
            type: "select",
            var: "seis_2_PT",
            options: [
              {
                value: "1",
                text: "Narra el cuento a través de una carta a un amigo(a",
              },
              { value: "0", text: "No narra el cuento a través de una carta" },
            ],
          },
          {
            label: "Presentación:",
            type: "select",
            var: "seis_3_PT",
            options: [
              { value: "1", text: "Apariencia limpia y agradable" },
              { value: "0.5", text: "su escritura es regular, pero no limpia" },
              {
                value: "0.50",
                text: "su escritura se presenta limpia, pero no regular",
              },
            ],
          },
          {
            label: "Precisión y variedad:",
            type: "select",
            var: "seis_4_PT",
            options: [
              {
                value: "3",
                text: "Narra hechos más importantes del cuento con precisión y claridad, incluyendo detalles interesantes. Las ideas esenciales del cuento son: Puma prisionero y Buey lo libera; Puma quiere comerse a buey; Buey pregunta si así se agradecen los favores, preguntan a tres animales; Conejo pide conocer cómo ocurrió; Puma muestra y vuelve a quedar prisionero; Conejo y Buey se van, dándole una lección",
              },
              {
                value: "2.5",
                text: "presenta solo 5 ideas, de forma precisa y clara",
              },
              {
                value: "1.25",
                text: "presenta solo 5 ideas, de manera imprecisa o confusa",
              },
              {
                value: "2",
                text: "presenta solo 4 ideas, de forma precisa y clara",
              },
              {
                value: "1",
                text: "presenta solo 4 ideas, de manera imprecisa o confusa",
              },
              {
                value: "1.5",
                text: "presenta solo 3 ideas, de forma precisa y clara",
              },
              {
                value: "0.75",
                text: "presenta solo 3 ideas, de manera imprecisa o confusa",
              },
              {
                value: "1.0",
                text: "presenta solo 2 ideas, de forma precisa y clara",
              },
              {
                value: "0.5",
                text: "presenta solo 2 ideas, de manera imprecisa o confusa",
              },
              {
                value: "0.50",
                text: "presenta solo 1 idea, de forma precisa y clara",
              },
              {
                value: "0.25",
                text: "presenta solo 1 idea, de manera imprecisa o confusa",
              },
            ],
          },
          {
            label: "Comprensión del tema:",
            type: "select",
            var: "seis_5_PT",
            options: [
              { value: "2", text: "Muestra comprensión de lo ocurrido" },
              { value: "1", text: "Muestra comprensión incompleta" },
              { value: "0", text: "No muestra comprensión de la fábula" },
            ],
          },
          {
            label: "Capacidad expresiva personal:",
            type: "select",
            var: "seis_6_PT",
            options: [
              {
                value: "2",
                text: "Revela originalidad y vitalidad captando el interés del lector",
              },
              {
                value: "1",
                text: "Revela limitada originalidad y vitalidad, captando medianamente el interés del lector",
              },
              {
                value: "0",
                text: "No revela originalidad ni capta el interés del lector",
              },
            ],
          },
          {
            label: "Reflexión sobre el significado:",
            type: "select",
            var: "seis_7_PT",
            options: [
              {
                value: "1",
                text: "Muestra reflexión sobre el sentido de la fábula",
              },
              { value: "0.5", text: "muestra reflexión incompleta" },
              { value: "0", text: "no muestra reflexión" },
            ],
          },
          {
            label: "Vocabuario preciso:",
            type: "select",
            var: "seis_8_PT",
            options: [
              { value: "1", text: "Usa palabras precisas" },
              { value: "0.5", text: "Usa vocabulario poco preciso" },
              { value: "0", text: "Usa vocabulario incorrecto o inadecuado" },
            ],
          },
          {
            label: "Vocabulario variado:",
            type: "select",
            var: "seis_9_PT",
            options: [
              { value: "1", text: "Usa diversidad de palabras" },
              {
                value: "0.5",
                text: "Usa vocabulario adecuado pero no impactante",
              },
              { value: "0", text: "Usa vocabulario muy restringido" },
            ],
          },
          {
            label: "Ideas fluyen con naturalidad:",
            type: "select",
            var: "seis_10_PT",
            options: [
              { value: "1", text: "Permite lectura ágil y amena" },
              {
                value: "0.5",
                text: "Presenta 1 ó 2 inconsistencias en el hilo conductor",
              },
              { value: "0", text: "No permite seguir el hilo de ideas" },
            ],
          },
          {
            label: "Presencia de conectores:",
            type: "select",
            var: "seis_11_PT",
            options: [
              {
                value: "2",
                text: "Utiliza conectores pertinentes y diversos cada vez que se requiere",
              },
              {
                value: "1",
                text: "Falta 1 ó 2 conectores en alguna parte del texto",
              },
              { value: "0", text: "Faltan 2 o más conectores" },
            ],
          },
          {
            label: "Estructura textual pertinente 1:",
            type: "select",
            var: "seis_12_PT",
            options: [
              {
                value: "2",
                text: "Respeta el formato de una carta, presenta los 4 descriptores, indicando la fecha, destinatario, despedida y firma",
              },
              { value: "1.5", text: "presenta solo 3 descriptores" },
              { value: "1", text: "presenta solo 2 descriptores" },
              { value: "0.5", text: "presenta solo 1 descriptor" },
              { value: "0", text: "no presenta descriptores" },
            ],
          },
          {
            label: "Estructura textual pertinente 1:",
            type: "select",
            var: "seis_13_PT",
            options: [
              {
                value: "1",
                text: "presenta los cuatro descriptores, incluye elemento gatillador, reacción tentativa, resultado y final",
              },
              { value: "0.75", text: "presenta solo 3 descriptores" },
              { value: "0.5", text: "presenta solo 2 descriptores" },
              { value: "0.25", text: "presenta solo 1 descriptor" },
              { value: "0", text: "no presenta descriptores" },
            ],
          },
          {
            label: "Sintaxis:",
            type: "select",
            var: "seis_14_PT",
            options: [
              { value: "1", text: "oraciones con orden lógico" },
              { value: "0.5", text: "1 o 2 error de sintaxis" },
              { value: "0", text: "Más de 2 errores de sintaxis" },
            ],
          },
          {
            label: "Morfosintaxis:",
            type: "select",
            var: "seis_15_PT",
            options: [
              {
                value: "1",
                text: "existe concordancia de género, número y tiempos verbales",
              },
              {
                value: "0.5",
                text: "hay 1 inconsistencia de género, número y tiempos verbales",
              },
              {
                value: "0",
                text: "hay más de 1 inconsistencia de género, número y tiempos verbales",
              },
            ],
          },
          {
            label: "Ortografía literal:",
            type: "select",
            var: "seis_16_PT",
            options: [
              {
                value: "1",
                text: "Uso de mayúsculas, de v/b; c/s/z; sin omisión de letras, sin escritura en carro ni inversiones",
              },
              { value: "0.5", text: "hay 1 o 2 errores" },
              { value: "0", text: "hay más de 2 errores" },
            ],
          },
          {
            label: "Ortografía acentual:",
            type: "select",
            var: "seis_17_PT",
            options: [
              {
                value: "1",
                text: "uso de tilde en palabras agudas, graves y esdrújulas",
              },
              { value: "0.5", text: "hay 1 ó 2 errores" },
              { value: "0", text: "hay más de 2 errores" },
            ],
          },
          {
            label: "Ortografía puntual:",
            type: "select",
            var: "seis_18_PT",
            options: [
              {
                value: "1",
                text: "uso adecuado del punto aparte, punto final y comas en enumeraciones",
              },
              { value: "0.5", text: "hay 1 ó 2 errores" },
              { value: "0", text: "hay más de 2 errores" },
            ],
          },
          {
            label: "Precisión y variedad:",
            type: "select",
            var: "dieciseis_1_PT",
            options: [
              {
                value: "3",
                text: "opina de manera clara, incluyendo al menos 3 argumentos que sustenten su opinión",
              },
              {
                value: "2",
                text: "opina de manera clara, incluyendo sólo 2 argumentos que sustenten su opinión",
              },
              {
                value: "1",
                text: "opina de manera poco clara, o incluye sólo 1 argumento que sustenta su opinión",
              },
              { value: "0", text: "no da su opinión o ésta es confusa" },
            ],
          },
          {
            label: "Comprensión del tema:",
            type: "select",
            var: "dieciseis_2_PT",
            options: [
              {
                value: "1",
                text: "muestra comprensión y compromiso con el tema",
              },
              {
                value: "0.5",
                text: "muestra escasa comprensión y compromiso con el tema",
              },
              { value: "0", text: "muestra incomprensión del tema" },
            ],
          },
          {
            label: "Capacidad expresiva personal:",
            type: "select",
            var: "dieciseis_3_PT",
            options: [
              {
                value: "2",
                text: "opina de manera original, escogiendo palabras específicas para referirse al tema",
              },
              {
                value: "1",
                text: "opina de manera poco original, con palabras poco específicas",
              },
              {
                value: "0",
                text: "su opinión es incompleta, repetitiva o utiliza palabras inapropiadas",
              },
            ],
          },
          {
            label: "Reflexión sobre el significado:",
            type: "select",
            var: "dieciseis_4_PT",
            options: [
              {
                value: "1",
                text: "muestra y/o emoción al opinar sobre el tema",
              },
              { value: "0.5", text: "muestra reflexión y emoción escasas" },
              { value: "0", text: "no muestra reflexión ni emoción" },
            ],
          },
          {
            label: "Ideas fluyen con naturalidad:",
            type: "select",
            var: "dieciseis_5_PT",
            options: [
              {
                value: "1",
                text: "su opinión se expresa con naturalidad dada la forma en que se integran las ideas que la sustentan",
              },
              {
                value: "0.5",
                text: "no hay clara integración entre la opinión y las ideas que la sustentan",
              },
              {
                value: "0",
                text: "los errores impiden seriamente seguir el hilo de las ideas o comprenderlas",
              },
            ],
          },
          {
            label: "Presencia de conectores:",
            type: "select",
            var: "dieciseis_6_PT",
            options: [
              {
                value: "1",
                text: "utiliza conectores pertinentes y variados además de “porque”",
              },
              {
                value: "0.5",
                text: "utiliza conectores pertinentes pero repetitivos",
              },
              { value: "0", text: "no utiliza conectores pertinentes" },
            ],
          },
          {
            label: "Estructura textual pertinente:",
            type: "select",
            var: "dieciseis_7_PT",
            options: [
              {
                value: "1",
                text: "desarrolla opinión de manera lógica y organizada",
              },
              {
                value: "0.5",
                text: "desarrolla su opinión de manera poco lógica y organizada",
              },
              {
                value: "0",
                text: "no aporta su opinión o ésta es incomprensible",
              },
            ],
          },
          {
            label: "Sintaxis:",
            type: "select",
            var: "dieciseis_8_PT",
            options: [
              { value: "1", text: "oraciones con orden lógico" },
              { value: "0.5", text: "1 error de sintaxis" },
              { value: "0", text: "más de 1 error de sintaxis" },
            ],
          },
          {
            label: "Morfosintaxis:",
            type: "select",
            var: "dieciseis_9_PT",
            options: [
              {
                value: "1",
                text: "existe concordancia de género, número y tiempos verbales",
              },
              {
                value: "0.5",
                text: "hay 1 inconsistencia de género, número y tiempos verbales",
              },
              {
                value: "0",
                text: "hay más de 1 inconsistencia de género, número y tiempos verbales",
              },
            ],
          },
          {
            label: "Ortografía literal:",
            type: "select",
            var: "dieciseis_10_PT",
            options: [
              {
                value: "1",
                text: "uso de mayúsculas, de v/b; c/s/z; sin omisión de letras, sin escritura en carro ni inversiones",
              },
              {
                value: "0.5",
                text: "hay 1 error en el uso de mayúsculas, de v/b; c/s/z; u omisión de letras, escritura en carro o inversiones",
              },
              {
                value: "0",
                text: "hay más de 1 error en el uso de mayúsculas, de v/b; c/s/z; u omisión de letras, escritura en carro o inversiones",
              },
            ],
          },
          {
            label: "Ortografía acentual:",
            type: "select",
            var: "dieciseis_11_PT",
            options: [
              {
                value: "1",
                text: "uso de tilde en palabras agudas, graves y esdrújulas",
              },
              {
                value: "0.5",
                text: "hay 1 ó 2 errores en el uso de tilde en palabras agudas, graves y esdrújulas",
              },
              {
                value: "0",
                text: "hay más de 2 errores en el uso de tilde en palabras agudas, graves y esdrújulas",
              },
            ],
          },
          {
            label: "Ortografía puntual:",
            type: "select",
            var: "dieciseis_12_PT",
            options: [
              {
                value: "1",
                text: "uso adecuado del punto aparte, punto final y comas",
              },
              {
                value: "0.5",
                text: "hay 1 ó 2 errores en el uso adecuado del punto aparte, punto final y comas",
              },
              {
                value: "0",
                text: "hay más de 2 errores en el uso adecuado del punto aparte, punto final y comas",
              },
            ],
          },
          { type: "h3", label: "7" },
          {
            type: "select",
            var: "siete_1_ML",
            options: [
              { value: "0", text: "Buey" },
              { value: "2", text: "Puma" },
              { value: "00", text: "Conejo" },
            ],
          },
          {
            type: "select",
            var: "siete_2_ML",
            options: [
              { value: "2", text: "Buey" },
              { value: "0", text: "Puma" },
              { value: "00", text: "Conejo" },
            ],
          },
          {
            type: "select",
            var: "siete_3_ML",
            options: [
              { value: "0", text: "Buey" },
              { value: "2", text: "Puma" },
              { value: "00", text: "Conejo" },
            ],
          },
          {
            type: "select",
            var: "siete_4_ML",
            options: [
              { value: "0", text: "Buey" },
              { value: "00", text: "Puma" },
              { value: "2", text: "Conejo" },
            ],
          },
          { type: "h3", label: "8" },
          {
            type: "radio",
            var: "ocho_CL",
            options: [
              { value: "1", text: " Un elefante hembra conduciendo la manada" },
              {
                value: "0",
                text: " Una manada de elefantes cazando un antílope",
              },
              {
                value: "01",
                text: " Una manada de elefantes comiendo hojas de los árboles",
              },
              {
                value: "00",
                text: " Una manada de elefantes caminando junto a un grupo de canguros",
              },
            ],
          },
          { type: "h3", label: "9" },
          {
            type: "radio",
            var: "nueve_CL",
            options: [
              { value: "0", text: " Un texto de opinión sobre elefantes" },
              { value: "1", text: " Un texto informativo sobre los elefantes" },
              { value: "00", text: " Una crónica sobre los elefantes" },
            ],
          },
          { type: "h3", label: "10" },
          {
            type: "select",
            var: "diez_1_CL",
            options: [
              { value: "0.5", text: "v" },
              { value: "0", text: "F" },
            ],
          },
          {
            type: "select",
            var: "diez_2_CL",
            options: [
              { value: "0", text: "v" },
              { value: "0.5", text: "F" },
            ],
          },
          {
            type: "select",
            var: "diez_3_CL",
            options: [
              { value: "0", text: "v" },
              { value: "0.5", text: "F" },
            ],
          },
          {
            type: "select",
            var: "diez_4_CL",
            options: [
              { value: "0.5", text: "v" },
              { value: "0", text: "F" },
            ],
          },
          { type: "h3", label: "11" },
          {
            label: "Hábitat elefante asiático:",
            type: "select",
            var: "once_1_CL",
            options: [
              { value: "1", text: "El Elefante asiático vive en la jungla" },
              { value: "0", text: "El elefante asiático vive en otra parte" },
            ],
          },
          {
            label: "Hábitat elefante africano:",
            type: "select",
            var: "once_2_CL",
            options: [
              {
                value: "1",
                text: "El elefante africano vive en la saba y en el bosque",
              },
              { value: "0", text: "El elefante africano vive en otra parte" },
            ],
          },
          {
            label: "Peso elefante asiático:",
            type: "select",
            var: "once_3_CL",
            options: [
              { value: "1", text: "El elefante asiático pesa 5 toneladas" },
              { value: "0", text: "El elefante asiático tiene otro peso" },
            ],
          },
          {
            label: "Peso elefante africano:",
            type: "select",
            var: "once_4_CL",
            options: [
              { value: "1", text: "El elefante africano pesa 7 toneladas" },
              { value: "0", text: "El elefante africano tiene otro peso" },
            ],
          },
          {
            label: "Orejas elefante asiático:",
            type: "select",
            var: "once_5_CL",
            options: [
              {
                value: "1",
                text: "El elefante asiático tiene orejas más cortas",
              },
              { value: "0", text: "otra respuesta" },
            ],
          },
          {
            label: "Orejas elefante africano:",
            type: "select",
            var: "once_6_CL",
            options: [
              {
                value: "1",
                text: "El elefante africano tiene las orejas más largas",
              },
              { value: "0", text: "otra respuesta" },
            ],
          },
          {
            label: "Colmillos elefante asiático:",
            type: "select",
            var: "once_7_CL",
            options: [
              {
                value: "1",
                text: "Solo los machos de elefante asiático tienen colmillos",
              },
              { value: "0", text: "otra respuesta" },
            ],
          },
          {
            label: "Colmillos elefante africano:",
            type: "select",
            var: "once_8_CL",
            options: [
              {
                value: "1",
                text: "Los elefantes africanos machos y hembra tienen colmillos",
              },
              { value: "0", text: "otra respuesta" },
            ],
          },
          { type: "h3", label: "12" },
          {
            type: "select",
            var: "doce_1_ML",
            options: [
              {
                value: "2",
                text: "las palabras están correctamente ordenadas",
              },
              {
                value: "1",
                text: "el orden está correcto, pero falta una palabra",
              },
              {
                value: "0",
                text: "el orden está incorrecto o le faltan 2 o más palabras",
              },
            ],
          },
          {
            type: "select",
            var: "doce_2_ML",
            options: [
              {
                value: "2",
                text: "las palabras están correctamente ordenadas",
              },
              {
                value: "1",
                text: "el orden está correcto, pero falta una palabra",
              },
              {
                value: "0",
                text: "el orden está incorrecto o le faltan 2 o más palabras",
              },
            ],
          },
          {
            type: "select",
            var: "doce_3_ML",
            options: [
              {
                value: "2",
                text: "las palabras están correctamente ordenadas",
              },
              {
                value: "1",
                text: "el orden está correcto, pero falta una palabra",
              },
              {
                value: "0",
                text: "el orden está incorrecto o le faltan 2 o más palabras",
              },
            ],
          },
          { type: "h3", label: "13" },
          {
            type: "select",
            var: "trece_1_CL",
            options: [
              { value: "1", text: "Linares" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "select",
            var: "trece_2_CL",
            options: [
              { value: "1", text: "Norte 5" },
              { value: "0", text: "otro" },
            ],
          },
          {
            type: "select",
            var: "trece_3_CL",
            options: [
              { value: "3", text: "Sur- San Javier-Oeste" },
              { value: "2", text: "Sur- San Javier- otro" },
              { value: "2.0", text: "Sur- otro- oeste" },
              { value: "1", text: "solo una opción es correcta" },
              { value: "0", text: "ninguna opción correcta" },
              { value: "3.0", text: "Oeste-Curepto-Sur" },
              { value: "2.00", text: "Oeste-Curepto-otro" },
              { value: "2.000", text: "Oeste-otro-sur" },
              { value: "1.0", text: "solo una opción es correcta" },
              { value: "00", text: "ninguna opción correcta" },
            ],
          },
          { type: "h3", label: "14" },
          {
            type: "select",
            var: "catorce_1_CL",
            options: [
              { value: "1", text: "Domingo" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "select",
            var: "catorce_2_CL",
            options: [
              { value: "0.5", text: "En copiapó" },
              { value: "0", text: "Otro" },
            ],
          },
          {
            type: "select",
            var: "catorce_3_CL",
            options: [
              { value: "0.5", text: "En Coihaique y Puerto Montt" },
              { value: "0", text: "otras" },
            ],
          },
          { type: "h3", label: "15" },
          {
            type: "select",
            var: "quince_1_CL",
            options: [
              { value: "1", text: "hecho" },
              { value: "0", text: "opinión" },
            ],
          },
          {
            type: "select",
            var: "quince_2_CL",
            options: [
              { value: "1", text: "hecho" },
              { value: "0", text: "opinión" },
            ],
          },
          {
            type: "select",
            var: "quince_3_CL",
            options: [
              { value: "0", text: "hecho" },
              { value: "1", text: "opinión" },
            ],
          },
          {
            type: "select",
            var: "quince_4_CL",
            options: [
              { value: "0", text: "hecho" },
              { value: "1", text: "opinión" },
            ],
          },
          { type: "h3", label: "16" },
          {
            label: "Precisión y variedad:",
            type: "select",
            var: "dieciseis_1_PT",
            options: [
              {
                value: "3",
                text: "opina de manera clara, incluyendo al menos 3 argumentos que sustenten su opinión",
              },
              {
                value: "2",
                text: "opina de manera clara, incluyendo sólo 2 argumentos que sustenten su opinión",
              },
              {
                value: "1",
                text: "opina de manera poco clara, o incluye sólo 1 argumento que sustenta su opinión",
              },
              { value: "0", text: "no da su opinión o ésta es confusa" },
            ],
          },
          {
            label: "Comprensión del tema:",
            type: "select",
            var: "dieciseis_2_PT",
            options: [
              {
                value: "1",
                text: "muestra comprensión y compromiso con el tema",
              },
              {
                value: "0.5",
                text: "muestra escasa comprensión y compromiso con el tema",
              },
              { value: "0", text: "muestra incomprensión del tema" },
            ],
          },
          {
            label: "Capacidad expresiva personal:",
            type: "select",
            var: "dieciseis_3_PT",
            options: [
              {
                value: "2",
                text: "opina de manera original, escogiendo palabras específicas para referirse al tema",
              },
              {
                value: "1",
                text: "opina de manera poco original, con palabras poco específicas",
              },
              {
                value: "0",
                text: "su opinión es incompleta, repetitiva o utiliza palabras inapropiadas",
              },
            ],
          },
          {
            label: "Reflexión sobre el significado:",
            type: "select",
            var: "dieciseis_4_PT",
            options: [
              {
                value: "1",
                text: "muestra y/o emoción al opinar sobre el tema",
              },
              { value: "0.5", text: "muestra reflexión y emoción escasas" },
              { value: "0", text: "no muestra reflexión ni emoción" },
            ],
          },
          {
            label: "Ideas fluyen con naturalidad:",
            type: "select",
            var: "dieciseis_5_PT",
            options: [
              {
                value: "1",
                text: "su opinión se expresa con naturalidad dada la forma en que se integran las ideas que la sustentan",
              },
              {
                value: "0.5",
                text: "no hay clara integración entre la opinión y las ideas que la sustentan",
              },
              {
                value: "0",
                text: "los errores impiden seriamente seguir el hilo de las ideas o comprenderlas",
              },
            ],
          },
          {
            label: "Presencia de conectores:",
            type: "select",
            var: "dieciseis_6_PT",
            options: [
              {
                value: "1",
                text: "utiliza conectores pertinentes y variados además de “porque”",
              },
              {
                value: "0.5",
                text: "utiliza conectores pertinentes pero repetitivos",
              },
              { value: "0", text: "no utiliza conectores pertinentes" },
            ],
          },
          {
            label: "Estructura textual pertinente:",
            type: "select",
            var: "dieciseis_7_PT",
            options: [
              {
                value: "1",
                text: "desarrolla opinión de manera lógica y organizada",
              },
              {
                value: "0.5",
                text: "desarrolla su opinión de manera poco lógica y organizada",
              },
              {
                value: "0",
                text: "no aporta su opinión o ésta es incomprensible",
              },
            ],
          },
          {
            label: "Sintaxis:",
            type: "select",
            var: "dieciseis_8_PT",
            options: [
              { value: "1", text: "oraciones con orden lógico" },
              { value: "0.5", text: "1 error de sintaxis" },
              { value: "0", text: "más de 1 error de sintaxis" },
            ],
          },
          {
            label: "Morfosintaxis:",
            type: "select",
            var: "dieciseis_9_PT",
            options: [
              {
                value: "1",
                text: "existe concordancia de género, número y tiempos verbales",
              },
              {
                value: "0.5",
                text: "hay 1 inconsistencia de género, número y tiempos verbales",
              },
              {
                value: "0",
                text: "hay más de 1 inconsistencia de género, número y tiempos verbales",
              },
            ],
          },
          {
            label: "Ortografía literal:",
            type: "select",
            var: "dieciseis_10_PT",
            options: [
              {
                value: "1",
                text: "uso de mayúsculas, de v/b; c/s/z; sin omisión de letras, sin escritura en carro ni inversiones",
              },
              {
                value: "0.5",
                text: "hay 1 error en el uso de mayúsculas, de v/b; c/s/z; u omisión de letras, escritura en carro o inversiones",
              },
              {
                value: "0",
                text: "hay más de 1 error en el uso de mayúsculas, de v/b; c/s/z; u omisión de letras, escritura en carro o inversiones",
              },
            ],
          },
          {
            label: "Ortografía acentual:",
            type: "select",
            var: "dieciseis_11_PT",
            options: [
              {
                value: "1",
                text: "uso de tilde en palabras agudas, graves y esdrújulas",
              },
              {
                value: "0.5",
                text: "hay 1 ó 2 errores en el uso de tilde en palabras agudas, graves y esdrújulas",
              },
              {
                value: "0",
                text: "hay más de 2 errores en el uso de tilde en palabras agudas, graves y esdrújulas",
              },
            ],
          },
          {
            label: "Ortografía puntual:",
            type: "select",
            var: "dieciseis_12_PT",
            options: [
              {
                value: "1",
                text: "uso adecuado del punto aparte, punto final y comas",
              },
              {
                value: "0.5",
                text: "hay 1 ó 2 errores en el uso adecuado del punto aparte, punto final y comas",
              },
              {
                value: "0",
                text: "hay más de 2 errores en el uso adecuado del punto aparte, punto final y comas",
              },
            ],
          },
        ],
        [
          { type: "h3", label: "1" },
          {
            label: "Ingrese puntaje: :",
            type: "select",
            var: "uno_CL",
            options: [
              { value: "0", text: "0" },
              { value: "1", text: "1" },
              { value: "2", text: "2" },
              { value: "3", text: "3" },
              { value: "4", text: "4" },
              { value: "5", text: "5" },
              { value: "6", text: "6" },
              { value: "7", text: "7" },
              { value: "8", text: "8" },
              { value: "9", text: "9" },
              { value: "10", text: "10" },
              { value: "11", text: "11" },
              { value: "12", text: "12" },
            ],
          },
          { type: "h3", label: "2" },
          {
            type: "radio",
            var: "dos_CL",
            options: [
              { value: "0", text: " Su desobediencia y obstinación" },
              { value: "1", text: " Su coraje, su inteligencia y su fuerza" },
              {
                value: "00",
                text: " La admiración de Filipo por la belleza de su hijo Alejandro",
              },
            ],
          },
          { type: "h3", label: "3" },
          {
            type: "radio",
            var: "tres_CL",
            options: [
              { value: "0", text: " Un domador de caballos de Macedonia" },
              { value: "1", text: " Rey de Macedonia y otros territorios" },
              {
                value: "00",
                text: " Un general de la caballería de Macedonia",
              },
            ],
          },
          { type: "h3", label: "4" },
          {
            type: "radio",
            var: "cuatro_CL",
            options: [
              { value: "0", text: " No quiere tenerlo en Macedonia" },
              {
                value: "0",
                text: " La superficie de Macedonia es muy pequeña",
              },
              {
                value: "1",
                text: " Piensa que Alejandro es capaz de conquistar nuevos mundos",
              },
            ],
          },
          { type: "h3", label: "5" },
          {
            type: "radio",
            var: "cinco_CL",
            options: [
              { value: "4", text: " En las líneas 1-2-25-28" },
              { value: "0", text: " En las líneas 3-13" },
              { value: "00", text: " En las líneas 1-2-15" },
            ],
          },
          { type: "h3", label: "6" },
          {
            label: "Domadores:",
            type: "select",
            var: "seis_1_CL",
            options: [
              { value: "2", text: "Escribe 2 evidencias" },
              { value: "1", text: "Escribe 1 evidencia" },
              { value: "0", text: "No escribe ninguna evidencia" },
            ],
          },
          {
            label: "Alejandro:",
            type: "select",
            var: "seis_2_CL",
            options: [
              { value: "2", text: "Escribe 2 evidencias" },
              { value: "1", text: "Escribe 1 evidencia" },
              { value: "0", text: "No escribe ninguna evidencia" },
            ],
          },
          { type: "h3", label: "7" },
          {
            label: "Relación con el destinatario:",
            type: "select",
            var: "siete_0_PT",
            options: [
              { value: "1", text: "Utiliza lenguaje formal" },
              { value: "0.5", text: "su lenguaje formal es fluctuante" },
              { value: "0", text: "su lenguaje es informal" },
            ],
          },
          {
            label: "Intención comunicativa:",
            type: "select",
            var: "siete_1_PT",
            options: [
              {
                value: "0.5",
                text: "está presente, pues describe correctamente al persona principal",
              },
              {
                value: "0",
                text: "no está presente, pues no describe correctamente al persona principal",
              },
            ],
          },
          {
            label: "Presentación:",
            type: "select",
            var: "siete_2_PT",
            options: [
              {
                value: "0.5",
                text: "el escrito tiene una presentación legible",
              },
              {
                value: "0.25",
                text: "el escrito tiene una presentación parcialmente legible",
              },
              {
                value: "0",
                text: "el escrito tiene una presentación ilegible",
              },
            ],
          },
          {
            label: "Precisión y variedad:",
            type: "select",
            var: "siete_3_PT",
            options: [
              {
                value: "5",
                text: "describe 5 ó más características físicas y psicológicas del persona principal",
              },
              {
                value: "4",
                text: "describe 3 ó 4 características físicas y psicológicas del persona principal",
              },
              {
                value: "3",
                text: "escribe 1 ó 2 características claras pero poco interesantes del persona principal",
              },
              {
                value: "2",
                text: "escribe un listado de 4 ó más características del persona principal",
              },
              {
                value: "1",
                text: "hace un listado de 2 a 3 características poco relevantes del persona principal",
              },
              {
                value: "0",
                text: "descripción muy imprecisa o incorrecta del persona principal",
              },
            ],
          },
          {
            label: "Comprensión del tema:",
            type: "select",
            var: "siete_4_PT",
            options: [
              {
                value: "2",
                text: "Muestra comprensión y compromiso con el personaje",
              },
              {
                value: "1",
                text: "Muestra escasa comprensión y compromiso con el personaje",
              },
              { value: "0", text: "No muestra comprensión del personaje" },
            ],
          },
          {
            label: "Capacidad expresiva personal:",
            type: "select",
            var: "siete_5_PT",
            options: [
              {
                value: "1",
                text: "Revela originalidad y vitalidad captando el interés del lector",
              },
              {
                value: "0.5",
                text: "Revela limitada originalidad y vitalidad, captando medianamente el interés del lector",
              },
              {
                value: "0",
                text: "No revela originalidad ni capta el interés del lector",
              },
            ],
          },
          {
            label: "Reflexión sobre el significado:",
            type: "select",
            var: "siete_6_PT",
            options: [
              { value: "1", text: "Muestra reflexión sobre Alejandro" },
              { value: "0.5", text: "muestra escasa reflexión" },
              { value: "0", text: "no muestra reflexión" },
            ],
          },
          {
            label: "Vocabulario preciso:",
            type: "select",
            var: "siete_7_PT",
            options: [
              { value: "1", text: "Usa palabras precisas y coloridas" },
              {
                value: "0.5",
                text: "Usa palabras más generales o usa palabras o expresiones propias del lenguaje oral",
              },
              { value: "0", text: "Usa vocabulario incorrecto o inadecuado" },
            ],
          },
          {
            label: "Vocabulario variado:",
            type: "select",
            var: "siete_8_PT",
            options: [
              { value: "1", text: "Usa diversidad de palabras" },
              { value: "0.5", text: "Usa vocabulario limitado" },
              { value: "0", text: "Usa vocabulario muy restringido o erróneo" },
            ],
          },
          {
            label: "Ideas Fluyen con naturalidad:",
            type: "select",
            var: "siete_9_PT",
            options: [
              {
                value: "1",
                text: "Permite lectura ágil y amena, porque las ideas se entrelazan con naturalidad",
              },
              {
                value: "0.5",
                text: "es necesario releer el texto, dado que presenta inconsistencias en el hilo conductor",
              },
              { value: "0", text: "No permite seguir el hilo de ideas" },
            ],
          },
          {
            label: "Presencia de conectores:",
            type: "select",
            var: "siete_10_PT",
            options: [
              {
                value: "1",
                text: "Utiliza conectores pertinentes y diversos cada vez que se requiere",
              },
              {
                value: "0.5",
                text: "Usa conectores repetitivos o poco pertinentes",
              },
              { value: "0", text: "Faltan conectores o éstos son inadecuados" },
            ],
          },
          {
            label: "Estructura textual pertinente 1:",
            type: "select",
            var: "siete_11_PT",
            options: [
              {
                value: "1",
                text: "Utiliza adecuacadamente el organizador gráfico o se infiere que lo utiliza",
              },
              { value: "0", text: "No utiliza el organizador gráfico" },
            ],
          },
          {
            label: "Estructura textual pertinente 2:",
            type: "select",
            var: "siete_12_PT",
            options: [
              {
                value: "1",
                text: "Su descripción es desorganizada o es una enumeración",
              },
              { value: "0", text: "Su descripción carece de estructura" },
            ],
          },
          {
            label: "Sintaxis:",
            type: "select",
            var: "siete_13_PT",
            options: [
              {
                value: "1",
                text: "Oraciones con orden lógico y presencia de oraciones coordinadas o subordinadas",
              },
              {
                value: "0.5",
                text: "Oraciones con orden lógico, pero ausencia de oraciones coordinadas o subordinadas, o presencia de oraciones coordinadas o subordinadas con secuencia confusa",
              },
              { value: "0", text: "oraciones incoherentes" },
            ],
          },
          {
            label: "Morfosintaxis:",
            type: "select",
            var: "siete_14_PT",
            options: [
              {
                value: "1",
                text: "existe concordancia de género, número, tiempos verbales y sistema de verbos",
              },
              {
                value: "0.5",
                text: "hay 1 inconsistencia de género, número y tiempos verbales y sistema de verbos",
              },
              {
                value: "0",
                text: "hay más de 1 inconsistencia de género, número y tiempos verbales y sistema de verbos",
              },
            ],
          },
          {
            label: "Ortogradfía literal:",
            type: "select",
            var: "siete_15_PT",
            options: [
              {
                value: "0.5",
                text: "presenta uso adecuado de ortografía literal, incluyendo mayúsculas",
              },
              {
                value: "0.25",
                text: "hay 1 o 2 errores en el uso de ortografía literal y mayúsculas",
              },
              {
                value: "0",
                text: "hay más de 2 errores en el uso de ortografía literal y mayúsculas",
              },
            ],
          },
          {
            label: "Ortografía acentual:",
            type: "select",
            var: "siete_16_PT",
            options: [
              { value: "0.5", text: "uso de tilde" },
              { value: "0.25", text: "hay 1 o 2 errores en el uso de tildes" },
              { value: "0", text: "hay más de 2 errores en el uso de tildes" },
            ],
          },
          {
            label: "Ortografía puntual:",
            type: "select",
            var: "siete_17_PT",
            options: [
              {
                value: "1",
                text: "uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de “pero”, “ya que” y “luego”",
              },
              {
                value: "0.5",
                text: "hay 1 ó 2 errores en el uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de “pero”, “ya que” y “luego”",
              },
              {
                value: "0",
                text: "hay más de 2 errores en el uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de “pero”, “ya que” y “luego”",
              },
            ],
          },
          { type: "h3", label: "8" },
          {
            type: "select",
            var: "ocho_1_CL",
            options: [
              { value: "1", text: "Alejandro" },
              { value: "0", text: "Filipo" },
              { value: "00", text: "Bucéfalo" },
            ],
          },
          {
            type: "select",
            var: "ocho_2_CL",
            options: [
              { value: "0", text: "Alejandro" },
              { value: "00", text: "Filipo" },
              { value: "1", text: "Bucéfalo" },
            ],
          },
          {
            type: "select",
            var: "ocho_3_CL",
            options: [
              { value: "0", text: "Alejandro" },
              { value: "1", text: "Filipo" },
              { value: "00", text: "Bucéfalo" },
            ],
          },
          {
            type: "select",
            var: "ocho_4_CL",
            options: [
              { value: "1", text: "Alejandro" },
              { value: "0", text: "Filipo" },
              { value: "00", text: "Bucéfalo" },
            ],
          },
          { type: "h3", label: "9" },
          {
            label: "No:",
            type: "select",
            var: "nueve_1_CL",
            options: [
              { value: "0.5", text: "No.-des" },
              { value: "0", text: "Casa, hogar, ambiente.-bio.-in.-des.-eco" },
              { value: "00", text: "Biológico, vida.-bio.-in.-des.-eco" },
              { value: "000", text: "Lo contrario de.-bio.-in.-des.-eco" },
              {
                value: "0000",
                text: "Componer, formar un todo.diversidad.-tangible.-composición",
              },
              {
                value: "00000",
                text: "Variedad.diversidad.-tangible.-composición.-sistema",
              },
              {
                value: "000000",
                text: "Sistema.diversidad.-tangible.-composición.-sistema",
              },
              {
                value: "0000000",
                text: "Tocar.diversidad.-tangible.-composición.-sistema",
              },
            ],
          },
          {
            label: "Casa, hogar, ambiente:",
            type: "select",
            var: "nueve_2_CL",
            options: [
              { value: "0", text: "No.-bio.-in.-des.-eco" },
              { value: "0.5", text: "Casa, hogar, ambiente.-eco" },
              { value: "00", text: "Biológico, vida.-bio.-in.-des.-eco" },
              { value: "000", text: "Lo contrario de.-bio.-in.-des.-eco" },
              {
                value: "0000",
                text: "Componer, formar un todo.diversidad.-tangible.-composición",
              },
              {
                value: "00000",
                text: "Variedad.diversidad.-tangible.-composición.-sistema",
              },
              {
                value: "000000",
                text: "Sistema.diversidad.-tangible.-composición.-sistema",
              },
              {
                value: "0000000",
                text: "Tocar.diversidad.-tangible.-composición.-sistema",
              },
            ],
          },
          {
            label: " Biológico, vida:",
            type: "select",
            var: "nueve_3_CL",
            options: [
              { value: "0", text: "No.-bio.-in.-des.-eco" },
              { value: "00", text: "Casa, hogar, ambiente.-bio.-in.-des.-eco" },
              { value: "0.5", text: "Biológico, vida.-bio" },
              { value: "000", text: "Lo contrario de.-bio.-in.-des.-eco" },
              {
                value: "0000",
                text: "Componer, formar un todo.diversidad.-tangible.-composición",
              },
              {
                value: "00000",
                text: "Variedad.diversidad.-tangible.-composición.-sistema",
              },
              {
                value: "000000",
                text: "Sistema.diversidad.-tangible.-composición.-sistema",
              },
              {
                value: "0000000",
                text: "Tocar.diversidad.-tangible.-composición.-sistema",
              },
            ],
          },
          {
            label: "Lo contrario de:",
            type: "select",
            var: "nueve_4_CL",
            options: [
              { value: "0", text: "No.-bio.-in.-des.-eco" },
              { value: "00", text: "Casa, hogar, ambiente.-bio.-in.-des.-eco" },
              { value: "000", text: "Biológico, vida.-bio.-in.-des.-eco" },
              { value: "0.5", text: "Lo contrario de.-des" },
              {
                value: "0000",
                text: "Componer, formar un todo.diversidad.-tangible.-composición",
              },
              {
                value: "00000",
                text: "Variedad.diversidad.-tangible.-composición.-sistema",
              },
              {
                value: "000000",
                text: "Sistema.diversidad.-tangible.-composición.-sistema",
              },
              {
                value: "0000000",
                text: "Tocar.diversidad.-tangible.-composición.-sistema",
              },
            ],
          },
          {
            label: " Componer, formar un todo:",
            type: "select",
            var: "nueve_5_CL",
            options: [
              { value: "0.5", text: "No.-bio.-in.-des.-eco" },
              { value: "0", text: "Casa, hogar, ambiente.-bio.-in.-des.-eco" },
              { value: "00", text: "Biológico, vida.-bio.-in.-des.-eco" },
              { value: "000", text: "Lo contrario de.-bio.-in.-des.-eco" },
              { value: "0.5", text: "Componer, formar un todo.-composición" },
              {
                value: "00000",
                text: "Variedad.diversidad.-tangible.-composición.-sistema",
              },
              {
                value: "000000",
                text: "Sistema.diversidad.-tangible.-composición.-sistema",
              },
              {
                value: "0000000",
                text: "Tocar.diversidad.-tangible.-composición.-sistema",
              },
            ],
          },
          {
            label: "Variedad:",
            type: "select",
            var: "nueve_6_CL",
            options: [
              { value: "0.5", text: "No.-bio.-in.-des.-eco" },
              { value: "0", text: "Casa, hogar, ambiente.-bio.-in.-des.-eco" },
              { value: "00", text: "Biológico, vida.-bio.-in.-des.-eco" },
              { value: "000", text: "Lo contrario de.-bio.-in.-des.-eco" },
              {
                value: "0000",
                text: "Componer, formar un todo.diversidad.-tangible.-composición",
              },
              { value: "0.5", text: "Variedad.diversidad" },
              {
                value: "000000",
                text: "Sistema.diversidad.-tangible.-composición.-sistema",
              },
              {
                value: "0000000",
                text: "Tocar.diversidad.-tangible.-composición.-sistema",
              },
            ],
          },
          {
            label: "Sistema:",
            type: "select",
            var: "nueve_7_CL",
            options: [
              { value: "0.5", text: "No.-bio.-in.-des.-eco" },
              { value: "0", text: "Casa, hogar, ambiente.-bio.-in.-des.-eco" },
              { value: "00", text: "Biológico, vida.-bio.-in.-des.-eco" },
              { value: "000", text: "Lo contrario de.-bio.-in.-des.-eco" },
              {
                value: "0000",
                text: "Componer, formar un todo.diversidad.-tangible.-composición",
              },
              {
                value: "00000",
                text: "Variedad.diversidad.-tangible.-composición.-sistema",
              },
              { value: "0.5", text: "Sistema.-sistema" },
              {
                value: "0000000",
                text: "Tocar.diversidad.-tangible.-composición.-sistema",
              },
            ],
          },
          {
            label: "Tocar:",
            type: "select",
            var: "nueve_8_CL",
            options: [
              { value: "0.5", text: "No.-bio.-in.-des.-eco" },
              { value: "0", text: "Casa, hogar, ambiente.-bio.-in.-des.-eco" },
              { value: "00", text: "Biológico, vida.-bio.-in.-des.-eco" },
              { value: "000", text: "Lo contrario de.-bio.-in.-des.-eco" },
              {
                value: "0000",
                text: "Componer, formar un todo.diversidad.-tangible.-composición",
              },
              {
                value: "00000",
                text: "Variedad.diversidad.-tangible.-composición.-sistema",
              },
              {
                value: "000000",
                text: "Sistema.diversidad.-tangible.-composición.-sistema",
              },
              { value: "0.5", text: "Tocar.-tangible" },
            ],
          },
          { type: "h3", label: "10" },
          {
            type: "radio",
            var: "diez_CL",
            options: [
              {
                value: "0",
                text: " La matanza indiscriminada de estos mamíferos",
              },
              { value: "3", text: " El calentamiento global" },
              {
                value: "00",
                text: " El desprendimiento de un gran trozo de hielo en la Antártica",
              },
            ],
          },
          { type: "h3", label: "11" },
          {
            label: ":",
            type: "multiselect",
            var: "once_1_CL",
            options: [
              { value: "0", text: "1" },
              { value: "00", text: "2" },
              { value: "000", text: "3" },
              { value: "0000", text: "4" },
              { value: "00000", text: "5" },
              { value: "000000", text: "6" },
              { value: "1", text: "7" },
              { value: "0000000", text: "8" },
            ],
          },
          {
            label: ":",
            type: "multiselect",
            var: "once_2_CL",
            options: [
              { value: "0", text: "1" },
              { value: "00", text: "2" },
              { value: "1", text: "3" },
              { value: "000", text: "4" },
              { value: "0000", text: "5" },
              { value: "00000", text: "6" },
              { value: "000000", text: "7" },
              { value: "2", text: "8" },
            ],
          },
          {
            label: ":",
            type: "multiselect",
            var: "once_3_CL",
            options: [
              { value: "0", text: "1" },
              { value: "00", text: "2" },
              { value: "000", text: "3" },
              { value: "0000", text: "4" },
              { value: "1", text: "5" },
              { value: "2", text: "6" },
              { value: "00000", text: "7" },
              { value: "000000", text: "8" },
            ],
          },
          {
            label: ":",
            type: "multiselect",
            var: "once_4_CL",
            options: [
              { value: "0", text: "1" },
              { value: "1", text: "2" },
              { value: "00", text: "3" },
              { value: "000", text: "4" },
              { value: "0000", text: "5" },
              { value: "00000", text: "6" },
              { value: "000000", text: "7" },
              { value: "0000000", text: "8" },
            ],
          },
          {
            label: ":",
            type: "multiselect",
            var: "once_5_CL",
            options: [
              { value: "0", text: "1" },
              { value: "00", text: "2" },
              { value: "000", text: "3" },
              { value: "1", text: "4" },
              { value: "0000", text: "5" },
              { value: "00000", text: "6" },
              { value: "000000", text: "7" },
              { value: "0000000", text: "8" },
            ],
          },
          {
            label: ":",
            type: "multiselect",
            var: "once_6_CL",
            options: [
              { value: "1", text: "1" },
              { value: "0", text: "2" },
              { value: "00", text: "3" },
              { value: "000", text: "4" },
              { value: "0000", text: "5" },
              { value: "00000", text: "6" },
              { value: "000000", text: "7" },
              { value: "0000000", text: "8" },
            ],
          },
          { type: "h3", label: "12" },
          {
            type: "select",
            var: "doce_1_CL",
            options: [
              { value: "0.5", text: "Mamiferos" },
              { value: "0", text: "otro" },
            ],
          },
          {
            type: "select",
            var: "doce_2_CL",
            options: [
              { value: "0.5", text: "Reptiles" },
              { value: "0", text: "otro" },
            ],
          },
          {
            type: "select",
            var: "doce_3_CL",
            options: [
              { value: "0.5", text: "Aves" },
              { value: "0", text: "otro" },
            ],
          },
          {
            type: "select",
            var: "doce_4_CL",
            options: [
              { value: "0.5", text: "Anfibios" },
              { value: "0", text: "otro" },
            ],
          },
          { type: "h3", label: "13" },
          {
            type: "select",
            var: "trece_1_CL",
            options: [
              {
                value: "1",
                text: "En groenlandia se está perdiendo el equilibrio del medio ambiente",
              },
              {
                value: "0",
                text: "El hombre no ha tenido suficiente conciencia sobre la importancia de la biodiversidad",
              },
              {
                value: "00",
                text: "La llegada de los osos polares a Islandia ha afectado la seguridad de sus hábitats",
              },
              {
                value: "000",
                text: "La vida humana no se ve alterada por el deterioro de la biodiversidad",
              },
            ],
          },
          {
            type: "select",
            var: "trece_2_CL",
            options: [
              {
                value: "0",
                text: "En groenlandia se está perdiendo el equilibrio del medio ambiente",
              },
              {
                value: "1",
                text: "El hombre no ha tenido suficiente conciencia sobre la importancia de la biodiversidad",
              },
              {
                value: "00",
                text: "La llegada de los osos polares a Islandia ha afectado la seguridad de sus hábitats",
              },
              {
                value: "000",
                text: "La vida humana no se ve alterada por el deterioro de la biodiversidad",
              },
            ],
          },
          {
            type: "select",
            var: "trece_3_CL",
            options: [
              {
                value: "0",
                text: "En groenlandia se está perdiendo el equilibrio del medio ambiente",
              },
              {
                value: "00",
                text: "El hombre no ha tenido suficiente conciencia sobre la importancia de la biodiversidad",
              },
              {
                value: "1",
                text: "La llegada de los osos polares a Islandia ha afectado la seguridad de sus hábitats",
              },
              {
                value: "000",
                text: "La vida humana no se ve alterada por el deterioro de la biodiversidad",
              },
            ],
          },
          {
            type: "select",
            var: "trece_4_CL",
            options: [
              {
                value: "0",
                text: "En groenlandia se está perdiendo el equilibrio del medio ambiente",
              },
              {
                value: "0",
                text: "El hombre no ha tenido suficiente conciencia sobre la importancia de la biodiversidad",
              },
              {
                value: "00",
                text: "La llegada de los osos polares a Islandia ha afectado la seguridad de sus hábitats",
              },
              {
                value: "1",
                text: "La vida humana no se ve alterada por el deterioro de la biodiversidad",
              },
            ],
          },
          { type: "h3", label: "14" },
          {
            type: "radio",
            var: "catorce_CL",
            options: [
              { value: "1", text: " Los párrafos 2 y 3" },
              { value: "4", text: " Los párrafos 3 y 4" },
              { value: "00", text: " El texto completo" },
            ],
          },
          { type: "h3", label: "15" },
          {
            label: "Relación con el destinatario:",
            type: "select",
            var: "quince_1_PT",
            options: [
              { value: "1", text: "Utiliza un lenguaje formal" },
              { value: "0.5", text: "Su Lenguaje formal es fluctuante" },
              { value: "0", text: "Su lenguaje es informal" },
            ],
          },
          {
            label: "Intención comunicativa:",
            type: "select",
            var: "quince_2_PT",
            options: [
              {
                value: "1",
                text: "Da su opinión fundamentada sobre la noticia del oso polar",
              },
              { value: "0", text: "No da su opinión fundamentada" },
            ],
          },
          {
            label: "Presentación:",
            type: "select",
            var: "quince_3_PT",
            options: [
              { value: "1", text: "Presentación legible" },
              { value: "0.5", text: "Presentación parcialmente legible" },
              { value: "0", text: "Presentación ilegible" },
            ],
          },
          {
            label: "Preseción y variedad:",
            type: "select",
            var: "quince_4_PT",
            options: [
              {
                value: "5",
                text: "opina de manera clara, incluyendo argumentos pertinentes basados en informaciones de los textos leídos y/o en principios valóricos",
              },
              {
                value: "4",
                text: "Opina de manera clara, pero sus argumentos son limitados",
              },
              {
                value: "3",
                text: "Su opinión no es suficientemente clara y sus argumentos limitados",
              },
              { value: "2", text: "Da su opinión sin dar argumentos" },
              {
                value: "1",
                text: "Su opinión no es clara ya que no procesa adecuadamente la información de los textos leídos",
              },
              { value: "0", text: "Su opinión y argumentos son incoherentes" },
            ],
          },
          {
            label: "Comprensión del tema:",
            type: "select",
            var: "quince_5_PT",
            options: [
              {
                value: "3",
                text: "Su opinión revela comprensión profunda sobre el tema",
              },
              { value: "2", text: "Revela comprensión superficial" },
              { value: "1", text: "Su comprensión es incipiente" },
              { value: "0", text: "No muestra comprensión" },
            ],
          },
          {
            label: "Acapacidad expresiva personal:",
            type: "select",
            var: "quince_6_PT",
            options: [
              {
                value: "2",
                text: "Su opinión y argumentos son convincentes captando el interés del lector",
              },
              {
                value: "1",
                text: "Su opinión y fundamentos parcialmente convincentes, captando medianamente el interés del lector",
              },
              {
                value: "0",
                text: "Su planteamiento no es convincente, ni capta el interés del lector",
              },
            ],
          },
          {
            label: "Reflexión sobre el significado:",
            type: "select",
            var: "quince_7_PT",
            options: [
              {
                value: "2",
                text: "Muestra reflexión y compromiso afectivo con el tema",
              },
              {
                value: "1.5",
                text: "Muestra compromiso, pero escasa reflexión",
              },
              { value: "1", text: "Escasa reflexión y compromiso" },
              { value: "0", text: "No muestra reflexión ni compromiso" },
            ],
          },
          {
            label: "Vocabulario preciso:",
            type: "select",
            var: "quince_8_PT",
            options: [
              {
                value: "2",
                text: "Usa palabras y expresiones precisas y pertinentes al tema ecológico y a la intención de argumentar",
              },
              {
                value: "1",
                text: "Usa palabras más generales o usa expresiones del lenguaje oral",
              },
              { value: "0", text: "Usa palabras incorrectas o inadecuadas" },
            ],
          },
          {
            label: "Vocabulario variado:",
            type: "select",
            var: "quince_9_PT",
            options: [
              {
                value: "2",
                text: "Usa diversidad de palabras, expresiones metafóricas, analogías o ejemplos",
              },
              { value: "1", text: "Usa un vocabulario elemental" },
              { value: "0", text: "Usa vocabulario muy restringido" },
            ],
          },
          {
            label: "Ideas fluyen con naturalidad:",
            type: "select",
            var: "quince_10_PT",
            options: [
              {
                value: "3",
                text: "Permite lectura ágil y amena, porque las ideas se entrelazan con naturalidad",
              },
              {
                value: "2",
                text: "Permite avanzar con menor rapidez y requiere relectura para seguir el hilo",
              },
              {
                value: "1",
                text: "Se observan intentos pero se lee con dificultad",
              },
              { value: "0", text: "No permite seguir el hilo de las ideas" },
            ],
          },
          {
            label: "Presencia de conectores:",
            type: "select",
            var: "quince_11_PT",
            options: [
              {
                value: "2",
                text: "Usa conectores pertinentes y diversos ( debido a las razones de, por tal motivo, etc",
              },
              {
                value: "1",
                text: "Utiliza conectores poco pertinentes y repetitivos",
              },
              { value: "0", text: "Faltan conectores o éstos son inadecuados" },
            ],
          },
          {
            label: "Estrategias para la organización:",
            type: "select",
            var: "quince_12_PT",
            options: [
              {
                value: "2",
                text: "Utiliza adecuadamente el organizador gráfico o se infiere que lo utiliza",
              },
              { value: "1", text: "Utiliza parcialmente el organizador" },
              {
                value: "0",
                text: "No utiliza el organizador gráfico o lo utiliza erróneamente",
              },
            ],
          },
          {
            label: "Estructura textual pertinente:",
            type: "select",
            var: "quince_13_PT",
            options: [
              {
                value: "2",
                text: "Da su opinión, argumenta y concluye en párrafos organizados y bien secuenciados",
              },
              {
                value: "1",
                text: "Su opinión y argumentos son incompletos o parcialmente organizados y secuenciados o reiterativos",
              },
              {
                value: "0",
                text: "La estructura de su opinión es insuficiente",
              },
            ],
          },
          {
            label: "Sintaxis:",
            type: "select",
            var: "quince_14_PT",
            options: [
              {
                value: "2",
                text: "Oraciones con orden lógico y presencia de oraciones coordinadas o subordinadas",
              },
              {
                value: "1",
                text: "Oraciones con orden lógico, pero ausencia de oraciones coordinadas o subordinadas, o presencia de oraciones coordinadas o subordinadas, pero secuencia confusa",
              },
              { value: "0", text: "Oraciones incoherentes" },
            ],
          },
          {
            label: "Morfosintaxis:",
            type: "select",
            var: "quince_15_PT",
            options: [
              {
                value: "2",
                text: "existe concordancia de género, número y tiempos verbales, del sistema de verbos y concordancia semántica",
              },
              {
                value: "1",
                text: "hay 1 inconsistencia de género, número y tiempos verbales, del sistema de verbos y concordancia semántica",
              },
              {
                value: "0",
                text: "hay más de 1 inconsistencia de género, número y tiempos verbales, del sistema de verbos y concordancia semántica",
              },
            ],
          },
          {
            label: "Ortografía literal:",
            type: "select",
            var: "quince_16_PT",
            options: [
              {
                value: "1",
                text: "presenta uso adecuado de ortografía literal, incluyendo mayúsculas",
              },
              {
                value: "0.5",
                text: "hay 1 o 2 errores en el uso de ortografía literal y mayúsculas",
              },
              {
                value: "0",
                text: "hay más de 2 errores en el uso de ortografía literal y mayúsculas",
              },
            ],
          },
          {
            label: "Ortografía acentual:",
            type: "select",
            var: "quince_17_PT",
            options: [
              { value: "1", text: "uso de tilde" },
              { value: "0.5", text: "hay 1 ó 2 errores en el uso de tildes" },
              { value: "0", text: "hay más de 2 errores en el uso de tildes" },
            ],
          },
          {
            label: "Ortografía puntual:",
            type: "select",
            var: "quince_18_PT",
            options: [
              {
                value: "2",
                text: "uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de “pero”, “ya que” y “luego”",
              },
              {
                value: "1",
                text: "hay 1 ó 2 errores en el uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de “pero”, “ya que” y “luego”",
              },
              {
                value: "0",
                text: "hay más de 2 errores en el uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de “pero”, “ya que” y “luego”",
              },
            ],
          },
          { type: "h3", label: "16" },
          {
            label: "Qué tipo de texto es?",
            type: "radio",
            var: "dieciseis_1_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0", text: "2" },
              { value: "00", text: "3" },
              { value: "000", text: "4" },
              { value: "0000", text: "5" },
              { value: "00000", text: "6" },
              { value: "000000", text: "7" },
            ],
          },
          {
            label: "Para qué sirve principalmente?",
            type: "radio",
            var: "dieciseis_2_CL",
            options: [
              { value: "0", text: "1" },
              { value: "0.5", text: "2" },
            ],
          },
          {
            label: "uede encontrar?",
            type: "multiselect",
            var: "dieciseis_3_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0", text: "2" },
              { value: "0.50", text: "3" },
              { value: "00", text: "4" },
            ],
          },
          {
            label: "Qué tipo de texto es?",
            type: "radio",
            var: "dieciseis_4_CL",
            options: [
              { value: "0", text: "1" },
              { value: "00", text: "2" },
              { value: "0.5", text: "3" },
              { value: "000", text: "4" },
              { value: "0000", text: "5" },
              { value: "00000", text: "6" },
              { value: "000000", text: "7" },
            ],
          },
          {
            label: "Para qué sirve principalmente?",
            type: "radio",
            var: "dieciseis_5_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0", text: "2" },
            ],
          },
          {
            label: "uede encontrar?",
            type: "multiselect",
            var: "dieciseis_6_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0", text: "2" },
              { value: "00", text: "3" },
              { value: "0.50", text: "4" },
            ],
          },
          {
            label: "Qué tipo de texto es?",
            type: "radio",
            var: "dieciseis_7_CL",
            options: [
              { value: "0", text: "1" },
              { value: "0.5", text: "2" },
              { value: "00", text: "3" },
              { value: "000", text: "4" },
              { value: "0000", text: "5" },
              { value: "00000", text: "6" },
              { value: "000000", text: "7" },
            ],
          },
          {
            label: "Para qué sirve principalmente?",
            type: "radio",
            var: "dieciseis_8_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0", text: "2" },
            ],
          },
          {
            label: "uede encontrar?",
            type: "multiselect",
            var: "dieciseis_9_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0.50", text: "2" },
              { value: "0", text: "3" },
              { value: "00", text: "4" },
            ],
          },
          {
            label: "Qué tipo de texto es?",
            type: "radio",
            var: "dieciseis_10_CL",
            options: [
              { value: "0", text: "1" },
              { value: "00", text: "2" },
              { value: "000", text: "3" },
              { value: "0.5", text: "4" },
              { value: "0000", text: "5" },
              { value: "00000", text: "6" },
              { value: "000000", text: "7" },
            ],
          },
          {
            label: "Para qué sirve principalmente?",
            type: "radio",
            var: "dieciseis_11_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0", text: "2" },
            ],
          },
          {
            label: "uede encontrar?",
            type: "multiselect",
            var: "dieciseis_12_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0", text: "2" },
              { value: "00", text: "3" },
              { value: "0.50", text: "4" },
            ],
          },
        ],
        [
          { type: "h3", label: "1" },
          {
            label: "Ingrese puntaje: :",
            type: "select",
            var: "uno_CL",
            options: [
              { value: "0", text: "0" },
              { value: "1", text: "1" },
              { value: "2", text: "2" },
              { value: "3", text: "3" },
              { value: "4", text: "4" },
              { value: "5", text: "5" },
              { value: "6", text: "6" },
              { value: "7", text: "7" },
              { value: "8", text: "8" },
              { value: "9", text: "9" },
              { value: "10", text: "10" },
              { value: "11", text: "11" },
              { value: "12", text: "12" },
            ],
          },
          { type: "h3", label: "2" },
          {
            type: "radio",
            var: "dos_CL",
            options: [
              {
                value: "1",
                text: " Que la avaricia puede impulsar a las personas as cometer actos deshonestos",
              },
              { value: "0", text: " Que la avaricia conduce a enriquecerse" },
              {
                value: "00",
                text: " Que la ingenuidad conduce a confiar en los desconocidos.",
              },
            ],
          },
          { type: "h3", label: "3" },
          {
            type: "radio",
            var: "tres_CL",
            options: [
              {
                value: "0",
                text: " Para que lo ayudara a enterrar a los cadáveres",
              },
              { value: "1", text: " Para que no le robara las monedas" },
              { value: "00", text: " Para que supiera construir bóvedas" },
            ],
          },
          { type: "h3", label: "4" },
          {
            type: "radio",
            var: "cuatro_CL",
            options: [
              { value: "0", text: " Le desea que se enferme y se muera" },
              { value: "1", text: " Lo considera una persona despreciable" },
              { value: "00", text: " Quiere que lo trasladen a otra ciudad" },
            ],
          },
          { type: "h3", label: "5" },
          {
            type: "radio",
            var: "cinco_CL",
            options: [
              { value: "4", text: " En los párrafos 2,4 y 5" },
              { value: "0", text: " En el párrafo 4" },
              { value: "00", text: " En los párrafos 2 y 4" },
            ],
          },
          { type: "h3", label: "6" },
          {
            type: "radio",
            var: "seis_CL",
            options: [
              {
                value: "0",
                text: " Cuando dice: Con muchísimo gusto, señor, con tal que me pagues como corresponde",
              },
              {
                value: "00",
                text: " Cuando se da cuenta que las bolsas tienen monedas y no cadáveres",
              },
              {
                value: "4",
                text: " Cuando dice: Permítame vivir en esta casa sin pagar y yo la iré reparando hasta recuperarla por completo",
              },
            ],
          },
          { type: "h3", label: "7" },
          {
            label: "Relación con el destinatario:",
            type: "select",
            var: "siete_1_PT",
            options: [
              {
                value: "1",
                text: "cuenta lo que ocurrió en 1° persona asumiendo el rol del personaje",
              },
              {
                value: "0.5",
                text: "sólo a veces lo hace en 1° persona asumiendo el rol del personaje",
              },
              {
                value: "0",
                text: "no se expresa en 1° persona, ni asume el rol del personaje",
              },
            ],
          },
          {
            label: "Intención comunicativa:",
            type: "select",
            var: "siete_2_PT",
            options: [
              { value: "1", text: "confiesa lo que ocurrió" },
              { value: "0", text: "no confiesa lo que ocurrió" },
            ],
          },
          {
            label: "Presentación:",
            type: "select",
            var: "siete_3_PT",
            options: [
              { value: "1", text: "el escrito tiene una presentación legible" },
              {
                value: "0.5",
                text: "el escrito tiene una presentación parcialmente legible",
              },
              {
                value: "0",
                text: "el escrito tiene una presentación ilegible",
              },
            ],
          },
          {
            label: "Precisión y variedad:",
            type: "select",
            var: "siete_4_PT",
            options: [
              {
                value: "5",
                text: "le cuenta los eventos importantes de la historia con claridad y detalles, le confiesa lo que planeó y su situación actual, denotando sus emociones",
              },
              {
                value: "4",
                text: "le cuenta algunos eventos de la historia con claridad, le confiesa lo que planeó, denotando sus emociones o le cuenta los eventos importantes con claridad y detalles, sin confesar lo que planeó",
              },
              {
                value: "3",
                text: "le cuenta la historia, lo que planeó y su situación actual de manera general o le cuenta la historia y su situación actual de manera general sin confesar lo que planeó",
              },
              {
                value: "2",
                text: "no logra contar la historia con claridad o faltan elementos importantes",
              },
              {
                value: "1",
                text: "relata sólo algunos elementos aislados de la historia",
              },
              { value: "0", text: "relato muy impreciso" },
            ],
          },
          {
            label: "Compromiso del tema:",
            type: "select",
            var: "siete_5_PT",
            options: [
              {
                value: "3",
                text: "Muestra comprensión profunda de la situación incluyendo imprensiones y reflexiones",
              },
              { value: "2", text: "Muestra comprensión satisfactoria" },
              { value: "1", text: "su comprensión del tema es superficial" },
              {
                value: "0",
                text: "no muestra comprensión o su comprensión es errónea",
              },
            ],
          },
          {
            label: "Capacidad expresiva personal:",
            type: "select",
            var: "siete_6_PT",
            options: [
              {
                value: "3",
                text: "revela empatía con el persona y vitalidad para describir las situaciones y emociones vividas, captando el interés del lector",
              },
              {
                value: "2",
                text: "revela empatía y vitalidad, captando medianamente el interés del lector",
              },
              {
                value: "1",
                text: "revela una cierta empatía con el personaje, sin captar el interés del lector",
              },
              {
                value: "0",
                text: "no revela empatía ni capta el interés del lector",
              },
            ],
          },
          {
            label: "Reflexión sobre el significado:",
            type: "select",
            var: "siete_7_PT",
            options: [
              {
                value: "2",
                text: "Muestra reflexión sobre la situación y se involucra activamente con el personaje",
              },
              {
                value: "1",
                text: "muestra escasa reflexión e involucramiento",
              },
              { value: "0", text: "no muestra reflexión ni involucramiento" },
            ],
          },
          {
            label: "Vocabulario preciso:",
            type: "select",
            var: "siete_8_PT",
            options: [
              {
                value: "3",
                text: "Usa palabras y expresiones coloridas y pertinentes a la situación",
              },
              {
                value: "2",
                text: "Usa palabras pertinentes pero no impactantes",
              },
              { value: "1", text: "Usa imprecisas o muy generales" },
              {
                value: "0",
                text: "sus palabras son incorrectas o inadecuadas",
              },
            ],
          },
          {
            label: "Vocabulario variado:",
            type: "select",
            var: "siete_9_PT",
            options: [
              {
                value: "3",
                text: "su vocabulario es diverso en palabras, expresiones, metáforas y analogías",
              },
              { value: "2", text: "Usa vocabulario limitado" },
              { value: "1", text: "Usa vocabulario muy restringido o erróneo" },
            ],
          },
          {
            label: "Ideas fluyen con naturalidad:",
            type: "select",
            var: "siete_10_PT",
            options: [
              {
                value: "3",
                text: "Permite lectura ágil y amena, porque las ideas se entrelazan con naturalidad",
              },
              {
                value: "2",
                text: "permite lectura amena, pero a veces requiere volver atrás en la lectura",
              },
              {
                value: "1",
                text: "ideas no fluyen con naturalidad, párrafos mal construidos",
              },
              { value: "0", text: "no permite seguir el hilo de ideas" },
            ],
          },
          {
            label: "Presencia de conectores:",
            type: "select",
            var: "siete_11_PT",
            options: [
              {
                value: "2",
                text: "Utiliza conectores pertinentes y diversos cada vez que se requiere",
              },
              {
                value: "1",
                text: "Usa conectores repetitivos o poco pertinentes",
              },
              { value: "0", text: "Faltan conectores o éstos son inadecuados" },
            ],
          },
          {
            label: "Estructura textual pertinente:",
            type: "select",
            var: "siete_12_PT",
            options: [
              {
                value: "3",
                text: "su relato contiene un inicio, un desarrollo y un cierre",
              },
              {
                value: "2",
                text: "falta un inicio y/o cierre, o los párrafos están mal construidos",
              },
              { value: "1", text: "su relato es desorganizado o reiterativo" },
              { value: "0", text: "su relato es incoherente" },
            ],
          },
          {
            label: "Sintaxis:",
            type: "select",
            var: "siete_13_PT",
            options: [
              {
                value: "2",
                text: "Oraciones con orden lógico y presencia de oraciones coordinadas o subordinadas",
              },
              {
                value: "1",
                text: "Oraciones con orden lógico, pero ausencia de oraciones coordinadas o subordinadas, o presencia de oraciones coordinadas o subordinadas con secuencia confusa",
              },
              { value: "0", text: "oraciones incoherentes" },
            ],
          },
          {
            label: "Morfosintaxis:",
            type: "select",
            var: "siete_14_PT",
            options: [
              {
                value: "2",
                text: "existe concordancia de género, número, tiempos verbales, sistema de verbos y concordancia semántica",
              },
              {
                value: "1",
                text: "hay 1 inconsistencia de género, número y tiempos verbales, sistema de verbos y concordancia semántica",
              },
              {
                value: "0",
                text: "hay más de 1 inconsistencia de género, número y tiempos verbales, sistema de verbos y concordancia semántica",
              },
            ],
          },
          {
            label: "Ortografía literal:",
            type: "select",
            var: "siete_15_PT",
            options: [
              {
                value: "1",
                text: "presenta uso adecuado de ortografía literal, incluyendo mayúsculas",
              },
              {
                value: "0.5",
                text: "hay 1 o 2 errores en el uso de ortografía literal y mayúsculas",
              },
              {
                value: "0",
                text: "hay más de 2 errores en el uso de ortografía literal y mayúsculas",
              },
            ],
          },
          {
            label: "Ortografía Acentual:",
            type: "select",
            var: "siete_16_PT",
            options: [
              { value: "1", text: "uso de tilde" },
              { value: "0.5", text: "hay 1 o 2 errores en el uso de tildes" },
              { value: "0", text: "hay más de 2 errores en el uso de tildes" },
            ],
          },
          {
            label: "Ortografía puntual:",
            type: "select",
            var: "siete_17_PT",
            options: [
              {
                value: "2",
                text: "uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de “pero”, “ya que” y “luego”, signos de interrogación y exclamación",
              },
              {
                value: "0.5",
                text: "hay 1 ó 2 errores en el uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de “pero”, “ya que” y “luego”, signos de interrogación y exclamación",
              },
              {
                value: "0",
                text: "hay más de 2 errores en el uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de “pero”, “ya que” y “luego”, signos de interrogación y exclamación",
              },
            ],
          },
          { type: "h3", label: "8" },
          {
            type: "select",
            var: "ocho_1_CL",
            options: [
              { value: "0", text: "Albañil" },
              { value: "1", text: "Viejo alto cadavérico" },
              { value: "00", text: "señor del pueblo" },
            ],
          },
          {
            type: "select",
            var: "ocho_2_CL",
            options: [
              { value: "0", text: "Albañil" },
              { value: "1", text: "Viejo alto cadavérico" },
              { value: "00", text: "señor del pueblo" },
            ],
          },
          {
            type: "select",
            var: "ocho_3_CL",
            options: [
              { value: "0", text: "Albañil" },
              { value: "00", text: "Viejo alto cadavérico" },
              { value: "1", text: "señor del pueblo" },
            ],
          },
          {
            type: "select",
            var: "ocho_4_CL",
            options: [
              { value: "1", text: "Albañil" },
              { value: "0", text: "Viejo alto cadavérico" },
              { value: "00", text: "señor del pueblo" },
            ],
          },
          { type: "h3", label: "9" },
          {
            label: "Agua:",
            type: "select",
            var: "nueve_1_CL",
            options: [
              { value: "0", text: "eólica" },
              { value: "00", text: "aerogeneradores" },
              { value: "000", text: "geotérmica" },
              { value: "1", text: "hidroeléctricas" },
            ],
          },
          {
            label: "Aire:",
            type: "select",
            var: "nueve_2_CL",
            options: [
              { value: "0", text: "eólica" },
              { value: "1", text: "aerogeneradores" },
              { value: "00", text: "geotérmica" },
              { value: "000", text: "hidroeléctricas" },
            ],
          },
          {
            label: "Viento:",
            type: "select",
            var: "nueve_3_CL",
            options: [
              { value: "1", text: "eólica" },
              { value: "0", text: "aerogeneradores" },
              { value: "00", text: "geotérmica" },
              { value: "000", text: "hidroeléctricas" },
            ],
          },
          {
            label: "Tierra:",
            type: "select",
            var: "nueve_4_CL",
            options: [
              { value: "0", text: "eólica" },
              { value: "00", text: "aerogeneradores" },
              { value: "1", text: "geotérmica" },
              { value: "000", text: "hidroeléctricas" },
            ],
          },
          {
            label: "Temperatura:",
            type: "select",
            var: "nueve_5_CL",
            options: [
              { value: "0", text: "eólica" },
              { value: "00", text: "aerogeneradores" },
              { value: "1", text: "geotérmica" },
              { value: "000", text: "hidroeléctricas" },
            ],
          },
          {
            label: "Proviene:",
            type: "select",
            var: "nueve_6_CL",
            options: [
              { value: "1", text: "eólica" },
              { value: "0", text: "aerogeneradores" },
              { value: "00", text: "geotérmica" },
              { value: "000", text: "hidroeléctricas" },
            ],
          },
          {
            label: "Generan, producen:",
            type: "select",
            var: "nueve_7_CL",
            options: [
              { value: "0", text: "eólica" },
              { value: "1", text: "aerogeneradores" },
              { value: "00", text: "geotérmica" },
              { value: "000", text: "hidroeléctricas" },
            ],
          },
          {
            label: "Electricidad:",
            type: "select",
            var: "nueve_8_CL",
            options: [
              { value: "0", text: "eólica" },
              { value: "00", text: "aerogeneradores" },
              { value: "00", text: "geotérmica" },
              { value: "1", text: "hidroeléctricas" },
            ],
          },
          { type: "h3", label: "10" },
          {
            type: "radio",
            var: "diez_CL",
            options: [
              {
                value: "0",
                text: " El Sistema interconectado del Norte Grande",
              },
              { value: "00", text: " El Sistema Interconectado Central" },
              { value: "2", text: " Aysén" },
              { value: "000", text: " Magallanes" },
            ],
          },
          { type: "h3", label: "11" },
          {
            label: "Ingrese puntaje: :",
            type: "select",
            var: "once_CL",
            options: [
              { value: "0", text: "0" },
              { value: "1", text: "1" },
              { value: "2", text: "2" },
              { value: "3", text: "3" },
              { value: "4", text: "4" },
              { value: "5", text: "5" },
              { value: "6", text: "6" },
            ],
          },
          { type: "h3", label: "12" },
          {
            label: "Energías no renovables:",
            type: "select",
            var: "doce_1_CL",
            options: [
              { value: "0.25", text: "gas natural o diesel" },
              { value: "00", text: "carbón" },
              { value: "000", text: "petróleo" },
              { value: "0000", text: "hidroembalse" },
              { value: "00000", text: "hidropasado" },
              { value: "000000", text: "biomasa" },
              { value: "0000000", text: "pequeña hidro" },
              { value: "00000000", text: "Eólica" },
            ],
          },
          {
            label: "%:",
            type: "select",
            var: "doce_2_CL",
            options: [
              { value: "0.25", text: "38.9" },
              { value: "0", text: "otro" },
            ],
          },
          {
            label: "Energías no renovables:",
            type: "select",
            var: "doce_3_CL",
            options: [
              { value: "0", text: "gas natural o diesel" },
              { value: "0.25", text: "carbón" },
              { value: "000", text: "petróleo" },
              { value: "0000", text: "hidroembalse" },
              { value: "00000", text: "hidropasado" },
              { value: "000000", text: "biomasa" },
              { value: "0000000", text: "pequeña hidro" },
              { value: "00000000", text: "Eólica" },
            ],
          },
          {
            label: "%:",
            type: "select",
            var: "doce_4_CL",
            options: [
              { value: "0.25", text: "17.4" },
              { value: "0", text: "otro" },
            ],
          },
          {
            label: "Energías no renovables:",
            type: "select",
            var: "doce_5_CL",
            options: [
              { value: "0", text: "gas natural o diesel" },
              { value: "00", text: "carbón" },
              { value: "0.25", text: "petróleo" },
              { value: "0000", text: "hidroembalse" },
              { value: "00000", text: "hidropasado" },
              { value: "000000", text: "biomasa" },
              { value: "0000000", text: "pequeña hidro" },
              { value: "00000000", text: "Eólica" },
            ],
          },
          {
            label: "%:",
            type: "select",
            var: "doce_6_CL",
            options: [
              { value: "0.25", text: "3.5" },
              { value: "0", text: "otro" },
            ],
          },
          {
            label: "Energías renovables convencionales:",
            type: "select",
            var: "doce_7_CL",
            options: [
              { value: "0", text: "gas natural o diesel" },
              { value: "00", text: "carbón" },
              { value: "000", text: "petróleo" },
              { value: "0.25", text: "hidroembalse" },
              { value: "00000", text: "hidropasado" },
              { value: "000000", text: "biomasa" },
              { value: "0000000", text: "pequeña hidro" },
              { value: "00000000", text: "Eólica" },
            ],
          },
          {
            label: "%:",
            type: "select",
            var: "doce_8_CL",
            options: [
              { value: "0.25", text: "9.9" },
              { value: "0", text: "otro" },
            ],
          },
          {
            label: "Energías renovables convencionales:",
            type: "select",
            var: "doce_9_CL",
            options: [
              { value: "0", text: "gas natural o diesel" },
              { value: "00", text: "carbón" },
              { value: "000", text: "petróleo" },
              { value: "00000", text: "hidroembalse" },
              { value: "0.25", text: "hidropasado" },
              { value: "000000", text: "biomasa" },
              { value: "0000000", text: "pequeña hidro" },
              { value: "00000000", text: "Eólica" },
            ],
          },
          {
            label: "%:",
            type: "select",
            var: "doce_10_CL",
            options: [
              { value: "0.25", text: "9.9" },
              { value: "0", text: "otro" },
            ],
          },
          {
            label: "Energías renovables no convencionales:",
            type: "select",
            var: "doce_11_CL",
            options: [
              { value: "0", text: "gas natural o diesel" },
              { value: "00", text: "carbón" },
              { value: "000", text: "petróleo" },
              { value: "00000", text: "hidroembalse" },
              { value: "000000", text: "hidropasado" },
              { value: "0.25", text: "biomasa" },
              { value: "0000000", text: "pequeña hidro" },
              { value: "00000000", text: "Eólica" },
            ],
          },
          {
            label: "%:",
            type: "select",
            var: "doce_12_CL",
            options: [
              { value: "0.25", text: "1.5" },
              { value: "0", text: "otro" },
            ],
          },
          {
            label: "Energías renovables no convencionales:",
            type: "select",
            var: "doce_13_CL",
            options: [
              { value: "0", text: "gas natural o diesel" },
              { value: "00", text: "carbón" },
              { value: "000", text: "petróleo" },
              { value: "00000", text: "hidroembalse" },
              { value: "000000", text: "hidropasado" },
              { value: "0000000", text: "biomasa" },
              { value: "0.25", text: "pequeña hidro" },
              { value: "00000000", text: "Eólica" },
            ],
          },
          {
            label: "%:",
            type: "select",
            var: "doce_14_CL",
            options: [
              { value: "0.25", text: "0.9" },
              { value: "0", text: "otro" },
            ],
          },
          {
            label: "Energías renovables no convencionales:",
            type: "select",
            var: "doce_15_CL",
            options: [
              { value: "0", text: "gas natural o diesel" },
              { value: "00", text: "carbón" },
              { value: "000", text: "petróleo" },
              { value: "00000", text: "hidroembalse" },
              { value: "000000", text: "hidropasado" },
              { value: "0000000", text: "biomasa" },
              { value: "00000000", text: "pequeña hidro" },
              { value: "0.25", text: "Eólica" },
            ],
          },
          {
            label: "%:",
            type: "select",
            var: "doce_16_CL",
            options: [
              { value: "0.25", text: "0.02" },
              { value: "0", text: "otro" },
            ],
          },
          { type: "h3", label: "13" },
          {
            type: "select",
            var: "trece_1_CL",
            options: [
              { value: "1", text: "Opinión" },
              { value: "0", text: "hecho" },
            ],
          },
          {
            type: "select",
            var: "trece_2_CL",
            options: [
              { value: "0", text: "Opinión" },
              { value: "1", text: "hecho" },
            ],
          },
          {
            type: "select",
            var: "trece_3_CL",
            options: [
              { value: "1", text: "Opinión" },
              { value: "1", text: "hecho" },
            ],
          },
          {
            type: "select",
            var: "trece_4_CL",
            options: [
              { value: "1", text: "Opinión" },
              { value: "0", text: "hecho" },
            ],
          },
          { type: "h3", label: "14" },
          {
            type: "radio",
            var: "catorce_CL",
            options: [
              {
                value: "0",
                text: " Comprender los párrafos 4 y 6 del texto “Energías renovables",
              },
              {
                value: "4",
                text: " Analizar el mapa, comprender su simbología y comprender el párrafo 5 del texto “Energías renovables”",
              },
              {
                value: "00",
                text: " Comprender la noticia del parque eólico en Canela y el párrafo 3 de “Energías renovables”",
              },
            ],
          },
          { type: "h3", label: "15" },
          {
            label: "Intención comunicativa:",
            type: "select",
            var: "quince_1_PT",
            options: [
              { value: "1", text: "Da su opinión fundamentada" },
              { value: "0", text: "No da su opinión fundamentada" },
            ],
          },
          {
            label: "Presentación:",
            type: "select",
            var: "quince_2_PT",
            options: [
              { value: "1", text: "Presentación legible" },
              { value: "0.5", text: "Presentación parcialmente legible" },
              { value: "0", text: "Presentación ilegible" },
            ],
          },
          {
            label: "Precisión y variedad:",
            type: "select",
            var: "quince_3_PT",
            options: [
              {
                value: "5",
                text: "Plantea su opinión de manera clara, incluyendo argumentos y detalles que los sustentan",
              },
              {
                value: "4",
                text: "Su opinión es clara, pero sus argumentos son insuficientes o su opinión es confusa, pero sus argumentos son claros",
              },
              {
                value: "3",
                text: "Su opinión no es suficientemente clara y sus argumentos limitados",
              },
              { value: "2", text: "Su opinión no tiene sustento" },
              {
                value: "1",
                text: "Su opinión y argumentos son confusos, ya que no procesa adecuadamente la información de los textos",
              },
              { value: "0", text: "Su opinión y argumentos son incoherentes" },
            ],
          },
          {
            label: "Comprensión del tema:",
            type: "select",
            var: "quince_4_PT",
            options: [
              {
                value: "2",
                text: "Su opinión revela comprensión profunda sobre el tema",
              },
              { value: "1", text: "Revela comprensión superficial" },
              { value: "0", text: "No muestra comprensión" },
            ],
          },
          {
            label: "Capacidad expresiva personal:",
            type: "select",
            var: "quince_5_PT",
            options: [
              {
                value: "1",
                text: "Su opinión y argumentos son convincentes captando el interés del lector",
              },
              {
                value: "0.5",
                text: "Su opinión y fundamentos son parcialmente convincentes, captando medianamente el interés del lector",
              },
              {
                value: "0",
                text: "Su planteamiento no es convincente, ni capta el interés del lector",
              },
            ],
          },
          {
            label: "Reflexión sobre el significado:",
            type: "select",
            var: "quince_6_PT",
            options: [
              {
                value: "1",
                text: "Muestra reflexión y compromiso afectivo con su planteamiento",
              },
              { value: "0.5", text: "Muestra escasa reflexión y compromiso" },
              { value: "0", text: "No muestra reflexión ni compromiso" },
            ],
          },
          {
            label: "Vocabulario preciso:",
            type: "select",
            var: "quince_7_PT",
            options: [
              {
                value: "1",
                text: "Usa palabras y expresiones precisas y pertinentes al tema y a la intención de argumentar",
              },
              {
                value: "0.5",
                text: "Usa palabras muy generales y poco convincentes",
              },
              { value: "0", text: "Usa palabras incorrectas o inadecuadas" },
            ],
          },
          {
            label: "Vocabulario variado:",
            type: "select",
            var: "quince_8_PT",
            options: [
              {
                value: "1",
                text: "Usa diversidad de palabras, datos y ejemplos",
              },
              { value: "0.5", text: "Usa un vocabulario elemental" },
              { value: "0", text: "Usa vocabulario muy restringido" },
            ],
          },
          {
            label: "Ideas fluyen con naturalidad:",
            type: "select",
            var: "quince_9_PT",
            options: [
              {
                value: "1",
                text: "Permite lectura ágil y amena, porque las ideas se entrelazan con naturalidad",
              },
              {
                value: "0.5",
                text: "Permite avanzar con menor rapidez y requiere relectura para seguir el hilo",
              },
              { value: "0", text: "No permite seguir el hilo de las ideas" },
            ],
          },
          {
            label: "Presencia de conectores:",
            type: "select",
            var: "quince_10_PT",
            options: [
              {
                value: "1",
                text: "Usa conectores pertinentes y diversos ( debido a las razones de, por tal motivo, etc",
              },
              {
                value: "0.5",
                text: "Utiliza conectores poco pertinentes y repetitivos",
              },
              { value: "0", text: "Faltan conectores o éstos son inadecuados" },
            ],
          },
          {
            label: "Estructura textual pertinente:",
            type: "select",
            var: "quince_11_PT",
            options: [
              {
                value: "2",
                text: "Plantea su opinión, argumenta de manera organizada y concluye de manera convincente",
              },
              {
                value: "1",
                text: "Su opinión, argumentos y conclusión están desorganizados o reiterativos",
              },
              { value: "0", text: "su opinión carece de estructura" },
            ],
          },
          {
            label: "Sintaxis:",
            type: "select",
            var: "quince_12_PT",
            options: [
              {
                value: "1",
                text: "Oraciones con orden lógico y presencia de oraciones coordinadas o subordinadas",
              },
              {
                value: "0.5",
                text: "Oraciones con orden lógico, pero ausencia de oraciones coordinadas o subordinadas, o presencia de oraciones coordinadas o subordinadas, pero secuencia confusa",
              },
              { value: "0", text: "Oraciones incoherentes" },
            ],
          },
          {
            label: "Morfosintaxis:",
            type: "select",
            var: "quince_13_PT",
            options: [
              {
                value: "1",
                text: "existe concordancia de género, número y tiempos verbales, del sistema de verbos y concordancia semántica",
              },
              {
                value: "0.5",
                text: "hay 1 inconsistencia de género, número y tiempos verbales, del sistema de verbos y concordancia semántica",
              },
              {
                value: "0",
                text: "hay más de 1 inconsistencia de género, número y tiempos verbales, del sistema de verbos y concordancia semántica",
              },
            ],
          },
          {
            label: "Ortografía literal:",
            type: "select",
            var: "quince_14_PT",
            options: [
              {
                value: "0.5",
                text: "presenta uso adecuado de ortografía literal, incluyendo mayúsculas",
              },
              {
                value: "0.25",
                text: "hay 1 o 2 errores en el uso de ortografía literal y mayúsculas",
              },
              {
                value: "0",
                text: "hay más de 2 errores en el uso de ortografía literal y mayúsculas",
              },
            ],
          },
          {
            label: "Ortografía acentual:",
            type: "select",
            var: "quince_15_PT",
            options: [
              { value: "0.5", text: "uso de tilde" },
              { value: "0.25", text: "hay 1 ó 2 errores en el uso de tildes" },
              { value: "0", text: "hay más de 2 errores en el uso de tildes" },
            ],
          },
          {
            label: "Ortografía puntual:",
            type: "select",
            var: "quince_16_PT",
            options: [
              {
                value: "1",
                text: "uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de “pero”, “ya que” y “luego”",
              },
              {
                value: "0.5",
                text: "hay 1 ó 2 errores en el uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de “pero”, “ya que” y “luego”",
              },
              {
                value: "0",
                text: "hay más de 2 errores en el uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de “pero”, “ya que” y “luego”",
              },
            ],
          },
          { type: "h3", label: "16" },
          {
            label: "¿Qué tipo de texto es?",
            type: "select",
            var: "dieciseis_1_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0", text: "2" },
              { value: "00", text: "3" },
              { value: "000", text: "4" },
              { value: "0000", text: "5" },
              { value: "00000", text: "6" },
              { value: "000000", text: "7" },
            ],
          },
          {
            label: "¿Para qué sirve principalmente?",
            type: "select",
            var: "dieciseis_2_CL",
            options: [
              { value: "0", text: "1" },
              { value: "0.5", text: "2" },
            ],
          },
          {
            label: "¿Puede encontrar?",
            type: "multiselect",
            var: "dieciseis_3_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0", text: "2" },
              { value: "0.50", text: "3" },
              { value: "00", text: "4" },
            ],
          },
          {
            label: "¿Qué tipo de texto es?",
            type: "select",
            var: "dieciseis_4_CL",
            options: [
              { value: "0", text: "1" },
              { value: "00", text: "2" },
              { value: "000", text: "3" },
              { value: "000", text: "4" },
              { value: "0000", text: "5" },
              { value: "0.5", text: "6" },
              { value: "000000", text: "7" },
            ],
          },
          {
            label: "¿Para qué sirve principalmente?",
            type: "select",
            var: "dieciseis_5_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0", text: "2" },
            ],
          },
          {
            label: "¿Puede encontrar?",
            type: "multiselect",
            var: "dieciseis_6_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0", text: "2" },
              { value: "00", text: "3" },
              { value: "0.50", text: "4" },
            ],
          },
          {
            label: "¿Qué tipo de texto es?",
            type: "select",
            var: "dieciseis_7_CL",
            options: [
              { value: "0", text: "1" },
              { value: "00", text: "2" },
              { value: "0.5", text: "3" },
              { value: "000", text: "4" },
              { value: "0000", text: "5" },
              { value: "00000", text: "6" },
              { value: "000000", text: "7" },
            ],
          },
          {
            label: "¿Para qué sirve principalmente?",
            type: "select",
            var: "dieciseis_8_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0", text: "2" },
            ],
          },
          {
            label: "¿Puede encontrar?",
            type: "multiselect",
            var: "dieciseis_9_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "00", text: "2" },
              { value: "0", text: "3" },
              { value: "0.50", text: "4" },
            ],
          },
          {
            label: "¿Qué tipo de texto es?",
            type: "select",
            var: "dieciseis_10_CL",
            options: [
              { value: "0", text: "1" },
              { value: "0.5", text: "2" },
              { value: "000", text: "3" },
              { value: "00", text: "4" },
              { value: "0000", text: "5" },
              { value: "00000", text: "6" },
              { value: "000000", text: "7" },
            ],
          },
          {
            label: "¿Para qué sirve principalmente?",
            type: "select",
            var: "dieciseis_11_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0", text: "2" },
            ],
          },
          {
            label: "¿Puede encontrar?",
            type: "multiselect",
            var: "dieciseis_12_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0.50", text: "2" },
              { value: "00", text: "3" },
              { value: "000", text: "4" },
            ],
          },
        ],
        [
          { type: "h3", label: "1" },
          {
            label: "Ingrese puntaje: :",
            type: "select",
            var: "uno_CL",
            options: [
              { value: "0", text: "0" },
              { value: "1", text: "1" },
              { value: "2", text: "2" },
              { value: "3", text: "3" },
              { value: "4", text: "4" },
              { value: "5", text: "5" },
              { value: "6", text: "6" },
              { value: "7", text: "7" },
              { value: "8", text: "8" },
              { value: "9", text: "9" },
              { value: "10", text: "10" },
              { value: "11", text: "11" },
              { value: "12", text: "12" },
            ],
          },
          { type: "h3", label: "2" },
          {
            type: "radio",
            var: "dos_CL",
            options: [
              {
                value: "1",
                text: " El triunfo de la amistad sobre la discordia",
              },
              {
                value: "0",
                text: " Los efectos de comer semillas de la discordia",
              },
              { value: "00", text: " Las consecuencias de la ociosidad" },
            ],
          },
          { type: "h3", label: "3" },
          {
            type: "radio",
            var: "tres_CL",
            options: [
              { value: "0", text: " Habían comido semillas de la Discordia" },
              { value: "1", text: " El campesino lo descuidó" },
              { value: "00", text: " Querían comerse las malas hierbas" },
            ],
          },
          { type: "h3", label: "4" },
          {
            type: "radio",
            var: "cuatro_CL",
            options: [
              {
                value: "1",
                text: " Ya habían suficientes guerras, divisiones y enemistades en el mundo",
              },
              {
                value: "0",
                text: " Ya había suficientes problemas ecológicos en los países",
              },
              {
                value: "00",
                text: " Ya había suficientes campos sembrados en el mundo",
              },
            ],
          },
          { type: "h3", label: "5" },
          {
            type: "radio",
            var: "cinco_CL",
            options: [
              { value: "00", text: " Una bandada de aves devastó su campo" },
              {
                value: "1",
                text: " El campesino volvió a su casa sin decir ni una sola palabra a su amigo",
              },
              {
                value: "0",
                text: " El campesino desechó la idea de llevar las semillas a otros países",
              },
            ],
          },
          { type: "h3", label: "6" },
          {
            label: "A):",
            type: "select",
            var: "seis_1_CL",
            options: [
              { value: "1", text: "la mujer" },
              { value: "0", text: "otro" },
            ],
          },
          {
            label: "B):",
            type: "select",
            var: "seis_2_CL",
            options: [
              {
                value: "1",
                text: "Ya los países estaban sembrados de suficientes semillas de la discordia y temía crear una tempestad sin igual",
              },
              { value: "0", text: "otro" },
            ],
          },
          {
            label: "C):",
            type: "select",
            var: "seis_3_CL",
            options: [
              { value: "1", text: "su amigo, vecino" },
              { value: "0", text: "otro" },
            ],
          },
          {
            label: "D):",
            type: "select",
            var: "seis_4_CL",
            options: [
              { value: "1", text: "el campesino" },
              { value: "0", text: "otro" },
            ],
          },
          { type: "h3", label: "7" },
          {
            label: "Situación inicial y conflicto:",
            type: "select",
            var: "siete_1_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0", text: "2" },
              { value: "00", text: "3" },
              { value: "000", text: "4" },
              { value: "0000", text: "5" },
              { value: "00000", text: "6" },
              { value: "0000000", text: "7" },
            ],
          },
          {
            label: "Intento de solución:",
            type: "select",
            var: "siete_2_CL",
            options: [
              { value: "0", text: "1" },
              { value: "0.5", text: "2" },
              { value: "00", text: "3" },
              { value: "000", text: "4" },
              { value: "0000", text: "5" },
              { value: "00000", text: "6" },
              { value: "0000000", text: "7" },
            ],
          },
          {
            label: "Intento de solución:",
            type: "select",
            var: "siete_3_CL",
            options: [
              { value: "0", text: "1" },
              { value: "00", text: "2" },
              { value: "0.5", text: "3" },
              { value: "000", text: "4" },
              { value: "0000", text: "5" },
              { value: "00000", text: "6" },
              { value: "0000000", text: "7" },
            ],
          },
          {
            label: "Intento de solución:",
            type: "select",
            var: "siete_4_CL",
            options: [
              { value: "0", text: "1" },
              { value: "000", text: "2" },
              { value: "00", text: "3" },
              { value: "0.5", text: "4" },
              { value: "0000", text: "5" },
              { value: "00000", text: "6" },
              { value: "0000000", text: "7" },
            ],
          },
          {
            label: "Intento de solución:",
            type: "select",
            var: "siete_5_CL",
            options: [
              { value: "0", text: "1" },
              { value: "0000", text: "2" },
              { value: "00", text: "3" },
              { value: "000", text: "4" },
              { value: "0.5", text: "5" },
              { value: "00000", text: "6" },
              { value: "0000000", text: "7" },
            ],
          },
          {
            label: "Momento culminante:",
            type: "select",
            var: "siete_6_CL",
            options: [
              { value: "0", text: "1" },
              { value: "00000", text: "2" },
              { value: "00", text: "3" },
              { value: "000", text: "4" },
              { value: "0000", text: "5" },
              { value: "1.5", text: "6" },
              { value: "0000000", text: "7" },
            ],
          },
          {
            label: "Desenlace:",
            type: "select",
            var: "siete_7_CL",
            options: [
              { value: "0", text: "1" },
              { value: "00000", text: "2" },
              { value: "00", text: "3" },
              { value: "000", text: "4" },
              { value: "0000", text: "5" },
              { value: "0.5", text: "6" },
              { value: "0000000", text: "7" },
            ],
          },
          { type: "h3", label: "8" },
          {
            label: "Intención comunicativa:",
            type: "select",
            var: "ocho_1_PT",
            options: [
              { value: "1", text: "emite juicio crítico" },
              { value: "0", text: "no logra emitir juicio crítico" },
            ],
          },
          {
            label: "Presentación:",
            type: "select",
            var: "ocho_2_PT",
            options: [
              { value: "1", text: "el escrito tiene una presentación legible" },
              {
                value: "0.5",
                text: "el escrito tiene una presentación parcialmente legible",
              },
              {
                value: "0",
                text: "el escrito tiene una presentación ilegible",
              },
            ],
          },
          {
            label: "Precisión y variedad:",
            type: "select",
            var: "ocho_3_PT",
            options: [
              {
                value: "4",
                text: "plantea su juicio de manera clara y convincente, analizando las acciones del campesino a partir de sus valores",
              },
              {
                value: "3",
                text: "su juicio es claro, pero sus argumentos son insuficientes o no considera aspectos claves del relato",
              },
              { value: "2", text: "formula un juicio claro sin sustentarlo" },
              {
                value: "1",
                text: "su juicio no es claro, ya que no procesa adecuadamente las acciones del campesino",
              },
              { value: "0", text: "juicio y argumentos incoherentes" },
            ],
          },
          {
            label: "Comprensión del tema:",
            type: "select",
            var: "ocho_4_PT",
            options: [
              {
                value: "3",
                text: "su planteamiento revela comprensión de las acciones del campesino en relación a sus valores",
              },
              { value: "2", text: "revela comprensión superficial" },
              { value: "1", text: "su comprensión es incipiente" },
              { value: "0", text: "no muestra comprensión" },
            ],
          },
          {
            label: "Capacidad expresiva personal:",
            type: "select",
            var: "ocho_5_PT",
            options: [
              {
                value: "1",
                text: "su juicio es convincente y capta el interés del lector",
              },
              {
                value: "0.5",
                text: "su planteamiento es parcialmente convincente, captando medianamente el interés del lector",
              },
              {
                value: "0",
                text: "su planteamiento no es convincente o es inconsistente",
              },
            ],
          },
          {
            label: "Reflexión sobre el significado:",
            type: "select",
            var: "ocho_6_PT",
            options: [
              { value: "1", text: "Muestra reflexión y compromiso valórico" },
              { value: "0.5", text: "muestra escasa reflexión y compromiso" },
              { value: "0", text: "no muestra reflexión ni ini compromiso" },
            ],
          },
          {
            label: "Vocabulario preciso:",
            type: "select",
            var: "ocho_7_PT",
            options: [
              {
                value: "1",
                text: "Usa palabras y expresiones precisas y pertinentes que clarifican la comprensión de su análisis",
              },
              {
                value: "0.5",
                text: "usa palabras precisas, pero no convincentes o usa palabras muy generales o usa palabras o expresiones propias del lenguaje oral",
              },
              { value: "0", text: "usa palabras incorrectas o inadecuadas" },
            ],
          },
          {
            label: "Vocabulario variado:",
            type: "select",
            var: "ocho_8_PT",
            options: [
              {
                value: "1",
                text: "su vocabulario es muy variado, ya que usa diversidad de palabras, expresiones, metáforas, analogías o ejemplos",
              },
              { value: "0.5", text: "su vocabulario es elemental" },
              { value: "0", text: "su vocabulario es incorrecto o inadecuado" },
            ],
          },
          {
            label: "Ideas fluyen con naturalidad:",
            type: "select",
            var: "ocho_9_PT",
            options: [
              {
                value: "1",
                text: "Permite lectura ágil y amena porque las ideas se entrelazan con naturalidad",
              },
              {
                value: "0.5",
                text: "se avanza con menor rapidez o requiere relectura para seguir el hilo conductor de las ideas",
              },
              { value: "0", text: "no permite seguir el hilo de ideas" },
            ],
          },
          {
            label: "Presencia de conectores:",
            type: "select",
            var: "ocho_10_PT",
            options: [
              {
                value: "1",
                text: "Utiliza conectores pertinentes y diversos cada vez que se requiere",
              },
              {
                value: "0.5",
                text: "Usa conectores repetitivos o poco pertinentes",
              },
              { value: "0", text: "Faltan conectores o éstos son inadecuados" },
            ],
          },
          {
            label: "Estrategias para la organización:",
            type: "select",
            var: "ocho_11_PT",
            options: [
              {
                value: "1",
                text: "utiliza un organizador gráfico o se observa que o utiliza implícitamente",
              },
              { value: "0.5", text: "usa parcialmente el organizador gráfico" },
              { value: "0", text: "no utiliza el organizador gráfico" },
            ],
          },
          {
            label: "Estructura textual pertinente:",
            type: "select",
            var: "ocho_12_PT",
            options: [
              {
                value: "1",
                text: "plantea su juicio, argumenta y concluye de manera organizada",
              },
              {
                value: "0.5",
                text: "su juicio, argumentos y conclusión son parcialmente organizados o desestructurados o reiterativos",
              },
              { value: "0", text: "su juicio carece de estructura" },
            ],
          },
          {
            label: "Sintaxis:",
            type: "select",
            var: "ocho_13_PT",
            options: [
              {
                value: "1",
                text: "Oraciones con orden lógico y presencia de oraciones coordinadas o subordinadas",
              },
              {
                value: "0.5",
                text: "Oraciones con orden lógico, pero ausencia de oraciones coordinadas o subordinadas, o presencia de oraciones coordinadas o subordinadas con secuencia confusa",
              },
              { value: "0", text: "oraciones incoherentes" },
            ],
          },
          {
            label: "Morfosintaxis:",
            type: "select",
            var: "ocho_14_PT",
            options: [
              {
                value: "1",
                text: "existe concordancia de género, número, tiempos verbales y sistema de verbos",
              },
              {
                value: "0.5",
                text: "hay 1 inconsistencia de género, número, tiempos verbales y sistema de verbos",
              },
              {
                value: "0",
                text: "hay más de 1 inconsistencia de género, número, tiempos verbales y sistema de verbos",
              },
            ],
          },
          {
            label: "Ortografía literal:",
            type: "select",
            var: "ocho_15_PT",
            options: [
              {
                value: "0.5",
                text: "presenta uso adecuado de ortografía literal, incluyendo mayúsculas",
              },
              {
                value: "0.25",
                text: "hay 1 o 2 errores en el uso de ortografía literal y mayúsculas",
              },
              {
                value: "0",
                text: "hay más de 2 errores en el uso de ortografía literal y mayúsculas",
              },
            ],
          },
          {
            label: "Ortografía acentual:",
            type: "select",
            var: "ocho_16_PT",
            options: [
              { value: "0.5", text: "uso de tilde" },
              { value: "0.25", text: "hay 1 ó 2 errores en el uso de tildes" },
              { value: "0", text: "hay más de 2 errores en el uso de tildes" },
            ],
          },
          {
            label: "Ortografía puntual:",
            type: "select",
            var: "ocho_17_PT",
            options: [
              {
                value: "1",
                text: "uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de “pero”, “ya que” y “luego",
              },
              {
                value: "0.5",
                text: "hay 1 ó 2 errores en el uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de “pero”, “ya que” y “luego”",
              },
              {
                value: "0",
                text: "hay más de 2 errores en el uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de “pero”, “ya que” y “luego”",
              },
            ],
          },
          { type: "h3", label: "9" },
          {
            type: "select",
            var: "nueve_CL",
            options: [
              { value: "2", text: "Entre 400 y 440" },
              { value: "0", text: "otro" },
            ],
          },
          { type: "h3", label: "10" },
          {
            type: "radio",
            var: "diez_CL",
            options: [
              {
                value: "0",
                text: " Preguntó casa por casa dónde se abastecían de agua",
              },
              {
                value: "3",
                text: " Desarrolló una metodología para descubrir el origen de la epidemia de cólera",
              },
              {
                value: "00",
                text: " Con sus evidencias logró que las autoridades removieran la palanca de la bomba de agua de Broad Street, que era la fuente que originaba la epidemia.",
              },
            ],
          },
          { type: "h3", label: "11" },
          {
            label: "Primera acción:",
            type: "select",
            var: "once_1_CL",
            options: [
              { value: "2", text: "Escribe correctamente la acción" },
              { value: "0", text: "no escribe correctamente la acción" },
            ],
          },
          {
            label: "Segunda acción:",
            type: "select",
            var: "once_2_CL",
            options: [
              { value: "2", text: "Escribe correctamente la acción" },
              { value: "0", text: "no escribe correctamente la acción" },
            ],
          },
          { type: "h3", label: "12" },
          {
            label: "Problema de Snow:",
            type: "select",
            var: "doce_1_CL",
            options: [
              { value: "1", text: "lo completa correctamente" },
              { value: "0", text: "no lo completa" },
            ],
          },
          {
            label: "Antecedentes relevantes:",
            type: "select",
            var: "doce_2_CL",
            options: [
              { value: "3", text: "Completa los 3 antecedentes" },
              { value: "2", text: "completa 2 antecedentes" },
              { value: "1", text: "completa 1 antecedente" },
              { value: "0", text: "no completa ningún antecedente" },
            ],
          },
          {
            label: "Resultado:",
            type: "select",
            var: "doce_3_CL",
            options: [
              { value: "2", text: "completa de forma correcta el resultado" },
              {
                value: "0",
                text: "no completa de forma correcta el resultado",
              },
            ],
          },
          { type: "h3", label: "13" },
          {
            type: "radio",
            var: "trece_CL",
            options: [
              { value: "4", text: " En el párrafo 3 " },
              { value: "0", text: " En el párrafo 4" },
              { value: "00", text: " En el párrafo 6" },
            ],
          },
          { type: "h3", label: "14" },
          {
            label: "Relación con el destinatario:",
            type: "select",
            var: "catorce_1_PT",
            options: [
              {
                value: "1",
                text: "se expresa en primera persona asumiendo el rol del personaje",
              },
              {
                value: "0.5",
                text: "sólo a veces se expresa en 1ª persona y asume el rol del personaje",
              },
              {
                value: "0",
                text: "no se expresa en 1ª persona ni asume el rol del personaje",
              },
            ],
          },
          {
            label: "Intención comunicativa o propósito:",
            type: "select",
            var: "catorce_2_PT",
            options: [
              { value: "1", text: "relata cómo convenció a las autoridades" },
              {
                value: "0",
                text: "no relata cómo convenció a las autoridades",
              },
            ],
          },
          {
            label: "Presentación:",
            type: "select",
            var: "catorce_3_PT",
            options: [
              { value: "1", text: "presentación legible" },
              { value: "0.5", text: "presentación parcialmente legible" },
              { value: "0", text: "presentación ilegible" },
            ],
          },
          {
            label: "Precisión y variedad:",
            type: "select",
            var: "catorce_4_PT",
            options: [
              {
                value: "5",
                text: "evoca la situación con claridad, incluyendo detalles, emociones y pensamientos del protagonista",
              },
              {
                value: "4",
                text: "evoca la situación con claridad, incluyendo algunos detalles y emociones",
              },
              {
                value: "3",
                text: "evoca la situación de manera general, pero no logra presentar detalles ni emociones con precisión",
              },
              { value: "2", text: "no logra evocar la situación con claridad" },
              {
                value: "1",
                text: "el escrito presenta algunos pensamientos y emociones, pero éstos no son consistentes",
              },
              { value: "0", text: "evocación muy imprecisa" },
            ],
          },
          {
            label: "Comprensión del tema:",
            type: "select",
            var: "catorce_5_PT",
            options: [
              {
                value: "3",
                text: "muestra comprensión profunda de la situación, incluyendo impresiones y reflexiones",
              },
              {
                value: "2",
                text: "muestra una comprensión satisfactoria de la situación",
              },
              { value: "1", text: "comprensión superficial" },
              {
                value: "0",
                text: "no muestra comprensión o su comprensión es errónea",
              },
            ],
          },
          {
            label: "Capacidad expresiva personal:",
            type: "select",
            var: "catorce_6_PT",
            options: [
              {
                value: "3",
                text: "revela empatía con el personaje y vitalidad para describir sus emociones y pensamientos, captando el interés del lector",
              },
              {
                value: "2",
                text: "revela empatía y vitalidad, captando medianamente el interés del lector",
              },
              {
                value: "1",
                text: "revela una cierta empatía, sin captar el interés del lector",
              },
              {
                value: "0",
                text: "no revela empatía ni capta el interés del lector",
              },
            ],
          },
          {
            label: "Reflexión sobre el significado:",
            type: "select",
            var: "catorce_7_PT",
            options: [
              {
                value: "2",
                text: "Muestra reflexión sobre la situación y se involucra activamente con el personaje",
              },
              { value: "1", text: "reflexión superficial y poco compromiso" },
              { value: "0", text: "no muestra reflexión ni compromiso" },
            ],
          },
          {
            label: "Vocabulario preciso:",
            type: "select",
            var: "catorce_8_PT",
            options: [
              {
                value: "3",
                text: "Usa palabras y expresiones precisas y pertinentes a la situación",
              },
              {
                value: "2",
                text: "Usa palabras pertinentes, pero no impactantes",
              },
              { value: "1", text: "su vocabulario es impreciso o general" },
              { value: "0", text: "usa vocabulario incorrecto o inadecuado" },
            ],
          },
          {
            label: "Vocabulario variado:",
            type: "select",
            var: "catorce_9_PT",
            options: [
              {
                value: "2",
                text: "Usa diversidad de palabras, datos y ejemplos",
              },
              { value: "1", text: "Usa un vocabulario elemental" },
              { value: "0", text: "Usa vocabulario muy restringido" },
            ],
          },
          {
            label: "Ideas fluyen con naturalidad:",
            type: "select",
            var: "catorce_10_PT",
            options: [
              {
                value: "3",
                text: "Permite lectura ágil y amena, porque las ideas se entrelazan con naturalidad",
              },
              {
                value: "2",
                text: "Permite avanzar con menor rapidez y requiere relectura para seguir el hilo",
              },
              {
                value: "1",
                text: "ideas no fluyen con naturalidad, párrafos mal construidos",
              },
              { value: "0", text: "No permite seguir el hilo de las ideas" },
            ],
          },
          {
            label: "Presencia de conectores:",
            type: "select",
            var: "catorce_11_PT",
            options: [
              { value: "2", text: "Usa conectores pertinentes y diversos" },
              {
                value: "1",
                text: "Utiliza conectores poco pertinentes y repetitivos",
              },
              { value: "0", text: "Faltan conectores o éstos son inadecuados" },
            ],
          },
          {
            label: "Estructura textual pertinente:",
            type: "select",
            var: "catorce_12_PT",
            options: [
              {
                value: "3",
                text: "su evocación contiene un inicio, un desarrollo y un cierre",
              },
              { value: "2", text: "al relato le falta un inicio y/o cierre" },
              { value: "1", text: "su relato es desorganizado o reiterativo" },
              { value: "0", text: "su relato es incoherente" },
            ],
          },
          {
            label: "Sintaxis:",
            type: "select",
            var: "catorce_13_PT",
            options: [
              {
                value: "2",
                text: "Oraciones con orden lógico y presencia de oraciones coordinadas o subordinadas",
              },
              {
                value: "1",
                text: "Oraciones con orden lógico, pero ausencia de oraciones coordinadas o subordinadas, o presencia de oraciones coordinadas o subordinadas, pero secuencia confusa",
              },
              { value: "0", text: "Oraciones sin orden lógico" },
            ],
          },
          {
            label: "Morfosintaxis:",
            type: "select",
            var: "catorce_14_PT",
            options: [
              {
                value: "2",
                text: "existe concordancia de género, número y tiempos verbales, del sistema de verbos y concordancia semántica",
              },
              {
                value: "1",
                text: "hay 1 inconsistencia de género, número y tiempos verbales, del sistema de verbos y concordancia semántica",
              },
              {
                value: "0",
                text: "hay más de 1 inconsistencia de género, número y tiempos verbales, del sistema de verbos y concordancia semántica",
              },
            ],
          },
          {
            label: "Ortografía literal:",
            type: "select",
            var: "catorce_15_PT",
            options: [
              {
                value: "1",
                text: "presenta uso adecuado de ortografía literal, incluyendo mayúsculas",
              },
              {
                value: "0.5",
                text: "hay 1 o 2 errores en el uso de ortografía literal y mayúsculas",
              },
              {
                value: "0",
                text: "hay más de 2 errores en el uso de ortografía literal y mayúsculas",
              },
            ],
          },
          {
            label: "Ortografía acentual:",
            type: "select",
            var: "catorce_16_PT",
            options: [
              { value: "1", text: "uso de tilde" },
              { value: "0.5", text: "hay 1 ó 2 errores en el uso de tildes" },
              { value: "0", text: "hay más de 2 errores en el uso de tildes" },
            ],
          },
          {
            label: "Ortografía puntual:",
            type: "select",
            var: "catorce_17_PT",
            options: [
              {
                value: "2",
                text: "uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de “pero”, “ya que” y “luego”, signos de interrogación y exclamación",
              },
              {
                value: "1",
                text: "hay 1 ó 2 errores en el uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de “pero”, “ya que” y “luego”, signos de interrogación y exclamación",
              },
              {
                value: "0",
                text: "hay más de 2 errores en el uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de “pero”, “ya que” y “luego”, signos de interrogación y exclamación",
              },
            ],
          },
          { type: "h3", label: "15" },
          {
            label: "A):",
            type: "select",
            var: "quince_1_CL",
            options: [
              { value: "0.5", text: "Hubiera" },
              { value: "0", text: "otro" },
            ],
          },
          {
            label: "A):",
            type: "select",
            var: "quince_2_CL",
            options: [
              { value: "0.5", text: "habido" },
              { value: "0", text: "otro" },
            ],
          },
          {
            label: "B):",
            type: "select",
            var: "quince_3_CL",
            options: [
              { value: "0.5", text: "imaginó" },
              { value: "0", text: "otro" },
            ],
          },
          {
            label: "B):",
            type: "select",
            var: "quince_4_CL",
            options: [
              { value: "0.5", text: "iba" },
              { value: "0", text: "otro" },
            ],
          },
          {
            label: "C):",
            type: "select",
            var: "quince_5_CL",
            options: [
              { value: "0.5", text: "vaciara" },
              { value: "0", text: "otro" },
            ],
          },
          {
            label: "D):",
            type: "select",
            var: "quince_6_CL",
            options: [
              { value: "0.5", text: "produjera" },
              { value: "0", text: "otro" },
            ],
          },
          { type: "h3", label: "16" },
          {
            label: "Qué tipo de texto es?",
            type: "select",
            var: "dieciseis_1_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0.0", text: "2" },
              { value: "00", text: "3" },
              { value: "000", text: "4" },
              { value: "0000", text: "5" },
              { value: "00000", text: "6" },
              { value: "000000", text: "7" },
            ],
          },
          {
            label: "Para qué sirve principalmente?",
            type: "select",
            var: "dieciseis_2_CL",
            options: [
              { value: "0", text: "1" },
              { value: "0.5", text: "2" },
            ],
          },
          {
            label: "uede encontrar?",
            type: "multiselect",
            var: "dieciseis_3_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0", text: "2" },
              { value: "0.50", text: "3" },
              { value: "00", text: "4" },
            ],
          },
          {
            label: "Qué tipo de texto es?",
            type: "select",
            var: "dieciseis_4_CL",
            options: [
              { value: "0", text: "1" },
              { value: "0.0", text: "2" },
              { value: "0.5", text: "3" },
              { value: "000", text: "4" },
              { value: "0000", text: "5" },
              { value: "00000", text: "6" },
              { value: "000000", text: "7" },
            ],
          },
          {
            label: "Para qué sirve principalmente?",
            type: "select",
            var: "dieciseis_5_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0", text: "2" },
            ],
          },
          {
            label: "uede encontrar?",
            type: "multiselect",
            var: "dieciseis_6_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0", text: "2" },
              { value: "00", text: "3" },
              { value: "0.50", text: "4" },
            ],
          },
          {
            label: "Qué tipo de texto es?",
            type: "select",
            var: "dieciseis_7_CL",
            options: [
              { value: "0", text: "1" },
              { value: "0.0", text: "2" },
              { value: "00", text: "3" },
              { value: "000", text: "4" },
              { value: "0.5", text: "5" },
              { value: "00000", text: "6" },
              { value: "000000", text: "7" },
            ],
          },
          {
            label: "Para qué sirve principalmente?",
            type: "select",
            var: "dieciseis_8_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0", text: "2" },
            ],
          },
          {
            label: "uede encontrar?",
            type: "multiselect",
            var: "dieciseis_9_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0", text: "2" },
              { value: "00", text: "3" },
              { value: "0.50", text: "4" },
            ],
          },
          {
            label: "Qué tipo de texto es?",
            type: "select",
            var: "dieciseis_10_CL",
            options: [
              { value: "0", text: "1" },
              { value: "0.0", text: "2" },
              { value: "00", text: "3" },
              { value: "0.5", text: "4" },
              { value: "0000", text: "5" },
              { value: "00000", text: "6" },
              { value: "000000", text: "7" },
            ],
          },
          {
            label: "Para qué sirve principalmente?",
            type: "select",
            var: "dieciseis_11_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0", text: "2" },
            ],
          },
          {
            label: "L:",
            type: "multiselect",
            var: "dieciseis_12_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0", text: "2" },
              { value: "00", text: "3" },
              { value: "0.50", text: "4" },
            ],
          },
        ],
        [
          { type: "h3", label: "1" },
          {
            label: "Ingrese puntaje: :",
            type: "select",
            var: "uno_CL",
            options: [
              { value: "0", text: "0" },
              { value: "1", text: "1" },
              { value: "2", text: "2" },
              { value: "3", text: "3" },
              { value: "4", text: "4" },
              { value: "5", text: "5" },
              { value: "6", text: "6" },
              { value: "7", text: "7" },
              { value: "8", text: "8" },
              { value: "9", text: "9" },
              { value: "10", text: "10" },
              { value: "11", text: "11" },
              { value: "12", text: "12" },
            ],
          },
          { type: "h3", label: "2" },
          {
            type: "radio",
            var: "dos_CL",
            options: [
              { value: "0", text: " Las formas de triunfar en la vida" },
              { value: "1", text: " Qué es más importante para ser feliz" },
              { value: "0", text: " El espíritu emprendedor de algunos" },
            ],
          },
          { type: "h3", label: "3" },
          {
            type: "radio",
            var: "tres_CL",
            options: [
              { value: "1", text: " Codicia y vanidad" },
              { value: "0", text: " Inteligencia y creatividad" },
              { value: "0", text: " Energía y entusiasmo" },
            ],
          },
          { type: "h3", label: "4" },
          {
            type: "radio",
            var: "cuatro_CL",
            options: [
              { value: "0", text: " Sentí un gran cosquilleo" },
              { value: "0", text: " Sentí mucho frío" },
              { value: "1", text: " Sentí mucho miedo" },
            ],
          },
          { type: "h3", label: "5" },
          {
            type: "radio",
            var: "cinco_CL",
            options: [
              {
                value: "0",
                text: " Aparece en cualquier momento y pasa desapercibido entre nosotros",
              },
              {
                value: "4",
                text: " Quiere evitar que otras personas pongan en peligro su felicidad",
              },
              {
                value: "0",
                text: " Piensa que las personas pueden dejarse impresionar por su aspecto elegante y seguro",
              },
            ],
          },
          { type: "h3", label: "6" },
          {
            type: "select",
            var: "seis_1_CL",
            options: [
              {
                value: "1",
                text: "Los lectores/nosotros/los que están leyendo",
              },
              { value: "0", text: "otro" },
            ],
          },
          {
            type: "select",
            var: "seis_2_CL",
            options: [
              {
                value: "1",
                text: "Tucanes enloquecidos y jaguares feroces, animales árboles, humedad",
              },
              { value: "0", text: "otro" },
            ],
          },
          {
            type: "select",
            var: "seis_3_CL",
            options: [
              {
                value: "1",
                text: "No contar cómo consiguió la fama y juntar a otras personas para ofrecerles lo mismo",
              },
              { value: "0.5", text: "no contar cómo consiguió la fama" },
              {
                value: "00.5",
                text: "juntar a otras personas para ofrecerles lo mismo",
              },
              { value: "0", text: "otro" },
            ],
          },
          {
            type: "select",
            var: "seis_4_CL",
            options: [
              { value: "1", text: "La felicidad / su felicidad" },
              { value: "0", text: "otro" },
            ],
          },
          { type: "h3", label: "7" },
          {
            type: "radio",
            var: "siete_CL",
            options: [
              { value: "1", text: " En las líneas 5 y 47" },
              { value: "0", text: " En las líneas 23 y 33" },
              { value: "0", text: " En las líneas 17 y 32" },
            ],
          },
          { type: "h3", label: "8" },
          {
            label: "Relación con el destinatario:",
            type: "select",
            var: "ocho_1_PT",
            options: [
              { value: "1", text: "utiliza un lenguaje formal" },
              { value: "0.5", text: "utiliza un lenguaje formal fluctuante" },
              { value: "0", text: "utiliza un lenguaje informal" },
            ],
          },
          {
            label: "Intención comunicativa:",
            type: "select",
            var: "ocho_2_PT",
            options: [
              {
                value: "1",
                text: "narra el cuento en primera persona asumiendo el rol del Hombre de negocios",
              },
              {
                value: "0",
                text: "no narra en primera persona, ni asume el rol del Hombre de negocios",
              },
            ],
          },
          {
            label: "Presentación:",
            type: "select",
            var: "ocho_3_PT",
            options: [
              { value: "1", text: "el escrito tiene una presentación legible" },
              {
                value: "0.5",
                text: "el escrito tiene una presentación parcialmente legible",
              },
              {
                value: "0",
                text: "el escrito tiene una presentación ilegible",
              },
            ],
          },
          {
            label: "Precisión y variedad:",
            type: "select",
            var: "ocho_4_PT",
            options: [
              {
                value: "5",
                text: "Narra la situación con claridad, incluyendo detalles, emociones y pensamientos del personaje",
              },
              {
                value: "4",
                text: "narra la situación con claridad, incluyendo algunos detalles, emociones y pensamientos",
              },
              {
                value: "3",
                text: "narra la situación de manera general, pero no logra presentar detalles con precisión",
              },
              {
                value: "2",
                text: "no logra narrar la situación y sólo incluye algunos pensamientos y/o emociones",
              },
              {
                value: "1",
                text: "el escrito presenta algunas ideas, pero éstas son confusas",
              },
              { value: "0", text: "evocación imprecisa o errónea" },
            ],
          },
          {
            label: "Comprensión del tema:",
            type: "select",
            var: "ocho_5_PT",
            options: [
              {
                value: "3",
                text: "muestra comprensión profunda de la situación infiriendo impresiones y reflexiones",
              },
              {
                value: "2",
                text: "muestra una comprensión satisfactoria de la situación",
              },
              {
                value: "1",
                text: "muestra una comprensión superficial de la situación",
              },
              {
                value: "0",
                text: "no muestra comprensión, o su comprensión es errónea",
              },
            ],
          },
          {
            label: "Capacidad expresiva personal:",
            type: "select",
            var: "ocho_6_PT",
            options: [
              {
                value: "3",
                text: "revela empatía con el personaje y vitalidad para describir sus emociones y pensamientos, captando el interés del lector",
              },
              {
                value: "2",
                text: "revela empatía y vitalidad, captando medianamente el interés del lector",
              },
              {
                value: "1",
                text: "revela una cierta empatía, pero no capta el interés del lector",
              },
              {
                value: "0",
                text: "no revela empatía ni capta el interés del lector",
              },
            ],
          },
          {
            label: "Reflexión sobre el significado:",
            type: "select",
            var: "ocho_7_PT",
            options: [
              {
                value: "2",
                text: "Muestra reflexión sobre la situación y se involucra activamente con el personaje",
              },
              {
                value: "1",
                text: "muestra reflexión superficial y poco compromiso",
              },
              { value: "0", text: "no muestra reflexión ni compromiso" },
            ],
          },
          {
            label: "Vocabulario preciso:",
            type: "select",
            var: "ocho_8_PT",
            options: [
              {
                value: "3",
                text: "Usa palabras y expresiones precisas y pertinentes a la expresión (personal) de un Hombre de Negocios",
              },
              {
                value: "2",
                text: "usa palabras precisas, pero no impactantes",
              },
              { value: "1", text: "usa palabras imprecisas o generales" },
              { value: "0", text: "usa palabras incorrectas o inadecuadas" },
            ],
          },
          {
            label: "Vocabulario variado:",
            type: "select",
            var: "ocho_9_PT",
            options: [
              {
                value: "2",
                text: "su vocabulario es muy variado, ya que usa diversidad de palabras, expresiones y/o metáforas",
              },
              { value: "1", text: "su vocabulario es limitado" },
              { value: "0", text: "su vocabulario muy restringido" },
            ],
          },
          {
            label: "Ideas fluyen con naturalidad:",
            type: "select",
            var: "ocho_10_PT",
            options: [
              {
                value: "3",
                text: "Permite lectura ágil y amena porque las ideas se entrelazan con naturalidad",
              },
              {
                value: "2",
                text: "se avanza con menor rapidez o requiere relectura para seguir el hilo conductor de las ideas",
              },
              {
                value: "1",
                text: "ideas no fluyen con naturalidad, posee párrafos mal construidos",
              },
              { value: "0", text: "no permite seguir el hilo de las ideas" },
            ],
          },
          {
            label: "Presencia de conectores:",
            type: "select",
            var: "ocho_11_PT",
            options: [
              {
                value: "2",
                text: "Utiliza conectores pertinentes y diversos cada vez que se requiere",
              },
              {
                value: "1",
                text: "Usa conectores repetitivos o poco pertinentes",
              },
              { value: "0", text: "Faltan conectores o éstos son inadecuados" },
            ],
          },
          {
            label: "Estructura textual pertinente:",
            type: "select",
            var: "ocho_12_PT",
            options: [
              {
                value: "3",
                text: "el cuento contiene un inicio, un desarrollo y un final",
              },
              { value: "2", text: "la estructura del relato es incompleta" },
              { value: "1", text: "su relato es desorganizado o reiterativo" },
              {
                value: "0",
                text: "su relato es incoherente o desestructurado",
              },
            ],
          },
          {
            label: "Sintaxis:",
            type: "select",
            var: "ocho_13_PT",
            options: [
              {
                value: "2",
                text: "Oraciones con orden lógico y presencia de oraciones coordinadas o subordinadas",
              },
              {
                value: "1",
                text: "Oraciones con orden lógico, pero ausencia de oraciones coordinadas o subordinadas, o presencia de oraciones coordinadas o subordinadas con secuencia confusa",
              },
              { value: "0", text: "oraciones incoherentes" },
            ],
          },
          {
            label: "Morfosintaxis:",
            type: "select",
            var: "ocho_14_PT",
            options: [
              {
                value: "2",
                text: "existe concordancia de género, número, tiempos verbales, sistema de verbos y concordancia semántica",
              },
              {
                value: "1",
                text: "hay 1 inconsistencia de género, número, tiempos verbales, sistema de verbos y concordancia semántica",
              },
              {
                value: "0",
                text: "hay más de 1 inconsistencia de género, número, tiempos verbales, sistema de verbos y concordancia semántica",
              },
            ],
          },
          {
            label: "Ortografía literal:",
            type: "select",
            var: "ocho_15_PT",
            options: [
              {
                value: "1",
                text: "presenta uso adecuado de ortografía literal, incluyendo mayúsculas",
              },
              {
                value: "0.5",
                text: "hay 1 o 2 errores en el uso de ortografía literal y mayúsculas",
              },
              {
                value: "0",
                text: "hay más de 2 errores en el uso de ortografía literal y mayúsculas",
              },
            ],
          },
          {
            label: "Ortografía Acentual:",
            type: "select",
            var: "ocho_16_PT",
            options: [
              { value: "1", text: "uso adecuado de tilde" },
              { value: "0.5", text: "hay 1 ó 2 errores en el uso de tildes" },
              { value: "0", text: "hay más de 2 errores en el uso de tildes" },
            ],
          },
          {
            label: "Ortogafía puntual:",
            type: "select",
            var: "ocho_17_PT",
            options: [
              {
                value: "2",
                text: 'uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de "pero", "ya que" y "luego"',
              },
              {
                value: "1",
                text: 'hay 1 ó 2 errores en el uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de "pero", "ya que" y "luego", signos de interrogación y exclamación',
              },
              {
                value: "0",
                text: 'hay más de 2 errores en el uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de "pero", "ya que" y "luego", signos de interrogación y exclamación',
              },
            ],
          },
          { type: "h3", label: "9" },
          {
            label: "Intencidad Mercalli:",
            type: "select",
            var: "nueve_1_CL",
            options: [
              { value: "0.5", text: "Intensidad X" },
              { value: "0", text: "otro" },
            ],
          },
          {
            label: "Efectos visibles:",
            type: "select",
            var: "nueve_2_CL",
            options: [
              {
                value: "1",
                text: "Muchas construcciones destruidas. Suelo muy agrietado",
              },
              { value: "0", text: "otro" },
            ],
          },
          {
            label: "Intensidad Mercalli:",
            type: "select",
            var: "nueve_3_CL",
            options: [
              { value: "0.5", text: "Intensidad XII" },
              { value: "0", text: "otro" },
            ],
          },
          {
            label: "Efectos Visibles:",
            type: "select",
            var: "nueve_4_CL",
            options: [
              {
                value: "1",
                text: "Área de miles de KM. Devastador, destrucción total. Ondulaciones en la superficie del suelo. Objetos se mueven y voltean",
              },
              { value: "0", text: "otro" },
            ],
          },
          { type: "h3", label: "10" },
          {
            label: "studio:",
            type: "multiselect",
            var: "diez_1_CL",
            options: [
              { value: "0.5", text: "1" },
              { value: "0", text: "2" },
              { value: "0.50", text: "3" },
              { value: "00", text: "4" },
              { value: "000", text: "5" },
              { value: "0000", text: "6" },
              { value: "0.500", text: "7" },
              { value: "00000", text: "8" },
              { value: "000000", text: "9" },
              { value: "0000000", text: "10" },
              { value: "00000000", text: "11" },
              { value: "000000000", text: "12" },
            ],
          },
          {
            label: ":",
            type: "multiselect",
            var: "diez_2_CL",
            options: [
              { value: "0", text: "1" },
              { value: "00", text: "2" },
              { value: "000", text: "3" },
              { value: "0000", text: "4" },
              { value: "00000", text: "5" },
              { value: "0.5", text: "6" },
              { value: "000000", text: "7" },
              { value: "0.50", text: "8" },
              { value: "0.500", text: "9" },
              { value: "0000000", text: "10" },
              { value: "00000000", text: "11" },
              { value: "0.5000", text: "12" },
            ],
          },
          {
            label: ":",
            type: "multiselect",
            var: "diez_3_CL",
            options: [
              { value: "0", text: "1" },
              { value: "0.5", text: "2" },
              { value: "00", text: "3" },
              { value: "0.50", text: "4" },
              { value: "0.500", text: "5" },
              { value: "000", text: "6" },
              { value: "0000", text: "7" },
              { value: "00000", text: "8" },
              { value: "000000", text: "9" },
              { value: "0.5000", text: "10" },
              { value: "0.50000", text: "11" },
              { value: "0000000", text: "12" },
            ],
          },
          { type: "h3", label: "11" },
          {
            type: "radio",
            var: "once_CL",
            options: [
              { value: "3", text: " XVII" },
              { value: "0", text: " XVIII" },
              { value: "0", text: " XIX" },
            ],
          },
          { type: "h3", label: "12" },
          {
            type: "select",
            var: "doce_1_CL",
            options: [
              { value: "0.5", text: "Hecho" },
              { value: "0", text: "Opinión" },
            ],
          },
          {
            type: "select",
            var: "doce_2_CL",
            options: [
              {
                value: "1",
                text: " Está basado en estudios del instituto sismológico de la U. de chile. / Porque hay pruebas concretas que muestran que así fue. / Porque los ríos cambiaron su curso, nacieron lagos, las montañas se movieron. / u otros fundamentos correctos ",
              },
              { value: "0", text: "otro" },
            ],
          },
          {
            type: "select",
            var: "doce_3_CL",
            options: [
              { value: "0", text: "Hecho" },
              { value: "0.5", text: "Opinión" },
            ],
          },
          {
            type: "select",
            var: "doce_4_CL",
            options: [
              { value: "0", text: "Hecho" },
              { value: "0.5", text: "Opinión" },
            ],
          },
          {
            type: "select",
            var: "doce_5_CL",
            options: [
              { value: "0.5", text: "Hecho" },
              { value: "0", text: "Opinión" },
            ],
          },
          {
            type: "select",
            var: "doce_6_CL",
            options: [
              {
                value: "1",
                text: " Fundamentos Basado en cuadro del Instituto sismológico de la U de chile ",
              },
              { value: "0", text: "otro" },
            ],
          },
          { type: "h3", label: "13" },
          {
            type: "radio",
            var: "trece_CL",
            options: [
              { value: "0", text: " En el párrafo 1, 2 y 3 " },
              { value: "1", text: " En el párrafo 3 y 4" },
              { value: "00", text: " En el párrafo 2" },
            ],
          },
          { type: "h3", label: "14" },
          {
            label: "Intención comunicativa:",
            type: "select",
            var: "catorce_1_PT",
            options: [
              {
                value: "1",
                text: "Da su opinión fundamentada sobre los hábitos mentales de M. Cisternas",
              },
              { value: "0", text: "No da su opinión fundamentada" },
            ],
          },
          {
            label: "Presentación:",
            type: "select",
            var: "catorce_2_PT",
            options: [
              { value: "1", text: "presentación legible" },
              { value: "0.5", text: "presentación parcialmente legible" },
              { value: "0", text: "presentación ilegible" },
            ],
          },
          {
            label: "Precisión y variedad:",
            type: "select",
            var: "catorce_3_PT",
            options: [
              {
                value: "5",
                text: "su opinión es clara, selecciona los hábitos mentales del científico y los fundamentos que los sustentan",
              },
              {
                value: "4",
                text: "opina de manera clara, pero sus fundamentos son débiles o fundamenta sobre 2 hábitos",
              },
              {
                value: "3",
                text: "da su opinión de manera clara, pero sin desarrollar fundamentos o fundamenta sobre 1 sólo hábito",
              },
              { value: "2", text: "su opinión y fundamentos son poco claros" },
              {
                value: "1",
                text: "su opinión es poco clara y no tiene fundamentos",
              },
              { value: "0", text: "su opinión y fundamentos son incoherentes" },
            ],
          },
          {
            label: "Comprensión del tema:",
            type: "select",
            var: "catorce_4_PT",
            options: [
              {
                value: "2",
                text: "su opinión revela comprensión profunda sobre el tema",
              },
              { value: "1", text: "revela comprensión superficial" },
              { value: "0", text: "no muestra comprensión" },
            ],
          },
          {
            label: "Capacidad expresiva personal:",
            type: "select",
            var: "catorce_5_PT",
            options: [
              {
                value: "1",
                text: "su opinión y fundamentos son convincentes, captando el interés del lector",
              },
              {
                value: "0.5",
                text: "su opinión y fundamentos son parcialmente convincentes, captando medianamente el interés del lector",
              },
              {
                value: "0",
                text: "su planteamiento no es convincente, ni capta el interés del lector",
              },
            ],
          },
          {
            label: "Reflexión sobre el significado:",
            type: "select",
            var: "catorce_6_PT",
            options: [
              {
                value: "1",
                text: "Muestra reflexión y compromiso afectivo con el tema",
              },
              { value: "0.5", text: "muestra escasa reflexión y compromiso" },
              { value: "0", text: "no muestra reflexión ni compromiso" },
            ],
          },
          {
            label: "Vocabulario preciso:",
            type: "select",
            var: "catorce_7_PT",
            options: [
              {
                value: "1",
                text: "Usa palabras y expresiones precisas y pertinentes al tema",
              },
              {
                value: "0.5",
                text: "Usa palabras muy generales o usa palabras o expresiones propias del lenguaje oral",
              },
              { value: "0", text: "Usa palabras incorrectas o inadecuadas" },
            ],
          },
          {
            label: "Vocabulario variado:",
            type: "select",
            var: "catorce_8_PT",
            options: [
              {
                value: "1",
                text: "su vocabulario es diverso, usa varias palabras, expresiones y ejemplos",
              },
              { value: "0.5", text: "usa un vocabulario limitado" },
              {
                value: "0",
                text: "usa un vocabulario muy restringido o incorrecto",
              },
            ],
          },
          {
            label: "Ideas fluyen con naturalidad:",
            type: "select",
            var: "catorce_9_PT",
            options: [
              {
                value: "1",
                text: "Permite lectura ágil y amena, porque las ideas se entrelazan con naturalidad",
              },
              {
                value: "0.5",
                text: "Permite avanzar con menor rapidez y requiere relectura para seguir el hilo",
              },
              { value: "0", text: "No permite seguir el hilo de las ideas" },
            ],
          },
          {
            label: "Presencia de conectores:",
            type: "select",
            var: "catorce_10_PT",
            options: [
              { value: "1", text: "Usa conectores pertinentes y diversos" },
              {
                value: "0.5",
                text: "Utiliza conectores poco pertinentes y repetitivos",
              },
              { value: "0", text: "Faltan conectores o éstos son inadecuados" },
            ],
          },
          {
            label: "Estructura y organización:",
            type: "select",
            var: "catorce_11_PT",
            options: [
              {
                value: "1",
                text: "utiliza adecuadamente el organizador gráfico o se infiere que lo utiliza",
              },
              {
                value: "0.5",
                text: "utiliza parcialmente o erróneamente el organizador gráfico",
              },
              { value: "0", text: "no utiliza el organizador gráfico" },
            ],
          },
          {
            label: "Estructura textual pertiennte:",
            type: "select",
            var: "catorce_12_PT",
            options: [
              {
                value: "1",
                text: "da su opinión, argumenta y concluye en párrafos organizados y bien secuenciados",
              },
              {
                value: "0.5",
                text: "su opinión, argumentos y conclusión no están bien organizados, o son reiterativos o no incluye conclusión",
              },
              { value: "0", text: "su opinión no tiene estructura" },
            ],
          },
          {
            label: "Sintaxis:",
            type: "select",
            var: "catorce_13_PT",
            options: [
              {
                value: "1",
                text: "Oraciones con orden lógico y presencia de oraciones coordinadas o subordinadas",
              },
              {
                value: "0.5",
                text: "Oraciones con orden lógico, pero ausencia de oraciones coordinadas o subordinadas, o presencia de oraciones coordinadas o subordinadas, pero secuencia confusa",
              },
              { value: "0", text: "Oraciones sin orden lógico" },
            ],
          },
          {
            label: "Morfosintaxis:",
            type: "select",
            var: "catorce_14_PT",
            options: [
              {
                value: "1",
                text: "existe concordancia de género, número y tiempos verbales, del sistema de verbos",
              },
              {
                value: "0.5",
                text: "hay 1 inconsistencia de género, número y tiempos verbales, del sistema de verbos",
              },
              {
                value: "0",
                text: "hay más de 1 inconsistencia de género, número y tiempos verbales, del sistema de verbos",
              },
            ],
          },
          {
            label: "Ortografía literal:",
            type: "select",
            var: "catorce_15_PT",
            options: [
              {
                value: "0.5",
                text: "presenta uso adecuado de ortografía literal, incluyendo mayúsculas",
              },
              {
                value: "0.25",
                text: "hay 1 o 2 errores en el uso de ortografía literal y mayúsculas",
              },
              {
                value: "0",
                text: "hay más de 2 errores en el uso de ortografía literal y mayúsculas",
              },
            ],
          },
          {
            label: "Ortografía acentual:",
            type: "select",
            var: "catorce_16_PT",
            options: [
              { value: "0.5", text: "hay un adecuado uso de tilde" },
              { value: "0.25", text: "hay 1 ó 2 errores en el uso de tildes" },
              { value: "0", text: "hay más de 2 errores en el uso de tildes" },
            ],
          },
          {
            label: "Ortografía puntual:",
            type: "select",
            var: "catorce_17_PT",
            options: [
              {
                value: "1",
                text: 'uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de "pero", "ya que" y "luego"',
              },
              {
                value: "0.5",
                text: 'hay 1 ó 2 errores en el uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de "pero", "ya que" y "luego"',
              },
              {
                value: "0",
                text: 'hay más de 2 errores en el uso adecuado del punto aparte, punto final, dos puntos, comas en enumeraciones y antes de "pero", "ya que" y "luego"',
              },
            ],
          },
          { type: "h3", label: "15" },
          {
            type: "select",
            var: "quince_1_CL",
            options: [
              { value: "0.5", text: "hubiera" },
              { value: "0", text: "otro" },
            ],
          },
          {
            type: "select",
            var: "quince_2_CL",
            options: [
              { value: "0.5", text: "hecho" },
              { value: "0", text: "otro" },
            ],
          },
          {
            type: "select",
            var: "quince_3_CL",
            options: [
              { value: "0.5", text: "sabían" },
              { value: "0", text: "otro" },
            ],
          },
          {
            type: "select",
            var: "quince_4_CL",
            options: [
              { value: "0.5", text: "era o es" },
              { value: "0", text: "otro" },
            ],
          },
          {
            type: "select",
            var: "quince_5_CL",
            options: [
              { value: "0.5", text: "permitió" },
              { value: "0", text: "otro" },
            ],
          },
          {
            type: "select",
            var: "quince_6_CL",
            options: [
              { value: "0.5", text: "es" },
              { value: "0", text: "otro" },
            ],
          },
          { type: "h3", label: " 16" },
          {
            label: "e texto es?",
            type: "multiselect",
            var: "dieciseis_1_CL",
            options: [
              { value: "0.3334", text: "1" },
              { value: "0", text: "2" },
              { value: "00", text: "3" },
              { value: "000", text: "4" },
              { value: "0000", text: "5" },
              { value: "00000", text: "6" },
              { value: "000000", text: "7" },
            ],
          },
          {
            label: "¿Para que sirve principalmente?",
            type: "select",
            var: "dieciseis_2_CL",
            options: [
              { value: "0", text: "1" },
              { value: "0.3334", text: "2" },
            ],
          },
          {
            label: "uede encontrar?",
            type: "multiselect",
            var: "dieciseis_3_CL",
            options: [
              { value: "0.3334", text: "1" },
              { value: "0", text: "2" },
              { value: "0.33340", text: "3" },
              { value: "00", text: "4" },
            ],
          },
          {
            label: "e texto es?",
            type: "multiselect",
            var: "dieciseis_4_CL",
            options: [
              { value: "0", text: "1" },
              { value: "00", text: "2" },
              { value: "0.3334", text: "3" },
              { value: "000", text: "4" },
              { value: "0000", text: "5" },
              { value: "00000", text: "6" },
              { value: "0.33340", text: "7" },
            ],
          },
          {
            label: "¿Para que sirve principalmente?",
            type: "select",
            var: "dieciseis_5_CL",
            options: [
              { value: "0.3334", text: "1" },
              { value: "0", text: "2" },
            ],
          },
          {
            label: "uede encontrar?",
            type: "multiselect",
            var: "dieciseis_6_CL",
            options: [
              { value: "0.3334", text: "1" },
              { value: "0", text: "2" },
              { value: "00", text: "3" },
              { value: "0.33340", text: "4" },
            ],
          },
          {
            label: "e texto es?",
            type: "multiselect",
            var: "dieciseis_7_CL",
            options: [
              { value: "0", text: "1" },
              { value: "0.3334", text: "2" },
              { value: "00", text: "3" },
              { value: "000", text: "4" },
              { value: "0000", text: "5" },
              { value: "00000", text: "6" },
              { value: "000000", text: "7" },
            ],
          },
          {
            label: "¿Para que sirve principalmente?",
            type: "select",
            var: "dieciseis_8_CL",
            options: [
              { value: "0.3334", text: "1" },
              { value: "0", text: "2" },
            ],
          },
          {
            label: "uede encontrar?",
            type: "multiselect",
            var: "dieciseis_9_CL",
            options: [
              { value: "0.3334", text: "1" },
              { value: "0.33340", text: "2" },
              { value: "0", text: "3" },
              { value: "0.333400", text: "4" },
            ],
          },
          {
            label: "e texto es?",
            type: "multiselect",
            var: "dieciseis_10_CL",
            options: [
              { value: "0", text: "1" },
              { value: "00", text: "2" },
              { value: "000", text: "3" },
              { value: "0000", text: "4" },
              { value: "00000", text: "5" },
              { value: "000000", text: "6" },
              { value: "0.3334", text: "7" },
            ],
          },
          {
            label: "¿Para que sirve principalmente?",
            type: "select",
            var: "dieciseis_11_CL",
            options: [
              { value: "0.3334", text: "1" },
              { value: "0", text: "2" },
            ],
          },
          {
            label: "uede encontrar?",
            type: "multiselect",
            var: "dieciseis_12_CL",
            options: [
              { value: "0.3334", text: "1" },
              { value: "0", text: "2" },
              { value: "00", text: "3" },
              { value: "0.33340", text: "4" },
            ],
          },
        ],
      ],
    };
  },
};
</script>
<style lang=""></style>
