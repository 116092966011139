<template>
  <b-modal centered ref="incomponent-modal-image-load" size="lg" hide-footer hide-header>
    <b-container>
        <b-row v-if="showImage">
            <b-img src="https://picsum.photos/1024/400/?image=41" fluid alt="Responsive image"/>
        </b-row>
        <b-row v-if="showLoading">
            <b-col class="text-center">
                <span>Estamos creando tu colegio</span>
            </b-col>
        </b-row>
        <b-row v-if="showLoading">
            <b-col class="text-center">
                <b-spinner variant="primary" label="Spinning"/>
            </b-col>
        </b-row>
        <b-row v-if="showVideo">
            <b-col class="text-center">
                <b-embed
                  type="iframe"
                  aspect="16by9"
                  src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                  allowfullscreen
                ></b-embed>
            </b-col>
        <!-- </b-row>
          <b-col class="text-center">
            <b-button variant="primary" :disabled="$store.state.login.school===''">Ir al colegio</b-button>  
          </b-col>
        <b-row> -->
        </b-row>
    </b-container>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      showImage: false,
      showLoading: false,
      showVideo: false,
    };
  },
  methods: {
    open(withLoading, withImage, withVideo=false) {
      this.showImage = withImage;
      this.showLoading = withLoading;
      this.showVideo = withVideo;
      this.$refs["incomponent-modal-image-load"].show();
    }
  }
};
</script>

<style lang="scss">

</style>
