<template>
  <b-overlay :show="loading">
    <div>
      <RubricReviewModal ref="rubric-review" @set-indicators="setIndicators" />
      <!-- <b-row>
              <b-button variant="danger" @click="$emit('back')" pill class="ml-5">
                  <v-icon name="arrow-left"/> Volver
              </b-button>
          </b-row> -->
      <b-row align-h="center"
        ><h4>Respuestas de {{ selectedResult.name }}</h4></b-row
      >
      <b-row align-h="center"
        ><h5>Situaciones especiales de la evaluación</h5></b-row
      >
      <b-row>
        <b-col>
          <b-form-checkbox
            id="checkbox-1"
            v-model="read_instructions"
            name="checkbox-1"
            :value="true"
            :unchecked-value="false"
          >
            Estudiante no tiene dominio lector.
          </b-form-checkbox>
          <b-form-checkbox
            id="checkbox-2"
            v-model="explain_additional"
            name="checkbox-2"
            :value="true"
            :unchecked-value="false"
          >
            En la aplicación leí el enunciado de las preguntas de matemática y
            comprensión oral de lenguaje.
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col cols="4"> Comentar algo ocurrido en la evaluación: </b-col>
        <b-col>
          <b-textarea
            placeholder="Escribir comentarios..."
            :rows="4"
            v-model="observations"
          />
        </b-col>
      </b-row>
      <b-row align-h="center"><h5>Respuestas</h5></b-row>
      <b-row
        class="m-4 result-row p-2"
        v-for="(answer, aIndex) in selectedResult.answers"
        :key="aIndex"
      >
        <b-col cols="12" class="my-1 text-left">
          <h5>Pregunta #{{ aIndex + 1 }}: {{ answer.question.question }}</h5>
        </b-col>
        <b-row class="w-100 p-4">
          <b-col cols="8" class="my-1 text-left">
            <div class="d-inline">
              {{
                answer.question.kind === 0
                  ? "Alternativa Seleccionada"
                  : "Respuesta"
              }}:
            </div>
            <div class="d-inline" v-if="answer.question.kind === 0">
              <div v-if="answer.alternative">
                <div class="d-inline-block">
                  <span v-if="!answer.alternative.is_formula">
                    {{ answer.alternative.text }}
                  </span>
                  <math-field
                    id="formula"
                    v-else
                    :read-only="true"
                    virtual-keyboard-mode="manual"
                  >
                    {{ answer.alternative.text }}
                  </math-field>
                </div>
                <v-icon
                  v-if="answer.alternative && answer.alternative.is_correct"
                  name="check"
                  class="text-success d-inline"
                />
                <v-icon v-else name="times" class="text-danger d-inline" />
              </div>
              <div v-else class="ml-3">Pregunta omitida</div>
            </div>
            <div v-else-if="answer.question.kind === 1">
              <div
                v-if="
                  answer.question.response_type === 1 && answer.text_response
                "
              >
                {{ answer.text_response }}
              </div>
              <div
                v-else-if="
                  answer.question.response_type === 2 && answer.image_response
                "
              >
                <b-img :src="answer.image_response" />
              </div>
              <div
                v-else-if="
                  answer.question.response_type === 3 && answer.audio_response
                "
              >
                <audio
                  controls
                  :src="answer.audio_response"
                  preload="auto"
                ></audio>
              </div>
              <div
                v-else-if="
                  answer.question.response_type === 4 && answer.letter_response
                "
                class="w-75 mx-auto"
              >
                <div v-if="answer.letter_response">
                  <b-row align-h="end"
                    ><span>{{ answer.letter_response.city_date }}</span></b-row
                  >
                  <b-row align-h="start"
                    ><span>{{ answer.letter_response.greeting }}</span></b-row
                  >
                  <b-row align-h="start"
                    ><span class="text-justify">{{
                      answer.letter_response.body
                    }}</span></b-row
                  >
                  <b-row align-h="start"
                    ><span>{{ answer.letter_response.goodbye }}</span></b-row
                  >
                  <b-row align-h="center"
                    ><span>{{ answer.letter_response.signature }}</span></b-row
                  >
                  <b-row align-h="start"
                    ><span>{{ answer.letter_response.postdata }}</span></b-row
                  >
                </div>
              </div>
              <div
                v-else-if="
                  answer.question.response_type === 5 && answer.news_response
                "
                class="w-75 mx-auto"
              >
                <div v-if="answer.news_response">
                  <b-row align-h="start"
                    ><span>{{ answer.news_response.epigraph }}</span></b-row
                  >
                  <b-row align-h="start"
                    ><span class="h5">{{
                      answer.news_response.title
                    }}</span></b-row
                  >
                  <b-row align-h="start"
                    ><span class="h6">{{
                      answer.news_response.subtitle
                    }}</span></b-row
                  >
                  <b-row align-h="start"
                    ><span class="text-justify">{{
                      answer.news_response.lead
                    }}</span></b-row
                  >
                  <b-row align-h="start"
                    ><span class="text-justify">{{
                      answer.news_response.news_body
                    }}</span></b-row
                  >
                </div>
              </div>
              <div v-else>Pregunta omitida</div>
            </div>
            <div v-else-if="answer.question.kind === 2">
              <ol>
                <li
                  v-for="(txt, txtidx) in answer.dictation_responses"
                  :class="
                    isCorrectDictation(answer, txtidx)
                      ? 'text-success'
                      : isCorrectDictation(answer, txtidx) === false
                      ? 'text-danger'
                      : 'text-info'
                  "
                  :key="`li-${answer.question.index}-${txtidx}`"
                >
                  {{ txt }}
                  <v-icon
                    :name="
                      isCorrectDictation(answer, txtidx)
                        ? 'check'
                        : isCorrectDictation(answer, txtidx) === null
                        ? 'question'
                        : 'times'
                    "
                  />
                </li>
              </ol>
            </div>
            <div v-else-if="answer.question.kind === 3">
              <b-row class="mx-1">
                <b-button
                  v-for="(option_resp, optindex) in answer.option_responses"
                  :key="`btn-${answer.question.index}-${option_resp.option.id}`"
                  :variant="
                    option_resp.option.id ===
                    answer.question.options[optindex].id
                      ? 'success'
                      : 'danger'
                  "
                  class="m-2"
                >
                  <b-img
                    v-if="option_resp.option.image"
                    :src="option_resp.option.image"
                    class="btn-image"
                    fluid
                    thumbnail
                  />
                  <span
                    class="w-100 d-block"
                    v-if="!option_resp.option.is_formula"
                    >{{ option_resp.option.text }}</span
                  >
                  <math-field
                    class="d-block"
                    id="formula"
                    v-else
                    :read-only="true"
                    virtual-keyboard-mode="manual"
                  >
                    {{ option_resp.option.text }}
                  </math-field>
                </b-button>
              </b-row>
              <b-row><span class="mx-3">Respuesta correcta:</span></b-row>
              <b-row class="mx-1">
                <b-button
                  v-for="option in answer.question.options.filter(
                    (o) => o.correct
                  )"
                  :key="`btn2-${answer.question.index}-${option.id}`"
                  variant="success"
                  class="m-2"
                  ><b-img
                    v-if="option.image"
                    :src="option.image"
                    class="btn-image"
                    fluid
                    thumbnail
                  />
                  <span class="w-100 d-block" v-if="!option.is_formula">{{
                    option.text
                  }}</span>
                  <math-field
                    class="d-block"
                    id="formula"
                    v-else
                    :read-only="true"
                    virtual-keyboard-mode="manual"
                  >
                    {{ option.text }}
                  </math-field>
                </b-button>
              </b-row>
            </div>
            <div v-else-if="answer.question.kind === 4">
              <b-row
                v-for="(exrep, exindex) of answer.exercise_responses"
                :key="`exrep-${exrep.id}`"
              >
                <b-col cols="6">{{
                  answer.question.exercises[exindex].text
                }}</b-col>
                <b-col
                  cols="6"
                  v-if="answer.question.exercises[exindex].type < 3"
                >
                  <div v-if="exrep.alternative" class="d-inline-block">
                    <span v-if="!exrep.alternative.is_formula">
                      {{ exrep.alternative.text }}
                    </span>
                    <math-field
                      v-else
                      id="formula"
                      :read-only="true"
                      virtual-keyboard-mode="manual"
                    >
                      {{ exrep.alternative.text }}
                    </math-field>
                  </div>
                  <div v-else class="d-inline-block">
                    {{ exrep.text }}
                  </div>
                  <v-icon
                    v-if="exrep.alternative"
                    :class="
                      exrep.alternative.correct ? 'text-success' : 'text-danger'
                    "
                    :name="exrep.alternative.correct ? 'check' : 'times'"
                  />
                  <v-icon
                    v-else
                    :class="
                      isCorrectExercise(answer, exindex)
                        ? 'text-success'
                        : isCorrectExercise(answer, exindex) === null
                        ? 'text-info'
                        : 'text-danger'
                    "
                    :name="
                      isCorrectExercise(answer, exindex)
                        ? 'check'
                        : isCorrectExercise(answer, exindex) === null
                        ? 'question'
                        : 'times'
                    "
                  />
                </b-col>
                <b-col
                  v-else-if="
                    answer.question.exercises[exindex].type === 3 &&
                    exrep.exercise_responses_files
                  "
                >
                  <div
                    v-for="(file, findex) in exrep.exercise_responses_files"
                    :key="'f-' + findex"
                  >
                    <audio controls class="mx-auto w-100" preload="auto">
                      <source :src="file.file" />
                      Tu navegador no soporta la reproducción de audio
                    </audio>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col
            cols="4"
            class="text-left p-2"
            style="border-left: #f8b500 1px solid"
          >
            <b-button
              class="d-block mb-3"
              variant="primary"
              pill
              v-if="
                answer.question.rubric.id &&
                answer.question.rubric.levels.length &&
                answer.question.rubric.indicators.length
              "
              @click="
                $refs['rubric-review'].open(
                  answer.question.rubric,
                  answer.chosen_indicators,
                  answer.id
                )
              "
            >
              Corregir con rúbrica
            </b-button>
            <h5>Corregir Manualmente</h5>
            <b-row v-if="answer.question.kind === 1">
              <b-col cols="6">Puntaje</b-col>
              <b-col cols="4">
                <b-input
                  type="number"
                  min="0"
                  :disabled="
                    answer.question.rubric.id &&
                    answer.question.rubric.levels.length &&
                    answer.question.rubric.indicators.length
                  "
                  :max="answer.question.score"
                  size="sm"
                  @change="answer.reviewed = true"
                  v-model="answer.score"
                />
              </b-col>
            </b-row>
            <!-- <b-radio-group buttons v-if="answer.question.kind===1"
                              @click="answer.reviewed = true"
                              :button-variant="getColor(answer.is_correct)"
                              v-model="answer.is_correct" class="mb-3"
                              :options="evalOptions"></b-radio-group> -->
            <b-row v-else-if="answer.question.kind === 2" no-gutters>
              <b-col cols="7">Dictados correctos:</b-col>
              <b-col cols="5"
                >{{ getCorrectDictations(answer) }} de
                {{
                  answer.question.dictation_audios.filter(
                    (x) => x.correct_answers && x.correct_answers.length > 0
                  ).length
                }}</b-col
              >
              <b-col cols="7">Dictados por revisar:</b-col>
              <b-col cols="2"
                ><b-input
                  type="number"
                  min="0"
                  size="sm"
                  value="0"
                  @change="addToScoreDict($event, answer)"
              /></b-col>
              <b-col cols="3" class="pl-1"
                ><small
                  >buenas de
                  {{
                    answer.question.dictation_audios.filter(
                      (x) =>
                        !x.correct_answers || x.correct_answers.length === 0
                    ).length
                  }}</small
                ></b-col
              >
            </b-row>
            <b-row v-else-if="answer.question.kind === 4" no-gutters>
              <b-col cols="7">Ejercicios correctos:</b-col>
              <b-col cols="5"
                >{{ getCorrectExercises(answer) }} de
                {{
                  answer.question.exercises.filter(
                    (x) => x.correct_answers && x.correct_answers.length > 0
                  ).length
                }}</b-col
              >
              <b-col cols="7">Ejercicios por revisar:</b-col>
              <b-col cols="2"
                ><b-input
                  type="number"
                  min="0"
                  size="sm"
                  value="0"
                  @change="addToScore($event, answer)"
              /></b-col>
              <b-col cols="3" class="pl-1"
                ><small
                  >buenas de
                  {{
                    answer.question.exercises.filter(
                      (x) =>
                        !x.correct_answers || x.correct_answers.length === 0
                    ).length
                  }}</small
                ></b-col
              >
            </b-row>
            <b-row v-else-if="answer.question.kind === 3" no-gutters>
              <b-col
                >Selecciones correctas: {{ getCorrectOptions(answer) }} de
                {{
                  answer.question.options.filter((o) => o.correct).length
                }}</b-col
              >
            </b-row>
            <b-row>
              <b-col cols="12"
                >Puntaje:
                {{ answer.reviewed ? answer.score : getScore(answer) }}
                pts.</b-col
              >
            </b-row>
            <b-row
              v-if="
                answer.question.kind === 1 &&
                (answer.question.response_type === 1 ||
                  answer.question.response_type === 3)
              "
            >
              <b-col> Emisiones PSF: </b-col>
            </b-row>
            <b-row
              v-if="
                answer.question.kind === 1 &&
                (answer.question.response_type === 1 ||
                  answer.question.response_type === 3)
              "
            >
              <b-col>
                <b-textarea
                  rows="2"
                  v-model="answer.observations"
                  placeholder="Emisiones PSF..."
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-row>
      <b-row align-h="center">
        <b-button pill variant="primary" @click="sendEvaluation">
          <v-icon name="save" /> Guardar evaluación
        </b-button>
        <b-button pill variant="success" @click="sendEvaluationClose">
          <v-icon name="save" /> Guardar y cerrar
        </b-button>
      </b-row>
    </div>
    <template #overlay>
      <b-container>
        <b-row class="d-flex justify-content-center">
          <b-img :src="loadingGif" rounded alt="Loading image" />
        </b-row>
      </b-container>
    </template>
  </b-overlay>
</template>
<script>
import customMaterialService from "../../../services/customMaterialService";
import RubricReviewModal from "../modals/RubricReviewModal";
import "mathlive";

export default {
  components: {
    RubricReviewModal,
  },
  props: {
    reviewResponseID: {
      type: String,
      required: false,
    },
    selectedLink: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      selectedResult: {},
      evalOptions: [
        { text: "Correcto", value: true },
        { text: "Incorrecto", value: false },
      ],
      observations: "",
      explain_additional: false,
      read_instructions: false,
      loading: false,
      loadingGif: require("../../../assets/img/preloader.gif"),
    };
  },
  methods: {
    setIndicators(chosenIndicators, answerID, score, maxScore) {
      this.$nextTick(() => {
        let ans = this.selectedResult.answers.filter(
          (a) => a.id === answerID
        )[0];
        ans.chosen_indicators = chosenIndicators;
        ans.score = Math.round((ans.question.score * score) / maxScore);
        ans.reviewed = true;
      });
    },
    getColor(selected) {
      if (selected === null) return "outline-primary";
      else if (selected) return "success";
      return "danger";
    },
    formatAnswers(data) {
      let answers = data;
      let time =
        Math.round(
          answers.map((a) => a.time_taken).reduce((x, y) => x + y, 0) * 100
        ) / 100;
      this.selectedResult = {
        id: this.selectedLink.id,
        started: this.selectedLink.started,
        time: time,
        rut: this.selectedLink.student_rut,
        answers: answers,
        email: this.selectedLink.student_email,
        group: this.selectedLink.group
          ? this.selectedLink.group
          : { id: "none" },
        name: this.selectedLink.student_name,
        videocall: this.selectedLink.videocall,
      };
    },
    getCorrectOptions(ans) {
      let correct = 0;
      ans.option_responses.forEach((o, i) => {
        if (o.option.id === ans.question.options[i].id) correct++;
      });
      return correct;
    },
    isCorrectDictation(answer, dIndex) {
      if (dIndex >= answer.question.dictation_audios.length) {
        return false;
      }
      if (
        !answer.question.dictation_audios[dIndex].correct_answers ||
        answer.question.dictation_audios[dIndex].correct_answers.length === 0
      ) {
        return null;
      }
      return answer.question.dictation_audios[dIndex].correct_answers
        .map((x) => x.toLowerCase().trim())
        .includes(answer.dictation_responses[dIndex].toLowerCase().trim());
    },
    isCorrectExercise(answer, eIndex) {
      if (eIndex >= answer.question.exercises.length) {
        return false;
      }
      if (
        !answer.question.exercises[eIndex].correct_answers ||
        answer.question.exercises[eIndex].correct_answers.length === 0
      )
        return null;
      return answer.question.exercises[eIndex].correct_answers
        .map((x) => x.toLowerCase().trim())
        .includes(answer.exercise_responses[eIndex].text.toLowerCase().trim());
    },
    getCorrectExercises(answer) {
      return answer.exercise_responses.filter(
        (r, i) =>
          (r.alternative && r.alternative.is_correct) ||
          this.isCorrectExercise(answer, i) === true
      ).length;
    },
    getCorrectDictations(answer) {
      return answer.dictation_responses.filter(
        (r, i) => this.isCorrectDictation(answer, i) === true
      ).length;
    },
    getAllCorrects(student) {
      let result = 0;
      student.answers.forEach((a) => {
        if (a.question.kind === 0 && a.alternative && a.alternative.is_correct)
          result++;
        else if (a.question.kind === 2) result += this.getCorrectDictations(a);
        else if (a.question.kind === 3) result += this.getCorrectOptions(a);
        else if (a.question.kind === 4) result += this.getCorrectExercises(a);
      });
      return result;
    },
    getAllQuestions(student) {
      let result = 0;
      student.answers.forEach((a) => {
        if (a.question.kind === 0 || a.question.kind === 1) result++;
        else if (a.question.kind === 2) result += a.question.dictation;
        else if (a.question.kind === 3) result += a.question.options.length;
        else if (a.question.kind === 4) result += a.question.exercises.length;
      });
      return result;
    },
    getNonRevised(student) {
      return student.answers.filter(
        (a) =>
          (a.question.kind === 1 && !a.reviewed) ||
          (a.question.kind === 5 &&
            a.question.exercises.filter(
              (x) => !x.correct_answers || x.correct_answers.length === 0
            ).length > 0) ||
          (a.question.kind === 2 &&
            a.question.dictation_audios.filter(
              (x) => !x.correct_answers || x.correct_answers.length === 0
            ).length > 0)
      ).length;
    },
    getRevisable(student) {
      return (
        student.answers.filter((a) => a.question.kind === 1).length +
        student.answers.filter(
          (a) =>
            a.question.kind === 5 &&
            a.question.exercises.filter((x) => x.correct_answers).length === 0
        )
      );
    },
    getScore(answer) {
      if (answer !== null) {
        if (answer.question.kind === 0) {
          if (answer.alternative !== null) {
            answer.is_correct = answer.alternative.is_correct;
            return answer.is_correct ? answer.question.score : 0;
          } else {
            return 0;
          }
        } else if (answer.question.kind === 1)
          return answer.is_correct ? answer.question.score : 0;
        else if (answer.question.kind === 2) {
          return (
            (answer.question.score / answer.question.dictation) *
            this.getCorrectDictations(answer)
          );
        } else if (answer.question.kind === 3)
          return (
            (answer.question.score /
              answer.question.options.filter((o) => o.correct).length) *
            this.getCorrectOptions(answer)
          );
        else {
          return (
            (answer.question.score / answer.question.exercises.length) *
            this.getCorrectExercises(answer)
          );
        }
      } else return 0;
    },
    getResponses() {
      this.loading = true;
      this.observations = "";
      this.explain_additional = false;
      this.read_instructions = false;
      customMaterialService.getResponses(this.reviewResponseID).then((data) => {
        this.observations = data.events.observations;
        this.explain_additional = data.events.explain_additional;
        this.read_instructions = data.events.read_instructions;
        this.formatAnswers(data.answers);
        this.loading = false;
      });
    },
    saveEvents() {
      let events = {};
      events.observations = this.observations;
      if (this.read_instructions) {
        events.read_instructions = this.read_instructions;
      }
      if (this.explain_additional) {
        events.explain_additional = this.explain_additional;
      }
      customMaterialService.saveEvents(this.reviewResponseID, events);
    },
    sendEvaluation() {
      let data = [];
      this.selectedResult.answers.forEach((a) =>
        data.push({
          id: a.id,
          score: a.reviewed ? a.score : this.getScore(a),
          reviewed: a.reviewed,
          chosen_indicators: a.chosen_indicators,
          observations: a.observations,
        })
      );
      this.saveEvents();
      customMaterialService
        .evaluateMaterial({ data: data, invite: this.selectedLink.id })
        .then(
          () => this.$toasted.success("Evaluación guardada"),
          () => this.$toasted.error("No se pudo guardar. Intenta nuevamente")
        );
    },
    sendEvaluationClose() {
      let data = [];
      this.selectedResult.answers.forEach((a) =>
        data.push({
          id: a.id,
          score: a.reviewed ? a.score : this.getScore(a),
          reviewed: a.reviewed,
          chosen_indicators: a.chosen_indicators,
          observations: a.observations,
        })
      );
      this.saveEvents();
      customMaterialService
        .evaluateMaterial({
          data: data,
          close: true,
          invite: this.selectedLink.id,
        })
        .then(
          () => {
            this.$toasted.success("Evaluación guardada");
            this.close();
          },
          () => this.$toasted.error("No se pudo guardar. Intenta nuevamente")
        );
      this.createReport(
        this.selectedLink.student_grade.id,
        this.selectedLink.test_id,
        this.selectedLink.id,
        this.observations
      );
    },
    addToScore(correct, answer) {
      this.$set(answer, "reviewed", true);
      if (correct > answer.question.exercises.length) return;
      this.$set(
        answer,
        "score",
        correct * (answer.question.score / answer.question.exercises.length)
      );
    },
    addToScoreDict(correct, answer) {
      this.$set(answer, "reviewed", true);
      if (correct > answer.question.dictation_audios.length) return;
      this.$set(
        answer,
        "score",
        correct *
          (answer.question.score / answer.question.dictation_audios.length)
      );
    },
    close() {
      this.$emit("back");
    },
    createReport(studentGradeID, material, material_link, obs) {
      customMaterialService
        .createReport({
          student_grade: studentGradeID,
          material: material,
          material_link: material_link,
          observations: obs,
        })
        .then((data) => {
          // const student = this.students_reviewed.find(elem => elem.id === studentGradeID);
          // student.last_eureka_report = data.last_eureka_report;
          // student.last_eureka_report_date = data.last_eureka_report_date;
          console.log(data);
          this.ret = data.data;
        });
    },
  },
  mounted() {
    if (this.reviewResponseID) {
      this.getResponses();
    } else {
      this.selectedResult = {};
    }
  },
};
</script>
<style lang="scss"></style>
