<template>
  <div>
    <b-row class="py-3 px-2 mx-1 bg-white rounded">
      <b-col cols="3" class="text-left">
        <b-button
          variant="danger"
          @click="$emit('back-to-application-admin')"
          pill
          class="ml-1"
        >
          <v-icon name="arrow-left" /> Volver
        </b-button>
      </b-col>
      <b-col>
        <b-form-select v-model="selectedStudentLevel" @change="changeLevel">
          <b-form-select-option :value="''" disabled>
            Seleccione un Nivel
          </b-form-select-option>
          <b-form-select-option v-if="levels.length == 0" disabled>
            No hay estudiantes en esta aplicación
          </b-form-select-option>
          <b-form-select-option
            v-for="(level, levelIdx) in levels"
            :key="levelIdx"
            :value="level"
          >
            {{ level.label }}
          </b-form-select-option>
        </b-form-select>
      </b-col>

      <b-col>
        <b-form-select v-model="selectedStudentsGrade" @change="changeGrade">
          <b-form-select-option :value="null" disabled>
            Seleccione un Curso
          </b-form-select-option>
          <b-form-select-option
            v-for="(grade, gradeIdx) in loadedLevels[selectedStudentLevel.code]"
            :key="gradeIdx"
            :value="grade"
          >
            {{ selectedStudentLevel.label + " " + grade.name }}
          </b-form-select-option>
        </b-form-select>
      </b-col>

      <b-col>
        <b-form-select v-model="selectedStudentLink" @change="changeStudent">
          <b-form-select-option :value="null" disabled>
            Seleccione un Estudiante
          </b-form-select-option>
          <b-form-select-option
            v-for="(student, studentIdx) in selectedStudents"
            :key="studentIdx"
            :value="student.material_link"
          >
            {{ student.material_link.student_name }}
          </b-form-select-option>
        </b-form-select>
      </b-col>
    </b-row>
    <div class="bg-white mt-2 mx-1 rounded">
      <b-tabs
        v-model="activeTab"
        content-class="p-0"
        fill
        align="center"
        card
        justified
      >
        <b-tab title="Ver el Material" lazy>
          <material-preview
            :inMaterial="{ id: selectedApplication.material.id }"
            mode="checkingApplication"
          />
        </b-tab>
        <b-tab title="Corregir Aplicación">
          <div v-if="selectedStudentLink">
            <material-response-review
              v-if="selectedStudentLink.finished"
              :key="renderResponseKey"
              :reviewResponseID="
                selectedStudentLink ? selectedStudentLink.id : null
              "
              :selectedLink="selectedStudentLink ? selectedStudentLink : null"
              @back="$emit('back')"
            />
            <div v-else>
              <p class="text-warning">
                ESTE ESTUDIANTE NO HA RESPONDIDO EL MATERIAL
              </p>
            </div>
          </div>
          <div v-else>
            <p class="text-warning">SELECCIONE UN ESTUDIANTE</p>
          </div>
        </b-tab>
        <b-tab title="Ver Informe">
          <div v-if="!reportLink">
            <p class="text-warning">
              NO SE HA GENERADO INFORME PARA ESTE ESTUDIANTE
            </p>
          </div>
          <div v-else>
            <div class="text-right">
              <b-button @click="downloadReport">Descargar Informe</b-button>
            </div>
            <b-embed
              :src="reportLink"
              type="iframe"
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
              aspect="21by9"
            />
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import customMaterialService from "@/services/customMaterialService";
import MaterialResponseReview from "@/components/custom-materials/miscellaneous/MaterialResponseReview";
import MaterialPreview from "@/components/custom-materials/miscellaneous/MaterialPreview";

export default {
  name: "MaterialResponseAdmin",
  components: {
    MaterialResponseReview,
    MaterialPreview,
  },
  props: {
    selectedLink: {
      type: Object,
      required: false,
    },
    application: {
      type: Object,
      required: false,
    },
    levels: {
      type: Array,
      required: false,
    },
    selectedLevel: {
      type: Object,
      required: false,
    },
    selectedGrade: {
      type: Object,
      required: false,
    },
  },
  created() {
    // this.selectedGrade = this.selectedLink.student_grade.grade;
    this.selectedApplication = this.application;
    this.selectedStudentLink = this.selectedLink;
    this.selectedStudentLevel = this.selectedLevel;
    this.selectedStudentsGrade = this.selectedGrade;
    this.selectedStudents = this.selectedGrade.students;
    this.$options.sockets.onmessage = (data) => {
      const message = JSON.parse(data.data);
      if (message.type === "report_file_updated") {
        if (message.material_link.id == this.selectedStudentLink.id) {
          this.reportLink = message.material_file.alternate_link;
          this.downloadLink = message.material_file.download_link;
          this.activeTab = 2;
          this.$toasted.success("Informe creado!");
        }
      }
    };
  },
  data() {
    return {
      selectedApplication: {},
      // selectedGrade: "",
      selectedStudentLink: null,
      selectedStudentLevel: null,
      // applications:[],
      renderResponseKey: 0,
      reportLink: "",
      downloadLink: "",
      activeTab: 1,
      loadedLevels: {},
      selectedStudentsGrade: null,
      selectedStudents: [],
    };
  },
  computed: {
    schoolID() {
      return this.$store.state.login.school;
    },
    userID() {
      return this.$store.state.login.user_id;
    },
  },
  watch: {},
  mounted() {
    this.retrieveReportLink();
    this.load(this.selectedLevel.code);
  },
  methods: {
    renderingResponseKey() {
      this.renderResponseKey += 1;
    },

    changeStudent() {
      this.renderingResponseKey();
      this.retrieveReportLink();
    },
    changeLevel(newVal) {
      this.selectedStudentLink = null;
      this.selectedStudentsGrade = null;
      this.selectedStudents = [];

      !this.loadedLevels[newVal.code] ? this.load(newVal.code) : "";
    },
    retrieveReportLink() {
      customMaterialService
        .getApplicationReport(this.selectedStudentLink.id)
        .then((response) => {
          this.reportLink = response.alternate_link;
          this.downloadLink = response.download_link;
        })
        .catch((e) => {
          console.log(e);
          this.reportLink = "";
        });
    },
    downloadReport() {
      const link = document.createElement("a");
      link.href = this.downloadLink;

      link.setAttribute("download", "file.docx");
      document.body.appendChild(link);
      link.click();
    },
    load(level) {
      this.$set(this.loadedLevels, level, null);
      customMaterialService
        .getLevelLinks(this.selectedApplication.id, level)
        .then((data) => {
          this.$set(this.loadedLevels, level, data.grades);
        });
    },
    changeGrade(newVal) {
      this.selectedStudentLink = null;
      this.selectedStudents = newVal.students;
    },
  },
};
</script>

<style></style>
