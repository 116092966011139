<template>
  <b-container class="responsive-container">
    <b-form-row
      v-if="question.additional_instruction"
      class="w-100 p-1 p-lg-3 mt-2"
      style="background-color: #e3e8ff"
    >
      <b-col
        v-if="question.additional_instruction.audio"
        class="text-left"
        cols="1"
      >
        <div class="d-inline-block">
          <audio
            ref="player-additional"
            :src="question.additional_instruction.audio"
            @ended="
              () => {
                nowPlaying = '';
              }
            "
          ></audio>
          <div v-if="nowPlaying !== 'additional'">
            <b-img
              @click="togglePlayer('additional')"
              class="responsive-icon"
              style="cursor: pointer"
              :src="listenIcon"
            />
          </div>
          <div v-else>
            <b-img
              @click="togglePlayer('additional')"
              class="responsive-icon"
              style="cursor: pointer"
              :src="repeatIcon"
            />
            <b-img
              @click="pausePlayer('additional')"
              class="responsive-icon"
              style="cursor: pointer"
              :src="
                $refs[`player-additional`] &&
                $refs[`player-additional`]['paused']
                  ? resumeIcon
                  : pauseIcon
              "
            />
          </div>
        </div>
      </b-col>
      <b-col class="text-left" cols="8">
        <p
          class="content responsive-additional-instruction"
          style="white-space: pre-wrap"
        >
          {{ question.additional_instruction.instruction }}
        </p>
      </b-col>
    </b-form-row>
    <div
      v-if="
        question.additional_instruction &&
        question.additional_instruction.formulas
      "
    >
      <b-form-row
        class="w-100 p-2 p-lg-3 ml-2 mr-2 justify-content-center"
        v-for="(formula, findex) in question.additional_instruction.formulas"
        :key="findex"
      >
        <b-col cols="auto">
          <math-field
            id="formula"
            :key="formula"
            :read-only="true"
            class="responsive-additional-instruction"
            virtual-keyboard-mode="manual"
            style="height: 50px"
          >
            {{ formula }}
          </math-field>
        </b-col>
      </b-form-row>
    </div>
    <b-form-row
      v-if="
        question.additional_instruction && question.additional_instruction.image
      "
      class="justify-content-center my-3"
    >
      <b-col :cols="getColAdditionalInstructionImage(question)">
        <b-img :src="question.additional_instruction.image" fluid-grow />
      </b-col>
    </b-form-row>
    <b-form-row
      class="w-100 p-1 p-lg-3"
      :class="
        design === 1
          ? 'modern-question-bg'
          : design === 2
          ? 'dream-question-bg'
          : design === 3
          ? 'calm-question-bg'
          : 'default-question-bg'
      "
    >
      <b-col class="text-left" cols="2">
        <strong
          style="padding-right: 15px"
          class="responsive-question-number"
          :class="
            design === 1
              ? 'modern-question-number'
              : design === 2
              ? 'dream-question-number'
              : design === 3
              ? 'calm-question-number'
              : 'default-question-number'
          "
        >
          {{ qindex + 1 }}
        </strong>
        <div v-if="question.audio" class="d-inline-block">
          <audio
            :ref="`player-question-${qindex}`"
            :src="question.audio"
            @ended="
              () => {
                nowPlaying = '';
              }
            "
          ></audio>
          <div v-if="nowPlaying !== `question-${qindex}`">
            <b-img
              @click="togglePlayer(`question-${qindex}`)"
              class="responsive-icon"
              style="cursor: pointer"
              :src="listenIcon"
            />
          </div>
          <div v-else>
            <b-img
              @click="togglePlayer(`question-${qindex}`)"
              class="responsive-icon"
              style="cursor: pointer"
              :src="repeatIcon"
            />
            <b-img
              @click="pausePlayer(`question-${qindex}`)"
              class="responsive-icon"
              style="cursor: pointer"
              :src="
                $refs[`player-question-${qindex}`] &&
                $refs[`player-question-${qindex}`]['paused']
                  ? resumeIcon
                  : pauseIcon
              "
            />
          </div>
          <!-- <b-button class="" pill size="md" @click="togglePlayer('main')">
                        {{$refs['player-main']}}
                        <v-icon :name="$refs['player-main'] && !$refs['player-main']['paused']? 'redo':'volume-up'" scale="1.2"/>
                    </b-button> -->
        </div>
      </b-col>
      <b-col class="text-left" cols="10">
        <p
          class="content responsive-question-content"
          style="white-space: pre-wrap"
        >
          {{ question.question }}
        </p>
      </b-col>
    </b-form-row>
    <b-form-row class="mt-3">
      <div class="mb-3" v-if="question.is_count_question">
        <CountQuestionComponent :question="question" />
      </div>
    </b-form-row>
    <b-form-row
      class="w-100 justify-content-center"
      v-for="(formula, findex) in question.formulas"
      :key="findex"
    >
      <b-col cols="auto">
        <math-field
          id="formula"
          :read-only="true"
          :key="formula"
          virtual-keyboard-mode="manual"
          style="height: 50px; font-size: 20pt"
        >
          {{ formula }}
        </math-field>
      </b-col>
    </b-form-row>
    <b-form-row v-if="question.text" class="px-lg-3 text-muted text-left">
      <b-col>Pregunta relacionada al texto anterior</b-col>
    </b-form-row>
    <b-form-row v-if="question.image" class="p-3 center-content">
      <b-img
        :src="question.image"
        :class="
          question.image_class ? question.image_class : 'question-image-w50'
        "
        style=""
        left
      ></b-img>
    </b-form-row>
    <div v-if="question.kind === 0" class="w-100">
      <h6 class="text-left px-2 px-lg-3 my-3 responsive-instruction">
        Selecciona la alternativa correcta:
      </h6>
      <b-row class="w-100 align-items-end">
        <b-col
          v-for="(alternative, altIndex) of question.alternatives"
          :key="`${question.id}-${altIndex}`"
          class="mt-3"
          :class="getClassAlternative(alternative, question)"
          @click="chosenAlternative = alternative"
          :cols="
            alternative.width != 0
              ? alternative.width
              : getColAlternative(question)
          "
        >
          <div
            class="d-inline-block h-100 pb-2 pt-3 px-3"
            :class="
              chosenAlternative === alternative
                ? design === 1
                  ? 'modern-border-color'
                  : design === 2
                  ? 'dream-border-color'
                  : design === 3
                  ? 'calm-border-color'
                  : 'default-border-color'
                : ''
            "
            :style="
              chosenAlternative === alternative
                ? 'border-style: solid; border-radius: 12%; border-width: medium;'
                : ''
            "
            style="background-color: #e3e8ff; font-size: 16px; cursor: pointer"
          >
            <b-img
              v-if="alternative.image"
              :src="alternative.image"
              class="question-image"
              width="100px"
              :height="alternative.height ? alternative.height : ''"
              style=""
            />
            <span
              class="w-100 d-block align-middle text-left responsive-alternative"
              style="font-family: 'KaTeX_Main'"
              v-if="!alternative.is_formula && !alternative.image"
            >
              {{ alternative.text }}
            </span>
            <span
              class="w-100 d-block align-middle responsive-alternative"
              style="font-family: 'KaTeX_Main'"
              v-if="!alternative.is_formula && alternative.image"
            >
              {{ alternative.text }}
            </span>
            <math-field
              class="align-middle responsive-alternative"
              v-if="alternative.is_formula"
              :key="alternative.text"
              style="pointer-events: none; height: 50px; margin-bottom: 10px"
              id="formula"
              :read-only="true"
            >
              {{ alternative.text }}
            </math-field>
            <div v-if="alternative.audio" class="d-inline-block">
              <audio
                :ref="`player-alt-${altIndex}`"
                :src="alternative.audio"
                @ended="
                  () => {
                    nowPlaying = '';
                  }
                "
              ></audio>
              <div v-if="nowPlaying !== `alt-${altIndex}`">
                <b-img
                  @click="altTogglePlayer(`alt-${altIndex}`)"
                  class="responsive-icon"
                  style="cursor: pointer"
                  :src="listenIcon"
                />
              </div>
              <div v-else>
                <b-img
                  @click="altTogglePlayer(`alt-${altIndex}`)"
                  class="responsive-icon"
                  style="cursor: pointer"
                  :src="repeatIcon"
                />
                <b-img
                  @click="altPausePlayer(`alt-${altIndex}`)"
                  class="responsive-icon"
                  style="cursor: pointer"
                  :src="
                    $refs[`player-alt-${altIndex}`] &&
                    $refs[`player-alt-${altIndex}`]['paused']
                      ? resumeIcon
                      : pauseIcon
                  "
                />
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-form-row v-else-if="question.kind === 1" class="w-100">
      <div v-if="question.response_type === 1" class="m-2 w-100">
        <b-textarea
          v-model="textResponse"
          class="responsive-essay"
          placeholder="Escribe acá tu respuesta..."
          rows="6"
        >
        </b-textarea>
      </div>
      <div v-else-if="question.response_type === 2" class="m-2 w-100">
        <FilePond
          ref="pond"
          :allow-multiple="false"
          accepted-file-types="image/jpeg, image/png"
          class="p-lg-2 m-2"
          label-idle="Arrastra tu imagen acá o haz clic para navegar"
        ></FilePond>
        <b-container v-if="prevImg">
          <b-row> Imagen anterior: <b-img :src="prevImg" fluid></b-img> </b-row>
          <b-row>
            *Si no subes imagen se considerará no contestada, en caso de querer
            la misma imagen por favor volver a subirla.
          </b-row>
        </b-container>
      </div>
      <b-row
        v-else-if="question.response_type === 3"
        class="pl-3 pr-0 my-2 w-100 align-items-center"
      >
        <b-col cols="12" class="text-center d-inline-block">
          <CustomAudioRecorderComponent
            baseMsg="Click para grabar tu respuesta"
            recordingMsg="Grabando tu respuesta"
            warningMsg="Si eliminas el audio
          se perderá el registro"
            @result="handleAudio"
            @mute-all="muteAllAudios"
          />
        </b-col>
      </b-row>
      <div v-else-if="question.response_type === 4" class="m-2">
        <b-row align-h="end">
          <b-col cols="8">
            <b-input v-model="letterResponse.city_date" size="sm" />
          </b-col>
        </b-row>
        <b-row align-h="start">
          <b-col cols="8">
            <b-input v-model="letterResponse.greeting" size="sm" />
          </b-col>
          <b-col class="mt-1" cols="12">
            <b-textarea v-model="letterResponse.body" rows="6"></b-textarea>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col cols="8">
            <b-input v-model="letterResponse.goodbye" size="sm" />
          </b-col>
        </b-row>
        <b-row align-h="end">
          <b-col cols="8">
            <b-input v-model="letterResponse.signature" size="sm" />
          </b-col>
        </b-row>
        <b-row align-h="start">
          <b-col cols="12">
            <b-input v-model="letterResponse.postdata" size="sm" />
          </b-col>
        </b-row>
      </div>
      <div v-else-if="question.response_type === 5" class="m-2">
        <b-row align-h="start">
          <b-input size="sm" v-model="newsResponse.epigraph" />
        </b-row>
        <b-row align-h="start">
          <b-input size="lg" v-model="newsResponse.title" />
        </b-row>
        <b-row align-h="start">
          <b-input size="sm" v-model="newsResponse.subtitle" />
        </b-row>
        <b-row align-h="start">
          <b-textarea size="sm" rows="3" v-model="newsResponse.lead" />
        </b-row>
        <b-row align-h="start">
          <b-textarea size="sm" rows="6" v-model="newsResponse.news_body" />
        </b-row>
      </div>
    </b-form-row>
    <b-form-row v-else-if="question.kind === 2" class="px-3">
      <h6 class="text-left mb-3">Ingresa lo dictado:</h6>
      <b-row
        v-for="dictindex in question.dictation"
        :key="`dict-${dictindex}`"
        align-h="start"
        class="w-100"
        no-gutters
      >
        <b-col cols="12" lg="9">
          <b-form-group :label="`${dictindex}.-`" label-cols="1">
            <b-input
              v-model="dictations[dictindex]"
              :readonly="currentDict !== dictindex"
              size="sm"
              @keyup.enter="nextDict"
            ></b-input>
          </b-form-group>
        </b-col>
        <b-col class="mx-3 mx-lg-0 py-0" cols="5" lg="3">
          <!-- <b-button v-show="currentDict===dictindex" class="btn-circle mx-1" pill size="sm" variant="info"
                            @click="$refs[`dyn-player-${dictindex}`][0]['play']()">
                    <v-icon name="microphone"/>
                    </b-button> -->
          <b-button
            v-show="currentDict === dictindex"
            class="my-0"
            pill
            size="sm"
            variant="primary"
            @click="nextDict"
          >
            Siguiente
            <v-icon name="forward" />
          </b-button>
        </b-col>
        <audio
          :ref="`dyn-player-${dictindex}`"
          :src="question.dictation_audios[dictindex - 1].audio"
        />
      </b-row>
    </b-form-row>
    <b-form-row v-else-if="question.kind === 3" class="w-100 p-2">
      <h4 class="text-left mb-3 responsive-instruction">
        Haz clic en las respuestas correctas:
      </h4>
      <b-row class="w-100 align-items-end">
        <b-col
          v-for="(option, optindex) in shuffledOptions"
          :key="`opt-select-${optindex}`"
          class="mt-3"
          @click="chooseOption(!chosenOptions.includes(option), option)"
          :md="option.width != 0 ? option.width : getCol(question)"
          cols="12"
        >
          <div>
            <strong
              v-if="chosenOptions.includes(option)"
              class="pr-2 responsive-option"
              style="color: #16c79a; font-size: 20px"
              >{{ chosenOptions.indexOf(option) + 1 }}°</strong
            >
          </div>
          <div
            class="w-100"
            :class="
              chosenOptions.includes(option)
                ? design === 1
                  ? 'modern-border-color'
                  : design === 2
                  ? 'dream-border-color'
                  : design === 3
                  ? 'calm-border-color'
                  : 'default-border-color'
                : ''
            "
            :style="
              chosenOptions.includes(option)
                ? 'border-style: solid; border-radius: 5%; border-width: medium;'
                : ''
            "
            style="background-color: #e3e8ff; font-size: 16px; cursor: pointer"
          >
            <b-img
              v-if="option.image"
              :src="option.image"
              class="question-image"
              style=""
            />
            <span
              class="w-100 d-block align-middle text-left ml-3 mt-3 responsive-option"
              style="font-family: 'KaTeX_Main'"
              v-if="!option.is_formula && !option.image"
            >
              {{ option.text }}
            </span>
            <span
              class="w-100 d-block align-middle responsive-option"
              style="font-family: 'KaTeX_Main'"
              v-if="!option.is_formula && option.image"
            >
              {{ option.text }}
            </span>
            <math-field
              class="align-middle responsive-option"
              v-if="option.is_formula"
              :key="option.text"
              style="pointer-events: none; height: 50px"
              id="formula"
              :read-only="true"
            >
              {{ option.text }}
            </math-field>
            <div v-if="option.audio" class="d-inline-block">
              <audio
                :ref="`player-opt-${optindex}`"
                :src="option.audio"
                @ended="
                  () => {
                    nowPlaying = '';
                  }
                "
              ></audio>
              <div v-if="nowPlaying !== `opt-${optindex}`">
                <b-img
                  @click="altTogglePlayer(`opt-${optindex}`)"
                  class="responsive-opt-icon"
                  style="cursor: pointer"
                  :src="listenIcon"
                />
              </div>
              <div v-else>
                <b-img
                  @click="altTogglePlayer(`opt-${optindex}`)"
                  class="responsive-opt-icon"
                  style="cursor: pointer"
                  :src="repeatIcon"
                />
                <b-img
                  @click="altTogglePlayer(`opt-${optindex}`)"
                  class="responsive-opt-icon"
                  style="cursor: pointer"
                  :src="
                    $refs[`player-opt-${optindex}`] &&
                    $refs[`player-opt-${optindex}`]['paused']
                      ? resumeIcon
                      : pauseIcon
                  "
                />
              </div>
            </div>
            <audio
              v-if="option.audio"
              :ref="`dyn-option-player-${optindex}`"
              :src="option.audio"
            />
          </div>
        </b-col>
      </b-row>
    </b-form-row>
  </b-container>
</template>
<script>
import CustomAudioRecorderComponent from "./CustomAudioRecorderComponent.vue";
import CountQuestionComponent from "./CountQuestionComponent.vue";
export default {
  components: {
    CustomAudioRecorderComponent,
    CountQuestionComponent,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },

    design: {
      type: Number,
      required: false,
    },
    qindex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      nowPlaying: "",
      textResponse: "",
      letterResponse: {},
      newsResponse: {},
      dictations: [],
      chosenAlternative: null,
      chosenOptions: [],
      exerciseResponses: [],
      currentDict: 1,
      prevImg: null,
      shuffledOptions: [],
      audio: null,
      audioBlob: null,
      listenIcon: require("../../../assets/img/eureka_icons/icono_escuchar.svg"),
      repeatIcon: require("../../../assets/img/eureka_icons/icono_repetiraudio.svg"),
      resumeIcon: require("../../../assets/img/eureka_icons/icono_comenzar.svg"),
      pauseIcon: require("../../../assets/img/eureka_icons/icono_reproduciendo.svg"),
    };
  },
  mounted() {
    this.shuffledOptions = this.shuffle(this.question.options);
    this.clearAnswers();
  },
  methods: {
    clearAnswers() {
      this.pondObject = {};
      this.audioBlob = null;
      this.audio = null;
      this.textResponse = "";
      this.chosenAlternative = null;
      this.chosenExerciseAlternative = null;
      this.dictations = [];
      this.chosenOptions = [];
      this.exerciseResponses = [];
      this.chosenExerciseAlternative = null;
      this.letterResponse = this.emptyLetter();
      this.newsResponse = this.emptyNews();
      this.prevImg = null;
    },
    shuffle: function (array) {
      let array_copy = [...array];
      while (array_copy.length && array_copy[0].id === array[0].id) {
        let currentIndex = array.length,
          randomIndex;

        // While there remain elements to shuffle...
        while (currentIndex != 0) {
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;

          // And swap it with the current element.
          [array_copy[currentIndex], array_copy[randomIndex]] = [
            array_copy[randomIndex],
            array_copy[currentIndex],
          ];
        }
      }
      return array_copy;
    },
    handleAudio(data) {
      console.log(data);
      this.audioBlob = data.blob;
      this.audio = data.url;
    },

    chooseOption(event, opt) {
      if (event) {
        this.chosenOptions.push(opt);
        // this.nextOpt()
      } else {
        var val = this.chosenOptions.filter((c) => c.id === opt.id)[0];
        this.chosenOptions.splice(this.chosenOptions.indexOf(val), 1);
        //  = this.chosenOptions.filter(c => c.id !== opt.id)
      }
    },
    altTogglePlayer(name) {
      if (this.nowPlaying == name) {
        this.$refs[`player-${this.nowPlaying}`][0]["currentTime"] = 0;
      }
      this.muteAllAudios();
      this.nowPlaying = name;
      if (
        this.$refs[`player-${name}`] &&
        this.$refs[`player-${name}`][0]["paused"]
      ) {
        this.$refs[`player-${name}`][0]["play"]();
      } else {
        this.$refs[`player-${name}`][0]["currentTime"] = 0;
      }
    },
    altPausePlayer(name) {
      if (
        this.$refs[`player-${name}`] &&
        this.$refs[`player-${name}`][0]["paused"]
      ) {
        this.$refs[`player-${name}`][0]["play"]();
      } else {
        this.$refs[`player-${name}`][0]["pause"]();
      }
    },
    togglePlayer(name) {
      console.log(this.$refs[`player-${name}`]);
      if (this.nowPlaying) {
        this.$refs[`player-${this.nowPlaying}`]["currentTime"] = 0;
        console.log("primer if");
      }
      this.muteAllAudios();
      this.nowPlaying = name;
      if (
        this.$refs[`player-${name}`] &&
        this.$refs[`player-${name}`]["paused"]
      ) {
        console.log("segundo if");

        this.$refs[`player-${name}`]["play"]();
      } else {
        console.log("else");
        this.$refs[`player-${name}`]["currentTime"] = 0;
      }
    },
    pausePlayer(name) {
      if (
        this.$refs[`player-${name}`] &&
        this.$refs[`player-${name}`]["paused"]
      ) {
        this.$refs[`player-${name}`]["play"]();
      } else {
        this.$refs[`player-${name}`]["pause"]();
      }
    },
    nextDict() {
      this.currentDict++;
      if (this.currentDict <= this.question.dictation) {
        this.$refs[`dyn-player-${this.currentDict}`][0]["play"]();
      }
    },

    emptyLetter() {
      return {
        city_date: "",
        greeting: "",
        body: "",
        goodbye: "",
        signature: "",
        postdata: "",
      };
    },
    emptyNews() {
      return {
        epigraph: "",
        title: "",
        subtitle: "",
        lead: "",
        news_body: "",
      };
    },
    getExerciseLetter: function (n) {
      var ordA = "a".charCodeAt(0);
      var ordZ = "z".charCodeAt(0);
      var len = ordZ - ordA + 1;

      var s = "";
      while (n >= 0) {
        s = String.fromCharCode((n % len) + ordA) + s;
        n = Math.floor(n / len) - 1;
      }
      return s;
    },

    getCol: function (question) {
      const COLS = {
        0: "",
        1: 12,
        2: 6,
        3: 4,
        4: 3,
        5: "",
        6: 2,
        7: "",
        8: "",
        9: "",
        10: "",
        11: "",
        12: 1,
      };
      return COLS[question.col_type];
    },
    getColAlternative: function (question) {
      const COLS = {
        0: 12,
        1: 12,
        2: 6,
        3: 4,
        4: 3,
        5: "",
        6: 2,
        7: "",
        8: "",
        9: "",
        10: "",
        11: "",
        12: 1,
      };
      return COLS[question.col_type];
    },
    getClassAlternative: function (alternative, question) {
      return (alternative.width != 0
        ? alternative.width
        : this.getColAlternative(question)) == 12
        ? "text-left"
        : "text-center";
    },
    getColAdditionalInstructionImage: function (question) {
      return question.additional_instruction.image_col
        ? question.additional_instruction.image_col
        : "3";
    },
    muteAllAudios() {
      let audios = document.querySelectorAll("audio");
      audios.forEach((audio) => {
        audio.pause();
      });
    },
  },
  computed: {
    questionID() {
      return this.question.id;
    },
  },
  watch: {
    questionID() {
      this.shuffledOptions = this.shuffle(this.question.options);
      this.getPreviousAnswer();
    },
    nowPlaying(newVal) {
      this.$emit("now-playing", newVal);
    },
  },
};
</script>
<style lang="scss">
.default-question-bg {
  background-color: #e3e8ff !important;
}

.default-question-number {
  color: #758bfd !important;
}

.default-border-color {
  border-color: #16c79a !important;
}

.modern-question-bg {
  background-color: #dcf0f9 !important;
}

.modern-question-number {
  color: #008cff !important;
}

.modern-border-color {
  border-color: #ffa001 !important;
}

.dream-question-bg {
  background-color: #f9d9f6 !important;
}

.dream-border-color {
  border-color: #48d6ed !important;
}

.dream-question-number {
  color: #ea5cd6 !important;
}

.calm-question-bg {
  background-color: #d6e8e5 !important;
}

.calm-question-number {
  border-color: #45b49b !important;
}

.calm-border-color {
  color: #b28346 !important;
}

.test-start {
  border: #f8b500 0px solid;
  border-radius: 10px;
}

.question-form {
  border: #f8b500 1px solid;
  margin: 0.5em auto !important;
  width: 100%;
}

.vue-audio-recorder {
  width: 55px !important;
  height: 55px !important;
}

.btn-recording {
  margin: 0.2em auto !important;
  margin-left: 10px !important;
  min-width: 30px;
  min-height: 30px;
}

.text-content {
  white-space: pre-wrap;
  word-wrap: break-word;
}

@media (min-width: 800px) {
  .btn-image {
    max-width: 200px !important;
    height: auto;
  }

  .question-img {
    max-width: 400px !important;
    height: auto;
  }
}

@media (max-width: 800px) {
  .btn-image {
    max-width: 95% !important;
    height: auto;
  }

  .question-img {
    max-width: 95% !important;
    height: auto;
  }
}

ol {
  list-style: none;
  counter-reset: item;
}
li {
  counter-increment: item;
  margin-bottom: 5px !important;
}
ol li::before {
  color: white;
  margin-right: 10px !important;
  content: counter(item);
  width: 1.5em;
  background: #758bfd;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  font-size: 8px;
  text-align: center;
}
.question-image {
  width: 100%;
}

.center-content {
  align-items: center;
  justify-content: center;
}
.question-image-w50 {
  width: 50%;
}
.question-image-w75 {
  width: 75%;
}
.question-image-w100 {
  width: 100%;
}
.question-image-w25 {
  width: 25%;
}
.question-image-100p {
  width: 100px;
}
.question-image-200p {
  width: 200px;
}
.question-image-300p {
  width: 300px;
}
.question-image-400p {
  width: 400px;
}
.question-image-500p {
  width: 500px;
}

@media screen and (min-width: 501px) {
  .responsive-question-number {
    font-size: 25px;
  }
  .responsive-question-content {
    font-size: 25px;
  }
  .responsive-alternative {
    font-size: 20pt;
  }
  .responsive-option {
    font-size: 20px;
  }
  .responsive-icon {
    width: 30px;
  }
  .responsive-opt-icon {
    width: 25px;
  }
  .responsive-additional-instruction {
    font-size: 20pt;
  }
}

@media screen and (max-width: 500px) {
  .responsive-question-number {
    font-size: 12px;
  }
  .responsive-question-content {
    font-size: 12px;
  }
  .responsive-instruction {
    font-size: 12px;
  }
  .responsive-alternative {
    font-size: 10px;
  }
  .responsive-option {
    font-size: 10px;
  }
  .responsive-icon {
    width: 22.5px;
  }
  .responsive-opt-icon {
    width: 20px;
  }
  .responsive-essay {
    font-size: 12px;
  }
  .responsive-additional-instruction {
    font-size: 12px;
  }
  .responsive-container {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
}
</style>
