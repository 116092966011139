<template>
  <b-modal
    centered
    size="xl"
    ref="component-modal-document-test"
    hide-footer
    hide-header
  >
    <DocumentTest 
      :school="school" 
      :year="year" 
      :modeModal="mode"
      :selected_student="selected_student"
      :selected_grade="selected_grade"
      @open-loading="$emit('open-loading')"
      @show-fudei="show_fudei"
      @delete-document="delete_document"
      @create-fudei="create_fudei"
      @restore-document="restore_document"
      @show-modal-upload-document="show_modal_upload_document"
      @archived-document="archived_document"
      @view-document="view_document"
    />
  </b-modal>
</template>
<script>
import DocumentTest from "@/components/redpie/miscellaneous/document-tests/DocumentTest";
export default {
  components: {
    DocumentTest,
  },
  data () {
    return {
			school: null,
      year: null,
      mode: null,
      selected_student: null,
      selected_grade: null,
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    open(school, year, mode, student, grade) {
      this.school = school;
      this.year = year;
      this.mode = mode;
      this.selected_student = student;
      this.selected_grade = grade;
      this.$refs["component-modal-document-test"].show();
    },
    show_modal_upload_document: function(data) {
      this.$emit("show-modal-upload-document", data);
    },
    show_fudei: function(fudei_id, sgdt_id, state) {
      this.$emit("show-fudei", fudei_id, sgdt_id, state);
    },
    create_fudei: function(sgdt_id) {
      this.$emit("create-fudei", sgdt_id);
    },
    view_document: function(data) {
      console.log('modal');
      this.$emit("view-document", data);
    },
    download_document: function (data) {
      this.$emit("download-document", data);
    },
    archived_document: function (data) {
      this.$emit("archived-document", data);
    },
    delete_document: function (data) {
      this.$emit("delete-document", data);
    },
    restore_document: function (data) {
      this.$emit("restore-document", data);
    },
  }
}
</script>
<style lang="scss">
.modal {
  height: 95%;
}
</style>