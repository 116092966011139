<template lang="">
  <div class="container">
    <h1>Prueba ICAP v. Chilena</h1>

    <div v-for="section in test" :key="section.label" class="section">
      <h3>{{ section.label }}</h3>
      <div
        v-for="question in section.questions"
        :key="question.var"
        class="question"
      >
        <label :for="question.var">{{ question.label }}</label>
        <input
          type="number"
          :id="question.var"
          v-model="answer.answers[question.var]"
          :min="question.min"
          :max="question.max"
          :required="question.required"
          class="input-field"
        />
      </div>
    </div>

    <button @click="send_test" class="submit-btn">Enviar</button>
  </div>
</template>

<script>
import redpieService from "../../../../services/redpieService";
import testService from "../../../../services/testService";

export default {
  props: ["test_id", "student_id", "previous_answer", "semester"],
  watch: {
    student_id() {
      this.answer.student_id = this.student_id;
    },
    test_id() {
      this.answer.test = this.test_id;
    },
    previous_answer: {
      handler(val) {
        this.$nextTick(() => {
          for (const [key, value] of Object.entries(val)) {
            this.answer.answers[key] = value;
          }
        });
      },
      deep: true,
    },
  },
  mounted() {
    if (this.previous_answer && Object.keys(this.previous_answer).length) {
      for (const [key, value] of Object.entries(this.previous_answer)) {
        this.$set(this.answer.answers, key, value);
      }
    }
  },
  data() {
    return {
      answer: {
        test: this.test_id,
        answers: this.defaultAnswers(),
        student_id: this.student_id,
      },
      // Estructura de la prueba con textos específicos para cada pregunta
      test: [
        {
          label: "Destrezas Motoras (DM)",
          questions: [
            {
              var: `dm_item1`,
              label: "1. Pasa objetos pequeños de una mano a otra",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
            {
              var: `dm_item2`,
              label:
                "2. Se sienta solo/a manteniendo la cabeza y la espalda derechas y firmes (sin apoyo) durante treinta segundos.",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
            {
              var: `dm_item3`,
              label:
                "3. Se mantiene de pie, al menos durante cinco segundos, apoyándose en muebles u otros objetos.",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
            {
              var: `dm_item4`,
              label:
                "4. Mete objetos pequeños en recipientes y los vuelva a sacar después.",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
            {
              var: `dm_item5`,
              label:
                "5. Hace rayas, marcas o dibujos, con lápiz o con pinturas, en una hoja de papel.",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
            {
              var: `dm_item6`,
              label:
                "6. Escribe su nombre copiándolo de un modelo.",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
            {
              var: `dm_item7`,
              label:
                "7. Levanta y lleva una bolsa con diferentes comestibles por lo menos a una distancia de seis metros y la deposita en el suelo.",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
          ],
        },
        {
          label: "Destrezas Sociales y Comunicativas (DSC)",
          questions: [
            {
              var: `dsc_item1`,
              label:
                "1. En actividades grupales, espera por lo menos dos minutos a que le llegue su turno (por ejemplo, espera su turno para patear una pelota o tomar un trago de agua).",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
            {
              var: `dsc_item2`,
              label:
                "2. Ofrece ayuda a otras personas (ejemplo: mantiene la puerta abierta para que pase una persona que tiene las manos ocupadas o recoge un objeto que se le ha caído a alguien).",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
            {
              var: `dsc_item3`,
              label:
                "3. Se comporta de una manera adecuada, sin llamar la atención de los demás, cuando está con sus amigos en lugares públicos (por ejemplo: cine, micro, centro comercial, etc.).",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
            {
              var: `dsc_item4`,
              label:
                "4. Cuenta de manera resumida una historia de forma que otra persona pueda entenderla (por ejemplo un programa de TV o una película del cine).",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
            {
              var: `dsc_item5`,
              label:
                "5. Recuerda o sabe cómo localizar números telefónicos y llama a sus amigos.",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
          ],
        },
        {
          label: "Destrezas de la Vida Personal (DVP)",
          questions: [
            {
              var: `dvp_item1`,
              label: "1. Traga alimentos blandos.",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
            {
              var: `dvp_item2`,
              label:
                "2. Permanece sin orinarse al menos durante tres horas.",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
            {
              var: `dvp_item3`,
              label:
                "3. Se quita el pantalón o la falda y la ropa interior.",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
            {
              var: `dvp_item4`,
              label:
                "4. Hace sus necesidades cuando se le sienta en WC de acuerdo a un horario regular o cuando se le lleva al baño.",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
            {
              var: `dvp_item5`,
              label:
                "5. Se pone camisetas o polerón, aunque sea al revés.",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
          ],
        },
        {
          label: "Destrezas de la Vida Diaria (DVD)",
          questions: [
            {
              var: `dvd_item1`,
              label:
                "1. Lava, seca y luego guarda los platos en su lugar.",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
            {
              var: `dvd_item2`,
              label:
                "2. Arregla su dormitorio, lo cual incluye guardar su ropa, cambiar las sábanas, quitar el polvo y barrer el suelo.",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
            {
              var: `dvd_item3`,
              label:
                "3. Prepara listas de compras de por lo menos seis productos para adquirir en una tienda de comestibles.",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
            {
              var: `dvd_item4`,
              label:
                "4. Carga y maneja una lavadora, utilizando la cantidad de detergente y el programa apropiado.",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
            {
              var: `dvd_item5`,
              label:
                "5. Planifica, prepara y sirve una comida completa para más de dos personas.",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
            {
              var: `dvd_item6`,
              label:
                "6. Realiza pequeñas reparaciones de su ropa, como coserse un botón, o encarga a la persona adecuada para que lo haga.",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
            {
              var: `dvd_item7`,
              label:
                "7. Maneja herramientas manuales eléctricas, potencialmente peligrosas, y aparatos de piezas móviles (por ejemplo: un taladro eléctrico, una batidora o una licuadora).",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
            {
              var: `dvd_item8`,
              label:
                "8. Administra su dinero de forma que cubra los gastos de, por lo menos, una semana (ocio, transporte y otras necesidades).",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
            {
              var: `dvd_item9`,
              label:
                "9. Rellena formularios y asiste a entrevistas de selección para buscar trabajo.",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
            {
              var: `dvd_item10`,
              label:
                "10. Efectúa pago de boletas (agua, luz, internet, etc.) antes de que venza el plazo.",
              type: "number",
              min: 0,
              max: 3,
              required: true,
            },
          ],
        },
      ],
    };
  },
  methods: {
    defaultAnswers() {
      let answers = {};
      // Inicializamos todos los campos con valor 0 por defecto
      for (let i = 1; i <= 7; i++) answers[`dm_item${i}`] = 0;
      for (let i = 1; i <= 5; i++) answers[`dsc_item${i}`] = 0;
      for (let i = 1; i <= 5; i++) answers[`dvp_item${i}`] = 0;
      for (let i = 1; i <= 10; i++) answers[`dvd_item${i}`] = 0;
      return answers;
    },
    submitTest() {
      // Lógica para enviar las respuestas
      console.log(this.answer);
    },
    send_test: function () {
      let test;
      this.answer["semester"] = this.semester;
      testService
        .send_test(this.answer)
        .then((data) => {
          test = data;

          redpieService.assignDocuments({
            test: this.answer.test,
            school: this.$store.state.login.school,
            year: this.$store.state.login.year,
            student_grade: this.answer.student_id,
          });
        })
        .then(
          () => {
            this.$emit("update-document");
            this.$emit("add-test", test);
          },
          () => {
            this.$toasted.error("Ha ocurrido un error al crear test");
          }
        );
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #fff3e0; /* Color de fondo amarillo claro */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #e65100; /* Naranjo oscuro */
}

.section {
  margin-bottom: 20px;
}

h3 {
  background-color: #ffb74d; /* Naranjo medio */
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.question {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

label {
  flex: 1;
  font-weight: bold;
  color: #e65100; /* Naranjo oscuro */
}

.input-field {
  width: 50px;
  text-align: center;
  border: 1px solid #e65100; /* Borde naranjo oscuro */
  border-radius: 5px;
  padding: 5px;
  background-color: #fff3e0; /* Fondo amarillo claro */
}

.submit-btn {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #ff9800; /* Naranjo fuerte */
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #fb8c00; /* Naranjo más oscuro */
}
</style>
