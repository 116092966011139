<template>
    <svg version="1.1" id="Layer_1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    :viewBox="viewbox"
    :width="width"
    :height="height"
    xml:space="preserve"
    :class="on ? 'custom-icon-button':''">
  

   <g v-if="on">
    <path class="st0-on" d="M6,29.9h20.9h20.9c5.6,0,8.2-6.9,4-10.5L45.5,14L30.8,1.5c-2.2-2-5.6-2-7.9,0L8.4,13.9l-6.3,5.4
	C-2.2,23,0.4,29.9,6,29.9z"/>
    </g>

   <g v-else>
    <path class="st0-off" d="M6,29.9h20.9h20.9c5.6,0,8.2-6.9,4-10.5L45.5,14L30.8,1.5c-2.2-2-5.6-2-7.9,0L8.4,13.9l-6.3,5.4
	C-2.2,23,0.4,29.9,6,29.9z"/>
  </g>

  

  </svg>
  </template>
  
  <script>
  export default {
      name: 'ArrowUpIcon',
      props: {
        viewbox: {
              type: String,
              default: "0 0 18 18"
        },
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },
          on:{
            type: Boolean,
            default: true
            } 
          },
   
  }
  </script>
  
<style scoped>
.st0-off{ 
    fill:#F3F3F3;
    }
.st0-on{ 
    fill:#F8B500;
    }
.custom-icon-button{
    cursor:pointer;
}
</style>