//init store

const state = {
  token: localStorage.getItem("token"),
  first_name: localStorage.getItem("first_name"),
  last_name: localStorage.getItem("last_name"),
  email: localStorage.getItem("email"),
  thumbnail: localStorage.getItem("thumbnail"),
  country: localStorage.getItem("country"),
  points: localStorage.getItem("points"),
  school: localStorage.getItem("school"),
  year: localStorage.getItem("year"),
  schoolLevel: localStorage.getItem("schoolLevel"),
  grade: localStorage.getItem("grade"),
  subtag: localStorage.getItem("subtag"),
  notifications: [],
  new_notifications: 0,
  missions: [],
  user_level: 0,
  currentExp: 0,
  nextLevelExp: 0,
  user_id: localStorage.getItem("user_id"),
  anonID: localStorage.getItem("anonID"),
  useMaterials: localStorage.getItem("useMaterials"),
  useAssisted: localStorage.getItem("useAssisted"),
  isAdmin: localStorage.getItem("isAdmin"),
  buy: false,
  isPaid: localStorage.getItem("isPaid"),
  socket: { isConnected: false },
  online: localStorage.getItem("isAdmin"),
};
const mutations = {
  setUser(state, payload) {
    state.token = payload.token;
    localStorage.setItem("token", payload.token);
    state.first_name = payload.first_name;
    localStorage.setItem("first_name", payload.first_name);
    state.last_name = payload.last_name;
    localStorage.setItem("last_name", payload.last_name);
    state.email = payload.email;
    localStorage.setItem("email", payload.email);
    state.thumbnail = payload.thumbnail;
    localStorage.setItem("thumbnail", payload.thumbnail);
    state.country = payload.country;
    localStorage.setItem("country", payload.country);
    state.points = payload.points;
    localStorage.setItem("points", payload.points);
    state.school = payload.school;
    localStorage.setItem("school", payload.school);
    state.user_id = payload.id;
    localStorage.setItem("user_id", payload.id);
    state.schoolLevel = payload.level;
    localStorage.setItem("schoolLevel", payload.school_level);
    state.isAdmin = payload.isAdmin;
    localStorage.setItem("isAdmin", payload.isAdmin);
    state.user_level = payload.level;
    localStorage.setItem("user_level", payload.level);
    state.currentExp = payload.current_level_exp;
    localStorage.setItem("currentExp", payload.current_level_exp);
    state.nextLevelExp = payload.next_level_experience;
    localStorage.setItem("nextLevelExp", payload.next_level_experience);
    state.isPaid = payload.isPaid;
    localStorage.setItem("isPaid", payload.isPaid);
  },
  setUserEmpty(state) {
    state.token = "";
    localStorage.setItem("token", "");
    state.first_name = "";
    localStorage.setItem("first_name", "");
    state.last_name = "";
    localStorage.setItem("last_name", "");
    state.email = "";
    localStorage.setItem("email", "");
    state.thumbnail = "";
    localStorage.setItem("thumbnail", "");
    state.country = 0;
    localStorage.setItem("country", 0);
    state.points = 0;
    localStorage.setItem("points", 0);
    state.school = undefined;
    localStorage.setItem("school", undefined);
    state.subtag = "";
    localStorage.setItem("subtag", "");
    state.schoolLevel = "";
    localStorage.setItem("schoolLevel", 0);
    state.user_level = 0;
    localStorage.setItem("user_level", 0);
    state.currentExp = 0;
    localStorage.setItem("currentExp", 0);
    state.nextLevelExp = 0;
    localStorage.setItem("nextLevelExp", 0);
    state.isAdmin = false;
    localStorage.setItem("isAdmin", false);
    state.useMaterials = false;
    localStorage.setItem("useMaterials", false);
    state.useAssisted = false;
    localStorage.setItem("useAssisted", false);
    state.isPaid = false;
    localStorage.setItem("isPaid", false);
    window.google.accounts.id.disableAutoSelect();
  },
  setOnline(state, online) {
    state.online = online;
    localStorage.setItem("online", online);
  },
  setPoints(state, payload) {
    state.points = payload.points;
    localStorage.setItem("points", payload.points);
  },
  setCountry(state, payload) {
    state.country = payload.country;
    localStorage.setItem("country", payload.country);
  },
  setSchool(state, school) {
    state.school = school;
    localStorage.setItem("school", school);
  },
  setYear(state, year) {
    state.year = year;
    localStorage.setItem("year", year);
  },
  setGrade(state, grade) {
    state.grade = grade;
    localStorage.setItem("grade", grade);
  },
  setSubtag(state, payload) {
    state.subtag = payload.subtag;
    localStorage.setItem("state", payload.subtag);
  },
  setNotifications(state, payload) {
    state.notifications = payload;
  },
  addNotification(state, payload) {
    state.notifications = [...payload, ...state.notifications];
  },
  pushNotification(state, payload) {
    state.notifications = [...state.notifications, ...payload];
  },
  setNewNotifications(state, count) {
    state.new_notifications = count;
  },
  setMissions(state, payload) {
    state.missions = payload;
  },
  setSchoolLevel(state, level) {
    state.schoolLevel = level;
    localStorage.setItem("schoolLevel", level);
  },
  setMaterials(state, useMaterials) {
    state.useMaterials = useMaterials;
    localStorage.setItem("useMaterials", useMaterials);
  },
  setAssisted(state, useAssisted) {
    state.useAssisted = useAssisted;
    localStorage.setItem("useAssisted", useAssisted);
  },
  setAnonID(state, ID) {
    state.anonID = ID;
    localStorage.setItem("anonID", ID);
  },
  changeBuy(state) {
    state.buy = !state.buy;
    localStorage.setItem("buy", state.buy);
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
