<template>
  <b-modal
    id="login-modal"
    ref="login-modal"
    centered
    hide-header
    hide-footer
    @hidden="$emit('hidden')"
  >
    <b-modal ref="message-modal" hide-footer hide-header no-close-on-backdrop>
      <b-container>
        <b-row>
          {{ message }}
        </b-row>
        <b-row>
          <b-img :src="saveAsImg" fluid />
        </b-row>
        <b-row>
          <b-col class="text-center">
            <b-button variant="primary" pill @click="closeAndUpdate">
              Entendido!
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <b-container class="container">
      <b-row>
        <b-col cols="12" sm="12" class="logo-right">
          <div class="image-right"></div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" sm="12" class="no-bottom">
          <p class="med-text text-center">
            Te damos la bienvenida a
            <br />
            <strong>utopie</strong>
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" sm="12" class="no-bottom">
          <b-row v-if="is_registering">
            <div v-if="manualRegister">
              <b-col cols="12" sm="12" class="no-bottom">
                <p class="text-center">
                  Llena los siguientes campos para crear tu cuenta
                </p>
              </b-col>
            </div>
          </b-row>
          <b-row v-else>
            <b-col cols="12" sm="12">
              <p class="text-center">Ingresa con tu cuenta</p>
            </b-col>
          </b-row>
          <b-form
            v-if="!is_registering"
            @submit="
              authenticate_password({ email: email, password: password })
            "
          >
            <b-col>
              <b-form-group label-for="dropdown-form-email">
                <b-form-input
                  v-on:keyup.enter="login({ email: email, password: password })"
                  type="email"
                  placeholder="Dirección de correo electrónico"
                  v-model="email"
                ></b-form-input>
              </b-form-group>
              <b-form-group label-for="dropdown-form-password">
                <b-form-input
                  v-on:keyup.enter="login({ email: email, password: password })"
                  type="password"
                  placeholder="Contraseña"
                  v-model="password"
                />
                <a href="/accounts/password_reset/" style="font-size: 13px"
                  >Olvidé mi contraseña</a
                >
              </b-form-group>
              <b-row>
                <b-col cols="12" sm="12" class="text-center">
                  <b-button
                    variant="primary"
                    @click="login({ email: email, password: password })"
                    v-if="!$store.state.login.token"
                    right
                    >Iniciar Sesión</b-button
                  >
                </b-col>
              </b-row>
              <b-row class="padd-top">
                <b-col cols="12" sm="12">
                  <p class="text-center">
                    <strong>o</strong>
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" class="text-center">
                  <div ref="google" />
                  <!-- <b-button @click="showPrompt"
                    size="sm"
                    variant="light"
                    class="my-2 my-sm-0"
                    block
                    style="color: black;"
                    ref="google"
                    v-if="!$store.state.login.token"
                  >
                    <img src="../../../assets/img/google__g__logo.svg" alt width="32" height="32"/>
                    Continuar con Google
                  </b-button> -->
                </b-col>
              </b-row>
            </b-col>
          </b-form>

          <b-form v-else>
            <div v-if="manualRegister">
              <b-form-group label-for="dropdown-form-r_first_name">
                <b-form-input
                  placeholder="Nombre"
                  v-on:keyup.enter="registerAux"
                  v-model="r_first_name"
                  :state="r_first_name.length > 0"
                ></b-form-input>
              </b-form-group>
              <b-form-group label-for="dropdown-form-r_last_name">
                <b-form-input
                  placeholder="Apellido"
                  v-on:keyup.enter="registerAux"
                  v-model="r_last_name"
                  :state="r_last_name.length > 0"
                ></b-form-input>
              </b-form-group>
              <b-form-group label-for="dropdown-form-r_email">
                <b-form-input
                  placeholder="Correo"
                  v-on:keyup.enter="registerAux"
                  type="email"
                  v-model="r_email"
                  :state="r_email.length > 0"
                ></b-form-input>
              </b-form-group>
              <b-form-group label-for="dropdown-form-r_password">
                <b-form-input
                  type="password"
                  v-on:keyup.enter="registerAux"
                  placeholder="Contraseña"
                  v-model="r_password"
                  :state="r_password.length > 0"
                ></b-form-input>
              </b-form-group>
              <b-form-group label-for="dropdown-form-r_password2">
                <b-form-input
                  type="password"
                  placeholder="Repetir contraseña"
                  v-on:keyup.enter="registerAux"
                  v-model="r_password2"
                  :state="r_password2.length > 0"
                ></b-form-input>
              </b-form-group>
              <b-row>
                <b-col cols="12" sm="12" class="text-center">
                  <b-button
                    variant="primary"
                    class="my-2 my-sm-0"
                    @click="registerAux"
                    >Registrarse</b-button
                  >
                </b-col>
              </b-row>
            </div>
            <div v-else>
              <b-row>
                <b-col cols="12" sm="12" class="text-center">
                  <div ref="google" />
                  <!-- <b-button @click="showPrompt"
                    size="sm"
                    variant="light"
                    class="my-2 my-sm-0"
                    block
                    style="color: black;"
                    v-if="!$store.state.login.token"
                    ref="google"
                  >
                    <img src="../../../assets/img/google__g__logo.svg" alt width="32" height="32"/>
                    Continuar con Google
                  </b-button> -->
                </b-col>
              </b-row>
              <b-row class="padd-top">
                <b-col cols="12" sm="12">
                  <p class="text-center">
                    <strong>o</strong>
                  </p>
                </b-col>
              </b-row>
              <div class="w-100 text-center">
                <b-button variant="primary" pill @click="manualRegister = true">
                  Registrate con tus datos
                </b-button>
              </div>
              <b-row class="padd-top">
                <b-col cols="12" sm="12">
                  <p class="text-center">
                    <strong>o</strong>
                  </p>
                </b-col>
              </b-row>
              <div class="w-100 text-center">
                <b-button
                  variant="secondary"
                  pill
                  @click="is_registering = false"
                >
                  Inicia sesión!
                </b-button>
              </div>
            </div>
          </b-form>
          <!-- <b-row>
            <b-col cols="12" sm="12" class="text-center">
              <p class="bottom-text padd-top">
                Al registrarte, aceptas las
                <strong>Condiciones del servicio</strong>
                <br />y las
                <strong>Políticas de privacidad</strong> de Utopie.
              </p>
            </b-col>
          </b-row> -->
          <!-- <b-row>
            <b-col cols="12" sm="12" class="text-center">
              <b-container v-if="!is_registering">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="my-2 my-sm-0"
                  @click.stop.prevent="set_register(true)"
                >
                  <div class="bottom-text" style="font-size: 20px;"><strong>Aún no estás en Utopie? Regístrate!</strong></div>
                </b-button>
              </b-container>
              <b-container v-else>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="my-2 my-sm-0 mr-1"
                  @click.stop.prevent="set_register(false)"
                  v-if="!$store.state.login.token"
                  right
                >
                  <div class="bottom-text" style="font-size: 20px;"><strong>Ya tienes cuenta? Inicia sesión!</strong></div>
                </b-button>
              </b-container>
            </b-col>
          </b-row> -->
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import { authMixin } from "../../../mixins/authMixin";
import auth from "@/services/authService";
import userService from "@/services/userService";

export default {
  mixins: [authMixin],
  data: function () {
    return {
      manualRegister: false,
      email: "",
      password: "",
      r_email: "",
      r_password: "",
      r_password2: "",
      r_first_name: "",
      r_last_name: "",
      is_registering: false,
      auth2: null,
      clientID: process.env.VUE_APP_GOOGLE_ID,
      message: "",
      saveAsImg: require("../../../assets/img/save_as.jpg"),
    };
  },
  created() {},
  watch: {
    is_registering() {
      this.start2();
    },
  },
  methods: {
    closeAndUpdate() {
      userService.alertMessageSeen();
      this.$refs["message-modal"].hide();
    },
    decodeJWT(token) {
      return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
    },
    handleCredentialResponse(data) {
      this.authGoogle(this.decodeJWT(data.credential));
    },
    authGoogle(googleUser) {
      var googleID = googleUser.sub;
      var user = {
        email: googleUser.email,
        given_name: googleUser.given_name,
        family_name: googleUser.family_name,
        picture: googleUser.picture,
      };
      var payload = {
        user: user,
        token: googleID,
        school_id: this.$store.state.login.school,
      };
      auth.authenticateGoogle(payload).then(
        (data) => {
          if (data.token) {
            this.$store.commit("login/setUser", data);
            this.close();
            if (data.message) {
              this.message = data.message;
              this.$refs["message-modal"].show();
            }
          } else {
            this.$toasted.error("Revisa tu correo o contraseña.");
            this.$store.commit("login/setUserEmpty");
            // TODO: add form error
          }
          // eslint-disable-next-line
        },
        (error) => {
          if (error.response.data.message)
            this.$toasted.error(error.response.data.message);
          else {
            console.log(error);
            this.$toasted.error(
              "Ha ocurrido un error en el servidor, intenta más tarde."
            );
            this.$store.commit("login/setUserEmpty");
          }
        }
      );
    },
    registerAux: function () {
      this.register({
        first_name: this.r_first_name,
        last_name: this.r_last_name,
        email: this.r_email,
        password1: this.r_password,
        password2: this.r_password2,
        school_id: this.$store.state.login.school,
      });
    },
    clean: function () {
      this.email = "";
      this.password = "";
      this.r_email = "";
      this.r_password = "";
      this.r_password2 = "";
      this.r_first_name = "";
      this.r_last_name = "";
      this.manualRegister = false;
    },
    login: function (payload) {
      this.authenticate_password(payload, this);
    },
    deleteAllCookies() {
      const cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },
    showPrompt() {
      window.google.accounts.id.prompt();
    },
    start2: async function () {
      try {
        window.google.accounts.id.initialize({
          client_id: process.env.VUE_APP_GOOGLE_ID,
          callback: this.handleCredentialResponse,
          cancel_on_tap_outside: false,
          ux_mode: "popup",
        });
        await new Promise((r) => setTimeout(r, 1000));
        window.google.accounts.id.renderButton(this.$refs["google"], {
          text: "signin_with", // or 'signup_with' | 'continue_with' | 'signin'
          size: "large", // or 'small' | 'medium'
          width: 400, // max width 400
          theme: "outline", // or 'filled_black' |  'filled_blue'
          logo_alignment: "left", // or 'center'
          shape: "pill",
          locale: "es",
        });
        // window.google.accounts.id.prompt((notification) => {
        //   if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
        //     // continue with another identity provider.
        //   }
        // });
      } catch (error) {
        console.log(error);
      }
    },
    open: function (register) {
      // if (!this.$store.state.login.token) {
      //   this.deleteAllCookies();
      // }
      this.clean();
      if (register) {
        this.is_registering = true;
      } else {
        this.is_registering = false;
      }
      this.$refs["login-modal"].show();
      this.start2();
    },
    close: function () {
      this.$refs["login-modal"].hide();
    },
    authenticate_facebook: function () {
      this.authenticate("facebook", this);
    },
    authenticate_g: function () {
      this.authenticate_google(this);
    },
    set_register: function (register) {
      this.is_registering = register;
      // TODO reset errors
    },
    register: function (payload) {
      this.register_user(payload, this);
    },
  },
};
</script>

<style lang="scss">
.form-control:focus {
  border-color: $primary !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset,
    0px 0px 8px rgba(255, 100, 255, 0.5) !important;
}
.bottom-text {
  color: gray;
  font-size: 13px;
}
.padd-top {
  padding-top: 10px !important;
}
#customBtn {
  display: inline-block;
  background: white;
  color: #444;
  width: 190px;
  border-radius: 5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  white-space: nowrap;
}
#customBtn:hover {
  cursor: pointer;
}
span.label {
  font-family: serif;
  font-weight: normal;
}
span.icon {
  background: url("/identity/sign-in/g-normal.png") transparent 5px 50%
    no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}
span.buttonText {
  display: inline-block;
  vertical-align: middle;
  padding-left: 42px;
  padding-right: 42px;
  font-size: 14px;
  font-weight: bold;
}
</style>
