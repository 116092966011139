<template>
  <div>
    <b-modal ref="delete-material-modal" hide-header centered size="lg">
      <b-container>
        <b-row>
          <b-col class="text-center">
            ¿Está seguro que desea enviar este material a la papelera?
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <b-row>
          <b-col class="text-center">
            <b-button
              variant="danger"
              @click="
                materialToDelete = '';
                $refs['delete-material-modal'].hide();
              "
              pill
            >
              Cancelar
            </b-button>
          </b-col>
          <b-col class="text-center">
            <b-button variant="warning" @click="deleteMaterial()" pill>
              Eliminar
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <b-row class="text-left mx-2 p-2">
      <b-col>
        <b-select v-model="profession">
          <b-form-select-option :value="''">Profesión...</b-form-select-option>
          <b-form-select-option
            v-for="(role, ir) in roles.filter((r) => [6].includes(r[0]))"
            :key="ir + '-role'"
            :value="role[0]"
          >
            {{ role[1] }}
          </b-form-select-option>
        </b-select>
      </b-col>
      <b-col>
        <b-select v-model="subject">
          <b-form-select-option :value="''">Asignatura...</b-form-select-option>
          <b-form-select-option
            v-for="(subject, is) in subjects.filter((s) =>
              [7, 29].includes(s[0])
            )"
            :key="is + '-subject'"
            :value="subject[0]"
          >
            {{ subject[1] }}
          </b-form-select-option>
        </b-select>
      </b-col>
      <b-col>
        <b-select v-model="grade">
          <b-form-select-option :value="''">Nivel...</b-form-select-option>
          <b-form-select-option
            v-for="(grade, ig) in grades.filter((g) =>
              [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].includes(g[0])
            )"
            :key="ig + '-grade'"
            :value="grade[0]"
          >
            {{ grade[1] }}
          </b-form-select-option>
        </b-select>
      </b-col>
    </b-row>
    <b-row class="text-right mx-2 p-2">
      <b-col v-if="paperBin">
        <b-form-checkbox
          v-model="showRecycle"
          name="recycle"
          value="active"
          unchecked-value="not_active"
        >
          Mostrar materiales en papelera
        </b-form-checkbox>
      </b-col>
      <b-col :cols="paperBin ? 1 : 12" class="text-right">
        <b-button
          variant="unknown"
          size="sm"
          class="mx-auto"
          v-b-tooltip.hover
          title="Papelera"
          @click="paperBin = !paperBin"
        >
          <v-icon name="trash-alt" class="text-danger" />
        </b-button>
      </b-col>
    </b-row>
    <b-row class="text-left mx-2">
      <b-col cols="3" class="align-middle cursor-pointer">
        <p class="h6 w-100" @click="$emit('create-new-material')">
          Crear nuevo material
          <v-icon name="plus-circle" scale="1.2" style="cursor: pointer" />
        </p>
      </b-col>
    </b-row>
    <hr />
    <b-row class="text-left mx-2 p-2 w-100">
      <b-col cols="12" class="mx-0 px-0">
        <b-container class="m-0 p-0">
          <b-row class="" v-if="!loadingMaterials">
            <b-col v-for="material in materials" :key="material.id" cols="6">
              <material-row-component
                class="mb-3"
                :material="material"
                :paperBin="paperBin"
                mode="material-list"
                @open-material="$emit('open-material', material.id)"
                @apply-material="$emit('apply-material', material.id)"
                @delete-material="deleteMaterialModal(material.id)"
              />

              <hr />
            </b-col>
          </b-row>

          <b-row v-else>
            <b-col class="text-center">
              <b-spinner variant="primary" label="Spinning"></b-spinner>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row v-if="pages > 1">
      <b-col class="text-center">
        <b-button-group>
          <b-button
            variant="outline-info"
            v-if="page > 1"
            @click="page = page - 1"
          >
            &#60;
          </b-button>
          <b-button
            variant="outline-info"
            :pressed="page === 1"
            @click="page = 1"
          >
            1
          </b-button>
          <b-button variant="outline-info" v-if="page - pageRange / 2 > 2">
            ...</b-button
          >
          <b-button
            v-for="(p, index) in pagesArray.filter((p) => {
              return (
                1 < p &&
                p < pages &&
                ((pageRange < page &&
                  page - pageRange / 2 <= p &&
                  p <= page + pageRange / 2) ||
                  (pageRange >= page && p <= pageRange + 1))
              );
            })"
            :key="'page-' + index"
            :pressed="p === page"
            @click="page = p"
            variant="outline-info"
          >
            {{ p }}
          </b-button>
          <b-button
            variant="outline-info"
            v-if="pages - 1 > page + pageRange / 2"
          >
            ...</b-button
          >
          <b-button
            variant="outline-info"
            :pressed="page === pages"
            @click="page = pages"
          >
            {{ pages }}</b-button
          >
          <b-button
            variant="outline-info"
            v-if="page < pages"
            @click="page = page + 1"
          >
            &#62;
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import customMaterialService from "@/services/customMaterialService";
import MaterialRowComponent from "@/components/custom-materials/miscellaneous/MaterialRowComponent";
import informationService from "@/services/informationService";

export default {
  name: "MaterialListComponent",
  components: {
    MaterialRowComponent,
  },
  data() {
    return {
      materials: [],
      perPage: 30,
      page: 1,
      pages: 1,
      order: "-created_at",
      profession: "",
      subject: "",
      grade: "",
      loadingMaterials: false,
      paperBin: false,
      pageRange: 6,
      roles: [],
      grades: [],
      subjects: [],
      showRecycle: "not_active",
      materialToDelete: "",
    };
  },
  watch: {
    profession() {
      this.page = 1;
      this.getFilteredMaterials();
    },

    subject() {
      this.page = 1;
      this.getFilteredMaterials();
    },

    grade() {
      this.page = 1;
      this.getFilteredMaterials();
    },
    showRecycle() {
      this.getPaginatedMaterials();
    },
    page() {
      this.getPaginatedMaterials();
    },
  },
  computed: {
    pagesArray() {
      return Array.from({ length: this.pages }, (_, i) => i + 1);
    },
  },
  mounted() {
    this.getStorageFilters();

    this.getPaginatedMaterials();
    this.getOptions();
  },
  methods: {
    getStorageFilters() {
      this.profession = localStorage.profession ? localStorage.profession : 6;

      this.subject = localStorage.subject ? localStorage.subject : "";
      this.grade = localStorage.grade ? localStorage.grade : "";
    },
    getPaginatedMaterials() {
      this.loadingMaterials = true;
      customMaterialService
        .getPaginatedMaterials(
          this.$store.state.login.school,
          this.page,
          this.perPage,
          this.order,
          this.profession,
          this.subject,
          this.grade,
          this.showRecycle
        )
        .then((data) => {
          this.materials = data.materials;
          this.pages = data.pages;
          this.loadingMaterials = false;
        })
        .catch(() =>
          this.$toasted.error("Ocurrió un problema al traer tus materiales")
        );
    },
    getFilteredMaterials() {
      localStorage.profession = this.profession;
      localStorage.subject = this.subject;
      localStorage.grade = this.grade;

      if (this.page === 1) {
        this.getPaginatedMaterials();
      } else {
        this.page = 1;
      }
    },
    getOptions() {
      informationService.getCardSubjectsValues().then((data) => {
        this.subjects = data;
      });
      informationService.getPieGrades().then((data) => {
        this.grades = data.grades;
      }),
        informationService.getMaterialsRoles().then((data) => {
          this.roles = data.roles;
        });
    },
    deleteMaterialModal(materialId) {
      this.materialToDelete = materialId;
      this.$refs["delete-material-modal"].show();
    },
    deleteMaterial() {
      customMaterialService
        .deleteMaterial(this.materialToDelete)
        .then(() => {
          this.getPaginatedMaterials();
          this.materialToDelete = "";
          this.$toasted.success("Material movido a la papelera");
          this.$refs["delete-material-modal"].hide();
        })
        .catch(() => this.$toasted.error("No se pudo eliminar el material"));
    },
  },
};
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
