<template>
  <tbody>
    <td class="group-title-value">
      <div class="title-value border-ultralight">
        <span class="title-value-span">
          {{ item.student.first_name }}
          {{ item.student.last_name }}
        </span>
      </div>
    </td>
    <td class="group-title-value">
      <div class="title-value border-ultralight">
        <multiselect
          v-model="item.diagnostic"
          :options="diagnosesList"
          :multiple="false"
          group-values="diagnoses"
          group-label="label"
          :group-select="false"
          placeholder="Seleccionar diagnóstico"
          deselectLabel="Presiona INTRO para remover"
          track-by="name"
          label="name"
        >
          <span slot="noResult">No se ha encontrado ningún diagnóstico</span>
        </multiselect>
      </div>
    </td>
    <td class="group-title-value" v-if="item.diagnostic.name != 'Nada'">
      <div class="title-value border-ultralight">
        <b-form-select
          v-model="item.student.pie"
          :options="pieOptions"
          size="sm"
        />
      </div>
    </td>
    <td>
      <!-- <b-button variant="success" @click="ok">
        ENVIAR!
      </b-button> -->
    </td>
  </tbody>
</template>

<script>
import redpieService from "../../../services/redpieService";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    diagnosesList: {
      required: true,
    },
    subgroup: {
      type: Object,
      required: true,
    },
  },
  data() {
    const pieOptions = [
      { value: 0, text: "Sobrecupo" },
      { value: 1, text: "Postula" },
      { value: 2, text: "Continúa" },
      { value: 3, text: "Egresa" },
      { value: 4, text: "Retirado" },
      { value: 4, text: "Otro" },
    ];
    return {
      pieOptions: pieOptions,
    };
  },
  computed: {
    diagnostic: function() {
      return this.item.diagnostic;
    },
    pie: function() {
      return this.item.student.pie;
    },
  },
  watch: {
    pie: function() {
      redpieService.updateStudent(this.item.student.id, this.item.student);
    },
    diagnostic: function() {
      redpieService.updateDiagnostic(this.item.student.id, {
        "student_id": this.item.student.id,
        "grade_id": this.subgroup.grade_id,
        "diagnostic": this.diagnostic.code,
        
      })
      this.$emit("reload-student")
      this.$emit("reload-grades")
    }
  },
  methods: {
  },
  created() {},
};
</script>
<style lang="scss">
.special-table {
  width: 100%;
  background-color: white;

  table {
    width: 100%;
    font-size: 0.7em;
    text-align: center;

    button {
      font-size: 1em;
      text-transform: uppercase;
      font-weight: bold;
    }

    .subgroup-label {
      text-align: left;
      font-size: 1em;
      font-weight: bold;

      td {
        padding: 2px 6px;
      }
    }

    .subgroup-item {
      td {
        padding: 4px 2px;
      }
    }

    .group-title-value {
      width: 256px;
      font-size: 1.4em;
      font-weight: bold;
      cursor: pointer;

      .title-value {
        padding: 0;
        height: 48px !important;
        line-height: 48px !important;
        border: solid 2px;
        border-radius: 24px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        span {
          font-size: 14px;
          line-height: normal;
          padding: 0 12px;
        }

        button {
          height: 48px;
          font-size: 0.65em;
          text-transform: unset;
          border-radius: 24px !important;
          color: white;
          line-height: 1;
        }
        button:first-child {
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }
        button:last-child {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      }
      .title-value > span.title-value-span {
        display: initial;
      }
      .title-value > div.title-value-buttons {
        display: none;
        width: 100%;

        .unique-button {
          border-radius: 24px !important;
          width: 100% !important;
        }
      }
      .title-value:hover > span.title-value-span {
        display: none;
      }
      .title-value:hover > div.title-value-buttons {
        display: initial;
      }
    }
  }

  .group {
    hr {
      height: 4px;
      margin: 0;
    }

    .group-title button {
      font-weight: bold !important;
      font-size: 0.9em;

      span {
        margin-left: 12px;
      }
    }
  }

  .item-additional-buttons {
    cursor: pointer;
  }
}
</style>
