<template>
  <div style="height: 100%">
    <div class="m-2 p-2 text-center">
      <transition name="slide-fade" mode="out-in">
        <component
          :is="view"
          class="w-100"
          ref="main"
          :inMaterial="material"
          :materialID="materialID"
          :tags="tags"
          :mode="mode"
          :creating="creating"
          :editing="editing"
          :application="application"
          :selectedLink="appLink"
          :selectedApplication="appID"
          :selectedLevel="level"
          :selectedGrade="grade"
          :levels="levels"
          :key="componentKey"
          @create-new-material="createMaterial"
          @open-material="openMaterial"
          @back-to-list="backToList"
          @preview-material="previewMaterial"
          @back-to-creation="backToCreation"
          @create-application="createApplication"
          @clear-material="material = {}"
          @response-admin="responseAdmin"
          @back-to-application-admin="openApplicationAdmin"
          @open-application-status="openApplication"
          @open-created-material="openCreatedMaterial"
        ></component>
      </transition>
    </div>
  </div>
</template>

<script>
import InteractiveMaterialsTabs from "@/components/custom-materials/miscellaneous/InteractiveMaterialsTabs";
import CreateMaterial from "@/components/custom-materials/miscellaneous/CreateMaterial";
import MaterialsStatusIndex from "@/components/custom-materials/pages/MaterialsStatusIndex";
import MaterialPreview from "@/components/custom-materials/miscellaneous/MaterialPreview";
import MaterialResponseAdmin from "@/components/custom-materials/miscellaneous/MaterialResponseAdmin";
import MaterialApplicationsAdmin from "@/components/custom-materials/miscellaneous/MaterialApplicationsAdmin";
// import materialDocumentSocket from "@/mixins/materialDocumentSocket";

export default {
  name: "CustomMaterialIndex",
  components: {
    InteractiveMaterialsTabs,
    CreateMaterial,
    MaterialsStatusIndex,
    MaterialPreview,
    MaterialResponseAdmin,
    MaterialApplicationsAdmin,
  },
  props: {
    requiredView: {
      type: String,
      required: false,
    },
  },
  mounted() {
    if (this.requiredView == "create_material") {
      this.createMaterial();
    }
  },
  // mixins: [materialDocumentSocket],
  data() {
    return {
      view: InteractiveMaterialsTabs,
      material: {},
      application: null,
      appLink: "",
      level: 0,
      mode: "",
      creating: false,
      editing: false,
      tags: [],
      appID: null,
      levels: {},
      grade: {},
      materialID: "",
      componentKey: 0,
    };
  },
  created() {
    // this.getTags();
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    createApplication(material) {
      this.mode = "openApp";
      this.material = material;
      this.view = InteractiveMaterialsTabs;
      // this.view.openApplicationMaterial(material);
      this.$emit("switch-return-button");
    },
    createMaterial() {
      this.material = null;
      this.materialID = "";
      this.mode = "creating";
      this.view = CreateMaterial;
      this.$emit("switch-return-button");
    },
    openMaterial(materialID) {
      this.materialID = materialID;
      this.mode = "editing";
      this.view = CreateMaterial;
      this.$emit("switch-return-button");
    },
    backToList(mode = "materials") {
      this.material = null;
      this.materialID = "";
      this.mode = mode;
      this.view = InteractiveMaterialsTabs;
      this.$emit("switch-return-button");
    },
    goToResults(material) {
      this.material = material;
      // this.view = TestResults
    },
    previewMaterial(material, mode) {
      this.material = material;
      this.mode = mode;
      this.view = MaterialPreview;
    },
    backToCreation(material) {
      this.material = material;
      this.view = CreateMaterial;
    },
    responseAdmin(link, levels, level, grade) {
      this.appLink = link;
      this.levels = levels;
      // this.application = app;
      this.level = level;
      this.grade = grade;
      this.view = MaterialResponseAdmin;
    },
    openApplicationAdmin() {
      this.mode = "openApp";
      this.view = MaterialApplicationsAdmin;

      // this.$refs["main"].openApplication(this.application);
    },
    openApplication(app) {
      this.application = app;
      this.appID = app.id;
      this.joinApplication(this.appID);
      this.view = MaterialApplicationsAdmin;
      this.$emit("switch-return-button");
    },
    openCreatedMaterial(materialID) {
      this.materialID = materialID;
      this.mode = "editing";
      this.view = CreateMaterial;
      this.forceRerender();
    },
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to,
.slide-fade-leave-active {
  transform: translateX(20px);
  opacity: 0;
}
</style>
