<template>
  <div>
    <b-container class="services" fluid="true" v-if="route==='redpie'">
        <b-row class="text-center">
          <span class="mx-auto h4 text-warning font-weight-bold mt-4">¡Todo lo que necesitas!</span>
        </b-row>
        <b-row class="mx-lg-1 d-none d-lg-inline-flex">
          <b-col lg="3" v-for="service in services" :key="service.id" class="px-lg-2 py-lg-1 p-sm-1">
            <b-card class="service text-center m-2 p-0" size="sm">
              <b-row><b-img class="mx-auto d-block" :src="service.img" height="90"></b-img></b-row>
              <b-row><span class="text-card mx-auto">{{service.name}}</span></b-row>
              <p class="text-muted"><small >{{service.description}}</small></p>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="d-block mx-5 d-lg-none">
          <carousel
            class="mx-5"
            :per-page="1"
            :mouse-drag="true"
            :navigationEnabled="true"
            :paginationEnabled="false"
            :autoplay="true"
            :autoplayTimeout="5000"
          >
            <slide
              v-for="service in services" 
              :key="service.id"
            >
              <b-card class="service text-center m-2 p-0 w-100" size="sm">
                <b-row><b-img class="mx-auto d-block" :src="service.img" height="90"></b-img></b-row>
                <b-row><span class="text-card mx-auto">{{service.name}}</span></b-row>
                <p class="text-muted"><small >{{service.description}}</small></p>
              </b-card>
            </slide>
          </carousel>
        </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "OurServices",
  props: {
    route: {
      type: String,
      required: false
    }
  },
  data(){
    return {
      services: [
        {
          id: 1,
          name: 'FUDEI',
          description: 'Trabaja el fudei SIN compartir la clave de comunidad escolar directamente desde UTOPIE! Sin errores ni problemas para guardar Trabajo simultáneamente con todo tu equipo, online y con autoguardado, guardamos datos para que no tengas que volver a escribirlos y te presentamos sugerencias para que avances más rápido.',
          img: require('../../../assets/img/sales/file-green.svg'),
        },{
          id: 2,
          name: 'Informes Psicológicos',
          description: 'Trabaja con los formatos de TU colegio, autocompletaremos los datos del estudiante, curso y establecimiento. También puedes corregir de forma automática las pruebas de Wisc III, Wisc V, Wais IV e ICAP. Ingresando los puntajes brutos o estándar el sistema, puedes obtener en tu informe el análisis cuantitativo, discrepancias, fortalezas, debilidades, etc.',
          img: require('../../../assets/img/sales/file-blue.svg'),
        },{
          id: 3,
          name: 'Informes Psicopedagógicos',
          description: 'Trabajando en el formato de TU colegio autocompletaremos varios datos. Así mismo podrás corregir de forma automática las pruebas de Evalúa 2.0, 3.0 y 4.0, Evamat, y CLPT Ingresando los puntajes. El sistema interpreta automáticamente los resultados y entrega un análisis que puedes complementar con nuestro banco de sugerencias o escribiendo directamente en el informe.',
          img: require('../../../assets/img/sales/file-red.svg'),
        },{
          id: 4,
          name: 'Informes Fonoaudiológicos',
          description: 'Trabajando con el formato de TU colegio autocompletaremos varios datos. Así mismo podrás corregir de forma automática las pruebas de STSG Expresivo y Receptivo, TEPROSIF-R, TECAL, IDTEL y PEFE, ingresando aciertos, errores, procesos de simplificación y puntajes. El sistema entregará un análisis en tu informe.',
          img: require('../../../assets/img/sales/file-yellow.svg'),
        },{
          id: 5,
          name: 'Monitoreo de los Procesos',
          description: 'Podrás revisar el estado de progreso de todos los documentos del colegio, permitiendo planificar los procesos y el trabajo de forma efectiva, así mismo se podrá filtrar de manera rápida las actividades que realizan día a día todos los profesionales del equipo PIE o escuela especial.',
          img: require('../../../assets/img/sales/graph.svg'),
        },{
          id: 6,
          name: '¡Trabaja tus Informes!',
          description: 'Utopie es el único sistema en el mercado que no te obliga a utilizar un formato predeterminado de informe, sino que permite que trabajes en línea y simultáneamente con todo tu equipo tus propios formatos! La información se guarda de forma automática, y  ofrecemos sugerencias de párrafos tipo para acelerar el llenado.',
          img: require('../../../assets/img/sales/file-colors.svg'),
        },{
          id: 7,
          name: '¡Todos los archivos del programa, como si estuvieran en un solo computador!',
          description: 'Podrás crear carpetas y subcarpetas compartidas con tu equipo, para resguardar todos tus materiales y archivos importantes, ¡organizándolos por curso y profesional!',
          img: require("../../../assets/img/sales/folder.svg")
        },{
          id: 15,
          name: 'Planificaciones DUA',
          description: 'Podrás trabajar en línea y de manera simultánea las planificaciones de cada curso. Encontrarás un formato amigable y completo, siguiendo los lineamientos del decreto 83, o también podrás cargar tu propio formato y utilizarlo en conjunto a todo equipo, teniendo acceso a todos los objetivos de aprendizaje.',
          img: require('../../../assets/img/sales/file-blue.svg'),
        },{
          id: 11,
          name: 'PACI',
          description: 'Puedes trabajar un formato amigable de PACI directamente relacionado con los puntos obligatorios que solicita el decreto 83 (o proponer tu propio formato). El sistema te va a facilitar los objetivos de aprendizaje, habilidades y actitudes. Así mismo te ofrecerá sugerencias de adecuaciones curriculares y otras informaciones útiles que te permitirán completar el informe sin errores muy rápidamente.',
          img: require('../../../assets/img/sales/file-green.svg'),
        },{
          id: 12,
          name: 'PAI - Estado de Avance',
          description: 'Puedes trabajar Tus propios formatos de informes de PAI y  Estado de Avance, en ellos cargaremos de forma automática todos los datos de identificación. Así mismo tendrás acceso rápido a todos los objetivos de aprendizaje, con su priorización, actitudes e indicadores de evaluación, así como sugerencias que te permitirán completarlo en forma simultánea con tu equipo de manera veloz',
          img: require('../../../assets/img/sales/file-red.svg'),
        },{
          id: 13,
          name: 'FU de reevaluación',
          description: 'En Utopie puedes trabajar todos los formatos de Fu de reevaluación totalmente online, simultáneo con todo tu equipo y con autoguardado de la información. Adicionalmente la plataforma completará automáticamente los datos del equipo de aula, establecimiento y estudiante. Recibirás sugerencias para completar el informe de manera muy rápida.',
          img: require('../../../assets/img/sales/file-yellow.svg'),
        },{
          id: 14,
          name: 'Banco de materiales',
          description: 'Utopie cuenta con un banco de materiales ordenados por curso, diagnóstico, tipo de material, función que permite trabajar y profesión, de forma que puedas escoger los que más te gusten y usarlos para trabajar con tus estudiantes',
          img: require('../../../assets/img/sales/image-red.svg'),
        },{
          id: 16,
          name: 'Actas',
          description: 'Puedes trabajar y guardar todas las actas de todos tus cursos, encontrarás en el sitio varias plantillas amigables o podrás cargar las que tu necesites',
          img: require('../../../assets/img/sales/file-green.svg'),
        },{
          id: 17,
          name: 'Bitácoras',
          description: 'Guarda un respaldo del trabajo con los estudiantes, de las reuniones de equipo y citaciones de apoderados en un amigable formato de bitácoras ordenadas por curso. También podrás cargar el propio formato de tu colegio',
          img: require('../../../assets/img/sales/file-green.svg'),
        },{
          id: 18,
          name: 'Cápsulas',
          description: 'Guarda todas las cápsulas de asignatura y de trabajo NEE que desarrolles y ordénalas por curso. Podrás guardar los links de YouTube y Los videos se reproducirán desde la plataforma. Quedando ordenados por curso y disponibles para todo el equipo',
          img: require('../../../assets/img/sales/file-video.svg'),
        },{
          id: 19,
          name: 'Registro de Actividades',
          description: 'Coordinadores, Directores, Jefes UTP y sostenedores, podrán revisar en detalle toda la actividad de sus equipos de forma ordenada, pudiendo monitorear los procesos en detalle y planificar las actividades.',
          img: require('../../../assets/img/sales/bell.svg'),
        },{
          id: 21,
          name: 'Horarios de profesionales',
          description: 'Coordinadoras(es) y sus equipos podrán realizar en línea sus horarios en un amigable formato. El sistema revisará de forma automática que en cada curso se cumplan con las horas exigidas de apoyo, planificación y apoyo excepcional',
          img: require('../../../assets/img/sales/calendar-check.svg'),
        },{
          id: 22,
          name: 'Videollamadas',
          description: 'Realiza videollamadas con los profesionales de tu equipo, apoderados o estudiantes desde la plataforma!. Luego podrás descargar un acta de registro para conservar la evidencia del trabajo remoto.',
          img: require('../../../assets/img/sales/videocall.svg')
        },{
          id: 23,
          name: 'Objetivos de Aprendizaje',
          description: 'El equipo tendrá a disposición todos los OA actualizados y sus priorizaciones, junto con otros párrafos comunes que suelen repetirse en los informes. ¡No pierdas tiempo escribiéndolos!',
          img: require('../../../assets/img/sales/file-blue.svg')
        },{
          id: 24,
          name: 'Carga automática',
          description: '¡Toda la información de los estudiantes se carga automáticamente a partir de SIGE y de tu nómina PIE! Luego de la capacitación puedes usar inmediatamente la plataforma sin perder el tiempo configurando datos.',
          img: require('../../../assets/img/sales/file-red.svg')
        },{
          id: 25,
          name: 'Gestión del equipo',
          description: 'Puedes agregar y quitar miembros en tu equipo, asignar poderes, monitorear la actividad, etc…',
          img: require('../../../assets/img/sales/file-yellow.svg')
        }
      ]
    }
  }
}
</script>

<style scoped>
.services{
  background-color: #f0f5f7;
}
.service{
  border-radius: 10px;
  border-width: 0;
}
.text-card {
  font-size: large;
  font-weight: bold;
}
p {
  line-height: 1.1 !important;
}
</style>