<template>
  <b-modal
    centered
    size="xl"
    ref="modal"
    hide-header
    :hide-footer="listing"
    @hide="close"
    id="miModal"
  >
    <QuestionManagerModal ref="question-manager" :isGlobal="true" />
    <warning-empty-modal
      :warning="warningText"
      message="Completa el contenido para poder guardarlo"
      ref="warning-empty-modal"
    />
    <RelatedMaterialsSidebar
      @open-material="openRelatedMaterial"
      :objectID="id"
      ref="related-materials-sidebar"
    />
    <b-modal ref="pond-modal" hide-footer hide-header centered>
      <FilePond
        ref="pond"
        class="p-2 m-2"
        label-idle="Arrastra tus archivos acá o haz clic para navegar"
        :allow-multiple="false"
        accepted-file-types="image/jpeg, image/png, image/svg+xml"
        :files="pondObject && pondObject.image ? [pondObject.image] : []"
      />
      <b-row class="text-right">
        <b-col offset="5">
          <b-button pill variant="danger" class="m-1" @click="closePond">
            <v-icon name="times" /> Cancelar</b-button
          >
          <b-button
            pill
            variant="primary"
            class="my-1 ml-1 mr-2"
            @click="associateImage"
          >
            <v-icon name="save" /> Guardar</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
    <AudioRecordingModal ref="audio-modal" />
    <div v-if="listing">
      <b-row class="text-left mx-2 p-2">
        <b-col cols="3" class="cursor-pointer">
          <p class="h6 w-100" @click="createNew()">
            Crear nuevo texto
            <v-icon name="plus-circle" scale="1.2" style="cursor: pointer" />
          </p>
        </b-col>
      </b-row>

      <b-row class="text-left mx-2 p-2">
        <b-col cols="12" class="mt-4 mx-0 px-0">
          <b-container class="m-0 p-0">
            <b-row class="m-0 p-0">
              <b-col v-for="(text, tindex) in texts" :key="tindex" cols="4">
                <text-row-component
                  :text="text"
                  @click.native="selectText(text)"
                />
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
      <b-row v-if="tPages > 1">
        <b-col class="text-center">
          <b-button-group>
            <b-button
              variant="outline-info"
              v-if="tPage > 1"
              @click="tPage = tPage - 1"
            >
              &#60;
            </b-button>
            <b-button
              variant="outline-info"
              :pressed="tPage === 1"
              @click="tPage = 1"
            >
              1
            </b-button>
            <b-button variant="outline-info" v-if="tPage - tPageRange / 2 > 2">
              ...</b-button
            >
            <b-button
              v-for="(p, index) in tPagesArray.filter((p) => {
                return (
                  1 < p &&
                  p < tPages &&
                  ((tPageRange < tPage &&
                    tPage - tPageRange / 2 <= p &&
                    p <= tPage + mPageRange / 2) ||
                    (tPageRange >= tPage && p <= tPageRange + 1))
                );
              })"
              :key="'page-' + index"
              :pressed="p === tPage"
              @click="tPage = p"
              variant="outline-info"
            >
              {{ p }}
            </b-button>
            <b-button
              variant="outline-info"
              v-if="tPages - 1 > tPage + tPageRange / 2"
            >
              ...</b-button
            >
            <b-button
              variant="outline-info"
              :pressed="tPage === tPages"
              @click="tPage = tPages"
            >
              {{ tPages }}</b-button
            >
            <b-button
              variant="outline-info"
              v-if="tPage < tPages"
              @click="tPage = tPage + 1"
            >
              &#62;
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </div>

    <div v-else-if="selecting" class="selecting">
      <b-tabs justified v-model="tabIndex">
        <b-tab title="Texto">
          <div v-if="tabStoryIndex === 0">
            <h1>{{ title }}</h1>
            <h2>{{ author }}</h2>
            <p>{{ content }}</p>
          </div>
          <div v-else-if="tabStoryIndex === 1" class="w-75 mx-auto">
            <CustomAudioSlider :content="storyChapters" />
          </div>
          <div v-else-if="tabStoryIndex === 2" class="w-75 mx-auto my-2">
            <div class="mx-auto">
              <h4>{{ title }}</h4>
            </div>
            <audio
              controls
              v-if="audio"
              :src="audio"
              :key="key"
              class="w-100 my-2"
            ></audio>
          </div>
        </b-tab>
        <b-tab title="Preguntas relacionadas">
          <b-container v-if="!questions.length">
            <b-row>
              <b-col class="text-center m-5">
                No hay preguntas asociadas a este texto.
              </b-col>
            </b-row>
          </b-container>
          <b-container v-else>
            <hr />
            <b-row class="text-left mx-2 p-2">
              <b-col v-for="question in questions" :key="question.id" cols="12">
                <question-row-component
                  :question="question"
                  mode="text-related"
                  @click.native="
                    canOpenQuestion
                      ? openQuestion(question)
                      : $toasted.info('No puedes ver los detalles desde aquí!')
                  "
                />
                <hr />
                <b-tooltip
                  :target="question.id"
                  triggers="hover"
                  placement="auto"
                >
                  {{ eKinds[question.kind] }}
                </b-tooltip>
              </b-col>
            </b-row>
            <b-row v-if="ePages > 1">
              <b-col class="text-center">
                <b-button-group>
                  <b-button
                    variant="outline-info"
                    v-if="ePage > 1"
                    @click="ePage = ePage - 1"
                  >
                    &#60;
                  </b-button>
                  <b-button
                    variant="outline-info"
                    :pressed="ePage === 1"
                    @click="ePage = 1"
                  >
                    1
                  </b-button>
                  <b-button
                    variant="outline-info"
                    v-if="ePage - ePageRange / 2 > 2"
                  >
                    ...</b-button
                  >
                  <b-button
                    v-for="(p, index) in ePagesArray.filter((p) => {
                      return (
                        1 < p &&
                        p < ePages &&
                        ((ePageRange < ePage &&
                          ePage - ePageRange / 2 <= p &&
                          p <= ePage + ePageRange / 2) ||
                          (ePageRange >= ePage && p <= ePageRange + 1))
                      );
                    })"
                    :key="'page-' + index"
                    :pressed="p === ePage"
                    @click="ePage = p"
                    variant="outline-info"
                  >
                    {{ p }}
                  </b-button>
                  <b-button
                    variant="outline-info"
                    v-if="ePages - 1 > ePage + ePageRange / 2"
                  >
                    ...</b-button
                  >
                  <b-button
                    variant="outline-info"
                    :pressed="ePage === ePages"
                    @click="ePage = ePages"
                  >
                    {{ ePages }}</b-button
                  >
                  <b-button
                    variant="outline-info"
                    v-if="ePage < ePages"
                    @click="ePage = ePage + 1"
                  >
                    &#62;
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </b-container>
        </b-tab>
      </b-tabs>
    </div>
    <b-form v-else-if="creating || editing">
      <b-tabs v-model="tabStoryIndex" fill>
        <b-tab
          title="Texto"
          :disabled="editing && (tabStoryIndex !== 0 ? true : false)"
        >
          <h4 class="py-2 m-2">Ingresa tu nuevo texto</h4>
          <b-input
            v-model="title"
            class="p-2 m-2"
            placeholder="Título del texto"
            required
          ></b-input>
          <b-input
            v-model="author"
            class="p-2 m-2"
            placeholder="Nombre de el/la autor(a)"
            required
          ></b-input>
          <b-textarea
            v-model="content"
            class="p-2 m-2"
            placeholder="Ingresa tu texto aquí"
            rows="6"
            required
          ></b-textarea>
          <b-row>
            <b-col cols="3" class="ml-3"
              ><vue-record-audio @result="handleAudio" mode="press"
            /></b-col>
            <b-col cols="8"
              ><audio
                controls
                v-if="audio"
                :src="audio"
                :key="key"
                class="w-100 my-2"
              ></audio
            ></b-col>
          </b-row>
          <b-row>
            O sube tu archivo de audio:
            <b-form-file
              class="p-2 m-2"
              placeholder="Haz clic para navegar o arrastra y suelta"
              drop-placeholder="Arrastra aquí"
              accept="audio/*, video/mp4"
              @input="handleAudio"
            />
          </b-row>
        </b-tab>
        <b-tab
          title="Historia Multimedia"
          :disabled="editing && (tabStoryIndex !== 1 ? true : false)"
        >
          <b-overlay
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            :show="loadingSave || loadingGet"
          >
            <b-row>
              <b-input v-model="title" class="p-2 m-2" placeholder="Título">
              </b-input>
            </b-row>
            <b-row v-for="(chapter, cindex) in storyChapters" :key="cindex">
              <b-col cols="3" class="text-center"
                >Capítulo {{ cindex + 1 }}</b-col
              >
              <!-- <b-col
                ><b-input
                  v-model="chapter.title"
                  placeholder="Título capítulo"
                ></b-input
              ></b-col> -->

              <b-col cols="2" class="text-right"> Adjuntar imagen </b-col>
              <b-col cols="1" @click="attachImage(chapter)">
                <ClipIcon viewbox="0 0 100 100" width="55" height="55" />
              </b-col>
              <b-col cols="1" class="pt-2">
                <v-icon
                  v-if="!loadingSave"
                  class="d-inline-block ml-1"
                  :name="chapter.image ? 'check' : 'times-circle'"
                  :class="chapter.image ? 'text-success' : 'text-danger'"
                />
              </b-col>
              <b-col cols="2" class="text-right"> Grabar audio </b-col>
              <b-col cols="1" @click="manageRecording(chapter)">
                <MicrophoneIcon viewbox="0 0 100 100" width="55" height="55" />
              </b-col>
              <b-col cols="1" class="pt-2">
                <v-icon
                  v-if="!loadingSave"
                  class="d-inline-block ml-1"
                  :name="chapter.audio ? 'check' : 'times-circle'"
                  :class="chapter.audio ? 'text-success' : 'text-danger'"
                />
              </b-col>
              <b-col class="text-right">
                <div @click="deleteChapter(cindex)">
                  <TrashIcon viewbox="0 0 100 100" width="55" height="55" />
                </div>
              </b-col>
            </b-row>
            <b-row class="w-100 px-3 py-2 text-left" style="cursor: pointer">
              <div class="text-muted">
                <p
                  class="h6 mx-2"
                  @click="storyChapters.push(getEmptyChapter())"
                >
                  Clic acá para agregar un capítulo
                  <v-icon name="plus-circle" scale="1.2" />
                </p>
              </div>
            </b-row>
          </b-overlay>
        </b-tab>
        <b-tab
          title="Historia en Audio"
          :disabled="editing && (tabStoryIndex !== 2 ? true : false)"
        >
          <b-overlay
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            :show="loadingSave"
          >
            <b-row>
              <b-col>
                <b-input
                  v-model="title"
                  class="p-2 m-2 w-75"
                  placeholder="Título"
                ></b-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="ml-3"> Graba la historia haciendo click </b-col>
            </b-row>
            <b-row>
              <b-col class="ml-3">
                <vue-record-audio @result="handleAudio" mode="press" />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="ml-3">
                <audio
                  controls
                  v-if="audio"
                  :src="audio"
                  :key="key"
                  class="w-50 my-2"
                ></audio>
              </b-col>
            </b-row>

            <b-row class="ml-1">
              <b-col>
                O sube tu archivo de audio:
                <b-form-file
                  class="p-2 m-1 w-75"
                  placeholder="Haz clic para navegar o arrastra y suelta"
                  drop-placeholder="Arrastra aquí"
                  accept="audio/*, video/mp4"
                  @input="handleAudio"
                />
              </b-col>
            </b-row>
          </b-overlay>
        </b-tab>
      </b-tabs>
    </b-form>

    <template #modal-footer>
      <div v-if="selecting">
        <div class="text-right p-0 m-0">
          <b-button
            pill
            variant="danger"
            class="mx-1"
            v-on="fromList ? { click: close } : { click: backToList }"
            ><v-icon name="chevron-left" /> <b> Volver</b></b-button
          >
          <b-button
            v-if="!fromList"
            pill
            variant="primary"
            class="mx-1"
            @click="addToPage"
            >Seleccionar</b-button
          >
          <b-button
            pill
            variant="primary"
            class="mx-1"
            @click="editText"
            v-if="allowEdit"
          >
            Editar</b-button
          >
          <b-button
            v-if="id && fromList"
            pill
            variant="primary"
            class="mx-1"
            @click="openRelatedMaterials"
            >Usar</b-button
          >
        </div>
      </div>
      <div v-else-if="creating || editing">
        <div class="text-right p-0 m-0">
          <b-button
            pill
            variant="danger"
            class="mx-1"
            @click="fromList ? close() : resetView()"
          >
            <v-icon name="times" /> Descartar</b-button
          >
          <b-button
            pill
            variant="primary"
            class="mx-1"
            @click="save"
            :disabled="disableSave"
            ><v-icon name="save" /> Guardar</b-button
          >
          <b-button
            v-if="id"
            pill
            variant="primary"
            class="mx-1"
            @click="openRelatedMaterials"
          >
            Usar</b-button
          >
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import customMaterialService from "@/services/customMaterialService";
import TextRowComponent from "@/components/custom-materials/miscellaneous/TextRowComponent";
import QuestionManagerModal from "@/components/custom-materials/modals/QuestionManagerModal";
import QuestionCard from "@/components/custom-materials/miscellaneous/QuestionCard";
import WarningEmptyModal from "@/components/custom-materials/modals/WarningEmptyModal.vue";
import MicrophoneIcon from "../icons/MicrophoneIcon";
import ClipIcon from "../icons/ClipIcon";
import TrashIcon from "../icons/TrashIcon";
import AudioRecordingModal from "@/components/custom-materials/modals/AudioRecordingModal";
import CustomAudioSlider from "@/components/custom-materials/miscellaneous/CustomAudioSlider";
import QuestionRowComponent from "@/components/custom-materials/miscellaneous/QuestionRowComponent";
import RelatedMaterialsSidebar from "@/components/custom-materials/miscellaneous/RelatedMaterialsSidebar";
export default {
  name: "TextManagerModal",
  components: {
    TextRowComponent,
    QuestionCard,
    QuestionManagerModal,
    WarningEmptyModal,
    MicrophoneIcon,
    ClipIcon,
    TrashIcon,
    AudioRecordingModal,
    CustomAudioSlider,
    QuestionRowComponent,
    RelatedMaterialsSidebar,
  },
  data() {
    return {
      id: "",
      texts: [],
      tabIndex: 0,
      creating: true,
      editing: false,
      listing: false,
      fromList: false,
      selecting: false,
      title: "",
      author: "",
      content: "",
      storyChapters: [],
      pageMaterial: 1,
      text: undefined,
      audio: null,
      audioBlob: null,
      key: false,
      icon_2: require("../../../assets/img/material_icons/Icon2.svg"),
      icon_new: require("../../../assets/img/material_icons/Icon4.svg"),
      disableSave: false,
      tPageRange: 4,
      tPages: 1,
      perPage: 8,
      tPage: 1,
      order: "-created_at",
      allowEdit: true,
      eKinds: {
        0: "Alternativas",
        1: "Desarrollo",
        2: "Dictado",
        3: "Ordenamiento"
      },
      per_page: 8,
      ePages: 1,
      ePage: 1,
      ePageRange: 4,
      questions: [],
      canOpenQuestion: true,
      tabStoryIndex: 0,
      loadingSave: false,
      pondObject: null,
      eje: "",
      loadingGet: false,
      warningText: "",
    };
  },
  watch: {
    ePage() {
      this.getPaginatedRelatedQuestions();
    },
    tPage() {
      this.getPaginatedSchoolTexts();
    },
    storyChapters: {
      handler: function (val) {
        val.forEach((chapter) => {
          this.$watch(
            () => chapter.image,
            (newVal, oldVal) => {
              if (newVal !== oldVal) {
                chapter.id = "";
              }
            }
          );
          this.$watch(
            () => chapter.audio,
            (newVal, oldVal) => {
              if (newVal !== oldVal) {
                chapter.id = "";
              }
            }
          );
        });
      },
      deep: true,
    },
  },
  computed: {
    tPagesArray() {
      return Array.from({ length: this.tPages }, (_, i) => i + 1);
    },
    schoolID() {
      return this.$store.state.login.school;
    },
    ePagesArray() {
      return Array.from({ length: this.ePages }, (_, i) => i + 1);
    },
  },
  methods: {
    open() {
      this.tabIndex = 0;
      if (this.listing) {
        this.retrieveData();
      }
      this.$refs["modal"].show();
    },
    openQuestion(question) {
      this.$refs["question-manager"].selectQuestion(question, true);
    },
    retrieveData() {
      this.ePages = 1;
      this.ePage = 1;
      this.order = "-created_at";

      this.getPaginatedSchoolTexts();
    },
    getPaginatedRelatedQuestions() {
      customMaterialService
        .getPaginatedRelatedQuestions(
          this.text.id,
          this.ePage,
          this.per_page,
          this.order
        )
        .then((data) => {
          this.questions = data.questions;
          this.ePages = data.pages;
        });
    },
    getPaginatedSchoolTexts() {
      customMaterialService
        .getPaginatedSchoolFilteredTexts(
          this.$store.state.login.school,
          this.tPage,
          this.perPage,
          this.eje
        )
        .then((data) => {
          this.texts = data.texts;
          this.tPages = data.pages;
        })
        .catch(() =>
          this.$toasted.error("Ocurrió un problema al traer tus textos")
        );
    },
    select(text) {
      this.$emit("text-selected", text);
      this.$refs["modal"].hide();
    },
    resetView() {
      this.title = "";
      this.author = "";
      this.content = "";
      this.audioBlob = null;
      this.audio = null;
    },
    openFromPage(page, text, eje) {
      this.creating = false;
      this.editing = false;

      if (text) {
        this.text = JSON.parse(JSON.stringify(text));
        this.questions = [];
        this.getPaginatedRelatedQuestions();
        this.title = text.title;
        this.author = text.author;
        this.content = text.content;
        this.audio = text.audio;
        this.id = text.id;
      }

      this.selecting = Boolean(text);
      this.listing = this.selecting ? false : true;
      this.pageMaterial = page;
      this.eje = eje;
      this.open();
    },

    createNew(fromList = false) {
      this.resetView();
      this.fromList = fromList;
      this.creating = true;
      this.editing = false;
      this.listing = false;
      this.selecting = false;
      this.id = "";
      if (this.fromList) {
        this.open();
      }
    },
    selectText(text, fromList = false, allowEdit = true) {
      this.fromList = fromList;
      this.text = JSON.parse(JSON.stringify(text));
      this.questions = [];
      this.getPaginatedRelatedQuestions();
      this.title = text.title;
      this.author = text.author;
      this.content = text.content;
      this.audio = text.audio;
      this.tabStoryIndex = text.content_type;
      this.storyChapters = text.story_chapters;
      this.allowEdit = allowEdit;
      this.id = text.id;
      this.creating = false;
      this.editing = false;
      this.listing = false;
      this.selecting = true;
      if (fromList) {
        this.canOpenQuestion = false;
        this.open();
      } else {
        this.canOpenQuestion = true;
      }
    },
    backToList() {
      this.creating = false;
      this.editing = false;
      this.listing = true;
      this.selecting = false;
    },

    editText() {
      this.creating = false;
      this.editing = true;
      this.listing = false;
      this.selecting = false;
      this.open();
    },

    close() {
      this.creating = true;
      this.editing = false;
      this.listing = false;
      this.selecting = false;
      this.fromList = false;
      this.text = undefined;
      this.title = "";
      this.author = "";
      this.content = "";
      this.storyChapters = [];
      this.tabStoryIndex = 0;
      this.audio = null;
      this.audioBlob = null;
      this.loadingSave = false;
      this.warningText = "";
      this.$refs["modal"].hide();
    },
    handleAudio(data) {
      this.audioBlob = data;
      this.audio = window.URL.createObjectURL(data);
      this.key = !this.key;
    },
    addToPage() {
      this.$emit("add-text-material", this.text, this.pageMaterial);
      this.close();
    },
    saveText() {
      console.log("guardar");
      this.loadingSave = true;
      if (this.editing) {
        delete this.text.id;
      }
      customMaterialService
        .createText({
          title: this.title,
          author: this.author,
          content: this.content,
          story_chapters: this.storyChapters,
          content_type: this.tabStoryIndex,
        })
        .then(
          (data) => {
            this.text = data;

            customMaterialService
              .associateTextSchool({ text: data.id, school: this.schoolID })
              .then((data) => console.log(data));

            if (this.audio) {
              let formdata = new FormData();
              formdata.append("text", data.id);
              formdata.append(
                "audio",
                this.audioBlob,
                `audio-${this.$moment().unix()}.wav`
              );
              customMaterialService.addTextAudio(formdata).then(
                () => {
                  this.text.audio = this.audio;
                  this.audio = null;
                  this.audioBlob = null;
                  this.$emit("created-text", this.text);
                  this.$emit("add-text-material", this.text, this.pageMaterial);
                  this.close();
                },
                () =>
                  this.$toasted.error("Ocurrió un error al guardar el audio")
              );
            } else {
              this.$emit("add-text-material", this.text, this.pageMaterial);
              this.$emit("created-text", data);
              this.close();
            }
          },
          () => this.$toasted.error("Ocurrió un error al crear el texto")
        );
    },
    saveStory() {
      let storyMultimedia = [];
      this.loadingSave = true;

      this.storyChapters.forEach((chapter, chIdx) => {
        console.log(chapter);
        if (chapter.image && chapter.image.file) {
          storyMultimedia.push({ chapter: chIdx, image: chapter.image.file });
          delete chapter.image;
          delete chapter.image_url;
        }
        if (chapter.image && !chapter.image.file) {
          let xhr = new XMLHttpRequest();
          xhr.open("GET", chapter.image);
          xhr.responseType = "blob";
          xhr.onload = function () {
            storyMultimedia.push({ chapter: chIdx, image: xhr.response });
          };
          xhr.send();

          delete chapter.image;
        }
        if (chapter.audio_blob) {
          storyMultimedia.push({
            chapter: chIdx,
            audio: chapter.audio_blob,
          });
          delete chapter.audio;
          delete chapter.audio_blob;
          delete chapter.changed;
        }
        if (chapter.audio && !chapter.audio_blob) {
          let xhr = new XMLHttpRequest();
          xhr.open("GET", chapter.audio);
          xhr.responseType = "blob";
          xhr.onload = function () {
            storyMultimedia.push({
              chapter: chIdx,
              audio: xhr.response,
            });
          };
          xhr.send();

          delete chapter.audio;
        }
        if (chapter.id) {
          delete chapter.id;
        }
      });
      customMaterialService
        .createStory({
          title: this.title,
          story_chapters: this.storyChapters,
          content_type: this.tabStoryIndex,
        })
        .then((data) => {
          this.text = data;
          this.storyChapters = data.story_chapters;
          this.id = data.id;
          this.sendStoryFiles(storyMultimedia);
          customMaterialService
            .associateTextSchool({ text: data.id, school: this.schoolID })
            .then((data) => console.log(data));
        });
    },
    updateStory() {
      let storyMultimedia = [];
      this.loadingSave = true;

      this.storyChapters.forEach((chapter, chIdx) => {
        // if a chapter has id, it means it was already created and then its has been not changed, so we don't need to send it again
        if (chapter.id) {
          storyMultimedia.push({ chapter: chIdx, id: chapter.id });
          delete chapter.image;
          delete chapter.audio;
        } else {
          if (chapter.image && chapter.image.file) {
            storyMultimedia.push({ chapter: chIdx, image: chapter.image.file });
            delete chapter.image;
            delete chapter.image_url;
          }
          if (chapter.image && !chapter.image.file) {
            let xhr = new XMLHttpRequest();
            xhr.open("GET", chapter.image);
            xhr.responseType = "blob";
            xhr.onload = function () {
              storyMultimedia.push({ chapter: chIdx, image: xhr.response });
            };
            xhr.send();

            delete chapter.image;
          }
          if (chapter.audio_blob) {
            storyMultimedia.push({
              chapter: chIdx,
              audio: chapter.audio_blob,
            });
            delete chapter.audio;
            delete chapter.audio_blob;
            delete chapter.changed;
          }
          if (chapter.audio && !chapter.audio_blob) {
            let xhr = new XMLHttpRequest();
            xhr.open("GET", chapter.audio);
            xhr.responseType = "blob";
            xhr.onload = function () {
              storyMultimedia.push({
                chapter: chIdx,
                audio: xhr.response,
              });
            };
            xhr.send();

            delete chapter.audio;
          }
        }
        if (chapter.id) {
          delete chapter.id;
        }
      });
      customMaterialService
        .updateText({
          id: this.id,
          title: this.title,
          story_chapters: this.storyChapters,
          content_type: this.tabStoryIndex,
        })
        .then((data) => {
          this.text = data;
          this.storyChapters = data.story_chapters;
          this.id = data.id;
          this.sendStoryFiles(storyMultimedia);
          customMaterialService
            .associateTextSchool({ text: data.id, school: this.schoolID })
            .then((data) => console.log(data));
        });
    },
    saveAudio() {
      this.loadingSave = true;
      if (this.editing) {
        delete this.text.id;
      }
      customMaterialService
        .createText({
          title: this.title,
          story_chapters: this.storyChapters,
          content_type: this.tabStoryIndex,
        })
        .then(
          (data) => {
            this.text = data;

            customMaterialService
              .associateTextSchool({ text: data.id, school: this.schoolID })
              .then((data) => console.log(data));

            if (this.audio) {
              let formdata = new FormData();
              formdata.append("text", data.id);
              formdata.append(
                "audio",
                this.audioBlob,
                `audio-${this.$moment().unix()}.wav`
              );
              customMaterialService.addTextAudio(formdata).then(
                () => {
                  this.text.audio = this.audio;
                  this.audio = null;
                  this.audioBlob = null;
                  this.$emit("created-text", this.text);
                  this.$emit("add-text-material", this.text, this.pageMaterial);
                  this.close();
                },
                () =>
                  this.$toasted.error("Ocurrió un error al guardar el audio")
              );
            } else {
              this.$emit("add-text-material", this.text, this.pageMaterial);
              this.$emit("created-text", data);
              this.close();
            }
          },
          () => this.$toasted.error("Ocurrió un error al crear el texto")
        );
    },
    save() {
      if (this.validateText()) {
        switch (this.tabStoryIndex) {
          case 0:
            this.saveText();
            break;
          case 1:
            this.id ? this.updateStory() : this.saveStory();
            break;
          case 2:
            this.saveAudio();

            break;
        }
      } else {
        this.$refs["warning-empty-modal"].show();
      }
    },
    // create function to validate the text deppending of tabStoryIndex
    validateText() {
      switch (this.tabStoryIndex) {
        case 0:
          if (this.content && this.title) {
            return true;
          } else {
            this.warningText = "Tu texto debe tener un título y contenido";
            return false;
          }

        case 1:
          if (this.storyChapters.length > 0) {
            return true;
          } else {
            this.warningText = "Tu historia debe tener al menos un capítulo";
            return false;
          }

        case 2:
          if (this.audio && this.title) {
            return true;
          } else {
            this.warningText = "Tu audio debe tener un archivo de audio";
            return false;
          }
      }
    },
    // create a function to show a warning modal if communicating to the user the information that is missing
    showWarningModal() {
      this.$refs["warning-empty-modal"].show();
    },
    getEmptyChapter() {
      return {
        title: "",
        image: "",
        audio: "",
      };
    },
    closePond() {
      this.$refs["pond-modal"].hide();
    },
    associateImage() {
      let images = this.$refs.pond.getFiles();

      if (!this.pondObject) {
        return;
      }
      if (images.length < 1 && !this.pondObject.image) {
        this.$toasted.error("Debes subir una imagen para poder guardar");
        return;
      }
      if (this.pondObject.image && images.length === 0) {
        this.pondObject.image = null;
        return;
      }
      this.$set(this.pondObject, "image", images[0]);
      this.$set(
        this.pondObject,
        "image_url",
        window.URL.createObjectURL(images[0].file)
      );

      this.closePond();
    },
    attachImage(obj) {
      this.pondObject = obj;
      this.$refs["pond-modal"].show();
    },
    manageRecording(obj) {
      this.$refs["audio-modal"].open(obj);
    },
    sendStoryFiles(storyMultimedia) {
      storyMultimedia.forEach((c, cindex) => {
        let formData = new FormData();
        if (c.image instanceof Blob) {
          formData.append("image", c.image);
        } else if (c.audio instanceof Blob) {
          console.log("audio");
          formData.append(
            "audio",
            c.audio,
            `audio-${this.$moment().unix()}.wav`
          );
        } else {
          formData.append("id", c.id);
        }

        customMaterialService
          .uploadStoryFiles(this.storyChapters[c.chapter].id, formData)
          .then(() => {
            if (cindex === storyMultimedia.length - 1) {
              this.loadingSave = false;
              this.$emit("add-text-material", this.text, this.pageMaterial);
              this.$emit("created-text", this.text);
              this.close();
            }
          });
      });
    },
    deleteChapter(index) {
      this.storyChapters.splice(index, 1);
    },
    openRelatedMaterials() {
      this.$refs["related-materials-sidebar"].openFromText();
    },
    openRelatedMaterial(materialID) {
      this.$emit("open-related-material", materialID);
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
.selecting {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
