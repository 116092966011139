import api from "@/services/api";
import multiform from "@/services/multiform";

export default {
  getAllQuestions() {
    return api.get("special-questions/").then((response) => response.data);
  },
  getSchoolQuestions(schoolId) {
    return api
      .get(`special-questions/get_questions_school/?school=${schoolId}`)
      .then((response) => response.data);
  },
  getPaginatedSchoolQuestions(school, page, per_page, order, hashtags, textID) {
    return api
      .get(
        `special-questions/get_paginated_questions_school/?school=${school}&page=${page}&per_page=${per_page}&order=${order}&hashtags=${hashtags}&text=${textID}`
      )
      .then((response) => response.data);
  },
  getPaginatedRelatedQuestions(textID, page, per_page, order) {
    return api
      .get(
        `special-questions/get_paginated_questions_school/?page=${page}&per_page=${per_page}&order=${order}&text=${textID}`
      )
      .then((response) => response.data);
  },
  createQuestion(question) {
    return api
      .post("special-questions/", question)
      .then((response) => response.data);
  },
  saveNumberOfImages(question) {
    return api
      .post("special-questions/save_number_of_images/", question)
      .then((response) => response.data);
  },
  saveSelectedImages(question) {
    return api
      .post("special-questions/save_selected_images/", question)
      .then((response) => response.data);
  },
  getCountImages() {
    return api.get("special-questions/get_count_images/").then((response) => response.data);
  },
  getRelatedTexts(questionID) {
    return api
      .get(`special-questions/${questionID}/get_related_texts/`)
      .then((response) => response.data);
  },
  updateQuestion(question) {
    return api
      .patch(`special-questions/${question.id}/`, question)
      .then((response) => response.data);
  },
  getQuestion(questionID) {
    return api
      .get(`special-questions/${questionID}/`)
      .then((response) => response.data);
  },
  getQuestionAlternatives(questionID) {
    return api
      .get(`special-questions/get_alternatives/?question=${questionID}`)
      .then((response) => response.data);
  },
  uploadFiles(formData) {
    return multiform
      .post("special-questions/files/", formData)
      .then((response) => response.data);
  },
  saveAdditionalInstruction(formData) {
    return multiform
      .post("special-questions/save_additional_instruction/", formData)
      .then((response) => response.data);
  },
  createDictation(formData) {
    return multiform
      .post("special-questions/dictation/", formData)
      .then((response) => response.data);
  },
  optionFiles(formData) {
    return multiform
      .post("special-questions/options/", formData)
      .then((response) => response.data);
  },
  associateQuestionSchool(obj) {
    return multiform
      .post("special-questions/associate_to_school/", obj)
      .then((response) => response.data);
  },
  saveSubtags(payload) {
    return api
      .post("special-questions/subtags/", payload)
      .then((response) => response.data);
  },
  saveRubric(payload) {
    return api
      .post("material-rubrics/create_rubric_question/", payload)
      .then((response) => response.data);
  },
  getSchoolTexts(school) {
    return api
      .get(`special-texts/get_texts_school/?school=${school}`)
      .then((response) => response.data);
  },
  getPaginatedSchoolTexts(school, page, per_page, order, recycle) {
    return api
      .get(
        `special-texts/get_paginated_texts_school/?school=${school}&page=${page}&per_page=${per_page}&order=${order}&recycle=${recycle}`
      )
      .then((response) => response.data);
  },
  getPaginatedSchoolFilteredTexts(school, page, per_page, eje) {
    return api
      .get(
        `special-texts/get_paginated_texts_filtered_school/?school=${school}&page=${page}&per_page=${per_page}&eje=${eje}`
      )
      .then((response) => response.data);
  },
  associateTextSchool(payload) {
    return multiform
      .post("special-texts/associate_to_school/", payload)
      .then((response) => response.data);
  },
  createText(payload) {
    return api
      .post("special-texts/", payload)
      .then((response) => response.data);
  },
  updateText(text) {
    return api
      .patch(`special-texts/${text.id}/`, text)
      .then((response) => response.data);
  },
  getText(id) {
    return api.get(`special-texts/${id}/`).then((response) => response.data);
  },
  addTextAudio(formdata) {
    return multiform
      .post("special-texts/audio/", formdata)
      .then((response) => response.data);
  },
  createMaterial(material) {
    return api
      .post("custom-materials/", material)
      .then((response) => response.data);
  },
  updateMaterial(material) {
    return api
      .patch(`custom-materials/${material.id}/`, material)
      .then((response) => response.data);
  },
  restoreMaterial(payload) {
    return api
      .post(
        "custom-materials/restore_material/", payload
      ).then((response) => response.data);
  },
  changeName(payload) {
    return api
      .post(
        "custom-materials/change_name/", payload
      ).then((response) => response.data);
  },
  createPage(materialID, data) {
    return api
      .post("special-pages/", { material_id: materialID, ...data })
      .then((response) => response.data);
  },
  updatePage(page) {
    return api
      .put(`special-pages/${page.id}/`, page)
      .then((response) => response.data);
  },
  deletePage(page) {
    return api
      .delete(`special-pages/${page.id}/`)
      .then((response) => response.data);
  },
  createQuestionPage(pageID, questionID, index, score) {
    return api
      .post("special-question-page/", {
        page: pageID,
        question: questionID,
        index: index,
        score: score,
      })
      .then((response) => response.data);
  },
  deleteQuestionPage(pageID, questionID) {
    return api
      .post("special-question-page/delete_question_page/", {
        page: pageID,
        question: questionID,
      })
      .then((response) => response.data);
  },
  getQuestionPage() {
    return api.get(`special-question-page/get_`);
  },
  getOrCreateQuestionPage(pageID, questionID) {
    return api
      .get(
        `special-question-page/get_question_page/?page=${pageID}&question=${questionID}`
      )
      .then((response) => response.data);
  },
  getMaterials() {
    return api.get("custom-materials/").then((response) => response.data);
  },
  getFilteredMaterials(school, subject, grade) {
    return api
      .get(
        `custom-materials/get_filtered_materials/?school=${school}&subject=${subject}&grade=${grade}`
      )
      .then((response) => response.data);
  },
  getFilteredApplications(school, subject, grade) {
    return api
      .get(
        `material-application/get_filtered_applications/?school=${school}&subject=${subject}&grade=${grade}`
      )
      .then((response) => response.data);
  },
  getPaginatedMaterials(
    school,
    page,
    per_page,
    order,
    professional,
    subject,
    grade,
    recycle
  ) {
    return api
      .get(
        `custom-materials/get_paginated_materials/?school=${school}&page=${page}&per_page=${per_page}&order=${order}&professional=${professional}&subject=${subject}&grade=${grade}&recycle=${recycle}`
      )
      .then((response) => response.data);
  },
  getMaterialByID(id) {
    return api
      .get(`custom-materials/get_material_by_id/?id=${id}`)
      .then((response) => response.data);
  },
  uploadPageFiles(formData) {
    return multiform
      .post("custom-materials/files/", formData)
      .then((response) => response.data);
  },
  getMaterialLink(materialID) {
    return api
      .get(`material-links/${materialID}/`)
      .then((response) => response.data);
  },
  generateLink(material) {
    return api
      .post("material-links/", material)
      .then((response) => response.data);
  },
  getMaterial(materialID) {
    return api
      .get(`custom-materials/${materialID}/`)
      .then((response) => response.data);
  },
  materialStarted(inviteID, payload) {
    return api
      .post(`material-links/${inviteID}/start/`, payload)
      .then((response) => response.data);
  },
  closeMaterial(payload) {
    return api
      .post("material-links/close_material/", payload)
      .then((response) => response.data);
  },
  checkAnswers(inviteID) {
    return api
      .get(`material-links/${inviteID}/check_answers/`)
      .then((response) => response.data);
  },
  getPreviousAnswers(invitationID, pageID) {
    return api
      .get(
        `material-responses/get-previous-answers/?invitation=${invitationID}&page=${pageID}`
      )
      .then((response) => response.data);
  },
  savePageAnswers(inviteID, payload) {
    return api
      .post(`material-links/${inviteID}/save_page_answers/`, payload)
      .then((response) => response.data);
  },
  updatePageResponse(inviteID, payload) {
    return api
      .post(`material-links/${inviteID}/update_page_response/`, payload)
      .then((response) => response.data);
  },
  saveEvents(inviteID, payload) {
    return api
      .post(`material-links/${inviteID}/save_evaluation_events/`, payload)
      .then((response) => response.data);
  },
  sendLinkToStudent(id) {
    return api
      .get(`material-links/${id}/send-link/`)
      .then((response) => response.data);
  },
  getUserMaterials(userID) {
    return api
      .get(`custom-materials/get_user_materials/?user=${userID}`)
      .then((response) => response.data);
  },
  getStudentsWithoutMaterials(schoolID, year) {
    return api
      .get(`schools/${schoolID}/get_students_without_materials/?year=${year}`)
      .then((response) => response.data);
  },
  getSchoolMaterials(schoolID) {
    return api
      .get(`schools/${schoolID}/get_materials/`)
      .then((response) => response.data);
  },
  associateMaterialSchool(payload) {
    return api
      .post("custom-materials/associate_to_school/", payload)
      .then((response) => response.data);
  },
  getGrades(schoolID, year) {
    return api
      .get(`schools/${schoolID}/grades/?year=${year}`)
      .then((response) => response.data);
  },
  getGradeMaterials(materialID, schoolID) {
    return api
      .get(`custom-materials/${materialID}/grades/?school=${schoolID}`)
      .then((response) => response.data);
  },
  getStudentsNotReviewed(schoolID, year) {
    return api
      .get(
        `schools/${schoolID}/get_students_not_reviewed_materials/?year=${year}`
      )
      .then((response) => response.data);
  },
  getResponses(inviteID) {
    return api
      .get(`material-responses/invite/?id=${inviteID}`)
      .then((response) => response.data);
  },
  submitAnswer(formData) {
    return multiform
      .post("material-responses/", formData)
      .then((response) => response.data);
  },
  evaluateMaterial(data) {
    return api
      .post("material-responses/evaluate/", data)
      .then((response) => response.data);
  },
  getStudentsNotClosed(schoolID, year) {
    return api
      .get(
        `schools/${schoolID}/get_students_not_closed_materials/?year=${year}`
      )
      .then((response) => response.data);
  },
  createReport(payload) {
    console.log(payload);
    return api
      .post("schools/create_material_report/", payload)
      .then((response) => response.data);
  },
  getApplications() {
    return api.get("material-application/").then((response) => response.data);
  },
  getApplicationDetails(appID) {
    return api
      .get(`material-application/${appID}/get_application_details/`)
      .then((response) => response.data);
  },
  getLevelLinks(appID, level) {
    return api
      .get(
        `material-application/${appID}/get_application_level_links/?level=${level}`
      )
      .then((response) => response.data);
  },
  createApplication(materialID, payloadApp) {
    return api
      .post(`custom-materials/${materialID}/create_application/`, payloadApp)
      .then((response) => response.data);
  },
  getSchoolApplications(schoolID) {
    return api
      .get(`material-application/get_applications_school/?school=${schoolID}`)
      .then((response) => response.data);
  },
  getMaterialApplications(materialID) {
    return api
      .get(
        `material-application/get_applications_material/?material=${materialID}`
      )
      .then((response) => response.data);
  },
  associateFamilySchool(familyID, schoolID) {
    return api
      .get(
        `family-question/${familyID}/associate_to_school/?school=${schoolID}`
      )
      .then((response) => response.data);
  },
  getFamily(familyID) {
    return api
      .get(`family-question/${familyID}/`)
      .then((response) => response.data);
  },
  getPaginatedSchoolFamilies(
    school,
    page,
    per_page,
    order,
    hashtags,
    recycle,
    eje,
    textID,
    ekind
  ) {
    return api
      .get(
        `family-question/get_paginated_families_school/?school=${school}&page=${page}&per_page=${per_page}&order=${order}&hashtags=${hashtags}&recycle=${recycle}&eje=${eje}&text=${textID}&ekind=${ekind}`
      )
      .then((response) => response.data);
  },
  getPaginatedFamiliesFilteredSchool(school, page, per_page, ejeID, textID) {
    return api
      .get(
        `family-question/get_paginated_families_filtered_school/?school=${school}&page=${page}&per_page=${per_page}&eje=${ejeID}&text=${textID}`
      )
      .then((response) => response.data);
  },
  getStudentsApplication(appID) {
    return api
      .get(`material-application/${appID}/get_application_students/`)
      .then((response) => response.data);
  },
  getGradesApplication(appID) {
    return api
      .get(`material-application/${appID}/get_application_grades/`)
      .then((response) => response.data);
  },
  addStudentsApplication(appID, payload) {
    return api
      .post(`material-application/${appID}/add_students/`, payload)
      .then((response) => response.data);
  },
  getPaginatedSchoolApplications(schoolID, page, perPage, order, recycle) {
    return api
      .get(
        `material-application/get_paginated_applications_school/?school=${schoolID}&page=${page}&per_page=${perPage}&order=${order}&recycle=${recycle}`
      )
      .then((response) => response.data);
  },
  getApplicationReport(linkID) {
    return api
      .get(
        `material-application/get_application_report/?material_link=${linkID}`
      )
      .then((response) => response.data);
  },
  restoreApplication(payload) {
    return api
      .post(
        'material-application/restore_application/', payload
      ).then((response) => response.data);
  },
  deletePageImage(pageImage) {
    return api
      .post("custom-materials/delete_images/", pageImage)
      .then((response) => response.data);
  },
  deletePageAudio(page) {
    return api.post("custom-materials/delete_audio/", page);
  },
  deleteText(textID) {
    return api
      .delete(`special-texts/${textID}/`)
      .then((response) => response.data);
  },
  deleteMaterial(materialID) {
    return api
      .delete(`custom-materials/${materialID}/`)
      .then((response) => response.data);
  },

  deleteFamily(familyID) {
    return api
      .post("family-question/delete_family_questions/", { family: familyID })
      .then((response) => response.data);
  },
  createStory(payload) {
    return api
      .post("special-texts/", payload)
      .then((response) => response.data);
  },
  uploadStoryFiles(chapterID, formData) {
    return multiform
      .post(`chapter-multimedia/${chapterID}/files/`, formData)
      .then((response) => response.data);
  },
  getStory(storyID) {
    return multiform
      .get(`story-multimedia/${storyID}/`)
      .then((response) => response.data);
  },
  uploadMaterialBackground(materialID, formData) {
    return multiform
      .post(`custom-materials/${materialID}/background_file/`, formData)
      .then((response) => response.data);
  },
  deleteMaterialBackground(materialID) {
    return api
      .post(`custom-materials/${materialID}/delete_background/`)
      .then((response) => response.data);
  },
  getRelatedMaterialsToText(textID, schoolID) {
    return api
      .get(`special-texts/${textID}/get_related_materials/?school=${schoolID}`)
      .then((response) => response.data);
  },
  getRelatedMaterialsToQuestion(questionID, schoolID) {
    return api
      .get(
        `special-questions/${questionID}/get_related_materials/?school=${schoolID}`
      )
      .then((response) => response.data);
  },
  deleteApplication(appID) {
    return api.delete(`material-application/${appID}/`);
  },
};
