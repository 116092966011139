<template>
  <b-container>
    <b-row class="my-2">
      <b-col>
        <b-form-radio v-model="selectedMode" :value="1"
          ><strong>Seleccionar por niveles y diagnósticos</strong></b-form-radio
        >
      </b-col>
      <b-col class="mx-0 px-0">
        <b-form-radio v-model="selectedMode" :value="2" class="mx-0 px-0"
          ><strong>Seleccionar estudiantes</strong></b-form-radio
        >
      </b-col>
    </b-row>
    <b-row style="height: 540px">
      <b-col style="overflow-y: scroll">
        <b-overlay :show="selectedMode !== 1" style="height: 100%">
          <b-container>
            <b-row> Seleccione Niveles: </b-row>
            <b-row>
              <multiselect
                v-model="selectedGrades"
                :group-select="true"
                :multiple="true"
                :options="gradesList"
                deselectLabel="Presiona INTRO para remover"
                group-label="label"
                group-values="levels"
                label="name"
                placeholder="Seleccionar nivel"
                track-by="name"
              >
                <span slot="noResult">No se ha encontrado ningún nivel</span>
              </multiselect>
            </b-row>
            <b-row> Seleccione Diagnósticos: </b-row>
            <multiselect
              v-model="selectedDiagnoses"
              :group-select="true"
              :multiple="true"
              :options="diagnosesList"
              deselectLabel="Presiona INTRO para remover"
              group-label="label"
              group-values="diagnoses"
              label="name"
              placeholder="Seleccionar diagnóstico"
              track-by="name"
            >
              <span slot="noResult"
                >No se ha encontrado ningún diagnóstico</span
              >
            </multiselect>
          </b-container>
          <template #overlay>
            <b-container>
              <b-row class="d-flex justify-content-center"> </b-row>
            </b-container>
          </template>
        </b-overlay>
      </b-col>
      <b-col style="max-height: 100%; overflow-y: scroll">
        <b-overlay
          :show="selectedMode !== 2"
          style="height: 100%"
          :class="loadingLevels ? 'd-flex align-items-center' : ''"
        >
          <b-container>
            <div
              v-if="!loadingLevels && !loadingAppStudents"
              class="accordion"
              role="tablist"
            >
              <b-card
                no-body
                class="mb-1"
                v-for="(level, lIndex) in levels"
                :key="lIndex + '-level'"
              >
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle="'accordion-modal-' + lIndex"
                    variant="unknown"
                    class="text-left"
                    >{{ level.name }}</b-button
                  >
                </b-card-header>
                <b-collapse
                  :id="'accordion-modal-' + lIndex"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div
                      v-for="(grade, gIndex) in level.data"
                      :key="gIndex + '-grade'"
                    >
                      <span
                        style="
                          width: 100%;
                          background-color: #d3d3d3;
                          display: block;
                        "
                      >
                        {{ grade.rename ? grade.rename : grade.letter }}
                      </span>
                      <div>
                        <b-button
                          class="m-1"
                          v-for="(student, sIndex) in grade.students"
                          :key="sIndex + '-student'"
                          :variant="
                            selectedStudents.includes(student.id)
                              ? 'info'
                              : 'outline-info'
                          "
                          pill
                          @click="selectStudent(student.id)"
                          :disabled="
                            appStudents.filter((e) => e.id == student.id)
                              .length > 0
                          "
                        >
                          {{ student.student_name }}
                        </b-button>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <div v-else>
              <b-row>
                <b-col class="text-center">
                  <b-spinner
                    style="width: 3rem; height: 3rem"
                    type="grow"
                    label="Loading..."
                  ></b-spinner>
                </b-col>
              </b-row>
            </div>
          </b-container>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import redpieService from "@/services/redpieService";
import informationService from "@/services/informationService";
import customMaterialService from "@/services/customMaterialService";

export default {
  created() {
    informationService.getDiagnosesList().then((data) => {
      this.diagnosesList = data.grouped_diagnoses;
    });
    informationService.getPieGrades().then((data) => {
      this.gradesList = data.grades_by_level;
    });
    redpieService
      .getSimpleSchool(
        this.$store.state.login.school,
        this.$store.state.login.year
      )
      .then((data) => {
        this.levels = data.grades;
        this.loadingLevels = false;
      });
    if (this.selectedApplication) {
      customMaterialService
        .getStudentsApplication(this.selectedApplication)
        .then((data) => {
          this.appStudents = data;
          this.loadingAppStudents = false;
        });
    } else {
      this.loadingAppStudents = false;
    }
  },
  data() {
    return {
      selectedMode: 2,
      levels: [],
      appStudents: [],
      loadingLevels: true,
      loadingAppStudents: true,
      gradesList: [],
      diagnosesList: [],
      selectedStudents: [],
      selectedGrades: [],
      selectedDiagnoses: [],
    };
  },
  props: {
    selectedApplication: {
      type: String,
      required: false,
    },
  },
  methods: {
    save() {
      var data = {};
      if (this.selectedMode === 1) {
        data["grades"] = [];
        this.selectedGrades.forEach((grade) => {
          data.grades.push(grade.code);
        });
        data["diagnoses"] = [];
        this.selectedDiagnoses.forEach((diagnosis) => {
          data.diagnoses.push(diagnosis.code);
        });
      } else if (this.selectedMode === 2) {
        data["students"] = this.selectedStudents;
      }

      this.selectedApplication
        ? this.$emit("add-students", data)
        : this.$emit("data-selected", data);
    },
    selectStudent(sgID) {
      if (this.selectedStudents.includes(sgID)) {
        this.selectedStudents.splice(this.selectedStudents.indexOf(sgID), 1);
      } else {
        this.selectedStudents.push(sgID);
      }
    },
  },
};
</script>
<style></style>
