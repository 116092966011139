<template>
  <div class="padding-box-normal">
    <b-dropdown text="SELECCIONA TU COLEGIO!">
      <b-dropdown-item
        v-for="(school, index) in userSchools"
        v-on:reload-grades="reloadData"
        :key="index"
        @click="changeSchoolID(school.id)"
      >
        {{ school.name }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import redpieService from "../../../services/redpieService";
export default {
  data() {
    return {
      userSchools: {},
    };
  },
  props: {
    reloadFunc: {
      type: Function,
      required: true,
    },
  },
  methods: {
    loadUserSchools: function() {
      redpieService.getUserSchools().then((data) => {
        this.userSchools = data;
      });
    },
    changeSchoolID: function(id) {
      this.$store.commit("login/setSchool", id);
      this.reloadData();
      this.$emit("reload-school")
    },
    reloadData: function() {
      this.reloadFunc();
    },
  },
  created() {
    this.loadUserSchools();
  },
};
</script>
