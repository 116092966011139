<template>
  <b-modal
    centered
    ref="incomponent-modal-add-document-type"
    size="xl"
    hide-footer
  >
    <b-overlay :show="loading">
      <b-container>
        <b-row>
          <h3>Agregar Nuevo Tipo de Documento</h3>
        </b-row>
        <b-row align-h="center">
          <b-col class="custom-cols" cols="3">
            Nombre del Nuevo Tipo:
          </b-col>
          <b-col>
            <b-form-input
              v-model="document_type"
              placeholder="Nombre del Tipo..."
            />
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col class="custom-cols" cols="3">
            Plantillas:
          </b-col>
          <b-col>
            <b-form-file
              v-model="templates"
              multiple
              :file-name-formatter="formatNames"
              :state="templates.length>0"
              placeholder="---"
              drop-placeholder="Arrastrar archivos aquí..."
              browse-text="Seleccionar"
            ></b-form-file>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col class="custom-cols" cols="3">
            Cursos:
          </b-col>
          <b-col>
            <multiselect
              v-model="grades"
              :options="gradesList"
              :multiple="true"
              group-values="levels"
              group-label="label"
              :group-select="true"
              placeholder="Añadir más cursos"
              select-label="Seleccionar"
              deselectLabel="Presiona INTRO para remover"
              label="name"
              track-by="name"
            >
              <span slot="noOptions">No puedes agregar mas cursos</span>
              <span slot="noResult">No se ha encontrado ningún curso</span>
            </multiselect>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col class="custom-cols" cols="3">
            Diagnósticos:
          </b-col>
          <b-col>
            <multiselect
              v-model="diagnoses"
              :options="diagnosesList"
              :multiple="true"
              group-values="diagnoses"
              group-label="label"
              :group-select="true"
              placeholder="Añadir más diagnósticos"
              select-label="Seleccionar"
              deselectLabel="Presiona INTRO para remover"
              label="name"
              track-by="name"
            >
              <span slot="noOptions">No puedes agregar mas diagnósticos</span>
              <span slot="noResult">No se ha encontrado ningún diagnóstico</span>
            </multiselect>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col class="custom-cols" cols="3">
            Es multi:
          </b-col>
          <b-col>
            <b-form-checkbox
              id="checkbox-1"
              v-model="is_multi"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
            />
          </b-col>
        </b-row>
        <div v-if="is_multi">
          <b-row v-for="(template, tindex) in templates" :key="tindex">
            <b-container>
              <b-row>
                Templates Online para template: {{template.name}}
              </b-row>
              <b-container v-for="(temp_online, toindex) in template_questions" :key="toindex">
                <b-row>
                  <b-col class="custom-cols" cols="3">
                    Nombre de template online:
                  </b-col>
                  <b-col>
                    <b-form-input v-model="temp_online.template_name">
                    </b-form-input>
                  </b-col>
                  <b-col>
                    <multiselect
                      v-model="temp_online.grades"
                      :options="gradesList"
                      :multiple="true"
                      group-values="levels"
                      group-label="label"
                      :group-select="true"
                      placeholder="Cursos"
                      select-label="Seleccionar"
                      deselectLabel="Presiona INTRO para remover"
                      label="name"
                      track-by="name"
                    >
                      <span slot="noOptions">No puedes agregar mas cursos</span>
                      <span slot="noResult">No se ha encontrado ningún curso</span>
                    </multiselect>
                  </b-col>
                  <b-col>
                    <multiselect
                      v-model="temp_online.diagnoses"
                      :options="diagnosesList"
                      :multiple="true"
                      group-values="diagnoses"
                      group-label="label"
                      :group-select="true"
                      placeholder="Diagnósticos"
                      select-label="Seleccionar"
                      deselectLabel="Presiona INTRO para remover"
                      label="name"
                      track-by="name"
                    >
                      <span slot="noOptions">No puedes agregar mas diagnósticos</span>
                      <span slot="noResult">No se ha encontrado ningún diagnóstico</span>
                    </multiselect>
                  </b-col>
                </b-row>
                <b-card v-for="(q, index) in temp_online.questions" :key="index" no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle="'accordion-'+index" variant="unknown">Pregunta {{index + 1}}: 
                      {{q.question!==''?q.question:'No definida'}}
                    </b-button>
                  </b-card-header>
                  <b-collapse :id="'accordion-'+index" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <b-row>
                        <b-col class="custom-cols" cols="3">
                          Pregunta:
                        </b-col>
                        <b-col>
                          <b-form-input v-model="q.question">
                          </b-form-input>
                        </b-col>
                      </b-row>
                      <b-row v-for="(a, aindex) in q.answers" :key="aindex">
                        <b-col class="custom-cols" cols="3">
                          Respuesta {{aindex+1}}:
                        </b-col>
                        <b-col>
                          <b-form-input v-model="a.answer">
                          </b-form-input>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="text-center">
                          <b-button variant="primary" pill @click="q.answers.push({answer: ''})">
                            Añadir respuesta
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-row align-h="center" v-if="is_multi">
                  <b-col class="text-center">
                    <b-button variant="primary" pill @click="temp_online.questions.push({answers: [], question: ''})">
                      Añadir pregunta
                    </b-button>
                  </b-col>
                </b-row>
              </b-container>
              <b-row align-h="center" v-if="is_multi">
                <b-col class="text-center">
                  <b-button variant="primary" pill @click="template_questions.push(
                    {template: template.name, template_name: '', questions: [], diagnoses: [], grades: []})">
                    Añadir Template Online
                  </b-button>
                </b-col>
              </b-row>
            </b-container>
          </b-row>
        </div>
        <!-- <b-card v-for="(q, index) in questions" :key="index" no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle="'accordion-'+index" variant="unknown">Pregunta {{index + 1}}: 
              {{q.question!==''?q.question:'No definida'}}
            </b-button>
          </b-card-header>
          <b-collapse :id="'accordion-'+index" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-row>
                <b-col class="custom-cols" cols="3">
                  Pregunta:
                </b-col>
                <b-col>
                  <b-form-input v-model="q.question">
                  </b-form-input>
                </b-col>
              </b-row>
              <b-row v-for="(a, aindex) in q.answers" :key="aindex">
                <b-col class="custom-cols" cols="3">
                  Respuesta {{aindex+1}}:
                </b-col>
                <b-col>
                  <b-form-input v-model="a.answer">
                  </b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-center">
                  <b-button variant="primary" pill @click="q.answers.push({answer: ''})">
                    Añadir respuesta
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-row align-h="center" v-if="is_multi">
          <b-col class="text-center">
            <b-button variant="primary" pill @click="questions.push({answers: [], question: ''})">
              Añadir pregunta
            </b-button>
          </b-col>
        </b-row> -->
        <b-row align-h="end" style="padding-right: 15px;">
          <b-button variant="primary" @click="createType">
            Crear Tipo
          </b-button>
        </b-row>
      </b-container>
      <template #overlay>
        <b-container>
          <b-row class="d-flex justify-content-center">
            <b-img :src="loadingGif" rounded alt="Loading image"/>
          </b-row>
        </b-container>
      </template>
    </b-overlay>
  </b-modal>
</template>
<script>
import informationService from "../../../services/informationService";
import redpieService from "../../../services/redpieService";

export default {
  components: {
  },
  data () {
    return {
      loadingGif: require('../../../assets/img/preloader.gif'),
      document_type: "",
      templates: [],
      grades: [],
      diagnoses: [],
      gradesList: [],
      diagnosesList: [],
      loading: false,
      is_multi: false,
      template_questions: []
    }
  },
  created() {
    this.getDiagnosesList();
    this.getPieGrades();
  },
  mounted() {
  },
  methods: {
    clearFields() {
      this.document_type = "";
      this.templates = [];
      this.grades = [];
      this.diagnoses = [];
      this.template_questions = [];
      this.is_multi = false;
    },
    open() {
      this.clearFields();
      this.$refs["incomponent-modal-add-document-type"].show();
    },
    getDiagnosesList: function() {
      informationService.getDiagnosesList().then(
        data => {
          this.diagnosesList = data.grouped_diagnoses
        }
      )
    },
    getPieGrades: function() {
      informationService.getPieGrades().then(
        data => {
          this.gradesList = data.grades_by_level
        }
      )
    },
    formatNames: function(files) {
      if (files.length === 1) {
        return files[0].name
      } else {
        return `${files.length} archivos`
      }
    },
    createType: function () {
      this.loading = true;
      let formData = new FormData();
      formData.append("year", this.$store.state.login.year)
      if (this.document_type === "") {
        this.$toasted.error("Debes escribir un nombre para tu nuevo tipo!");
        this.loading = false;
        return ;
      }
      else {
        formData.append("new_document_type", this.document_type);
      }
      if (this.templates.length === 0) {
        this.$toasted.error("Debes agregar templates!");
        this.loading = false;
        return;
      }
      else {
        for (var i=0; i < this.templates.length; i++)
          formData.append("templates", this.templates[i])
      }
      if (this.grades.length === 0) {
        this.$toasted.error("Debes agregar cursos!");
        this.loading = false;
        return;
      }
      else {
        for (var j=0; j < this.grades.length; j++)
          formData.append("grades", this.grades[j].code)
      }
      if (this.diagnoses.length === 0) {
        this.$toasted.error("Debes agregar diagnósticos!");
        this.loading = false;
        return;
      }
      else {
        for (var k=0; k < this.diagnoses.length; k++)
          formData.append("diagnostics", this.diagnoses[k].code)
      }
      formData.append("is_multi", this.is_multi);
      if (this.is_multi) {
        for (var l=0; l < this.template_questions.length; l++)
          formData.append("template_questions", JSON.stringify(this.template_questions[l]))
      }
      formData.append("school_id", this.$store.state.login.school)
      redpieService.uploadDocumentTemplates(formData).then(
        data => {
          this.loading = false;
          this.$emit("reload")
          this.$toasted.success(data.message);
          this.clearFields();
        },
        error => {
            var message = "Ha ocurrido un error al crear el tipo de documento";
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
              message = error.response.data.message;
            }
            this.loading = false;
            this.$toasted.error(message);
          }
      )
    }
  },
  watch: {
  }
}
</script>
<style lang="scss">
.row {
  padding-bottom: 5px;

  .custom-cols {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 0px !important;
  }
}
</style>
