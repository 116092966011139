<template>
    <svg version="1.1" id="Layer_1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    :viewBox="viewbox"
    :width="width"
    :height="height"
    xml:space="preserve"
    class="custom-icon-button">
  
<g>
	<!-- <circle class="st0-clip" cx="43" cy="43" r="43"/> -->
    <path class="cls-1" d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Zm0,92.93a15,15,0,1,1-15,15,15,15,0,0,1,15-15Zm0-46.47a15,15,0,1,1-15,15,15,15,0,0,1,15-15Z"/>
</g>

  

  </svg>
  </template>
  
  <script>
  export default {
    name: 'ThreeDotsIcon',
    props: {
        viewbox: {
              type: String,
              default: "0 0 18 18"
        },
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },

    },
   
  }
  </script>
  
<style>
.cls-1{
    fill:#FFFFFF;
}
</style>