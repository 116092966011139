import api from "@/services/api";

export default {
  getDiagnosesList() {
    return api.get("get_diagnoses_list/").then((response) => response.data);
  },
  getShortenDiagnoses() {
    return api.get("get_shorten_diagnoses/").then((response) => response.data);
  },
  getDiagnosesGroupedList() {
    return api
      .get("get_diagnoses_grouped_list/")
      .then((response) => response.data);
  },
  getGradeLevelsRenames(schoolID) {
    return api
      .get(`schools/${schoolID}/get_levels_renames/`)
      .then((response) => response.data);
  },
  getPieGrades() {
    return api.get("get_grades_list/").then((response) => response.data);
  },
  getSubjects() {
    return api.get("get_subjects_list/").then((response) => response.data);
  },
  getCountries() {
    return api.get("get_countries/").then((response) => response.data);
  },
  getUsers() {
    return api.get("get_users/").then((response) => response.data);
  },
  getRoles() {
    return api.get("get_roles/").then((response) => response.data);
  },
  getMaterialsRoles() {
    return api.get("get_materials_roles/").then((response) => response.data);
  },
  getRegions(country) {
    return api.get(`get_regions/${country}/`).then((response) => response.data);
  },
  getCardSubjects() {
    return api.get(`get_card_subjects/`).then((response) => response.data);
  },
  getCardSubjectsValues() {
    return api
      .get(`get_card_subjects_values/`)
      .then((response) => response.data);
  },
  getCardGrades() {
    return api.get(`get_card_grades/`).then((response) => response.data);
  },
  ping() {
    return api.get("ping/").then((response) => response.data);
  },
  download_sige_template() {
    return api
      .post("download_sige_template/", {}, { responseType: "blob" })
      .then((response) => response);
  },
  getEjesValues(subject, level) {
    return api
      .get(`get_ejes/?subject=${subject}&level=${level}`)
      .then((response) => response.data);
  },
};
