var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.material.is_active ? '' : 'recycle'},[_c('b-card',{attrs:{"header-bg-variant":_vm.material.profession === 2
        ? 'danger'
        : _vm.material.profession === 5
        ? 'secondary'
        : 'primary',"id":_vm.material.id,"header-text-variant":"white","no-body":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-form-row',{attrs:{"no-gutters":""}},[_c('b-col',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.$emit('open-material')}}},[_vm._v(_vm._s(_vm.material.name.length > 18 ? _vm.material.name.substring(0, 15) + "..." : _vm.material.name))]),(_vm.mode === 'material-list' && _vm.material.is_active)?_c('b-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mx-auto",attrs:{"variant":"unknown","size":"sm","title":"Eliminar material"},on:{"click":function($event){return _vm.$emit('delete-material')}}},[_c('v-icon',{class:_vm.material.profession === 2
                  ? 'text-warning'
                  : _vm.material.profession === 5
                  ? 'text-warning'
                  : 'text-danger',attrs:{"name":"trash-alt"}})],1)],1):_vm._e()],1)]},proxy:true}])},[_c('b-card-body',[_c('b-form-row',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticStyle:{"cursor":"pointer"},attrs:{"title":_vm.mode !== 'appModal' ? 'Editar Material' : 'Aplicar Material'},on:{"click":function($event){return _vm.$emit('open-material')}}},[_c('b-col',{staticClass:"text-center px-0",attrs:{"cols":"4","offset":"3"}},[_c('b-img',{attrs:{"src":_vm.material.profession === 2
                ? _vm.icon
                : _vm.material.profession === 5
                ? _vm.icon2
                : _vm.icon3,"height":"100"}})],1),_c('b-col',{staticClass:"text-left px-0",attrs:{"cols":"5","align-self":"end"}},[_c('b-badge',{attrs:{"variant":_vm.material.profession === 2
                ? 'danger'
                : _vm.material.profession === 5
                ? 'secondary'
                : 'primary'}},[_vm._v(_vm._s(_vm.material.duration)+" minutos")])],1)],1)],1),(_vm.mode !== 'appModal' && _vm.material.is_active)?_c('b-card-footer',{staticClass:"bg-transparent"},[_c('b-button',{staticClass:"mr-0",attrs:{"pill":"","variant":_vm.material.profession === 2
            ? 'danger'
            : _vm.material.profession === 5
            ? 'secondary'
            : 'primary',"block":""},on:{"click":function($event){return _vm.$emit('apply-material')}}},[_vm._v(" Aplicar ")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }