<template>
  <div style="height: auto">
    <b-card bg-variant="light">
      <p>
        No es necesario que completes las respuestas correctas, se
        autocompletarán si las dejas en blanco
      </p>
      <div
        v-for="(item, index) in test[answer.step]"
        :key="index + '-' + answer.step"
      >
        <h3 v-if="item.type === 'h3'">{{ item.label }}</h3>
        <h4 v-else-if="item.type === 'h4'">{{ item.label }}</h4>
        <h2 v-else-if="item.type === 'h2'">{{ item.label }}</h2>
        <b-form-group
          v-else
          class="mb-0"
          :label="item.label"
          :label-for="item.var"
          label-cols-sm="4"
          label-align-sm="right"
          label-size="sm"
        >
          <b-form-input
            v-if="item.type === 'text'"
            size="sm"
            v-model="answer.answers[item.var]"
            :id="item.var"
          ></b-form-input>
          <b-form-input
            v-if="item.type === 'number'"
            type="number"
            size="sm"
            v-model="answer.answers[item.var]"
            :id="item.var"
          ></b-form-input>
          <b-form-checkbox
            v-if="item.type === 'checkbox'"
            size="sm"
            v-model="answer.answers[item.var]"
            :id="item.var"
          ></b-form-checkbox>
          <b-form-select
            v-if="item.type === 'select'"
            size="sm"
            v-model="answer.answers[item.var]"
            :options="item.options"
            :state="checkState(item)"
          ></b-form-select>
          <div v-if="item.type === 'radio'" class="d-flex flex-row">
            <div
              v-for="option in item.options"
              :key="option.value + '-' + item.var + '-' + answer.step"
              class="mr-3"
            >
              <b-form-radio
                :id="`radio-${item.var}-${option.value}`"
                :name="item.var"
                :value="option.value"
                v-model="answer.answers[item.var]"
                >{{ option.text }}</b-form-radio
              >
            </div>
            <strong>Correcta: {{ correctAnswer(item.options) }}</strong>
          </div>
          <b-form-textarea
            v-if="item.type === 'textarea'"
            :id="item.var"
            v-model="answer.answers[item.var]"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
      </div>
    </b-card>
    <b-button @click="send_test">{{
      answer.step == 14 ? "Corregir Test" : "Siguiente paso"
    }}</b-button>
  </div>
</template>
<script>
import testService from "../../../../services/testService";
import redpieService from "../../../../services/redpieService";

export default {
  props: ["test_id", "student_id", "previous_answer", "semester"],
  mounted() {
    this.loadPreviousAnswers();
  },
  watch: {
    step() {
      this.loadPreviousAnswers();
    },
    student_id() {
      this.answer.student_id = this.student_id;
    },
    test_id() {
      this.answer.test = this.test_id;
    },
  },
  data() {
    return {
      answer: {
        test: this.test_id,
        answers: {},
        student_id: this.student_id,
        step: 0,
      },
      test: [
        [
          { type: "h2", label: "Microdominio fonológico" },

          { type: "h4", label: "reconocimiento prosódico" },

          {
            label: "Edad:",
            type: "select",
            var: "Rango_Etario",
            options: [
              { value: "a", text: "6 - 7 años, 11 meses" },

              { value: "b", text: "8 - 9 años, 11 meses" },
            ],
          },

          {
            type: "radio",
            var: "p1",
            label: "P1:",
            options: [
              { value: "0", text: "A" },
              { value: "1", text: "B" },
              { value: "00", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p2",
            label: "P2:",
            options: [
              { value: "0", text: "A" },
              { value: "00", text: "B" },
              { value: "1", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p3",
            label: "P3:",
            options: [
              { value: "0", text: "A" },
              { value: "1", text: "B" },
              { value: "00", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p4",
            label: "P4:",
            options: [
              { value: "0", text: "A" },
              { value: "00", text: "B" },
              { value: "1", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p5",
            label: "P5:",
            options: [
              { value: "0", text: "A" },
              { value: "00", text: "B" },
              { value: "1", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p6",
            label: "P6:",
            options: [
              { value: "1", text: "A" },
              { value: "0", text: "B" },
              { value: "00", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p7",
            label: "P7:",
            options: [
              { value: "0", text: "A" },
              { value: "1", text: "B" },
              { value: "00", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p8",
            label: "P8:",
            options: [
              { value: "0", text: "A" },
              { value: "00", text: "B" },
              { value: "1", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p9",
            label: "P9:",
            options: [
              { value: "0", text: "A" },
              { value: "00", text: "B" },
              { value: "1", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p10",
            label: "P10:",
            options: [
              { value: "1", text: "A" },
              { value: "0", text: "B" },
              { value: "00", text: "C" },
            ],
          },
        ],

        [
          { type: "h2", label: "Microdominio fonológico" },

          { type: "h4", label: "adición silábica" },

          {
            type: "radio",
            var: "p1",
            label: "P1:",
            options: [
              { value: "1", text: "LE" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p2",
            label: "P2:",
            options: [
              { value: "1", text: "FE" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p3",
            label: "P3:",
            options: [
              { value: "1", text: "RE" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p4",
            label: "P4:",
            options: [
              { value: "1", text: "PE" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p5",
            label: "P5:",
            options: [
              { value: "1", text: "PI" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p6",
            label: "P6:",
            options: [
              { value: "1", text: "RE" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p7",
            label: "P7:",
            options: [
              { value: "1", text: "BLO" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p8",
            label: "P8:",
            options: [
              { value: "1", text: "BO" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p9",
            label: "P9:",
            options: [
              { value: "1", text: "LO" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p10",
            label: "P10:",
            options: [
              { value: "1", text: "TE" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p11",
            label: "P11:",
            options: [
              { value: "1", text: "LI" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p12",
            label: "P12:",
            options: [
              { value: "1", text: "LE" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p13",
            label: "P13:",
            options: [
              { value: "1", text: "TE" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p14",
            label: "P14:",
            options: [
              { value: "1", text: "PI" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p15",
            label: "P15:",
            options: [
              { value: "1", text: "FI" },
              { value: "0", text: "Otro" },
            ],
          },
        ],

        [
          { type: "h2", label: "Microdominio fonológico" },

          { type: "h4", label: "inversión_silábica" },

          {
            type: "radio",
            var: "p1",
            label: "P1:",
            options: [
              { value: "1", text: "Caballo" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p2",
            label: "P2:",
            options: [
              { value: "1", text: "Pantalón" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p3",
            label: "P3:",
            options: [
              { value: "1", text: "Pirata" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p4",
            label: "P4:",
            options: [
              { value: "1", text: "Cuaderno" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p5",
            label: "P5:",
            options: [
              { value: "1", text: "Camisa" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p6",
            label: "P6:",
            options: [
              { value: "1", text: "Pelota" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p7",
            label: "P7:",
            options: [
              { value: "1", text: "Estuche" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p8",
            label: "P8:",
            options: [
              { value: "1", text: "Pizarra" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p9",
            label: "P9:",
            options: [
              { value: "1", text: "Carpeta" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p10",
            label: "P10:",
            options: [
              { value: "1", text: "Sirena" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p11",
            label: "P11:",
            options: [
              { value: "1", text: "Pintura" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p12",
            label: "P12:",
            options: [
              { value: "1", text: "Peineta" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p13",
            label: "P13:",
            options: [
              { value: "1", text: "Serrucho" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p14",
            label: "P14:",
            options: [
              { value: "1", text: "Escoba" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p15",
            label: "P15:",
            options: [
              { value: "1", text: "Caracol" },
              { value: "0", text: "Otro" },
            ],
          },
        ],

        [
          { type: "h2", label: "Microdominio fonológico" },

          { type: "h4", label: "unión de fonemas" },

          {
            type: "radio",
            var: "p1",
            label: "P1:",
            options: [
              { value: "1", text: "Sol" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p2",
            label: "P2:",
            options: [
              { value: "1", text: "Café" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p3",
            label: "P3:",
            options: [
              { value: "1", text: "Ratón" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p4",
            label: "P4:",
            options: [
              { value: "1", text: "Camisa" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p5",
            label: "P5:",
            options: [
              { value: "1", text: "Mueble" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p6",
            label: "P6:",
            options: [
              { value: "1", text: "Alcalde" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p7",
            label: "P7:",
            options: [
              { value: "1", text: "Palmera" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p8",
            label: "P8:",
            options: [
              { value: "1", text: "Elefante" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p9",
            label: "P9:",
            options: [
              { value: "1", text: "Película" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p10",
            label: "P10:",
            options: [
              { value: "1", text: "Plástico" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p11",
            label: "P11:",
            options: [
              { value: "1", text: "Micrófono" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p12",
            label: "P12:",
            options: [
              { value: "1", text: "Renacuajo" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p13",
            label: "P13:",
            options: [
              { value: "1", text: "Computador" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p14",
            label: "P14:",
            options: [
              { value: "1", text: "Dinosaurio" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p15",
            label: "P15:",
            options: [
              { value: "1", text: "Ferrocarril" },
              { value: "0", text: "Otro" },
            ],
          },
        ],

        [
          { type: "h2", label: "Microdominio morfosintáctico" },

          { type: "h4", label: "ejercicio de modelado" },

          {
            type: "radio",
            var: "p1",
            label: "P1:",
            options: [
              { value: "1", text: "C" },
              { value: "0", text: "I" },
            ],
          },

          {
            type: "radio",
            var: "p2",
            label: "P2:",
            options: [
              { value: "1", text: "C" },
              { value: "0", text: "I" },
            ],
          },

          {
            type: "radio",
            var: "p3",
            label: "P3:",
            options: [
              { value: "1", text: "C" },
              { value: "0", text: "I" },
            ],
          },

          {
            type: "radio",
            var: "p4",
            label: "P4:",
            options: [
              { value: "1", text: "C" },
              { value: "0", text: "I" },
            ],
          },

          {
            type: "radio",
            var: "p5",
            label: "P5:",
            options: [
              { value: "1", text: "C" },
              { value: "0", text: "I" },
            ],
          },

          {
            type: "radio",
            var: "p6",
            label: "P6:",
            options: [
              { value: "1", text: "C" },
              { value: "0", text: "I" },
            ],
          },

          {
            type: "radio",
            var: "p7",
            label: "P7:",
            options: [
              { value: "1", text: "C" },
              { value: "0", text: "I" },
            ],
          },

          {
            type: "radio",
            var: "p8",
            label: "P8:",
            options: [
              { value: "1", text: "C" },
              { value: "0", text: "I" },
            ],
          },

          {
            type: "radio",
            var: "p9",
            label: "P9:",
            options: [
              { value: "1", text: "C" },
              { value: "0", text: "I" },
            ],
          },

          {
            type: "radio",
            var: "p10",
            label: "P10:",
            options: [
              { value: "1", text: "C" },
              { value: "0", text: "I" },
            ],
          },

          {
            type: "radio",
            var: "p11",
            label: "P11:",
            options: [
              { value: "1", text: "C" },
              { value: "0", text: "I" },
            ],
          },

          {
            type: "radio",
            var: "p12",
            label: "P12:",
            options: [
              { value: "1", text: "C" },
              { value: "0", text: "I" },
            ],
          },

          {
            type: "radio",
            var: "p13",
            label: "P13:",
            options: [
              { value: "1", text: "C" },
              { value: "0", text: "I" },
            ],
          },

          {
            type: "radio",
            var: "p14",
            label: "P14:",
            options: [
              { value: "1", text: "C" },
              { value: "0", text: "I" },
            ],
          },

          {
            type: "radio",
            var: "p15",
            label: "P15:",
            options: [
              { value: "1", text: "C" },
              { value: "0", text: "I" },
            ],
          },
        ],

        [
          { type: "h2", label: "Microdominio morfosintáctico" },

          { type: "h4", label: "sufijación de profesiones" },

          {
            type: "radio",
            var: "p1",
            label: "P1:",
            options: [
              { value: "1", text: "Artista" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p2",
            label: "P2:",
            options: [
              { value: "1", text: "Pintor" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p3",
            label: "P3:",
            options: [
              { value: "1", text: "Relojero" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p4",
            label: "P4:",
            options: [
              { value: "1", text: "Zapatero" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p5",
            label: "P5:",
            options: [
              { value: "1", text: "Diseñador" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p6",
            label: "P6:",
            options: [
              { value: "1", text: "Verdulero" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p7",
            label: "P7:",
            options: [
              { value: "1", text: "Carnicero" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p8",
            label: "P8:",
            options: [
              { value: "1", text: "Pastelero" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p9",
            label: "P9:",
            options: [
              { value: "1", text: "Joyero" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p10",
            label: "P10:",
            options: [
              { value: "1", text: "Tejedor" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p11",
            label: "P11:",
            options: [
              { value: "1", text: "Jardinero" },
              { value: "0", text: "Otro" },
            ],
          },
        ],

        [
          { type: "h2", label: "Microdominio morfosintáctico" },

          { type: "h4", label: "sufijación de aumentativos" },

          {
            type: "radio",
            var: "p1",
            label: "P1:",
            options: [
              { value: "1", text: "Altísimo" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p2",
            label: "P2:",
            options: [
              { value: "1", text: "Flaquísimo" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p3",
            label: "P3:",
            options: [
              { value: "1", text: "Carísimo" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p4",
            label: "P4:",
            options: [
              { value: "1", text: "Manotas" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p5",
            label: "P5:",
            options: [
              { value: "1", text: "Hermosísima" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p6",
            label: "P6:",
            options: [
              { value: "1", text: "Esforzadísimo" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p7",
            label: "P7:",
            options: [
              { value: "1", text: "Simpatiquísimas" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p8",
            label: "P8:",
            options: [
              { value: "1", text: "Inteligentísimas" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p9",
            label: "P9:",
            options: [
              { value: "1", text: "Larguísimas" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p10",
            label: "P10:",
            options: [
              { value: "1", text: "Estudiosísimas" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p11",
            label: "P11:",
            options: [
              { value: "1", text: "Pesadísimas" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p12",
            label: "P12:",
            options: [
              { value: "1", text: "Viejísimas" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p13",
            label: "P13:",
            options: [
              { value: "1", text: "Cariñosísima" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p14",
            label: "P14:",
            options: [
              { value: "1", text: "Estrictísima" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p15",
            label: "P15:",
            options: [
              { value: "1", text: "Peligrosísimo" },
              { value: "0", text: "Otro" },
            ],
          },
        ],

        [
          { type: "h2", label: "Microdominio semántico" },

          { type: "h4", label: "relación atributiva" },

          {
            type: "radio",
            var: "p1",
            label: "P1:",
            options: [
              { value: "1", text: "Rectangular" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p2",
            label: "P2:",
            options: [
              { value: "1", text: "Liviana" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p3",
            label: "P3:",
            options: [
              { value: "1", text: "Saladas" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p4",
            label: "P4:",
            options: [
              { value: "1", text: "Blanda" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p5",
            label: "P5:",
            options: [
              { value: "1", text: "Oscuridad" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p6",
            label: "P6:",
            options: [
              { value: "1", text: "Hembra" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p7",
            label: "P7:",
            options: [
              { value: "1", text: "Cuero" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p8",
            label: "P8:",
            options: [
              { value: "1", text: "Buena" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p9",
            label: "P9:",
            options: [
              { value: "1", text: "Áspera" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p10",
            label: "P10:",
            options: [
              { value: "1", text: "Barato" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p11",
            label: "P11:",
            options: [
              { value: "1", text: "Hunde" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p12",
            label: "P12:",
            options: [
              { value: "1", text: "Mar" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p13",
            label: "P13:",
            options: [
              { value: "1", text: "Real, Verdadero" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p14",
            label: "P14:",
            options: [
              { value: "1", text: "Tersa, Lisa" },
              { value: "0", text: "Otro" },
            ],
          },
        ],

        [
          { type: "h2", label: "Microdominio semántico" },

          { type: "h4", label: "relación funcional" },

          {
            type: "radio",
            var: "p1",
            label: "P1:",
            options: [
              { value: "1", text: "medir" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p2",
            label: "P2:",
            options: [
              { value: "1", text: "navega" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p3",
            label: "P3:",
            options: [
              { value: "1", text: "nadar" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p4",
            label: "P4:",
            options: [
              { value: "1", text: "segundos" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p5",
            label: "P5:",
            options: [
              { value: "1", text: "aire" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p6",
            label: "P6:",
            options: [
              { value: "1", text: "curan, sanan" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p7",
            label: "P7:",
            options: [
              { value: "1", text: "acusan" },
              { value: "0", text: "Otro" },
            ],
          },
        ],

        [
          { type: "h2", label: "Microdominio semántico" },

          { type: "h4", label: "relación de metonimia" },

          {
            type: "radio",
            var: "p1",
            label: "P1:",
            options: [
              { value: "1", text: "rodilla" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p2",
            label: "P2:",
            options: [
              { value: "1", text: "alas" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p3",
            label: "P3:",
            options: [
              { value: "1", text: "cuesco" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p4",
            label: "P4:",
            options: [
              { value: "1", text: "años" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p5",
            label: "P5:",
            options: [
              { value: "1", text: "días" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p6",
            label: "P6:",
            options: [
              { value: "1", text: "tentáculos" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p7",
            label: "P7:",
            options: [
              { value: "1", text: "centímetros" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p8",
            label: "P8:",
            options: [
              { value: "1", text: "sangre" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p9",
            label: "P9:",
            options: [
              { value: "1", text: "hocico" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p10",
            label: "P10:",
            options: [
              { value: "1", text: "oficinas" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p11",
            label: "P11:",
            options: [
              { value: "1", text: "palabras" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p12",
            label: "P12:",
            options: [
              { value: "1", text: "segundos" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p13",
            label: "P13:",
            options: [
              { value: "1", text: "alcaldes" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p14",
            label: "P14:",
            options: [
              { value: "1", text: "planetas" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p15",
            label: "P15:",
            options: [
              { value: "1", text: "actores" },
              { value: "0", text: "Otro" },
            ],
          },
        ],

        [
          { type: "h2", label: "Microdominio semántico" },

          { type: "h4", label: "formación de conceptos" },

          {
            type: "radio",
            var: "p1",
            label: "P1:",
            options: [
              { value: "1", text: "Líquidos" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p2",
            label: "P2:",
            options: [
              { value: "1", text: "Meses del año" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p3",
            label: "P3:",
            options: [
              { value: "1", text: "Profesiones" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p4",
            label: "P4:",
            options: [
              { value: "1", text: "Negocios" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p5",
            label: "P5:",
            options: [
              { value: "1", text: "Elementos para cortar" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p6",
            label: "P6:",
            options: [
              { value: "1", text: "Festividades" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p7",
            label: "P7:",
            options: [
              { value: "1", text: "Dinosaurios" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p8",
            label: "P8:",
            options: [
              { value: "1", text: "Enfermedades" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p9",
            label: "P9:",
            options: [
              { value: "1", text: "Presidentes de Chile" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p10",
            label: "P10:",
            options: [
              { value: "1", text: "Océanos" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p11",
            label: "P11:",
            options: [
              { value: "1", text: "Países europeos/Países" },
              { value: "0", text: "Otro" },
            ],
          },

          {
            type: "radio",
            var: "p12",
            label: "P12:",
            options: [
              { value: "1", text: "Monedas/Dinero" },
              { value: "0", text: "Otro" },
            ],
          },
        ],

        [
          { type: "h2", label: "Microdominio semántico" },

          { type: "h4", label: "reconocimiento de conceptos" },

          {
            type: "radio",
            var: "p1",
            label: "P1:",
            options: [
              { value: "1", text: "Alfombra" },
              { value: "0", text: "Puerta" },
              { value: "00", text: "Ventana" },
              { value: "000", text: "Techo" },
            ],
          },

          {
            type: "radio",
            var: "p2",
            label: "P2:",
            options: [
              { value: "0", text: "Teclado" },
              { value: "00", text: "Disco Duro" },
              { value: "1", text: "Pendrive" },
              { value: "000", text: "Pantalla" },
            ],
          },

          {
            type: "radio",
            var: "p3",
            label: "P3:",
            options: [
              { value: "0", text: "Rosa" },
              { value: "00", text: "Clavel" },
              { value: "1", text: "Pino" },
              { value: "000", text: "Margarita" },
            ],
          },

          {
            type: "radio",
            var: "p4",
            label: "P4:",
            options: [
              { value: "0", text: "Tigre" },
              { value: "1", text: "Lobo" },
              { value: "00", text: "Puma" },
              { value: "000", text: "Gato" },
            ],
          },

          {
            type: "radio",
            var: "p5",
            label: "P5:",
            options: [
              { value: "0", text: "Limón" },
              { value: "1", text: "Plátano" },
              { value: "00", text: "Naranja" },
              { value: "000", text: "Pomelo" },
            ],
          },

          {
            type: "radio",
            var: "p6",
            label: "P6:",
            options: [
              { value: "1", text: "Martillo" },
              { value: "0", text: "Lápices" },
              { value: "00", text: "Regla" },
              { value: "000", text: "Plumones" },
            ],
          },

          {
            type: "radio",
            var: "p7",
            label: "P7:",
            options: [
              { value: "0", text: "Flauta" },
              { value: "00", text: "Saxofón" },
              { value: "1", text: "Timbal" },
              { value: "000", text: "Armónica" },
            ],
          },

          {
            type: "radio",
            var: "p8",
            label: "P8:",
            options: [
              { value: "0", text: "Corazón" },
              { value: "00", text: "Pulmones" },
              { value: "1", text: "Oídos" },
              { value: "000", text: "Hígado" },
            ],
          },

          {
            type: "radio",
            var: "p9",
            label: "P9:",
            options: [
              { value: "1", text: "Avión" },
              { value: "0", text: "Camión" },
              { value: "00", text: "Automóvil" },
              { value: "000", text: "Carreta" },
            ],
          },

          {
            type: "radio",
            var: "p10",
            label: "P10:",
            options: [
              { value: "0", text: "León" },
              { value: "1", text: "Serpiente" },
              { value: "00", text: "Caballo" },
              { value: "000", text: "Vaca" },
            ],
          },

          {
            type: "radio",
            var: "p11",
            label: "P11:",
            options: [
              { value: "0", text: "Queso" },
              { value: "00", text: "Yogurt" },
              { value: "1", text: "Jugo" },
              { value: "000", text: "Crema" },
            ],
          },

          {
            type: "radio",
            var: "p12",
            label: "P12:",
            options: [
              { value: "0", text: "Teléfono" },
              { value: "00", text: "Computador" },
              { value: "1", text: "Imprenta" },
              { value: "000", text: "Televisor" },
            ],
          },
        ],

        [
          { type: "h2", label: "Microdominio semántico" },

          { type: "h4", label: "razonamiento inferencial" },

          {
            type: "radio",
            var: "p1",
            label: "P1:",
            options: [
              { value: "1", text: "A" },
              { value: "0", text: "B" },
              { value: "00", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p2",
            label: "P2:",
            options: [
              { value: "0", text: "A" },
              { value: "1", text: "B" },
              { value: "00", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p3",
            label: "P3:",
            options: [
              { value: "1", text: "A" },
              { value: "0", text: "B" },
              { value: "00", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p4",
            label: "P4:",
            options: [
              { value: "0", text: "A" },
              { value: "1", text: "B" },
              { value: "00", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p5",
            label: "P5:",
            options: [
              { value: "0", text: "A" },
              { value: "00", text: "B" },
              { value: "1", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p6",
            label: "P6:",
            options: [
              { value: "0", text: "A" },
              { value: "00", text: "B" },
              { value: "1", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p7",
            label: "P7:",
            options: [
              { value: "1", text: "A" },
              { value: "0", text: "B" },
              { value: "00", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p8",
            label: "P8:",
            options: [
              { value: "0", text: "A" },
              { value: "00", text: "B" },
              { value: "1", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p9",
            label: "P9:",
            options: [
              { value: "0", text: "A" },
              { value: "1", text: "B" },
              { value: "00", text: "C" },
            ],
          },
        ],

        [
          { type: "h2", label: "Microdominio pragmático" },

          { type: "h4", label: "frases hechas" },

          {
            type: "radio",
            var: "p1",
            label: "P1:",
            options: [
              { value: "0", text: "A" },
              { value: "1", text: "B" },
              { value: "00", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p2",
            label: "P2:",
            options: [
              { value: "0", text: "A" },
              { value: "00", text: "B" },
              { value: "1", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p3",
            label: "P3:",
            options: [
              { value: "1", text: "A" },
              { value: "0", text: "B" },
              { value: "00", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p4",
            label: "P4:",
            options: [
              { value: "0", text: "A" },
              { value: "1", text: "B" },
              { value: "00", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p5",
            label: "P5:",
            options: [
              { value: "1", text: "A" },
              { value: "0", text: "B" },
              { value: "00", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p6",
            label: "P6:",
            options: [
              { value: "0", text: "A" },
              { value: "1", text: "B" },
              { value: "00", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p7",
            label: "P7:",
            options: [
              { value: "0", text: "A" },
              { value: "00", text: "B" },
              { value: "1", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p8",
            label: "P8:",
            options: [
              { value: "0", text: "A" },
              { value: "00", text: "B" },
              { value: "1", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p9",
            label: "P9:",
            options: [
              { value: "1", text: "A" },
              { value: "0", text: "B" },
              { value: "00", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p10",
            label: "P10:",
            options: [
              { value: "0", text: "A" },
              { value: "1", text: "B" },
              { value: "00", text: "C" },
            ],
          },
        ],

        [
          { type: "h2", label: "Microdominio pragmático" },

          { type: "h4", label: "ironias" },

          {
            type: "radio",
            var: "p1",
            label: "P1:",
            options: [
              { value: "0", text: "A" },
              { value: "00", text: "B" },
              { value: "1", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p2",
            label: "P2:",
            options: [
              { value: "1", text: "A" },
              { value: "00", text: "B" },
              { value: "0", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p3",
            label: "P3:",
            options: [
              { value: "0", text: "A" },
              { value: "00", text: "B" },
              { value: "1", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p4",
            label: "P4:",
            options: [
              { value: "1", text: "A" },
              { value: "0", text: "B" },
              { value: "00", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p5",
            label: "P5:",
            options: [
              { value: "0", text: "A" },
              { value: "1", text: "B" },
              { value: "00", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p6",
            label: "P6:",
            options: [
              { value: "0", text: "A" },
              { value: "1", text: "B" },
              { value: "00", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p7",
            label: "P7:",
            options: [
              { value: "0", text: "A" },
              { value: "00", text: "B" },
              { value: "1", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p8",
            label: "P8:",
            options: [
              { value: "1", text: "A" },
              { value: "0", text: "B" },
              { value: "00", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p9",
            label: "P9:",
            options: [
              { value: "1", text: "A" },
              { value: "0", text: "B" },
              { value: "00", text: "C" },
            ],
          },

          {
            type: "radio",
            var: "p10",
            label: "P10:",
            options: [
              { value: "1", text: "A" },
              { value: "0", text: "B" },
              { value: "00", text: "C" },
            ],
          },
        ],
      ],
      is_valid: null,
    };
  },
  methods: {
    correctAnswer(options) {
      const correct = options.find((option) => option.value === "1");
      return correct ? correct.text : "";
    },
    checkRequired() {
      const answerKeys = Object.keys(this.answer.answers);
      if (
        Object.values(this.answer.answers).filter((elem) => elem == undefined)
          .length
      ) {
        this.is_valid = false;
      } else {
        this.is_valid = this.required_fields.every((element) => {
          return answerKeys.includes(element);
        });
      }
    },
    checkState(item) {
      if (this.is_valid == null) {
        return null;
      }
      return this.answer.answers[item.var] ? null : false;
    },
    autoFillAnswers() {
      this.test[this.answer.step].forEach((item) => {
        if (!this.answer.answers[item.var] && item.options) {
          const correct = item.options.find((option) => option.value === "1");
          if (correct) {
            this.$set(this.answer.answers, item.var, correct.value);
          }
        }
      });
    },
    loadPreviousAnswers() {
      if (
        this.previous_answer &&
        this.previous_answer[`step-${this.answer.step}`]
      ) {
        console.log(
          "Cargando respuestas previas para el paso",
          this.answer.step,
          this.previous_answer[`step-${this.answer.step}`]
        );
        Object.entries(
          this.previous_answer[`step-${this.answer.step}`]
        ).forEach(([key, value]) => {
          this.$set(this.answer.answers, key, value);
        });
      }
    },
    send_test() {
      this.autoFillAnswers();
      let test;
      this.checkRequired();
      if (this.is_valid) {
        this.is_valid = null;
        // semester
        this.answer["semester"] = this.semester;
        testService
          .send_test(this.answer)
          .then(
            (data) => {
              test = data;

              redpieService.assignDocuments({
                test: this.answer.test,
                school: this.$store.state.login.school,
                year: this.$store.state.login.year,
                student_grade: this.answer.student_id,
              });
            },
            (error) => {
              console.error(error);
              this.$toasted.error("Ha ocurrido al hacer el test");
            }
          )
          .then(
            () => {
              if (this.answer.step !== 14) {
                this.answer.step++;
                this.answer.answers = {};
                this.loadPreviousAnswers();
                this.answer.redpsi_id = test.redpsi_id;
                this.answer.previous_answer = test.data;
              } else {
                this.$emit("update-document");
                this.$emit("add-test", test);
              }
            },
            () => {
              this.$toasted.error("Ha ocurrido al crear documento");
            }
          );
      }
    },
  },
  computed: {
    required_fields() {
      return this.test[this.answer.step]
        .filter((elem) => elem.var != null)
        .map((elem) => elem.var);
    },
  },
};
</script>
<style lang=""></style>
