<template>
    <div>
        <b-modal hide-header hide-footer ref="confirm-modal">
            <b-container>
                <b-row>
                    <b-col class="text-center">
                        Perderás los datos de la etapa actual. ¿Continuar?
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-center">
                        <b-button pill variant="danger" @click="$refs['confirm-modal'].hide()" class="mx-2">
                            No
                        </b-button>
                        <b-button pill variant="primary" @click="returnStage(test)" class="mx-2">
                            Si
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
        <b-container fluid class="px-0">
            <b-row class="text-center mx-0 px-0">
                <b-container class="text-center mx-0 px-0" fluid>
                    <b-row v-if="test.test_id" style="cursor: pointer;" class="text-center mx-0 px-0">
                        <b-col cols="3" class="text-right pr-1 mx-0 pl-0" @click="getAction(test)" >
                            <b-img width="30em" :src="getImg(test)" class="mt-3"/>
                        </b-col>
                        <b-col class="text-left pl-1 mx-0 pr-0" @click="getAction(test)">
                            <strong class="d-inline-block mt-3" :class="getClass(test)">{{getText(test)}}</strong>
                        </b-col>
                        <b-col cols="2" class="px-0 mx-0 text-left" 
                                v-b-tooltip.hover title="Devolver a etapa anterior" 
                                @click="$refs['confirm-modal'].show()"
                                v-if="test.started"
                        >
                            <b-img width="20px" :src="backIcon"/>
                        </b-col>
                    </b-row>
                    <div v-else>
                        No hay Evaluación disponible para el nivel/asignatura seleccionado.
                    </div>
                </b-container>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import customTestService from '../../../services/customTestService'

export default {
    props: {
        test: {
            type: Object,
            required: false
        },
        download: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            copyIcon: require('../../../assets/img/eureka_icons/eureka_svg_icons/Ajustados sin fondo_link.svg'),
            continueIcon: require('../../../assets/img/eureka_icons/eureka_svg_icons/Ajustados sin fondo_terminar prueba.svg'),
            reviewIcon: require('../../../assets/img/eureka_icons/eureka_svg_icons/Ajustados sin fondo_revisar.svg'),
            readyIcon: require('../../../assets/img/eureka_icons/eureka_svg_icons/Ajustados sin fondo_listo.svg'),
            backIcon: require('../../../assets/img/eureka_icons/eureka_svg_icons/Íconos_atrás.svg')
        }
    },
    created() {
    },
    methods: {
        getClass(test) {
            if (test.started) {
                if (!test.finished) {
                    return "continue";
                } else if (test.finished && !test.reviewed) {
                    return "review";
                } else {
                    return "ready";
                }
            } else {
                return "copy";
            }
        },
        getText(test) {
            if (test.started) {
                if (!test.finished) {
                    return "Continuar";
                } else if (test.finished && !test.reviewed) {
                    return "Revisar";
                } else {
                    return "Listo!";
                }
            } else {
                return "Copiar Link";
            }
        },
        getImg(test) {
            if (test.started) {
                if (!test.finished) {
                    return this.continueIcon;
                } else if (test.finished && !test.reviewed) {
                    return this.reviewIcon;
                } else {
                    return this.readyIcon;
                }
            } else {
                return this.copyIcon;
            }
        },
        getAction(test) {
            if (test.started) {
                if (!test.finished) {
                    return this.copyLink(test);
                } else if (test.finished && !test.reviewed) {
                    return this.review(test);
                } else {
                    return ;
                }
            } else {
                return this.copyLink(test);
            }
        },
        getValueClasses(test) {
            if (test.started) {
                if (!test.finished) {
                    return [
                        "text-primary",
                        "text-ultralight-hover",
                        "bg-primary-hover",
                        "border-primary",
                    ];
                } else if (test.finished && !test.reviewed) {
                    return [
                        "text-secondary",
                        "text-ultralight-hover",
                        "bg-secondary-hover",
                        "border-secondary",
                    ];
                } else {
                    return [
                        "text-tertiary",
                        "text-ultralight-hover",
                        "bg-tertiary-hover",
                        "border-tertiary",
                    ];
                }
            } else {
                return [
                    "text-redpie",
                    "text-ultralight-hover",
                    "bg-redpie-hover",
                    "border-redpie",
                ];
            }
        },
        getTitle(test) {
            if (test.started) {
                if (!test.finished) {
                    return "NO TERMINADO";
                } else if (test.finished && !test.reviewed) {
                    return "NO REVISADO";
                } else {
                    return "REVISADO";
                }
            } else {
                return "SIN COMENZAR";
            }
        },
        getValueIcon(test) {
            if (test.started) {
                if (!test.finished) {
                    return "minus";
                } else if (test.finished && !test.reviewed) {
                    return "check";
                } else {
                    return "folder-open";
                }
            } else {
                return "times";
            }
        },
        generateLink(test) {
            customTestService.generateLink({
                test: test.test_id,
                rut: test.student_rut,
                email: test.student_email,
                name: test.student_name,
                videocall_kind: 3,
                student_grade: test.student_grade
            }).then(
                data => {
                    test.started = true;
                    let testURL = `${process.env['VUE_APP_BASE_URL']}/tests#/t/${data.id}`
                    if ((navigator).clipboard) {
                        (navigator).clipboard.writeText(testURL);
                    } else if ((window).clipboardData) {
                        (window).clipboardData.setData('url', testURL);
                    } else {
                        this.$toasted.error("No se pudo copiar");
                        return
                    }
                    this.$toasted.success("Link copiado en el portapapeles")
                },
                () => this.$toasted.error("Ocurrió un error. Intenta nuevamente.")
            )
        },
        copyLink(test) {
            if (test.started || test.id) {
                let testURL = `${process.env["VUE_APP_BASE_URL"]}/tests#/t/${test.id}`;
                if (navigator.clipboard) {
                    navigator.clipboard.writeText(testURL);
                } else if (window.clipboardData) {
                    window.clipboardData.setData("url", testURL);
                } else {
                    this.$toasted.error("No se pudo copiar");
                    return;
                }
                this.$toasted.success("Link copiado en el portapapeles");
            }
            else {
                this.generateLink(test);
            }
        },
        closeLink(test) {
            customTestService
                .closeTest({ invite: test.id })
                .then((data) => {
                    test.finished = true;
                    this.$toasted.success(data.message);
                });
        },
        closeAndReview(test) {
            customTestService
                .closeTest({ invite: test.id })
                .then((data) => {
                    test.finished = true;
                    this.$toasted.success(data.message);
                    this.review(test);
                });
        },
        review(test) {
            this.$emit("response-admin", test);
        },
        returnStage(test) {
            customTestService.returnStage({link: test.id}).then(
                () => {
                    this.$toasted.success("Test devuelto!");
                    this.$emit("reload");
                    this.$refs['confirm-modal'].hide()
                }
            )
        }
    }
}
</script>
<style lang="scss">
.value-container {
  cursor: pointer;
  margin: 0 auto;
  width: 56px;
  height: 24px;
  background-color: white;
  border: solid 1px;
  border-radius: 16px;
  font-size: 0.5em !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.copy {
    color: #E54856 
}
.continue {
    color: #F1BC13
}
.review {
    color: #3880A5
}
.ready {
    color: #17B2AD 
}
</style>