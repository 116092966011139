<template>
  <div class="work-schedules radius-box-8 padding-box-16">
    <div>
      <toggle-button
        :value="checked"
        :sync="true"
        :width="64"
        :height="32"
        :switch-color="toggleColors"
        @change="onChangeToggleButton"
      />
    </div>
    <div>
      <span v-if="!checked"
        >Quiero trabajar las <strong>planificaciones DUA</strong></span
      >
      <span v-if="checked"
        >Quiero trabajar los <strong>documentos de los niños</strong></span
      >
    </div>
  </div>
</template>

<script>
import colors from "../../../assets/scss/redpie4/colors.scss";

export default {
  created() {
  },
  components: {},
  data() {
    return {
      toggleColors: {checked: colors.light, unchecked: colors.primary, disabled: ''},
      checked: false
    };
  },
  methods: {
    onChangeToggleButton(event) {
      console.log(event)
      this.checked = !this.checked;
      /*if (event.value) {
        this.$router.push({ name: "dua-planning" });
      } else {
        this.$router.push({ name: "home" });
      }*/
    },
    detectPageToCheck() {
      if (this.$route.fullPath === "/") {
        this.checked = false;
        document
          .querySelector("#globalContainer")
          .classList.remove("work-schedules-active-component");
      } else if (this.$route.fullPath === "/dua-planning") {
        this.checked = true;
        document
          .querySelector("#globalContainer")
          .classList.add("work-schedules-active-component");
      }
    }
  },
  watch: {
	// eslint-disable-next-line
    $route(to, from) {
      this.detectPageToCheck();
    }
  },
  mounted() {
    this.detectPageToCheck();
  }
};
</script>

<style lang="scss">
@import "../../../assets/scss/redpie4/colors";

.work-schedules {
  width: 100%;
  background-color: white;
  border: solid 3px $primary ;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8em;
}
</style>
